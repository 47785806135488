
/**
 * 格式化日期
 * @param {日期} date 
 * @param {格式} type 1：yyyy-MM-dd 2: yyyy-MM-dd HH:mm:ss
 */
export function formatTime(date, type = 1) {
  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let strDate = date.getDate().toString().padStart(2, '0');
  //时间格式yyyy-MM-dd HH:MM:SS
  const strTime = getTime(date)
  const str = `${date.getFullYear()}-${month}-${strDate}`;
  if (type === 1) {
    return str
  } else if (type === 2) {
    return str + ' ' + strTime
  }

}
// 获取时间，格式HH:MM:SS
function getTime(date) {
  //时间格式HH:MM:SS

  const hours = date.getHours()
  const hoursStr = hours >= 10 ? hours : '0' + hours

  const minutes = date.getMinutes()
  const minutesStr = minutes >= 10 ? minutes : '0' + minutes

  const seconds = date.getSeconds()
  const secondsStr = seconds >= 10 ? seconds : '0' + seconds
  return `${hoursStr}:${minutesStr}:${secondsStr}`;
}

export function getDateDiff(dateStr) {
  let result = ''
  var minute = 1000 * 60;
  var hour = minute * 60;
  var day = hour * 24;
  var month = day * 30;
  let dateTimeStamp = new Date(dateStr).getTime()

  var now = new Date().getTime();
  var diffValue = now - dateTimeStamp;
  if (diffValue < 0) {
    //若日期不符则弹出窗口告之
    //alert("结束日期不能小于开始日期！");
  }
  var monthC = diffValue / month;
  var weekC = diffValue / (7 * day);
  var dayC = diffValue / day;
  var hourC = diffValue / hour;
  var minC = diffValue / minute;
  if (monthC >= 1) {
    // result = parseInt(monthC) + "个月前";
    result = dateStr
  } else if (weekC >= 1) {
    // result = parseInt(weekC) + "周前";
    result = dateStr
  } else if (dayC >= 1) {
    result = parseInt(dayC) + "天前";
  } else if (hourC >= 1) {
    result = parseInt(hourC) + "小时前";
  } else if (minC >= 1) {
    result = parseInt(minC) + "分钟前";
  } else
    result = "几秒前";
  return result;
}

//获取当前日期函数
export function getNowFormatDate() {
  let date = new Date(),
    year = date.getFullYear(), //获取完整的年份(4位)
    month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
    strDate = date.getDate() // 获取当前日(1-31)
  if (month < 10) month = `0${month}` // 如果月份是个位数，在前面补0
  if (strDate < 10) strDate = `0${strDate}` // 如果日是个位数，在前面补0

  return `${year}-${month}-${strDate}`
}

/**
    * 获取上一个月-第二种
    * @date 格式为yyyy-mm-dd的日期，如：2019-01-25
    * 对日期做处理，获取2019-03-28，29，30，31，都会返回2019-02-28
    * 
    */
export function getPreMonth(date) {
  var arr = date.split('-');
  var year = arr[0]; //获取当前日期的年份
  var month = arr[1]; //获取当前日期的月份
  var day = arr[2]; //获取当前日期的日
  var month2 = parseInt(month) - 1;
  if (month2 == 0) {
    year = parseInt(year) - 1;
    month2 = 12;
  }
  var days2 = new Date(year, month2, 0);
  days2 = days2.getDate();
  if (day > days2) {
    day = days2;
  }
  if (month2 < 10) {
    month2 = '0' + month2;
  }
  var t2 = year + '-' + month2 + '-' + day;
  return t2;
}

/**
 * 获取下一个月-第二种
 * @date 格式为yyyy-mm-dd的日期，如：2019-01-25
 * 
 */
export function getNextMonth(date) {
  var arr = date.split('-');
  var year = arr[0]; //获取当前日期的年份
  var month = arr[1]; //获取当前日期的月份
  var day = arr[2]; //获取当前日期的日
  var month2 = parseInt(month) + 1;
  if (month2 == 13) {
    year = parseInt(year) + 1;
    month2 = 1;
  }
  var day2 = day;
  var days2 = new Date(year, month2, 0);
  days2 = days2.getDate();
  if (day2 > days2) {
    day2 = days2;
  }
  if (month2 < 10) {
    month2 = '0' + month2;
  }
  var t2 = year + '-' + month2 + '-' + day2;
  return t2;
}