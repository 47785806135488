<template>
  <ul class="dic-ul" v-loading="fullLoading">
    <li class="hm-search">
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item :label="$t('Components.System.Dictionary.Search')">
          <el-input
            v-model="searchForm.inputSearch"
            :placeholder="$t('Components.System.Dictionary.NameCode')"
            clearable
          />
        </el-form-item>
        <el-form-item :label="$t('Components.System.Dictionary.Dataflag')">
          <el-select
            v-model="searchForm.dataflag"
            :placeholder="$t('Components.System.Dictionary.SelectDataflag')"
            clearable
          >
            <el-option
              :label="$t('Components.System.Dictionary.DataflagY')"
              value="true"
            />
            <el-option
              :label="$t('Components.System.Dictionary.DataflagN')"
              value="false"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="buttonVisible.searchButton"
            type="primary"
            @click="queryClick"
            :icon="Search"
            >{{ $t("Components.System.Dictionary.Search1") }}</el-button
          >
          <el-button
            v-if="buttonVisible.resetButton"
            @click="resetClick"
            :icon="RefreshLeft"
            >{{ $t("Components.System.Dictionary.Reset") }}</el-button
          >
        </el-form-item>
      </el-form>
    </li>
    <li class="hm-operate">
      <!-- 操作部分 -->
      <el-button
        v-if="buttonVisible.addButton"
        :style="{ color: 'white' }"
        :icon="Plus"
        :color="ButtonColor.Add"
        @click="
          () => {
            dialogVisible = true;
          }
        "
        >{{ $t("Components.System.Dictionary.Add") }}</el-button
      >
      <el-button
        v-if="buttonVisible.editButton"
        :disabled="!(selectDictionarys && selectDictionarys.length === 1)"
        :style="{ color: 'white' }"
        :icon="Edit"
        :color="ButtonColor.Edit"
        @click="handleEdit(1)"
        >{{ $t("Components.System.Dictionary.Edit") }}</el-button
      >
      <el-button
        v-if="buttonVisible.deleteButton"
        :disabled="!(selectDictionarys && selectDictionarys.length > 0)"
        :style="{ color: 'white' }"
        :icon="Delete"
        :color="ButtonColor.Delete"
        @click="handleDeleteBatch"
        >{{ $t("Components.System.Dictionary.Delete") }}</el-button
      >
      <el-button
        v-if="buttonVisible.exportButton"
        :style="{ color: 'white' }"
        :icon="Download"
        :color="ButtonColor.Export"
        @click="handleExport"
        >{{ $t("Components.System.Dictionary.Export") }}</el-button
      >
      <el-upload
        ref="uploadRef"
        accept=".xls, .xlsx"
        :action="request_dic_import"
        :limit="1"
        name="file"
        :headers="{ token: token }"
        :on-success="handleUploadSuccess"
        :show-file-list="false"
      >
        <el-button
          v-if="buttonVisible.importButton"
          :style="{ color: 'white' }"
          :icon="Upload"
          :color="ButtonColor.Upload"
          >{{ $t("Components.System.Dictionary.Import") }}</el-button
        >
      </el-upload>
    </li>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          row-key="guid"
          :expand-row-keys="[]"
          @row-click="handleRowClick"
          :row-class-name="tableRowClassName"
          @sort-change="handleSortChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            prop="name"
            min-width="120"
            :label="$t('Components.System.Dictionary.Name')"
          />
          <el-table-column
            prop="code"
            min-width="120"
            :label="$t('Components.System.Dictionary.Code')"
          />
          <el-table-column
            prop="remark"
            min-width="160"
            :label="$t('Components.System.Dictionary.Remark')"
          />
          <el-table-column
            prop="is_system"
            :label="$t('Components.System.SystemParam.IsSystem')"
          >
            <template #default="scope">
              <el-tag v-if="scope.row.is_system" effect="dark">
                {{ t("Common.YesOrNo.Yes") }}
              </el-tag>
              <el-tag v-else :style="{ color: 'white' }" :color="ButtonColor.DataflagN">{{
                t("Common.YesOrNo.No")
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="dataflag"
            width="100"
            :label="$t('Components.System.Dictionary.Dataflag')"
          >
            <template #default="scope">
              <el-switch
                :disabled="!buttonVisible.dataflag || scope.row.is_system"
                v-model="scope.row.dataflag"
                @change="(flag) => handleDataflagChange(flag, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            width="200"
            :label="$t('Components.System.Dictionary.Operate')"
          >
            <template #default="scope">
              <el-space wrap v-if="!scope.row.is_system">
                <el-button
                  v-if="buttonVisible.editButtonLine"
                  :style="{ color: 'white' }"
                  :icon="Edit"
                  :color="ButtonColor.Edit"
                  size="small"
                  @click="handleEdit(2, scope.row)"
                  >{{ $t("Components.System.Dictionary.Edit") }}</el-button
                >
                <el-button
                  v-if="buttonVisible.deleteButtonLine"
                  :style="{ color: 'white' }"
                  :icon="Delete"
                  :color="ButtonColor.Delete"
                  size="small"
                  bg
                  @click="handleDelete(scope.row)"
                  >{{ $t("Components.System.Dictionary.Delete") }}</el-button
                >
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </li>
    </el-scrollbar>

    <li class="hm-page">
      <el-pagination
      small
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="AllPageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </li>
  </ul>
  <DictionaryModify
    v-if="dialogVisible"
    @handleCloseDialog="handleCloseDialog"
    :editData="editData"
    @getDictionaryPage="getDictionaryPage"
  />
</template>
<script setup>
import { onMounted, reactive, ref, markRaw, watchEffect,
  defineEmits } from "vue";
import {
  RefreshLeft,
  Search,
  Edit,
  Plus,
  Delete,
  WarnTriangleFilled,
  Download,
  Upload,
} from "@element-plus/icons-vue";
import {
  request_dictionary_page,
  request_dictionary_dataflag,
  request_dictionary_delete,
  request_dictionary_delete_batch,
  request_dictionary_export,
  request_dic_import,
} from "@/api/apis";
import { DefaultPageSize } from "@/consts/frame";
import { AllPageSize, ButtonColor, LocalStorageKey } from "@/consts/frame";
import DictionaryModify from "@/components/System/Dictionary/DictionaryModify.vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";
import { useStore } from "@/store/index";
import statusCode from "@/api/statusCode";

const store = useStore();
const { t } = useI18n();

const token = ref("");
const emits = defineEmits(["handleDictionaryClick", "handleClearActiveDictionary"]);
const uploadRef = ref();
const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  addButton: false,
  editButton: false,
  deleteButton: false,
  editButtonLine: false,
  deleteButtonLine: false,
  dataflag: false,
  exportButton: false,
  importButton: false,
});
const searchForm = reactive({
  inputSearch: "",
  dataflag: undefined,
});
// 当前页码
const currentPage = ref(1);
// 页码大小
const pageSize = ref(DefaultPageSize);
// 表格数据
const tableData = ref([]);
// 表格加载
const tableLoading = ref(false);
// 总数量
const total = ref(0);
// 编辑的数据
const editData = ref({});
// 弹窗是否可见
const dialogVisible = ref(false);
// 选择的字典
const selectDictionarys = ref([]);
// 选择的字典guid
const selectDictionaryGuid = ref("");
// 创建时间排序规则 如果为undefined 则没有这个参数，如果为true代表倒叙，为false代表正序
const createTimeSort = ref(undefined);

const fullLoading = ref(false);
onMounted(async () => {
  await getDictionaryPage();
  if (localStorage) {
    token.value = localStorage.getItem(LocalStorageKey.Token);
  }
});
// 分页查询字典
const getDictionaryPage = async () => {
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    text: searchForm.inputSearch,
    dataflag: searchForm.dataflag,
    createTimeSort: createTimeSort.value,
  };
  tableLoading.value = true;
  const result = await request_dictionary_page(trans, () => (tableLoading.value = false));
  total.value = result.total;
  tableData.value = result.rows;
  selectDictionarys.value = [];
  selectDictionaryGuid.value = "";
  emits("handleClearActiveDictionary");
};
// 查询事件
const queryClick = () => {
  getDictionaryPage();
};
// 重置事件
const resetClick = () => {
  searchForm.dataflag = undefined;
  searchForm.inputSearch = "";
  getDictionaryPage();
};
// 处理页码大小改变事件
const handleSizeChange = (size) => {
  pageSize.value = size;
  getDictionaryPage();
};
// 处理当前页改变事件
const handleCurrentChange = (index) => {
  currentPage.value = index;
  getDictionaryPage();
};
// 处理编辑事件
const handleEdit = (type, data) => {
  let row = undefined;
  if (type === 1) {
    // 判断是否有且仅选择了一条用户数据
    if (selectDictionarys.value.length !== 1) {
      ElMessage.warning(t("Components.System.Dictionary.OnlyOneSelectEdit"));
      return;
    }
    row = selectDictionarys.value[0];
  } else if (type === 2) {
    row = data;
  }

  editData.value = {
    guid: row.guid,
    name: row.name,
    sort: row.sort,
    is_system: row.is_system,
    code: row.code,
    remark: row.remark,
  };
  dialogVisible.value = true;
};
// 处理回调关闭事件
const handleCloseDialog = () => {
  dialogVisible.value = false;
  editData.value = {};
  // selectDictionarys.value = [];
};
// 处理有效性改变事件
const handleDataflagChange = async (flag, row) => {
  await request_dictionary_dataflag(row.guid, true, () => {
    row.dataflag = !row.dataflag;
  });
};
// 处理选择回调事件
const handleSelectionChange = (dictionarys) => {
  selectDictionarys.value = dictionarys;
};
// 处理删除
const handleDelete = async (row) => {
  ElMessageBox.confirm(
    t("Components.System.Dictionary.ConfirmDeleteDic"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      await request_dictionary_delete(row.guid, true);
      getDictionaryPage();
    })
    .catch(() => {});
};
// 批量删除
const handleDeleteBatch = async () => {
  if (selectDictionarys.value.length === 0) {
    ElMessage.warning(t("Components.System.Dictionary.AtLeastOneDic"));
    return;
  }
  ElMessageBox.confirm(
    t("Components.System.Dictionary.ConfirmDeleteDic"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const trans = {
        guids: selectDictionarys.value.map((item) => item.guid),
      };
      await request_dictionary_delete_batch(trans, true);
      getDictionaryPage();
    })
    .catch(() => {});
};
// 处理排序改变事件
const handleSortChange = async ({ prop, order }) => {
  if (prop === "createtime") {
    // 创建时间排序
    if (order === "ascending") {
      // 时间正序
      createTimeSort.value = false;
    } else if (order === "descending") {
      // 时间倒叙
      createTimeSort.value = true;
    } else {
      createTimeSort.value = undefined;
    }
  }
  await getDictionaryPage();
};
// 行点击事件
const handleRowClick = (row) => {
  selectDictionaryGuid.value = row.guid;
  emits("handleDictionaryClick", row.guid);
};
// 激活行的颜色
const tableRowClassName = ({ row }) => {
  if (row.guid === selectDictionaryGuid.value) {
    return "success-row";
  }
};
// 导出
const handleExport = async () => {
  fullLoading.value = true;
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    text: searchForm.inputSearch,
    dataflag: searchForm.dataflag,
    createTimeSort: createTimeSort.value,
  };
  const result = await request_dictionary_export(
    trans,
    true,
    () => (fullLoading.value = false)
  );
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = result;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
};

// 处理上传成功
const handleUploadSuccess = async (response) => {
    uploadRef.value.clearFiles();
  if (response.code === statusCode.Success) {
    ElMessage.success(response.message);
  } else {
    ElMessage.warning(response.message);
  }
};
watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Dictionary,
      MenuButtonCodes.System.Dictionary.DictionarySearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Dictionary,
      MenuButtonCodes.System.Dictionary.DictionaryReset
    );
    // 处理新增
    buttonVisible.addButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Dictionary,
      MenuButtonCodes.System.Dictionary.DictionaryAdd
    );
    // 处理编辑
    buttonVisible.editButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Dictionary,
      MenuButtonCodes.System.Dictionary.DictionaryEdit
    );
    // 处理删除
    buttonVisible.deleteButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Dictionary,
      MenuButtonCodes.System.Dictionary.DictionaryDelete
    );
    // 处理删除行
    buttonVisible.editButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Dictionary,
      MenuButtonCodes.System.Dictionary.DictionaryEditLine
    );
    // 处理删除行
    buttonVisible.deleteButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Dictionary,
      MenuButtonCodes.System.Dictionary.DictionaryDeleteLine
    );
    // 处理有效性
    buttonVisible.dataflag = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Dictionary,
      MenuButtonCodes.System.Dictionary.DictionaryDataflag
    );
    // 处理导出
    buttonVisible.exportButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Dictionary,
      MenuButtonCodes.System.Dictionary.DictionaryExport
    );
    // 处理导出
    buttonVisible.importButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Dictionary,
      MenuButtonCodes.System.Dictionary.DictionaryImport
    );
  }
});
</script>
<style>
.dic-ul .el-scrollbar__view {
  height: calc(100% - 8px);
}
.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}
</style>
<style scoped lang="scss">
.dic-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  .dic-li-search {
  }
}
</style>
