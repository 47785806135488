<template>
 <div :style="{height: '450px'}">
  <el-scrollbar>
    <el-steps
    class="project-task-log-step"
    direction="vertical"
    v-if="props.data && props.data.projectTaskLogs"
  >
    <div class="div-log">
      <el-step
        status="process"
        v-for="item in props.data.projectTaskLogs"
        :key="item.guid"
      >
        <template #icon>
          <div class="div-dot"></div>
        </template>
        <template #title>
          <el-form label-width="60px" size="small">
            <el-form-item :label='t("Components.Project.ProjectTask.ProjectTaskLogApprove")'>
              <el-text :style="{ fontWeight: 'normal' }">{{ item.createname }}</el-text>
            </el-form-item>
            <el-form-item :label='t("Components.Project.ProjectTask.ProjectTaskOperateType")'>
              <el-text :style="{ fontWeight: 'normal' }">{{
                computedTitle(item)
              }}</el-text>
            </el-form-item>
            <el-form-item :label='t("Components.Project.ProjectTask.ProjectTaskOperateTime")'>
              <el-text :style="{ fontWeight: 'normal' }">{{ item.createtime }}</el-text>
            </el-form-item>
            <el-form-item :label='t("Components.Project.ProjectTask.ProjectTaskApprover")' v-if="item.type === ProjectTaskLogType.Commit">
              <el-space wrap>
                <el-tag v-for="(item1, index1) in item.users" :key="index1">
                  <el-text :style="{ fontWeight: 'normal' }">{{
                    item1.user_name
                  }}</el-text>
                </el-tag>
              </el-space>
            </el-form-item>
            <el-form-item :label='t("Components.Project.ProjectTask.ProjectTaskDispatcher")' v-if="item.type === ProjectTaskLogType.Dipatch">
              <el-space wrap>
                <el-tag v-for="(item1, index1) in item.users" :key="index1">
                  <el-text :style="{ fontWeight: 'normal' }">{{
                    item1.user_name
                  }}</el-text>
                </el-tag>
              </el-space>
            </el-form-item>
            <el-form-item :label='t("Components.Project.ProjectTask.ProjectTaskCollboration")' v-if="item.type === ProjectTaskLogType.Dipatch">
              <el-space wrap>
                <el-tag v-for="(item1, index1) in item.collborations" :key="index1">
                  <el-text :style="{ fontWeight: 'normal' }">{{
                    item1.user_name
                  }}</el-text>
                </el-tag>
              </el-space>
            </el-form-item>
            <el-form-item
            :label='t("Components.Project.ProjectTask.ProjectTaskApproveResult")'
              v-if="item.type === ProjectTaskLogType.Approve"
            >
            <el-tag  effect="dark" v-if="item.approve_result && item.approve_result.approve_status === ApproveStatus.Pass"   type="success"> 
              
              <el-text 
                :style="{ fontWeight: 'normal', color: 'white'  }">{{t("Components.Project.ProjectTask.ProjectTaskApprovePass")}}</el-text>
            </el-tag>
            <el-tag  effect="dark" v-else-if="item.approve_result && item.approve_result.approve_status === ApproveStatus.Refuse"   type="danger"> 
              <el-text 
                :style="{ fontWeight: 'normal', color: 'white'  }">{{t("Components.Project.ProjectTask.ProjectTaskApproveRefuse")}}</el-text>
            </el-tag>
            </el-form-item>
          </el-form>
          <el-divider  border-style="dashed" ></el-divider>
        </template>
      </el-step>
    </div>
  </el-steps>
  </el-scrollbar>
 </div>
</template>
<script setup>
import { computed, defineProps } from "vue";
import { ProjectTaskLogType,ApproveStatus } from "@/consts/enum";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps({
  data: {
    type: Object,
    default: () => undefined,
  },
});
const computedTitle = computed(() => (log) => {
  if (log.type === ProjectTaskLogType.Commit) {
    // 提交类型
    return t("Components.Project.ProjectTask.ProjectTaskLogCommit");
  } else if (log.type === ProjectTaskLogType.Dipatch) {
    // 派遣
    return t("Components.Project.ProjectTask.ProjectTaskLogDispatch");
  } else if (log.type === ProjectTaskLogType.Approve) {
    // 审批
    return t("Components.Project.ProjectTask.ProjectTaskLogApprove");
  }
});
</script>
<style>
.project-task-log-step .el-form-item--small {
  margin-bottom: 0 !important;
}
.project-task-log-step .el-form-item--small .el-form-item__label {
  display: inline-block;
  text-align-last: justify;
}
.el-divider.el-divider--horizontal{
  margin: 8px 0;
}
</style>
<style scoped lang="scss">
.div-log {
  padding: 12px;
  .div-dot {
    width: 8px;
    height: 8px;
    background-color: #108ee9;
    border-radius: 4px;
  }
}
</style>
