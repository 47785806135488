<template>
  <Dialog
    width="40%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <div class="div-comment-root">
      <div class="div-comment-object">引用：</div>
      <span class="span-comment-content" :style="{ fontSize: '16px' }">{{
        selectContent
      }}</span>
    </div>
    <el-input
      v-model="comments"
      type="textarea"
      placeholder="请输入内容"
    />
  </Dialog>
</template>
<script setup >
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { onMounted, ref, defineProps,defineEmits } from "vue";
// import { LocalStorageKey, LangName } from "@/consts/frame";
import { useI18n } from "vue-i18n";
import {
  request_project_comment,
} from "@/api/apis";
import { ElMessage } from "element-plus";
import { ProjectTaskCommentModule } from "@/consts/enum";

const { t } = useI18n();
const props = defineProps({
  module: {
    type: Number,
    default: () => ProjectTaskCommentModule.Default,
  },
  relateGuid: {
    type: String,
    default: () => "",
  },
  grading: {
    type: String,
    default: () => "",
  },
  mainCategory: {
    type: String,
    default: () => "",
  },
  subCategory: {
    type: String,
    default: () => "",
  },
  elaborateModule: {
    type: Number,
    default: () => 0,
  },
  appendixDetailGuid: {
    type: String,
    default: () => "",
  },
  rootGuid: {
    type: String,
    default: () => "",
  },
  sourecContent: {
    type: String,
    default: () => "",
  },
  parentGuid: {
    type: String,
    default: () => "",
  },
  projectTaskGuid: {
    type: String,
    default: () => "",
  },
  selectStart: {
    type: Number,
    default: () => 0,
  },
  selectEnd: {
    type: Number,
    default: () => 0,
  },
  selectContent: {
    type: String,
    default: () => "",
  },
});
// // 语言
// const lang = ref(localStorage.getItem(LocalStorageKey.Lang) || LangName.ZHCN);

const emits = defineEmits(["handleCloseDialog", "handleCommitDialog"]);
const dialogTitle = ref("");
// 内容
const comments = ref("");

onMounted(async () => {
  dialogTitle.value = t("Components.Report.GenerateReport.ReviewComments");
});

// 处理提交事件
const handleCommit = async () => {
  if (!comments.value) {
    ElMessage.warning(
      t(
        "Components.Project.ProjectTask.Report1.ContentSummary.PleaseInputComment"
      )
    );
    return;
  }
  // if (props.module === ProjectTaskCommentModule.ContentSummary) {
  //   // 如果是稽查小结
  //   if (!props.sourecContent) {
  //     ElMessage.warning(t("Components.Report.GenerateReport.NoContent"));
  //     return
  //   }
  // }
  const trans = {
    module: props.module,
    relate_guid: props.relateGuid,
    grading: props.grading,
    main_category: props.mainCategory,
    sub_category: props.subCategory,
    elaborate_module: props.elaborateModule,
    appendix_detail_guid: props.appendixDetailGuid,
    root_guid: props.rootGuid, // 这边不应该是rootguid
    parent_guid: props.parentGuid,
    source_content: props.sourecContent,
    comment: comments.value,
    select_start: props.selectStart,
    select_end: props.selectEnd,
    select_content: props.selectContent,
  };
  await request_project_comment(props.projectTaskGuid, trans);
  emits("handleCommitDialog");
};
// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};
</script>
<style scoped lang='scss'>
span {
  white-space: pre-wrap;
}
.div-comment-root {
  margin-bottom: 8px;
  .div-comment-object {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .span-comment-content {
    font-size: 16px;
    font-weight: 600;
  }
}
</style>