<template>
  <ul class="dicitem-ul" v-loading="fullLoading">
    <li class="hm-search">
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item :label="$t('Components.System.Dictionary.Item.Search')">
          <el-input
            :disabled="!selectDictionaryGuid"
            v-model="searchForm.inputSearch"
            :placeholder="$t('Components.System.Dictionary.Item.NameCode')"
            clearable
          />
        </el-form-item>
        <el-form-item :label="$t('Components.System.Dictionary.Item.Dataflag')">
          <el-select
            :disabled="!selectDictionaryGuid"
            v-model="searchForm.dataflag"
            :placeholder="
              $t('Components.System.Dictionary.Item.SelectDataflag')
            "
            clearable
          >
            <el-option
              :label="$t('Components.System.Dictionary.Item.DataflagY')"
              value="true"
            />
            <el-option
              :label="$t('Components.System.Dictionary.Item.DataflagN')"
              value="false"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="buttonVisible.searchButton"
            type="primary"
            :disabled="!selectDictionaryGuid"
            @click="queryClick"
            :icon="Search"
            >{{ $t("Components.System.Dictionary.Item.Search1") }}</el-button
          >
          <el-button
            v-if="buttonVisible.resetButton"
            :disabled="!selectDictionaryGuid"
            @click="resetClick"
            :icon="RefreshLeft"
            >{{ $t("Components.System.Dictionary.Item.Reset") }}</el-button
          >
        </el-form-item>
      </el-form>
    </li>
    <li class="hm-operate">
      <!-- 操作部分 -->
      <el-button
        v-if="buttonVisible.addButton"
        :disabled="!selectDictionaryGuid"
        :style="{ color: 'white' }"
        :icon="Plus"
        :color="ButtonColor.Add"
        @click="
          () => {
            dialogVisible = true;
          }
        "
        >{{ $t("Components.System.Dictionary.Item.Add") }}</el-button
      >
      <el-button
        v-if="buttonVisible.editButton"
        :disabled="
          !(selectDictionaryItems && selectDictionaryItems.length === 1)
        "
        :style="{ color: 'white' }"
        :icon="Edit"
        :color="ButtonColor.Edit"
        @click="handleEdit(1)"
        >{{ $t("Components.System.Dictionary.Item.Edit") }}</el-button
      >
      <el-button
        v-if="buttonVisible.deleteButton"
        :disabled="!(selectDictionaryItems && selectDictionaryItems.length > 0)"
        :style="{ color: 'white' }"
        :icon="Delete"
        :color="ButtonColor.Delete"
        @click="handleDeleteBatch"
        >{{ $t("Components.System.Dictionary.Item.Delete") }}</el-button
      >
      <el-button
        v-if="buttonVisible.exportButton"
        :disabled="!selectDictionaryGuid"
        :style="{ color: 'white' }"
        :icon="Download"
        :color="ButtonColor.Export"
        @click="handleExport"
        >{{ $t("Components.System.Dictionary.Item.Export") }}</el-button
      >
      <el-upload
        ref="uploadRef"
        accept=".xls, .xlsx"
        :action="request_dictionaryitem_import"
        :limit="1"
        name="file"
        :headers="{ token: token }"
        :on-success="handleUploadSuccess"
        :show-file-list="false"
        :data="{ dic_guid: selectDictionaryGuid }"
      >
        <el-button
          v-if="buttonVisible.importButton"
          :disabled="!selectDictionaryGuid"
          :style="{ color: 'white' }"
          :icon="Upload"
          :color="ButtonColor.Upload"
          >{{ $t("Components.System.Dictionary.Item.Import") }}</el-button
        >
      </el-upload>
    </li>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          row-key="guid"
          :expand-row-keys="[]"
          @sort-change="handleSortChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            prop="id"
            width="60"
            :label="$t('Components.System.Dictionary.Item.Id')"
          />
          <el-table-column
            prop="name"
            min-width="120"
            :label="$t('Components.System.Dictionary.Item.Name')"
          >
            <template #default="scope">
              <el-text v-if="scope.row.echo_style ===  DictionaryItemEchoStyle.Default">{{scope.row.name}}</el-text>
              <el-tag v-else :type="scope.row.echo_style === DictionaryItemEchoStyle.Primary ? '' : scope.row.echo_style">
                {{ scope.row.name }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="value"
            width="120"
            :label="$t('Components.System.Dictionary.Item.Value')"
          >
            <template #default="scope">
              <!-- <el-tag :type="scope.row.echo_style"> -->
              {{ scope.row.value }}
              <!-- </el-tag> -->
            </template>
          </el-table-column>
          <el-table-column
            prop="sort"
            min-width="80"
            :label="$t('Components.System.Dictionary.Item.Sort')"
          />
          <el-table-column
            prop="remark"
            min-width="160"
            :label="$t('Components.System.Dictionary.Item.Remark')"
          />
          <el-table-column
            prop="dataflag"
            width="100"
            :label="$t('Components.System.Dictionary.Item.Dataflag')"
          >
            <template #default="scope">
              <el-switch
                :disabled="!buttonVisible.dataflag || scope.row.is_system"
                v-model="scope.row.dataflag"
                @change="(flag) => handleDataflagChange(flag, scope.row)"
              />
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="createtime"
            sortable
            min-width="160"
            :label="$t('Components.System.Dictionary.CreateTime')"
          /> -->
          <el-table-column
            width="200"
            :label="$t('Components.System.Dictionary.Item.Operate')"
          >
            <template #default="scope">
              <el-space wrap v-if="!scope.row.is_system">
                <el-button
                  v-if="buttonVisible.editButtonLine"
                  :style="{ color: 'white' }"
                  :icon="Edit"
                  :color="ButtonColor.Edit"
                  size="small"
                  @click="handleEdit(2, scope.row)"
                  >{{ $t("Components.System.Dictionary.Item.Edit") }}</el-button
                >
                <el-button
                  v-if="buttonVisible.deleteButtonLine"
                  :style="{ color: 'white' }"
                  :icon="Delete"
                  :color="ButtonColor.Delete"
                  size="small"
                  bg
                  @click="handleDelete(scope.row)"
                  >{{
                    $t("Components.System.Dictionary.Item.Delete")
                  }}</el-button
                >
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </li>
    </el-scrollbar>
  </ul>
  <DictionaryItemModify
    v-if="dialogVisible"
    @handleCloseDialog="handleCloseDialog"
    :editData="editData"
    @getDictionaryItem="getDictionaryItem"
    :selectDictionaryGuid="selectDictionaryGuid"
  />
</template>
<script setup>
import {
  onMounted,
  reactive,
  ref,
  markRaw,
  defineExpose,
  watchEffect,
} from "vue";
import {
  RefreshLeft,
  Search,
  Edit,
  Plus,
  Delete,
  WarnTriangleFilled,
  Download,
  Upload,
} from "@element-plus/icons-vue";
import {
  request_dictionaryitem,
  request_dictionaryitem_delete,
  request_dictionaryitem_dataflag,
  request_dictionaryitem_delete_batch,
  request_dictionaryitem_export,
  request_dictionaryitem_import,
} from "@/api/apis";
import {
  ButtonColor,
  LocalStorageKey,
} from "@/consts/frame";
import DictionaryItemModify from "@/components/System/Dictionary/DictionaryItemModify.vue";
import {DictionaryItemEchoStyle} from "@/consts/enum";
import { ElMessage, ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";
import { useStore } from "@/store/index";
import statusCode from "@/api/statusCode";

const store = useStore();
const { t } = useI18n();
const token = ref("");
const searchForm = reactive({
  inputSearch: "",
  dataflag: undefined,
});
const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  addButton: false,
  editButton: false,
  deleteButton: false,
  editButtonLine: false,
  deleteButtonLine: false,
  dataflag: false,
  exportButton: false,
  importButton: false,
});
const uploadRef = ref();
// 表格数据
const tableData = ref([]);
// 表格加载
const tableLoading = ref(false);
// 编辑的数据
const editData = ref({});
// 弹窗是否可见
const dialogVisible = ref(false);
// 选择的字典项
const selectDictionaryItems = ref([]);
// 排序排序规则 如果为undefined 则没有这个参数，如果为true代表倒叙，为false代表正序
const sortSort = ref(undefined);
// 选中的字典guid
const selectDictionaryGuid = ref("");

const fullLoading = ref(false);

onMounted(async () => {
  // await getDictionaryItem();
  if (localStorage) {
    token.value = localStorage.getItem(LocalStorageKey.Token);
  }
});
// 查询字典项
const getDictionaryItem = async (dictionaryGuid) => {
  if (dictionaryGuid) {
    selectDictionaryGuid.value = dictionaryGuid;
  }
  const trans = {
    text: searchForm.inputSearch,
    dataflag: searchForm.dataflag,
    sortSort: sortSort.value,
    dic_guid: dictionaryGuid || selectDictionaryGuid.value,
  };
  tableLoading.value = true;
  const result = await request_dictionaryitem(
    trans,
    () => (tableLoading.value = false)
  );
  tableData.value = result;
  selectDictionaryItems.value = [];
};
// 查询事件
const queryClick = () => {
  getDictionaryItem();
};
// 重置事件
const resetClick = () => {
  searchForm.dataflag = undefined;
  searchForm.inputSearch = "";
  getDictionaryItem();
};
// 处理编辑事件,type: 类型1：代表顶部操作部分编辑；2：代表行内编辑
const handleEdit = (type, data) => {
  let row = undefined;
  if (type === 1) {
    // 判断是否有且仅选择了一条用户数据
    if (selectDictionaryItems.value.length !== 1) {
      ElMessage.warning(
        t("Components.System.Dictionary.Item.OnlyOneSelectEdit")
      );
      return;
    }
    row = selectDictionaryItems.value[0];
  } else if (type === 2) {
    row = data;
  }

  editData.value = {
    guid: row.guid,
    name: row.name,
    sort: row.sort,
    value: row.value,
    id: row.id,
    echo_style: row.echo_style,
    remark: row.remark,
  };
  dialogVisible.value = true;
};
// 处理回调关闭事件
const handleCloseDialog = () => {
  dialogVisible.value = false;
  editData.value = {};
  // selectDictionaryItems.value = [];
};
// 处理有效性改变事件
const handleDataflagChange = async (flag, row) => {
  await request_dictionaryitem_dataflag(row.guid, true, () => {
    row.dataflag = !row.dataflag;
  });
};
// 处理选择回调事件
const handleSelectionChange = (dictionarys) => {
  selectDictionaryItems.value = dictionarys;
};
// 处理删除
const handleDelete = async (row) => {
  ElMessageBox.confirm(
    t("Components.System.Dictionary.Item.ConfirmDeleteDicItem"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      await request_dictionaryitem_delete(row.guid, true);
      getDictionaryItem();
    })
    .catch(() => {});
};
// 批量删除
const handleDeleteBatch = async () => {
  if (selectDictionaryItems.value.length === 0) {
    ElMessage.warning(t("Components.System.Dictionary.Item.AtLeastOneDicItem"));
    return;
  }
  ElMessageBox.confirm(
    t("Components.System.Dictionary.Item.ConfirmDeleteDicItem"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const trans = {
        guids: selectDictionaryItems.value.map((item) => item.guid),
      };
      await request_dictionaryitem_delete_batch(trans, true);
      getDictionaryItem();
    })
    .catch(() => {});
};
// 处理排序改变事件
const handleSortChange = async ({ prop, order }) => {
  if (prop === "sort") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      sortSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      sortSort.value = true;
    } else {
      sortSort.value = undefined;
    }
  }
  await getDictionaryItem();
};
// 清空
const clearInfo = () => {
  selectDictionaryGuid.value = "";
  searchForm.inputSearch = "";
  searchForm.dataflag = undefined;
  tableData.value = [];
};
// 导出
const handleExport = async () => {
  fullLoading.value = true;
  const trans = {
    text: searchForm.inputSearch,
    dataflag: searchForm.dataflag,
    sortSort: sortSort.value,
    dic_guid: selectDictionaryGuid.value,
  };
  const result = await request_dictionaryitem_export(
    trans,
    true,
    () => (fullLoading.value = false)
  );
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = result;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
};
// 处理上传成功
const handleUploadSuccess = async (response) => {
  uploadRef.value.clearFiles();
  if (response.code === statusCode.Success) {
    ElMessage.success(response.message);
  } else {
    ElMessage.warning(response.message);
  }
};
watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Dictionary,
      MenuButtonCodes.System.Dictionary.DictionaryItemSearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Dictionary,
      MenuButtonCodes.System.Dictionary.DictionaryItemReset
    );
    // 处理新增
    buttonVisible.addButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Dictionary,
      MenuButtonCodes.System.Dictionary.DictionaryItemAdd
    );
    // 处理编辑
    buttonVisible.editButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Dictionary,
      MenuButtonCodes.System.Dictionary.DictionaryItemEdit
    );
    // 处理删除
    buttonVisible.deleteButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Dictionary,
      MenuButtonCodes.System.Dictionary.DictionaryItemDelete
    );
    // 处理删除行
    buttonVisible.editButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Dictionary,
      MenuButtonCodes.System.Dictionary.DictionaryItemEditLine
    );
    // 处理删除行
    buttonVisible.deleteButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Dictionary,
      MenuButtonCodes.System.Dictionary.DictionaryItemDeleteLine
    );
    // 处理有效性
    buttonVisible.dataflag = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Dictionary,
      MenuButtonCodes.System.Dictionary.DictionaryItemDataflag
    );
    // 处理导出
    buttonVisible.exportButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Dictionary,
      MenuButtonCodes.System.Dictionary.DictionaryItemExport
    );
    // 处理导入
    buttonVisible.importButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Dictionary,
      MenuButtonCodes.System.Dictionary.DictionaryItemImport
    );
  }
});
defineExpose({
  clearInfo,
  getDictionaryItem,
});
</script>
<style>
.dictionary-root-right-li .el-scrollbar__view {
  height: calc(100% - 8px);
}
</style>
<style scoped lang="scss">
.dicitem-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  .dicitem-li-search {
  }
}
</style>
