<template>
  <div class="footer-div">
    <!-- {{ $t('Common.Company') }} ©{{ new Date().getFullYear()}} -->
    <el-space>
        <span>{{ $t("Common.Company") }} ©{{ new Date().getFullYear() }}</span>
        <span>|</span>
        <span class="beian-span" @click="handleBeian">沪ICP备2024062923号-1</span>
      </el-space>
  </div>
</template>
<script setup >

const handleBeian = ()=>{
  window.open("http://beian.miit.gov.cn/")
}
</script>
<style scoped lang='scss'>
.footer-div{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  .beian-span{
    cursor: pointer;
  }
}
</style>