<template>
  <ul class="projecttask-ul" v-loading="fullLoading">
    <el-collapse-transition>
      <div v-show="isShowSearch">
        <li class="hm-search">
          <!-- 查询部分 -->
          <el-form :inline="true" :model="searchForm" label-width="100">
            <el-form-item
              :label="$t('Components.Project.ProjectTask.PlanNumber')"
            >
              <!-- <el-input
                v-model="searchForm.project_number"
                :placeholder="
                  $t('Components.Project.ProjectTask.PleaseInputProjectNumber')
                "
                clearable
              /> -->
              <el-select
                v-model="searchForm.project_guid"
                filterable
                clearable
                :placeholder="
                  t('Components.Project.ProjectTask.PleaseSelectProject')
                "
              >
                <el-option
                  v-for="item in projects"
                  :key="item.guid"
                  :label="item.project_number"
                  :value="item.guid"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('Components.Project.ProjectTask.Number')">
              <el-input
                v-model="searchForm.number"
                :placeholder="
                  $t('Components.Project.ProjectTask.PleaseInputNumber')
                "
                clearable
              />
            </el-form-item>
            <el-form-item
              :label="$t('Components.Project.ProjectTask.MainAuditor')"
            >
              <el-select
                v-model="searchForm.main_auditor"
                filterable
                clearable
                :placeholder="
                  t('Components.Project.ProjectTask.PleaseSelectMainAuditor')
                "
              >
                <el-option
                  v-for="item in users"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('Components.Project.ProjectTask.Auditor')">
              <el-select
                v-model="searchForm.auditor"
                filterable
                clearable
                :placeholder="
                  t('Components.Project.ProjectTask.PleaseSelectAuditor')
                "
              >
                <el-option
                  v-for="item in users"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <!-- <el-form-item :label="$t('Components.Project.ProjectTask.Client')">
              <el-select
                v-model="searchForm.client_guid"
                filterable
                clearable
                :placeholder="
                  t('Components.Project.ProjectTask.PleaseSelectClient')
                "
              >
                <el-option
                  v-for="item in clients"
                  :key="item.guid"
                  :label="item.name"
                  :value="item.guid"
                />
              </el-select>
            </el-form-item> -->
            <el-form-item
              :label="$t('Components.Project.ProjectTask.AuditedPartyName')"
            >
              <el-select
                v-model="searchForm.audited_party_guid"
                filterable
                clearable
                :placeholder="
                  t('Components.Project.ProjectTask.PleaseSelectAuditedParty1')
                "
              >
                <el-option
                  v-for="item in auditedPartys"
                  :key="item.guid"
                  :label="item.name"
                  :value="item.guid"
                />
              </el-select>
            </el-form-item>
            <!-- 被稽查方区域 -->
            <el-form-item
              :label="$t('Components.Project.ProjectTask.AuditedPartyArea')"
            >
              <el-select
                v-model="searchForm.audited_party_area"
                filterable
                clearable
                :placeholder="
                  t('Components.Project.ProjectTask.PleaseSelectAuditedPartyArea')
                "
              >
                <el-option
                  v-for="item in auditedPartyAreas"
                  :key="item.guid"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <!-- 被稽查方资质 -->
            <el-form-item
              :label="$t('Components.Project.ProjectTask.AuditedPartyQualification')"
            >
              <el-select
                v-model="searchForm.audited_party_qualification"
                filterable
                clearable
                :placeholder="
                  t('Components.Project.ProjectTask.PleaseSelectAuditedPartyQualification')
                "
              >
                <el-option
                  v-for="item in auditedPartyQualifications"
                  :key="item.guid"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <!-- 医院类型 -->
            <el-form-item
              :label="$t('Components.Project.ProjectTask.AuditedPartyHospitalType')"
            >
              <el-select
                v-model="searchForm.hospital_type"
                filterable
                clearable
                :placeholder="
                  t('Components.Project.ProjectTask.PleaseSelectHospitalType')
                "
              >
                <el-option
                  v-for="item in hospitalTypes"
                  :key="item.guid"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="
                $t('Components.Project.ProjectTask.ProjectTrialProgressStage')
              "
            >
              <el-select
                v-model="searchForm.trial_progress_stage"
                filterable
                clearable
                :placeholder="
                  t(
                    'Components.Project.ProjectTask.PleaseSelectTrialProgressStage1'
                  )
                "
              >
                <el-option
                  v-for="item in trailProgressStages"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="
                $t('Components.Project.ProjectTask.ImplementationDepartment')
              "
            >
              <el-input
                v-model="searchForm.implementation_department"
                :placeholder="
                  $t(
                    'Components.Project.ProjectTask.PleaseInputImplementationDepartment'
                  )
                "
                clearable
              />
            </el-form-item>
            <el-form-item
              :label="$t('Components.Project.ProjectTask.MainResearcher')"
            >
              <el-input
                v-model="searchForm.main_researcher"
                :placeholder="
                  $t('Components.Project.ProjectTask.PleaseInputMainResearcher')
                "
                clearable
              />
            </el-form-item>
            <el-form-item
              :label="t('Components.Project.ProjectTask.CreateTime')"
            >
              <el-date-picker
                v-model="searchForm.planned_audit_time"
                type="daterange"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                range-separator=" - "
                :start-placeholder="
                  t('Components.Project.ProjectTask.StartDate')
                "
                :end-placeholder="t('Components.Project.ProjectTask.EndDate')"
              />
            </el-form-item>
            <el-form-item
              :label="$t('Components.Project.ProjectTask.ProjectTaskProgress')"
            >
              <el-select
                v-model="searchForm.progress"
                filterable
                clearable
                :placeholder="
                  t(
                    'Components.Project.ProjectTask.PleaseSelectProjectTaskProgress'
                  )
                "
              >
                <el-option
                  v-for="item in projectTaskProgresses"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <!-- <el-form-item
              :label="$t('Components.Project.ProjectTask.TherapeuticArea')"
            >
              <el-input
                v-model="searchForm.therapeutic_area"
                :placeholder="
                  $t(
                    'Components.Project.ProjectTask.PleaseInputTherapeuticArea'
                  )
                "
                clearable
              />
            </el-form-item>
            <el-form-item
              :label="$t('Components.Project.ProjectTask.Indications')"
            >
              <el-input
                v-model="searchForm.indications"
                :placeholder="
                  $t('Components.Project.ProjectTask.PleaseInputIndications')
                "
                clearable
              />
            </el-form-item>
            <el-form-item
              :label="$t('Components.Project.ProjectTask.TrialStaging')"
            >
              <el-select
                v-model="searchForm.trial_staging"
                filterable
                clearable
                :placeholder="
                  t('Components.Project.ProjectTask.PleaseSelectTrialStaging')
                "
              >
                <el-option
                  v-for="item in trailStagings"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            
           
            <el-form-item
              :label="$t('Components.Project.ProjectTask.AuditType')"
            >
              <el-select
                v-model="searchForm.audit_type"
                filterable
                clearable
                :placeholder="
                  t('Components.Project.ProjectTask.PleaseSelectAuditType')
                "
              >
                <el-option
                  v-for="item in auditTypes"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('Components.Project.ProjectTask.ProjectManager')"
            >
              <el-select
                v-model="searchForm.project_manager"
                filterable
                clearable
                :placeholder="
                  t('Components.Project.ProjectTask.PleaseSelectProjectManager')
                "
              >
                <el-option
                  v-for="item in users"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item> -->

            <el-form-item label="">
              <el-button
                v-if="buttonVisible.searchButton"
                type="primary"
                @click="queryClick"
                :icon="Search"
                >{{ $t("Components.Project.ProjectTask.Search1") }}</el-button
              >
              <el-button
                v-if="buttonVisible.resetButton"
                @click="resetClick"
                :icon="RefreshLeft"
                >{{ $t("Components.Project.ProjectTask.Reset") }}</el-button
              >
            </el-form-item>
          </el-form>
        </li>
      </div>
    </el-collapse-transition>

    <li class="hm-operate">
      <!-- 操作部分 -->
      <ul class="operate-ul">
        <li class="left-li">
          <el-button
            v-if="buttonVisible.addButton"
            :style="{ color: 'white' }"
            :icon="Plus"
            :color="ButtonColor.Add"
            @click="handleAdd"
            >{{ $t("Components.Project.ProjectTask.Add") }}</el-button
          >
          <el-button
            v-if="buttonVisible.editButton"
            :disabled="!(selectProjectTasks && selectProjectTasks.length === 1)"
            :style="{ color: 'white' }"
            :icon="Edit"
            :color="ButtonColor.Edit"
            @click="handleEdit(1)"
            >{{ $t("Components.Project.ProjectTask.Edit") }}</el-button
          >
        </li>
        <li class="right-li">
          <el-space>
            <el-tooltip
              effect="dark"
              :content="
                !isShowSearch
                  ? t('Components.Project.ProjectTask.ShowSearch')
                  : t('Components.Project.ProjectTask.HideSearch')
              "
              placement="top"
            >
              <el-button
                type=""
                circle
                :icon="Search"
                size="small"
                @click="() => (isShowSearch = !isShowSearch)"
              ></el-button>
            </el-tooltip>
          </el-space>
        </li>
      </ul>
    </li>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <!-- :cell-class-name="handleCellClassName" -->
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          row-key="guid"
          @selection-change="handleSelectionChange"
          @sort-change="handleSortChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            prop="project_number"
            min-width="200"
            :label="$t('Components.Project.ProjectTask.ProjectNumber')"
            sortable
          >
          </el-table-column>
          <el-table-column
            min-width="200"
            :label="$t('Components.Project.ProjectTask.Number')"
            sortable
          >
            <template #default="scope">
              <el-text
                type="primary"
                class="text-projectnumber"
                @click="handleEdit(3, scope.row)"
                >{{ scope.row.number }}</el-text
              >
            </template>
          </el-table-column>
          <el-table-column
            width="140"
            :label="$t('Components.Project.ProjectTask.MainAuditor')"
          >
            <template #default="scope">
              <el-tag
                v-for="item in scope.row.mainAuditors"
                :key="item.user_guid"
                :style="{ margin: '0 8px 8px 0' }"
                >{{ item.user_name }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            width="140"
            :label="$t('Components.Project.ProjectTask.Auditor')"
          >
            <template #default="scope">
              <el-tag
                v-for="item in scope.row.auditors"
                :key="item.user_guid"
                :style="{ margin: '0 8px 8px 0' }"
                >{{ item.user_name }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="audited_party_name"
            width="140"
            :label="$t('Components.Project.ProjectTask.AuditedPartyName')"
          >
            <template #default="scope">
              <el-text>{{ scope.row.audited_party_name }}</el-text>
            </template>
          </el-table-column>
          <el-table-column
            prop="audited_party_area"
            width="140"
            :label="$t('Components.Project.ProjectTask.AuditedPartyArea')"
          >
            <template #default="scope">
              <div v-if="scope.row.audited_party_area">
                <el-text
                  v-if="
                    computeDicItemEchoStyle(
                      Dics.System.AuditedPartyArea,
                      scope.row.audited_party_area
                    ) === DictionaryItemEchoStyle.Default
                  "
                  >{{
                    computeDicItem(
                      Dics.System.AuditedPartyArea,
                      scope.row.audited_party_area
                    )
                  }}</el-text
                >
                <el-tag
                  v-else-if="computeDicItem(
                      Dics.System.AuditedPartyArea,
                      scope.row.audited_party_area
                    )"
                  :type="
                    computeDicItemEchoStyle(
                      Dics.System.AuditedPartyArea,
                      scope.row.audited_party_area
                    )
                  "
                  >{{
                    computeDicItem(
                      Dics.System.AuditedPartyArea,
                      scope.row.audited_party_area
                    )
                  }}</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="audited_party_qualification"
            width="140"
            :label="
              $t('Components.Project.ProjectTask.AuditedPartyQualification')
            "
          >
            <template #default="scope">
              <div v-if="scope.row.audited_party_qualification">
                <el-text
                  v-if="
                    computeDicItemEchoStyle(
                      Dics.System.AuditedPartyQualification,
                      scope.row.audited_party_qualification
                    ) === DictionaryItemEchoStyle.Default
                  "
                  >{{
                    computeDicItem(
                      Dics.System.AuditedPartyQualification,
                      scope.row.audited_party_qualification
                    )
                  }}</el-text
                >
                <el-tag
                  v-else
                  :type="
                    computeDicItemEchoStyle(
                      Dics.System.AuditedPartyQualification,
                      scope.row.audited_party_qualification
                    )
                  "
                  >{{
                    computeDicItem(
                      Dics.System.AuditedPartyQualification,
                      scope.row.audited_party_qualification
                    )
                  }}</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="hospital_type"
            width="140"
            :label="$t('Components.Project.ProjectTask.HospitalType')"
          >
            <template #default="scope">
              <div v-if="scope.row.hospital_type">
                <el-text
                  v-if="
                    computeDicItemEchoStyle(
                      Dics.System.HospitalType,
                      scope.row.hospital_type
                    ) === DictionaryItemEchoStyle.Default
                  "
                  >{{
                    computeDicItem(
                      Dics.System.HospitalType,
                      scope.row.hospital_type
                    )
                  }}</el-text
                >
                <el-tag
                  v-else
                  :type="
                    computeDicItemEchoStyle(
                      Dics.System.HospitalType,
                      scope.row.hospital_type
                    )
                  "
                  >{{
                    computeDicItem(
                      Dics.System.HospitalType,
                      scope.row.hospital_type
                    )
                  }}</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="trial_progress_stage"
            width="140"
            :label="
              $t('Components.Project.ProjectTask.ProjectTrialProgressStage')
            "
          >
            <template #default="scope">
              <el-text>{{ scope.row.trial_progress_stage }}</el-text>
            </template>
          </el-table-column>
          <el-table-column
            prop="implementation_department"
            width="140"
            :label="
              $t('Components.Project.ProjectTask.ImplementationDepartment')
            "
          >
            <template #default="scope">
              <el-text>{{ scope.row.implementation_department }}</el-text>
            </template>
          </el-table-column>
          <el-table-column
            prop="main_researcher"
            width="140"
            :label="$t('Components.Project.ProjectTask.MainResearcher')"
          >
            <template #default="scope">
              <el-text>{{ scope.row.main_researcher }}</el-text>
            </template>
          </el-table-column>
          <el-table-column
            prop="audit_address"
            width="140"
            :label="$t('Components.Project.ProjectTask.AuditAddress')"
          >
            <template #default="scope">
              <el-text>{{ scope.row.audit_address }}</el-text>
            </template>
          </el-table-column>
          <el-table-column
            prop="planned_audit_starttime"
            width="160"
            sortable
            :label="$t('Components.Project.ProjectTask.PlannedAuditStartTime')"
          >
          </el-table-column>
          <el-table-column
            prop="planned_audit_endtime"
            width="160"
            sortable
            :label="$t('Components.Project.ProjectTask.PlannedAuditEndTime')"
          >
          </el-table-column>
          <el-table-column
            prop="planned_reporting_date"
            width="160"
            sortable
            :label="$t('Components.Project.ProjectTask.PlannedReportingDate')"
          >
          </el-table-column>
          <el-table-column
            prop="planned_capa_date"
            width="160"
            sortable
            :label="$t('Components.Project.ProjectTask.PlannedCAPADate')"
          >
          </el-table-column>
          <el-table-column
            prop="createtime"
            width="160"
            sortable
            :label="$t('Components.Project.ProjectTask.CreateTime')"
          >
          </el-table-column>
          <el-table-column
            prop="cancel_reason"
            width="160"
            :label="$t('Components.Project.ProjectList.CancelReason')"
          />
          <el-table-column
            prop="remark"
            width="160"
            :label="$t('Components.Project.ProjectTask.Remark')"
          />
          <el-table-column
            :label="$t('Components.Project.ProjectTask.ProjectTaskLog')"
            width="60"
            fixed="right"
            align="center"
          >
            <template #default="scope">
              <el-popover
                v-if="
                  scope.row.projectTaskLogs &&
                  scope.row.projectTaskLogs.length > 0
                "
                placement="left"
                :width="300"
                trigger="hover"
                title=""
              >
                <template #reference>
                  <span class="icon iconfont icon-progress"> </span>
                </template>
                <ProjectTaskLogPopover :data="scope.row" />
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            prop="progress"
            width="120"
            fixed="right"
            :label="$t('Components.Project.ProjectTask.ProjectTaskProgress')"
          >
            <template #default="scope">
              <el-text
                v-if="scope.row.progress === ProjectTaskProgress.ToBeAllocated"
                >{{
                  t("Components.Project.ProjectTask.ToBeAllocated")
                }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectTaskProgress.Ready"
                >{{ t("Components.Project.ProjectTask.Ready") }}</el-text
              >
              <el-text
                v-else-if="
                  scope.row.progress === ProjectTaskProgress.AuditImplementation
                "
                >{{
                  t("Components.Project.ProjectTask.AuditImplementation")
                }}</el-text
              >
              <el-text
                v-else-if="
                  scope.row.progress === ProjectTaskProgress.ReportWriting
                "
                >{{
                  t("Components.Project.ProjectTask.ReportWriting")
                }}</el-text
              >
              <el-text
                v-else-if="
                  scope.row.progress === ProjectTaskProgress.SubmitForReview
                "
                >{{
                  t("Components.Project.ProjectTask.SubmitForReview")
                }}</el-text
              >
              <el-text
                v-else-if="
                  scope.row.progress === ProjectTaskProgress.UnderReview
                "
                >{{ t("Components.Project.ProjectTask.UnderReview") }}</el-text
              >
              <el-text
                v-else-if="
                  scope.row.progress === ProjectTaskProgress.ReviewedAndApproved
                "
                >{{
                  t("Components.Project.ProjectTask.ReviewedAndApproved")
                }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectTaskProgress.Cancel"
                >{{ t("Components.Project.ProjectTask.Cancel") }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectTaskProgress.Complete"
                >{{ t("Components.Project.ProjectTask.Complete") }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectTaskProgress.Rewrite"
                >{{ t("Components.Project.ProjectTask.Rewrite") }}</el-text
              >
              <el-text
                v-else-if="
                  scope.row.progress === ProjectTaskProgress.CAPAFinalize
                "
                >{{ t("Components.Project.ProjectTask.CAPAFinalize") }}</el-text
              >
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('Components.Project.ProjectTask.Operate')"
            width="300"
            fixed="right"
          >
            <template #default="scope">
              <el-space wrap>
                <!-- 编辑按钮 -->
                <el-button
                  v-if="
                    buttonVisible.editButtonLine &&
                    scope.row.progress !== ProjectTaskProgress.Cancel &&
                    scope.row.progress !== ProjectTaskProgress.Complete
                  "
                  :style="{ color: 'white' }"
                  :color="ButtonColor.Edit"
                  size="small"
                  @click="handleEdit(2, scope.row)"
                  >{{ $t("Components.Project.ProjectTask.Edit") }}</el-button
                >
                <el-button
                  v-if="buttonVisible.infoButtonLine"
                  :style="{ color: 'white' }"
                  :color="ButtonColor.Info"
                  @click="handleEdit(3, scope.row)"
                  size="small"
                  >{{ $t("Components.Project.ProjectTask.Info") }}</el-button
                >
                <!-- 派遣人员按钮 -->
                <el-button
                  v-if="buttonVisible.dispatchButtonLine"
                  :style="{ color: 'white' }"
                  :color="ButtonColor.Dispatch"
                  size="small"
                  @click="handleDispatchUser(scope.row)"
                  >{{
                    $t("Components.Project.ProjectTask.Dispatch")
                  }}</el-button
                >
                <!-- 稽查小结按钮 -->
                <el-button
                  v-if="buttonVisible.auditSummaryLine"
                  :style="{ color: 'white' }"
                  :color="ButtonColor.AuditDispatch"
                  @click="handleAuditSummary(scope.row)"
                  size="small"
                  >{{
                    $t("Components.Project.ProjectTask.AuditSummary")
                  }}</el-button
                >
                <!-- 稽查发现 -->
                <el-button
                  v-if="buttonVisible.discoveryLine && !computedIsNoReport(scope.row.audit_type)"
                  :style="{ color: 'white' }"
                  :color="ButtonColor.Discovery"
                  @click="handleDiscovery(scope.row)"
                  size="small"
                  >{{
                    $t("Components.Project.ProjectTask.DiscoveryButton")
                  }}</el-button
                >
                <!-- 报告 -->
                <el-button
                  v-if="buttonVisible.reportLine&& !computedIsNoReport(scope.row.audit_type)"
                  :style="{ color: 'white' }"
                  :color="ButtonColor.Report"
                  size="small"
                  @click="handleReport(scope.row)"
                  >{{ $t("Components.Project.ProjectTask.Report") }}</el-button
                >
                <!-- 稽查证明 -->
                <el-button
                  v-if="buttonVisible.auditCertificateLine&& !computedIsNoReport(scope.row.audit_type)"
                  :style="{ color: 'white' }"
                  :color="ButtonColor.AuditProve"
                  size="small"
                  @click="handleExportAuditCertificate(scope.row)"
                  >{{
                    $t("Components.Project.ProjectTask.AuditCertificate")
                  }}</el-button
                >
                <!-- 导出报告 -->
                <el-button
                  v-if="buttonVisible.exportReportLine&& !computedIsNoReport(scope.row.audit_type)"
                  :style="{ color: 'white' }"
                  :color="ButtonColor.Export"
                  size="small"
                  @click="handleExport(scope.row)"
                  >{{
                    $t("Components.Project.ProjectTask.ExportReport")
                  }}</el-button
                >
                <!-- CAPA报告 -->
                <el-button
                  v-if="buttonVisible.exportCAPALine&& !computedIsNoReport(scope.row.audit_type)"
                  :style="{ color: 'white' }"
                  :color="ButtonColor.AuditProve"
                  size="small"
                  @click="handleExportCAPA(scope.row)"
                  >{{
                    $t("Components.Project.ProjectTask.CAPAReport")
                  }}</el-button
                >
                <!-- 报告附件 -->
                <el-button
                  v-if="buttonVisible.reportAppendixLine&& !computedIsNoReport(scope.row.audit_type)"
                  :style="{ color: 'white' }"
                  :color="ButtonColor.ReportAppendix"
                  size="small"
                  @click="handleReportAppendix(scope.row)"
                  >{{
                    $t("Components.Project.ProjectTask.ReportAppendix1")
                  }}</el-button
                >
                <!-- 完成 -->
                <el-button
                  v-if="
                    buttonVisible.completeLine &&
                    (scope.row.progress ===
                      ProjectTaskProgress.ReviewedAndApproved ||
                      scope.row.progress === ProjectTaskProgress.CAPAFinalize ||
                      (
                        computedIsNoReport(scope.row.audit_type) && 
                        (
                          (scope.row.progress === ProjectTaskProgress.AuditImplementation && !scope.row.is_capa) ||
                          (scope.row.progress === ProjectTaskProgress.CAPAFinalize && scope.row.is_capa)
                        )
                      )
                    )
                  "
                  :style="{ color: 'white' }"
                  :color="ButtonColor.Complete"
                  size="small"
                  @click="handleComplete(scope.row)"
                  >{{
                    $t("Components.Project.ProjectTask.Complete")
                  }}</el-button
                >
                <!-- 取消 -->
                <el-button
                  v-if="
                    (buttonVisible.cancelLine &&
                      scope.row.progress ===
                        ProjectTaskProgress.ToBeAllocated) ||
                    scope.row.progress === ProjectTaskProgress.Ready
                  "
                  :style="{ color: 'white' }"
                  :color="ButtonColor.Delete"
                  @click="handleCancel(scope.row)"
                  size="small"
                  >{{ $t("Components.Project.ProjectTask.Cancel1") }}</el-button
                >
                <!-- 返改 -->
                <el-button
                  v-if="
                    buttonVisible.rewriteLine &&
                    scope.row.progress ===
                      ProjectTaskProgress.ReviewedAndApproved
                  "
                  :style="{ color: 'white' }"
                  :color="ButtonColor.Rewrite"
                  @click="handleRewrite(scope.row)"
                  size="small"
                  >{{ $t("Components.Project.ProjectTask.Rewrite") }}</el-button
                >
                <!-- CAPA定稿 -->
                <el-button
                  v-if="
                   ( buttonVisible.capaFinalizeLine &&
                    scope.row.progress ===
                      ProjectTaskProgress.ReviewedAndApproved ) ||
                      (computedIsNoReport &&  scope.row.progress ===
                      ProjectTaskProgress.AuditImplementation && scope.row.is_capa)
                  "
                  :style="{ color: 'white' }"
                  :color="ButtonColor.Discovery"
                  @click="handleCAPAFinalize(scope.row)"
                  size="small"
                  >{{
                    $t("Components.Project.ProjectTask.CAPAFinalize")
                  }}</el-button
                >
                <!-- 重启 -->
                <el-button
                  v-if="
                    buttonVisible.restartLine &&
                    scope.row.progress === ProjectTaskProgress.Complete
                  "
                  :style="{ color: 'white' }"
                  :color="ButtonColor.Restart"
                  @click="handleRestart(scope.row)"
                  size="small"
                  >{{ $t("Components.Project.ProjectTask.Restart") }}</el-button
                >
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </li>
    </el-scrollbar>

    <li class="hm-page">
      <el-pagination
        small
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="AllPageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </li>
  </ul>
  <ProjectTaskModify
    v-if="dialogVisible"
    @handleCloseDialog="handleCloseDialog"
    :editData="editData"
    @getProjectTaskPage="getProjectTaskPage"
    :projects="projects"
    :auditedPartys="auditedPartys"
    :trailProgressStages="trailProgressStages"
    :users="users"
    :isView="isView"
    :hospitalTypes="hospitalTypes"
  />
  <DispatchUser
    v-if="dialogVisibleDiapatch"
    @handleCloseDialog="handleCloseDialog"
    :projectTaskGuid="currentProjectTaskGuid"
    :users="users"
    :projectTask="currentProjectTask"
    @getProjectTaskPage="getProjectTaskPage"
  />
  <AuditSummary
    v-if="dialogVisibleAuditSummary"
    @handleCloseDialog="() => (dialogVisibleAuditSummary = false)"
    :projectTaskGuid="currentProjectTaskGuid"
    :projectTask="currentProjectTask"
    @getProjectTaskPage="getProjectTaskPage"
  />
</template>
<script setup>
import { reactive, ref, markRaw, onMounted, watchEffect, computed } from "vue";
import {
  Plus,
  RefreshLeft,
  Edit,
  Search,
  WarnTriangleFilled,
} from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";
import { AllPageSize, ButtonColor, DefaultPageSize } from "@/consts/frame";
import {
  request_client_page,
  request_user_page,
  request_auditedparty_page,
  request_projecttask_page,
  request_project_all,
  request_projecttask_progress,
  request_projecttask_report_export,
  request_projecttask_auditcertificate_export,
  request_projecttask_capa_export,
} from "@/api/apis";
import ProjectTaskModify from "@/components/Project/ProjectTask/ProjectTaskModify.vue";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";
import { useStore } from "@/store/index";
import { Dics } from "@/consts/dic";
import { ProjectTaskProgress, ProjectTaskOperateType } from "@/consts/enum";
import DispatchUser from "@/components/Project/ProjectTask/DispatchUser.vue";
import AuditSummary from "@/components/Project/ProjectTask/AuditSummary.vue";
import { router } from "@/router";
import { useRoute } from "vue-router";
import ProjectTaskLogPopover from "@/components/Project/ProjectTask/ProjectTaskLogPopover.vue";
import { DictionaryItemEchoStyle } from "@/consts/enum";

const store = useStore();
const { t } = useI18n();
const route = useRoute();
const projectTaskGuid = ref((route.query && route.query.projectid) ?? "");
// 语言
const searchForm = reactive({
  project_number: "", // 项目编号
  project_guid: "", // 项目guid
  number: "", // 任务编号
  main_auditor: undefined, // 主稽查员
  auditor: undefined, // 稽查员
  client_guid: undefined, // 委托方
  therapeutic_area: "", // 治疗领域
  indications: "", // 适应症
  trial_staging: undefined, // 试验分期
  trial_progress_stage: undefined, // 试验进行阶段
  audited_party_guid: undefined, // 被稽查方
  implementation_department: "", // 实施科室
  main_researcher: "", // 主要研究者
  audit_type: undefined, // 稽查类型
  project_manager: undefined, // 项目负责人
  planned_audit_time: undefined, // 时间区间
  progress: undefined, // 进度
  audited_party_area: undefined, // 被稽查方区域
  audited_party_qualification: undefined, // 被稽查方资质
  hospital_type: undefined, // 医院类型
});
const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  addButton: false,
  editButton: false,
  editButtonLine: false,
  infoButtonLine: false,
  dispatchButtonLine: false,
  auditSummaryLine: false,
  discoveryLine: false,
  reportLine: false,
  auditCertificateLine: false,
  exportCAPALine: false,
  exportReportLine: false,
  reportAppendixLine: false,
  completeLine: false,
  cancelLine: false,
  restartLine: false,
  rewriteLine: false,
  capaFinalizeLine: false,
});
// 当前页码
const currentPage = ref(1);
// 页码大小
const pageSize = ref(DefaultPageSize);
// 表格数据
const tableData = ref([]);
// 表格加载
const tableLoading = ref(false);
// 总数量
const total = ref(0);
// 编辑的数据
const editData = ref(undefined);
// 弹窗是否可见
const dialogVisible = ref(false);
// 派遣人员弹窗是否可见
const dialogVisibleDiapatch = ref(false);
// 稽查小结弹窗是否可见
const dialogVisibleAuditSummary = ref(false);
// 选择的项目任务
const selectProjectTasks = ref([]);
// 项目任务进度
const projectTaskProgresses = ref([
  {
    label: t("Components.Project.ProjectTask.ToBeAllocated"),
    value: ProjectTaskProgress.ToBeAllocated,
  },
  {
    label: t("Components.Project.ProjectTask.Ready"),
    value: ProjectTaskProgress.Ready,
  },
  {
    label: t("Components.Project.ProjectTask.AuditImplementation"),
    value: ProjectTaskProgress.AuditImplementation,
  },
  {
    label: t("Components.Project.ProjectTask.ReportWriting"),
    value: ProjectTaskProgress.ReportWriting,
  },
  {
    label: t("Components.Project.ProjectTask.SubmitForReview"),
    value: ProjectTaskProgress.SubmitForReview,
  },
  {
    label: t("Components.Project.ProjectTask.UnderReview"),
    value: ProjectTaskProgress.UnderReview,
  },
  {
    label: t("Components.Project.ProjectTask.ReviewedAndApproved"),
    value: ProjectTaskProgress.ReviewedAndApproved,
  },
  {
    label: t("Components.Project.ProjectTask.Cancel"),
    value: ProjectTaskProgress.Cancel,
  },
  {
    label: t("Components.Project.ProjectTask.Complete"),
    value: ProjectTaskProgress.Complete,
  },
  {
    label: t("Components.Project.ProjectTask.Rewrite"),
    value: ProjectTaskProgress.Rewrite,
  },
  {
    label: t("Components.Project.ProjectTask.CAPAFinalize"),
    value: ProjectTaskProgress.CAPAFinalize,
  },
]);
// 全屏加载
const fullLoading = ref(false);
// 客户集合
const clients = ref([]);
// 用户集合
const users = ref([]);
// 被稽查方
const auditedPartys = ref([]);
// 项目
const projects = ref([]);
// 是否显示搜索
const isShowSearch = ref(true);
// 试验分期
const trailStagings = ref([]);
// 试验进展阶段
const trailProgressStages = ref([]);
// 稽查类型
const auditTypes = ref([]);
// 医院类型字典集合
const hospitalTypes = ref([]);
// 被稽查方区域字典集合
const auditedPartyAreas = ref([]);
// 被稽查方资质字典集合
const auditedPartyQualifications = ref([]);
// 当前项目任务guid
const currentProjectTaskGuid = ref("");
// 当前项目任务的对象
const currentProjectTask = ref(undefined);

const projectNumberSort = ref(undefined)
const projectTaskNumberSort = ref(undefined)
const createTimeSort = ref(undefined);
const plannedAuditStartTimeSort = ref(undefined);
const plannedAuditEndTimeSort = ref(undefined);
const plannedReportingDateSort = ref(undefined);
const plannedCAPADateSort = ref(undefined);

const isView = ref(false);
// 处理字典数据
const computeDicItem = computed(() => (dic, data) => {
  switch (dic) {
    case Dics.System.HospitalType: // 医院类型
      if (hospitalTypes.value) {
        const findData = hospitalTypes.value.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.AuditedPartyArea: // 被稽查方区域
      if (auditedPartyAreas.value) {
        const findData = auditedPartyAreas.value.find(
          (item) => item.value == data
        );
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.AuditedPartyQualification: // 被稽查方资质
      if (auditedPartyQualifications.value) {
        const findData = auditedPartyQualifications.value.find(
          (item) => item.value == data
        );
        if (findData) {
          return findData.name;
        }
      }
      break;
  }
});

// 处理回显样式
const computeDicItemEchoStyle = computed(() => (dic, data) => {
  let echoStyle = "";
  switch (dic) {
    case Dics.System.HospitalType: // 医院类型
      if (hospitalTypes.value) {
        const findData = hospitalTypes.value.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
    case Dics.System.AuditedPartyArea: // 被稽查方区域
      if (auditedPartyAreas.value) {
        const findData = auditedPartyAreas.value.find(
          (item) => item.value == data
        );
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
    case Dics.System.AuditedPartyQualification: // 被稽查方资质
      if (auditedPartyQualifications.value) {
        const findData = auditedPartyQualifications.value.find(
          (item) => item.value == data
        );
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
  }
  return !echoStyle || echoStyle === DictionaryItemEchoStyle.Primary
    ? ""
    : echoStyle;
});
// 计算是否是不需要报告的
const computedIsNoReport = computed(()=>(auditType)=>{
  const findAuditType = auditTypes.value.find(item=>item.value === auditType)
  if(findAuditType.remark === 'NR'){ // 代表不需要报告
    return true
  }
  return false
})
onMounted(async () => {
  searchForm.progress =
    route.query && route.query.progress
      ? Number(route.query.progress)
      : undefined;
  await getProjectAll();
  getClientsAll();
  getProjectTaskPage();
  getUserAll();
  getAuditedPartyAll();
});
// 分页查询项目任务
const getProjectTaskPage = async () => {
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    text: searchForm.inputSearch,
    project_number: searchForm.project_number,
    project_guid: searchForm.project_guid,
    number: searchForm.number,
    client_guid: searchForm.client_guid,
    main_auditor: searchForm.main_auditor,
    auditor: searchForm.auditor,
    therapeutic_area: searchForm.therapeutic_area,
    indications: searchForm.indications,
    trial_staging: searchForm.trial_staging,
    trial_progress_stage: searchForm.trial_progress_stage,
    audited_party_guid: searchForm.audited_party_guid,
    implementation_department: searchForm.implementation_department,
    main_researcher: searchForm.main_researcher,
    audit_type: searchForm.audit_type,
    project_manager: searchForm.project_manager,
    start_time:
      searchForm.planned_audit_time && searchForm.planned_audit_time.length > 0
        ? searchForm.planned_audit_time[0]
        : undefined,
    end_time:
      searchForm.planned_audit_time && searchForm.planned_audit_time.length > 1
        ? searchForm.planned_audit_time[1]
        : undefined,
    progress: searchForm.progress,
    createTimeSort: createTimeSort.value,
    plannedAuditStartTimeSort: plannedAuditStartTimeSort.value,
    plannedAuditEndTimeSort: plannedAuditEndTimeSort.value,
    plannedReportingDateSort: plannedReportingDateSort.value,
    plannedCAPADateSort: plannedCAPADateSort.value,
    audited_party_area: searchForm.audited_party_area,
    audited_party_qualification: searchForm.audited_party_qualification,
    hospital_type: searchForm.hospital_type
  };
  tableLoading.value = true;
  const result = await request_projecttask_page(
    trans,
    () => (tableLoading.value = false)
  );
  total.value = result.total;
  tableData.value = result.rows;
  selectProjectTasks.value = [];
  editData.value = undefined;
};
// 获取所有委托方
const getClientsAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
  };
  const result = await request_client_page(trans);
  if (result.total > 0) {
    clients.value = result.rows;
  } else {
    clients.value = [];
  }
};
// 获取所有被稽查方
const getAuditedPartyAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
  };
  const result = await request_auditedparty_page(trans);
  if (result.total > 0) {
    auditedPartys.value = result.rows;
  } else {
    auditedPartys.value = [];
  }
};
// 获取所有的项目
const getProjectAll = async () => {
  const trans = {
    progresses: [],
    is_audit_service_type: true,
  };
  const result = await request_project_all(trans);
  if (result && result.length > 0) {
    projects.value = result;
  } else {
    projects.value = [];
  }
  if (projectTaskGuid.value) {
    searchForm.project_guid = projectTaskGuid.value;
  }
};
// 获取所有用户
const getUserAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
    is_system: false,
  };
  const result = await request_user_page(trans);
  if (result.total > 0) {
    users.value = result.rows.map((item) => {
      return { label: item.name, value: item.guid };
    });
  } else {
    users.value = [];
  }
};

// 查询事件
const queryClick = () => {
  getProjectTaskPage();
};
// 重置事件
const resetClick = () => {
  searchForm.project_guid = "";
  searchForm.project_number = ""; // 项目编号
  searchForm.number = ""; // 任务编号
  searchForm.client_guid = undefined; // 委托方
  (searchForm.main_auditor = undefined), // 主稽查员
    (searchForm.auditor = undefined), // 稽查员
    (searchForm.therapeutic_area = ""); // 治疗领域
  searchForm.indications = ""; // 适应症
  searchForm.trial_staging = undefined; // 试验分期
  searchForm.trial_progress_stage = undefined; // 试验进行阶段
  searchForm.audited_party_guid = undefined; // 被稽查方
  searchForm.implementation_department = ""; // 实施科室
  searchForm.main_researcher = ""; // 主要研究者
  searchForm.audit_type = undefined; // 稽查类型
  searchForm.project_manager = undefined; // 项目负责人
  searchForm.planned_audit_time = undefined; // 计划稽查时间
  searchForm.actual_audit_time = undefined; // 实际稽查时间
  searchForm.progress = undefined; // 进度
  searchForm.audited_party_area = undefined
  searchForm.audited_party_qualification = undefined
  searchForm.hospital_type = undefined

  getProjectTaskPage();
};
// 处理页码大小改变事件
const handleSizeChange = (size) => {
  pageSize.value = size;
  getProjectTaskPage();
};
// 处理当前页改变事件
const handleCurrentChange = (index) => {
  currentPage.value = index;
  getProjectTaskPage();
};
// 处理排序改变事件
const handleSortChange = async ({ prop, order }) => {
  projectNumberSort.value = undefined
  projectTaskNumberSort.value = undefined
  createTimeSort.value = undefined;
  plannedAuditStartTimeSort.value = undefined;
  plannedAuditEndTimeSort.value = undefined;
  plannedReportingDateSort.value = undefined;
  plannedCAPADateSort.value = undefined;

  if (prop === "project_number") {
    projectNumberSort.value = undefined;
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      projectNumberSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      projectNumberSort.value = true;
    } else {
      projectNumberSort.value = undefined;
    }
  }
  if (prop === "number") {
    projectTaskNumberSort.value = undefined;
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      projectTaskNumberSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      projectTaskNumberSort.value = true;
    } else {
      projectTaskNumberSort.value = undefined;
    }
  }
  if (prop === "createtime") {
    // 创建时间
    createTimeSort.value = undefined;
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      createTimeSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      createTimeSort.value = true;
    } else {
      createTimeSort.value = undefined;
    }
  }
  if (prop === "planned_audit_starttime") {
    // 计划稽查开始时间
    plannedAuditStartTimeSort.value = undefined;
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      plannedAuditStartTimeSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      plannedAuditStartTimeSort.value = true;
    } else {
      plannedAuditStartTimeSort.value = undefined;
    }
  }
  if (prop === "planned_audit_endtime") {
    // 计划稽查结束时间
    plannedAuditEndTimeSort.value = undefined;
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      plannedAuditEndTimeSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      plannedAuditEndTimeSort.value = true;
    } else {
      plannedAuditEndTimeSort.value = undefined;
    }
  }
  if (prop === "planned_reporting_date") {
    // 计划报告时间
    plannedReportingDateSort.value = undefined;
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      plannedReportingDateSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      plannedReportingDateSort.value = true;
    } else {
      plannedReportingDateSort.value = undefined;
    }
  }
  if (prop === "planned_capa_date") {
    // 计划CAPA日期
    plannedCAPADateSort.value = undefined;
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      plannedCAPADateSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      plannedCAPADateSort.value = true;
    } else {
      plannedCAPADateSort.value = undefined;
    }
  }
  await getProjectTaskPage();
};
// 处理新增
const handleAdd = () => {
  dialogVisible.value = true;
  if (searchForm.project_guid) {
    editData.value = {
      project_guid: searchForm.project_guid,
    };
  }
};
// 处理编辑事件
const handleEdit = (type, data) => {
  let row = undefined;
  if (type === 1) {
    // 判断是否有且仅选择了一条用户数据
    if (selectProjectTasks.value.length !== 1) {
      ElMessage.warning(t("Components.Project.ProjectTask.OnlyOneSelectEdit"));
      return;
    }
    row = selectProjectTasks.value[0];
  } else if (type === 2) {
    row = data;
  } else {
    isView.value = true;
    row = data;
  }

  // // 判断项目负责人是否存在下拉框中
  // const  findProjectManager = clients.value.find(item=>item.guid === row.project_manager)
  // const  findProjectAssistance = clients.value.find(item=>item.guid === row.project_assistance)

  editData.value = {
    guid: row.guid,
    project_guid: row.project_guid,
    audited_party_guid: row.audited_party_guid,
    project_stage: row.project_stage,
    implementation_department: row.implementation_department,
    main_researcher: row.main_researcher,
    center_number: row.center_number,
    audit_address: row.audit_address,
    planned_audit_time: [
      row.planned_audit_starttime,
      row.planned_audit_endtime,
    ],
    actual_audit_time: [row.actual_audit_starttime, row.actual_audit_endtime],
    planned_reporting_date: row.planned_reporting_date,
    completed_reporting_date: row.completed_reporting_date,
    planned_capa_date: row.planned_capa_date,
    actual_capa_date: row.actual_capa_date,
    customer_project_manager: row.customer_project_manager,
    customer_inspector: row.customer_inspector,
    progress: row.progress,
    hospital_type: row.hospital_type,
    remark: row.remark
  };
  dialogVisible.value = true;
};
// 处理回调关闭事件
const handleCloseDialog = (type) => {
  if (type === 1) {
    // 项目任务编辑/查看
    dialogVisible.value = false;
  } else if (type === 2) {
    // 派遣
    dialogVisibleDiapatch.value = false;
  } else if (type === 3) {
    // 稽查小结
    dialogVisibleAuditSummary.value = false;
  }
  currentProjectTaskGuid.value = "";
  currentProjectTask.value = undefined;
  editData.value = undefined;
  isView.value = false;
};
// 处理选择回调事件
const handleSelectionChange = (data) => {
  selectProjectTasks.value = data;
};
// 处理派遣
const handleDispatchUser = (row) => {
  currentProjectTaskGuid.value = row.guid;
  dialogVisibleDiapatch.value = true;
  currentProjectTask.value = row;
};
// 稽查小结
const handleAuditSummary = (row) => {
  currentProjectTaskGuid.value = row.guid;
  dialogVisibleAuditSummary.value = true;
  currentProjectTask.value = row;
};
// 跳转到发现报告
const handleDiscovery = (row) => {
  router.push({ path: "/project/discovery", query: { guid: row.guid } });
};
// 取消
const handleCancel = (row) => {

  ElMessageBox.prompt(t('Components.Project.ProjectList.InputCancelReason'), t('Components.Project.ProjectList.Tip'),{
    confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
    cancelButtonClass: t("Common.ElMessageBox.CancelText"),
    inputType: 'textarea',
    inputPattern: /^.+$/,
    inputErrorMessage: t('Components.Project.ProjectList.InputCancelReason')
  })
  .then(async ({ value }) => {
    const trans = {
        operate_type: ProjectTaskOperateType.Cancel,
        cancel_reason: value,
      };
      await request_projecttask_progress(row.guid, trans);
      getProjectTaskPage();
  })
  .catch(() => {})


  // ElMessageBox.confirm(
  //   t("Components.Project.ProjectTask.ConfirmCancelProjectTask"),
  //   t("Common.ElMessageBox.OperateConfirm"),
  //   {
  //     confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
  //     cancelButtonText: t("Common.ElMessageBox.CancelText"),
  //     type: "warning",
  //     icon: markRaw(WarnTriangleFilled),
  //   }
  // )
  //   .then(async () => {
  //     const trans = {
  //       operate_type: ProjectTaskOperateType.Cancel,
  //     };
  //     await request_projecttask_progress(row.guid, trans);
  //     getProjectTaskPage();
  //   })
  //   .catch(() => {});
};
// 完成
const handleComplete = (row) => {
  ElMessageBox.confirm(
    t("Components.Project.ProjectTask.ConfirmCompleteProjectTask"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const trans = {
        operate_type: ProjectTaskOperateType.Complete,
      };
      await request_projecttask_progress(row.guid, trans);
      getProjectTaskPage();
    })
    .catch(() => {});
};
// 返改
const handleRewrite = (row) => {
  ElMessageBox.confirm(
    t("Components.Project.ProjectTask.ConfirmRewriteProjectTask"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const trans = {
        operate_type: ProjectTaskOperateType.Rewrite,
      };
      await request_projecttask_progress(row.guid, trans);
      getProjectTaskPage();
    })
    .catch(() => {});
};
// CAPA定稿
const handleCAPAFinalize = (row) => {
  ElMessageBox.confirm(
    t("Components.Project.ProjectTask.ConfirmCAPAFinalizeProjectTask"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const trans = {
        operate_type: ProjectTaskOperateType.CAPAFinalize,
      };
      await request_projecttask_progress(row.guid, trans);
      getProjectTaskPage();
    })
    .catch(() => {});
};
// 重启
const handleRestart = (row) => {
  ElMessageBox.confirm(
    t("Components.Project.ProjectTask.ConfirmRestartProjectTask"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const trans = {
        operate_type: ProjectTaskOperateType.Restart,
      };
      await request_projecttask_progress(row.guid, trans);
      getProjectTaskPage();
    })
    .catch(() => {});
};
// 处理报告点击事件
const handleReport = async (row) => {
  router.push({ path: "/project/generatereport", query: { guid: row.guid } });
};
// 导出报告
const handleExport = async (row) => {
  const result = await request_projecttask_report_export(row.guid);
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = result;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
};
// 导出稽查证明
const handleExportAuditCertificate = async (row) => {
  const result = await request_projecttask_auditcertificate_export(row.guid);
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = result;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
};
// 导出capa报告
const handleExportCAPA = async (row) => {
  const result = await request_projecttask_capa_export(row.guid);
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = result;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
};
// 跳转到报告附件
const handleReportAppendix = (row) => {
  router.push({ path: "/project/reportappendix", query: { guid: row.guid } });
};
// 监听菜单数据
watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskSearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskReset
    );
    // 处理新增
    buttonVisible.addButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskAdd
    );
    // 处理编辑
    buttonVisible.editButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskEdit
    );
    // 处理编辑行
    buttonVisible.editButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskEditLine
    );
    // 处理信息行
    buttonVisible.infoButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskInfoLine
    );
    // 处理派遣行
    buttonVisible.dispatchButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskDispatchLine
    );
    // 处理稽查小结
    buttonVisible.auditSummaryLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskAuditSummaryLine
    );
    // 处理稽查发现
    buttonVisible.discoveryLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskDiscoveryLine
    );
    // 处理报告
    buttonVisible.reportLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskReportLine
    );
    // 处理稽查证明
    buttonVisible.auditCertificateLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskAuditCertificateLine
    );
    // 处理CAPA导出
    buttonVisible.exportCAPALine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskExportCAPALine
    );
    // 处理导出报告
    buttonVisible.exportReportLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskExportReportLine
    );
    // 处理报告附件
    buttonVisible.reportAppendixLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskReportAppendixLine
    );
    // 处理完成
    buttonVisible.completeLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskCompleteLine
    );
    // 处理取消
    buttonVisible.cancelLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskCancelLine
    );
    // 处理重启
    buttonVisible.restartLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskRestartLine
    );
    // 处理返改
    buttonVisible.rewriteLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskRewriteLine
    );
    // 处理CAPA定稿
    buttonVisible.capaFinalizeLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskCAPAFinalize
    );
  }
});
// 监听字典数据
watchEffect(() => {
  if (store.userInfo) {
    if (store.userInfo.dics && store.userInfo.dics.length > 0) {
      // 稽查类型
      const findAuditTypeDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.AuditType
      );
      if (findAuditTypeDic) {
        if (findAuditTypeDic.items && findAuditTypeDic.items.length > 0) {
          auditTypes.value = findAuditTypeDic.items;
        } else {
          auditTypes.value = [];
        }
      } else {
        auditTypes.value = [];
      }
      // 试验阶段
      const findTrailStagingDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.TrialStaging
      );
      if (findTrailStagingDic) {
        if (findTrailStagingDic.items && findTrailStagingDic.items.length > 0) {
          trailStagings.value = findTrailStagingDic.items;
        } else {
          trailStagings.value = [];
        }
      } else {
        trailStagings.value = [];
      }

      // 试验进展阶段
      const findTrailProgressStageDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.TrialProgressStage
      );
      if (findTrailProgressStageDic) {
        if (
          findTrailProgressStageDic.items &&
          findTrailProgressStageDic.items.length > 0
        ) {
          trailProgressStages.value = findTrailProgressStageDic.items;
        } else {
          trailProgressStages.value = [];
        }
      } else {
        trailProgressStages.value = [];
      }
      // 被稽查方区域
      const findAuditedPartyAreaDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.AuditedPartyArea
      );
      if (findAuditedPartyAreaDic) {
        if (
          findAuditedPartyAreaDic.items &&
          findAuditedPartyAreaDic.items.length > 0
        ) {
          auditedPartyAreas.value = findAuditedPartyAreaDic.items;
        } else {
          auditedPartyAreas.value = [];
        }
      } else {
        auditedPartyAreas.value = [];
      }
      // 被稽查方资质
      const findAuditedPartyQualificationDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.AuditedPartyQualification
      );
      if (findAuditedPartyQualificationDic) {
        if (
          findAuditedPartyQualificationDic.items &&
          findAuditedPartyQualificationDic.items.length > 0
        ) {
          auditedPartyQualifications.value =
            findAuditedPartyQualificationDic.items;
        } else {
          auditedPartyQualifications.value = [];
        }
      } else {
        auditedPartyQualifications.value = [];
      }
      // 医院类型
      const findHospitalTypeDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.HospitalType
      );
      if (findHospitalTypeDic) {
        if (findHospitalTypeDic.items && findHospitalTypeDic.items.length > 0) {
          hospitalTypes.value = findHospitalTypeDic.items;
        } else {
          hospitalTypes.value = [];
        }
      } else {
        hospitalTypes.value = [];
      }
    }
  }
});
</script>
<style>
.projecttask-ul .el-scrollbar__view {
  height: calc(100% - 8px);
}
.projecttask-ul .createtime-small {
  font-size: 12px !important;
}
</style>
<style scoped lang="scss">
.projecttask-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  .hm-operate {
    .operate-ul {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      width: 100%;
      .left-li {
        flex: 1;
      }
      .right-li {
        display: flex;
        align-items: center;
      }
    }
  }
  .text-projectnumber {
    &:hover {
      cursor: pointer;
    }
  }
  .icon-progress {
    color: #108ee9;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
