<template>
  <ul class="title-ul">
    <li class="left-li">
      <h2>
        1.{{
          t("Components.Project.ProjectTask.Report1.GeneralInfomation.Title")
        }}
      </h2>
    </li>
    <li class="right-li">
      <!-- 只有非待分配，准备中，稽查实施，报告撰写，完成状态可以进行评论 -->
      <div v-if="computedCommentCount > 0">
        <el-badge
          :value="computedCommentCount"
          type="success"
          class="item"
          v-if="
            props.projectTaskProgress !== ProjectTaskProgress.ToBeAllocated &&
            props.projectTaskProgress !== ProjectTaskProgress.Ready &&
            props.projectTaskProgress !==
              ProjectTaskProgress.AuditImplementation &&
            props.projectTaskProgress !== ProjectTaskProgress.ReportWriting &&
            props.projectTaskProgress !== ProjectTaskProgress.Complete
          "
        >
          <el-badge
            class="notread-comment-count"
            :value="computedNotReadCommentCount"
            v-if="computedNotReadCommentCount > 0"
          >
            <el-button
              type="primary"
              size="small"
              circle
              :icon="ChatDotSquare"
              @click="handleCommentClick"
            ></el-button>
          </el-badge>
          <el-button
            v-else
            type="primary"
            size="small"
            circle
            :icon="ChatDotSquare"
            @click="handleCommentClick"
          ></el-button>
        </el-badge>
      </div>
      <div v-else>
        <el-button
          v-if="
            props.projectTaskProgress !== ProjectTaskProgress.ToBeAllocated &&
            props.projectTaskProgress !== ProjectTaskProgress.Ready &&
            props.projectTaskProgress !==
              ProjectTaskProgress.AuditImplementation &&
            props.projectTaskProgress !== ProjectTaskProgress.ReportWriting &&
            props.projectTaskProgress !== ProjectTaskProgress.Complete
          "
          type="primary"
          size="small"
          circle
          :icon="ChatDotSquare"
          @click="handleCommentClick"
        ></el-button>
      </div>
    </li>
  </ul>

  <table class="table" :style="{ width: '100%' }">
    <tr>
      <td class="label-bold" :style="{ width: '12%' }">
        {{
          t("Components.Project.ProjectTask.Report1.GeneralInfomation.Sponsor")
        }}
      </td>
      <td class="text" :style="{ width: '32%' }">
        <!-- {{
          props.data && props.data.project ? props.data.project.sponsor : ""
        }} -->
        <span
          v-if="!sponsorIsEdit"
          @click="handleEditDate(ProjectTaskBaseInfoOperateType.Sponsor)"
        >
          {{
            props.data && props.data.project ? props.data.project.sponsor : ""
          }}
        </span>
        <div class="dd" v-else>
          <el-input
            v-model="editingSponsor"
            :placeholder="
              t(
                'Components.Project.ProjectTask.Report1.GeneralInfomation.PleaseInputSponsor'
              )
            "
          ></el-input>
          <el-space>
            <el-button
              type="primary"
              circle
              size="small"
              :icon="Check"
              @click.stop="
                handleBaseInfoOK(ProjectTaskBaseInfoOperateType.Sponsor)
              "
            ></el-button>
            <el-button
              circle
              size="small"
              type="danger"
              :icon="Close"
              @click.stop="handleBaseInfoCancel"
            ></el-button>
          </el-space>
        </div>
      </td>
      <td class="label-bold" :style="{ width: '12%' }">
        {{
          t(
            "Components.Project.ProjectTask.Report1.GeneralInfomation.DrugsDevices"
          )
        }}
      </td>
      <td class="text" :style="{ width: '16%' }">
        {{
          props.data && props.data.project
            ? props.data.project.research_products
            : ""
        }}
      </td>
      <td class="label-bold" :style="{ width: '12%' }">
        {{
          t(
            "Components.Project.ProjectTask.Report1.GeneralInfomation.PlanNumber"
          )
        }}
      </td>
      <td class="text" :style="{ width: '16%' }">
        {{
          props.data && props.data.project ? props.data.project.plan_number : ""
        }}
      </td>
    </tr>
    <tr>
      <td class="label-bold">
        {{
          t(
            "Components.Project.ProjectTask.Report1.GeneralInfomation.ProjectName"
          )
        }}
      </td>
      <td class="text" colspan="5">
        {{
          props.data && props.data.project ? props.data.project.scheme_name : ""
        }}
      </td>
    </tr>
    <tr>
      <td class="label-bold">
        {{
          t("Components.Project.ProjectTask.Report1.GeneralInfomation.Auditor")
        }}
      </td>
      <!-- 需要根据稽查人员列表进行拼接，拼接规则
      如果只有一个，那么这个就直接显示，如果存在多个，那么主稽查员放在第一个，张三(主稽查员),李四 -->
      <td class="text" colspan="5">
        <div class="user-div">
          <div class="auditor-div">
            <span>{{ computedAuditor }}</span>
            <span
              v-if="
                !parttimeUserIsEdit 
               
              "
            >
              <span v-if=" props.data &&
                props.data.project_task &&
                props.data.project_task.parttime_users">
                <span
                v-for="item in props.data.project_task.parttime_users"
                :key="item"
                @dblclick.stop="
                  () => {
                    sourceParttimeUser = item;
                    parttimeUserIsEdit = true;
                    editingPartimeUser = item;
                  }
                "
              >
                ,{{ item }}
              </span>
              </span>
            </span>
            <div class="dd" v-else>
              <el-input
                v-model="editingPartimeUser"
                :placeholder="
                  t(
                    'Components.Project.ProjectTask.Report1.GeneralInfomation.PleaseInputParttimeUser'
                  )
                "
              ></el-input>
              <el-space>
                <el-button
                  type="primary"
                  circle
                  size="small"
                  :icon="Check"
                  @click.stop="handlePartimeUserOk()"
                ></el-button>
                <el-button
                  circle
                  size="small"
                  type="danger"
                  :icon="Close"
                  @click.stop="handleBaseInfoCancel"
                ></el-button>
              </el-space>
            </div>
          </div>
          <div class="parttime-user-div">
            <el-button
              v-if="computedCanEdit && !parttimeUserIsEdit"
              :icon="Plus"
              size="small"
              type="primary"
              @click="
                () => {
                  parttimeUserIsEdit = true;
                  sourceParttimeUser = '';
                }
              "
              >{{
                t(
                  "Components.Project.ProjectTask.Report1.GeneralInfomation.ParttimeUser"
                )
              }}</el-button
            >
            <el-button
              v-if="computedCanEdit && parttimeUserIsEdit && sourceParttimeUser"
              :icon="Delete"
              size="small"
              type="danger"
              @click="handleDeleteParttimeUser"
              >{{
                t(
                  "Components.Project.ProjectTask.Report1.GeneralInfomation.Delete"
                )
              }}</el-button
            >
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td class="label-bold">
        {{
          t(
            "Components.Project.ProjectTask.Report1.GeneralInfomation.AuditDate"
          )
        }}
      </td>
      <td
        class="text"
        colspan="3"
        @click="handleEditDate(ProjectTaskBaseInfoOperateType.AuditDate)"
      >
        <span v-if="!auditDateIsEdit">
          <span v-if="sourceAuditStartDateStr && sourceAuditEndDateStr"
            >{{ sourceAuditStartDateStr }} - {{ sourceAuditEndDateStr }}</span
          >
        </span>
        <div class="dd" v-else>
          <el-date-picker
            v-model="editingAuditDate"
            type="datetimerange"
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            range-separator=" - "
            :start-placeholder="t('Components.Project.ProjectTask.StartDate')"
            :end-placeholder="t('Components.Project.ProjectTask.EndDate')"
            :disabled-date="auditDisabledDate"
          />
          <el-space>
            <el-button
              type="primary"
              circle
              size="small"
              :icon="Check"
              @click.stop="
                handleBaseInfoOK(ProjectTaskBaseInfoOperateType.AuditDate)
              "
            ></el-button>
            <el-button
              circle
              size="small"
              type="danger"
              :icon="Close"
              @click.stop="handleBaseInfoCancel"
            ></el-button>
          </el-space>
        </div>
      </td>
      <td class="label-bold">
        {{
          t(
            "Components.Project.ProjectTask.Report1.GeneralInfomation.ReportDate"
          )
        }}
      </td>
      <td
        class="text"
        colspan="2"
        @click="handleEditDate(ProjectTaskBaseInfoOperateType.ReportDate)"
      >
        <span v-if="!reportDateIsEdit">{{ sourceReportDateStr }}</span>
        <div class="dd" v-else>
          <el-date-picker
            v-model="editingReportDate"
            type="date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :placeholder="
              t(
                'Components.Project.ProjectTask.Report1.GeneralInfomation.PleaseSelectReportDate'
              )
            "
            :disabled-date="reportDisabledDate"
          />
          <el-space>
            <el-button
              type="primary"
              circle
              size="small"
              :icon="Check"
              @click.stop="
                handleBaseInfoOK(ProjectTaskBaseInfoOperateType.ReportDate)
              "
            ></el-button>
            <el-button
              circle
              size="small"
              type="danger"
              :icon="Close"
              @click.stop="handleBaseInfoCancel"
            ></el-button>
          </el-space>
        </div>
      </td>
    </tr>
  </table>

  <table class="table" :style="{ width: '100%' }">
    <tr>
      <td class="label-bold" :style="{ width: '12%' }">
        {{
          t(
            "Components.Project.ProjectTask.Report1.GeneralInfomation.ProjectManager"
          )
        }}
      </td>
      <td
        class="text"
        :style="{ width: '38%' }"
        @click="handleEditDate(ProjectTaskBaseInfoOperateType.ProjectManager)"
      >
        <span v-if="!projectManagerIsEdit">{{ sourceProjectManager }}</span>
        <div class="dd" v-else>
          <el-input
            v-model="editingProjectManager"
            :placeholder="
              t(
                'Components.Project.ProjectTask.Report1.GeneralInfomation.PleaseInputProjectManager'
              )
            "
          ></el-input>
          <el-space>
            <el-button
              type="primary"
              circle
              size="small"
              :icon="Check"
              @click.stop="
                handleBaseInfoOK(ProjectTaskBaseInfoOperateType.ProjectManager)
              "
            ></el-button>
            <el-button
              circle
              size="small"
              type="danger"
              :icon="Close"
              @click.stop="handleBaseInfoCancel"
            ></el-button>
          </el-space>
        </div>
      </td>
      <td class="label-bold" :style="{ width: '12%' }">
        {{
          t(
            "Components.Project.ProjectTask.Report1.GeneralInfomation.Inspector"
          )
        }}
      </td>
      <td
        class="text"
        :style="{ width: '38%' }"
        @click="handleEditDate(ProjectTaskBaseInfoOperateType.Inspector)"
      >
        <span v-if="!inspectorIsEdit">{{ sourceInspector }}</span>
        <div class="dd" v-else>
          <el-input
            v-model="editingInspector"
            :placeholder="
              t(
                'Components.Project.ProjectTask.Report1.GeneralInfomation.PleaseInputInspector'
              )
            "
          ></el-input>
          <el-space>
            <el-button
              type="primary"
              circle
              size="small"
              :icon="Check"
              @click.stop="
                handleBaseInfoOK(ProjectTaskBaseInfoOperateType.Inspector)
              "
            ></el-button>
            <el-button
              circle
              size="small"
              type="danger"
              :icon="Close"
              @click.stop="handleBaseInfoCancel"
            ></el-button>
          </el-space>
        </div>
      </td>
    </tr>
  </table>

  <table class="table" :style="{ width: '100%' }">
    <tr>
      <td class="label-bold" :style="{ width: '12%' }">
        {{
          t(
            "Components.Project.ProjectTask.Report1.GeneralInfomation.ResearchCenterName"
          )
        }}
      </td>
      <td class="text" colspan="4">
        {{
          props.data && props.data.project_task
            ? props.data.project_task.audited_party_name
            : ""
        }}
      </td>
      <td class="label-bold" :style="{ width: '12%' }">
        {{
          t(
            "Components.Project.ProjectTask.Report1.GeneralInfomation.MainResearcher"
          )
        }}
      </td>
      <td class="text">
        {{
          props.data && props.data.project_task
            ? props.data.project_task.main_researcher
            : ""
        }}
      </td>
    </tr>
    <tr>
      <td class="label-bold">
        {{
          t(
            "Components.Project.ProjectTask.Report1.GeneralInfomation.CenterNumber"
          )
        }}
      </td>
      <td
        class="text"
        colspan="2"
        @click="handleEditDate(ProjectTaskBaseInfoOperateType.CenterNumber)"
      >
        <span v-if="!centerNumberIsEdit">{{ sourceCenterNumber }}</span>
        <div class="dd" v-else>
          <el-input
            v-model="editingCenterNumber"
            :placeholder="
              t(
                'Components.Project.ProjectTask.Report1.GeneralInfomation.PleaseInputCenterNumber'
              )
            "
          ></el-input>
          <el-space>
            <el-button
              type="primary"
              circle
              size="small"
              :icon="Check"
              @click.stop="
                handleBaseInfoOK(ProjectTaskBaseInfoOperateType.CenterNumber)
              "
            ></el-button>
            <el-button
              circle
              size="small"
              type="danger"
              :icon="Close"
              @click.stop="handleBaseInfoCancel"
            ></el-button>
          </el-space>
        </div>
      </td>
      <td class="label-bold">
        {{
          t(
            "Components.Project.ProjectTask.Report1.GeneralInfomation.CenterAddress"
          )
        }}
      </td>
      <td
        class="text"
        colspan="3"
        @click="handleEditDate(ProjectTaskBaseInfoOperateType.AuditAddress)"
      >
        <span v-if="!auditAddressIsEdit">{{ sourceAuditAddress }}</span>
        <div class="dd" v-else>
          <el-input
            v-model="editingAuditAddress"
            :placeholder="
              t(
                'Components.Project.ProjectTask.Report1.GeneralInfomation.PleaseInputAduitAddress'
              )
            "
          ></el-input>
          <el-space>
            <el-button
              type="primary"
              circle
              size="small"
              :icon="Check"
              @click.stop="
                handleBaseInfoOK(ProjectTaskBaseInfoOperateType.AuditAddress)
              "
            ></el-button>
            <el-button
              circle
              size="small"
              type="danger"
              :icon="Close"
              @click.stop="handleBaseInfoCancel"
            ></el-button>
          </el-space>
        </div>

        <!-- {{
          props.data && props.data.project_task
            ? props.data.project_task.audit_address
            : ""
        }} -->
      </td>
    </tr>
    <tr>
      <td class="label-bold" rowspan="2">
        {{
          t(
            "Components.Project.ProjectTask.Report1.GeneralInfomation.CenterStatus"
          )
        }}
      </td>
      <td :style="{ width: '14.6%' }">
        {{
          t("Components.Project.ProjectTask.Report1.GeneralInfomation.Filter")
        }}
      </td>
      <td :style="{ width: '14.6%' }">
        {{
          t("Components.Project.ProjectTask.Report1.GeneralInfomation.Import")
        }}
      </td>
      <td :style="{ width: '14.6%' }">
        {{
          t(
            "Components.Project.ProjectTask.Report1.GeneralInfomation.JoinGroup"
          )
        }}
      </td>
      <td :style="{ width: '14.6%' }">
        {{ t("Components.Project.ProjectTask.Report1.GeneralInfomation.Exit") }}
      </td>
      <td :style="{ width: '14.6%' }">
        {{
          t("Components.Project.ProjectTask.Report1.GeneralInfomation.FollowUp")
        }}
      </td>
      <td :style="{ width: '14.6%' }">
        {{
          t("Components.Project.ProjectTask.Report1.GeneralInfomation.Complete")
        }}
      </td>
    </tr>
    <tr>
      <td>
        <el-input
          v-if="computedCanEdit"
          v-model="filterInput"
          :placeholder="
            t('Components.Project.ProjectTask.Report1.GeneralInfomation.Filter')
          "
          @blur="handleBlur(ProjectTaskCenterStatusType.Filter)"
        ></el-input>
        <span v-else>{{ filterInput }}</span>
      </td>
      <td>
        <el-input
          v-if="computedCanEdit"
          v-model="importInput"
          :placeholder="
            t('Components.Project.ProjectTask.Report1.GeneralInfomation.Import')
          "
          @blur="handleBlur(ProjectTaskCenterStatusType.Import)"
        ></el-input>
        <span v-else>{{ importInput }}</span>
      </td>
      <td>
        <el-input
          v-if="computedCanEdit"
          v-model="joinGroupInput"
          :placeholder="
            t(
              'Components.Project.ProjectTask.Report1.GeneralInfomation.JoinGroup'
            )
          "
          @blur="handleBlur(ProjectTaskCenterStatusType.JoinGroup)"
        ></el-input>
        <span v-else>{{ joinGroupInput }}</span>
      </td>
      <td>
        <el-input
          v-if="computedCanEdit"
          v-model="exitInput"
          :placeholder="
            t('Components.Project.ProjectTask.Report1.GeneralInfomation.Exit')
          "
          @blur="handleBlur(ProjectTaskCenterStatusType.Exist)"
        ></el-input>
        <span v-else>{{ exitInput }}</span>
      </td>
      <td>
        <el-input
          v-if="computedCanEdit"
          v-model="followUpInput"
          :placeholder="
            t(
              'Components.Project.ProjectTask.Report1.GeneralInfomation.FollowUp'
            )
          "
          @blur="handleBlur(ProjectTaskCenterStatusType.FollowUp)"
        ></el-input>
        <span v-else>{{ followUpInput }}</span>
      </td>
      <td>
        <el-input
          v-if="computedCanEdit"
          v-model="completeInput"
          :placeholder="
            t(
              'Components.Project.ProjectTask.Report1.GeneralInfomation.Complete'
            )
          "
          @blur="handleBlur(ProjectTaskCenterStatusType.Complete)"
        ></el-input>
        <div v-else :style="{ minHeight: '24px' }">
          <span v-if="!computedCanEdit">{{ completeInput }}</span>
        </div>
      </td>
    </tr>
  </table>

  <el-button
    v-if="computedCanEdit"
    :icon="Plus"
    type="primary"
    :style="{ marginBottom: '20px' }"
    @click="handleAddAttendees"
    >{{
      t("Components.Project.ProjectTask.Report1.GeneralInfomation.Add")
    }}</el-button
  >
  <table class="table" :style="{ width: '100%' }">
    <tr>
      <td class="text" colspan="3" align="center">
        {{
          t(
            "Components.Project.ProjectTask.Report1.GeneralInfomation.ResearchCenterAttendees"
          )
        }}
      </td>
    </tr>
    <tr>
      <td :style="{ width: '33.33%' }">
        {{ t("Components.Project.ProjectTask.Report1.GeneralInfomation.Name") }}
      </td>
      <td :style="{ width: '33.33%' }">
        {{
          t("Components.Project.ProjectTask.Report1.GeneralInfomation.Position")
        }}
      </td>
      <td :style="{ width: '33.33%' }" v-if="computedCanEdit">
        {{
          t("Components.Project.ProjectTask.Report1.GeneralInfomation.Operate")
        }}
      </td>
    </tr>
    <tr v-for="(item, index) in attendees" :key="index">
      <td>
        <!-- v-if="
            !item.isAdd &&
            (!currentEditAttemdee || currentEditAttemdee.guid !== item.guid)
          " -->

        <span
          v-if="
            !item.isAdd &&
            (!currentEditAttemdee || currentEditAttemdee.guid !== item.guid)
          "
          >{{ item.name }}</span
        >

        <el-input
          v-else
          v-model="item.name"
          :placeholder="
            t(
              'Components.Project.ProjectTask.Report1.GeneralInfomation.PleaseInputName'
            )
          "
        ></el-input>
      </td>
      <td>
        <span
          v-if="
            !item.isAdd &&
            (!currentEditAttemdee || currentEditAttemdee.guid !== item.guid)
          "
          >{{ item.job }}</span
        >
        <el-input
          v-else
          v-model="item.job"
          :placeholder="
            t(
              'Components.Project.ProjectTask.Report1.GeneralInfomation.PleaseInputJob'
            )
          "
        ></el-input>
      </td>
      <td v-if="computedCanEdit">
        <el-space
          v-if="
            !item.isAdd &&
            (!currentEditAttemdee || currentEditAttemdee.guid !== item.guid)
          "
        >
          <el-button
            type="primary"
            size="small"
            :icon="Edit"
            @click="handleEdit(item)"
            >{{
              t("Components.Project.ProjectTask.Report1.GeneralInfomation.Edit")
            }}</el-button
          >
          <el-button
            type="danger"
            size="small"
            :icon="Delete"
            @click="handleDelete(item)"
            >{{
              t(
                "Components.Project.ProjectTask.Report1.GeneralInfomation.Delete"
              )
            }}</el-button
          >
        </el-space>
        <el-space v-else>
          <el-button
            type="primary"
            size="small"
            circle
            :icon="Check"
            @click="handleOk(item)"
          ></el-button>
          <el-button
            type="danger"
            size="small"
            circle
            :icon="Close"
            @click="handleCancel(item)"
          ></el-button>
        </el-space>
      </td>
    </tr>
  </table>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import {
  watchEffect,
  ref,
  defineProps,
  onMounted,
  computed,
  defineEmits,
} from "vue";
import {
  Plus,
  Check,
  Close,
  Edit,
  Delete,
  ChatDotSquare,
} from "@element-plus/icons-vue";
import { nanoid } from "nanoid";
import {
  request_projecttask_attendees_add,
  request_projecttask_attendees_modify,
  request_projecttask_attendees_delete,
  request_projecttask_centerstatus_save,
  request_projecttask_report_baseinfo_update,
  request_projecttask_parttime_user_add,
  request_projecttask_parttime_user_modify,
  request_projecttask_parttime_user_delete,
} from "@/api/apis";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  ProjectTaskCenterStatusType,
  ProjectTaskProgress,
} from "@/consts/enum";
import { Dics } from "@/consts/dic";
import { useStore } from "@/store/index";
import {
  ProjectTaskCommentModule,
  ProjectTaskBaseInfoOperateType,
} from "@/consts/enum";
import pubsub from "pubsub-js";
import PubsubMessage from "@/consts/pubsubMessage";

const store = useStore();
const { t } = useI18n();
const emits = defineEmits(["getProjectTask"]);
const props = defineProps({
  data: {
    type: Object,
    default: () => undefined,
  },
  projectTaskGuid: {
    type: String,
    default: () => "",
  },
  projectTaskProgress: {
    type: Number,
    default: () => 0,
  },
  commentModuleCount: {
    type: Array,
    default: () => [],
  },
  canReview: {
    type: Boolean,
    default: () => false,
  },
});
const filterInput = ref(""); // 筛选
const importInput = ref(""); // 导入
const joinGroupInput = ref(""); // 入组
const exitInput = ref(""); // 退出
const followUpInput = ref(""); // 随访
const completeInput = ref(""); // 完成
const attendees = ref([]); // 出席人员
const currentEditAttemdee = ref(""); // 当前编辑的数据

const centerStatus = ref([]);
const projectTaskAuditUserRoles = ref([]);
const dispatchUsers = ref([]);

const sponsorIsEdit = ref(false); // 申办者是否编辑
const auditDateIsEdit = ref(false); // 稽查日期是否编辑
const reportDateIsEdit = ref(false); // 报告日期是否编辑
const projectManagerIsEdit = ref(false); // 项目经理是否编辑
const inspectorIsEdit = ref(false); // 检察院是否编辑
const centerNumberIsEdit = ref(false); // 中心编号是否编辑
const auditAddressIsEdit = ref(false); // 中心地址是否编辑
const parttimeUserIsEdit = ref(false); // 兼职人员是否编辑

const sourceSponsor = ref("");
const sourceAuditDate = ref(undefined);
const sourceAuditStartDateStr = ref("");
const sourceAuditEndDateStr = ref("");
const sourceReportDate = ref(undefined);
const sourceReportDateStr = ref("");
const sourceProjectManager = ref("");
const sourceInspector = ref("");
const sourceCenterNumber = ref("");
const sourceAuditAddress = ref("");
const sourceParttimeUser = ref("");

const editingSponsor = ref("");
const editingAuditDate = ref();
const editingReportDate = ref();
const editingProjectManager = ref();
const editingInspector = ref();
const editingCenterNumber = ref();
const editingAuditAddress = ref();
const editingPartimeUser = ref();

// 是否可以编辑
const computedCanEdit = computed(() => {
  return (
    props.projectTaskProgress === ProjectTaskProgress.ReportWriting ||
    props.projectTaskProgress === ProjectTaskProgress.Rewrite
  );
});

const computedParttimeUsers = computed(() => {
  let str = "";
  if (props.data.parttime_users && props.data.parttime_users.length > 0) {
    str = "," + props.data.parttime_users.join(",");
  }
  return str;
});

// 处理稽查员拼接字符串
const computedAuditor = computed(() => {
  let mainAuditeRole = undefined;
  if (
    projectTaskAuditUserRoles.value &&
    projectTaskAuditUserRoles.value.length > 0
  ) {
    mainAuditeRole = projectTaskAuditUserRoles.value[0];
  }
  let str = "";
  // 存在这个角色
  if (dispatchUsers.value && dispatchUsers.value.length > 0) {
    // 存在稽查人员数据
    if (mainAuditeRole) {
      // 有主稽查员这个字典
      const findMainAuditor = dispatchUsers.value.find(
        (item) => item.role === mainAuditeRole.value
      );
      if (findMainAuditor) {
        // 找到主稽查员
        str += findMainAuditor.user_name + `(${mainAuditeRole.name})` + ",";
      }

      dispatchUsers.value.forEach((item) => {
        if (findMainAuditor) {
          if (item.guid !== findMainAuditor.guid) {
            str += item.user_name + ",";
          }
        } else {
          str += item.user_name + ",";
        }
      });
    } else {
      // 没有主稽查员这个字典
      dispatchUsers.value.forEach((item) => {
        if (mainAuditeRole && item.role === mainAuditeRole.value) {
          str += item.user_name + `(${mainAuditeRole.name})` + ",";
        } else {
          str += item.user_name + ",";
        }
      });
    }

    // dispatchUsers.value &&
    //   dispatchUsers.value.forEach((item) => {
    //     if (mainAuditeRole && item.role === mainAuditeRole.value) {
    //       str += item.user_name + `(${mainAuditeRole.name})` + ",";
    //     } else {
    //       str += item.user_name + ",";
    //     }
    //   });
  }
  if (str.length > 0) str = str.substring(0, str.lastIndexOf(","));
  return str;
});
// 计算模块评论数量
const computedCommentCount = computed(() => {
  if (props.commentModuleCount && props.commentModuleCount.length > 0) {
    const findCommentModuleCountData = props.commentModuleCount.find(
      (item) => item.module === ProjectTaskCommentModule.BaseInfo
    );
    if (findCommentModuleCountData) {
      return findCommentModuleCountData.count;
    }
  }
  return 0;
});
const computedNotReadCommentCount = computed(() => {
  if (props.commentModuleCount && props.commentModuleCount.length > 0) {
    const findCommentModuleCountData = props.commentModuleCount.find(
      (item) => item.module === ProjectTaskCommentModule.BaseInfo
    );
    if (findCommentModuleCountData) {
      // return findCommentModuleCountData.not_read_count;

      if (props.canReview)
        return findCommentModuleCountData.not_read_count_for_approver;
      else return findCommentModuleCountData.not_read_count;
    }
  }
  return 0;
});

onMounted(() => {});

// 处理添加出席人员
const handleAddAttendees = () => {
  attendees.value.push({
    guid: nanoid(),
    isAdd: true,
  });
};
// 处理添加出席人员
const handleOk = async (data) => {
  if (!data.name) {
    ElMessage.warning(
      t(
        "Components.Project.ProjectTask.Report1.GeneralInfomation.PleaseInputName"
      )
    );
    return;
  }
  if (!data.job) {
    ElMessage.warning(
      t(
        "Components.Project.ProjectTask.Report1.GeneralInfomation.PleaseInputJob"
      )
    );
    return;
  }
  const trans = {
    name: data.name,
    job: data.job,
    guid: data.guid,
  };
  if (!data.isAdd) {
    // 修改
    await request_projecttask_attendees_modify(props.projectTaskGuid, trans);
    currentEditAttemdee.value = undefined;
  } else {
    // 添加
    const result = await request_projecttask_attendees_add(
      props.projectTaskGuid,
      trans
    );
    data.guid = result;
    Reflect.deleteProperty(data, "isAdd");
  }
};
// 处理取消
const handleCancel = (data) => {
  if (data.isAdd) {
    // 删除添加的数据
    attendees.value.splice(
      attendees.value.findIndex((item) => item.guid === data.guid),
      1
    );
  } else {
    // 取消编辑中的数据，需要复原
    if (currentEditAttemdee.value) {
      data.name = currentEditAttemdee.value.name;
      data.job = currentEditAttemdee.value.job;
      currentEditAttemdee.value = undefined;
    }
  }
};
// 处理编辑
const handleEdit = (data) => {
  if (currentEditAttemdee.value) {
    // 如果有值，需要还原之前的数据
    const findData = attendees.value.find(
      (item) => item.guid === currentEditAttemdee.value.guid
    );
    if (findData) {
      findData.name = currentEditAttemdee.value.name;
      findData.job = currentEditAttemdee.value.job;
    }
  }
  currentEditAttemdee.value = {
    name: data.name,
    job: data.job,
    guid: data.guid,
  };
};
// 处理删除
const handleDelete = (data) => {
  ElMessageBox.confirm(
    t(
      "Components.Project.ProjectTask.Report1.GeneralInfomation.ConfirmDeleteAttendees"
    ),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
    }
  )
    .then(async () => {
      const trans = {
        guid: data.guid,
      };
      await request_projecttask_attendees_delete(props.projectTaskGuid, trans);
      // 删除添加的数据
      attendees.value.splice(
        attendees.value.findIndex((item) => item.guid === data.guid),
        1
      );
    })
    .catch(() => {});
};
// 处理失去焦点
const handleBlur = async (type) => {
  let text = "";
  if (type === ProjectTaskCenterStatusType.Filter) {
    text = filterInput.value;
  } else if (type === ProjectTaskCenterStatusType.Import) {
    text = importInput.value;
  } else if (type === ProjectTaskCenterStatusType.JoinGroup) {
    text = joinGroupInput.value;
  } else if (type === ProjectTaskCenterStatusType.Exist) {
    text = exitInput.value;
  } else if (type === ProjectTaskCenterStatusType.FollowUp) {
    text = followUpInput.value;
  } else if (type === ProjectTaskCenterStatusType.Complete) {
    text = completeInput.value;
  }
  const findData = centerStatus.value.find((item) => item.type === type);
  if (!findData) {
    ElMessage.warning(
      t(
        "Components.Project.ProjectTask.Report1.GeneralInfomation.CenterStatusNone"
      )
    );
    return;
  }
  const trans = { guid: findData.guid, type, text };
  await request_projecttask_centerstatus_save(props.projectTaskGuid, trans);
};
// 评论点击
const handleCommentClick = () => {
  const data = {
    projectTaskProgress: props.projectTaskProgress,
    module: ProjectTaskCommentModule.BaseInfo,
    title: t("Components.Project.ProjectTask.Report1.GeneralInfomation.Title"),
  };
  pubsub.publish(PubsubMessage.ProjectTaskReportComment, data);
};
// 处理编辑
const handleEditDate = (operateType) => {
  if (!computedCanEdit.value) return;
  sponsorIsEdit.value = false;
  auditDateIsEdit.value = false;
  reportDateIsEdit.value = false;
  projectManagerIsEdit.value = false;
  inspectorIsEdit.value = false;
  centerNumberIsEdit.value = false;
  auditAddressIsEdit.value = false;
  if (operateType === ProjectTaskBaseInfoOperateType.Sponsor) {
    sponsorIsEdit.value = true;
    editingSponsor.value = sourceSponsor.value;
  } else if (operateType === ProjectTaskBaseInfoOperateType.AuditDate) {
    auditDateIsEdit.value = true;
    editingAuditDate.value = sourceAuditDate.value;
  } else if (operateType === ProjectTaskBaseInfoOperateType.ReportDate) {
    reportDateIsEdit.value = true;
    editingReportDate.value = sourceReportDate.value;
  } else if (operateType === ProjectTaskBaseInfoOperateType.ProjectManager) {
    projectManagerIsEdit.value = true;
    editingProjectManager.value = sourceProjectManager.value;
  } else if (operateType === ProjectTaskBaseInfoOperateType.Inspector) {
    inspectorIsEdit.value = true;
    editingInspector.value = sourceInspector.value;
  } else if (operateType === ProjectTaskBaseInfoOperateType.CenterNumber) {
    centerNumberIsEdit.value = true;
    editingCenterNumber.value = sourceCenterNumber.value;
  } else if (operateType === ProjectTaskBaseInfoOperateType.AuditAddress) {
    auditAddressIsEdit.value = true;
    editingAuditAddress.value = sourceAuditAddress.value;
  }
};
// 处理保存
const handleBaseInfoOK = async (operateType) => {
  if (operateType === ProjectTaskBaseInfoOperateType.AuditDate) {
    // 稽查日期
    if (!editingAuditDate.value || editingAuditDate.value.length != 2) {
      ElMessage.warning(
        t(
          "Components.Project.ProjectTask.Report1.GeneralInfomation.PleaseSelectAuditDate"
        )
      );
      return;
    }
  } else if (operateType === ProjectTaskBaseInfoOperateType.ReportDate) {
    // 报告日期
    // if (!editingReportDate.value) {
    //   ElMessage.warning(
    //     t(
    //       "Components.Project.ProjectTask.Report1.GeneralInfomation.PleaseSelectReportDate"
    //     )
    //   );
    //   return;
    // }
  } else if (operateType === ProjectTaskBaseInfoOperateType.ProjectManager) {
    // 项目经理
    if (!editingProjectManager.value) {
      ElMessage.warning(
        t(
          "Components.Project.ProjectTask.Report1.GeneralInfomation.PleaseInputProjectManager"
        )
      );
      return;
    }
  } else if (operateType === ProjectTaskBaseInfoOperateType.Inspector) {
    // 监查员
    if (!editingInspector.value) {
      ElMessage.warning(
        t(
          "Components.Project.ProjectTask.Report1.GeneralInfomation.PleaseInputInspector"
        )
      );
      return;
    }
  } else if (operateType === ProjectTaskBaseInfoOperateType.CenterNumber) {
    // 中心编号
    if (!editingCenterNumber.value) {
      ElMessage.warning(
        t(
          "Components.Project.ProjectTask.Report1.GeneralInfomation.PleaseInputCenterNumber"
        )
      );
      return;
    }
  } else if (operateType === ProjectTaskBaseInfoOperateType.AuditAddress) {
    // 中心地址
    if (!editingAuditAddress.value) {
      ElMessage.warning(
        t(
          "Components.Project.ProjectTask.Report1.GeneralInfomation.PleaseInputAduitAddress"
        )
      );
      return;
    }
  }
  const trans = {
    sponsor: editingSponsor.value,
    audit_start_date:
      editingAuditDate.value && editingAuditDate.value.length === 2
        ? editingAuditDate.value[0]
        : undefined,
    audit_end_date:
      editingAuditDate.value && editingAuditDate.value.length === 2
        ? editingAuditDate.value[1]
        : undefined,
    report_date: editingReportDate.value,
    customer_project_manager: editingProjectManager.value,
    customer_inspector: editingInspector.value,
    center_number: editingCenterNumber.value,
    audit_address: editingAuditAddress.value,
    operate_type: operateType,
  };
  await request_projecttask_report_baseinfo_update(
    props.projectTaskGuid,
    trans
  );
  handleBaseInfoCancel();
  // 需要更新数据
  emits("getProjectTask");
};

const handlePartimeUserOk = async () => {
  if (sourceParttimeUser.value) {
    // 有数据代表修改
    const trans = {
      new_user_name: editingPartimeUser.value,
      old_user_name: sourceParttimeUser.value,
    };
    await request_projecttask_parttime_user_modify(
      props.projectTaskGuid,
      trans
    );
  } else {
    // 没有数据代表新增
    const trans = {
      new_user_name: editingPartimeUser.value,
    };
    await request_projecttask_parttime_user_add(props.projectTaskGuid, trans);
  }

  handleBaseInfoCancel();
  // 需要更新数据
  emits("getProjectTask");
};

const handleDeleteParttimeUser = async () => {
  if (!sourceParttimeUser.value) {
    ElMessage.warning(
      t(
        "Components.Project.ProjectTask.Report1.GeneralInfomation.NotFoundSourceParttimeUser"
      )
    );
    return;
  }
  const trans = {
    old_user_name: sourceParttimeUser.value,
  };
  await request_projecttask_parttime_user_delete(props.projectTaskGuid, trans);
  handleBaseInfoCancel();
  // 需要更新数据
  emits("getProjectTask");
};
// 取消编辑稽查日期
const handleBaseInfoCancel = () => {
  sponsorIsEdit.value = false;
  auditDateIsEdit.value = false;
  reportDateIsEdit.value = false;
  projectManagerIsEdit.value = false;
  inspectorIsEdit.value = false;
  centerNumberIsEdit.value = false;
  auditAddressIsEdit.value = false;
  parttimeUserIsEdit.value = false;
  editingSponsor.value = undefined;
  editingAuditDate.value = undefined;
  editingReportDate.value = undefined;
  editingPartimeUser.value = undefined;
  editingProjectManager.value = "";
  editingInspector.value = "";
  editingCenterNumber.value = "";
  editingSponsor.value = "";
  editingAuditAddress.value = "";
  editingPartimeUser.value = "";
};

const auditDisabledDate = (date) => {
  return date.getTime() > Date.now();
};
const reportDisabledDate = (date) => {
  return date.getTime() > Date.now();
};
// 拆分
watchEffect(() => {
  if (props.data) {
    attendees.value = props.data.attendees;
    dispatchUsers.value = props.data.dispatch_users;
    centerStatus.value = props.data.center_status;
    props.data.center_status.forEach((item) => {
      if (item.type === ProjectTaskCenterStatusType.Filter) {
        filterInput.value = item.text;
      } else if (item.type === ProjectTaskCenterStatusType.Import) {
        importInput.value = item.text;
      } else if (item.type === ProjectTaskCenterStatusType.JoinGroup) {
        joinGroupInput.value = item.text;
      } else if (item.type === ProjectTaskCenterStatusType.Exist) {
        exitInput.value = item.text;
      } else if (item.type === ProjectTaskCenterStatusType.FollowUp) {
        followUpInput.value = item.text;
      } else if (item.type === ProjectTaskCenterStatusType.Complete) {
        completeInput.value = item.text;
      }
    });

    if (props.data.project_task) {
      if (
        props.data.project_task.actual_audit_endtime &&
        props.data.project_task.actual_audit_starttime
      ) {
        sourceAuditDate.value = [
          props.data.project_task.actual_audit_starttime,
          props.data.project_task.actual_audit_endtime,
        ];
      }
      sourceAuditStartDateStr.value =
        props.data.project_task.actual_audit_starttime_str;
      sourceAuditEndDateStr.value =
        props.data.project_task.actual_audit_endtime_str;
      sourceReportDateStr.value =
        props.data.project_task.completed_reporting_date_str;
      sourceReportDate.value = props.data.project_task.completed_reporting_date;
      sourceProjectManager.value =
        props.data.project_task.customer_project_manager;
      sourceInspector.value = props.data.project_task.customer_inspector;
      sourceCenterNumber.value = props.data.project_task.center_number;
      sourceAuditAddress.value = props.data.project_task.audit_address;
      sourceSponsor.value = props.data.project.sponsor;
    }
  }
});

// 监听字典数据
watchEffect(() => {
  if (store.userInfo) {
    if (store.userInfo.dics && store.userInfo.dics.length > 0) {
      // 项目任务稽查人员角色
      const findDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.ProjectTaskAuditUserRole
      );
      if (findDic) {
        if (findDic.items && findDic.items.length > 0) {
          projectTaskAuditUserRoles.value = findDic.items;
        } else {
          projectTaskAuditUserRoles.value = [];
        }
      } else {
        projectTaskAuditUserRoles.value = [];
      }
    }
  }
});
</script>
<style>
</style>
<style scoped lang="scss">
.notread-comment-count {
  ::v-deep .el-badge__content {
    margin-top: 20px;
    font-size: 0.8em;
  }
}

.title-ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  .left-li {
    flex: 1;
  }
  .right-li {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.dd {
  display: flex;
  align-items: center;
  .el-space {
    margin-left: 4px;
  }
}
.user-div {
  display: flex;
  .auditor-div {
    display: flex;
  }
  .parttime-user-div {
    margin-left: 8px;
  }
}
</style>
