<template>
  <Dialog
    width="60%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form :model="form" label-width="128px" :rules="rules" ref="formRef">
      <el-row>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.WorkingHours.WorkingHours.TotalServiceFeeBeforeTax')"
            prop="total_service_fee_before_tax"
          >
            <el-input-number
              :min="0"
              :style="{ width: '100%' }"
              v-model="form.total_service_fee_before_tax"
              :placeholder="
                t(
                  'Components.WorkingHours.WorkingHours.PleaseInputTotalServiceFeeBeforeTax'
                )
              "
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.WorkingHours.WorkingHours.ContractNo')"
            prop="contract_no"
          >
            <el-input
              v-model="form.contract_no"
              :placeholder="
                t('Components.WorkingHours.WorkingHours.PleaseInputContractNo')
              "
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.WorkingHours.WorkingHours.ProjectNumber')"
            prop="project_guid"
          >
            <el-select
              disabled
              filterable
              :style="{ width: '100%' }"
              v-model="form.project_guid"
              :placeholder="
                $t('Components.WorkingHours.WorkingHours.PleaseSelectProjectNumber')
              "
              clearable
              @change="handleProjectSelectChange"
            >
              <el-option
                v-for="item in projects"
                :key="item.guid"
                :label="item.project_number"
                :value="item.guid"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12"
          ><el-form-item
            :label="$t('Components.WorkingHours.WorkingHours.ProjectTaskNumber')"
            prop="project_task_guid"
          >
            <el-select
              disabled
              filterable
              :style="{ width: '100%' }"
              v-model="form.project_task_guid"
              placeholder=" "
              clearable
            >
              <el-option
                v-for="item in projectTasks"
                :key="item.guid"
                :label="item.number"
                :value="item.guid"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('Components.WorkingHours.WorkingHours.Type')">
            <el-select
              :style="{ width: '100%' }"
              v-model="form.service_type"
              filterable
              clearable
              disabled
              :placeholder="t('Components.Project.ProjectList.PleaseSelectServiceType')"
            >
              <el-option
                v-for="item in props.serviceTypes"
                :key="item.guid"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="工时数据" v-if="computedIsAuditServiceType">
        <el-table :data="tableData" row-key="guid">
          <el-table-column label="使用时间(h)" fixed :width="120" align="center">
            <template #default="scope">
              {{
                scope.row.type === WorkingHoursDetailType.Plan
                  ? t("Components.WorkingHours.WorkingHours.Plan")
                  : scope.row.type === WorkingHoursDetailType.Actual
                  ? t("Components.WorkingHours.WorkingHours.Actual")
                  : ""
              }}
            </template>
          </el-table-column>
          <!-- 工时稽查准备 -->
          <el-table-column
            align="center"
            :label="props.workingHoursAuditReady ? props.workingHoursAuditReady.name : ''"
          >
            <el-table-column
              v-for="item in props.workingHoursAuditReadys"
              :key="item.guid"
              :label="item.name"
              :width="160"
              align="center"
            >
              <template #default="scope">
                <WorkingHoursInput
                  :type="scope.row.type"
                  :dicGuid="
                    props.workingHoursAuditReady ? props.workingHoursAuditReady.guid : ''
                  "
                  :dicItemGuid="item.dic_guid"
                  :dicItemValue="item.value"
                  @inputChange="handleInputChange"
                  :time="computedSingleTime(scope.row, item.dic_guid, item.value)"
                />
              </template>
            </el-table-column>
          </el-table-column>
          <!-- 现场稽查 -->
          <el-table-column
            align="center"
            :label="
              props.workingHoursOnsideAudit ? props.workingHoursOnsideAudit.name : ''
            "
          >
            <el-table-column
              v-for="item in props.workingHoursOnsideAudits"
              :key="item.guid"
              :label="item.name"
              :width="160"
              align="center"
            >
              <template #default="scope">
                <WorkingHoursInput
                  :type="scope.row.type"
                  :dicGuid="
                    props.workingHoursOnsideAudit
                      ? props.workingHoursOnsideAudit.guid
                      : ''
                  "
                  :dicItemGuid="item.dic_guid"
                  :dicItemValue="item.value"
                  :time="computedSingleTime(scope.row, item.dic_guid, item.value)"
                  @inputChange="handleInputChange"
                />
              </template>
            </el-table-column>
          </el-table-column>
          <!-- 后续工作 -->
          <el-table-column
            align="center"
            :label="props.workingHoursFollowup ? props.workingHoursFollowup.name : ''"
          >
            <el-table-column
              v-for="item in props.workingHoursFollowups"
              :key="item.guid"
              :label="item.name"
              :width="160"
              align="center"
            >
              <template #default="scope">
                <WorkingHoursInput
                  :type="scope.row.type"
                  :dicGuid="
                    props.workingHoursFollowup ? props.workingHoursFollowup.guid : ''
                  "
                  :dicItemGuid="item.dic_guid"
                  :dicItemValue="item.value"
                  :time="computedSingleTime(scope.row, item.dic_guid, item.value)"
                  @inputChange="handleInputChange"
                />
              </template>
            </el-table-column>
          </el-table-column>
          <!-- 差旅 -->
          <el-table-column
            align="center"
            :label="
              props.workingHoursBusinessTrip ? props.workingHoursBusinessTrip.name : ''
            "
          >
            <el-table-column
              v-for="item in props.workingHoursBusinessTrips"
              :key="item.guid"
              :label="item.name"
              :width="160"
              align="center"
            >
              <template #default="scope">
                <WorkingHoursInput
                  :type="scope.row.type"
                  :dicGuid="
                    props.workingHoursBusinessTrip
                      ? props.workingHoursBusinessTrip.guid
                      : ''
                  "
                  :dicItemGuid="item.dic_guid"
                  :dicItemValue="item.value"
                  :time="computedSingleTime(scope.row, item.dic_guid, item.value)"
                  @inputChange="handleInputChange"
                />
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-row v-if="!computedIsAuditServiceType">
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.WorkingHours.WorkingHours.PlanWorkingHours')"
          >
            <el-input
              v-model="planHours"
              :placeholder="
                t('Components.WorkingHours.WorkingHours.PleaseInputPlanWorkingHours')
              "
              @change="handlePlanHoursChange"
            ></el-input> </el-form-item
        ></el-col>
        <el-col :span="12">
          <el-form-item
            v-if="!computedIsAuditServiceType"
            :label="$t('Components.WorkingHours.WorkingHours.ActualWorkingHours')"
          >
            <el-input
              v-model="actualHours"
              :placeholder="
                t('Components.WorkingHours.WorkingHours.PleaseInputActualWorkingHours')
              "
              @change="handleActualHoursChange"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.WorkingHours.WorkingHours.UnitPrice')"
            prop="unit_price"
          >
            <el-input-number
              :style="{ width: '100%' }"
              v-model="form.unit_price"
              :min="0"
              :placeholder="
                t('Components.WorkingHours.WorkingHours.PleaseInputUnitPrice')
              "
              @change="handleUnitPriceChange"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.WorkingHours.WorkingHours.Discount')"
            prop="discount"
          >
            <el-input-number
              :style="{ width: '100%' }"
              v-model="form.discount"
              :min="0"
              :max="1"
              :placeholder="t('Components.WorkingHours.WorkingHours.PleaseInputDiscount')"
              @change="handleDiscountChange"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-form-item
        v-if="computedIsAuditServiceType"
        :label="$t('Components.WorkingHours.WorkingHours.OtherAmount')"
        prop="other_amount"
      >
        <el-input-number
          :style="{ width: '100%' }"
          v-model="form.other_amount"
          :min="0"
          :placeholder="t('Components.WorkingHours.WorkingHours.PleaseInputOtherAmount')"
          @change="handleOtherAmountChange"
        />
      </el-form-item> -->
      <el-row>
        <el-col :span="12">
          <el-form-item
            v-if="computedIsAuditServiceType"
            :label="$t('Components.WorkingHours.WorkingHours.ParttimeJobConversion')"
            prop="parttime_job_conversion"
          >
            <el-input-number
              :style="{ width: '100%' }"
              v-model="form.parttime_job_conversion"
              :min="0"
              :placeholder="
                t('Components.WorkingHours.WorkingHours.PleaseInputParttimeJobConversion')
              "
              @change="handleParttimeJobConversionChange"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            v-if="computedIsAuditServiceType"
            :label="$t('Components.WorkingHours.WorkingHours.TeachingConversion')"
            prop="teaching_conversion"
          >
            <el-input-number
              :style="{ width: '100%' }"
              v-model="form.teaching_conversion"
              :min="0"
              :placeholder="
                t('Components.WorkingHours.WorkingHours.PleaseInputTeachingConversion')
              "
              @change="handleTeachingConversionChange"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="computedIsAuditServiceType">
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.WorkingHours.WorkingHours.PlanTotalHours')"
            prop="plan_total_hours"
          >
            <el-input-number
              :style="{ width: '100%' }"
              v-model="form.plan_total_hours"
              :min="0"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.WorkingHours.WorkingHours.PlanTotalAmount')"
            prop="plan_total_amount"
          >
            <el-input-number
              :style="{ width: '100%' }"
              v-model="form.plan_total_amount"
              :min="0"
              disabled
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="computedIsAuditServiceType">
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.WorkingHours.WorkingHours.ActualTotalHours')"
            prop="actual_total_hours"
          >
            <el-input-number
              :style="{ width: '100%' }"
              v-model="form.actual_total_hours"
              :min="0"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.WorkingHours.WorkingHours.ActualTotalAmount')"
            prop="actual_total_amount"
          >
            <el-input-number
              :style="{ width: '100%' }"
              v-model="form.actual_total_amount"
              :min="0"
              disabled
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="!computedIsAuditServiceType">
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.WorkingHours.WorkingHours.PlanTotalAmount')"
            prop="plan_total_amount"
          >
            <el-input-number
              :style="{ width: '100%' }"
              v-model="form.plan_total_amount"
              :min="0"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.WorkingHours.WorkingHours.ActualTotalAmount')"
            prop="actual_total_amount"
          >
            <el-input-number
              :style="{ width: '100%' }"
              v-model="form.actual_total_amount"
              :min="0"
              disabled
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            :label="props.auditType === WorkingHoursType.Audit ? $t('Components.WorkingHours.WorkingHours.User') : $t('Components.WorkingHours.WorkingHours.User1')"
            prop="user_guid"
          >
            <el-select
              filterable
              :style="{ width: '100%' }"
              v-model="form.user_guid"
              :placeholder="$t('Components.WorkingHours.WorkingHours.PleaseSelectUser')"
              clearable
            >
              <el-option
                v-for="item in users"
                :key="item.guid"
                :label="item.name"
                :value="item.guid"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.WorkingHours.WorkingHours.Time')"
            prop="startEndTime"
          >
            <el-date-picker
              v-model="form.startEndTime"
              type="daterange"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              range-separator=" - "
              :start-placeholder="
                t('Components.WorkingHours.WorkingHours.PleaseSelectStartTime')
              "
              :end-placeholder="
                t('Components.WorkingHours.WorkingHours.PleaseSelectEndTime')
              "
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
        :label="$t('Components.WorkingHours.WorkingHours.WorkContent')"
        prop="work_content"
      >
        <el-input
          v-model="form.work_content"
          type="textarea"
          :placeholder="t('Components.WorkingHours.WorkingHours.PleaseInputWorkContent')"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('Components.WorkingHours.WorkingHours.Remark')"
        prop="remark"
      >
        <el-input
          v-model="form.remark"
          type="textarea"
          :placeholder="t('Components.WorkingHours.WorkingHours.PleaseInputRemark')"
        ></el-input>
      </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup>
import {
  ref,
  reactive,
  markRaw,
  onMounted,
  defineProps,
  defineEmits,
  computed,
  watchEffect,
} from "vue";
import { WarnTriangleFilled } from "@element-plus/icons-vue";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { useI18n } from "vue-i18n";
import {
  request_workinghours_add,
  request_workinghours_modify,
  request_projecttask_all_byproject,
} from "@/api/apis";
import { ElMessageBox } from "element-plus";
import { WorkingHoursDetailType , WorkingHoursType} from "@/consts/enum";
import WorkingHoursInput from "@/components/WorkingHours/WorkingHours/WorkingHoursInput.vue";
import { useStore } from "@/store/index";
import { SystemParamCode } from "@/consts/systemParam";

const store = useStore();

const { t } = useI18n();
const props = defineProps({
  editData: {
    type: Object,
    default: () => {},
  },
  projects: {
    type: Array,
    default: () => [],
  },
  serviceTypes: {
    type: Array,
    default: () => [],
  },
  users: {
    type: Array,
    default: () => [],
  },
  initProjectTasks: {
    type: Array,
    default: () => [],
  },
  auditType:{
    type: Number,
    default: ()=> WorkingHoursType.Audit
  },

  workingHoursAuditReadys: {
    type: Array,
    default: () => [],
  },
  workingHoursOnsideAudits: {
    type: Array,
    default: () => [],
  },
  workingHoursFollowups: {
    type: Array,
    default: () => [],
  },
  workingHoursBusinessTrips: {
    type: Array,
    default: () => [],
  },

  workingHoursAuditReady: {
    type: Object,
    default: () => {},
  },
  workingHoursOnsideAudit: {
    type: Object,
    default: () => {},
  },
  workingHoursFollowup: {
    type: Object,
    default: () => {},
  },
  workingHoursBusinessTrip: {
    type: Object,
    default: () => {},
  },
});
console.log(props.editData)
const emits = defineEmits(["handleCloseDialog", "getWorkingHoursPage"]);
// 标题
const dialogTitle = ref("");
// 表单ref
const formRef = ref();
// 表单数据
const form = reactive({
  contract_no: props.editData.contract_no,
  project_guid: props.editData.project_guid,
  project_task_guid: undefined,
  type: props.editData.type,
  service_type: props.editData.service_type,
  user_guid: props.editData.user_guid,
  startEndTime: props.editData.startEndTime,
  work_content: props.editData.work_content,
  remark: props.editData.remark,
  unit_price: props.editData.unit_price ? parseFloat(props.editData.unit_price) : 0,
  discount: props.editData.discount ? parseFloat(props.editData.discount) : 1,
  plan_total_amount: props.editData.plan_total_amount
    ? parseFloat(props.editData.plan_total_amount)
    : 0,
  plan_total_hours: props.editData.plan_total_hours,
  actual_total_amount: props.editData.actual_total_amount
    ? parseFloat(props.editData.actual_total_amount)
    : 0,
  actual_total_hours: props.editData.actual_total_hours,
  // other_amount: props.editData.other_amount ? parseFloat(props.editData.other_amount) : 0,
  other_amount: 0,
  total_service_fee_before_tax: props.editData.total_service_fee_before_tax,
  parttime_job_conversion: props.editData.parttime_job_conversion ? parseFloat(props.editData.parttime_job_conversion) : 0,
  teaching_conversion: props.editData.teaching_conversion ? parseFloat(props.editData.teaching_conversion) : 0,
});

const projectTasks = ref(props.initProjectTasks);

const tableData = ref([]);
const planHours = ref(0); // 非稽查类型计划工时
const actualHours = ref(0); // 非稽查类型实际工时
const auditServiceTypeSystemParam = ref(undefined);

const computedSingleTime = computed(() => (row, dic_guid, dic_item_value) => {
  if (row.details && row.details.length > 0) {
    const findData = row.details.find(
      (item) => item.dic_guid == dic_guid && item.dic_item_value === dic_item_value
    );
    if (findData) {
      return findData.time;
    }
  }
});

// 表单校验规则
const rules = ref({
  total_service_fee_before_tax: [
    {
      required: true,
      message: t("Components.WorkingHours.WorkingHours.PleaseInputTotalServiceFeeBeforeTax"),
      trigger: "blur",
    },
  ],
  contract_no: [
    {
      required: true,
      message: t("Components.WorkingHours.WorkingHours.PleaseInputContractNo"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.WorkingHours.WorkingHours.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  // project_guid: [
  //   {
  //     required: true,
  //     message: t(
  //       "Components.WorkingHours.WorkingHours.PleaseSelectProjectNumber"
  //     ),
  //     trigger: "blur",
  //   },
  //   {
  //     max: 100,
  //     message: t("Components.WorkingHours.WorkingHours.ValidateMaxLength"),
  //     trigger: "change",
  //   },
  // ],
  // project_task_guid: [
  //   {
  //     required: true,
  //     message: t(
  //       "Components.WorkingHours.WorkingHours.PleaseSelectProjectTaskNumber"
  //     ),
  //     trigger: "blur",
  //   },
  //   {
  //     max: 100,
  //     message: t("Components.WorkingHours.WorkingHours.ValidateMaxLength"),
  //     trigger: "change",
  //   },
  // ],
  type: [
    {
      required: true,
      message: t("Components.WorkingHours.WorkingHours.PleaseSelectType"),
      trigger: "blur",
    },
  ],
  unit_price: [
    {
      required: true,
      message: t("Components.WorkingHours.WorkingHours.PleaseInputUnitPrice"),
      trigger: "blur",
    },
  ],
  discount: [
    {
      required: true,
      message: t("Components.WorkingHours.WorkingHours.PleaseInputDiscount"),
      trigger: "blur",
    },
  ],
  total_hours: [
    {
      required: true,
      message: t("Components.WorkingHours.WorkingHours.PleaseInputTotalHours"),
      trigger: "blur",
    },
  ],
  total_amount: [
    {
      required: true,
      message: t("Components.WorkingHours.WorkingHours.PleaseInputTotalAmount"),
      trigger: "blur",
    },
  ],
  other_amount: [
    {
      required: true,
      message: t("Components.WorkingHours.WorkingHours.PleaseInputOtherAmount"),
      trigger: "blur",
    },
  ],
  parttime_job_conversion: [
    {
      required: true,
      message: t("Components.WorkingHours.WorkingHours.PleaseInputParttimeJobConversion"),
      trigger: "blur",
    },
  ],
  teaching_conversion: [
    {
      required: true,
      message: t("Components.WorkingHours.WorkingHours.PleaseInputTeachingConversion"),
      trigger: "blur",
    },
  ],
  user_guid: [
    {
      required: true,
      message: t("Components.WorkingHours.WorkingHours.PleaseSelectUser"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.WorkingHours.WorkingHours.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  startEndTime: [
    {
      required: true,
      message: t("Components.WorkingHours.WorkingHours.PleaseSelectStartEndTime"),
      trigger: "change",
    },
  ],
});

onMounted(async () => {
  if (props.editData && props.editData.guid) {
    // 代表修改
    dialogTitle.value = t(
      "Components.WorkingHours.WorkingHours.DialogWorkingHoursTitleMaintance"
    );
    if(!computedIsAuditServiceType.value && props.editData.details && props.editData.details.length > 0){ // 非稽查类型
      const planData = props.editData.details.find(item=>item.type === WorkingHoursDetailType.Plan) // 计划数据
      const actualData = props.editData.details.find(item=>item.type === WorkingHoursDetailType.Actual) // 实际数据
      if(planData){
        planHours.value = planData.time
      }
      if(actualData){
        actualHours.value = actualData.time
      }
    }
  } else {
    dialogTitle.value = t(
      "Components.WorkingHours.WorkingHours.DialogWorkingHoursTitleAdd"
    );
  }
});

const computedIsAuditServiceType = computed(() => {
  return form.service_type === auditServiceTypeSystemParam.value.value;
});
// 处理提交事件
const handleCommit = async () => {
  if (!formRef.value) return;
  await formRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(
        t("Components.WorkingHours.WorkingHours.ConfirmSaveWorkingHours"),
        t("Common.ElMessageBox.OperateConfirm"),
        {
          confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
          cancelButtonText: t("Common.ElMessageBox.CancelText"),
          type: "warning",
          icon: markRaw(WarnTriangleFilled),
        }
      )
        .then(async () => {
          await saveWorkingHours();
        })
        .catch(() => {});
    }
  });
};
// 保存工时数据
const saveWorkingHours = async () => {
  const dd = [];
  if (computedIsAuditServiceType.value) {
    // 稽查类型
    tableData.value.forEach((item) => {
      item.details.forEach((item1) => {
        dd.push({
          dic_guid: item1.dic_guid,
          dic_item_value: item1.dic_item_value,
          time: item1.time,
          type: item.type,
        });
      });
    });
  } else {
    // 非稽查类型
    dd.push({
      time: planHours.value,
      type: WorkingHoursDetailType.Plan,
    });
    dd.push({
      time: actualHours.value,
      type: WorkingHoursDetailType.Actual,
    });
  }

  const trans = {
    contract_no: form.contract_no,
    project_guid: form.project_guid,
    project_task_guid: form.project_task_guid,
    user_guid: form.user_guid,
    start_time: form.startEndTime[0],
    end_time: form.startEndTime[1],
    work_content: form.work_content,
    remark: form.remark,
    unit_price: form.unit_price,
    discount: form.discount,
    total_amount: form.total_amount,
    total_hours: form.total_hours,
    other_amount: form.other_amount,
    parttime_job_conversion: form.parttime_job_conversion,
    teaching_conversion: form.teaching_conversion,
    details: dd,
    total_service_fee_before_tax: form.total_service_fee_before_tax,
  };
  if (props.editData && props.editData.guid) {
    // 修改
    await request_workinghours_modify(props.editData.guid, trans);
  } else {
    // 新增
    await request_workinghours_add(trans);
  }
  emits("getWorkingHoursPage");
  emits("handleCloseDialog");
};
// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};
// 处理项目改变事件
const handleProjectSelectChange = async (data) => {
  form.project_task_guid = "";
  // 重新计算下费用
  if (computedIsAuditServiceType.value) {
    // 是稽查类型的
    form.plan_total_amount = parseFloat(
      (
        form.plan_total_hours * form.unit_price * (form.discount ? form.discount : 1) 
      ).toFixed(2)
    );
    form.actual_total_amount = parseFloat(
      (
        form.actual_total_hours * form.unit_price * (form.discount ? form.discount : 1) +
        form.other_amount +
        form.parttime_job_conversion +
        form.teaching_conversion
      ).toFixed(2)
    );
  } else {
    // 非稽查类型的
    form.plan_total_amount = parseFloat(
      (planHours.value * form.unit_price * (form.discount ? form.discount : 1)).toFixed(2)
    );
    form.actual_total_amount = parseFloat(
      (actualHours.value * form.unit_price * (form.discount ? form.discount : 1)).toFixed(
        2
      )
    );
  }

  const trans = {
    project_guid: data,
  };
  const result = await request_projecttask_all_byproject(trans);
  projectTasks.value = result;
};
// 处理输入改变事件
const handleInputChange = (data) => {
  const findData = tableData.value.find((item) => item.type === data.type);
  if (findData) {
    if (findData.details) {
      const findDicData = findData.details.find(
        (item) =>
          item.dic_guid === data.dicGuid && item.dic_item_value === data.dicItemValue
      );
      if (findDicData) {
        findDicData.time = data.input;
        // 计算一遍所有的工时和费用
        let totalHours = 0;
        findData.details.forEach((item) => {
          totalHours += item.time;
        });
        if (data.type === WorkingHoursDetailType.Plan) {
          // 计划
          form.plan_total_amount = parseFloat(
            (form.unit_price
              ? form.unit_price * totalHours * (form.discount ? form.discount: 1)
              : 0 * (form.discount ? form.discount : 1)
            ).toFixed(2)
          );
          form.plan_total_hours = totalHours;
        } else if (data.type === WorkingHoursDetailType.Actual) {
          // 实际
          form.actual_total_amount = parseFloat(
            (form.unit_price
              ? form.unit_price * totalHours
              : 0 * (form.discount ? form.discount : 1) +
                form.other_amount +
                form.parttime_job_conversion +
                form.teaching_conversion
            ).toFixed(2)
          );
          form.actual_total_hours = totalHours;
        }
      }
    }
  }
};
// 单价改变事件
const handleUnitPriceChange = (value) => {
  if (value) {
    form.plan_total_amount = parseFloat(
      (
        form.plan_total_hours * value * (form.discount ? form.discount : 1)
      ).toFixed(2)
    );
    form.actual_total_amount = parseFloat(
      (
        form.actual_total_hours * value * (form.discount ? form.discount : 1) +
        form.other_amount +
        form.parttime_job_conversion +
        form.teaching_conversion
      ).toFixed(2)
    );
  } else {
    form.plan_total_amount = 0;
    form.actual_total_amount = 0;
  }
};
// 折扣改变事件
const handleDiscountChange = (value) => {
  if (value) {
    if (computedIsAuditServiceType.value) {
      form.plan_total_amount = parseFloat(
        (
          form.plan_total_hours * form.unit_price * value
        ).toFixed(2)
      );
      form.actual_total_amount = parseFloat(
        (
          form.actual_total_hours * form.unit_price * value +
          form.other_amount +
          form.parttime_job_conversion +
          form.teaching_conversion
        ).toFixed(2)
      );
    } else {
      form.plan_total_amount = parseFloat(
        (planHours.value * value * form.unit_price).toFixed(2)
      );
      form.actual_total_amount = parseFloat(
        (actualHours.value * value * form.unit_price).toFixed(2)
      );
    }
  }
};
// 兼职折算改变事件
const handleParttimeJobConversionChange = (value) => {
  if (value) {
    form.plan_total_amount = parseFloat(
      (form.plan_total_hours * form.unit_price * form.discount).toFixed(2)
    );
    form.actual_total_amount = parseFloat(
      (form.actual_total_hours * form.unit_price * form.discount + value + form.other_amount + form.teaching_conversion).toFixed(2)
    );
  }
};
// 带教折算改变事件
const handleTeachingConversionChange = (value) => {
  if (value) {
    form.plan_total_amount = parseFloat(
      (form.plan_total_hours * form.unit_price * form.discount).toFixed(2)
    );
    form.actual_total_amount = parseFloat(
      (form.actual_total_hours * form.unit_price * form.discount + value + form.parttime_job_conversion + form.other_amount).toFixed(2)
    );
  }
};
// 处理非稽查类型计划工时改变事件
const handlePlanHoursChange = (value) => {
  if (value) {
    form.plan_total_amount = parseFloat(
      (value * form.unit_price * form.discount).toFixed(2)
    );
  }
};
// 处理非稽查类型实际工时改变事件
const handleActualHoursChange = (value) => {
  if (value) {
    form.actual_total_amount = parseFloat(
      (value * form.unit_price * form.discount).toFixed(2)
    );
  }
};

watchEffect(() => {
  const findProjectTask = projectTasks.value.find(
    (item) => item.guid === props.editData.project_task_guid
  );
  if (findProjectTask) {
    form.project_task_guid = props.editData.project_task_guid;
  }
});

watchEffect(() => {
  const planDetailsData = [];
  const actualDetailData = [];
  props.workingHoursAuditReadys.forEach((item) => {
    // 找到对应的time数据
    let planTime = 0;
    let actualTime = 0;
    if (props.editData && props.editData.guid) {
      const findPlanTimeData = props.editData.details.find(
        (item1) =>
          item1.dic_guid === item.dic_guid &&
          item1.dic_item_value === item.value &&
          item1.type === WorkingHoursDetailType.Plan
      );
      if (findPlanTimeData) {
        planTime = findPlanTimeData.time;
      }
      const findActualTimeData = props.editData.details.find(
        (item1) =>
          item1.dic_guid === item.dic_guid &&
          item1.dic_item_value === item.value &&
          item1.type === WorkingHoursDetailType.Actual
      );
      if (findActualTimeData) {
        actualTime = findActualTimeData.time;
      }
    }
    planDetailsData.push({
      dic_guid: item.dic_guid,
      dic_item_value: item.value,
      dic_item_name: item.name,
      time: planTime,
      type: WorkingHoursDetailType.Plan,
    });
    actualDetailData.push({
      dic_guid: item.dic_guid,
      dic_item_value: item.value,
      dic_item_name: item.name,
      time: actualTime,
      type: WorkingHoursDetailType.Actual,
    });
  });
  props.workingHoursOnsideAudits.forEach((item) => {
    // 找到对应的time数据
    let planTime = 0;
    let actualTime = 0;
    if (props.editData && props.editData.guid) {
      const findPlanTimeData = props.editData.details.find(
        (item1) =>
          item1.dic_guid === item.dic_guid &&
          item1.dic_item_value === item.value &&
          item1.type === WorkingHoursDetailType.Plan
      );
      if (findPlanTimeData) {
        planTime = findPlanTimeData.time;
      }
      const findActualTimeData = props.editData.details.find(
        (item1) =>
          item1.dic_guid === item.dic_guid &&
          item1.dic_item_value === item.value &&
          item1.type === WorkingHoursDetailType.Actual
      );
      if (findActualTimeData) {
        actualTime = findActualTimeData.time;
      }
    }
    planDetailsData.push({
      dic_guid: item.dic_guid,
      dic_item_value: item.value,
      dic_item_name: item.name,
      time: planTime,
      type: WorkingHoursDetailType.Plan,
    });
    actualDetailData.push({
      dic_guid: item.dic_guid,
      dic_item_value: item.value,
      dic_item_name: item.name,
      time: actualTime,
      type: WorkingHoursDetailType.Actual,
    });
  });
  props.workingHoursFollowups.forEach((item) => {
    // 找到对应的time数据
    let planTime = 0;
    let actualTime = 0;
    if (props.editData && props.editData.guid) {
      const findPlanTimeData = props.editData.details.find(
        (item1) =>
          item1.dic_guid === item.dic_guid &&
          item1.dic_item_value === item.value &&
          item1.type === WorkingHoursDetailType.Plan
      );
      if (findPlanTimeData) {
        planTime = findPlanTimeData.time;
      }
      const findActualTimeData = props.editData.details.find(
        (item1) =>
          item1.dic_guid === item.dic_guid &&
          item1.dic_item_value === item.value &&
          item1.type === WorkingHoursDetailType.Actual
      );
      if (findActualTimeData) {
        actualTime = findActualTimeData.time;
      }
    }
    planDetailsData.push({
      dic_guid: item.dic_guid,
      dic_item_value: item.value,
      dic_item_name: item.name,
      time: planTime,
      type: WorkingHoursDetailType.Plan,
    });
    actualDetailData.push({
      dic_guid: item.dic_guid,
      dic_item_value: item.value,
      dic_item_name: item.name,
      time: actualTime,
      type: WorkingHoursDetailType.Actual,
    });
  });
  props.workingHoursBusinessTrips.forEach((item) => {
    // 找到对应的time数据
    let planTime = 0;
    let actualTime = 0;
    if (props.editData && props.editData.guid) {
      const findPlanTimeData = props.editData.details.find(
        (item1) =>
          item1.dic_guid === item.dic_guid &&
          item1.dic_item_value === item.value &&
          item1.type === WorkingHoursDetailType.Plan
      );
      if (findPlanTimeData) {
        planTime = findPlanTimeData.time;
      }
      const findActualTimeData = props.editData.details.find(
        (item1) =>
          item1.dic_guid === item.dic_guid &&
          item1.dic_item_value === item.value &&
          item1.type === WorkingHoursDetailType.Actual
      );
      if (findActualTimeData) {
        actualTime = findActualTimeData.time;
      }
    }
    planDetailsData.push({
      dic_guid: item.dic_guid,
      dic_item_value: item.value,
      dic_item_name: item.name,
      time: planTime,
      type: WorkingHoursDetailType.Plan,
    });
    actualDetailData.push({
      dic_guid: item.dic_guid,
      dic_item_value: item.value,
      dic_item_name: item.name,
      time: actualTime,
      type: WorkingHoursDetailType.Actual,
    });
  });
  tableData.value = [
    {
      type: WorkingHoursDetailType.Plan,
      details: planDetailsData,
    },
    {
      type: WorkingHoursDetailType.Actual,
      details: actualDetailData,
    },
  ];
});
// 监听字典数据
watchEffect(() => {
  if (store.userInfo) {
    if (store.userInfo.system_params && store.userInfo.system_params.length > 0) {
      // 服务类型
      const findServiceType = store.userInfo.system_params.find(
        (item) => item.code === SystemParamCode.AuditServiceType
      );
      if (findServiceType) {
        auditServiceTypeSystemParam.value = findServiceType;
      }
    }
  }
});
</script>
<style scoped lang="scss"></style>
