// 字典编码配置
export const Dics = {
  System:{
    AnnouncementType: 'AnnouncementType', // 通知公告类型
    AuditedPartyCategory: 'AuditedPartyCategory', // 被稽查方分类
    ClientCategory: 'ClientCategory', // 委托方分类
    AuditType: 'AuditType', // 稽查类型
    TrialStaging: 'TrialStaging', // 试验分期
    ResearchProductType: 'ResearchProductType', // 研究产品类型
    SponsorType: 'SponsorType', // 申办者类型
    TherapeuticArea: 'TherapeuticArea', // 治疗领域
    TrialProgressStage: 'TrialProgressStage', // 试验进展阶段
    ServiceType: 'ServiceType', // 服务类型
    ProjectTaskAuditUserRole: 'ProjectTaskAuditUserRole', // 项目任务稽查人员角色
    DiscoveryGrading: 'DiscoveryGrading', // 发现分级
    DiscoveryMainCategory:'DiscoveryMainCategory', // 发现主分类
    DiscoverySubCategory: 'DiscoverySubCategory', // 发现子分类
    PresetText: 'PresetText', // 预置文本
    DiscoveryLevel: 'DiscoveryLevel', // 发现等级
    WorkingHoursAuditReady: 'WorkingHoursAuditReady', // 工时稽查准备
    WorkingHoursOnsideAudit: 'WorkingHoursOnsideAudit', // 工时现场稽查
    WorkingHoursFollowup: 'WorkingHoursFollowup', // 工时后续工作
    WorkingHoursBusinessTrip: 'WorkingHoursBusinessTrip', // 工时差旅时间
    AuditedPartyArea:'AuditedPartyArea', // 被稽查方区域
    AuditedPartyQualification:'AuditedPartyQualification', // 被稽查方资质
    HospitalType:'HospitalType', // 医院类型
  },
  PresetText:{
    Title: 'PresetText',
    ClientAuditRequirements: '委托方稽查要求'
  }
}