<template>
  <ul class="menu-left-ul">
    <li class="hm-search">
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item :label="$t('Components.System.Menu.Search')">
          <el-input
            v-model="searchForm.inputSearch"
            :placeholder="$t('Components.System.Menu.NameCode')"
            clearable
          />
        </el-form-item>
        <el-form-item :label="$t('Components.System.Menu.Dataflag')">
          <el-select
            v-model="searchForm.dataflag"
            :placeholder="$t('Components.System.Menu.SelectDataflag')"
            clearable
          >
            <el-option
              :label="$t('Components.System.Menu.DataflagY')"
              value="true"
            />
            <el-option
              :label="$t('Components.System.Menu.DataflagN')"
              value="false"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryClick" :icon="Search">{{
            $t("Components.System.Menu.Search1")
          }}</el-button>
          <el-button @click="resetClick" :icon="RefreshLeft">{{
            $t("Components.System.Menu.Reset")
          }}</el-button>
        </el-form-item>
      </el-form>
    </li>
    <li class="hm-operate">
      <!-- 操作部分 -->
      <el-button
        :style="{ color: 'white' }"
        :icon="Plus"
        :color="ButtonColor.Add"
        @click="
          () => {
            addParentGuid = '';
            dialogVisible = true;
          }
        "
        >{{ $t("Components.System.Menu.Add") }}</el-button
      >
      <el-button
        v-if="isExpand"
        :style="{ color: 'white' }"
        :icon="Fold"
        :color="ButtonColor.ExpandFold"
        @click="handleExpandFold"
        >{{ $t("Components.System.Menu.FoldAll") }}</el-button
      >
      <el-button
        v-else
        :style="{ color: 'white' }"
        :icon="Expand"
        :color="ButtonColor.ExpandFold"
        @click="handleExpandFold"
        >{{ $t("Components.System.Menu.ExpandAll") }}</el-button
      >
    </li>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <el-table
          :data="tableData"
          row-key="guid"
          :expand-row-keys="expandRowKeys"
          v-loading="tableLoading"
          @row-click="handleRowClick"
          :row-class-name="tableRowClassName"
        >
          <el-table-column
            prop="cn_name"
            min-width="120"
            :label="$t('Components.System.Menu.CNName')"
          />
          <el-table-column
            prop="en_name"
            min-width="120"
            :label="$t('Components.System.Menu.ENName')"
          />
          <el-table-column
            prop="code"
            min-width="120"
            :label="$t('Components.System.Menu.Code')"
          />
          <el-table-column
            prop="icon"
            :label="$t('Components.System.Menu.Icon')"
            width="80"
          >
            <template #default="scope">
              <el-icon v-if="scope.row.icon">
                <component :is="scope.row.icon" />
              </el-icon>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="path"
            :label="$t('Components.System.Menu.Path')"
          /> -->
          <el-table-column
            prop="sort"
            :label="$t('Components.System.Menu.Sort')"
            sortable=""
            width="80"
          />
          <el-table-column
            prop="dataflag"
            :label="$t('Components.System.Menu.Dataflag')"
            width="80"
          >
            <template #default="scope">
              <el-tag v-if="scope.row.dataflag" type="" effect="dark">{{
                $t("Components.System.Menu.DataflagY")
              }}</el-tag>
              <el-tag
                v-else
                :style="{ color: 'white' }"
                :color="ButtonColor.DataflagN"
                >{{ $t("Components.System.Menu.DataflagN") }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('Components.System.Menu.Operate')"
            width="200"
            fixed="right"
          >
            <template #default="scope">
              <el-space wrap>
                <el-button
                  :style="{ color: 'white' }"
                  :icon="Plus"
                  :color="ButtonColor.Add"
                  size="small"
                  @click.stop="
                    () => {
                      addParentGuid = scope.row.guid;
                      dialogVisible = true
                    }
                  "
                  >{{ $t("Components.System.Menu.Add") }}</el-button
                >
                <el-button
                  :style="{ color: 'white' }"
                  :icon="Edit"
                  :color="ButtonColor.Edit"
                  size="small"
                  @click.stop="handleEditMenu(scope.row)"
                  >{{ $t("Components.System.Menu.Edit") }}</el-button
                >
                <el-button
                  :style="{ color: 'white' }"
                  :icon="Delete"
                  :color="ButtonColor.Delete"
                  size="small"
                  bg
                  @click.stop="handleDelete(scope.row)"
                  >{{ $t("Components.System.Menu.Delete") }}</el-button
                >
                <el-button
                  v-if="!scope.row.dataflag"
                  :style="{ color: 'white' }"
                  :icon="Bell"
                  :color="ButtonColor.DataflagY"
                  size="small"
                  @click.stop="handleDataflag(scope.row)"
                  >{{ $t("Components.System.Menu.SetDataflagY") }}</el-button
                >
                <el-button
                  v-else
                  :style="{ color: 'white' }"
                  :icon="Bell"
                  :color="ButtonColor.DataflagN"
                  size="small"
                  @click.stop="handleDataflag(scope.row)"
                  >{{ $t("Components.System.Menu.SetDataflagN") }}</el-button
                >
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </li>
    </el-scrollbar>
  </ul>

  <MenuModify
    v-if="dialogVisible"
    @handleCloseDialog="handleCloseDialog"
    :menuDataAll="tableData"
    :lang="lang"
    @getMenuAll="getMenuAll"
    :editMenuData="editMenuData"
    :addParentGuid="addParentGuid"
  />
</template>
<script setup>
import { onMounted, reactive, ref, markRaw, defineEmits } from "vue";
import { request_menu_all } from "@/api/apis";
import {
  Search,
  RefreshLeft,
  Plus,
  Edit,
  Delete,
  Expand,
  Fold,
  Bell,
  WarnTriangleFilled,
} from "@element-plus/icons-vue";
import { ButtonColor } from "@/consts/frame";
import MenuModify from "@/components/System/Menu/MenuModify.vue";
import { LocalStorageKey, LangName } from "@/consts/frame";
import { request_menu_delete, request_menu_dataflag } from "@/api/apis";
import { ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const lang = ref(localStorage.getItem(LocalStorageKey.Lang) || LangName.ZHCN);

const emits = defineEmits(["handleMenuClick", "handleClearActiveMenu"]);

const searchForm = reactive({
  inputSearch: "",
  dataflag: undefined,
});
// 菜单数据
const tableData = ref([]);
// 列表加载
const tableLoading = ref(false);
// 是否展开
const isExpand = ref(false);
// 展开的行guid集合
const expandRowKeys = ref([]);
// 维护弹窗是否可见
const dialogVisible = ref(false);
// 编辑的菜单数据
const editMenuData = ref({});
// 添加父级guid
const addParentGuid = ref("");
// 激活的菜单guid
const activeMenuGuid = ref("");

onMounted(async () => {
  await getMenuAll();
});
// 获取所有菜单
const getMenuAll = async () => {
  const trans = {
    text: searchForm.inputSearch,
    dataflag: searchForm.dataflag,
  };
  tableLoading.value = true;
  const result = await request_menu_all(
    trans,
    () => (tableLoading.value = false)
  );
  tableData.value = result;

  activeMenuGuid.value = "";
  // 触发右侧清空
  emits("handleClearActiveMenu");
};

// 查询事件
const queryClick = async () => {
  await getMenuAll();
};
// 重置事件
const resetClick = async () => {
  searchForm.inputSearch = "";
  searchForm.dataflag = undefined;
  await getMenuAll();
};
// 处理展开和折叠
const handleExpandFold = () => {
  const guids = [];
  tableData.value.forEach((item) => {
    recursionAllMenu(item, guids);
  });
  if (isExpand.value) {
    expandRowKeys.value = [];
  } else {
    expandRowKeys.value = guids;
  }
  isExpand.value = !isExpand.value;
};
// 递归处理所有菜单
const recursionAllMenu = (menu, guids) => {
  guids.push(menu.guid);
  if (menu.children && menu.children.length > 0) {
    // 存在子数据
    menu.children.forEach((item) => {
      guids.push(item.guid);
      recursionAllMenu(item, guids);
    });
  }
};
// 编辑菜单
const handleEditMenu = (row) => {
  editMenuData.value = {
    guid: row.guid,
    cn_name: row.cn_name,
    en_name: row.en_name,
    icon: row.icon,
    parent_guid: row.parent_guid,
    path: row.path,
    sort: row.sort,
    code: row.code,
  };
  dialogVisible.value = true;
};
// 处理关闭事件
const handleCloseDialog = () => {
  dialogVisible.value = false;
  editMenuData.value = {};
  addParentGuid.value = "";
};
// 处理删除菜单事件
const handleDelete = async (row) => {
  ElMessageBox.confirm(
    t("Components.System.Menu.ConfirmDeleteMenu"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      await request_menu_delete(row.guid);
      dialogVisible.value = false;
      getMenuAll();
    })
    .catch(() => {});
};
// 处理设置菜单有效性
const handleDataflag = async (row) => {
  let title = "";
  if (row.dataflag) title = t("Components.System.Menu.ConfirmDataflagN");
  else title = t("Components.System.Menu.ConfirmDataflagY");
  ElMessageBox.confirm(title, t("Common.ElMessageBox.OperateConfirm"), {
    confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
    cancelButtonText: t("Common.ElMessageBox.CancelText"),
    type: "warning",
    icon: markRaw(WarnTriangleFilled),
  })
    .then(async () => {
      await request_menu_dataflag(row.guid);
      dialogVisible.value = false;
      getMenuAll();
    })
    .catch(() => {});
};
// 行点击事件
const handleRowClick = (row) => {
  activeMenuGuid.value = row.guid;
  emits("handleMenuClick", row.guid);
};
// 激活行的颜色
const tableRowClassName = ({ row }) => {
  if (row.guid === activeMenuGuid.value) {
    return "success-row";
  }
};
</script>
<style>
.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}
</style>
<style>
.menu-root-left-li .el-scrollbar__view {
  height: calc(100% - 8px);
}
</style>

<style scoped lang="scss">
.menu-left-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  .menu-left-ul-li-search {
  }
  .hm-table {
      height: calc(100%);
    .el-table {
      height: calc(100%);
    }
  }
}
</style>
