<template>
  <Dialog
    width="60%"
    :title="dialogTitle"
    :showFooter="false"
    @handleCancle="handleCancle"
  >
    <el-table v-loading="tableLoading" :data="tableData" row-key="guid">
      <el-table-column
        sortable
        prop="id"
        :label="$t('Components.Project.ProjectTask.AuditSummaryView.Id')"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        sortable
        show-overflow-tooltip
        :label="$t('Components.Project.ProjectTask.AuditSummaryView.Name')"
      >
      </el-table-column>
      <el-table-column
        prop="is_complete"
        sortable
        :label="
          $t('Components.Project.ProjectTask.AuditSummaryView.IsComplete')
        "
        width="120"
      >
        <template #default="scope">
          <el-tag v-if="scope.row.is_complete" type="success">{{
            t("Components.Project.ProjectTask.AuditSummaryView.HaveComplete")
          }}</el-tag>
          <el-tag v-else type="warning">{{
            t("Components.Project.ProjectTask.AuditSummaryView.NotComplete")
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="updatetime"
        sortable
        :label="
          $t('Components.Project.ProjectTask.AuditSummaryView.UpdateTime')
        "
        width="200"
      >
        <template #default="scope">
          <el-text>{{ scope.row.updatetime ? formatTime(new Date(scope.row.updatetime), 2) : '' }}</el-text>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('Components.Project.ProjectTask.AuditSummaryView.Operate')"
        width="120"
      >
        <template #default="scope">
          <el-button
            :disabled="computeEditButtonEnable"
            :style="{ color: 'white' }"
            :icon="Edit"
            :color="ButtonColor.Edit"
            size="small"
            @click="handleEdit(scope.row)"
            >{{
              $t("Components.Project.ProjectTask.AuditSummaryView.Modify")
            }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <AuditSummaryItemModify
      v-if="dialogVisible"
      :dialogTitle="currentEditName"
      :projectTaskGuid="props.projectTask.guid"
      :auditSummaryGuid="auditSummaryGuid"
      @handleCloseDialog="() => {
        dialogVisible = false;
        emits('getProjectTaskPage')
      }"
      @getProjectTaskAuditSummary="getProjectTaskAuditSummary"
    />
  </Dialog>
</template>
<script setup>
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import {
  onMounted,
  ref,
  computed,
  defineEmits,
  defineProps
} from "vue";
import { useI18n } from "vue-i18n";
import { request_projecttask_auditsummary_get } from "@/api/apis";
import { formatTime } from "@/util/timeUtil";
import { Edit } from "@element-plus/icons-vue";
import { ButtonColor } from "@/consts/frame";
import AuditSummaryItemModify from "@/components/Project/ProjectTask/AuditSummaryItemModify.vue";
import { ProjectTaskProgress } from "@/consts/enum";

const props = defineProps({
  projectTask: {
    type: Object,
    default: () => {},
  },
});

const { t } = useI18n();
const emits = defineEmits(["handleCloseDialog", 'getProjectTaskPage']);

const dialogTitle = ref("");
const tableLoading = ref(false);
const tableData = ref([]);
const dialogVisible = ref(false);
const auditSummaryGuid = ref("");
const currentEditName = ref("");

onMounted(() => {
  dialogTitle.value = t(
    "Components.Project.ProjectTask.AuditSummaryView.AuditSummary"
  );
  getProjectTaskAuditSummary();
});

const computeEditButtonEnable = computed(() => {
  // 完成状态不可以修改
  return (
    props.projectTask.progress === ProjectTaskProgress.Complete  || props.projectTask.progress === ProjectTaskProgress.Cancel   
  );
});

// 获取项目任务稽查小结
const getProjectTaskAuditSummary = async () => {
  tableLoading.value = true;
  const result = await request_projecttask_auditsummary_get(
    props.projectTask.guid,
    () => (tableLoading.value = false)
  );
  tableData.value = result;
};

// 处理关闭事件
const handleCancle = () => {
  auditSummaryGuid.value = "";
  currentEditName.value = "";
  emits("handleCloseDialog", 3);
};
// 处理编辑
const handleEdit = (data) => {
  auditSummaryGuid.value = data.guid;
  currentEditName.value = data.name;
  dialogVisible.value = true;
};
</script>
<style scoped lang="scss"></style>
