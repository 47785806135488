<template>
  <Dialog
    width="40%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form :model="form" label-width="120px" :rules="rules" ref="formRef">
      <el-form-item
        :label="$t('Components.Database.Client.Name')"
        prop="name"
      >
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item
        :label="$t('Components.Database.Client.Category')"
        prop="category"
      >
        <el-select v-model="form.category" :placeholder="t('Components.Database.Client.PleaseSelectCategory')" :style="{width: '100%'}"> 
          <el-option
            v-for="item in clientCategorys"
            :key="item.guid"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup>
import {
  ref,
  reactive,
  markRaw,
  onMounted,
  defineProps,
  defineEmits,
} from "vue";
import { WarnTriangleFilled } from "@element-plus/icons-vue";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { useI18n } from "vue-i18n";
import {
  request_client_add,
  request_client_modify,
} from "@/api/apis";
import { ElMessageBox } from "element-plus";

const { t } = useI18n();
const props = defineProps({
  clientCategorys: {
    type: Array,
    default: () => [],
  },
  editData: {
    type: Object,
    default: () => {},
  },
});
const emits = defineEmits(["handleCloseDialog", "getClientPage"]);
// 标题
const dialogTitle = ref("");
// 表单ref
const formRef = ref();
// 表单数据
const form = reactive({
  name: props.editData.name,
  category: props.editData.category 
});

// 表单校验规则
const rules = ref({
  name: [
    {
      required: true,
      message: t(
        "Components.Database.Client.ValidateClientNameRequired"
      ),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.Database.Client.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  category: [
    {
      required: true,
      message: t(
        "Components.Database.Client.ValidateClientCategoryRequired"
      ),
      trigger: "blur",
    },
  ],
});

onMounted(async () => {
  if (props.editData && props.editData.guid) {
    // 代表修改
    dialogTitle.value = t(
      "Components.Database.Client.DialogClientTitleMaintance"
    );
  } else {
    dialogTitle.value = t(
      "Components.Database.Client.DialogClientTitleAdd"
    );
  }
});
// 处理提交事件
const handleCommit = async () => {
  if (!formRef.value) return;
  await formRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(
        t("Components.Database.Client.ConfirmSaveClient"),
        t("Common.ElMessageBox.OperateConfirm"),
        {
          confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
          cancelButtonText: t("Common.ElMessageBox.CancelText"),
          type: "warning",
          icon: markRaw(WarnTriangleFilled),
        }
      )
        .then(async () => {
          await saveClient();
        })
        .catch(() => {});
    }
  });
};
// 保存被稽查方数据
const saveClient = async () => {
  const trans = {
    name: form.name,
    category: form.category
  };
  if (props.editData && props.editData.guid) {
    // 修改
    await request_client_modify(props.editData.guid, trans);
  } else {
    // 新增
    await request_client_add(trans);
  }
  emits("getClientPage");
  emits("handleCloseDialog");
};
// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};
</script>
<style scoped lang="scss"></style>
