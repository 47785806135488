export default {
  Common: {
    Back: '返回',
    Home: '首页',
    Customer: '道一医药稽查系统',
    Version: 'v1.0',
    AccessLogModule: {
      NoModule: '未知模块',
      BaseServiceModule: '基础服务模块',
      SystemModule: '系统模块',
      UtilModule: '工具模块',
      LogModule: '日志模块',
      Database: '信息库模块',
      Project: '项目模块',
      ReviewApprove: '审阅批准',
      Progress: '进度模块',
      WorkingHours: '工时模块',
      Calendar: '日历模块',
      Statistics: '统计模块',
    },
    AccessLogType: {
      NoType: '未知状态',
      Search: '查询',
      Add: '新增',
      Modify: '修改',
      Export: '导出',
      Import: '导入',
      SingleDelete: '单条删除',
      BatchDelete: '批量删除',
      Clear: '清空',
      Dataflag: '设置状态',
      Login: '登录',
    },
    YesOrNo: {
      Yes: '是',
      No: '否'
    },
    Error: {
      LoginExpired: '登录状态已过期',
      RemoteLogin: '账号异地登录',
      NoPermission: '无权限访问服务器',
      Support: '系统状态码未知，请联系技术支持'
    },
    Company: '苏州怀墨科技有限公司',
    PopoverBottom: {
      Cancel: '取消',
      Confirm: '确定',
    },
    IconPicker: {
      ChooseIcon: '请选择图标'
    },
    ElMessageBox: {
      OperateConfirm: '操作确认',
      ConfirmText: '确认',
      CancelText: '取消'
    },
    ChooseUser: {
      ChooseUserSingle: {
        Search: '搜索',
        Search1: '查询',
        Reset: '重置',
        Name: '用户名称/手机号/账号',
        Dataflag: '状态',
        SelectDataflag: "请选择状态",
        DataflagY: "有效",
        DataflagN: '无效',
        UserName: '用户名称',
        NickName: '昵称',
        Account: '账号',
        Phone: '手机号',
        Avator: '头像',
        ChooseUser: '选择人员',
        Sex: '性别',
        Male: '男',
        Female: '女',
        Choose: '选择',
        Operate: '操作',
      }
    },
    Month: {
      January: '一月',
      February: '二月',
      March: '三月',
      April: '四月',
      May: '五月',
      June: '六月',
      July: '七月',
      August: '八月',
      September: '九月',
      October: '十月',
      November: '十一月',
      December: '十二月'
    },
    Week: {
      Monday: '星期一',
      Tuesday: '星期二',
      Wednesday: '星期三',
      Thursday: '星期四',
      Friday: '星期五',
      Saturday: '星期六',
      Sunday: '星期日'
    },
    Operate: {
      ShowSearch: '显示搜索栏',
      HideSearch: '隐藏搜索栏',

    }
  },
  // 组件
  Components: {
    Login: {
      InputAccount: '请输入账号',
      InputPassword: '请输入密码',
      RememberPassword: '记住密码',
      Login: '登录',
      PasswordMin6: '密码长度至少6位'
    },
    LockScreen: {
      InputPassword: '请输入密码',
      ExitLockToLogin: '退屏并重新登录',
      Unlock: '解锁',
      UnSearchLockCache: '缓存数据不存在或已被清空,请退屏至登录页重新登录',
      PasswordNotMatch: '密码不正确',
      NoToken: '没有访问票据,请重新登录'
    },
    // 首页
    Index: {

      Home:{
        MyProject: '我的项目',
        MyProjectTask: '我的任务',
        TotalHyperWindow: '总超窗',
        MyCommitReview: '我提交的审阅',
        MyReview: '我的审阅',
        WaitMeReview: '待我审阅',

        Count: '数量',
        Report: '报告',
        CAPA: 'CAPA',
        ReportCAPA: '报告/CAPA超窗',

        AuditCalendar: '稽查日历',
        ReportCalendar: '报告日历',

        PleaseSelectUser: '请选择需要查看的人员',
        
        Calendar:{
          Index: '编号',
          ProjectTaskNumber: '任务编号',
          SchemeName: '方案名称',
          PlanNumber: '方案编号',
          AuditedPartyName: '被稽查方名称',
          PlannedAuditStartTime: '计划稽查开始时间',
          PlannedAuditEndTime: '计划稽查结束时间',
          ActualAuditStartTime: '实际稽查开始时间',
          ActualAuditEndTime: '实际稽查结束时间',
          PlannedReportingTime: '报告计划时间',
          PlannedCAPATime: 'CAPA计划完成时间',
          IsCAPA:"是否需要审核CAPA",
          ProjectTaskCreator: '任务创建人',
          ProjectManager: '项目负责人',
          PorjectAssistance: '项目协助人',
          DispatchUsers: '任务派遣人',
        }
      },

      // 登录
      Login: {
        Login: '登录',
        Account: '账号',
        Password: '密码',
        InputAccount: '请输入账号',
        InputPhone: '请输入手机号',
        PhoneFormatIncorrect: '手机号格式不正确',
        InputPassword: '请输入密码',
        PasswordMin6: '密码长度至少6位'
      },
      Header: {
        Tip_Suoding: '锁定',
        Tip_More: '更多',
        TodayClose: '今日关闭',
        Show: '显示公告'
      },
      Index: {
        'SystemSetting': "系统设置",
        "PersonalInfomation": "个人信息",
        "LockTime": "锁屏时间(分)",
        "LockTimeTip": "若不需要锁屏,可置空或设置为0",
        "SureLoginOut": "确定退出登录吗？",
      },
      PersonalInformation: {
        UserName: '用户名称',
        PersonalInfo: "个人资料",
        Name: "姓名",
        Account: "账号",
        Phone: "手机号",
        Department: "部门",
        Role: "角色",
        Job: '岗位',
        Email: '邮箱',
        BaseInfo: '基本信息',
        Sex: '性别',
        Save: '保存',
        ModifyPassword: '修改密码',
        LoginLog: '登录日志',

        OldPassword: '旧密码',
        NewPassword1: '新密码',
        NewPassword2: '确认密码',

        ValidateNameRequired: '姓名必填',
        ValidateEmailRequired: '邮箱必填',
        ValidatePhoneRequired: '手机号必填',
        ValidateMaxLength: '最大长度超过100个字符',
        InplueValideEmail: '请输入有效邮箱格式',
        InplueValidePhone: '请输入有效的电话号码',
        PasswordMin6: '密码长度至少6位',
        TwoPassworNotEqual: '两次密码不一致',
        ValidatePasswordOldAndNew: '旧密码和新密码一致',
        InputOldPassword: "请输入旧密码",
        InputPassword1: '请输入新密码',
        InputPassword2: '请输入确认密码',

        AvatorNone: '上传头像返回地址为空'
      }
    },
    // 头部
    Header: {
      // 头部弹出层
      HeaderPopover: {
        PersonalInfo: '个人信息',
        DayAndNightMode: '日夜模式',
        ClearCache: '清除缓存',
        Language: '语言',
        LoginOut: '退出登录',
        Chinese: '中文',
        English: '英文',
        SystemSetting: '系统设置'
      }
    },
    System: {
      Menu: {
        Search: '搜索',
        Search1: '查询',
        Reset: '重置',
        NameCode: '名称/编码',
        Dataflag: '状态',
        SelectDataflag: "请选择状态",
        DataflagY: "有效",
        DataflagN: '无效',
        CNName: '中文名称',
        ENName: '英文名称',
        Code: '编码',
        Icon: '图标',
        Path: '路径',
        Sort: '排序',
        Operate: '操作',
        Add: '新增',
        Edit: '编辑',
        Delete: '删除',
        SetDataflagY: '设为有效',
        SetDataflagN: '设为无效',
        ExpandAll: '一键展开',
        FoldAll: '一键折叠',
        MenuAdd: '新增菜单',
        ValidateMenuCNNameRequired: '中文名称必填',
        ValidateMenuENNameRequired: '英文名称必填',
        ValidateMenuCodeRequired: '编码必填',
        ValidateMenuSortRequired: '序号必填',
        ValidateMenuPathRequired: '路径必填',
        ValidateMaxLength: '最大长度超过100个字符',
        ValidateMaxLength255: '最大长度超过255个字符',
        ParentMenu: '父级菜单',
        ChooseParentMenu: '请选择父级菜单',
        DialogTitleAdd: '新增菜单',
        DialogTitleMaintance: '菜单维护',
        ConfirmSaveMenu: '确定保存菜单吗?',
        ConfirmDeleteMenu: '子菜单及从属的按钮将全部删除! 确定删除菜单吗?',
        ConfirmDataflagY: '确定将菜单设置有效吗?',
        ConfirmDataflagN: '子菜单及从属的按钮将全部失效! 确定将菜单设置无效吗?',
        DialogMenuButtonTitleAdd: '新增菜单按钮',
        DialogMenuButtonTitleMaintance: '菜单按钮维护',
        ConfirmSaveMenuButton: '确定保存菜单按钮吗?',
        ConfirmMenuButtonDataflagY: '确定将菜单按钮设置有效吗?',
        ConfirmMenuButtonDataflagN: '确定将菜单按钮设置无效吗?',
        ConfirmDeleteMenuButton: '确定删除菜单按钮吗?',
      },
      Role: {
        Search: '搜索',
        Search1: '查询',
        Reset: '重置',
        NameCode: '名称/编码',
        Dataflag: '状态',
        SelectDataflag: "请选择状态",
        DataflagY: "有效",
        DataflagN: '无效',
        Name: '角色名称',
        Code: '角色编码',
        Sort: '序号',
        CreateTime: '创建时间',
        Operate: '操作',
        Add: '新增',
        Edit: '编辑',
        Copy: '复制',
        Export: '导出',
        Delete: '删除',
        SetDataflagY: '设为有效',
        SetDataflagN: '设为无效',
        ValidateRoleNameRequired: '角色名称必填',
        ValidateRoleCodeRequired: '角色编码必填',
        ValidateRoleSortRequired: '序号必填',
        ValidateMaxLength: '最大长度超过100个字符',
        ValidateMaxLength255: '最大长度超过255个字符',
        DialogRoleTitleAdd: '新增角色',
        DialogRoleTitleMaintance: '角色维护',
        PermissionRange: '权限范围',
        DataPermission: '数据权限',
        MenuPermission: '菜单权限',
        ConfirmSaveRole: '确定保存角色吗?',
        ConfirmDeleteRole: '确定删除角色吗?',
        ConfirmCopyRole: '确定复制角色吗?',
        AtLeastOneRole: '至少选择一条角色数据',
        ExpandFold: '展开/折叠',
        SelectAllOrNot: '全选/全不选',
        CheckStrictly: '父子联动',
        OnlyOneSelectEdit: "有且仅能选择一条角色数据进行编辑",
        OnlyOneSelectCopy: "有且仅能选择一条角色数据进行复制"
      },
      Department: {
        Search: '搜索',
        Search1: '查询',
        Reset: '重置',
        NameCode: '名称/编码',
        Dataflag: '状态',
        SelectDataflag: "请选择状态",
        DataflagY: "有效",
        DataflagN: '无效',
        ExpandAll: '一键展开',
        FoldAll: '一键折叠',
        Add: '新增',
        Children: '子级',
        Edit: '编辑',
        Delete: '删除',
        Name: '部门名称',
        Code: '部门编码',
        Sort: '排序',
        Principle: '负责人',
        CreateTime: '创建时间',
        Operate: '操作',
        ParentDepartment: '父级部门',
        DialogTitleAdd: '新增部门',
        DialogTitleMaintance: '部门维护',
        ChooseParentDepartment: '请选择父级部门',
        ValidateDepartmentNameRequired: '名称必填',
        ValidateDepartmentSortRequired: '序号必填',
        ValidateMaxLength100: '最大长度超过100个字符',
        ConfirmSaveDepartment: '确定保存部门吗?',
        ConfirmDeleteDepartment: '确定删除部门数据吗?',
        ChooseUser: '请选择负责人',
        PermissionAll: '全部数据权限',
        PermissionCustom: '自定义数据权限',
        PermissionCurrentDepartment: '本部门数据权限',
        PermissionCurrentDepartmentAndChild: '本部门及子部门数据权限',
        PermissionMySelf: '仅本人数据权限',
        ChooseDataPermission: '请选择数据权限'
      },
      Job: {
        Search: '搜索',
        Search1: '查询',
        Reset: '重置',
        NameCode: '名称/编码',
        Remark: '备注',
        Dataflag: '状态',
        SelectDataflag: "请选择状态",
        DataflagY: "有效",
        DataflagN: '无效',
        Name: '岗位名称',
        Code: '岗位编码',
        Sort: '序号',
        CreateTime: '创建时间',
        Operate: '操作',
        Add: '新增',
        Edit: '编辑',
        Delete: '删除',
        Export: '导出',
        SetDataflagY: '设为有效',
        SetDataflagN: '设为无效',
        ConfirmDeleteJob: '确定删除岗位吗?',
        AtLeastOneJob: '至少选择一条岗位数据',
        ValidateJobNameRequired: '岗位名称必填',
        ValidateJobCodeRequired: '岗位编码必填',
        ValidateJobSortRequired: '序号必填',
        ValidateMaxLength: '最大长度超过100个字符',
        ValidateMaxLength255: '最大长度超过255个字符',
        DialogJobTitleAdd: '新增岗位',
        DialogJobTitleMaintance: '岗位维护',
        ConfirmSaveJob: '确定保存岗位吗?',
        OnlyOneSelectEdit: "有且仅能选择一条岗位数据进行编辑"
      },
      User: {
        DepartmentCodeName: '部门名称/编码',
        Search: '搜索',
        Search1: '查询',
        Reset: '重置',
        NamePhone: '用户名称/手机号/账号',
        Remark: '备注',
        Dataflag: '状态',
        SelectDataflag: "请选择状态",
        DataflagY: "有效",
        DataflagN: '无效',
        Add: '新增',
        Edit: '编辑',
        ResetPassword: '重置密码',
        Delete: '删除',
        Export: '导出',
        Name: '用户名称',
        NickName: '用户昵称',
        Department: '归属部门',
        Phone: '手机号码',
        Email: '邮箱',
        Sex: '性别',
        Job: '岗位',
        Role: '角色',
        Account: '账号',
        CreateTime: '创建时间',
        Password: '密码',
        Avator: '头像',
        Operate: '操作',
        SetDataflagY: '设为有效',
        SetDataflagN: '设为无效',
        ConfirmDeleteUser: '确定删除用户吗?',
        ConfirmResetPassword: '确定重置密码吗?',
        AtLeastOneUser: '至少选择一条用户数据',
        Male: '男',
        Female: '女',
        SelectJobPlaceholder: '请选择岗位',
        SelectRolePlaceholder: '请选择角色',
        InputPasswordPlaceholder: '请输入密码',
        ValidateUserNameRequired: '用户名称必填',
        ValidateUserNickNameRequired: '用户昵称必填',
        ValidateUserPasswordRequired: '用户密码必填',
        ValidateUserAccountRequired: '用户账号必填',
        ValidateUserEmailRequired: '邮箱必填',
        ValidateMaxLength100: '最大长度超过100个字符',
        ValidateMinPassword: '密码最少6位',
        ValidateMaxLength255: '最大长度超过255个字符',
        InplueValideEmail: '请输入有效邮箱格式',
        InplueValidePhone: '请输入有效的电话号码',
        DialogUserTitleAdd: '新增用户',
        DialogUserTitleMaintance: '用户维护',
        ConfirmSaveUser: '确定保存用户信息吗?',
        OnlyOneSelectEdit: "有且仅能选择一条用户数据进行编辑"

      },
      Dictionary: {
        Search: '搜索',
        Search1: '查询',
        Reset: '重置',
        NameCode: '名称/编码',
        Dataflag: '状态',
        SelectDataflag: "请选择状态",
        DataflagY: "有效",
        DataflagN: '无效',
        Name: '字典名称',
        Code: '编码',
        Sort: '序号',
        Remark: '备注',
        CreateTime: '创建时间',
        Operate: '操作',
        Add: '新增',
        Edit: '编辑',
        Delete: '删除',
        Export: '导出',
        Import: '导入',
        SetDataflagY: '设为有效',
        SetDataflagN: '设为无效',
        DialogTitleAdd: '新增字典',
        DialogTitleMaintance: '字典维护',
        ValidateDicNameRequired: '字典名称必填',
        ValidateDicCodeRequired: '字典编码必填',
        ValidateDictionarySortRequired: '序号必填',
        ValidateMaxLength: '最大长度超过100个字符',
        ValidateMaxLength255: '最大长度超过255个字符',
        DialogDicTitleAdd: '新增字典',
        DialogDicTitleMaintance: '字典维护',
        ConfirmSaveDic: '确定保存字典吗?',
        ConfirmDeleteDic: '确定删除字典吗?',
        AtLeastOneDic: '至少选择一条字典数据',
        OnlyOneSelectEdit: "有且仅能选择一条字典数据进行编辑",
        ExcelPathNone: '上传文件返回地址为空',
        Item: {
          Search: '搜索',
          Search1: '查询',
          Reset: '重置',
          NameCode: '名称',
          Dataflag: '状态',
          SelectDataflag: "请选择状态",
          DataflagY: "有效",
          DataflagN: '无效',
          Name: '名称',
          Id: '编号',
          Sort: '序号',
          Value: '值',
          EchoStyle: '回显样式',
          Choose: '请选择',
          Export: '导出',

          Default: '默认',
          Primary: '主要',
          Success: '成功',
          Info: '信息',
          Warning: '警告',
          Danger: '危险',

          Remark: '备注',
          Operate: '操作',
          Add: '新增',
          Edit: '编辑',
          Import: '导入',
          Delete: '删除',
          SetDataflagY: '设为有效',
          SetDataflagN: '设为无效',
          ValidateDicItemNameRequired: '字典项名称必填',
          ValidateMaxLength: '最大长度超过100个字符',
          ValidateMaxLength255: '最大长度超过255个字符',
          DialogDicItemTitleAdd: '新增字典项',
          DialogDicItemTitleMaintance: '字典项维护',
          ConfirmSaveDicItem: '确定保存字典项吗?',
          ConfirmDeleteDicItem: '确定删除字典项吗?',
          AtLeastOneDicItem: '至少选择一条字典项数据',
          ValidateDictionaryItemSortRequired: '序号必填',
          ValidateDictionaryItemValueRequired: '值必填',
          OnlyOneSelectEdit: "有且仅能选择一条字典项数据进行编辑",

          PleaseInputRemark: '若需有父子级关联(系统内置)请在子级的备注中写入父级对应字典项的值;稽查类型的备注等于"NR"则代表将不会走报告流程'
        }
      },
      SystemParam: {
        Search: '搜索',
        Search1: '查询',
        Reset: '重置',
        NameCode: '名称/编码',
        Remark: '备注',
        Dataflag: '状态',
        SelectDataflag: "请选择状态",
        DataflagY: "有效",
        DataflagN: '无效',
        Name: '参数名称',
        Code: '参数编码',
        Value: '参数值',
        Sort: '序号',
        IsSystem: '是否系统',
        CreateTime: '创建时间',
        Operate: '操作',
        Add: '新增',
        Edit: '编辑',
        Delete: '删除',
        Export: '导出',
        SetDataflagY: '设为有效',
        SetDataflagN: '设为无效',
        ConfirmDeleteSystemParam: '确定删除参数配置吗?',
        AtLeastOneSystemParam: '至少选择一条参数配置数据',
        ValidateSystemParamNameRequired: '参数配置名称必填',
        ValidateSystemParamCodeRequired: '参数配置编码必填',
        ValidateSystemParamValueRequired: '参数配置值必填',
        ValidateSystemParamSortRequired: '序号必填',
        ValidateMaxLength: '最大长度超过100个字符',
        ValidateMaxLength255: '最大长度超过255个字符',
        DialogSystemParamTitleAdd: '新增参数配置',
        DialogSystemParamTitleMaintance: '参数配置维护',
        ConfirmSaveSystemParam: '确定保存参数配置吗?',
        OnlyOneSelectEdit: "有且仅能选择一条参数配置数据进行编辑",
        OnlyAdminCanEdit: '只允许管理员账号可进行修改',
      },
      Announcement: {
        Search: '搜索',
        Search1: '查询',
        Reset: '重置',
        TitleCreateName: '标题 / 创建人',
        Remark: '备注',
        Dataflag: '状态',
        Type: '类型',
        SelectDataflag: "请选择状态",
        SelectType: "请选择类型",
        Notity: '通知',
        Announcement: '公告',
        IsShow: '是否发布',
        DataflagY: "有效",
        DataflagN: '无效',
        Index: '序号',
        Name: '参数名称',
        Code: '参数编码',
        Value: '参数值',
        Title: '标题',
        Sort: '序号',
        IsSystem: '是否系统',
        CreateTime: '创建时间',
        CreateName: '创建人',
        Content: '内容',
        Operate: '操作',
        Add: '新增',
        Edit: '编辑',
        Delete: '删除',
        Export: '导出',
        SetDataflagY: '设为有效',
        SetDataflagN: '设为无效',
        ConfirmDeleteAnnouncement: '确定删除通知公告吗?',
        AtLeastOneAnnouncement: '至少选择一条通知公告数据',
        ValidateAnnouncementTitleRequired: '通知公告标题必填',
        ValidateAnnouncementTypeRequired: '通知公告类型必选',
        ValidateMaxLength: '最大长度超过100个字符',
        ValidateMaxLength255: '最大长度超过255个字符',
        DialogAnnouncementTitleAdd: '新增通知公告',
        DialogAnnouncementTitleMaintance: '通知公告维护',
        ConfirmSaveAnnouncement: '确定保存通知公告吗?',
        OnlyOneSelectEdit: "有且仅能选择一条通知公告数据进行编辑",
        PleaseInputTitle: '请输入标题',
        PleaseInputContent: '请输入内容...',
        PleaseInputSort: '请输入序号'
      },

      ExcelTemplate: {
        Search: '搜索',
        Search1: '查询',
        Reset: '重置',
        SearchName: '模板名称 / 编码',
        Dataflag: '状态',
        SelectDataflag: "请选择状态",
        DataflagY: "有效",
        DataflagN: '无效',
        Name: '模板名称',
        Code: '模板编码',
        FileName: '模板文件',
        Remark: '备注',
        CreateTime: '创建时间',
        Operate: '操作',
        Add: '新增',
        Edit: '编辑',
        Download: '下载',
        Delete: '删除',
        Export: '导出',
        Upload: '上传',
        SetDataflagY: '设为有效',
        SetDataflagN: '设为无效',
        ConfirmDeleteExcelTemplate: '确定删除模板数据吗?',
        AtLeastOneExcelTemplate: '至少选择一条模板数据数据',
        ValidateExcelTemplateNameRequired: '模板名称必填',
        ValidateExcelTemplateCodeRequired: '模板编码必填',
        ValidateExcelTemplateFileRequired: '请上传一个模板文件',
        ValidateMaxLength: '最大长度超过100个字符',
        ValidateMaxLength255: '最大长度超过255个字符',
        DialogExcelTemplateTitleAdd: '新增模板',
        DialogExcelTemplateTitleMaintance: '模板维护',
        ConfirmSaveExcelTemplate: '确定保存模板数据吗?',
        OnlyOneSelectEdit: "有且仅能选择一条模板数据进行编辑",
        ExcelPathNone: '上传文件返回地址为空',
        NotAdminCanDelete: '非管理员账户不允许删除',
        OnlyAdminCanEdit: '只允许管理员账号可进行修改',
        UploadTip: '只允许上传一个文件,文件类型为.xls|.xlsx'
      },
    },
    Log: {
      OperateLog: {
        Search: '搜索',
        Search1: '查询',
        Reset: '重置',

        Id: '编号',
        Module: '操作模块',
        Type: '操作类型',
        Method: '请求方式',
        IP: 'IP地址',
        Address: '请求地址',
        Status: '状态',
        Request: '请求参数',
        Response: '返回数据',
        Message: '响应信息', // 目前是放的是错误信息，其实在response中已存在
        OperateTime: '操作时间',
        OperateName: '操作人',
        TimeConsuming: '耗时',

        Operate: '操作',
        Edit: '编辑',
        Delete: '删除',
        Export: '导出',
        Clear: '清空',
        View: '查看',

        ConfirmDeleteAccessLog: '确定删除操作日志吗?',
        ConfirmClearAccessLog: '清空后将不可恢复，确定清空吗?',
        AtLeastOneAccessLog: '至少选择一条操作日志数据',
        PleaseChooseModule: '请选择系统模块',
        PleaseInputSearch: '接口地址 / 操作人',
        PleaseSelectType: '请选择操作类型',
        PleaseSelectStatus: '请选择状态',
        Detail: '操作日志详情',
        Success: '成功',
        Failure: '失败',
      },
      LoginLog: {
        Search: '搜索',
        Search1: '查询',
        Reset: '重置',

        Id: '编号',
        Status: '状态',

        Operate: '操作',
        Edit: '编辑',
        Delete: '删除',
        Export: '导出',
        Clear: '清空',

        UserName: '用户名称',
        UserAccount: '用户账号',
        LoginIP: '登录地址',
        Browser: '浏览器',
        OS: '操作系统',
        LoginStatus: '登录状态',
        Message: '操作信息',
        LoginTime: '登录日期',

        StartDate: '开始日期',
        EndDate: '结束日期',

        PleaseInputSearch: '用户名称 / 账号',

        ConfirmDeleteLoginLog: '确定删除登录日志吗?',
        ConfirmClearLoginLog: '清空后将不可恢复，确定清空吗?',
        AtLeastOneLoginLog: '至少选择一条登录日志数据',
        PleaseSelectStatus: '请选择状态',
        Success: '成功',
        Failure: '失败',
      },
    },
    Report: {
      GenerateReport: {
        ReviewComments: '评审意见',
        Reply: '回复',
        NoContent: '请先维护稽查小结模块内容',
        DescriptionModify: '稽查描述修改',
        MainCategory: '主分类',
        SubCategory: '子分类',
        Grading: '分级',
        FilterNumber: '筛选号',
        QuestionDetail: '问题详情',
        QuestionInduction: '问题归纳',
        Remark: '备注',
        IsImportReport: '是否导入报告',
        Sort: '序号',
        Expand: '展开',
        CountReply: '条回复',
        View: '阅读',
        Check: '选中',
        Clear: '清除',


        Certical: '重大',
        Major: '重要',
        Minor: '轻微',
        Comment: '建议',


        PleaseSelectGrading: '请选择层级',
        AuditDiscovery: "稽查发现"
      }
    },
    Database: {
      AuditedParty: {
        Search: '搜索',
        Search1: '查询',
        Reset: '重置',
        Dataflag: '状态',
        SelectDataflag: "请选择状态",
        DataflagY: "有效",
        DataflagN: '无效',
        Name: '名称',
        Category: '稽查类型',
        Qualification: '资质',
        Area: '区域',
        CreateTime: '创建时间',
        Operate: '操作',
        Add: '新增',
        Edit: '编辑',
        Delete: '删除',
        Export: '导出',
        SetDataflagY: '设为有效',
        SetDataflagN: '设为无效',
        ConfirmDeleteAuditedParty: '确定删除被稽查方数据吗?',
        AtLeastOneAuditedParty: '至少选择一条被稽查方数据',
        ValidateAuditedPartyNameRequired: '名称必填',
        ValidateAuditedPartyAreaRequired: '区域必填',
        ValidateAuditedPartyCategoryRequired: '分类必选',
        ValidateMaxLength: '最大长度超过100个字符',
        ValidateMaxLength255: '最大长度超过255个字符',
        DialogAuditedPartyTitleAdd: '新增被稽查方',
        DialogAuditedPartyTitleMaintance: '被稽查方维护',
        ConfirmSaveAuditedParty: '确定保存被稽查方吗?',
        OnlyOneSelectEdit: "有且仅能选择一条被稽查方数据进行编辑",
        PleaseInputName: '请输入名称',
        PleaseSelectCategory: '请选择稽查类型',
        PleaseSelectQualification:'请选择资质',
        PleaseSelectArea:'请选择区域',
      },
      Client: {
        Search: '搜索',
        Search1: '查询',
        Reset: '重置',
        Dataflag: '状态',
        SelectDataflag: "请选择状态",
        DataflagY: "有效",
        DataflagN: '无效',
        Name: '委托方名称',
        Category: '类别',
        Abbreviation: '缩写',
        Sort: '编号',
        CreateTime: '创建时间',
        Operate: '操作',
        Add: '新增',
        Edit: '编辑',
        Delete: '删除',
        Export: '导出',
        SetDataflagY: '设为有效',
        SetDataflagN: '设为无效',
        ConfirmDeleteClient: '确定删除委托方数据吗?',
        AtLeastOneClient: '至少选择一条委托方数据',
        ValidateClientNameRequired: '名称必填',
        ValidateClientAbbreviationRequired: '缩写必填',
        ValidateClientCategoryRequired: '分类必选',
        ValidateMaxLength: '最大长度超过100个字符',
        ValidateMaxLength255: '最大长度超过255个字符',
        DialogClientTitleAdd: '新增委托方',
        DialogClientTitleMaintance: '委托方维护',
        ConfirmSaveClient: '确定保存委托方吗?',
        OnlyOneSelectEdit: "有且仅能选择一条委托方数据进行编辑",
        PleaseSelectCategory: '请选择分类'
      },
      AuditSummary: {
        Search: '搜索',
        Search1: '查询',
        Reset: '重置',
        Dataflag: '状态',
        SelectDataflag: "请选择状态",
        DataflagY: "有效",
        DataflagN: '无效',
        Add: '新增',
        Edit: '编辑',
        Delete: '删除',
        Export: '导出',
        Name: '名称',
        DefaultValue: '默认值',
        Sort: '排序',
        Operate: '操作',
        PleaseInputName: '请输入名称',
        PleaseInputSort: '请输入序号',
        ValidateMaxLength: '最大长度超过100个字符',
        ValidateMaxLength255: '最大长度超过255个字符',
        DialogAuditSummaryTitleAdd: '新增稽查小结',
        DialogAuditSummaryTitleMaintance: '稽查小结维护',
        ConfirmSaveAuditSummary: '确定保存稽查小结吗?',
        ConfirmDeleteAuditSummary: '确定删除稽查小结嘛?',

        Item: {
          Search: '搜索',
          Search1: '查询',
          Reset: '重置',
          Dataflag: '状态',
          SelectDataflag: "请选择状态",
          DataflagY: "有效",
          DataflagN: '无效',
          Add: '新增',
          Edit: '编辑',
          Delete: '删除',
          Name: '默认名称',
          DefaultValue: '默认值',
          Sort: '排序',
          Operate: '操作',
          PleaseInputName: '请输入名称',
          PleaseInputSort: '请输入序号',
          ValidateMaxLength: '最大长度超过100个字符',
          ValidateMaxLength255: '最大长度超过255个字符',
          DialogAuditSummaryItemTitleAdd: '新增稽查小结项',
          DialogAuditSummaryItemTitleMaintance: '稽查小结项维护',
          ConfirmSaveAuditSummaryItem: '确定保存稽查小结项吗?',
          ConfirmDeleteAuditSummaryItem: '确定删除稽查小结项嘛?',
        }
      }
    },
    Project: {
      ProjectList: {
        Search: '搜索',
        Search1: '查询',
        Reset: '重置',
        Name: '治疗领域 / 适应症 / 研究产品',
        Dataflag: '状态',
        SelectDataflag: "请选择状态",
        DataflagY: "有效",
        DataflagN: '无效',
        Day: '天',
        WorkDay: '工作日',

        ProjectNumber: '项目编号',
        PlanNumber: '方案编号',
        Sponsor: '申办者',
        SponsorType: '申办者类型',
        Client: '委托方',
        ClientCategory: '委托方类型',
        ClientIno: '委托方类别/序号',
        TherapeuticArea: '治疗领域',
        Indications: '适应症',
        ResearchProduct: '研究产品',
        ResearchProductType: '研究产品类型',
        TrialStaging: '试验分期',
        TrialProgressStage: '试验进展阶段',
        AuditType: '稽查类型',
        SerialNumber: '序列号',
        ServiceType: '服务类型',
        PlanAuditsCount: '计划稽查次数',
        ActualAuditsCount: '实际稽查次数',
        ProjectProgress: '项目进度',
        ProjectManager: '项目负责人',
        PorjectAssistance: '项目协助人',
        CreateTime: '创建时间',
        Operate: '操作',
        StartDate: '开始日期',
        EndDate: '结束日期',
        ScheMenuName: '方案名称',
        CROName: 'CRO名称',
        Remark: '备注',
        AuditRequirements: '委托方稽查要求',
        ContractedReportDeliveryTime: '合同约定的报告交付时间',
        ContractedCapaDeliveryTime: '合同约定的CAPA交付时间',
        AuditPurpose: '稽查目的',
        AuditScope: '稽查范围',
        AuditBasis: '稽查依据',


        IsCAPA: '是否需要审核CAPA',
        Add: '新增',
        Edit: '编辑',
        Delete: '删除',
        Export: '导出',
        Complete: '完成',
        Cancel: '取消',
        Restart: '重启',
        Task: '任务',
        SetDataflagY: '设为有效',
        SetDataflagN: '设为无效',
        ConfirmDeleteProjectList: '确定删除项目数据吗?',
        AtLeastOneProjectList: '至少选择一条项目数据',
        ValidateProjectListNameRequired: '名称必填',
        ValidateProjectListAreaRequired: '区域必填',
        ValidateProjectListCategoryRequired: '分类必选',
        ValidateMaxLength: '最大长度超过100个字符',
        ValidateMaxLength255: '最大长度超过255个字符',
        ValidateMaxLength900: '最大长度超过900个字符',
        ValidateMaxLength999: '最大长度超过999个字符',
        DialogProjectListTitleAdd: '新增项目',
        DialogProjectListTitleMaintance: '项目维护',
        ConfirmSaveProjectList: '确定保存项目吗?',
        OnlyOneSelectEdit: "有且仅能选择一条项目数据进行编辑",
        OnlyOneSelectRestart: "有且仅能选择一条项目数据进行重启",
        OnlyOneSelectComplete: "有且仅能选择一条项目数据进行完成",
        OnlyOneSelectCancel: "有且仅能选择一条项目数据进行取消",
        ConfirmCompleteProjectList: '确定完成项目吗?',
        ConfirmRestartProjectList: '确定重启项目吗?',
        ConfirmCancelProjectList: '确定取消项目吗?',
        InputCancelReason: '请输入取消原因',
        Tip: '提示',
        CancelReason: '取消原因',

        PleaseInputSponsor: '请输入申办者',
        PleaseSelectClient: '请选择委托方',
        PleaseSelectClientCategory: '请选择委托方类型',
        PleaseInputSerialNumber: '请输入序列号',
        PleaseSelectServiceType: '请选择服务类型',
        PleaseSelectAuditType: '请选择稽查类型',
        PleaseSelectTherapeuticArea: '请选择治疗领域',
        PleaseInputPlanNumber: '请输入方案编号',
        PleaseInputProjectNumber: '请输入项目编号',
        AutoGenerateProjectNumber: '自动生成项目编号',
        PleaseInputTherapeuticArea: '请输入治疗领域',
        PleaseInputIndications: '请输入适应症',
        PleaseSelectIndications: '请选择/输入适应症',
        PleaseInputResearchProduct: '请输入研究产品',
        PleaseSelectResearchProductType: '请选择研究产品类型',
        PleaseSelectSponsorType: '请选择申办者类型',
        PleaseSelectTrialStaging: '请选择试验分期',
        PleaseInputScheMeName: '请输入方案名称',
        PleaseInputCroName: '请输入CRO名称',
        PleaseSelectInputCroName: '请选择/输入CRO名称',
        PleaseInputActualAuditsCount: '请输入实际稽查次数',
        PleaseInputPlanAuditsCount: '请输入计划稽查次数',
        PleaseSelectTrialProgressStage: '请选择试验进行阶段',
        PleaseSelectProjectProgress: '请选择项目进度',
        PleaseSelectProjectManager: '请选择项目负责人',
        PleaseSelectProjectAssistance: '请选择项目协助人',
        PleaseInputContractedReportDeliveryTime: '合同约定的报告交付时间',
        PleaseInputContractedCapaDeliveryTime: '合同约定的CAPA交付时间',
        PleaseInputAuditPurpose: '请输入稽查目的',
        PleaseInputAuditScope: '请输入稽查范围',
        PleaseInputAuditBasis: '请输入稽查依据',
        ClickGenerateProjectNumber: '点击生成项目编号',




        ShowSearch: '显示搜索栏',
        HideSearch: '隐藏搜索栏',

        ToBeAllocated: '待分配',
        Ready: '准备中',
        InProgress: '进行中',
        Cancel1: '已取消',


      },
      ProjectTask: {
        Search: '搜索',
        Search1: '查询',
        Reset: '重置',
        Dataflag: '状态',
        SelectDataflag: "请选择状态",
        DataflagY: "有效",
        DataflagN: '无效',

        ProjectNumber: '项目编号',
        SchemeName: '方案名称',
        Number: '任务编号',
        Client: '委托方',
        TherapeuticArea: '治疗领域',
        Indications: '适应症',
        TrialStaging: '试验分期',
        TrialProgressStage: '试验阶段',
        HospitalType: '医院类型',
        AuditedParty: '被稽查方',
        AuditedPartyArea: '被稽查方区域',
        AuditedPartyQualification: '被稽查方资质',
        AuditedPartyHospitalType: '医院类型',
        ImplementationDepartment: '实施科室',
        MainResearcher: '主要研究者',
        AuditType: '稽查类型',
        ProjectManager: '项目负责人',
        PorjectAssistance: '项目协助人',
        CustomerProjectManager: '项目经理',
        ServiceUser: '服务人员',
        ServiceContent: '服务内容',
        ServiceTime: '服务时间',
        Remark: '备注',
        MainAuditor: '主稽查员',
        Auditor: '稽查员',

        CustomerInspector: '监查员',
        CreateTime: '创建时间',
        StartDate: '开始日期',
        EndDate: '结束日期',
        ProjectTaskProgress: '任务进度',
        OnlyOneSelectEdit: "有且仅能选择一条进行编辑",

        AuditedPartyName: '被稽查方名称',
        ProjectTrialProgressStage: '项目阶段',
        AuditAddress: '稽查地址',
        CenterNumber: '中心编号',
        PlannedAuditStartTime: '计划稽查开始时间',
        PlannedAuditEndTime: '计划稽查结束时间',
        PlannedReportingDate: '报告计划时间',
        CompletedReportingDate: '报告完成时间',
        PlannedCAPADate: 'CAPA计划完成时间',
        ActualCAPADate: 'CAPA实际完成时间',
        PlannedAuditTime: '计划稽查时间',
        ActualAuditTime: '实际稽查时间',

        PlanNumber: '项目编号',

        PleaseSelectHospitalType: '请选择医院类型',
        PleaseSelectProject: '请选择项目',
        PleaseInputProjectNumber: '请输入项目编号',
        PleaseInputNumber: '请输入任务编号',
        PleaseSelectClient: '请选择委托方',
        PleaseInputTherapeuticArea: '请输入治疗领域',
        PleaseInputIndications: '请输入适应症',
        PleaseSelectTrialStaging: '请选择试验分期',
        PleaseSelectAuditedPartyArea: '请选择被稽查方区域',
        PleaseSelectTrialProgressStage: '请选择试验阶段',
        PleaseSelectTrialProgressStage1: '请选择项目阶段',
        PleaseSelectProjectStage: '请选择项目阶段',
        PleaseSelectAuditedParty: '请选择稽查方',
        PleaseSelectAuditedParty1: '请选择被稽查方',
        PleaseInputImplementationDepartment: '请输入实施科室',
        PleaseInputMainResearcher: '请输入主要研究者',
        PleaseSelectAuditType: '请选择稽查类型',
        PleaseSelectProjectManager: '请选择项目负责人',
        // PleaseSelectCustomerProjectManager: '请输入客户项目负责人',
        PleaseSelectServiceUser: '请选择服务人员',
        PleaseSelectServiceTime: '请选择服务时间',
        PleaseSelectServiceStartTime: '请选择服务开始时间',
        PleaseSelectServiceEndTime: '请选择服务结束时间',
        PleaseInputServiceContent: '请输入服务内容',
        PleaseInputRemark: '请输入备注',
        PleaseSelectPorjectAssistance: '请选择项目协助人',
        PleaseSelectProjectTaskProgress: '请选择任务进度',
        PleaseSelectProjectTrialProgressStage: '请选择项目阶段',
        PleaseInputAuditAddress: '请输入稽查地址',
        PleaseInputCenterNumber: '请输入中心编号',
        PleaseInputCustomerProjectManager: '请输入项目经理',
        PleaseInputCustomerProjectAssistance: '请输入监查员',
        PleaseSelectPlannedReportDate: '请选择报告计划时间',
        PleaseSelectCompletedReportDate: '请选择报告完成时间',
        PleaseSelectPlannedCAPADate: '请选择CAPA计划完成时间',
        PleaseSelectActualCAPADate: '请选择CAPA实际完成时间',
        PleaseSelectPlannedAuditTime: '请选择稽查时间',
        PleaseSelectMainAuditor: '请选择主稽查员',
        PleaseSelectAuditor: '请选择稽查员',
        PleaseSelectAuditedPartyQualification: '请选择被稽查方资质',

        DialogProjectTaskTitleView: '项目任务信息',
        DialogProjectTaskTitleAdd: '新增项目任务',
        DialogProjectTaskTitleMaintance: '项目任务维护',
        ConfirmSaveProjectTask: '确定保存项目任务吗?',
        ValidateMaxLength: '最大长度超过100个字符',

        ProjectTaskLog: '审阅记录',

        ToBeAllocated: '待分配',
        Ready: '准备中',
        AuditImplementation: '稽查实施',
        ReportWriting: '报告撰写',
        SubmitForReview: '待审阅',
        UnderReview: '审阅中',
        ReviewedAndApproved: '审阅通过',
        Cancel: '已取消',
        Complete: '已完成',
        Rewrite: '返改',
        CAPAFinalize: 'CAPA定稿',

        ShowSearch: '显示搜索栏',
        HideSearch: '隐藏搜索栏',

        ProjectTaskLogCommit: '提交',
        ProjectTaskLogDispatch: '派遣',
        ProjectTaskLogApprove: '审阅',

        ProjectTaskOperator: '操作人',
        ProjectTaskOperateTime: '操作时间',
        ProjectTaskOperateType: '操作类型',
        ProjectTaskApprover: '审阅人',
        ProjectTaskDispatcher: '派遣人',
        ProjectTaskCollboration: '协同人',
        ProjectTaskApproveResult: '审阅结果',
        ProjectTaskApprovePass: '通过',
        ProjectTaskApproveRefuse: '驳回',

        Add: '新增',
        Edit: '编辑',
        Operate: '操作',
        Dispatch: '派遣',
        AuditSummary: '稽查小结',
        DiscoveryButton: '发现',
        Report: '报告',
        AuditCertificate: '稽查证明',
        CAPAReport: 'CAPA报告',
        ExportReport: '导出报告',
        ExportAuditCertificate: '稽查证明',
        ReportAppendix1: '报告附件',
        Cancel1: '取消',
        Restart: '重启',
        Info: '信息',

        ConfirmCancelProjectTask: '确定取消项目任务吗?',
        ConfirmCompleteProjectTask: '确定完成项目任务吗?',
        ConfirmRewriteProjectTask: '确定返改项目任务吗?',
        ConfirmRestartProjectTask: '确定重启项目任务吗?',
        ConfirmCAPAFinalizeProjectTask: '确定CAPA定稿吗?',

        // 派遣人员
        DispatchUser: {
          AddDispatchUser: '添加稽查人员',
          DispatchUser: '派遣稽查人员',
          Name: '姓名',
          Role: '角色',
          PlannedStartTime: '计划开始时间',
          PlannedEndTime: '计划结束时间',
          Operate: '操作',
          Edit: '编辑',
          Delete: '删除',
          PleaseSelectDispatchUser: '请选择稽查人员',
          PleaseSelectDispatchUserRole: '请选择角色',
          PleaseSelectPlanStartTime: '请选择计划开始时间',
          PleaseSelectPlanEndTime: '请选择计划结束时间',
          Confirm: '确认',
          Cancel: '取消',
          UserListCannotFindUser: '用户列表中未找到选择的用户数据',
          RoleListCannotFindRole: '角色列表中未找到选择的角色数据',
          PlanStartTimeNotMoreThanPlanEndTime: '计划开始时间应小于计划结束时间',
          MustMainAuditor: '必须存在主稽查员',
          AuditorRoleNoMainAuditor: '稽查角色中没有主稽查员',
          ConfirmDeleteDispatchUser: '确定删除此稽查人员吗?',
        },
        // 稽查小结
        AuditSummaryView: {
          AuditSummary: '稽查小结',
          Id: '序号',
          Name: '稽查小结',
          IsComplete: '是否填写',
          HaveComplete: '已填写',
          NotComplete: '未填写',
          UpdateTime: '修改时间',
          Operate: '操作',
          Modify: '修改',
          PleaseInputValue: '请输入值',
          ConfirmSaveAuditSummary: '确定保存稽查小结吗?',
        },
        // 稽查发现
        Discovery: {
          Back: '返回',
          Search: '搜索',
          Search1: '查询',
          Reset: '重置',
          Add: '新增',
          Edit: '编辑',
          Operate: '操作',
          Delete: '删除',
          Export: '导出',
          Import: '导入',
          GenerateReport: '生成报告',

          Grading: '分级',
          MainCategory: '主分类',
          SubCategory: '子分类',
          AuditUser: '稽查员',
          ProblemOverview: '问题概述',
          ProblemSummary: '问题归纳',
          ProblemDetail: '问题详情',
          FilterNumber: '筛选号',
          Remark: '备注',
          IsImportReport: "是否入报告",


          PleaseSelectGrading: '请选择分级',
          PleaseSelectMainCategory: '请选择主分类',
          PleaseSelectSubCategory: '请选择子分类',
          PleaseSelectAuditUser: '请选择稽查员',
          PleaseInputFilterNumber: '请输入筛选号',
          PleaseInputProblemDetails: '请输入问题详情',
          PleaseInputProblemSummary: '请输入问题归纳',
          PleaseInputProblemOverview: '请输入问题概述',
          PleaseInputRemark: '请输入备注',


          ConfirmDeletepDiscovery: '确定删除稽查发现数据吗?',
          AtLeastOneDiscovery: '至少选择一条稽查发现数据',

          DialogDiscoveryTitleAdd: '新增稽查发现',
          DialogDiscoveryTitleMaintance: '稽查发现维护',
          ConfirmSaveDiscovery: '确定保存稽查发现吗?',
          ValidateMaxLength: '最大长度超过100个字符',
          ValidateMaxLength255: '最大长度超过255个字符',

          AuditDiscovery: '稽查发现'
        },
        // 报告
        Report1: {
          Header: {
            AuditReport: '稽查报告',
            Auditor: '稽查方',
            AuditedParty: '被稽查方',
            Client: '委托方',
            MainAuditor: '主稽查员姓名',
            Sign: '签名',
            Date: '日期',
            AuditorCount:'本轮稽查员的回复数',
            ApproverCount: '本轮审核人的回复数'
          },
          // 一般信息
          GeneralInfomation: {
            Title: '基本信息',
            Sponsor: '申办者',
            DrugsDevices: "药物/器械",
            PlanNumber: '方案编号',
            ProjectName: '项目名称',
            Auditor: '稽查员',
            AuditDate: '稽查日期',
            ReportDate: '报告日期',
            ProjectManager: '项目经理',
            Inspector: '监查员',
            ResearchCenterName: '研究中心名称',
            MainResearcher: '主要研究者',
            CenterNumber: '中心编号',
            CenterAddress: '中心地址',
            CenterStatus: '中心目前的状态',
            Filter: '筛选',
            Import: '导入',
            JoinGroup: '入组',
            Exit: '退出',
            FollowUp: '随访',
            Complete: '完成',
            ResearchCenterAttendees: '研究中心出席人员',
            Name: '姓名',
            Position: '职责/职位',
            Operate: '操作',
            Add: '添加人员',
            Edit: '编辑',
            Delete: '删除',
            ParttimeUser: '兼职人员',
            ConfirmDeleteAttendees: '确定删除出席人员嘛?',
            CenterStatusNone: '未在库中找到对应中心状态数据',

            PleaseInputName: '请输入出席人员名称',
            PleaseInputJob: '请输入出席人员职责/职位',

            PleaseInputSponsor: '请输入申办者',
            PleaseSelectAuditDate: '请选择稽查日期',
            PleaseSelectReportDate: '请选择报告日期',
            PleaseInputProjectManager: '请输入项目经理',
            PleaseInputParttimeUser: '请输入兼职人员',
            PleaseInputInspector: '请输入监查员',
            PleaseInputCenterNumber: '请输入中心编号',
            PleaseInputAduitAddress: '请输入中心地址',
            NotFoundSourceParttimeUser: '未找到原始兼职人员信息',
          },
          // 抄送
          Distribution: {
            Title: '稽查报告分发/抄送至',
            Add: '添加人员',
            Sort: '序号',
            Name: '姓名',
            Job: '职位',
            Company: '公司',
            Operate: '操作',
            Edit: '编辑',
            Delete: '删除',

            ExistAddNotMove: '存在新增的数据不允许移动',

            PleaseInputName: '请输入抄送人员名称',
            PleaseInputJob: '请输入抄送人员职位',
            PleaseInputCompany: '请输入抄送人员公司',
            PleaseCompanyUser: '选择公司成员',
            ConfirmDeleteDistribution: '确定删除抄送人员嘛?',
          },
          // 内容与小结
          ContentSummary: {
            Title: '稽查主要内容及小结',
            ReferenceStandards: '稽查参考标准',
            RangeAndMainContent: '稽查的范围和主要内容，包括',
            CenterOverview: '中心概况',
            PleaseInputContent: '请输入内容',
            Modify: '修改',
            Save: '保存',
            Cancel: '取消',
            PleaseInputComment: '请输入评论内容',
            ReplyTo: '回复对象',
            Commentator: '评论人',
            SourceContent: '原始内容',
            Comments: '评论内容',
            CommentTime: '评论时间',
            CurrentComment: '当前评论',
            QuotedPassage: '引用段落',
            AllText: '全文引用',
            // 暂无内容
            NoContent: '',
            Reply: '回复',

          },
          // 发现总结
          DiscoverySummary: {
            Title: '稽查发现汇总',
            Abbreviation: '缩写',
            MainCategory: '稽查发现主分类',
            SubCategory: '稽查发现子分类',
            Major: '重大',
            Main: '主要',
            Slight: '轻微'
          },
          // 发现描述
          DiscoveryDescription: {
            Title: '稽查发现描述',
            PleaseSelectMainCategory: '请选择主分类',
            PleaseSelectSubCategory: '请选择子分类',
            MainCategoryNotInDic: '主分类未在字典选项中',
            SubCategoryNotInDic: '子分类未在字典选项中',
            FilterNumber: '筛选号',
            // 暂无内容
            NoContent: '',
            PleaseInputContent: '请输入内容',
            Reference: '参考',
            Dilatation: '详述',
            MainCategory: '主分类',
            SubCategory: '子分类',
            PleaseSelectGrading: '请选择分级',
            Grading: '分级',
            Number: '编号',
            ProblemSummary: '问题归纳',
            ProblemDetail: '问题详情',
            ProblemOverview: '概述',
            ConfirmMoidfy: '修改分级和分类数据可能合并重组，页面将刷新重载数据，确定修改？'
          },
          // 报告附件
          ReportAppendix:{
            Title: '附表',
            SerialNumber: '序号',
            FilterNumber: '筛选号',
            Visit: '访视',
            SourceRecord: '原始记录',
            EDCPosition: 'EDC位置',
            EDCContent: 'EDC',

            PleaseInputName: '请输入名称',
            PleaseInputSerialNumber: '请输入序号',
            PleaseInputFilterNumber: '请输入筛选号',
            PleaseInputVisit: '请输入访视',
            PleaseInputSourceRecord: '请输入原始记录',
            PleaseInputEDCPosition : '请输入EDC位置',
            PleaseInputEDCContent: '请输入EDC内容'
          },
          Bottom: {
            ReviewCommit: '提交评审',
            PleaseSelectUser: '请选择评审人员',
            PleaseSelectApproveMode: '请选择审核模式',
            ConfirmCommit: '确定提交评审嘛?',
            Reject: '驳回',
            Pass: '通过',
            ApproveModeAnd: '会签',
            ApproveModeOr: '或签',
          }
        },
        Comment: {
          Comment: '评论',
          Reply: '回复'
        },
        // 报告附件
        ReportAppendix:{
          ImportDate: '导入日期',
          PleaseSelectStartDate: '请选择开始日期',
          PleaseSelectEndDate:'请选择结束日期',
          Search1: '搜索',
          Search: '查询',
          Reset: '重置',
          Add: '新增',
          Edit: '编辑',
          Delete: '删除',
          Import: '导入',
          DownloadTempalte: '下载模板',
          Operate: '操作',
          OnlyOneSelectEdit: "有且仅能选择一条报告附件进行编辑",
          ConfirmDeleteReportAppendix: '确定删除报告附件吗?',
          AtLeastOneReportAppendix: '至少选择一条报告附件',
          PleaseInputSearch: '请输入搜索内容',
          File: '报告附件',
          CreateName: '上传人',
          CreateTime: '上传时间',
          Sort: '排序',
          Name: '名称',
          SerialNumber: '序号',
          FilterNumber:'筛选号',
          Visit: '访视',
          SourceRecord: '原始记录',
          EDCPosition: 'EDC位置',
          EDCContent: 'EDC内容',
          
          PleaseInputName: '请输入名称',
          PleaseInputSort: '请输入排序',
          ModifyReportAppendix: '修改报告附件',
          AddReportAppendix: '新增报告附件',
          ConfirmSave: '确定保存报告附件数据吗?',
          ConfirmSaveReportAppendixDetail: '确定保存报告附件记录数据吗?',

          
          ConfirmDeleteReportAppendixDetail: '确定删除报告附件记录吗?',
          AtLeastOneReportAppendixDetail: '至少选择一条报告附件记录',

          PleaseInputSerialNumber: '请输入序号',
          PleaseInputFilterNumber: '请输入筛选号',
          PleaseInputVisit: '请输入访视',
          PleaseInputSourceRecord: '请输入原始记录',
          PleaseInputEDCPosition: '请输入EDC位置',
          PleaseInputEDCContent: '请输入EDC录入内容',
          ModifyReportAppendixDetail: '修改报告附件记录',
          AddReportAppendixDetail: '新增报告附件记录',


        }
      }
    },
    ReviewApprove: {
      MineReview: {
        Search: '查询',
        ProjectNumber: '项目编号',
        ProjectTaskNumber: '任务编号',
        AuditedParty: '被稽查方',
        SubmitTime: '提交审阅时间',
        Reset: '重置',
        Export: '导出',
        Operate: '操作',
        Distribute: '分配',
        Review: '审阅',
        StartDate: '开始时间',
        EndDate: '结束时间',
        Progress: '进度',
        ReviewApproveProgress: '审阅进度',

        Reviewer: '审阅人',
        WaiteReviewer: '待审阅人',
        SubmitForReview: '待审阅',


        DialogTitleDistribute: '分配审阅人',
        Collboration: '协同审阅人',
        ConfirmDistributeReviewer: '确定分配审阅人吗?',
        ApproverAndCollborationAtLeastOne: '审阅人和协同审核人至少选择一个',

        PleaseISelectProjectNumber: '请选择项目编号',
        PleaseSelectProjectTaskNumber: '请选择任务编号',
        PleaseSelectAuditParty: '请选择被稽查方',
        PleaseSelectReviewer: '请选择审阅人',
        PleaseSelectCollboration: '请选择协同审阅人',
        PleaseSelectReviewApproveProgress: '请选择审阅进度',

        WaitReview: '待审阅',
        HaveReview: '已审阅',
        WaitCollborationReview: '待协同审阅'
      }
    },
    Progress: {
      Project: {
        Search: '查询',
        Reset: '重置',
        Export: '导出',
        ProjectNumber: '项目编号',
        Progress: '进度',
        ProjectManager: '项目负责人',

        All: '全部',
        ToBeAllocated: '待分配',
        Ready: '准备中',
        InProgress: '进行中',
        Complete: '已完成',
        Cancel1: '已取消',

        PleaseInputProjectNumber: '请输入项目编号',
        PleaseSelectProgress: '请选择项目进度',
        PleaseSelectProjectManager: '请选择项目负责人'
      },
      ProjectTask: {
        Search: '查询',
        Reset: '重置',
        Export: '导出',
        ProjectNumber: '项目编号',
        ProjectTaskNumber: '任务编号',
        AuditedPartyName: '被稽查方名称',
        MainAuditor: '主稽查员',
        Auditor: '稽查员',
        ProjectStage: '项目阶段',
        ImplementationDepartment: '实施科室',
        MainResearcher: '主要研究者',
        AuditAddress: '稽查地址',
        PlannedAuditStartTime: '计划稽查开始时间',
        PlannedAuditEndTime: '计划稽查结束时间',
        Progress: '进度',

        PleaseInputProjectNumber: '请输入项目编号',
        PleaseInputProjectTaskNumber: '请输入任务编号',
        PleaseSelectProgress: '请选择项目进度',
        PleaseSelectMainAuditor: '请选择主稽查员',
        PleaseSelectAuditor: '请选择稽查员',

        All: '全部',
        ToBeAllocated: '待分配',
        Ready: '准备中',
        AuditImplementation: '稽查实施',
        ReportWriting: '报告撰写',
        SubmitForReview: '待审阅',
        UnderReview: '审阅中',
        ReviewedAndApproved: '审阅通过',
        CAPAFinalize: 'CAPA定稿',
        Complete: '已完成',
        Rewrite: '返改',
        Cancel: '已取消',
      },
      ProjectAndTask: {
        Search: '查询',
        Reset: '重置',
        Export: '导出',
        ProjectNumber: '项目编号',
        ProjectManager: '项目负责人',
        Progress: '进度',
        TaskStatistic: '任务统计',


        PleaseInputProjectNumber: '请输入项目编号',
        PleaseSelectProgress: '请选择项目进度',
        PleaseSelectProjectManager: '请选择项目负责人'
      },
      ReportAndCAPA: {
        Search: '查询',
        Reset: '重置',
        Export: '导出',

        ProjectTaskNumber: '任务编号',
        Progress: '进度',
        AuditedParty: '被稽查方',
        MainAuditor: '主稽查员',
        Auditor: '稽查员',
        ReprotHyperWindow: '报告超窗',
        ReportHyperWindowDays: '报告超窗天数',
        CAPAHyperWindow: 'CAPA超窗',
        ReportHyperWindowBefore: '报告超窗前3天',
        CAPAHyperWindowBefore: 'CAPA超窗前7天',
        PlannedAuditTime: '计划时间',
        AuditedPartyName:'被稽查方名称',
        AuditAddress:"稽查地址",
        PlanAuditTime: '计划稽查日期',
        PlanAuditStartTime: '计划稽查开始日期',
        PlanAuditEndTime:'计划稽查结束日期',
        ActualAuditTime: '实际稽查日期',
        ActualAuditStartTime: '实际稽查开始日期',
        ActualAuditEndTime: '实际稽查结束日期',
        PlanReportTime: '计划报告日期',
        CompleteReportTime: '完成报告日期',
        PlanCAPATime: '计划CAPA日期',
        ActualCAPATtime: '实际CAPA日期',
        ReprotHyperWindowDays: '报告超窗天数',
        CAPAHyperWindowDays: 'CAPA超窗天数',
        NotHyperWindow: '未超窗',
        HyperWindow: '超窗',
        TotalHyperWindow: '总超窗',
        ReportHyperWindow: '报告超窗',
        HyperWindow3Days: '超窗前3天',
        HyperWindow7Days: '超窗前7天',

        StartDays: '开始',
        EndDays: '结束',

        PleaseInputProjectTaskNumber: '请输入任务编号',
        PleaseInputReportHyperWindowDays: '请输入报告超窗天数',
        PleaseInputCAPAHyperWindowDays: '请输入CAPA超窗天数',
        PleaseSelectProgress: '请选择进度',
        PleaseSelectAuditedParty: '请选择被稽查方',
        PleaseSelectMainAuditor: '请选择主稽查员',
        PleaseSelectAuditor: '请选择稽查员',
        PleaseSelectReportIsHyperWindow: '请选择报告是否超窗',
        PleaseSelectCAPAIsHyperWindow: '请选择CAPA是否超窗',
        PleaseSelectStartDate: '请选择开始时间',
        PleaseSelectEndDate: '请选择结束时间',

      }
    },
    WorkingHours: {
      WorkingHours: {
        Search: '搜索',
        Search1: '查询',
        Reset: '重置',
        Add: '新增',
        Edit: '编辑',
        Delete: '删除',
        Export: '导出',
        ContractNo: '合同编号',
        ProjectNumber: '项目编号',
        WorkContent: '工作内容',
        Client: '委托方',
        ProjectTaskNumber: '任务编号',
        TotalServiceFeeBeforeTax: '税前服务费总额',
        User: '稽查/服务人员',
        ParttimeUser: '兼职人员',
        User1: '服务人员',
        ServiceType: '服务类型',
        Type: '类型',
        StartTime: '稽查开始日期',
        EndTime: '稽查结束日期',
        StartTime1: '服务开始日期',
        EndTime1: '服务结束日期',
        Operate: '操作',
        Time: '时间',
        Remark: '备注',
        AuditType: '稽查类型',
        NotAuditType: '非稽查类型',
        CenterNumber: '中心编号',
        AuditedPartyName: '中心名称',
        ReadyTime: '稽查准备',
        OnsideTime: '现场稽查',
        FollowupTime: '后续工作',
        BusinesstripTime: '差旅时间',
        UnitPrice: '每小时单价(元)',
        Discount: '折扣',
        TotalAmount: '总费用(元)',
        TotalHours: '总工时(小时)',
        WorkingHours: '工时',
        OtherAmount: '其他费用',
        ParttimeJobConversion: '兼职折算',
        TeachingConversion: '带教折算',
        PlanWorkingHours: '计划工时',
        ActualWorkingHours: '实际工时',
        PlanTotalHours: '计划总工时',
        PlanTotalAmount: '计划总费用',
        ActualTotalHours: '实际总工时',
        ActualTotalAmount: '实际总费用',
        Plan: '计划',
        Actual: '实际',
        StartTime2: '开始时间',
        EndTime2: '结束时间',



        PleaseSelectStartDate: '请选择开始时间',
        PleaseSelectEndDate: '请选择结束时间',
        PleaseInputContractNo: '请输入合同编号',
        PleaseInputTotalServiceFeeBeforeTax: '请输入税前服务费总额',
        PleaseSelectProjectNumber: '请选择项目编号',
        PleaseSelectProjectTaskNumber: '请选择任务编号',
        PleaseInputReadyTime: '请输入稽查准备时间',
        PleaseInputOnsideTime: '请输入现场稽查时间',
        PleaseInputFollowupTime: '请输入后续工作时间',
        PleaseInputBusinesstripTime: '请输入差旅时间',
        PleaseInputParttimeUser: '请输入兼职人员',
        PleaseInputPlanWorkingHours: '请输入计划工时',
        PleaseInputActualWorkingHours: '请输入实际工时',
        PleaseInputUnitPrice: '请输入单价',
        PleaseInputDiscount: '请输入折扣',
        PleaseInputTotalHours: '请输入总工时',
        PleaseInputTotalAmount: '请输入总费用',
        PleaseSelectUser: '请选择人员',
        PleaseSelectServiceType: '请选择服务类型',
        PleaseSelectType: '请选择类型',
        PleaseSelectStartTime: '请选择开始时间',
        PleaseSelectEndTime: '请选择结束时间',
        PleaseSelectStartEndTime: '请选择日期',
        PleaseInputRemark: '请输入备注',
        PleaseInputOtherAmount: '请输入其他费用',
        PleaseInputParttimeJobConversion: '请输入兼职折算',
        PleaseInputTeachingConversion: '请输入带教折算',
        PleaseInputWorkContent: '请输入工作内容',

        ValidateMaxLength: '最大长度超过100个字符',
        ValidateMaxLength255: '最大长度超过255个字符',
        ConfirmSaveWorkingHours: '确定保存工时数据嘛',
        DialogWorkingHoursTitleMaintance: '工时维护',
        DialogWorkingHoursTitleAdd: '新增工时',

        ConfirmDeleteWorkingHoursParty: '确定删除工时数据吗?',
        AtLeastOneWorkingHours: '至少选择一条工时数据',

        OnlyOneSelectEdit: "有且仅能选择一条工时进行编辑",
      }
    },

    AuditCalendar: {
      TaskCalendar: {
        DateRange: '日期范围',
        StartDate: '开始日期',
        EndDate: '结束日期',
        PleaseSelectStartDate: '请选择开始日期',
        PleaseSelectEndDate: '请选择结束日期',
        PleaseSelectDate: '请选择日期',
        PleaseSelectCAPAPlanTime: '请选择CAPA计划完成时间',
        PleaseSelectCAPAActualTime: '请选择CAPA计划完成时间',
        PleaseSelectPlannedReportTime: '请选择报告计划时间',
        PleaseSelectCompleteReportTime: '请选择报告完成时间',
        TipNotActualAuditTime: '未录入实际稽查时间',
        TipHaveActualAuditTime: '已录入实际稽查时间',
        Client: '委托方',
        AuditedParty: '被稽查方',
        ProjectNumber: '项目编号',
        ProjectTaskNumber: '任务编号',

        Today: '今日',
        Task: '任务',
        Search: '查询',
        Reset: '重置',

        ModifyTask: '修改项目任务',
        PlanAuditTime: '计划稽查时间',
        ActualAuditTime: '实际稽查时间',
        CAPAPlanTime: 'CAPA计划完成时间',
        CAPAActualTime: 'CAPA实际完成时间',
        PlannedReportTime: '报告计划时间',
        CompleteReportTime: '报告完成时间',

        PleaseSelectClient: '请选择委托方',
        PleaseSelectProjectNumber: '请选择项目',
        PleaseSelectProjectTaskNumber: '请选择任务',
        PleaseSelectAuditedParty: '请选择被稽查方',


        ConfirmSaveData: '确定保存嘛?'
      },
      PersoCalendar: {
        Add: '新增',
        DateRange: '日期范围',
        StartDate: '开始日期',
        EndDate: '结束日期',
        Client: '委托方',
        ProjectNumber: '项目编号',
        ProjectTaskNumber: '任务编号',
        Type: '类型',
        Remark: '备注',
        PleaseSelectType: '请选择类型',
        Scheduling: '排班',
        PleaseSelectClient: '请选择委托方',
        PleaseSelectProjectNumber: '请选择项目',
        PleaseSelectProjectTaskNumber: '请选择任务',
        PleaseSelectStartDate: '请选择开始日期',
        PleaseSelectEndDate: '请选择结束日期',
        PleaseSelectDate: '请选择日期',
        PleaseSelectUser: '请选择稽查员',
        PleaseSelectSchedulingDate: '请选择排班日期',
        PleaseInputRemark: '请输入备注',
        Today: '今日',
        Person: '稽查员',
        Task: '任务',
        Search: '查询',
        Reset: '重置',
        TipNotActualAuditTime: '未录入实际稽查时间',
        TipHaveActualAuditTime: '已录入实际稽查时间',
        TipScheduling: '排班',
        TipVacation: '休假',

        AddScheduling: '新增排班',
        ModifyScheduling: '修改排班',
        Starttime: '开始时间',
        Endtime: '结束时间',
        ConfirmSaveData: '确定保存嘛?',
        ConfirmDeleteTask: '确定删除人员排班数据吗?',


        SchedulingName: '排班',
        Vacation : '休假',
        DeleteTask: '删除'
      }
    },

    Statistics: {
      AuditDiscovery: {
        Search: '搜索',
        Search1: '查询',
        Reset: '重置',
        Add: '新增',
        Edit: '编辑',
        Export: '导出',

        ProjectNumber: '项目编号',
        Client: '委托方',
        Category: '委托方类型',
        ServiceType:'服务类型',
        AuditType: '稽查类型',
        Sponsor: '申办者',
        SponsorType: '申办者类型',
        ResearchProducts: '研究产品',
        ResearchProductsType: '研究产品类型',
        TrialStaging: '试验分期',
        TherapeuticArea: '治疗领域',
        Indications: '适应症',
        TrialProgressStage: '试验进展阶段',
        ProjectTaskNumber: '任务编号',
        AuditedParty: '被稽查方',
        AuditedPartyArea: '被稽查方区域',
        AuditedPartyQualification: '被稽查方资质',
        HospitalType: '医院类型',
        ImplementationDepartment: '实施科室',
        MainResearcher: '主要研究者',
        CompleteReportTime: '报告完成日期',
        DiscoveryGrading: '问题分级',
        MainCategory: '主分类',
        SubCategory: '子分类',
        ProblemOverview: '问题概述',
        ProblemSummary: '问题归纳',
        FilterNumber: '筛选号',
        ProblemDetail: '问题详情',
        Remark: '备注',
        IsImportReport: '是否写入报告',
        Yes: '是',
        No: '否',

        PleaseSelectProject: '请选择项目',
        PleaseSelectClient: '请选择委托方',
        PleaseSelectCategory:'请选择委托方类型',
        PleaseSelectServiceType: '请选择服务类型',
        PleaseSelectAuditType: '请选择稽查类型',
        PleaseInputSponsor: '请输入申办者',
        PleaseSelectSponsorType: '请选择申办者类型',
        PleaseResearchProducts: '请输入研究产品',
        PleaseSelectResearchProductsType: '请选择研究产品类型',
        PleaseSelectTrialStaging: '请选择试验分期',
        PleaseSelectTherapeuticArea: '请选择治疗领域',
        PleaseInputIndications: '请输入适应症',
        PleaseSelectTrialProgressStage: '请选择试验进展阶段',
        PleaseSelectProjectTaskNumber: '请选择任务',
        PleaseSelectAuditedParty: '请选择被稽查方',
        PleaseSelectAuditedPartyArea:'请选择被稽查方区域',
        PleaseSelectAuditedPartyQualification:'请选择被稽查方资质',
        PleaseSelectHospitalType: '请选择医院类型',
        PleaseInputImplementationDepartment: '请输入实施科室',
        PleaseInputMainResearcher: '请输入主要研究者',
        PleaseSelectCompleteReportStartTime: '请选择报告完成日期开始日期', 
        PleaseSelectCompleteReportEndTime: '请选择报告完成日期结束日期', 
        PleaseSelectDiscoveryGrading: '请选择问题分级',
        PleaseSelectMainCategory: '请选择主分类',
        PleaseSelectSubCategory: '请选择子分类',
        PleaseSelectIsImportReport: '请选择是否写入报告',


        BarChart: '柱状图',
        Table: '表格',
        OnlyCompleteStatistic: '统计完成状态',

        // ResearchProduct: '研究产品',
        // TherapeuticArea: '治疗领域',
        // AuditedParty: '被稽查方',
        // AuditedType: '稽查类型',
        // MainCategory: '主分类',
        // SubCategory: '子分类',
        // Grading: '分级',
        // ProblemSummary: '问题归纳',
        // FilterNumber: '筛选号',
        // ProblemDetail: '问题详情',
        // Remark: '备注',
        // IsImport: "是否入报告",
        // CreateUser: '创建人',
        // AuditTime: '稽查时间',
        // Yes: '是',
        // No: '否',
        // CompleteReportTime: '报告完成日期',



        // PleaseInputProjectNumber: '请输入项目编号',
        // PleaseSelectProjectClient: '请选择委托方',
        // PleaseInputResearchProduct: '请输入研究产品',
        // PleaseInputTherapeuticArea: '请输入治疗领域',
        // PleaseSelectAuditedPary: '请选择被稽查方',
        // PleaseSelectAuditedType: '请选择稽查类型',
        // PleaseSelectCreateUser: '请选择创建人',
        // PleaseSelectStartTime: '请选择开始日期',
        // PleaseSelectEndTime: '请选择结束日期',
        // PleaseSelectTherapeuticArea: '请选择治疗领域',

        // PleaseSelectCompleteReportTime: '请选择报告完成日期',
        // PleaseSelectCompleteReportTimeStart: '请选择报告完成开始日期',
        // PleaseSelectCompleteReportTimeEnd: '请选择报告完成结束日期',

        // ValidateMaxLength: '最大长度超过100个字符',
        // ValidateMaxLength255: '最大长度超过255个字符',
        // ConfirmSaveWorkingHours: '确定保存工时数据嘛',
        // DialogWorkingHoursTitleMaintance: '工时维护',
        // DialogWorkingHoursTitleAdd: '新增工时',


        // OnlyOneSelectEdit: "有且仅能选择一条工时进行编辑",
      },
      ProjectTask: {
        Search: '搜索',
        Search1: '查询',
        Reset: '重置',
        Add: '新增',
        Edit: '编辑',
        Export: '导出',

        ProjectTaskNumber: '任务编号',
        Client: '委托方',
        Category: '委托方类型',
        ServiceType: '服务类型',
        AuditType: '稽查类型',
        Sponsor: '申办者',
        SponsorType: '申办者类型',
        ResearchProducts: '研究产品',
        ResearchProductsType: '研究产品类型',
        TherapeuticArea: '治疗领域',
        Indications: '适应症',
        TrialStaging: '试验分期',
        TrialProgressStage: '试验进展阶段',
        AuditedParty: '被稽查方',
        AuditedPartyArea: '被稽查方区域',
        AuditedPartyQualification: '被稽查方资质',
        HospitalType: '医院类型',

        Auditaddress: '稽查地址',
        ImplementationDepartment: '实施科室',
        MainResearcher: '主要研究者',
        MainAuditor: '主稽查员',
        Auditor: '稽查员',
        AuditTime: '稽查时间',  
        ActualAuditStartTime: '实际稽查开始时间',   
        ActualAuditEndTime: '实际稽查结束时间',   


        PleaseSelectProjectTaskNumber: '请选择任务',
        PleaseSelectClient: '请选择委托方',
        PleaseSelectCategory: '请选择委托方类型',
        PleaseSelectServiceType: '请选择服务类型',
        PleaseSelectAuditType: '请选择稽查类型',
        PleaseInputSponsor: '请输入申办者',
        PleaseSelectSponsorType: '请选择申办者类型',
        PleaseResearchProducts: '请输入研究产品',
        PleaseSelectResearchProductsType: '请选择研究产品类型',



        PleaseSelectTherapeuticArea: '请选择治疗领域',
        PleaseInputIndications: '请输入适应症',
        PleaseSelectTrialStaging: '请选择试验分期',
        PleaseSelectTrialProgressStage: '请选择试验进展阶段',
        PleaseSelectAuditedParty: '请选择被稽查方',
        PleaseSelectAuditedPartyArea:'请选择被稽查方区域',
        PleaseSelectAuditedPartyQualification:'请选择被稽查方资质',
        PleaseSelectHospitalType: '请选择医院类型',

        PleaseInputAuditaddress: '请输入稽查地址',
        PleaseInputImplementationDepartment: '请输入实施科室',
        PleaseInputMainResearcher: '请输入主要研究者',
        PleaseSelectMainAuditor: '请选择主稽查员',
        PleaseSelectAuditor: '请选择稽查员',
        PleaseSelectStartTime: '请选择开始日期',
        PleaseSelectEndTime: '请选择结束日期',
      }
    }
  }
}