<template>
  <ul class="exceltemplate-ul">
    <li class="hm-search">
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item :label="$t('Components.System.ExcelTemplate.Search')">
          <el-input
            v-model="searchForm.inputSearch"
            :placeholder="$t('Components.System.ExcelTemplate.SearchName')"
            clearable
          />
        </el-form-item>
        <el-form-item :label="$t('Components.System.ExcelTemplate.Dataflag')">
          <el-select
            v-model="searchForm.dataflag"
            :placeholder="$t('Components.System.ExcelTemplate.SelectDataflag')"
            clearable
          >
            <el-option
              :label="$t('Components.System.ExcelTemplate.DataflagY')"
              value="true"
            />
            <el-option
              :label="$t('Components.System.ExcelTemplate.DataflagN')"
              value="false"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="buttonVisible.searchButton"
            type="primary"
            @click="queryClick"
            :icon="Search"
            >{{ $t("Components.System.ExcelTemplate.Search1") }}</el-button
          >
          <el-button
            v-if="buttonVisible.resetButton"
            @click="resetClick"
            :icon="RefreshLeft"
            >{{ $t("Components.System.ExcelTemplate.Reset") }}</el-button
          >
        </el-form-item>
      </el-form>
    </li>
    <li class="hm-operate">
      <!-- 操作部分 -->
      <el-button
        v-if="buttonVisible.addButton"
        :style="{ color: 'white' }"
        :icon="Plus"
        :color="ButtonColor.Add"
        @click="
          () => {
            dialogVisible = true;
          }
        "
        >{{ $t("Components.System.ExcelTemplate.Add") }}</el-button
      >
      <el-button
        v-if="buttonVisible.editButton"
        :disabled="!(selectExcelTemplates && selectExcelTemplates.length === 1)"
        :style="{ color: 'white' }"
        :icon="Edit"
        :color="ButtonColor.Edit"
        @click="handleEdit(1)"
        >{{ $t("Components.System.ExcelTemplate.Edit") }}</el-button
      >
      <el-button
        v-if="buttonVisible.deleteButton"
        :disabled="!(selectExcelTemplates && selectExcelTemplates.length > 0)"
        :style="{ color: 'white' }"
        :icon="Delete"
        :color="ButtonColor.Delete"
        @click="handleDeleteBatch"
        >{{ $t("Components.System.ExcelTemplate.Delete") }}</el-button
      >
    </li>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          row-key="guid"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            prop="name"
            min-width="200"
            show-overflow-tooltip
            :label="$t('Components.System.ExcelTemplate.Name')"
          />
          <el-table-column
            prop="code"
            min-width="200"
            show-overflow-tooltip
            :label="$t('Components.System.ExcelTemplate.Code')"
          />
          <el-table-column
            prop="file_name"
            min-width="200"
            show-overflow-tooltip
            :label="$t('Components.System.ExcelTemplate.FileName')"
          />
          <el-table-column
            prop="remark"
            min-width="200"
            show-overflow-tooltip
            :label="$t('Components.System.ExcelTemplate.Remark')"
          />
          <el-table-column
            prop="dataflag"
            :label="$t('Components.System.ExcelTemplate.Dataflag')"
          >
            <template #default="scope">
              <el-switch
                :disabled="!buttonVisible.dataflag"
                v-model="scope.row.dataflag"
                @change="(flag) => handleDataflagChange(flag, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="createtime"
            min-width="120"
            :label="$t('Components.System.ExcelTemplate.CreateTime')"
          />
          <el-table-column
            :label="$t('Components.System.ExcelTemplate.Operate')"
            width="240"
          >
            <template #default="scope">
              <el-space wrap>
                <el-button
                  v-if="buttonVisible.downloadButtonLine"
                  :style="{ color: 'white' }"
                  :icon="Download"
                  :color="ButtonColor.Export"
                  size="small"
                  @click="handleDownload(2, scope.row)"
                  >{{ $t("Components.System.ExcelTemplate.Download") }}</el-button
                >
                <el-button
                  v-if="buttonVisible.editButtonLine"
                  :style="{ color: 'white' }"
                  :icon="Edit"
                  :color="ButtonColor.Edit"
                  size="small"
                  @click="handleEdit(2, scope.row)"
                  >{{ $t("Components.System.ExcelTemplate.Edit") }}</el-button
                >
                <el-button
                  v-if="buttonVisible.deleteButtonLine"
                  :style="{ color: 'white' }"
                  :icon="Delete"
                  :color="ButtonColor.Delete"
                  size="small"
                  bg
                  @click="handleDelete(scope.row)"
                  >{{ $t("Components.System.ExcelTemplate.Delete") }}</el-button
                >
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </li>
    </el-scrollbar>

    <li class="hm-page">
      <el-pagination
      small
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="AllPageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </li>
  </ul>
  <ExcelTemplateModify
    v-if="dialogVisible"
    @handleCloseDialog="handleCloseDialog"
    :editData="editData"
    @getExcelTemplatePage="getExcelTemplatePage"
  />
</template>
<script setup>
import { reactive, ref, markRaw, onMounted, watchEffect } from "vue";
import {
  Plus,
  Delete,
  RefreshLeft,
  Edit,
  Search,
  WarnTriangleFilled,
  Download,
} from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";
import {
  AllPageSize,
  ButtonColor,
  DefaultPageSize,
} from "@/consts/frame";
import {
  request_exceltemplate_page,
  request_exceltemplate_delete,
  request_exceltemplate_delete_batch,
  request_exceltemplate_dataflag,
} from "@/api/apis";
import ExcelTemplateModify from "@/components/System/ExcelTemplate/ExcelTemplateModify.vue";
import { useStore } from "@/store/index";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";

const store = useStore();
const { t } = useI18n();
const searchForm = reactive({
  inputSearch: "",
  dataflag: undefined,
});
const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  addButton: false,
  editButton: false,
  deleteButton: false,
  editButtonLine: false,
  deleteButtonLine: false,
  downloadButtonLine: false,
  dataflag: false,
});
// 当前页码
const currentPage = ref(1);
// 页码大小
const pageSize = ref(DefaultPageSize);
// 表格数据
const tableData = ref([]);
// 表格加载
const tableLoading = ref(false);
// 总数量
const total = ref(0);
// 编辑的数据
const editData = ref({});
// 弹窗是否可见
const dialogVisible = ref(false);
// // 创建时间排序规则 如果为undefined 则没有这个参数，如果为true代表倒叙，为false代表正序
// const createTimeSort = ref(undefined);
// 选择的数据
const selectExcelTemplates = ref([]);

onMounted(async () => {
  await getExcelTemplatePage();
});
// 分页查询数据
const getExcelTemplatePage = async () => {
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    text: searchForm.inputSearch,
    dataflag: searchForm.dataflag,
    // createTimeSort: createTimeSort.value,
  };
  tableLoading.value = true;
  const result = await request_exceltemplate_page(
    trans,
    () => (tableLoading.value = false)
  );
  total.value = result.total;
  tableData.value = result.rows;
  selectExcelTemplates.value = [];
  editData.value = {};
};

// 查询事件
const queryClick = () => {
  getExcelTemplatePage();
};
// 重置事件
const resetClick = () => {
  searchForm.dataflag = undefined;
  searchForm.inputSearch = "";
  getExcelTemplatePage();
};
// 处理页码大小改变事件
const handleSizeChange = (size) => {
  pageSize.value = size;
  getExcelTemplatePage();
};
// 处理当前页改变事件
const handleCurrentChange = (index) => {
  currentPage.value = index;
  getExcelTemplatePage();
};
// 处理编辑事件
const handleEdit = (type, data) => {
  let row = undefined;
  if (type === 1) {
    // 判断是否有且仅选择了一条用户数据
    if (selectExcelTemplates.value.length !== 1) {
      ElMessage.warning(t("Components.System.ExcelTemplate.OnlyOneSelectEdit"));
      return;
    }
    row = selectExcelTemplates.value[0];
  } else if (type === 2) {
    row = data;
  }
  editData.value = {
    guid: row.guid,
    code: row.code,
    name: row.name,
    path: row.path,
    file_name: row.file_name,
    remark: row.remark,
  };
  dialogVisible.value = true;
};
// 处理回调关闭事件
const handleCloseDialog = () => {
  dialogVisible.value = false;
  editData.value = {};
};
// 处理有效性改变事件
const handleDataflagChange = async (flag, row) => {
  await request_exceltemplate_dataflag(row.guid, true, () => {
    row.dataflag = !row.dataflag;
  });
};
// 处理选择回调事件
const handleSelectionChange = (ammouncements) => {
  selectExcelTemplates.value = ammouncements;
};
// 处理删除
const handleDelete = async (row) => {
  ElMessageBox.confirm(
    t("Components.System.ExcelTemplate.ConfirmDeleteExcelTemplate"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      await request_exceltemplate_delete(row.guid, true);
      getExcelTemplatePage();
    })
    .catch(() => {});
};
// 批量删除
const handleDeleteBatch = async () => {
  if (selectExcelTemplates.value.length === 0) {
    ElMessage.warning(t("Components.System.ExcelTemplate.AtLeastOneExcelTemplate"));
    return;
  }
  ElMessageBox.confirm(
    t("Components.System.ExcelTemplate.ConfirmDeleteExcelTemplate"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const trans = {
        guids: selectExcelTemplates.value.map((item) => item.guid),
      };
      await request_exceltemplate_delete_batch(trans, true);
      getExcelTemplatePage();
    })
    .catch(() => {});
};
// 处理下载 type = 1代表多选下载；2代表行下载 
const handleDownload = (type, row) => {
  let path = ''
  if(type === 2){
    path = row.path
  }
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = path;
  link.target = '_blank'
  document.body.appendChild(link);
  link.click();
};

watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.TemplateManager,
      MenuButtonCodes.System.TemplateManager.ExcelTemplateSearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.TemplateManager,
      MenuButtonCodes.System.TemplateManager.ExcelTemplateReset
    );
    // 处理新增
    buttonVisible.addButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.TemplateManager,
      MenuButtonCodes.System.TemplateManager.ExcelTemplateAdd
    );
    // 处理编辑
    buttonVisible.editButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.TemplateManager,
      MenuButtonCodes.System.TemplateManager.ExcelTemplateEdit
    );
    // 处理删除
    buttonVisible.deleteButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.TemplateManager,
      MenuButtonCodes.System.TemplateManager.ExcelTemplateDelete
    );
    // 处理下载行
    buttonVisible.downloadButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.System.TemplateManager,
      MenuButtonCodes.System.TemplateManager.ExcelTemplateDownloadLine
    );
    // 处理编辑行
    buttonVisible.editButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.System.TemplateManager,
      MenuButtonCodes.System.TemplateManager.ExcelTemplateEditLine
    );
    // 处理删除行
    buttonVisible.deleteButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.System.TemplateManager,
      MenuButtonCodes.System.TemplateManager.ExcelTemplateDeleteLine
    );
    // 处理有效性
    buttonVisible.dataflag = validateMenuButton(
      store.userMenus,
      MenuCodes.System.TemplateManager,
      MenuButtonCodes.System.TemplateManager.ExcelTemplateDataflag
    );
  }
});
</script>
<style>
.exceltemplate-ul .el-scrollbar__view {
  height: calc(100% - 8px);
}
</style>
<style scoped lang="scss">
.exceltemplate-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  .exceltemplate-li-search {
    // height: 100px;
  }
}
</style>
