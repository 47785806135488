<template>
  <!-- 判断没有children数据 -->
  <el-menu-item
    class="aaa"
    v-if="!props.menuItem.children || props.menuItem.children.length === 0"
    :index="props.menuItem.menu_path"
    @click="handleMenuClick(props.menuItem)"
  >
    <el-icon>
      <component :is="props.menuItem.menu_icon" />
    </el-icon>
    <span>{{ props.menuItem.menu_name }}</span>
  </el-menu-item>
  <!-- 循环children的数据 -->
  <el-sub-menu class="bbb" v-else :index="props.menuItem.menu_path">
    <template #title>
      <el-icon>
        <component :is="props.menuItem.menu_icon" />
      </el-icon>
      <span>{{ props.menuItem.menu_name }}</span>
    </template>
    <MenuItem
      v-for="item in props.menuItem.children"
      :key="item.menu_guid"
      :menuItem="item"
    />
  </el-sub-menu>
</template>
<script setup>
import { defineProps, shallowRef } from "vue";
import { useRouter } from "vue-router";
import { routes } from "@/router";
import { useStore } from "@/store/index";
import { LocalStorageKey } from "@/consts/frame";
import MenuItem from "./MenuItem.vue";

const store = useStore();
const router = useRouter();
const props = defineProps({
  menuItem: undefined, // 菜单参数
});
// 处理二级菜单点击事件
const handleMenuClick = (currentItem) => {
  router.push({ path: currentItem.menu_path });
  const find404 = routes.filter((item) => item.path === "/404")[0];
  const activeMenu = {
    path: find404.path,
    name: find404.name,
    component: shallowRef(find404.component),
  };
  routes.forEach((item) => {
    // 先判断一级是否有，一级有的话就找一级的；一级没有就找children
    if (item.path === currentItem.menu_path) {
      // 一级找到了
      activeMenu.path = item.path;
      activeMenu.name = item.name;
      activeMenu.component = shallowRef(item.component);
      localStorage.setItem(LocalStorageKey.LastActiveMenuPath, item.path);
      // pubsub.publish(PubsubMessage.MenuClick, { menu_path: item.path })
      return;
    } else {
      // 一级没有找到，找二级
      if (item.children && item.children.length > 0) {
        // 存在节点数据
        const childrenFind = item.children.filter(
          (item1) => item1.path === currentItem.menu_path
        );
        if (childrenFind && childrenFind.length > 0) {
          activeMenu.path = childrenFind[0].path;
          activeMenu.name = childrenFind[0].name;
          activeMenu.component = shallowRef(childrenFind[0].component);
          localStorage.setItem(
            LocalStorageKey.LastActiveMenuPath,
            childrenFind[0].path
          );
          // pubsub.publish(PubsubMessage.MenuClick, {
          //   menu_path: childrenFind[0].path,
          // });
          return;
        }
      }
    }
  });
  store.setActiveMenu(activeMenu);
};
</script>
<style>
/* ,.el-menu--popup */
.el-menu--inline {
  background-color: #263445 !important;
}
.el-menu--inline span {
  pointer-events: none;
}
.el-menu-item:hover {
  background-color: #001528 !important;
}
.el-menu--inline :hover {
  background-color: #001528 !important;
}

.el-menu--popup {
  background-color: #263445 !important;
}
.el-menu--popup span {
  pointer-events: none;
}
.el-menu-item:hover {
  background-color: #001528 !important;
}
.el-menu--popup :hover {
  background-color: #001528 !important;
}
.el-menu--popup .el-menu-item {
  color: #bfcbd9;
}

/* 下面是有子菜单的菜单鼠标移入上面的背景颜色 */
.el-sub-menu__title:hover {
  background-color: #202d3d !important;
}
</style>
<style scoped lang="scss">
.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  // color: $dark_gray;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  line-height: 45px;
  margin: 10px;
}
</style>
