<template>
  <el-input-number v-model="inputData" :style="{width: '120px'}" @change="handleInputChange1" />
</template>
<script setup>

import {defineProps, defineEmits, ref} from 'vue'

const props= defineProps({
  type:{
    type: Number,
    default: ()=> 0
  },
  dicGuid:{
    type: String,
    default: ()=> ''
  },
  dicItemValue:{
    type: String,
    default: ()=>''
  },
  dicItemGuid: {
    type: String,
    default: ()=> ''
  },
  time: {
    type:Number,
    default: ()=> 0
  }
})
const inputData= ref(props.time)
const emits = defineEmits(['inputChange'])
// 处理改变事件
const handleInputChange1 = (value)=>{
  const data = {
    type: props.type,
    dicGuid: props.dicGuid,
    dicItemGuid: props.dicItemGuid,
    dicItemValue: props.dicItemValue,
    input: value
  }
  emits('inputChange', data)
}

</script>
<style scoped lang="scss"></style>
