import axios from "axios";
import { ElMessage } from 'element-plus'
// import { PAGESIZE, BASEURL } from './config'
import { BASEURL } from './config'
import pubsub from 'pubsub-js'
import PubsubMessage from '@/consts/pubsubMessage'
import { debounce } from '@/util/util'
import { DefaultPageSize, LocalStorageKey } from '@/consts/frame'
import { ApiStatus } from '@/consts/enum'


axios.interceptors.request.use(config => {
  const token = localStorage.getItem(LocalStorageKey.Token)
  const { url } = config
  const splitArr = url.split('/')
  if (!splitArr.find(item => item === 'login'))
    if (token)
      config.headers.token = token

  return config
})

axios.interceptors.response.use(response => {

  window.$global.TokenInvalid = false
  return response
}, error => {
  console.log(error)
  try {
    // 需要传递对象过去
    if (error.response.status === 601) {
      if(window.$global && !window.$global.TokenInvalid){
        window.$global.TokenInvalid = true
        pubsub.publish(PubsubMessage.LoginDialog, { flag: true, status: ApiStatus.Expired, response: error.response })
      }
      
    } else if (error.response.status === 600) {
      if(window.$global && !window.$global.TokenInvalid){
        window.$global.TokenInvalid = true
        pubsub.publish(PubsubMessage.LoginDialog, { flag: true, status: ApiStatus.RemoteLogin, response: error.response })
      }
      
    } else if (error.response.status === 401) {
      if(window.$global && !window.$global.TokenInvalid){
        window.$global.TokenInvalid = true
        pubsub.publish(PubsubMessage.LoginDialog, { flag: true, status: ApiStatus.Permission, response: error.response })
      }
      
    } else {
      if(window.$global && !window.$global.TokenInvalid){
        window.$global.TokenInvalid = true
        ElMessage.error(error.response)
      }
      // pubsub.publish(PubsubMessage.LoginDialog,  { flag: true, status: ApiStatus.NoCode, response: error.response})
      
    }
  } catch (error) {
    debounce(() => {
      ElMessage.error('服务器发生未知错误')
    }, 2000)

  }
})
// 默认参数
const defaultOptions = {
  data: {},
  ignoreToken: false,
  form: false,
  showErrorMessage500: true, // 是否显示内部错误
  showErrorMessage501: true, // 是否显示业务错误
  showSuccessMessage: false, // 是否显示成功消息
}

export function request(options) {
  let _options = Object.assign(JSON.parse(JSON.stringify(defaultOptions)), options)
  let {
    method,
    url,
    data,
    showErrorMessage500, // 显示500 服务器内部错误
    showErrorMessage501, // 显示501错误  业务错误
    showSuccessMessage, // 显示成功信息
    alwaysDoFunc, // 不管成功还是很错误都会执行的函数
    errDoFunc, // 只会发送内部错误的时候执行的函数
    successDoFunc,
  } = _options

  return new Promise((resolve) => {
    let promise;
    if (method === 'GET') {
      promise = axios.get(BASEURL + url, { params: data })
    } else if (method === 'POST') {
      promise = axios.post(BASEURL + url, data)
    } else if (method === 'PUT') {
      promise = axios.put(BASEURL + url, data)
    } else if (method === 'DELETE') {
      promise = axios.delete(BASEURL + url)
    }
    promise.then(response => {
      if (response) {
        if (alwaysDoFunc) {
          alwaysDoFunc()
        }
        if (response.data.code === 200) { // 执行成功
          if (showSuccessMessage) {
            ElMessage.success(response.data.message)
          }
          if(successDoFunc){
            successDoFunc()
          }
          resolve(response.data.response)
        } else { // 发生错误
          if (errDoFunc) {
            errDoFunc()
          }
          if (response.data.code === 501) {
            if (showErrorMessage501) { // 是否显示业务错误
              ElMessage.warning({
                dangerouslyUseHTMLString: true,
                message: response.data.message
              })
            } else { // 不显示业务错误
              debounce(() => {
                ElMessage.warning('服务器发生内部错误，请联系技术支持')
              }, 2000)
            }

          } else if (response.data.code === 500) {
            if (showErrorMessage500) { // 显示服务器错误
              ElMessage.error(response.data.message)
            }
          }
        }
      }
    })
      .catch(error => {
        console.log(error)
        debounce(() => {
          ElMessage.warning('系统发生未知错误，请联系技术支持。错误信息：' + error)
        }, 2000)
      })
  })
}

export function get(options) {
  return request({
    method: 'GET',
    ...options
  })
}

export function post(options, isPage = false) {
  if (isPage && options.data.pagesize === undefined) {
    options.data.pagesize = DefaultPageSize
  }
  return request({
    method: 'POST',
    ...options
  })
}

export function put(options) {
  return request({
    method: 'PUT',
    ...options
  })
}

export function del(options) {
  return request({
    method: 'DELETE',
    ...options
  })
}