<template>
  <!-- iclude 是组件名称，如果没有设置，默认名称就是注册的组件名称，这边需要缓存的是Index首页的页面 -->
  <!-- <router-view>
    <keep-alive ref="keepAliceRef" include="MainIndex" :exclude="exclude">
      <component :is="activePage" />
    </keep-alive>
  </router-view> -->
  <router-view />
</template>
<script setup >
import { ref, markRaw, watch, onMounted, shallowRef } from "vue";
import { useRouter } from "vue-router";
import MainIndex from "@/views/MainIndex";
import Login from "@/views/LoginIndex.vue";
import LockScreen from "@/views/LockScreen.vue";
import { useStore } from "@/store/index";
import { LocalStorageKey } from "./consts/frame";
// 激活的页面
const activePage = ref();
const router = useRouter();
const store = useStore();
// const keepAliceRef = ref();
// 排除的
const exclude = ref()

// 挂载
onMounted(() => {
  setTimeout(() => {
    const fullPath = router.currentRoute.value.fullPath;
    if (fullPath === "/lock") {
      // 锁定页面
      store.setActivePage({ component: shallowRef(LockScreen) });
    } else if (fullPath === "/login") {
      // 登录页面
      store.setActivePage({ component: shallowRef(Login) });
    } else {
      store.setActivePage({ component: shallowRef(MainIndex) });
    }
  }, 0);
  
  localStorage.removeItem(LocalStorageKey.MenuTag)
});
// 监听激活的页面
watch(
  () => store.activePage,
  (newValue) => {
    activePage.value = markRaw(newValue.component);
  }
);
// 监听
watch(()=>store.keepAliveIsCache, (newValue)=>{
  if(!newValue){ // 如果为false，就需要排除首页缓存
    exclude.value = 'MainIndex'
  }else{
    exclude.value = ''
  }
})
</script>
<style scoped lang='scss'></style>