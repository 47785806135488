<template>
  <ul class="department-ul">
    <li class="hm-search">
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item :label="$t('Components.System.Department.Search')">
          <el-input
            v-model="searchForm.inputSearch"
            :placeholder="$t('Components.System.Department.NameCode')"
            clearable
          />
        </el-form-item>
        <el-form-item :label="$t('Components.System.Department.Dataflag')">
          <el-select
            v-model="searchForm.dataflag"
            :placeholder="$t('Components.System.Department.SelectDataflag')"
            clearable
          >
            <el-option
              :label="$t('Components.System.Department.DataflagY')"
              value="true"
            />
            <el-option
              :label="$t('Components.System.Department.DataflagN')"
              value="false"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="buttonVisible.searchButton"
            type="primary"
            @click="queryClick"
            :icon="Search"
            >{{ $t("Components.System.Department.Search1") }}</el-button
          >
          <el-button
            v-if="buttonVisible.resetButton"
            @click="resetClick"
            :icon="RefreshLeft"
            >{{ $t("Components.System.Department.Reset") }}</el-button
          >
        </el-form-item>
      </el-form>
    </li>
    <li class="hm-operate">
      <!-- 操作部分 -->
      <el-button
        v-if="buttonVisible.addButton"
        :style="{ color: 'white' }"
        :icon="Plus"
        :color="ButtonColor.Add"
        @click="
          () => {
            dialogVisible = true;
          }
        "
        >{{ $t("Components.System.Department.Add") }}</el-button
      >
      <el-button
        v-if="isExpand && buttonVisible.expandButton"
        :style="{ color: 'white' }"
        :icon="Fold"
        :color="ButtonColor.ExpandFold"
        @click="handleExpandFold"
        >{{ $t("Components.System.Department.FoldAll") }}</el-button
      >
      <el-button
        v-else-if="!isExpand && buttonVisible.expandButton"
        :style="{ color: 'white' }"
        :icon="Expand"
        :color="ButtonColor.ExpandFold"
        @click="handleExpandFold"
        >{{ $t("Components.System.Department.ExpandAll") }}</el-button
      >
    </li>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          row-key="guid"
          :expand-row-keys="expandRowKeys"
        >
          <el-table-column
            prop="name"
            :label="$t('Components.System.Department.Name')"
          />
          <el-table-column
            prop="principle_name"
            :label="$t('Components.System.Department.Principle')"
          />
          <el-table-column
            prop="sort"
            :label="$t('Components.System.Department.Sort')"
          />
          <el-table-column
            prop="dataflag"
            :label="$t('Components.System.Department.Dataflag')"
          >
            <template #default="scope">
              <el-switch
                :disabled="!buttonVisible.dataflag"
                v-model="scope.row.dataflag"
                @change="(flag) => handleDataflagChange(flag, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="createtime"
            :label="$t('Components.System.Department.CreateTime')"
          />
          <el-table-column
            :label="$t('Components.System.Department.Operate')"
            width="300"
          >
            <template #default="scope">
              <el-space wrap>
                <el-button
                  v-if="buttonVisible.addButtonLine"
                  :style="{ color: 'white' }"
                  :icon="Plus"
                  :color="ButtonColor.Add"
                  size="small"
                  @click="
                    () => {
                      dialogVisible = true;
                      addParentGuid = scope.row.guid;
                    }
                  "
                  >{{ $t("Components.System.Department.Children") }}</el-button
                >
                <el-button
                  v-if="buttonVisible.editButtonLine"
                  :style="{ color: 'white' }"
                  :icon="Edit"
                  :color="ButtonColor.Edit"
                  size="small"
                  @click="handleEdit(scope.row)"
                  >{{ $t("Components.System.Department.Edit") }}</el-button
                >
                <el-button
                  v-if="buttonVisible.deleteButtonLine"
                  :style="{ color: 'white' }"
                  :icon="Delete"
                  :color="ButtonColor.Delete"
                  size="small"
                  bg
                  @click="handleDelete(scope.row)"
                  >{{ $t("Components.System.Department.Delete") }}</el-button
                >
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </li>
    </el-scrollbar>
    <li class="department-li-page"></li>
  </ul>
  <DepartmentModify
    v-if="dialogVisible"
    @getDepartmentAll="getDepartmentAll"
    @handleCloseDialog="handleCloseDialog"
    :editData="editData"
    :departmentDataAll="tableData"
    :addParentGuid="addParentGuid"
  />
</template>
<script setup>
import { ref, reactive, onMounted, watchEffect, markRaw } from "vue";
import { useI18n } from "vue-i18n";
import {
  RefreshLeft,
  Search,
  Edit,
  Plus,
  Delete,
  Fold,
  Expand,
  WarnTriangleFilled,
} from "@element-plus/icons-vue";
import { ButtonColor } from "@/consts/frame";
import {
  request_department_all,
  request_department_dataflag,
  request_department_delete,
} from "@/api/apis";
import DepartmentModify from "@/components/System/Department/DepartmentModify.vue";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";
import { useStore } from "@/store/index";
import { ElMessageBox } from "element-plus";
const store = useStore();

const { t } = useI18n();
const searchForm = reactive({
  inputSearch: "",
  dataflag: undefined,
});

const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  addButton: false,
  expandButton: false,
  addButtonLine: false,
  editButtonLine: false,
  deleteButtonLine: false,
  dataflag: false,
});
// 部门数据
const tableData = ref([]);
// 表格加载
const tableLoading = ref(false);
// 展开的行guid集合
const expandRowKeys = ref([]);
// 弹窗是否可见
const dialogVisible = ref(false);
// 是否展开
const isExpand = ref(false);
// 编辑的部门数据
const editData = ref({});
// 添加父级guid
const addParentGuid = ref("");

onMounted(async () => {
  await getDepartmentAll();
});

// 获取所有部门
const getDepartmentAll = async () => {
  const trans = {
    text: searchForm.inputSearch,
    dataflag: searchForm.dataflag,
  };
  tableLoading.value = true;
  const result = await request_department_all(
    trans,
    () => (tableLoading.value = false)
  );
  tableData.value = result;
};
// 查询事件
const queryClick = async () => {
  await getDepartmentAll();
};
// 重置事件
const resetClick = async () => {
  searchForm.inputSearch = "";
  searchForm.dataflag = undefined;
  await getDepartmentAll();
};
// 处理展开和折叠
const handleExpandFold = () => {
  const guids = [];
  tableData.value.forEach((item) => {
    recursionAllDepartment(item, guids);
  });
  if (isExpand.value) {
    expandRowKeys.value = [];
  } else {
    expandRowKeys.value = guids;
  }
  isExpand.value = !isExpand.value;
};
// 递归处理所有部门
const recursionAllDepartment = (department, guids) => {
  guids.push(department.guid);
  if (department.children && department.children.length > 0) {
    // 存在子数据
    department.children.forEach((item) => {
      guids.push(item.guid);
      recursionAllDepartment(item, guids);
    });
  }
};
// 处理有效性改变事件
const handleDataflagChange = async (flag, row) => {
  await request_department_dataflag(row.guid, true, () => {
    row.dataflag = !row.dataflag;
  });
  getDepartmentAll();
};
// 处理关闭事件
const handleCloseDialog = () => {
  dialogVisible.value = false;
  editData.value = {};
  addParentGuid.value = "";
};
const handleDelete = (row) => {
  ElMessageBox.confirm(
    t("Components.System.Department.ConfirmDeleteDepartment"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      await request_department_delete(row.guid, true);
      getDepartmentAll();
    })
    .catch(() => {});
};
// 处理编辑事件
const handleEdit = (row) => {
  editData.value = {
    guid: row.guid,
    name: row.name,
    sort: row.sort,
    parent_guid: row.parent_guid,
    principle: row.principle,
    principle_name: row.principle_name,
  };
  dialogVisible.value = true;
};
watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Department,
      MenuButtonCodes.System.Department.DepartmentSearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Department,
      MenuButtonCodes.System.Department.DepartmentReset
    );
    // 处理新增
    buttonVisible.addButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Department,
      MenuButtonCodes.System.Department.DepartmentAdd
    );
    // 处理展开
    buttonVisible.expandButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Department,
      MenuButtonCodes.System.Department.DepartmentExpand
    );
    // 处理新增单行
    buttonVisible.addButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Department,
      MenuButtonCodes.System.Department.DepartmentAddLine
    );
    // 处理编辑单行
    buttonVisible.editButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Department,
      MenuButtonCodes.System.Department.DepartmentEditLine
    );
    // 处理删除行
    buttonVisible.deleteButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Department,
      MenuButtonCodes.System.Department.DepartmentDeleteLine
    );
    // 处理有效性
    buttonVisible.dataflag = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Department,
      MenuButtonCodes.System.Department.DepartmentDataflag
    );
  }
});
</script>
<style>
.department-ul .el-scrollbar__view {
  height: calc(100% - 8px);
}
</style>
<style scoped lang="scss">
.department-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  .department-li-search {
  }
  .department-li-page {
    height: 8px;
  }
}
</style>
