<template>
  <ul class="ul-root">
    <li class="li-header">
      <div class="logo-div">
        <el-image :src="LOGO" fit="fill" />
      </div>
      <div class="header-content">
        <div class="marquee-root">
          <div
            v-html="announcement.content"
            v-if="announcement && announcement.guid && isShowAnnouncement"
            class="marquee-content"
            :style="{
              animationDuration: announcement.content.length * 0.2 + 5 + 's',
            }"
          ></div>
          <!-- <div v-else class="default-announcement">
            <el-icon>
              <i
                class="iconfont icon-announcement"
                :style="{ fontSize: '20px', color: 'red' }"
              ></i>
            </el-icon>
            <span> {{ defaultAnnouncement }}</span>
          </div> -->
        </div>
        <el-tooltip
          v-if="announcement && announcement.guid"
          class="box-item"
          effect="dark"
          :content="
            announcement && announcement.guid && isShowAnnouncement
              ? t('Components.Index.Header.TodayClose')
              : t('Components.Index.Header.Show')
          "
          placement="bottom"
        >
          <el-icon v-if="isShowAnnouncement"
            ><Close @click="handleHideAnnouncement"
          /></el-icon>
          <el-icon v-else><View @click="handleShowAnnouncement" /></el-icon>
        </el-tooltip>
      </div>

      <div :style="{ width: '200px' }">
        <Header />
      </div>
    </li>
    <li class="li-content">
      <el-container>
        <el-aside :width="menuWidth + 'px'">
          <Menu class="root-menu" ref="menuRef" />
        </el-aside>
        <el-main>
          <div class="div-menu-tag">
            <el-scrollbar>
              <el-space>
                <el-tag
                  @click.stop="handleTagClick(item)"
                  @close.stop="handleTagClose(item)"
                  v-for="(item, index) in cacheMenus"
                  :key="item.menu_path"
                  :closable="index === 0 ? false : true"
                  type="success"
                  :effect="item.menu_path === currentMenuTag ? 'dark' : 'plain'"
                  >{{ item.menu_cn_name }}</el-tag
                >
              </el-space>
            </el-scrollbar>
          </div>
          <el-icon class="icon-collapse" @click="handleMenuCollapse">
            <i v-if="!isCollapse" class="iconfont icon-zhedie1" />
            <i v-else class="iconfont icon-zhedie2" />
          </el-icon>
          <ul class="ul-right">
            <li class="li-main">
              <router-view />
            </li>
            <li class="li-footer"><Footer /></li>
          </ul>
        </el-main>
      </el-container>
    </li>
  </ul>
  <!-- 弹窗登录 -->
  <Login />
</template>
<script setup>
import Menu from "@/components/Index/Menu/MenuIndex.vue";
import Footer from "@/components/Index/Footer/FooterIndex.vue";
import Header from "@/components/Index/Header/HeaderIndex.vue";
// import Main from "@/components/Index/Main";
import Login from "@/components/Index/Login/LoginIndex.vue";
import {
  onActivated,
  ref,
  onDeactivated,
  shallowRef,
  onMounted,
  onBeforeUnmount,
} from "vue";
import { useStore } from "@/store/index";
import { useRouter } from "vue-router";
import { LocalStorageKey, IsLock, Const } from "@/consts/frame";
import LockScreen from "./LockScreen.vue";
import {
  request_user_baseinfo,
  request_user_menupermission,
  request_announcement_show,
} from "@/api/apis";
import { SystemParamCode } from "@/consts/systemParam";
import { useI18n } from "vue-i18n";
import LOGO from "@/assets/logo1.jpg";
import { Close, View } from "@element-plus/icons-vue";
import pubsub from "pubsub-js";
import PubsubMessage from "@/consts/pubsubMessage";

const { t } = useI18n();
const store = useStore();
const router = useRouter();
const isCollapse = ref(false);
// header red
const menuRef = ref();
// 最小宽度
const minWidth = 70;
// 最大宽度
const maxWidth = 200;
// 菜单div宽度，最大200px 折叠60px
const menuWidth = ref(maxWidth);
// 最近时间
const lastTime = ref();

const announcement = ref(undefined);
const isShowAnnouncement = ref(true);

const cacheMenus = ref([]);
const currentMenuTag = ref("");

onMounted(async () => {
  const lt = new Date().getTime();
  lastTime.value = lt;
  store.setLastOperateTime(new Date().getTime());
  window.document.onmousedown = () => {
    store.setLastOperateTime(new Date().getTime());
  };
  const intervalId = setInterval(() => {
    checkLockTime();
  }, Const.LockingIntervalTime * 1000);

  store.setLastOperateTimeId(intervalId);
  getAnnouncement();
  // 获取
  getUserBaseInfo();
  getUserMenuPermission();

  pubsub.subscribe(PubsubMessage.RouteChange, async (_, data) => {
    currentMenuTag.value = data.menu_path;
    // 需要更新下缓存的菜单
    const menuTags = localStorage.getItem(LocalStorageKey.MenuTag);
    if (menuTags) {
      const arr = menuTags.split("|");
      let newArr = [];
      if (!store.userMenus || store.userMenus.length === 0) {
        // 获取基础用户信息
        const result = await request_user_menupermission();
        store.setUserMenu(result);
      }
      if (store.userMenus && store.userMenus.length > 0) {
        arr.forEach((item) => {
          const findMenu = store.userMenus.find((item1) => item1.menu_path === item);
          if (findMenu) {
            newArr.push({
              menu_path: findMenu.menu_path,
              menu_cn_name: findMenu.menu_cn_name,
            });
          }
        });
      }
      cacheMenus.value = newArr;
    }
  });
});

const getAnnouncement = async () => {
  const result = await request_announcement_show();
  announcement.value = result;
  if (announcement.value && announcement.value.guid) {
    // 有需要展示的公告
    const data = localStorage.getItem(LocalStorageKey.MQ);
    if (data) {
      isShowAnnouncement.value = data != 1;
    } else {
      isShowAnnouncement.value = true;
    }
  }
};

// 处理菜单折叠
const handleMenuCollapse = () => {
  changeMenuCollapse(!isCollapse.value);
  isCollapse.value = !isCollapse.value;
};

// 处理菜单折叠，供子组件调用
const changeMenuCollapse = (status) => {
  menuRef.value.changeMenuCollapse(status);
  if (status) {
    // 需要折叠
    recursionReduceWidth(1);
  } else {
    recursionReduceWidth(2);
  }
};
// 递归宽度，type = 1:减少宽度；2：增加宽度
const recursionReduceWidth = (type) => {
  setTimeout(() => {
    if (type === 1) {
      if (menuWidth.value > minWidth) {
        menuWidth.value -= 5;
        recursionReduceWidth(type);
      }
    } else if (type === 2) {
      if (menuWidth.value < maxWidth) {
        menuWidth.value += 5;
        recursionReduceWidth(type);
      }
    }
  }, 1);
};
// 激活的时候执行
onActivated(() => {});
// 失活
onDeactivated(() => {
  if (store.lastOperateTimeIntervalId && store.lastOperateTimeIntervalId > 0) {
    // 如果存在定时器，则需要删除
    clearInterval(store.lastOperateTimeIntervalId);
    store.setLastOperateTimeId(undefined);
  }
  store.setLastOperateTime(undefined);
  window.document.onmousedown = null;
});
// 检查锁定时间
const checkLockTime = () => {
  const currentTime = new Date().getTime();
  const lastOperateTime = store.lastOperateTime;
  // 获取超时时间
  const lock_time =
    store.userInfo && store.userInfo.system_setting
      ? store.userInfo.system_setting.lock_time
      : null;
  const timeOut =
    lock_time && parseInt(lock_time) > 0 ? parseInt(lock_time) * 1000 * 60 : null; // 如果存在超时时间，则有，没有超时时间就是null
  lastTime.value = lastOperateTime;
  // 先判断是否需要进行超时锁频判断
  if (timeOut) {
    // 存在超时时间
    if (lastOperateTime) {
      // 如果存在缓存时间
      if (currentTime - lastOperateTime > timeOut) {
        // 超过时间了，则需要变成锁屏
        localStorage.setItem(LocalStorageKey.IsLock, IsLock.True); // 设置成锁屏
        router.push("/lock");
        store.setActivePage({ component: shallowRef(LockScreen) });
      }
    }
  }
};
// 用户基本下信息
const getUserBaseInfo = async () => {
  const result = await request_user_baseinfo();
  // 将用户数据缓存起来
  store.setUserInfo(result);
  localStorage.setItem(LocalStorageKey.UserInfo, result.user_info.name); // 将用户名信息存起来，因为vex在F5刷新后会丢失
  // 设置网站标题
  if (result.systemParam && result.systemParam.length > 0) {
    const findTitleParam = result.systemParam.find(
      (item) => item.code === SystemParamCode.Title
    );
    if (findTitleParam) {
      localStorage.setItem(LocalStorageKey.Title, findTitleParam.value);
      window.document.title = result.value;
    } else {
      window.document.title = t("Common.Customer");
    }
  }
};
// 获取用户菜单权限
const getUserMenuPermission = async () => {
  const result = await request_user_menupermission();
  store.setUserMenu(result);
};
// 今日关闭
const handleHideAnnouncement = async () => {
  if (announcement.value && announcement.value.guid) {
    localStorage.setItem(LocalStorageKey.MQ, 1);
    isShowAnnouncement.value = false;
  }
};
// 显示
const handleShowAnnouncement = () => {
  if (announcement.value && announcement.value.guid) {
    localStorage.setItem(LocalStorageKey.MQ, 0);
    isShowAnnouncement.value = true;
  }
};

onBeforeUnmount(() => {
  pubsub.unsubscribe(PubsubMessage.RouteChange);
});
// tag点击
const handleTagClick = (data) => {
  router.push({
    path: data.menu_path,
    // query: { progress: params.data.progress },
  });
};
// 关闭tag
const handleTagClose = (data) => {
  // 判断删除的是否是当前页，如果是，则跳转到首页
  const menuTags = localStorage.getItem(LocalStorageKey.MenuTag);
  let newArr = [];
  if (menuTags) {
    const arr = menuTags.split("|");
    arr.forEach((item) => {
      if (item !== data.menu_path) {
        newArr.push(item);
      }
    });
    if (newArr.length > 0) {
      const newStr = newArr.join("|");
      localStorage.setItem(LocalStorageKey.MenuTag, newStr);
    }
  }

  if (currentMenuTag.value === data.menu_path) { // 当前的菜单与操作菜单一致
    router.push({
      path: store.userMenus[0].menu_path,
      // query: { progress: params.data.progress },
    });
  } else { // 不一致
    //
    let newArr1 = [];
    if (store.userMenus && store.userMenus.length > 0) {
      newArr.forEach((item) => {
        const findMenu = store.userMenus.find((item1) => item1.menu_path === item);
        if (findMenu) {
          newArr1.push({
            menu_path: findMenu.menu_path,
            menu_cn_name: findMenu.menu_cn_name,
          });
        }
      });
      cacheMenus.value = newArr1;
    }
  }
};
</script>
<style scoped lang="scss">
.ul-root {
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  .li-header {
    height: 40px;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    .logo-div {
      height: 40px;
      width: 200px;
      .el-image {
        height: 40px;
        width: 120px;
      }
    }
    @keyframes marqueeAnim {
      0% {
        transform: translateX(100vw);
      }
      100% {
        transform: translateX(-100%);
      }
    }

    .header-content {
      width: calc(100% - 380px);
      display: flex;
      align-items: center;
    }
    .marquee-root {
      flex: 1;
      text-align: left;
      overflow: hidden;
      line-height: 40px;
      margin: 0 18px;
      .marquee-content {
        white-space: nowrap;
        display: inline-block;
        color: chocolate;
        font-size: 14px;
        line-height: 40px;
        animation: marqueeAnim 5s linear 0s infinite;
      }
      .default-announcement {
        display: flex;
        color: chocolate;
        justify-content: center;
        font-size: 14px;
        align-items: center;
      }
    }
  }
  .li-content {
    height: calc(100% - 40px);
    .el-container {
      width: 100%;
      height: 100%;
      .el-main {
        overflow: hidden;
        padding: 0 18px;
        background-color: rgb(245, 245, 245);
        position: relative;
        .el-icon {
        }
        .div-menu-tag {
          display: flex;
          align-items: center;
          height: 38px;
          .el-space {
            height: 38px;
            .el-tag {
              &:hover {
                cursor: pointer;
                background-color: #67c23a !important;
                color: white !important;
              }
            }
          }
        }
        .ul-right {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          width: 100%;
          height: 100%;
          flex-direction: column;
          .li-main {
            width: 100%;
            height: calc(100% - 56px);
          }
          .li-footer {
            height: 20px;
            font-size: 0.6em;
            justify-content: center;
            align-items: center;
            display: flex;
          }
        }
        .icon-collapse {
          font-size: 12px;
          position: absolute;
          top: 2px;
          left: 1px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.el-container {
  .el-aside {
    height: 100%;
    overflow: hidden;
  }
  .el-main {
    overflow: hidden;
    padding: 0 12px;
    background-color: rgb(245, 245, 245);
  }
}
</style>

<style lang="scss">
/* 所有查询的公共样式 */
.hm-search {
  // height: 60px;
  background-color: white;
  box-sizing: border-box;
  margin: 8px 0 0 0px !important;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  // flex-shrink: 0;
  .el-form-item {
    // margin-bottom: 0;
    // margin: 10px 10px 10px 20px !important;
    margin-right: 36px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}
.hm-operate {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  box-sizing: border-box;
  margin: 8px 0 0 0 !important;
  border-radius: 6px;
  display: flex;
  min-height: 56px;
  align-items: center;
  padding: 8px;
  // flex-shrink: 0;

  .el-button {
    margin: 4px 1em 4px 0 !important;
  }
}
.hm-page {
  height: 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  .el-pagination {
    float: right;
  }
}
.hm-table {
  flex: 1;
  margin: 8px 0 !important;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: white;
  height: 100%;
  .el-table {
    height: 100%;
  }
}
</style>
