<template>
  <ul class="projectandtaskprogress-ul" v-loading="fullLoading">
    <li class="count-li">
      <ul class="count-root-ul">
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left all">
              <el-icon>
                <i class="iconfont icon-all"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">{{ computedCount(undefined, true) }}</div>
              <div class="bottom-div">全部</div>
            </li>
          </ul>
        </li>
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left tobeallowcate">
              <el-icon>
                <i class="iconfont icon-tobeallowcate"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">
                {{ computedCount(ProjectProgress.ToBeAllocated) }}
              </div>
              <div class="bottom-div">待分配</div>
            </li>
          </ul>
        </li>
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left ready">
              <el-icon>
                <i class="iconfont icon-ready"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">
                {{ computedCount(ProjectProgress.Ready) }}
              </div>
              <div class="bottom-div">准备中</div>
            </li>
          </ul>
        </li>
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left inprogress">
              <el-icon>
                <i class="iconfont icon-inprogress"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">
                {{ computedCount(ProjectProgress.InProgress) }}
              </div>
              <div class="bottom-div">进行中</div>
            </li>
          </ul>
        </li>
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left complte">
              <el-icon>
                <i class="iconfont icon-complete"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">
                {{ computedCount(ProjectProgress.Complete) }}
              </div>
              <div class="bottom-div">已完成</div>
            </li>
          </ul>
        </li>
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left cancel">
              <el-icon>
                <i class="iconfont icon-cancel"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">
                {{ computedCount(ProjectProgress.Cancel) }}
              </div>
              <div class="bottom-div">已取消</div>
            </li>
          </ul>
        </li>
      </ul>
    </li>
    <li class="hm-search">
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item :label="$t('Components.Progress.Project.ProjectNumber')">
          <el-input
            v-model="searchForm.project_number"
            :placeholder="
              $t('Components.Progress.Project.PleaseInputProjectNumber')
            "
            clearable
          />
        </el-form-item>
        <el-form-item
          :label="$t('Components.Progress.ProjectAndTask.Progress')"
        >
          <el-select
            v-model="searchForm.progress"
            :placeholder="
              $t('Components.Progress.ProjectAndTask.PleaseSelectProgress')
            "
            clearable
          >
            <el-option
              v-for="item in projectProgresses"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('Components.Progress.ProjectAndTask.ProjectManager')"
        >
          <el-select
            v-model="searchForm.project_manager"
            :placeholder="
              $t('Components.Progress.ProjectAndTask.PleaseSelectProjectManager')
            "
            clearable
          >
            <el-option
              v-for="item in users"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="buttonVisible.searchButton"
            type="primary"
            @click="queryClick"
            :icon="Search"
            >{{ $t("Components.Progress.ProjectAndTask.Search") }}</el-button
          >
          <el-button
            v-if="buttonVisible.resetButton"
            @click="resetClick"
            :icon="RefreshLeft"
            >{{ $t("Components.Progress.ProjectAndTask.Reset") }}</el-button
          >
        </el-form-item>
      </el-form>
    </li>
    <li class="hm-operate">
      <!-- 操作部分 -->
      <el-button
        v-if="buttonVisible.exportButton"
        :style="{ color: 'white' }"
        :icon="Download"
        :color="ButtonColor.Export"
        @click="handleExport"
        >{{ $t("Components.Progress.ProjectAndTask.Export") }}</el-button
      >
    </li>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          row-key="guid"
          :header-cell-style="{ textAlign: 'center' }"
          @sort-change="handleSortChange"
        >
          <el-table-column
            prop="project_number"
            :label="$t('Components.Progress.ProjectAndTask.ProjectNumber')"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="progress"
            :width="120"
            align="center"
            :label="$t('Components.Progress.ProjectAndTask.Progress')"
          >
            <template #default="scope">
              <el-text
                v-if="scope.row.progress === ProjectProgress.ToBeAllocated"
                type="info"
                >{{
                  t("Components.Project.ProjectList.ToBeAllocated")
                }}</el-text
              >
              <el-text
                v-if="scope.row.progress === ProjectProgress.Ready"
                type="info"
                >{{ t("Components.Project.ProjectList.Ready") }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectProgress.InProgress"
                >{{ t("Components.Project.ProjectList.InProgress") }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectProgress.Complete"
                >{{ t("Components.Project.ProjectList.Complete") }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectProgress.Cancel"
                >{{ t("Components.Project.ProjectList.Cancel1") }}</el-text
              >
            </template>
          </el-table-column>
          <el-table-column
              align="center"
            prop="project_manager_name"
            :label="$t('Components.Progress.ProjectAndTask.ProjectManager')"
          >
          </el-table-column>
          <el-table-column
            :label="$t('Components.Progress.ProjectAndTask.TaskStatistic')"
          >
            <el-table-column
              align="center"
              :label="t('Components.Project.ProjectTask.ToBeAllocated')"
              width="100"
            >
              <template #default="scope">
                <el-text
                  v-if="
                    scope.row.tasks.find(
                      (item) =>
                        item.progress === ProjectTaskProgress.ToBeAllocated
                    )
                  "
                >
                  {{
                    scope.row.tasks.find(
                      (item) =>
                        item.progress === ProjectTaskProgress.ToBeAllocated
                    ).count
                  }}
                </el-text>
                <el-text v-else>0</el-text>
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              :label="t('Components.Project.ProjectTask.Ready')"
              width="100"
            >
              <template #default="scope">
                <el-text
                  :style="{ fontSize: '1.2em', fontWeight: '800' }"
                  v-if="
                    scope.row.tasks.find(
                      (item) => item.progress === ProjectTaskProgress.Ready
                    )
                  "
                >
                  {{
                    scope.row.tasks.find(
                      (item) => item.progress === ProjectTaskProgress.Ready
                    ).count
                  }}
                </el-text>
                <el-text v-else>0</el-text>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              align="center"
              :label="t('Components.Project.ProjectTask.AuditImplementation')"
              width="100"
            >
              <template #default="scope">
                <el-text
                  :style="{ fontSize: '1.2em', fontWeight: '800' }"
                  v-if="
                    scope.row.tasks.find(
                      (item) =>
                        item.progress ===
                        ProjectTaskProgress.AuditImplementation
                    )
                  "
                >
                  {{
                    scope.row.tasks.find(
                      (item) =>
                        item.progress ===
                        ProjectTaskProgress.AuditImplementation
                    ).count
                  }}
                </el-text>
                <el-text v-else>0</el-text>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              align="center"
              :label="t('Components.Project.ProjectTask.ReportWriting')"
              width="100"
            >
              <template #default="scope">
                <el-text
                  :style="{ fontSize: '1.2em', fontWeight: '800' }"
                  v-if="
                    scope.row.tasks.find(
                      (item) =>
                        item.progress === ProjectTaskProgress.ReportWriting
                    )
                  "
                >
                  {{
                    scope.row.tasks.find(
                      (item) =>
                        item.progress === ProjectTaskProgress.ReportWriting
                    ).count
                  }}
                </el-text>
                <el-text v-else>0</el-text>
              </template>
            </el-table-column>

            <el-table-column
              prop="name"
              align="center"
              :label="t('Components.Project.ProjectTask.SubmitForReview')"
              width="100"
            >
              <template #default="scope">
                <el-text
                  :style="{ fontSize: '1.2em', fontWeight: '800' }"
                  v-if="
                    scope.row.tasks.find(
                      (item) =>
                        item.progress === ProjectTaskProgress.SubmitForReview
                    )
                  "
                >
                  {{
                    scope.row.tasks.find(
                      (item) =>
                        item.progress === ProjectTaskProgress.SubmitForReview
                    ).count
                  }}
                </el-text>
                <el-text v-else>0</el-text>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              align="center"
              :label="t('Components.Project.ProjectTask.UnderReview')"
              width="100"
            >
              <template #default="scope">
                <el-text
                  :style="{ fontSize: '1.2em', fontWeight: '800' }"
                  v-if="
                    scope.row.tasks.find(
                      (item) =>
                        item.progress === ProjectTaskProgress.UnderReview
                    )
                  "
                >
                  {{
                    scope.row.tasks.find(
                      (item) =>
                        item.progress === ProjectTaskProgress.UnderReview
                    ).count
                  }}
                </el-text>
                <el-text v-else>0</el-text>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              align="center"
              :label="t('Components.Project.ProjectTask.Rewrite')"
              width="100"
            >
              <template #default="scope">
                <el-text
                  :style="{ fontSize: '1.2em', fontWeight: '800' }"
                  v-if="
                    scope.row.tasks.find(
                      (item) => item.progress === ProjectTaskProgress.Rewrite
                    )
                  "
                >
                  {{
                    scope.row.tasks.find(
                      (item) => item.progress === ProjectTaskProgress.Rewrite
                    ).count
                  }}
                </el-text>
                <el-text v-else>0</el-text>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              align="center"
              :label="t('Components.Project.ProjectTask.ReviewedAndApproved')"
              width="100"
            >
              <template #default="scope">
                <el-text
                  :style="{ fontSize: '1.2em', fontWeight: '800' }"
                  v-if="
                    scope.row.tasks.find(
                      (item) =>
                        item.progress ===
                        ProjectTaskProgress.ReviewedAndApproved
                    )
                  "
                >
                  {{
                    scope.row.tasks.find(
                      (item) =>
                        item.progress ===
                        ProjectTaskProgress.ReviewedAndApproved
                    ).count
                  }}
                </el-text>
                <el-text v-else>0</el-text>
              </template>
            </el-table-column>
            
            <el-table-column
              prop="name"
              align="center"
              :label="t('Components.Project.ProjectTask.CAPAFinalize')"
              width="100"
            >
              <template #default="scope">
                <el-text
                  :style="{ fontSize: '1.2em', fontWeight: '800' }"
                  v-if="
                    scope.row.tasks.find(
                      (item) => item.progress === ProjectTaskProgress.CAPAFinalize
                    )
                  "
                >
                  {{
                    scope.row.tasks.find(
                      (item) => item.progress === ProjectTaskProgress.CAPAFinalize
                    ).count
                  }}
                </el-text>
                <el-text v-else>0</el-text>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              align="center"
              :label="t('Components.Project.ProjectTask.Complete')"
              width="100"
            >
              <template #default="scope">
                <el-text
                  :style="{ fontSize: '1.2em', fontWeight: '800' }"
                  v-if="
                    scope.row.tasks.find(
                      (item) => item.progress === ProjectTaskProgress.Complete
                    )
                  "
                >
                  {{
                    scope.row.tasks.find(
                      (item) => item.progress === ProjectTaskProgress.Complete
                    ).count
                  }}
                </el-text>
                <el-text v-else>0</el-text>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              align="center"
              :label="t('Components.Project.ProjectTask.Cancel')"
              width="100"
            >
              <template #default="scope">
                <el-text
                  :style="{ fontSize: '1.2em', fontWeight: '800' }"
                  v-if="
                    scope.row.tasks.find(
                      (item) => item.progress === ProjectTaskProgress.Cancel
                    )
                  "
                >
                  {{
                    scope.row.tasks.find(
                      (item) => item.progress === ProjectTaskProgress.Cancel
                    ).count
                  }}
                </el-text>
                <el-text v-else>0</el-text>
              </template>
            </el-table-column>
            
          </el-table-column>
        </el-table>
      </li>
    </el-scrollbar>

    <li class="hm-page">
      <el-pagination
        small
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="AllPageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </li>
  </ul>
</template>
<script setup>
import { reactive, ref, onMounted, watchEffect, computed } from "vue";
import { RefreshLeft, Search, Download } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import {
  AllPageSize,
  ButtonColor,
  DefaultPageSize,
} from "@/consts/frame";
import {
  request_projectprogress_count,
  request_projectandtaskprogress_page,
  request_projectandtaskprogress_export,
  request_user_page
} from "@/api/apis";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";
import { useStore } from "@/store/index";
import { ProjectProgress, ProjectTaskProgress } from "@/consts/enum";

const store = useStore();
const { t } = useI18n();
const searchForm = reactive({
  project_number: "",
  progress: undefined,
  project_manager: undefined,
});
const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  exportButton: false,
});
// 当前页码
const currentPage = ref(1);
// 页码大小
const pageSize = ref(DefaultPageSize);
// 表格数据
const tableData = ref([]);
// 表格加载
const tableLoading = ref(false);
// 总数量
const total = ref(0);
// 全屏加载
const fullLoading = ref(false);
// 
const projectNumberSort = ref(undefined)

const countData = ref([]);

// 项目进度
const projectProgresses = ref([
  {
    label: t("Components.Project.ProjectList.ToBeAllocated"),
    value: ProjectProgress.ToBeAllocated,
  },
  {
    label: t("Components.Project.ProjectList.Ready"),
    value: ProjectProgress.Ready,
  },
  {
    label: t("Components.Project.ProjectList.InProgress"),
    value: ProjectProgress.InProgress,
  },
  {
    label: t("Components.Project.ProjectList.Complete"),
    value: ProjectProgress.Complete,
  },
  {
    label: t("Components.Project.ProjectList.Cancel1"),
    value: ProjectProgress.Cancel,
  },
]);
const users = ref([])
// 计算数量
const computedCount = computed(() => (progress, isAll = false) => {
  if (isAll) {
    if (countData.value) {
      let count = 0;
      countData.value.forEach((item) => {
        count += item.count;
      });
      return count;
    }
  } else {
    if (countData.value) {
      const findData = countData.value.find(
        (item) => item.progress === progress
      );
      if (findData) {
        return findData.count;
      }
    }
  }

  return 0;
});
onMounted(async () => {
  getProgressProjectPage();
  getProgressProjectCount();
  getUserAll()
});
// 分页查询
const getProgressProjectPage = async () => {
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    project_number: searchForm.project_number,
    progress: searchForm.progress,
    project_manager: searchForm.project_manager,
    ProjectNumberSort: projectNumberSort.value
  };
  tableLoading.value = true;
  const result = await request_projectandtaskprogress_page(
    trans,
    () => (tableLoading.value = false)
  );
  total.value = result.total;
  tableData.value = result.rows;
};
// 获取进度数量
const getProgressProjectCount = async () => {
  const result = await request_projectprogress_count();
  countData.value = result;
};

// 查询事件
const queryClick = () => {
  getProgressProjectPage();
};
// 重置事件
const resetClick = () => {
  searchForm.progress = undefined;
  searchForm.project_number = "";
  searchForm.project_manager = undefined
  getProgressProjectPage();
};
// 处理页码大小改变事件
const handleSizeChange = (size) => {
  pageSize.value = size;
  getProgressProjectPage();
};
// 处理当前页改变事件
const handleCurrentChange = (index) => {
  currentPage.value = index;
  getProgressProjectPage();
};
// 导出
const handleExport = async () => {
  fullLoading.value = true;
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    project_number: searchForm.project_number,
    progress: searchForm.progress,
    project_manager: searchForm.project_manager,
    ProjectNumberSort: projectNumberSort.value
  };
  const result = await request_projectandtaskprogress_export(
    trans,
    true,
    () => (fullLoading.value = false)
  );
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = result;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
};
// 获取所有用户
const getUserAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
    is_system: false,
  };
  const result = await request_user_page(trans);
  if (result.total > 0) {
    users.value = result.rows;
  } else {
    users.value = [];
  }
};
// 处理排序改变事件
const handleSortChange = async ({ prop, order }) => {
    projectNumberSort.value = undefined;
  if (prop === "project_number") {
    if (order === "ascending") {
      projectNumberSort.value = false;
    } else if (order === "descending") {
      projectNumberSort.value = true;
    } else {
      projectNumberSort.value = undefined;
    }
  } 
  await getProgressProjectPage();
};
watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Progress.ProjectAndTaskProgress,
      MenuButtonCodes.Progress.ProjectAndTaskProgress
        .ProjectAndTaskProgressSearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Progress.ProjectAndTaskProgress,
      MenuButtonCodes.Progress.ProjectAndTaskProgress
        .ProjectAndTaskProgressReset
    );
    // 处理导出
    buttonVisible.exportButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Progress.ProjectAndTaskProgress,
      MenuButtonCodes.Progress.ProjectAndTaskProgress
        .ProjectAndTaskProgressExport
    );
  }
});
</script>
<style>
.projectandtaskprogress-ul .el-scrollbar__view {
  height: calc(100% - 8px);
}
</style>
<style scoped lang="scss">
.projectandtaskprogress-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  .count-li {
    .count-root-ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      .li-single {
        border-radius: 12px;
        width: 200px;
        height: 80px;
        padding: 8px 28px;
        .single-ul {
          border-radius: 12px;
          display: flex;
          list-style: none;
          margin: 0;
          padding: 0;
          height: 80px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          // 全部
          .all {
            background-color: #519be0;
          }
          // 待分配
          .tobeallowcate {
            background-color: #86bc53;
          }
          // 准备中
          .ready {
            background-color: #90c4ba;
          }
          // 进行中
          .inprogress {
            background-color: #b066d8;
          }
          // 完成
          .complte {
            background-color: #21ba6d;
          }
          // 取消
          .cancel {
            background-color: #f3730e;
          }
          .li-left {
            border-radius: 12px 0 0 12px;
            width: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            .iconfont {
              font-size: 48px;
              color: white;
            }
          }
          .li-right {
            border-radius: 0 12px 12px 0;
            padding: 8px 0;
            width: 140px;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: white;
            .top-div {
              font: 600;
              flex: 1;
              font-size: 2em;
            }
          }
        }
      }
    }
  }
}
</style>
