<template>
  <el-dialog
    v-model="loginDialogVisible"
    :title="$t('Components.Index.Login.Login')"
    width="30%"
    center
    :close-on-click-modal="false"
    :before-close="handleDialogBeforeClose"
  >
    <el-form :model="form" :rules="rules" ref="formRef">
      <el-form-item :label="$t('Components.Index.Login.Account')" prop="account">
        <el-input v-model="form.account" />
      </el-form-item>
      <el-form-item :label="$t('Components.Index.Login.Password')" prop="password">
        <el-input type="password" v-model="form.password" show-password />
      </el-form-item>
      <!-- <el-form-item label="">
        <el-button type="primary" :style="{width: '100%'}">登 录</el-button>
      </el-form-item> -->
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="loginDialogVisible = false">{{
          $t("Common.PopoverBottom.Cancel")
        }}</el-button>
        <el-button :loading="buttonLoading" type="primary" @click="handleLogin">
          {{ $t("Components.Index.Login.Login") }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, onMounted,onBeforeUnmount } from "vue";
import pubsub from "pubsub-js";
import PubsubMessage from "@/consts/pubsubMessage";
import { useI18n } from "vue-i18n";
import { ElMessage } from "element-plus";
import { ApiStatus } from "@/consts/enum";
import { request_login } from "@/api/apis";
import { LocalStorageKey, IsLock } from "@/consts/frame";
import md5 from "js-md5";
import DeviceDetector from "device-detector-js";

const { t } = useI18n();
// 登录弹窗是否可见
const loginDialogVisible = ref(false);
// form ref
const form = reactive({
  account: "",
  password: "",
});
// 登录按钮加载
const buttonLoading = ref(false);

const formRef = ref();
// 挂载事件
onMounted(() => {
  pubsub.subscribe(PubsubMessage.LoginDialog, (_, data) => {
    if (!loginDialogVisible.value) {
      if (data) {
        if (data.status === ApiStatus.Expired) {
          let str = t("Common.Error.LoginExpired");
          if (data && data.response && data.response.data) {
            str += " | " + data.response.data;
          }
          ElMessage.warning(str);
        } else if (data.status === ApiStatus.RemoteLogin) {
          let str = t("Common.Error.RemoteLogin");
          if (data && data.response && data.response.data) {
            str += " | " + data.response.data;
          }
          ElMessage.warning(str);
        } else if (data.status === ApiStatus.Permission) {
          let str = t("Common.Error.NoPermission");
          if (data && data.response && data.response.data) {
            str += " | " + data.response.data;
          }
          ElMessage.warning(str);
        } else if (data.status === ApiStatus.NoCode) {
          let str = t("Common.Error.Support");
          if (data && data.response && data.response.data) {
            str += " | " + data.response.data;
          }
          ElMessage.warning(str);
        }
      }
      loginDialogVisible.value = true;
    }
  });
});
// 校验规则
const rules = reactive({
  account: [
    {
      required: true,
      message: t("Components.Index.Login.InputAccount"),
      trigger: "change",
    },
  ],
  password: [
    {
      required: true,
      message: t("Components.Index.Login.InputPassword"),
      trigger: "change",
    },
    {
      min: 6,
      message: t("Components.Index.Login.PasswordMin6"),
      trigger: "change",
    },
  ],
});
// 处理弹窗关闭前
const handleDialogBeforeClose = () => {
  form.password = "";
  loginDialogVisible.value = false;
};
// 处理登录事件
const handleLogin = async () => {
  if (!formRef.value) return;
  await formRef.value.validate(async (valid) => {
    if (valid) {
      var userAgent = navigator.userAgent.toLowerCase();
      const deviceDetector = new DeviceDetector();
      const device = deviceDetector.parse(userAgent);

      buttonLoading.value = true;
      const trans = {
        account: form.account,
        password: form.password,
        os: device.os.name + " " + device.os.version,
        browser: device.client.name + " " + device.client.version,
      };
      const result = await request_login(trans, () => (buttonLoading.value = false));
      loginDialogVisible.value = false;
      localStorage.setItem(LocalStorageKey.IsLock, md5(IsLock.False));
      localStorage.setItem(LocalStorageKey.Token, result);
      localStorage.setItem(LocalStorageKey.Password, md5(form.password));
    }
  });
};
onBeforeUnmount(() => {
  pubsub.unsubscribe(PubsubMessage.LoginDialog);
});
</script>
<style scoped lang="scss">
.el-form {
  padding: 0 50px;
}
</style>
