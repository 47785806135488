<template>
  <Dialog
    width="40%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form :model="form" label-width="80px" :rules="rules" ref="formRef">
      <el-form-item
        :label="$t('Components.System.Dictionary.Item.Name')"
        prop="name"
      >
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item
        :label="$t('Components.System.Dictionary.Item.Value')"
        prop="value"
      >
        <el-input 
              type="textarea"
               v-model="form.value" />
      </el-form-item>
      <el-form-item
        :label="$t('Components.System.Dictionary.Item.Sort')"
        prop="sort"
      >
        <el-input-number :style="{ width: '100%' }" v-model="form.sort" />
      </el-form-item>
      <el-form-item
        :label="$t('Components.System.Dictionary.Item.EchoStyle')"
        prop="echo_style"
      >
        <el-select
          :style="{ width: '100%' }"
          v-model="form.echo_style"
          :placeholder="$t('Components.System.Dictionary.Item.Choose')"
        >
          <el-option
            v-for="item in echoStyles"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('Components.System.Dictionary.Item.Remark')"
        prop="remark"
      >
        <el-input v-model="form.remark" type="textarea" rows="5" :placeholder="t('Components.System.Dictionary.Item.PleaseInputRemark')" />
      </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup>
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import {
  defineProps,
  ref,
  reactive,
  onMounted,
  markRaw,
  defineEmits,
} from "vue";
import { ElMessageBox } from "element-plus";
import { WarnTriangleFilled } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import {
  request_dictionaryitem_add,
  request_dictionaryitem_modify,
} from "@/api/apis";
import { DictionaryItemEchoStyle } from "@/consts/enum";

const { t } = useI18n();
const emits = defineEmits(["getDictionaryItem", "handleCloseDialog"]);
const props = defineProps({
  editData: {
    type: Object,
    default: () => {},
  },
  selectDictionaryGuid: {
    type: String,
    default: () => "",
  },
});
// 标题
const dialogTitle = ref("");

const form = reactive({
  name: props.editData.name,
  // code: props.editData.code,
  value: props.editData.value,
  sort: props.editData.sort || 0,
  remark: props.editData.remark,
  echo_style: props.editData.echo_style || DictionaryItemEchoStyle.Default,
  guid: props.editData.guid,
});
const formRef = ref();
// 回显样式数组
const echoStyles = ref([
  {
    label: t("Components.System.Dictionary.Item.Default"),
    value: DictionaryItemEchoStyle.Default,
  },
  {
    label: t("Components.System.Dictionary.Item.Primary"),
    value: DictionaryItemEchoStyle.Primary,
  },
  {
    label: t("Components.System.Dictionary.Item.Success"),
    value: DictionaryItemEchoStyle.Success,
  },
  {
    label: t("Components.System.Dictionary.Item.Info"),
    value: DictionaryItemEchoStyle.Info,
  },
  {
    label: t("Components.System.Dictionary.Item.Warning"),
    value: DictionaryItemEchoStyle.Warning,
  },
  {
    label: t("Components.System.Dictionary.Item.Danger"),
    value: DictionaryItemEchoStyle.Danger,
  },
]);
// 表单校验规则
const rules = ref({
  name: [
    {
      required: true,
      message: t(
        "Components.System.Dictionary.Item.ValidateDicItemNameRequired"
      ),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.Dictionary.Item.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  value: [
    {
      required: true,
      message: t(
        "Components.System.Dictionary.Item.ValidateDictionaryItemValueRequired"
      ),
      trigger: "blur",
    },
  ],
  sort: [
    {
      required: true,
      message: t(
        "Components.System.Dictionary.Item.ValidateDictionaryItemSortRequired"
      ),
      trigger: "blur",
    },
  ],
});
onMounted(() => {
  if (props.editData && props.editData.guid) {
    // 代表修改
    dialogTitle.value = t(
      "Components.System.Dictionary.Item.DialogDicItemTitleMaintance"
    );
  } else {
    dialogTitle.value = t(
      "Components.System.Dictionary.Item.DialogDicItemTitleAdd"
    );
  }
});

// 处理提交事件
const handleCommit = async () => {
  // 校验
  if (!formRef.value) return;
  await formRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(
        t("Components.System.Dictionary.Item.ConfirmSaveDicItem"),
        t("Common.ElMessageBox.OperateConfirm"),
        {
          confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
          cancelButtonText: t("Common.ElMessageBox.CancelText"),
          type: "warning",
          icon: markRaw(WarnTriangleFilled),
        }
      )
        .then(async () => {
          await saveDictionary();
        })
        .catch(() => {});
    }
  });
  return;
};
// 保存字典项数据
const saveDictionary = async () => {
  const trans = {
    name: form.name,
    // code: form.code,
    sort: form.sort,
    value: form.value,
    remark: form.remark,
    echo_style: form.echo_style,
    dic_guid: props.selectDictionaryGuid,
  };
  if (props.editData && props.editData.guid) {
    // 修改
    await request_dictionaryitem_modify(props.editData.guid, trans);
  } else {
    // 新增
    await request_dictionaryitem_add(trans);
  }
  emits("getDictionaryItem");
  emits("handleCloseDialog");
};
// 关闭
const handleCancle = () => {
  emits("handleCloseDialog");
};
</script>
<style scoped lang="scss">
.input-principle {
  &:hover {
    cursor: pointer;
  }
}
</style>
