<template>
  <div class="main-div">
    <div class="div-choose-user" v-if="isAdmin || buttonVisible.chooseUserSelect">
      <el-select
        v-model="chooseUser"
        :placeholder="t('Components.Index.Home.PleaseSelectUser')"
        @change="handleSelectChange"
        clearable
      >
        <el-option
          v-for="item in users"
          :key="item.guid"
          :label="item.name"
          :value="item.guid"
        ></el-option>
      </el-select>
    </div>
    <el-scrollbar>
      <div class="div-1">
        <ul class="ul-1-ul">
          <li class="li-left">
            <div class="div-top-statistic">
              <div class="div-statistic">
                <ul class="ul-single">
                  <li class="ul-single-li" @click="handleClick(1)">
                    <div class="div-statistic">
                      <ul class="statistic-ul">
                        <li class="icon-li icon-li-project">
                          <el-icon>
                            <i class="iconfont icon-all"></i>
                          </el-icon>
                        </li>
                        <li class="content-li">
                          <div class="div-count">{{ myProjectCount }}</div>
                          <div class="div-title">
                            {{ t("Components.Index.Home.MyProject") }}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li class="ul-single-li" @click="handleClick(2)">
                    <div class="div-statistic">
                      <ul class="statistic-ul">
                        <li class="icon-li icon-li-projecttask">
                          <el-icon>
                            <i class="iconfont icon-task"></i>
                          </el-icon>
                        </li>
                        <li class="content-li">
                          <div class="div-count">{{ myProjectTaskCount }}</div>
                          <div class="div-title">
                            {{ t("Components.Index.Home.MyProjectTask") }}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li class="ul-single-li" @click="handleClick(3)">
                    <div class="div-statistic">
                      <ul class="statistic-ul">
                        <li class="icon-li icon-li-waitmereview">
                          <el-icon>
                            <i class="iconfont icon-mine-wait-review"></i>
                          </el-icon>
                        </li>
                        <li class="content-li">
                          <div class="div-count">
                            {{
                              waitMyReviewCount +
                              myCommitReviewCount +
                              waitMeCollbrationCount
                            }}
                          </div>
                          <div class="div-title">
                            {{ t("Components.Index.Home.MyReview") }}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li class="ul-single-li" @click="handleClick(5)">
                    <div class="div-statistic">
                      <ul class="statistic-ul">
                        <li class="icon-li icon-li-hyperwindow">
                          <el-icon>
                            <i class="iconfont icon-task"></i>
                          </el-icon>
                        </li>
                        <li class="content-li">
                          <div class="div-count">{{ progressStatistic && progressStatistic.hyper_window_count ?( progressStatistic.hyper_window_count.report_count + progressStatistic.hyper_window_count.capa_count) :  0 }}</div>
                          <div class="div-title">
                            {{ t("Components.Index.Home.TotalHyperWindow") }}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  
                </ul>
              </div>
            </div>
            <div class="div-chart-project-task">
              <ul class="chart-ul">
                <li class="li-1">
                  <div
                    v-loading="progressLoading"
                    class="div-chart"
                    id="projectStatistic"
                  ></div>
                </li>
                <li class="li-2">
                  <div
                    v-loading="progressLoading"
                    class="div-chart"
                    id="projectTaskStatistic"
                  ></div>
                </li>
              </ul>
            </div>
          </li>
          <li class="li-right">
            <div class="calendar-type-div">
              <el-radio-group v-model="calenderType">
                <el-radio-button :label="1">{{
                  t("Components.Index.Home.AuditCalendar")
                }}</el-radio-button>
                <el-radio-button :label="2">{{
                  t("Components.Index.Home.ReportCalendar")
                }}</el-radio-button>
              </el-radio-group>
            </div>
            <el-calendar v-model="calendarData" v-loading="calendarLoading">
              <template #date-cell="{ data }">
                <div
                  :class="[isWeek(data.date) ? 'rest' : '']"
                  class="single-calendar-cell"
                >
                  <el-badge
                    :value="computedCalendar(data.day)"
                    class="item"
                    @click="handleDateClick(data)"
                  >
                    <div
                      :class="[
                        'circle-div',
                        computedCalendar(data.day) ? 'circle-div-have' : '',
                      ]"
                    >
                      <span>{{ data.day.split("-")[2] }}</span>
                    </div>
                  </el-badge>
                </div>
              </template>
            </el-calendar>
          </li>
        </ul>
      </div>

      <div class="div-1">
        <ul class="ul-1-ul">
          <li class="li-left">
            <div class="div-chart-project-task">
              <ul class="chart-ul">
                <li class="li-1">
                  <div
                    v-loading="progressLoading"
                    class="div-chart"
                    id="myCommitReview"
                  ></div>
                </li>
                <li class="li-2">
                  <div
                    v-loading="progressLoading"
                    class="div-chart"
                    id="hyperWindow"
                  ></div>
                </li>
              </ul>
            </div>
          </li>
          <li class="li-right announcement">
            <div class="announcement-div">
              <div class="title">系统通知</div>
              <ul class="calendar-ul">
                <el-scrollbar>
                  <li
                    class="calendar-ul-li"
                    v-for="item in announcementData"
                    :key="item.guid"
                    @click="handleAnnouncementClick(item)"
                  >
                    <span class="span-title">{{ item.title }}</span>
                    <div class="div-time">
                      <el-text>{{ item.createtime }}</el-text>
                    </div>
                  </li>
                </el-scrollbar>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </el-scrollbar>
  </div>
  <AnnouncementDetail
    v-if="announcementDialog"
    @handleCloseDialog="handleCloseDialog"
    :announcementData="currentAnnouncement"
  />
  <CalendarDateDetail
    v-if="calendarDateDialog"
    @handleCloseDialog="handleCloseCalendarDateDialog"
    :currentDate="currentDate"
    :calenderType="calenderType"
  />
</template>
<script setup>
import { onMounted, ref, onBeforeUnmount, computed, watchEffect, reactive } from "vue";
import * as echarts from "echarts";
import {
  request_home_project_countstatistic,
  request_home_projectandtask_progresscount,
  request_home_calendar,
  request_announcement_page,
  request_user_page,
} from "@/api/apis";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store/index";
import { Dics } from "@/consts/dic";
import AnnouncementDetail from "@/components/Main/AnnouncementDetail.vue";
import CalendarDateDetail from "@/components/Main/CalendarDateDetail.vue";
import { useRouter } from "vue-router";
import { ProjectProgress, ProjectTaskProgress } from "@/consts/enum";
import { validateMenuButton } from "@/util/util";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { SystemParamCode } from "@/consts/systemParam";

const { t } = useI18n();
const store = useStore();
const router = useRouter();
//
const calendarData = ref(new Date());
// 我的项目
let projectChartDom;
let projectChart;
let projectOption;
// 我的任务
let projectTaskChartDom;
let projectTaskChart;
let projectTaskOption;
// 我的提交审阅
let myCommitReviewChartDom;
let myCommitReviewChart;
let myCommitReviewOption;
// 超窗
let hyperWindowChartDom;
let hyperWindowChart;
let hyperWindowOption;

// 委托方
let clientChart;
// 服务类型
let serviceTypeChart;
// 稽查类型
let auditTypeChart;
// 我的项目数量
const myProjectCount = ref(0);
// 我的任务数量
const myProjectTaskCount = ref(0);
// 我提交审阅数量
const myCommitReviewCount = ref(0);
// 待我审阅数量
const waitMyReviewCount = ref(0);
// 待我协同审阅的数量
const waitMeCollbrationCount = ref(0);

const calendarMonthData = ref([]);
const progressLoading = ref(false);
const calendarLoading = ref(false);
const projectDetailLoading = ref(false);
const announcementTypes = ref([]);
const announcementData = ref([]);
const announcementDialog = ref(false);
const calendarDateDialog = ref(false);
const currentAnnouncement = ref(undefined);
const currentDate = ref(undefined);
const calenderType = ref(1);
const users = ref([]);
const chooseUser = ref("");
const isAdmin = ref(false);
const buttonVisible = reactive({
  chooseUserSelect: false,
});
let progressStatistic = [];

onMounted(async () => {
  progressLoading.value = true;
  projectDetailLoading.value = true;
  await getProjectAndTaskProgressStatistic();
  initProjectChart();
  initProjectTaskChart();
  initMyCommitReviewChart();
  initHyperWindowChart();

  getProjectCountStatistic();
  getCalendarData();
  getUserAll();
});

const computedCalendar = computed(() => (data) => {
  const findDate = calendarMonthData.value.find((item) => item.date_str === data);
  if (findDate && findDate.project_tasks && findDate.project_tasks.length > 0) {
    return findDate.project_tasks.length;
  }
  return "";
});

const getProjectCountStatistic = async () => {
  const trans = {
    user_guid: chooseUser.value,
  };
  const result = await request_home_project_countstatistic(trans);
  myProjectCount.value = result.project_count;
  myProjectTaskCount.value = result.project_task_count;
  waitMyReviewCount.value = result.wait_my_review_count;
  myCommitReviewCount.value = result.my_commit_review_count;
  waitMeCollbrationCount.value = result.wait_me_collbration_count;
};
const getProjectAndTaskProgressStatistic = async () => {
  const trans = {
    user_guid: chooseUser.value,
  };
  const result = await request_home_projectandtask_progresscount(
    trans,
    () => (progressLoading.value = false)
  );
  progressStatistic = result;
};
const getCalendarData = async () => {
  if (calendarData.value) {
    const trans = {
      year: calendarData.value.getFullYear(),
      month: calendarData.value.getMonth() + 1,
      type: calenderType.value,
      user_guid: chooseUser.value,
    };
    const result = await request_home_calendar(trans);
    calendarMonthData.value = result;
  }
};
// 初始化我的项目
const initProjectChart = (isRefresh = false) => {
  const xAxisData = [
    t("Components.Project.ProjectList.ToBeAllocated"),
    t("Components.Project.ProjectList.Ready"),
    t("Components.Project.ProjectList.InProgress"),
    t("Components.Project.ProjectList.Complete"),
    t("Components.Project.ProjectList.Cancel1"),
  ];
  // const data = [
  //   progressStatistic && progressStatistic.project.tobeallocated_count
  //     ? progressStatistic.project.tobeallocated_count
  //     : 0,
  //   progressStatistic && progressStatistic.project.ready_count
  //     ? progressStatistic.project.ready_count
  //     : 0,
  //   progressStatistic && progressStatistic.project.inprogress_count
  //     ? progressStatistic.project.inprogress_count
  //     : 0,
  //   progressStatistic && progressStatistic.project.complete_count
  //     ? progressStatistic.project.complete_count
  //     : 0,
  //   progressStatistic && progressStatistic.project.cancel_count
  //     ? progressStatistic.project.cancel_count
  //     : 0,
  // ];
  const data = [
    {
      name: "",
      value:
        progressStatistic && progressStatistic.project.tobeallocated_count
          ? progressStatistic.project.tobeallocated_count
          : 0,
      progress: ProjectProgress.ToBeAllocated,
    },
    {
      name: "",
      value:
        progressStatistic && progressStatistic.project.ready_count
          ? progressStatistic.project.ready_count
          : 0,
      progress: ProjectProgress.Ready,
    },
    {
      name: "",
      value:
        progressStatistic && progressStatistic.project.inprogress_count
          ? progressStatistic.project.inprogress_count
          : 0,
      progress: ProjectProgress.InProgress,
    },
    {
      name: "",
      value:
        progressStatistic && progressStatistic.project.complete_count
          ? progressStatistic.project.complete_count
          : 0,
      progress: ProjectProgress.Complete,
    },
    {
      name: "",
      value:
        progressStatistic && progressStatistic.project.cancel_count
          ? progressStatistic.project.cancel_count
          : 0,
      progress: ProjectProgress.Cancel,
    },
  ];
  projectOption = {
    title: {
      left: "center",
      text: t("Components.Index.Home.MyProject"),
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: xAxisData,
        axisLabel: {
          //x轴文字的配置
          show: true,
          interval: 0, //使x轴文字显示全
          rotate: 45,
        },
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        minInterval: 1,
      },
    ],
    series: [
      {
        name: t("Components.Index.Home.Count"),
        type: "bar",
        barWidth: 30,
        data: data,
        label: {
          show: true, //开启显示
          position: "top", //在上方显示
          // formatter: '{c}%',//显示百分号
          //数值样式
          color: "black", //字体颜色
          fontSize: 14, //字体大小
        },
        // itemStyle: {
        //   // normal: {
        //   label: {
        //     show: true, //开启显示
        //     position: "top", //在上方显示
        //     textStyle: {
        //       //数值样式
        //       color: "black",
        //       fontSize: 16,
        //     },
        //   },
        //   // },
        // },
      },
    ],
  };

  if (!isRefresh) {
    projectChartDom = document.getElementById("projectStatistic");
    projectChart = echarts.init(projectChartDom);
  }
  projectOption && projectChart.setOption(projectOption);

  projectChart.on("click", (params) => {
    router.push({
      path: "/project/projectlist",
      query: { progress: params.data.progress },
    });
  });
};
// 初始化项目任务
const initProjectTaskChart = (isRefresh = false) => {
  const xAxisData = [
    t("Components.Project.ProjectTask.ToBeAllocated"),
    t("Components.Project.ProjectTask.Ready"),
    t("Components.Project.ProjectTask.AuditImplementation"),
    t("Components.Project.ProjectTask.ReportWriting"),
    t("Components.Project.ProjectTask.SubmitForReview"),
    t("Components.Project.ProjectTask.UnderReview"),
    t("Components.Project.ProjectTask.Rewrite"),
    t("Components.Project.ProjectTask.ReviewedAndApproved"),
    t("Components.Project.ProjectTask.CAPAFinalize"),
    t("Components.Project.ProjectTask.Complete"),
    t("Components.Project.ProjectTask.Cancel"),
  ];
  const data = [
    {
      name: "",
      value:
        progressStatistic && progressStatistic.project_task.tobeallocated_count
          ? progressStatistic.project_task.tobeallocated_count
          : 0,
      progress: ProjectTaskProgress.ToBeAllocated,
    },
    {
      name: "",
      value:
        progressStatistic && progressStatistic.project_task.ready_count
          ? progressStatistic.project_task.ready_count
          : 0,
      progress: ProjectTaskProgress.Ready,
    },
    {
      name: "",
      value:
        progressStatistic && progressStatistic.project_task.auditimplementation_count
          ? progressStatistic.project_task.auditimplementation_count
          : 0,
      progress: ProjectTaskProgress.AuditImplementation,
    },
    {
      name: "",
      value:
        progressStatistic && progressStatistic.project_task.reportwriting_count
          ? progressStatistic.project_task.reportwriting_count
          : 0,
      progress: ProjectTaskProgress.ReportWriting,
    },
    {
      name: "",
      value:
        progressStatistic && progressStatistic.project_task.submitforreview_count
          ? progressStatistic.project_task.submitforreview_count
          : 0,
      progress: ProjectTaskProgress.SubmitForReview,
    },
    {
      name: "",
      value:
        progressStatistic && progressStatistic.project_task.underreview_count
          ? progressStatistic.project_task.underreview_count
          : 0,
      progress: ProjectTaskProgress.UnderReview,
    },
    {
      name: "",
      value:
        progressStatistic && progressStatistic.project_task.rewrite_count
          ? progressStatistic.project_task.rewrite_count
          : 0,
      progress: ProjectTaskProgress.Rewrite,
    },
    {
      name: "",
      value:
        progressStatistic && progressStatistic.project_task.reviewedandApproved_count
          ? progressStatistic.project_task.reviewedandApproved_count
          : 0,
      progress: ProjectTaskProgress.ReviewedAndApproved,
    },
    {
      name: "",
      value:
        progressStatistic && progressStatistic.project_task.capafinalize_count
          ? progressStatistic.project_task.capafinalize_count
          : 0,
      progress: ProjectTaskProgress.CAPAFinalize,
    },
    {
      name: "",
      value:
        progressStatistic && progressStatistic.project_task.complete_count
          ? progressStatistic.project_task.complete_count
          : 0,
      progress: ProjectTaskProgress.Complete,
    },
    {
      name: "",
      value:
        progressStatistic && progressStatistic.project_task.cancel_count
          ? progressStatistic.project_task.cancel_count
          : 0,
      progress: ProjectTaskProgress.Cancel,
    },
  ];
  projectTaskOption = {
    title: {
      left: "center",
      text: t("Components.Index.Home.MyProjectTask"),
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: xAxisData,
        axisLabel: {
          //x轴文字的配置
          show: true,
          interval: 0, //使x轴文字显示全
          rotate: 45,
        },
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        minInterval: 1,
      },
    ],
    series: [
      {
        name: t("Components.Index.Home.Count"),
        type: "bar",
        barWidth: 30,
        data: data,
        label: {
          show: true, //开启显示
          position: "top", //在上方显示
          // formatter: '{c}%',//显示百分号
          //数值样式
          color: "black", //字体颜色
          fontSize: 14, //字体大小
        },
        // itemStyle: {
        //   // normal: {
        //   label: {
        //     show: true, //开启显示
        //     position: "top", //在上方显示
        //     textStyle: {
        //       //数值样式
        //       color: "black",
        //       fontSize: 10,
        //     },
        //   },
        //   // },
        // },
      },
    ],
  };
  if (!isRefresh) {
    projectTaskChartDom = document.getElementById("projectTaskStatistic");
    projectTaskChart = echarts.init(projectTaskChartDom);
  }

  projectTaskOption && projectTaskChart.setOption(projectTaskOption);

  projectTaskChart.on("click", (params) => {
    router.push({
      path: "/project/projecttask",
      query: { progress: params.data.progress },
    });
  });
};

// 初始化我的提交的审阅
const initMyCommitReviewChart = (isRefresh = false) => {
  const xAxisData = [
    t("Components.Project.ProjectTask.ToBeAllocated"),
    t("Components.Project.ProjectTask.SubmitForReview"),
    t("Components.Project.ProjectTask.UnderReview"),
    t("Components.Project.ProjectTask.Rewrite"),
    t("Components.Project.ProjectTask.ReviewedAndApproved"),
  ];
  const data = [
    {
      name: "",
      value:
        progressStatistic &&
        progressStatistic.my_commit_review_progress_count.tobeallocated_count
          ? progressStatistic.my_commit_review_progress_count.tobeallocated_count
          : 0,
      progress: ProjectTaskProgress.ToBeAllocated,
    },
    {
      name: "",
      value:
        progressStatistic &&
        progressStatistic.my_commit_review_progress_count.submitforreview_count
          ? progressStatistic.my_commit_review_progress_count.submitforreview_count
          : 0,
      progress: ProjectTaskProgress.SubmitForReview,
    },
    {
      name: "",
      value:
        progressStatistic &&
        progressStatistic.my_commit_review_progress_count.underreview_count
          ? progressStatistic.my_commit_review_progress_count.underreview_count
          : 0,
      progress: ProjectTaskProgress.UnderReview,
    },
    {
      name: "",
      value:
        progressStatistic &&
        progressStatistic.my_commit_review_progress_count.rewrite_count
          ? progressStatistic.my_commit_review_progress_count.rewrite_count
          : 0,
      progress: ProjectTaskProgress.Rewrite,
    },
    {
      name: "",
      value:
        progressStatistic &&
        progressStatistic.my_commit_review_progress_count.reviewedandApproved_count
          ? progressStatistic.my_commit_review_progress_count.reviewedandApproved_count
          : 0,
        // progressStatistic.my_commit_review_progress_count.reviewedandApproved_count + 
        // progressStatistic.my_commit_review_progress_count.complete_count + 
        // progressStatistic.my_commit_review_progress_count.capafinalize_count ,

      progress: ProjectTaskProgress.ReviewedAndApproved,
    },
  ];
  myCommitReviewOption = {
    title: {
      left: "center",
      text: t("Components.Index.Home.MyReview"),
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: xAxisData,
        axisLabel: {
          //x轴文字的配置
          show: true,
          interval: 0, //使x轴文字显示全
          rotate: 45,
        },
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        minInterval: 1,
      },
    ],
    series: [
      {
        name: t("Components.Index.Home.Count"),
        type: "bar",
        barWidth: 30,
        data: data,
        label: {
          show: true, //开启显示
          position: "top", //在上方显示
          // formatter: '{c}%',//显示百分号
          //数值样式
          color: "black", //字体颜色
          fontSize: 14, //字体大小
        },
        // itemStyle: {
        //   // normal: {
        //   label: {
        //     show: true, //开启显示
        //     position: "top", //在上方显示
        //     textStyle: {
        //       //数值样式
        //       color: "black",
        //       fontSize: 16,
        //     },
        //   },
        //   // },
        // },
      },
    ],
  };
  if (!isRefresh) {
    myCommitReviewChartDom = document.getElementById("myCommitReview");
    myCommitReviewChart = echarts.init(myCommitReviewChartDom);
  }

  myCommitReviewOption && myCommitReviewChart.setOption(myCommitReviewOption);

  myCommitReviewChart.on("click", (params) => {
    router.push({
      path: "/reviewapprove/minereview",
      query: { type: params.data.progress },
    });
  });
};
// 初始化超窗
const initHyperWindowChart = (isRefresh = false) => {
  // hyperWindow
  const xAxisData = [t("Components.Index.Home.Report"), t("Components.Index.Home.CAPA")];
  const data = [
    {
      name: "",
      value: progressStatistic.hyper_window_count.report_count,
      progress: 1, // 代表报告
    },
    {
      name: "",
      value: progressStatistic.hyper_window_count.capa_count,
      progress: 2, // 代表CAPA
    },
  ];
  hyperWindowOption = {
    color: ["#FF0000"],
    title: {
      left: "center",
      text: t("Components.Index.Home.ReportCAPA"),
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: xAxisData,
        axisLabel: {
          //x轴文字的配置
          show: true,
          interval: 0, //使x轴文字显示全
          rotate: 45,
        },
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        minInterval: 1,
      },
    ],
    series: [
      {
        name: t("Components.Index.Home.Count"),
        type: "bar",
        barWidth: 30,
        data: data,
        label: {
          show: true, //开启显示
          position: "top", //在上方显示
          // formatter: '{c}%',//显示百分号
          //数值样式
          color: "black", //字体颜色
          fontSize: 14, //字体大小
        },
        // itemStyle: {
        //   // normal: {
        //   label: {
        //     show: true, //开启显示
        //     position: "top", //在上方显示
        //     textStyle: {
        //       //数值样式
        //       color: "#FF0000",
        //       fontSize: 16,
        //     },
        //   },
        //   // },
        // },
      },
    ],
  };
  if (!isRefresh) {
    hyperWindowChartDom = document.getElementById("hyperWindow");
    hyperWindowChart = echarts.init(hyperWindowChartDom);
  }
  hyperWindowOption && hyperWindowChart.setOption(hyperWindowOption);

  hyperWindowChart.on("click", (params) => {
    // progress 1代表报告；2：代表CAPA
    params.data.progress;
    router.push({
      path: "/progress/reportandcapa",
      query: { type: params.data.progress },
    });
  });
};

// 点击跳转时间，type 1：项目；2：任务：3：审阅
const handleClick = (type) => {
  if (type === 1) {
    // 跳转到项目
    router.push("/project/projectlist");
  } else if (type === 2) {
    // 跳转到任务
    router.push("/project/projecttask");
  } else if (type === 3) {
    router.push("/reviewapprove/minereview");
  } else if (type === 4) {
    router.push({
      path: "/reviewapprove/minereview",
      query: { iswaitreview: 0 },
    });
    // router.push("/reviewapprove/minereview");
  }else if(type === 5){
    router.push("/progress/reportandcapa");
  }
};

onBeforeUnmount(() => {
  if (projectChart) {
    projectChart.dispose();
    projectChart = null;
  }
  if (projectTaskChart) {
    projectTaskChart.dispose();
    projectTaskChart = null;
  }
  if (clientChart) {
    clientChart.dispose();
    clientChart = null;
  }
  if (serviceTypeChart) {
    serviceTypeChart.dispose();
    serviceTypeChart = null;
  }
  if (auditTypeChart) {
    auditTypeChart.dispose();
    auditTypeChart = null;
  }
});
// 分页查询通知公告
const getAnnouncement = async (type) => {
  const trans = {
    pagesize: -1,
    pageindex: 1,
    dataflag: true,
    type: type,
    sortSort: false,
  };
  const result = await request_announcement_page(trans);
  announcementData.value = result.rows;
};
// 处理通知详情关闭
const handleCloseDialog = () => {
  announcementDialog.value = false;
  currentAnnouncement.value = undefined;
};
// 处理日历天关闭
const handleCloseCalendarDateDialog = () => {
  calendarDateDialog.value = false;
};
// 日历点击显示
const handleDateClick = (day) => {
  // 找到这一天的数据
  if (calendarMonthData.value && calendarMonthData.value.length > 0) {
    const findData = calendarMonthData.value.find((item) => item.date_str === day.day);
    if (findData) {
      calendarDateDialog.value = true;
      currentDate.value = findData;
    }
  }
};
// 处理通知点击
const handleAnnouncementClick = (data) => {
  announcementDialog.value = true;
  currentAnnouncement.value = {
    guid: data.guid,
    createtime: data.createtime,
    title: data.title,
  };
};

// 是否周末
const isWeek = (date) => {
  return date.getDay() === 6 || date.getDay() === 0;
};
// 获取所有用户
const getUserAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
    is_system: false,
  };
  const result = await request_user_page(trans);
  if (result.total > 0) {
    users.value = result.rows;
  } else {
    users.value = [];
  }
};

const handleSelectChange = async (value) => {
  chooseUser.value = value;

  progressLoading.value = true;
  projectDetailLoading.value = true;

  await getProjectAndTaskProgressStatistic();
  initProjectChart(true);
  initProjectTaskChart(true);
  initMyCommitReviewChart(true);
  initHyperWindowChart(true);

  getProjectCountStatistic();
  getCalendarData();
};
watchEffect(() => {
  if (calendarData.value) {
    getCalendarData();
  }
});

watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.chooseUserSelect = validateMenuButton(
      store.userMenus,
      MenuCodes.Home,
      MenuButtonCodes.Home.ChooseUser
    );
  }
});

watchEffect(() => {
  if (store.userInfo) {
    if (store.userInfo.dics && store.userInfo.dics.length > 0) {
      const findAnnouncementTypeDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.AnnouncementType
      );
      if (findAnnouncementTypeDic) {
        if (findAnnouncementTypeDic.items && findAnnouncementTypeDic.items.length > 0) {
          announcementTypes.value = findAnnouncementTypeDic.items;
          if (findAnnouncementTypeDic.items && findAnnouncementTypeDic.items.length > 0) {
            getAnnouncement(findAnnouncementTypeDic.items[0].value);
          }
        } else {
          announcementTypes.value = [];
        }
      } else {
        announcementTypes.value = [];
      }
    } else {
      announcementTypes.value = [];
    }

    if (store.userInfo.system_params && store.userInfo.system_params.length > 0) {
      const findData = store.userInfo.system_params.find(
        (item) => item.code === SystemParamCode.AdminAccount
      );
      if (findData) {
        // 找到了这笔数据
        if (findData.value === store.userInfo.user_info.account) {
          // 如果管理员账号和这个账号一致
          isAdmin.value = false;
        }
      }
    }
  }
});
</script>
<style>
.el-calendar-table .el-calendar-day {
  width: 50px !important;
  height: 46px !important;
  padding: 0 !important;
}
.main-div .el-calendar {
  width: 400px !important;
}

.main-div .el-dialog__body {
  padding-top: 10px;
}
</style>
<style scoped lang="scss">
// 定义第一个div的高度
$heigh-1: 440px;
// 定义顶部第一个div中第一行统计的高低
$height-1-1: 100px;
// 定义顶部第一个div中图表div的高度
$height-1-2: 320px;
// 定义图表的chart高度（减去一个paddingtop高度）
$height-1-chart: 300px;

.main-div {
  height: calc(100% - 60px);
  // margin: 20px;
  margin: 0px 20px 20px 20px;
  .div-choose-user {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .div-1 {
    min-height: $heigh-1;
    margin-top: 10px;
    .ul-1-ul {
      height: 100%;
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      .li-left {
        flex: 1;
        margin-right: 20px;
        box-sizing: border-box;
        .div-top-statistic {
          min-height: $height-1-1;
          display: flex;
          flex-wrap: wrap;
          .ul-single {
            display: flex;
            margin: 0;
            padding: 0;
            list-style: none;
            // justify-content: space-between;
            flex-wrap: wrap;
            .ul-single-li {
              width: 260px;
              // min-width: 200px;
              // max-width: 300px;
              min-height: $height-1-1;
              margin-bottom: 20px;
              margin-right: 26px;
              &:hover {
                cursor: pointer;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              }
              .div-statistic {
                display: flex;
                flex-direction: column;
                background-color: white;
                height: $height-1-1;
                // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                box-sizing: border-box;
                // border-radius: 12px;
                .statistic-ul {
                  height: 100%;
                  margin: 0;
                  padding: 0;
                  list-style: none;
                  display: flex;
                  .icon-li {
                    // border-top-left-radius: 12px;
                    // border-bottom-left-radius: 12px;
                    width: 86px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    .iconfont {
                      font-size: 58px;
                    }
                  }
                  .icon-li-project {
                    background-color: #519be0;
                  }
                  .icon-li-projecttask {
                    background-color: #21ba6d;
                  }
                  .icon-li-myreview {
                    background-color: #b066d8;
                  }
                  .icon-li-waitmereview {
                    background-color: #f3730e;
                  }
                  .icon-li-hyperwindow{
                    background-color: #FF0000;
                  }
                  .content-li {
                    flex: 1;
                    .div-count {
                      height: 68px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-size: 36px;
                      font-weight: 600;
                    }
                    .div-title {
                      height: 40px;
                      display: flex;
                      // align-items: center;
                      justify-content: center;
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
        .div-chart-project-task {
          min-height: $height-1-2;
          .chart-ul {
            display: flex;
            padding: 0;
            list-style: none;
            min-height: $height-1-2;
            margin: 20px 0 0 0;
            box-sizing: border-box;
            .li-1 {
              flex: 0.5;
              // padding: 20px 20px 0 0;
              padding-right: 20px;
              box-sizing: border-box;
            }
            .li-2 {
              flex: 0.5;
              padding-left: 20px;
              box-sizing: border-box;
            }
          }
        }
      }
      .li-right {
        width: 400px;
        // margin-left: 20px;
        margin-right: 20px !important;
        box-sizing: border-box;
        .calendar-type-div {
          height: 32px;
          display: flex;
          // align-items: center;
          justify-content: center;
          padding-bottom: 20px;
        }
        .el-calendar {
        }
      }
      .announcement {
        display: flex;
        flex-direction: column;
        .announcement-div {
          flex: 1;
          margin-top: 20px;
          background-color: white;
          .title {
            font-size: 17px;
            font-weight: 900;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            border-bottom: solid 1px #f5f5f5;
          }
          .calendar-ul {
            margin: 0;
            padding: 0 1px;
            list-style: none;
            height: 250px;
            .calendar-ul-li {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              padding: 10px 20px;
              &:hover {
                cursor: pointer;
                background-color: #ecf5ff;
              }
              .span-title {
                font-size: 16px;
                font-weight: 600;
              }
              .div-time {
                .el-text {
                  color: #a9a9a9;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      .rest {
        background-color: #d3d3d3;
      }
      .single-calendar-cell {
        // margin: 4px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        .circle-div {
          width: 26px;
          height: 26px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 13px;
        }
        .circle-div-have {
          background-color: #42b983;
          color: white;
        }
      }
    }
  }
  .div-2 {
    box-sizing: border-box;
    height: calc(100% - 60px - 440px) !important;
  }
  .div-chart {
    height: $height-1-chart;
    background-color: white;
    padding-top: 12px;
    box-sizing: border-box;
    min-width: 300px;
  }
}
</style>
