<template>
  <el-scrollbar>
    <div class="root-comment">
      <div class="div-title">
        <span></span>
      </div>
      <el-divider v-if="title"
        >{{ title }}({{
          t("Components.Project.ProjectTask.Comment.Comment")
        }})</el-divider
      >
      <el-button
        type="primary"
        v-if="
          title &&
          module !== ProjectTaskCommentModule.DiscoveryDescriptionAll &&
          module !== ProjectTaskCommentModule.ReportAppendixSingleAll && computedCanEdit 
          
        "
        @click="handleReply"
        >{{ t("Components.Project.ProjectTask.Comment.Reply") }}</el-button
      >
      <div class="comment-div" v-if="comments">
        <ItemEditReviewSingle
          v-for="item in comments"
          :key="item.guid"
          :comment="item"
          :projectTaskGuid="projectTaskGuid"
          :checkCommentGuid="checkCommentGuid"
          :projectTaskProgress="projectTaskProgress"
          :contentDoms="contentDoms"
          :rootContentDom="rootContentDom"
          @getComment="getComment"
          :module="module"
          :relateGuid="relateGuid"
          :grading="grading"
          :mainCategory="mainCategory"
          :subCategory="subCategory"
          :elaborateModule="elaborateModule"
          :appendixDetailGuid="appendixDetailGuid"
          :comments="comments"
          :data="props.data"
          :userInfo="props.userInfo"
        />
      </div>
    </div>
  </el-scrollbar>
  <ReviewComments
    v-if="showReviewComments"
    @handleCloseDialog="handleCloseDialog"
    @handleCommitDialog="handleCommitDialog"
    :projectTaskGuid="props.projectTaskGuid"
    :relateGuid="relateGuid"
    :grading="grading"
    :mainCategory="mainCategory"
    :subCategory="subCategory"
    :elaborateModule="elaborateModule"
    :appendixDetailGuid="appendixDetailGuid"
    :module="module"
    :selectStart="selectStart"
    :selectEnd="selectEnd"
    :selectContent="selectContent"
    :sourecContent="sourecContent"
  />
</template>
<script setup>
import { defineProps, onMounted, ref, onBeforeUnmount, defineEmits, computed } from "vue";
import ItemEditReviewSingle from "./_ItemEditReviewSingle.vue";
import pubsub from "pubsub-js";
import PubsubMessage from "@/consts/pubsubMessage";
import { useI18n } from "vue-i18n";
import ReviewComments from "./_ReviewComments.vue";
import {
  ProjectTaskProgress,
  ProjectTaskCommentModule,
  ProjectTaskCommentElaborate,
} from "@/consts/enum";
import { request_project_comment_get } from "@/api/apis";

const { t } = useI18n();

const props = defineProps({
  projectTaskGuid: {
    type: String,
    default: () => "",
  },
  comments: {
    type: Object,
    default: () => {},
  },
  data:{
    type: Object,
    default: ()=>{}
  },
  userInfo:{
    type: Object,
    default: ()=>{}
  }
});
const emits = defineEmits(["getCommentModuleCount"]);
const projectTaskContentSummary = ref(undefined);
const checkCommentGuid = ref("");
const projectTaskProgress = ref(0);
const title = ref("");

const sourecContent = ref(""); // 文本那边的内容
const module = ref(0); // 模块
const grading = ref(""); // 分级
const mainCategory = ref(""); // 主分类
const subCategory = ref(""); // 子分类
const elaborateModule = ref(ProjectTaskCommentElaborate.Default); // 详述模块
const appendixDetailGuid = ref(""); // 附件详情单个guid
const relateGuid = ref(""); // 关联业务的guid

// 所有内容 span 元素集合
const contentDoms = ref([]);
const rootContentDom = ref(undefined);
// 是否显示评论模块
const showReviewComments = ref(false);
// 选区开始位置
const selectStart = ref(0);
// 选取结束位置
const selectEnd = ref(0);
// 选取内容
const selectContent = ref("");
// 评论内容
const comments = ref();
// 是否可以编辑
const computedCanEdit = computed(() => {
  let canReply = false
  if(props.data && props.data.project_task){
    // 判断主稽查员是否包含
    if(props.data.project_task.main_auditor_guids && props.data.project_task.main_auditor_guids.length > 0){
      if(props.userInfo && props.data.project_task.main_auditor_guids.indexOf(props.userInfo.guid)>-1){
        canReply = true
      }
    }
    // 稽查人员
    if(props.data.project_task.auditor_guids && props.data.project_task.auditor_guids.length > 0){
      if(props.userInfo && props.data.project_task.auditor_guids.indexOf(props.userInfo.guid)>-1){
        canReply = true
      }
    }
    // 已审核的人员
    if(props.data.project_task.approved_user_guids && props.data.project_task.approved_user_guids.length > 0){
      if(props.userInfo && props.data.project_task.approved_user_guids.indexOf(props.userInfo.guid)>-1){
        canReply = true
      }
    }
    // 待审核人员
    if(props.data.approvers && props.data.approvers.length > 0){
      if(props.userInfo && props.data.approvers.map(item=>item.user_guid).indexOf(props.userInfo.guid)>-1){
        canReply = true
      }
    }
    // 协同审核
    if(props.data.collborations && props.data.collborations.length > 0){
      if(props.userInfo && props.data.collborations.map(item=>item.user_guid).indexOf(props.userInfo.guid)>-1){
        canReply = true
      }
    }
  }
  return (
    (projectTaskProgress.value === ProjectTaskProgress.ReportWriting || // 报告撰写
    projectTaskProgress.value === ProjectTaskProgress.Rewrite ||  // 返改
    projectTaskProgress.value === ProjectTaskProgress.SubmitForReview ||  // 待审月
    projectTaskProgress.value === ProjectTaskProgress.UnderReview || // 审阅中
    projectTaskProgress.value === ProjectTaskProgress.ReviewedAndApproved // 审阅通过
    ) && canReply
  );
});
onMounted(() => {
  pubsub.subscribe(PubsubMessage.ProjectTaskReportComment, async (_, data) => {
    console.log('subscribe', data.projectTaskProgress)
    contentDoms.value = [];
    relateGuid.value = "";
    grading.value = "";
    mainCategory.value = "";
    subCategory.value = "";
    elaborateModule.value = ProjectTaskCommentElaborate.Default;
    appendixDetailGuid.value = "";
    sourecContent.value = "";
    rootContentDom.value = undefined;
    module.value = ProjectTaskCommentModule.Default;
    projectTaskContentSummary.value = undefined;
    projectTaskProgress.value = 0;
    title.value = "";
    comments.value = [];
    checkCommentGuid.value = "";

    if (data.contentDom) {
      sourecContent.value = data.contentDom.innerText;
      rootContentDom.value = data.contentDom;
      contentDoms.value.push(data.contentDom);
    }
    relateGuid.value = data.relateGuid;
    grading.value = data.grading;
    mainCategory.value = data.mainCategory;
    subCategory.value = data.subCategory;
    elaborateModule.value = data.elaborateModule;
    appendixDetailGuid.value = data.appendixDetailGuid;
    module.value = data.module;
    projectTaskContentSummary.value = data.projectTaskContentSummary; // 这个属性只有稽查小结用的到
    projectTaskProgress.value = data.projectTaskProgress;
    title.value = data.title;
    comments.value = data.comments;

    await getComment();
  });
  pubsub.subscribe(PubsubMessage.ProjectTaskReportContentDom, (_, data) => {
    const findExist = contentDoms.value.find((item) => item.guid === data.guid);
    if (!findExist) {
      contentDoms.value.push(data);
    }
  });
  pubsub.subscribe(
    PubsubMessage.ProjectTaskContentSummaryFindParent,
    (_, data) => {
      checkCommentGuid.value = data.parent_guid;
    }
  );
  pubsub.subscribe(PubsubMessage.ProjectTaskClearCheckComment, () => {
    checkCommentGuid.value = "";
  });
});
// 处理点击回复
const handleReply = () => {
  const selection = window.getSelection();
  if (selection.toString()) {
    selectStart.value = selection.anchorOffset;
    selectEnd.value = selection.focusOffset;
    selectContent.value = selection.toString();
  } else {
    selectContent.value = sourecContent.value;
  }
  // 拿到选取的内容
  showReviewComments.value = true;
};
// 处理提交数据
const handleCommitDialog = () => {
  showReviewComments.value = false;
  getComment();
  // 通知更新评论数量
  pubsub.publish(PubsubMessage.ProjectTaskReportUpdateComment);
};
// 处理回调关闭
const handleCloseDialog = () => {
  showReviewComments.value = false;
  selectStart.value = 0;
  selectEnd.value = 0;
  selectContent.value = "";
};
// 获取评论
const getComment = async () => {
  const trans = {
    module: module.value,
    relate_guid: relateGuid.value,
    grading: grading.value,
    main_category: mainCategory.value,
    sub_category: subCategory.value,
    elaborate_module: elaborateModule.value,
    appendix_detail_guid: appendixDetailGuid.value
  };
  const result = await request_project_comment_get(
    props.projectTaskGuid,
    trans
  );
  comments.value = result;
  emits("getCommentModuleCount");
};

onBeforeUnmount(() => {
  pubsub.unsubscribe(PubsubMessage.ProjectTaskReportComment);
  pubsub.unsubscribe(PubsubMessage.ProjectTaskReportContentDom);
  pubsub.unsubscribe(PubsubMessage.ProjectTaskContentSummaryFindParent);
  pubsub.unsubscribe(PubsubMessage.ProjectTaskClearCheckComment);
  // pubsub.unsubscribe(PubsubMessage.ProjectTaskReportUpdateComment);
});
</script>
<style scoped lang="scss">
.root-comment {
  width: 100%;
  min-height: 100px;
  .div-title {
    font-weight: 800;
    font-size: 1.2em;
    padding: 8px;
  }
  .comment-div {
    font-size: 0.8em;
  }
}
</style>
