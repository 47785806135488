<template>
  <div>
    <div>
      <ul class="title-ul">
        <li class="left-li">
          <h3>{{ props.projectTaskContentSummary.audit_summary_name }}：</h3>
        </li>
        <li class="right-li">
          <div v-if="computedCommentCount > 0">
            <el-badge
              :value="computedCommentCount"
              type="success"
              class="item"
              v-if="
                props.projectTaskProgress !==
                  ProjectTaskProgress.ToBeAllocated &&
                props.projectTaskProgress !== ProjectTaskProgress.Ready &&
                props.projectTaskProgress !==
                  ProjectTaskProgress.AuditImplementation &&
                props.projectTaskProgress !==
                  ProjectTaskProgress.ReportWriting &&
                props.projectTaskProgress !== ProjectTaskProgress.Complete
              "
            >
              <el-badge
                :value="computedNotReadCommentCount"
                class="notread-comment-count"
                v-if="computedNotReadCommentCount > 0"
              >
                <el-button
                  type="primary"
                  size="small"
                  circle
                  :icon="ChatDotSquare"
                  @click="handleCommentClick"
                ></el-button>
              </el-badge>
              <el-button
              v-else
                  type="primary"
                  size="small"
                  circle
                  :icon="ChatDotSquare"
                  @click="handleCommentClick"
                ></el-button>
            </el-badge>
          </div>
          <div v-else>
            <el-button
              v-if="
                props.projectTaskProgress !==
                  ProjectTaskProgress.ToBeAllocated &&
                props.projectTaskProgress !== ProjectTaskProgress.Ready &&
                props.projectTaskProgress !==
                  ProjectTaskProgress.AuditImplementation &&
                props.projectTaskProgress !==
                  ProjectTaskProgress.ReportWriting &&
                props.projectTaskProgress !== ProjectTaskProgress.Complete
              "
              type="primary"
              size="small"
              circle
              :icon="ChatDotSquare"
              @click="handleCommentClick"
            ></el-button>
          </div>
        </li>
      </ul>
      <span v-if="!isEdit">
        <span ref="contentRef" v-if="editDataSource">{{ editDataSource }}</span>
        <span v-else :style="{ color: '#D3D3D3' }">
          {{
            t("Components.Project.ProjectTask.Report1.ContentSummary.NoContent")
          }}
        </span>
      </span>
      <el-input
        v-else
        v-model="editingData"
        type="textarea"
        :placeholder="
          t(
            'Components.Project.ProjectTask.Report1.ContentSummary.PleaseInputContent'
          )
        "
      />
      <div :style="{ marginTop: '20px' }">
        <el-space v-if="isEdit">
          <el-button type="primary" @click="handleSave">{{
            t("Components.Project.ProjectTask.Report1.ContentSummary.Save")
          }}</el-button>
          <el-button
            type=""
            @click="
              () => {
                isEdit = false;
              }
            "
            >{{
              t("Components.Project.ProjectTask.Report1.ContentSummary.Cancel")
            }}</el-button
          >
        </el-space>
        <el-space v-else>
          <el-button
            v-if="computedCanEdit"
            type="primary"
            @click="
              () => {
                editingData = editDataSource;
                isEdit = true;
              }
            "
            >{{
              t("Components.Project.ProjectTask.Report1.ContentSummary.Modify")
            }}</el-button
          >
        </el-space>
      </div>
    </div>
    <div>
      <!-- <ItemEditReview
        :projectTaskContentSummary="props.projectTaskContentSummary"
        :projectTaskGuid="props.projectTaskGuid"
        :contentDom="contentDom"
        @getContentSummary="()=>emits('getContentSummary')"
        :checkCommentGuid="props.checkCommentGuid"
        :projectTaskProgress="props.projectTaskProgress"
      /> -->
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  defineProps,
  onMounted,
  defineEmits,
  watchEffect,
  computed,
} from "vue";
import { useI18n } from "vue-i18n";
import { request_project_contentsummary_modify } from "@/api/apis";
import { ProjectTaskProgress, ProjectTaskCommentModule } from "@/consts/enum";
import { ChatDotSquare } from "@element-plus/icons-vue";
import pubsub from "pubsub-js";
import PubsubMessage from "@/consts/pubsubMessage";

const { t } = useI18n();
const props = defineProps({
  projectTaskContentSummary: {
    type: Object,
    default: () => {},
  },
  projectTaskGuid: {
    type: String,
    default: () => "",
  },
  checkCommentGuid: {
    type: String,
    default: () => "",
  },
  projectTaskProgress: {
    type: Number,
    default: () => 0,
  },
  commentModuleCount: {
    type: Array,
    default: () => [],
  },
  canReview: {
    type: Boolean,
    default: ()=> false
  }
});

const emits = defineEmits(["getContentSummary"]);
// 内容
const contentRef = ref();
// 是否处于编辑状态
const isEdit = ref(false);
// 原数据
const editDataSource = ref();
// 编辑中数据
const editingData = ref();

const contentDom = ref();

// 是否可以编辑
const computedCanEdit = computed(() => {
  return (
    props.projectTaskProgress === ProjectTaskProgress.ReportWriting ||
    props.projectTaskProgress === ProjectTaskProgress.Rewrite
  );
});
// 计算模块评论数量
const computedCommentCount = computed(() => {
  if (props.commentModuleCount && props.commentModuleCount.length > 0) {
    const findCommentModuleCountData = props.commentModuleCount.find(
      (item) =>
        item.module === ProjectTaskCommentModule.ContentSummary &&
        item.relate_guid === props.projectTaskContentSummary.guid
    );
    if (findCommentModuleCountData) {
      return findCommentModuleCountData.count;
    }
  }
  return 0;
});
// 计算模块评论数量
const computedNotReadCommentCount = computed(() => {
  if (props.commentModuleCount && props.commentModuleCount.length > 0) {
    const findCommentModuleCountData = props.commentModuleCount.find(
      (item) =>
        item.module === ProjectTaskCommentModule.ContentSummary &&
        item.relate_guid === props.projectTaskContentSummary.guid
    );
    if (findCommentModuleCountData) {
      // return findCommentModuleCountData.not_read_count;
      if(props.canReview) return findCommentModuleCountData.not_read_count_for_approver
      else  return findCommentModuleCountData.not_read_count;
    }
  }
  return 0;
});
onMounted(() => {
  if (props.projectTaskContentSummary.content) {
    editDataSource.value = props.projectTaskContentSummary.content;
  } else {
    editDataSource.value =
      props.projectTaskContentSummary.audit_summary_default_value;
  }
});

watchEffect(() => {
  contentDom.value = contentRef.value;
});

// 处理保存
const handleSave = async () => {
  // 需要把数据更新
  const trans = {
    guid: props.projectTaskContentSummary.guid,
    audit_summary_guid: props.projectTaskContentSummary.audit_summary_guid,
    audit_summary_name: props.projectTaskContentSummary.audit_summary_name,
    content: editingData.value,
  };
  await request_project_contentsummary_modify(props.projectTaskGuid, trans);
  emits("getContentSummary");
  editDataSource.value = editingData.value;
  isEdit.value = false;
};
// 评论点击
const handleCommentClick = () => {
  const data = {
    projectTaskContentSummary: props.projectTaskContentSummary,
    contentDom: contentDom.value,
    checkCommentGuid: props.checkCommentGuid,
    projectTaskProgress: props.projectTaskProgress,
    title: props.projectTaskContentSummary.audit_summary_name,
    module: ProjectTaskCommentModule.ContentSummary,
    relateGuid: props.projectTaskContentSummary.guid,
    comments: props.projectTaskContentSummary.comments,
  };
  pubsub.publish(PubsubMessage.ProjectTaskReportComment, data);
};
</script>
<style scoped lang="scss">
span {
  white-space: pre-wrap;
}
.notread-comment-count {
  ::v-deep .el-badge__content {
    margin-top: 20px;
    font-size: 0.8em;
  }
}
.title-ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  .left-li {
    flex: 1;
  }
  .right-li {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
