<template>
  <Dialog
    width="60%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <ul class="user-ul">
      <li class="hm-search">
        <!-- 查询部分 -->
        <el-form :inline="true" :model="searchForm">
          <el-form-item :label="$t('Common.ChooseUser.ChooseUserSingle.Search')">
            <el-input
              v-model="searchForm.inputSearch"
              :placeholder="$t('Common.ChooseUser.ChooseUserSingle.Name')"
              clearable
            />
          </el-form-item>
          <!-- <el-form-item :label="$t('Common.ChooseUser.ChooseUserSingle.Dataflag')">
            <el-select
              v-model="searchForm.dataflag"
              :placeholder="$t('Common.ChooseUser.ChooseUserSingle.SelectDataflag')"
              clearable
            >
              <el-option :label="$t('Common.ChooseUser.ChooseUserSingle.DataflagY')" value="true" />
              <el-option :label="$t('Common.ChooseUser.ChooseUserSingle.DataflagN')" value="false" />
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="queryClick" :icon="Search">{{
              $t("Common.ChooseUser.ChooseUserSingle.Search1")
            }}</el-button>
            <el-button @click="resetClick" :icon="RefreshLeft">{{
              $t("Common.ChooseUser.ChooseUserSingle.Reset")
            }}</el-button>
          </el-form-item>
        </el-form>
      </li>
      <el-scrollbar>
        <li class="user-li-table">
          <!-- 表格部分 -->
          <el-table v-loading="tableLoading" :data="tableData" row-key="guid">
            <!-- <el-table-column type="selection" width="55" /> -->
            <el-table-column
              prop="name"
              :label="$t('Common.ChooseUser.ChooseUserSingle.UserName')"
            />
            <!-- <el-table-column prop="code" :label="$t('Common.ChooseUser.ChooseUserSingle.NickName')" /> -->
            <el-table-column
              prop="account"
              :label="$t('Common.ChooseUser.ChooseUserSingle.Account')"
            />
            <el-table-column
              prop="phone"
              :label="$t('Common.ChooseUser.ChooseUserSingle.Phone')"
            />
            <el-table-column
              prop="sex"
              :label="$t('Common.ChooseUser.ChooseUserSingle.Sex')"
            >
              <template #default="scope">
                <el-icon
                  :color="TagColor.Female"
                  v-if="scope.row.sex && scope.row.sex === Sex.Female"
                  ><Female
                /></el-icon>
                <el-icon
                  :color="TagColor.Male"
                  v-if="scope.row.sex && scope.row.sex === Sex.Male"
                  ><Male
                /></el-icon>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('Common.ChooseUser.ChooseUserSingle.Operate')"
              width="200"
            >
              <template #default="scope">
                <el-space wrap>
                  <el-button
                    v-if="scope.row.guid !== props.chooseUserGuid"
                    :style="{ color: 'white' }"
                    :color="ButtonColor.Choose"
                    size="small"
                    @click="handleChooseUser(scope.row)"
                    >{{ $t("Common.ChooseUser.ChooseUserSingle.Choose") }}</el-button
                  >
                </el-space>
              </template>
            </el-table-column>
          </el-table>
        </li>
      </el-scrollbar>

      <li class="hm-page">
        <el-pagination
      small
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="AllPageSize"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </li>
    </ul>
  </Dialog>
</template>
<script setup>
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { defineProps, ref, reactive, onMounted,defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import { request_user_page } from "@/api/apis";
import { Sex } from "@/consts/enum";
import { TagColor, ButtonColor, DefaultPageSize, AllPageSize } from "@/consts/frame";
import { Search, RefreshLeft, Male, Female } from "@element-plus/icons-vue";

const { t } = useI18n();

const emits = defineEmits(["handleCloseDialog", "updateChooseUser"]);
const props = defineProps({
  chooseUserGuid: {
    type: String,
    default: () => "",
  },
});
// 标题
const dialogTitle = ref("");
const searchForm = reactive({
  inputSearch: "",
  // dataflag: undefined,
});
// 表格加载
const tableLoading = ref(false);
// 表格数据
const tableData = ref([]);
// 当前页码
const currentPage = ref(1);
// 页码大小
const pageSize = ref(DefaultPageSize);
// 总数量
const total = ref(0);
onMounted(async () => {
  dialogTitle.value = t("Common.ChooseUser.ChooseUserSingle.ChooseUser");
  await getUserPage();
});
// 分页获取用户
const getUserPage = async () => {
  var trans = {
    text: searchForm.inputSearch,
    is_system: false,
  };
  tableLoading.value = true;
  const result = await request_user_page(trans, () => (tableLoading.value = false));

  total.value = result.total;
  tableData.value = result.rows;
};

// 处理页码大小改变事件
const handleSizeChange = (size) => {
  pageSize.value = size;
  getUserPage();
};
// 处理当前页改变事件
const handleCurrentChange = (index) => {
  currentPage.value = index;
  getUserPage();
};
// 查询事件
const queryClick = () => {
  getUserPage();
};
// 重置事件
const resetClick = () => {
  searchForm.inputSearch = "";
  getUserPage();
};

const handleCommit = () => {
  // emits("getDepartmentAll");
  emits("handleCloseDialog");
};
// 关闭弹窗
const handleCancle = () => {
  emits("handleCloseDialog");
};
// 处理选择用户
const handleChooseUser = (row) => {
  emits("updateChooseUser", row);
  emits("handleCloseDialog");
};
</script>
<style scoped lang="scss">
.user-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  .user-li-search {
    // height: 100px;
  }
  .user-li-table {
    // height: 100%;
    flex: 1;
    height: 100%;
    .el-table {
      height: 100%;
    }
  }
}
</style>
