<template>
  <Dialog
    width="60%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form :model="form" label-width="80px" :rules="rules" ref="formRef">
      <el-form-item :label="$t('Components.System.Role.Name')" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item :label="$t('Components.System.Role.Code')" prop="code">
        <el-input v-model="form.code" />
      </el-form-item>
      <el-form-item :label="$t('Components.System.Role.Sort')" prop="sort">
        <el-input-number :style="{ width: '50%' }" v-model="form.sort" />
      </el-form-item>
      <el-form-item
        :label="$t('Components.System.Role.PermissionRange')"
        prop="sort"
      >
        <el-select
          :style="{ width: '50%' }"
          class="m-2"
          v-model="form.data_permission_type"
          :placeholder="$t('Components.System.Department.ChooseDataPermission')"
        >
          <el-option
            v-for="item in dataPermissionOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.data_permission_type === DataPermission.PermissionCustom"
        :label="$t('Components.System.Role.DataPermission')"
        prop="data_permission_department"
      >
        <el-checkbox
          v-model="departmentExpandFold"
          :label="$t('Components.System.Role.ExpandFold')"
          @change="handleDepartmentTreeExpandFold"
        />
        <el-checkbox
          v-model="departmentSelectAllOrNot"
          :label="$t('Components.System.Role.SelectAllOrNot')"
          @change="handleDepartmentSelectAllOrNot"
        />
        <el-checkbox
          v-model="departmentTreeCheckStrictly"
          :label="$t('Components.System.Role.CheckStrictly')"
        />
        <!-- <span v-if="form.data_permission_type === DataPermission.PermissionCustom">123123</span> -->
        <el-tree
          v-loading="dataLoading"
          class="my-tree"
          ref="treeDepartmentRef"
          :data="treeDepartmentData"
          show-checkbox
          :default-expand-all="departmentExpandFold"
          node-key="guid"
          :check-strictly="!departmentTreeCheckStrictly"
          highlight-current
          :props="treeProps"
          @change="
            (value) => {
              if (value !== DataPermission.PermissionCustom) {
                form.data_permission_department = '';
              }
            }
          "
        />
      </el-form-item>
      <el-form-item :label="$t('Components.System.Role.MenuPermission')">
        <el-tree
          v-loading="menuLoading"
          class="my-tree"
          ref="treeRef"
          :data="treeData"
          show-checkbox
          :check-strictly="false"
          node-key="guid"
          highlight-current
          :props="treeProps"
        />
      </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  reactive,
  onMounted,
  markRaw,
} from "vue";
import { useI18n } from "vue-i18n";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import {
  request_menu_menubutton_tree,
  request_role_add,
  request_role_modify,
  request_department_all,
} from "@/api/apis";
import { CheckType } from "@/consts/enum";
import { ElMessageBox } from "element-plus";
import { WarnTriangleFilled } from "@element-plus/icons-vue";
import { DataPermission } from "@/consts/frame";
import { useStore } from "@/store/index";

const store = useStore();
const { t } = useI18n();
const props = defineProps({
  lang: {
    type: String,
    default: () => "zh-cn",
  },
  editData: {
    type: Object,
    default: () => {},
  },
  menuGuid: {
    type: String,
    default: () => "",
  },
});
const emits = defineEmits(["handleCloseDialog", "getRolePage"]);
// 标题
const dialogTitle = ref("");
// 表单ref
const formRef = ref();
// 树ref
const treeRef = ref();
// 部门树ref
const treeDepartmentRef = ref();
// 菜单
const treeData = ref();
// 部门
const treeDepartmentData = ref([]);
//
const dataLoading = ref(false);
//
const menuLoading = ref(false);
// 树
const treeProps = {
  children: "children",
  label: "name",
};
// 部门是否展开折叠
const departmentExpandFold = ref(true);
// 是否检查父子联动
const departmentTreeCheckStrictly = ref(false);
// 部门是否全选全不选
const departmentSelectAllOrNot = ref(false);
// 数据权限列表
const dataPermissionOptions = [
  {
    value: DataPermission.PermissionAll,
    label: t("Components.System.Department.PermissionAll"),
  },
  {
    value: DataPermission.PermissionCustom,
    label: t("Components.System.Department.PermissionCustom"),
  },
  {
    value: DataPermission.PermissionCurrentDepartment,
    label: t("Components.System.Department.PermissionCurrentDepartment"),
  },
  {
    value: DataPermission.PermissionCurrentDepartmentAndChild,
    label: t(
      "Components.System.Department.PermissionCurrentDepartmentAndChild"
    ),
  },
  {
    value: DataPermission.PermissionMySelf,
    label: t("Components.System.Department.PermissionMySelf"),
  },
];
// 表单数据
const form = reactive({
  name: props.editData.name,
  code: props.editData.code,
  sort: props.editData.sort || 1,
  data_permission_type:
    props.editData && props.editData.guid
      ? props.editData.data_permission_type
      : DataPermission.PermissionAll,
});

// 表单校验规则
const rules = ref({
  name: [
    {
      required: true,
      message: t("Components.System.Role.ValidateRoleNameRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.Role.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  code: [
    {
      required: true,
      message: t("Components.System.Role.ValidateRoleCodeRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.Role.ValidateMaxLength"),
      trigger: "change",
    },
  ],
});

onMounted(async () => {
  if (props.editData && props.editData.guid) {
    // 代表修改
    dialogTitle.value = t("Components.System.Role.DialogRoleTitleMaintance");
  } else {
    dialogTitle.value = t("Components.System.Role.DialogRoleTitleAdd");
  }
  // 获取树内容
  menuLoading.value = true
  const result = await request_menu_menubutton_tree({ dataflag: true }, ()=>menuLoading.value = false);
  // 增加设置是否可点击，可点击的逻辑，就是当前用户权限的所有

  const currentUserAllGuids = []
  store.userMenus.forEach(item=>{
    currentUserAllGuids.push(item.menu_guid)
    if(item.menu_buttons && item.menu_buttons.length > 0){
      item.menu_buttons.forEach(item1=>{
        currentUserAllGuids.push(item1.menu_button_guid)
      })
    }
  })

  recursionMenu(result, currentUserAllGuids);

  treeData.value = result;
  const departmentResult = await request_department_all({ dataflag: true });
  treeDepartmentData.value = departmentResult;

  if (props.editData && props.editData.guid) {
    if (treeRef.value) {
      treeRef.value.setCheckedKeys(props.editData.checkedGuids);
      if (
        props.editData.data_permission_type === DataPermission.PermissionCustom
      ) // 自定义数据权限类型
        treeDepartmentRef.value.setCheckedKeys(
          props.editData.checkedDepartmentGuids
        );
    }
  }
});
// 处理提交事件
const handleCommit = async () => {
  if (!formRef.value) return;
  await formRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(
        t("Components.System.Role.ConfirmSaveRole"),
        t("Common.ElMessageBox.OperateConfirm"),
        {
          confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
          cancelButtonText: t("Common.ElMessageBox.CancelText"),
          type: "warning",
          icon: markRaw(WarnTriangleFilled),
        }
      )
        .then(async () => {
          await saveRole();
        })
        .catch(() => {});
    }
  });
};
// 保存角色数据
const saveRole = async () => {
  const onlyCheckedNode = treeRef.value.getCheckedNodes(false, false);
  const allHalfAndCheckedNodes = treeRef.value.getCheckedNodes(false, true);

  let onlyCheckedNodeDepartment = [];
  let allHalfAndCheckedNodesDepartment = [];
  if (form.data_permission_type === DataPermission.PermissionCustom) {
    // 如果当前选择的类型是自定义的
    onlyCheckedNodeDepartment = treeDepartmentRef.value.getCheckedNodes(
      false,
      false
    );
    allHalfAndCheckedNodesDepartment = treeDepartmentRef.value.getCheckedNodes(
      false,
      true
    );
  }
  // 处理选中的菜单
  const checkMenuAndMenuButtons = [];
  if (allHalfAndCheckedNodes && allHalfAndCheckedNodes.length > 0) {
    allHalfAndCheckedNodes.forEach((item) => {
      // 判断是否是全选中的节点
      let checkedType = CheckType.HalfChecked;
      if (onlyCheckedNode) {
        const findOnlyCheckedNode = onlyCheckedNode.find(
          (m) => m.guid === item.guid
        );
        if (findOnlyCheckedNode) {
          checkedType = CheckType.Checked;
        }
      }
      checkMenuAndMenuButtons.push({
        guid: item.guid,
        menu_guid: item.menu_guid,
        type: item.type,
        check_type: checkedType,
      });
    });
  }
  // 处理选中的部门
  const checkDepartment = [];
  if (
    allHalfAndCheckedNodesDepartment &&
    allHalfAndCheckedNodesDepartment.length > 0
  ) {
    allHalfAndCheckedNodesDepartment.forEach((item) => {
      // 判断是否是全选中的节点
      let checkedType = CheckType.HalfChecked;
      if (onlyCheckedNodeDepartment) {
        const findOnlyCheckedNode = onlyCheckedNodeDepartment.find(
          (m) => m.guid === item.guid
        );
        if (findOnlyCheckedNode) {
          checkedType = CheckType.Checked;
        }
      }
      checkDepartment.push({
        department_guid: item.guid,
        check_type: checkedType,
      });
    });
  }
  const trans = {
    code: form.code,
    name: form.name,
    sort: form.sort,
    role_menu_menubuttons: checkMenuAndMenuButtons,
    data_permission_type: form.data_permission_type,
    data_permissions: checkDepartment,
  };
  if (props.editData && props.editData.guid) {
    // 修改
    await request_role_modify(props.editData.guid, trans);
  } else {
    // 新增
    await request_role_add(trans);
  }
  emits("getRolePage");
  emits("handleCloseDialog");
};
// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};
// 处理部门树一键展开折叠
const handleDepartmentTreeExpandFold = (value) => {
  const nodes = treeDepartmentRef.value.store.nodesMap;
  for (const node in nodes) {
    nodes[node].expanded = value;
  }
};
// 处理部门树全选全不选
const handleDepartmentSelectAllOrNot = (value) => {
  const guids = [];
  if (value) {
    treeDepartmentData.value.forEach((item) => {
      recursionDepartment(item, guids);
    });
  }
  treeDepartmentRef.value.setCheckedKeys(guids);
};

const recursionDepartment = (department, guids) => {
  guids.push(department.guid);
  if (department.children && department.children.length > 0) {
    department.children.forEach((item) => {
      guids.push(item.guid);
      recursionDepartment(item, guids);
    });
  }
};
// 递归处理菜单
const recursionMenu = (children, currentUserAllGuids)=>{
  children.forEach(item=>{
    const findData = currentUserAllGuids.find(item1=>item1 === item.guid)
    if(findData){
      item.disabled = false
    }else{
      item.disabled = true
    }
    if(item.children){
      recursionMenu(item.children, currentUserAllGuids)
    }
    
  })
}
</script>
<style scoped lang="scss">
.my-tree {
  width: 100%;
  border: solid 1px #dcdcdc;
  padding: 6px 0;
  border-radius: 6px;
}
</style>
