<template>
  <h2>5. {{ t("Components.Project.ProjectTask.Report1.DiscoverySummary.Title") }}</h2>
  <el-table
    :data="props.discoveryCategory"
    :span-method="objectSpanMethod"
    :header-cell-style="{
      background: '#f8f8f9',
      color: '#515a6e',
    }"
    border
  >
    <el-table-column
      min-width="20%"
      :label="t('Components.Project.ProjectTask.Report1.DiscoverySummary.Abbreviation')"
      prop="abbreviation"
    />
    <el-table-column
      min-width="20%"
      :label="t('Components.Project.ProjectTask.Report1.DiscoverySummary.MainCategory')"
      prop="mainCategoryName"
    />
    <el-table-column
      min-width="20%"
      :label="t('Components.Project.ProjectTask.Report1.DiscoverySummary.SubCategory')"
      prop="subCategoryName"
    />
    <el-table-column
      min-width="10%"
      :label="t('Components.Project.ProjectTask.Report1.DiscoverySummary.Major')"
      prop="majorCount"
    >
      <template #default="scope">
        <span :class="[scope.row.majorCount > 0 ? 'data-span' : '']">{{
          scope.row.majorCount > 0 ? 1 : 0
        }}</span>
      </template>
    </el-table-column>
    <el-table-column
      min-width="10%"
      :label="t('Components.Project.ProjectTask.Report1.DiscoverySummary.Main')"
      prop="mainCount"
    >
      <template #default="scope">
        <span :class="[scope.row.mainCount > 0 ? 'data-span' : '']">{{
          scope.row.mainCount > 0 ? 1 : 0
        }}</span>
      </template>
    </el-table-column>

    <el-table-column
      min-width="10%"
      :label="t('Components.Project.ProjectTask.Report1.DiscoverySummary.Slight')"
      prop="slightCount"
    >
      <template #default="scope">
        <span :class="[scope.row.slightCount > 0 ? 'data-span' : '']">{{
          scope.row.slightCount > 0 ? 1 : 0
        }}</span>
      </template>
    </el-table-column>
  </el-table>
</template>
<script setup>
import { ref, defineProps, onMounted } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps({
  projectTaskGuid: {
    type: String,
    default: () => "",
  },
  discoveryCategory: {
    type: Array,
    default: () => [],
  },
});
// 需要合并的对象
const mergeObj = {};
// 需要合并的列名
const mergeArr = ref([
  {
    columnName: "mainCategoryGuid", // 因为名称可能相同
    columnIndex: [0, 1], // 需要合并的列索引
  },
]);

onMounted(() => {
  getSpanArr();
});

const getSpanArr = () => {
  mergeArr.value.forEach(({ columnName: key }) => {
    // key = 列明
    if (!mergeObj[key]) {
      // 如果不存在这个key的数据
      mergeObj[key] = {};
    }
    // 循环所有需要合并的列名称
    props.discoveryCategory.forEach((item) => {
      // item 每一笔数据
      if (mergeObj[key][item[key]]) {
        // 如果存在这个对象
        mergeObj[key][item[key]].rowspan = mergeObj[key][item[key]].rowspan + 1;
        mergeObj[key][item[key]].colspan = 1;
      } else {
        // 不存在这个对象
        // mergeObj[mergeName] = { rowspan: 0, colspan: 0 };
        mergeObj[key][item[key]] = { rowspan: 1, colspan: 1 };
      }
    });
  });
};

// 默认接受四个值 { 当前行的值, 当前列的值, 行的下标, 列的下标 }
const objectSpanMethod = ({ row, rowIndex, columnIndex }) => {
  let dd = undefined;
  mergeArr.value.forEach(({ columnName: key, columnIndex: index }) => {
    // 循环所有的
    const findIndex = index.findIndex((item) => item === columnIndex);
    if (findIndex > -1) {
      if (mergeObj[key]) {
        // 如果在需要合并的列名中，找到这条数据的上一条数据，看是否和当前的一直，如果一直就代表已经做过合并了
        // 找到那条值
        if (rowIndex === 0) {
          dd = mergeObj[key][row[key]];
        } else {
          // 判断上一条
          if (props.discoveryCategory[rowIndex - 1][key] !== row[key]) {
            // 如果上一条数据的值和这一条不相等
            dd = mergeObj[key][row[key]];
          } else {
            dd = { rowspan: 0, colspan: 0 };
          }
        }
      }
    }
  });
  return dd;
};
</script>
<style scoped lang="scss">
.data-span {
  font-size: 1.2em;
  font-weight: 900;
  color: #1e90ff;
}
</style>
