<template>
  <ul class="user-ul">
    <!-- 左侧部门部分 -->
    <!-- <el-scrollbar v-loading="treeLoading"> -->
    <li class="left-li">
      <ul class="department-ul">
        <li class="hm-search">
          <el-form :inline="true">
            <el-form-item>
              <el-input
                v-model="departmentInputSearch"
                :placeholder="$t('Components.System.User.DepartmentCodeName')"
                clearable
              >
                <template #prefix>
                  <el-icon class="el-input__icon"><Search /></el-icon>
                </template>
              </el-input>
            </el-form-item>
          </el-form>
        </li>
        <li class="department-li">
          <el-scrollbar v-loading="treeLoading">
            <el-tree
              ref="treeRef"
              class="flow-tree"
              :data="treeData"
              node-key="guid"
              :check-strictly="true"
              :props="defaultProps"
              :filter-node-method="filterNode"
              @node-click="handleNodeClick"
              :expand-on-click-node="false"
              :highlight-current="highlightCurrent"
            />
          </el-scrollbar>
        </li>
      </ul>
    </li>
    <!-- </el-scrollbar> -->
    <!-- 右侧用户部分 -->
    <li class="right-li">
      <ul class="user-ul">
        <li class="hm-search">
          <!-- 查询部分 -->
          <el-form :inline="true" :model="searchForm">
            <el-form-item :label="$t('Components.System.User.Search')">
              <el-input
                v-model="searchForm.inputSearch"
                :placeholder="$t('Components.System.User.NamePhone')"
                clearable
              />
            </el-form-item>
            <el-form-item :label="$t('Components.System.User.Dataflag')">
              <el-select
                v-model="searchForm.dataflag"
                :placeholder="$t('Components.System.User.SelectDataflag')"
                clearable
              >
                <el-option :label="$t('Components.System.User.DataflagY')" value="true" />
                <el-option
                  :label="$t('Components.System.User.DataflagN')"
                  value="false"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                v-if="buttonVisible.searchButton"
                type="primary"
                @click="queryClick"
                :icon="Search"
                >{{ $t("Components.System.User.Search1") }}</el-button
              >
              <el-button
                v-if="buttonVisible.resetButton"
                @click="resetClick"
                :icon="RefreshLeft"
                >{{ $t("Components.System.User.Reset") }}</el-button
              >
            </el-form-item>
          </el-form>
        </li>
        <li class="hm-operate">
          <!-- 操作部分 -->
          <el-button
            v-if="buttonVisible.addButton"
            :style="{ color: 'white' }"
            :icon="Plus"
            :color="ButtonColor.Add"
            @click="
              () => {
                dialogVisible = true;
              }
            "
            >{{ $t("Components.System.User.Add") }}</el-button
          >
          <el-button
            v-if="buttonVisible.editButton"
            :disabled="!(selectUsers && selectUsers.length === 1)"
            :style="{ color: 'white' }"
            :icon="Edit"
            :color="ButtonColor.Edit"
            @click="handleEdit(1)"
            >{{ $t("Components.System.User.Edit") }}</el-button
          >
          <el-button
            v-if="buttonVisible.deleteButton"
            :disabled="!(selectUsers && selectUsers.length > 0)"
            :style="{ color: 'white' }"
            :icon="Delete"
            :color="ButtonColor.Delete"
            @click="handleDeleteBatch"
            >{{ $t("Components.System.User.Delete") }}</el-button
          >
          <el-button
            v-if="buttonVisible.exportButton"
            :style="{ color: 'white' }"
            :icon="Download"
            :color="ButtonColor.Export"
            @click="handleExport"
            >{{ $t("Components.System.User.Export") }}</el-button
          >
        </li>
        <el-scrollbar>
          <li class="hm-table">
            <!-- 表格部分 -->
            <el-table
              v-loading="tableLoading"
              :data="tableData"
              row-key="guid"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="55" />
              <el-table-column
                prop="name"
                min-width="100"
                :label="$t('Components.System.User.Name')"
              />
              <el-table-column
                prop="account"
                min-width="120"
                :label="$t('Components.System.User.Account')"
              />
              <el-table-column
                prop="department_name"
                min-width="120"
                :label="$t('Components.System.User.Department')"
              />
              <el-table-column
                prop="phone"
                min-width="120"
                :label="$t('Components.System.User.Phone')"
              />
              <el-table-column
                prop="avator"
                min-width="120"
                :label="$t('Components.System.User.Avator')"
              >
                <template #default="scope">
                  <el-image
                    v-if="scope.row.avator"
                    style="width: 32px; height: 32px"
                    :src="scope.row.avator"
                    fit="fill"
                  >
                    <template #error>
                      <div class="image-slot">
                        <el-icon><Picture /></el-icon>
                      </div>
                    </template>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column prop="sex" :label="$t('Components.System.User.Sex')">
                <template #default="scope">
                  <el-icon
                    :color="TagColor.Female"
                    v-if="scope.row.sex && scope.row.sex === Sex.Female"
                    ><Female
                  /></el-icon>
                  <el-icon
                    :color="TagColor.Male"
                    v-if="scope.row.sex && scope.row.sex === Sex.Male"
                    ><Male
                  /></el-icon>
                </template>
              </el-table-column>
              <el-table-column
                prop="dataflag"
                :label="$t('Components.System.User.Dataflag')"
              >
                <template #default="scope">
                  <el-switch
                    :disabled="!buttonVisible.dataflag"
                    v-model="scope.row.dataflag"
                    @change="(flag) => handleDataflagChange(flag, scope.row)"
                  />
                </template>
              </el-table-column>
              <!-- <el-table-column
                prop="createtime"
                sortable
                :label="$t('Components.System.User.CreateTime')"
              /> -->
              <el-table-column :label="$t('Components.System.User.Operate')" width="200">
                <template #default="scope">
                  <el-space wrap>
                    <el-button
                      v-if="buttonVisible.editButtonLine"
                      :style="{ color: 'white' }"
                      :icon="Edit"
                      :color="ButtonColor.Edit"
                      size="small"
                      @click="handleEdit(2, scope.row)"
                      >{{ $t("Components.System.User.Edit") }}</el-button
                    >
                    <el-button
                      v-if="buttonVisible.deleteButtonLine"
                      :style="{ color: 'white' }"
                      :icon="Delete"
                      :color="ButtonColor.Delete"
                      size="small"
                      bg
                      @click="handleDelete(scope.row)"
                      >{{ $t("Components.System.User.Delete") }}</el-button
                    >
                    <el-button
                      v-if="buttonVisible.resetPasswordLine"
                      :style="{ color: 'white' }"
                      :icon="RefreshRight"
                      :color="ButtonColor.ResetPassword"
                      size="small"
                      @click="handleResetPassword(scope.row.guid)"
                      >{{ $t("Components.System.User.ResetPassword") }}</el-button
                    >
                  </el-space>
                </template>
              </el-table-column>
            </el-table>
          </li>
        </el-scrollbar>
        
    <li class="hm-page">
      <el-pagination
        small
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="AllPageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </li>
      </ul>
    </li>
  </ul>
  <UserModify
    v-if="dialogVisible"
    @handleCloseDialog="handleCloseDialog"
    :editData="editData"
    @getUserPage="getUserPage"
    :departmentTreeData="treeData"
    :rolesData="rolesData"
    :jobsData="jobsData"
    :initAccountPassword="initAccountPassword"
  />
</template>
<script setup>
import { onMounted, ref, watch, reactive, markRaw, watchEffect } from "vue";
import {
  Search,
  RefreshLeft,
  Picture,
  Female,
  Male,
  WarnTriangleFilled,
  Delete,
  Edit,
  Plus,
  RefreshRight,
} from "@element-plus/icons-vue";
import {
  request_department_all,
  request_user_page,
  request_user_delete,
  request_user_delete_batch,
  request_user_dataflag,
  request_user_export,
  request_job_page,
  request_role_page,
  request_user_resetpassword
} from "@/api/apis";
import { useI18n } from "vue-i18n";
import { ButtonColor,AllPageSize, DefaultPageSize, TagColor } from "@/consts/frame";
import UserModify from "@/components/System/User/UserModify.vue";
import { Sex } from "@/consts/enum";
import { ElMessage, ElMessageBox } from "element-plus";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";
import { useStore } from "@/store/index";
import { SystemParamCode } from "@/consts/systemParam";

const store = useStore();

const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  addButton: false,
  editButton: false,
  deleteButton: false,
  editButtonLine: false,
  deleteButtonLine: false,
  dataflag: false,
  exportButton: false,
  resetPasswordLine: false,
});
// 部门查询
const departmentInputSearch = ref("");
// 树ref
const treeRef = ref();
// 树源数据
const treeData = ref([]);
// 树加载
const treeLoading = ref(false);

const defaultProps = {
  children: "children",
  label: "name",
};
const { t } = useI18n();
const searchForm = reactive({
  inputSearch: "",
  dataflag: undefined,
});
// 当前页码
const currentPage = ref(1);
// 页码大小
const pageSize = ref(DefaultPageSize);
// 表格数据
const tableData = ref([]);
// 表格加载
const tableLoading = ref(false);
// 总数量
const total = ref(0);
// 编辑的数据
const editData = ref({});
// 弹窗是否可见
const dialogVisible = ref(false);
// 全屏加载
const fullLoading = ref(false);
// 选择的用户
const selectUsers = ref([]);
// 角色数据
const rolesData = ref([]);
// 岗位数据
const jobsData = ref([]);
// 选择的部门guid
const chooseDepartmentGuid = ref("");
// 初始化账号密码
const initAccountPassword = ref("");

const highlightCurrent = ref(true)

onMounted(() => {
  getDepartmentAll();
  getUserPage();
  getAllJob();
  getAllRole();
});

// 获取所有部门
const getDepartmentAll = async () => {
  const trans = {
    text: departmentInputSearch.value,
    dataflag: true,
  };
  treeLoading.value = true;
  const result = await request_department_all(trans, () => (treeLoading.value = false));
  treeData.value = result;
};
// 获取所有角色
const getAllRole = async () => {
  const trans = {
    dataflag: true,
    pagesize: -1,
  };
  const result = await request_role_page(trans);
  rolesData.value = result.rows;
};
// 获取所有岗位
const getAllJob = async () => {
  const trans = {
    dataflag: true,
    pagesize: -1,
  };
  const result = await request_job_page(trans);
  jobsData.value = result.rows;
};
// 分页查询用户
const getUserPage = async () => {
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    text: searchForm.inputSearch,
    dataflag: searchForm.dataflag,
    is_system: false,
    department_guid: chooseDepartmentGuid.value,
  };
  tableLoading.value = true;
  const result = await request_user_page(trans, () => (tableLoading.value = false));
  total.value = result.total;
  tableData.value = result.rows;
  selectUsers.value = [];
  editData.value = {};
};
// 查询事件
const queryClick = () => {
  getUserPage();
};
// 重置事件
const resetClick = () => {
  searchForm.dataflag = undefined;
  searchForm.inputSearch = "";
  getUserPage();
};
// 处理编辑事件 type = 1 代表操作栏的编辑，type=2代表行编辑
const handleEdit = (type, data) => {
  let row = undefined;
  if (type === 1) {
    // 判断是否有且仅选择了一条用户数据
    if (selectUsers.value.length !== 1) {
      ElMessage.warning(t("Components.System.User.OnlyOneSelectEdit"));
      return;
    }
    row = selectUsers.value[0];
  } else if (type === 2) {
    row = data;
  }
  editData.value = {
    guid: row.guid,
    name: row.name,
    account: row.account,
    nick_name: row.nick_name,
    phone: row.phone,
    email: row.email,
    sex: row.sex,
    remark: row.remark,
    jobs: row.jobs,
    roles: row.roles,
    department_guid: row.department_guid,
    avator: row.avator,
  };
  dialogVisible.value = true;
};
// 处理回调关闭事件
const handleCloseDialog = () => {
  dialogVisible.value = false;
  editData.value = {};
  // selectUsers.value = [];
};
// 处理有效性改变事件
const handleDataflagChange = async (flag, row) => {
  await request_user_dataflag(row.guid, true, () => {
    row.dataflag = !row.dataflag;
  });
};
// 处理选择回调事件
const handleSelectionChange = (users) => {
  selectUsers.value = users;
};
// 处理删除
const handleDelete = async (row) => {
  ElMessageBox.confirm(
    t("Components.System.User.ConfirmDeleteUser"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      await request_user_delete(row.guid, true);
      getUserPage();
    })
    .catch(() => {});
};
// 批量删除
const handleDeleteBatch = async () => {
  if(selectUsers.value.length === 0){
    ElMessage.warning(t("Components.System.User.AtLeastOneUser"));
    return;
  }
  ElMessageBox.confirm(
    t("Components.System.User.ConfirmDeleteUser"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const trans = {
        guids: selectUsers.value.map((item) => item.guid),
      };
      await request_user_delete_batch(trans, true);
      getUserPage();
    })
    .catch(() => {});
};
// 导出
const handleExport = async () => {
  fullLoading.value = true;
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    text: searchForm.inputSearch,
    dataflag: searchForm.dataflag,
    is_system: false,
    department_guid: chooseDepartmentGuid.value,
  };
  const result = await request_user_export(
    trans,
    true,
    () => (fullLoading.value = false)
  );
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = result;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
};
// 监听左侧部门变化，用于过滤树节点数据
watch(departmentInputSearch, (val) => {
  treeRef.value.filter(val);
});
// 树节点过滤回调事件
const filterNode = (value, data) => {
  if (!value) return true;
  return data.name.includes(value);
};
// 处理节点点击事件
const handleNodeClick = (node) => {
  // 需要查询这个节点下面的用户数据
  if(chooseDepartmentGuid.value){ // 已选中过节点
    if(node.guid != chooseDepartmentGuid.value){ // 选中的部门与点击的不一致
      chooseDepartmentGuid.value = node.guid
      highlightCurrent.value = true
    }else{ // 选中的部门与点击的一致，代表这次要取消
      chooseDepartmentGuid.value = ''
      highlightCurrent.value = false
    }
  }else{
    chooseDepartmentGuid.value = node.guid;
    highlightCurrent.value = true
  }
  getUserPage();
};
// 处理重值密码
const handleResetPassword = (guid) => {
  ElMessageBox.confirm(
    t("Components.System.User.ConfirmResetPassword"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      await request_user_resetpassword(guid);
    })
    .catch(() => {});
};
// 处理当前页改变事件
const handleCurrentChange = (index) => {
  currentPage.value = index;
  getUserPage();
};
// 处理页码大小改变事件
const handleSizeChange = (size) => {
  pageSize.value = size;
  getUserPage();
};
// 拆分
watchEffect(()=>{
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.User,
      MenuButtonCodes.System.User.UserSearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.User,
      MenuButtonCodes.System.User.UserReset
    );
    // 处理新增
    buttonVisible.addButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.User,
      MenuButtonCodes.System.User.UserAdd
    );
    // 处理编辑
    buttonVisible.editButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.User,
      MenuButtonCodes.System.User.UserEdit
    );
    // 处理删除
    buttonVisible.deleteButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.User,
      MenuButtonCodes.System.User.UserDelete
    );
    // 处理编辑行
    buttonVisible.editButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.System.User,
      MenuButtonCodes.System.User.UserEditLine
    );
    // 处理重置密码行
    buttonVisible.resetPasswordLine = validateMenuButton(
      store.userMenus,
      MenuCodes.System.User,
      MenuButtonCodes.System.User.UserResetPasswordLine
    );
    // 处理删除行
    buttonVisible.deleteButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.System.User,
      MenuButtonCodes.System.User.UserDeleteLine
    );
    // 处理有效性
    buttonVisible.dataflag = validateMenuButton(
      store.userMenus,
      MenuCodes.System.User,
      MenuButtonCodes.System.User.UserDataflag
    );
    // 处理导出
    buttonVisible.exportButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.User,
      MenuButtonCodes.System.User.exportButton
    );
  }
})
watchEffect(() => {
  if (
    store.userInfo &&
    store.userInfo.system_params &&
    store.userInfo.system_params.length > 0
  ) {
    const findData = store.userInfo.system_params.find(
      (item) => item.code === SystemParamCode.InitAccountPassword
    );
    if (findData) {
      // 找到了这笔数据
      initAccountPassword.value = findData.value;
    } else {
      initAccountPassword.value = "";
    }
  } else {
    initAccountPassword.value = "";
  }

});
</script>
<style>
.department-li .el-tree-node__content {
  height: 36px;
}
.user-ul .el-scrollbar__view {
  height: calc(100% - 8px);
}
.department-li .el-scrollbar__view {
  height: calc(100%);
}
</style>
<style scoped lang="scss">
.user-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  height: 100%;
  .left-li {
    min-width: 260px;
    // flex-shrink: 0;
    box-sizing: border-box;
    height: 100%;
    .department-ul {
      list-style: none;
      padding: 0;
      margin: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      .department-li {
        margin-top: 8px;
        padding: 8px;
        background-color: white;
        box-sizing: border-box;
        width: 100%;
        height: calc(100% - 76px);
        .el-tree {
          height: 100%;
        }
      }
    }
  }
  .right-li {
    width: calc(100% - 260px);
    box-sizing: border-box;
    padding-left: 8px;
    .user-ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      .user-li-search {
      }
      .hm-table {
        height: calc(100% - 8px);
      }
    }
  }
}
</style>
