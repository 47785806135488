<template>
  <Dialog
    width="40%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form :model="form" label-width="120px" :rules="rules" ref="formRef">
      <el-form-item
        :label="$t('Components.Project.ProjectTask.ReportAppendix.SerialNumber')"
        prop="serial_number"
      >
        <el-input
          v-model="form.serial_number"
          :placeholder="
            t('Components.Project.ProjectTask.ReportAppendix.PleaseInputSerialNumber')
          "
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('Components.Project.ProjectTask.ReportAppendix.FilterNumber')" prop="filter_number">
        <el-input
          v-model="form.filter_number"
          :placeholder="
            t('Components.Project.ProjectTask.ReportAppendix.PleaseInputFilterNumber')
          "
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('Components.Project.ProjectTask.ReportAppendix.Visit')" prop="visit">
        <el-input
          v-model="form.visit"
          :placeholder="
            t('Components.Project.ProjectTask.ReportAppendix.PleaseInputVisit')
          "
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('Components.Project.ProjectTask.ReportAppendix.SourceRecord')" prop="source_record">
        <el-input
          v-model="form.source_record"
          :placeholder="
            t('Components.Project.ProjectTask.ReportAppendix.PleaseInputSourceRecord')
          "
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('Components.Project.ProjectTask.ReportAppendix.EDCPosition')" prop="edc_position">
        <el-input
          v-model="form.edc_position"
          :placeholder="
            t('Components.Project.ProjectTask.ReportAppendix.PleaseInputEDCPosition')
          "
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('Components.Project.ProjectTask.ReportAppendix.EDCContent')" prop="edc_content">
        <el-input
          v-model="form.edc_content"
          :placeholder="
            t('Components.Project.ProjectTask.ReportAppendix.PleaseInputEDCContent')
          "
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('Components.Project.ProjectTask.ReportAppendix.Sort')" prop="sort">
        <el-input-number
          :style="{ width: '100%' }"
          v-model="form.sort"
          :placeholder="
            t('Components.Project.ProjectTask.ReportAppendix.PleaseInputSort')
          "
        ></el-input-number>
      </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup>
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import {
  defineProps,
  ref,
  reactive,
  onMounted,
  markRaw,
  defineEmits,
} from "vue";
import { ElMessageBox } from "element-plus";
import { WarnTriangleFilled} from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import { request_projecttask_reportappendix_detail_modify, 
  request_projecttask_reportappendix_detail_add } from "@/api/apis";

const { t } = useI18n();
const emits = defineEmits([ "handleCloseDialog",'getProjectTaskReportAppendixDetailPage']);
const props = defineProps({
  editData: {
    type: Object,
    default: () => {},
  },
  projectTaskGuid: {
    type: String,
    default: ()=> ''
  },
  selectProjectTaskReportAppendixGuid:{
    type: String,
    default: ()=> ''
  }
});
// 标题
const dialogTitle = ref("");

const form = reactive({
  serial_number: props.editData.serial_number,
  filter_number: props.editData.filter_number,
  visit: props.editData.visit,
  source_record: props.editData.source_record,
  edc_position: props.editData.edc_position,
  edc_content: props.editData.edc_content,
  sort: props.editData.sort
});
const formRef = ref();
// 表单校验规则
const rules = ref({
  serial_number: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.ReportAppendix.PleaseInputSerialNumber"),
      trigger: "blur",
    },
  ],
  filter_number: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.ReportAppendix.PleaseInputFilterNumber"),
      trigger: "blur",
    },
  ],
  visit: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.ReportAppendix.PleaseInputVisit"),
      trigger: "blur",
    },
  ],
  source_record: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.ReportAppendix.PleaseInputSourceRecord"),
      trigger: "blur",
    },
  ],
  edc_position: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.ReportAppendix.PleaseInputEDCPosition"),
      trigger: "blur",
    },
  ],
  edc_content: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.ReportAppendix.PleaseInputEDCContent"),
      trigger: "blur",
    },
  ],
  sort: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.ReportAppendix.PleaseInputSort"),
      trigger: "blur",
    },
  ],
});
onMounted(() => {
  if (props.editData && props.editData.guid) {
    // 代表修改
    dialogTitle.value = t("Components.Project.ProjectTask.ReportAppendix.ModifyReportAppendixDetail");
  } else {
    dialogTitle.value = t("Components.Project.ProjectTask.ReportAppendix.AddReportAppendixDetail");
  }
});

// 处理提交事件
const handleCommit = async () => {
  // 校验
  if (!formRef.value) return;
  await formRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(
        t("Components.Project.ProjectTask.ReportAppendix.ConfirmSaveReportAppendixDetail"),
        t("Common.ElMessageBox.OperateConfirm"),
        {
          confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
          cancelButtonText: t("Common.ElMessageBox.CancelText"),
          type: "warning",
          icon: markRaw(WarnTriangleFilled),
        }
      )
        .then(async () => {
          await save();
        })
        .catch(() => {});
    }
  });
  return;
};
// 保存数据
const save = async () => {
  const trans = {
    serial_number: form.serial_number,
    filter_number: form.filter_number,
    visit: form.visit,
    source_record: form.source_record,
    edc_position: form.edc_position,
    edc_content: form.edc_content,
    sort: form.sort,
  };
  if (props.editData && props.editData.guid) {
    // 修改
    await request_projecttask_reportappendix_detail_modify(props.projectTaskGuid, props.selectProjectTaskReportAppendixGuid, props.editData.guid,  trans);
  } else {
    // 新增
    await request_projecttask_reportappendix_detail_add(props.projectTaskGuid, props.selectProjectTaskReportAppendixGuid, trans);
  }
  emits('getProjectTaskReportAppendixDetailPage')
  emits("handleCloseDialog");
};
// 关闭
const handleCancle = () => {
  emits("handleCloseDialog");
};
</script>
<style scoped lang="scss">
.input-principle {
  &:hover {
    cursor: pointer;
  }
}
</style>
