<template>
  <div class="single-div-root" @click="handlePersonalInfomation(true)">
    <div class="single-div">
      <el-icon>
        <i class="iconfont icon-user"></i>
      </el-icon>
      <span>{{ $t("Components.Header.HeaderPopover.PersonalInfo") }}</span>
    </div>
  </div>
  <!-- <div class="single-div-root">
    <div class="single-div">
      <el-icon>
        <i class="iconfont icon-zhuti_o"></i>
      </el-icon>
      <div class="left-right-div">
        <span>{{ $t('Components.Header.HeaderPopover.DayAndNightMode') }}</span>
        <el-switch @click="handleChangeMode" v-model="lightDarkMode" class="mt-2" size="small" inline-prompt
          :active-icon="Dark" width="38px" :inactive-icon="Light"
          style="--el-switch-on-color: rgb(44,44,44); --el-switch-off-color: rgb(242,242,242)" />
      </div>
    </div>
  </div> -->
  <div class="single-div-root">
    <div class="single-div" @click="handleClearCache">
      <el-icon>
        <i class="iconfont icon-qingchu"></i>
      </el-icon>
      <span>{{ $t("Components.Header.HeaderPopover.ClearCache") }}</span>
    </div>
  </div>
  <div class="single-div-root">
    <div class="single-div" @click="handleLanguageClick">
      <el-icon>
        <i class="iconfont icon-duoyuyan"></i>
      </el-icon>
      <div class="left-right-div">
        <span>{{ $t("Components.Header.HeaderPopover.Language") }}</span>
        <el-icon>
          <ArrowDown />
        </el-icon>
      </div>
    </div>
  </div>
  <transition name="el-zoom-in-top">
    <div v-show="languageMoreShow" class="language-more-div">
      <div class="single-div-root" @click="changeLanguage(Language.Chinese)">
        <div class="single-div">
          <div class="language-div">
            <el-icon> </el-icon>
            <span>{{ $t("Components.Header.HeaderPopover.Chinese") }}</span>
          </div>
          <el-icon v-if="lang === Language.Chinese">
            <CircleCheckFilled />
          </el-icon>
        </div>
      </div>
      <!-- <div class="single-div-root" @click="changeLanguage(Language.English)">
        <div class="single-div">
          <div class="language-div">
            <el-icon> </el-icon>
            <span>{{ $t("Components.Header.HeaderPopover.English") }}</span>
          </div>
          <el-icon v-if="lang === Language.English">
            <CircleCheckFilled />
          </el-icon>
        </div>
      </div> -->
    </div>
  </transition>
  <div class="single-div-root" @click="handleSystemSetting(true)">
    <div class="single-div">
      <el-icon>
        <i class="iconfont icon-xitongshezhi"></i>
      </el-icon>
      <span>{{ $t("Components.Header.HeaderPopover.SystemSetting") }}</span>
    </div>
  </div>
  <div class="single-div-root" @click="handleSignOut">
    <div class="single-div">
      <el-icon>
        <i class="iconfont icon-tuichu"></i>
      </el-icon>
      <span>{{ $t("Components.Header.HeaderPopover.LoginOut") }}</span>
    </div>
  </div>
</template>
<script setup >
import { onMounted, ref, markRaw, defineEmits, shallowRef } from "vue";
import {
  ArrowDown,
  CircleCheckFilled,
  WarnTriangleFilled,
} from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import { Language, Mode, LocalStorageKey } from "@/consts/frame";
import { ElMessageBox } from "element-plus";
import { useRouter } from "vue-router";
import Login from "@/views/LoginIndex.vue";
import { useStore } from "@/store/index";

import {request_util_clearcache} from '@/api/apis'

const { t } = useI18n();
const router = useRouter();
const store = useStore();
// 日夜模式
const lightDarkMode = ref(false); // 是否是暗模式
// 语言div是否展开
const languageMoreShow = ref(false);
// 语言，默认中文
const lang = ref(Language.Chinese);
// emits
const emits = defineEmits([
  "setSystemSettingVisible",
  "setPersonalInfomationVisible",
]);
// 语言展开div点击事件
const handleLanguageClick = () => {
  languageMoreShow.value = !languageMoreShow.value;
};
// 切换语言
const changeLanguage = (lan) => {
  lang.value = lan;
  localStorage.setItem(LocalStorageKey.Lang, lan);
  location.reload();
};
// 挂载
onMounted(() => {
  // 初始化语言
  if (localStorage.getItem(LocalStorageKey.Lang) === Language.English) {
    lang.value = Language.English;
  }
  // 初始化模式
  lightDarkMode.value =
    localStorage.getItem(LocalStorageKey.Mode) === Mode.Dark;
  document
    .getElementsByTagName("html")[0]
    .setAttribute(
      "class",
      localStorage.getItem(LocalStorageKey.Mode)
        ? localStorage.getItem(LocalStorageKey.Mode)
        : Mode.Light
    );
});
// 退出登录
const handleSignOut = () => {
  ElMessageBox.confirm(
    t("Components.Index.Index.SureLoginOut"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      // 清空是否锁定缓存数据
      localStorage.removeItem(LocalStorageKey.IsLock);
      // 清除密码数据
      localStorage.removeItem(LocalStorageKey.Password);
      // 清空token数据
      localStorage.removeItem(LocalStorageKey.Token);
      // 清空上一个页面
      localStorage.removeItem(LocalStorageKey.LastActiveMenuPath);
      // 清空用户信息
      store.setUserInfo(undefined);
      // 清空最近操作时间
      localStorage.removeItem(LocalStorageKey.LastOperateTime);
      // 清空监听事件
      if (
        store.lastOperateTimeIntervalId &&
        store.lastOperateTimeIntervalId > 0
      ) {
        clearInterval(store.lastOperateTimeIntervalId);
        store.setLastOperateTimeId(undefined);
      }
      store.setLastOperateTime(undefined);
      store.setKeepAliveCache(false);
      window.document.onmousedown = null;
      router.push("/login");
      // 跳转到登录页面
      store.setActivePage({ component: shallowRef(Login) });
    })
    .catch(() => {});
};
// 处理系统设置点击
const handleSystemSetting = (status) => {
  emits("setSystemSettingVisible", status);
};
// 处理个人信息点击
const handlePersonalInfomation = (status) => {
  emits("setPersonalInfomationVisible", status);
};
// 处理清除缓存
const handleClearCache = async()=>{
  await request_util_clearcache()
}
</script>
<style></style>
<style scoped lang='scss'>
.single-div-root {
  &:hover {
    cursor: pointer;
    background-color: rgb(245, 245, 245);
  }

  .single-div {
    padding: 0 12px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    display: flex;
    align-items: center;

    .language-div {
      flex: 1;
    }

    span {
      margin-left: 8px;
    }

    .left-right-div {
      width: 100%;
      display: flex;
      height: 100%;
      align-items: center;

      span {
        flex: 1;
      }
    }
  }
}
</style>