<template>
  <Dialog
    width="40%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form :model="form" label-width="120px" :rules="rules" ref="formRef">
      <el-form-item
        :label="$t('Components.ReviewApprove.MineReview.Reviewer')"
        prop="approvers"
      >
        <el-select
          v-model="form.approvers"
          :placeholder="
            t('Components.ReviewApprove.MineReview.PleaseSelectReviewer')
          "
          :style="{ width: '100%' }"
          multiple
          collapse-tags
          clearable
        >
          <el-option
            v-for="item in users"
            :key="item.guid"
            :label="item.name"
            :value="item.guid"
            :disabled="
              props.editData.approvers.find(
                (item1) => item1.user_guid === item.guid
              )
                ? true
                : false
            "
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        :label="$t('Components.ReviewApprove.MineReview.Collboration')"
        prop="collborations"
      >
        <el-select
          v-model="form.collborations"
          :placeholder="
            t('Components.ReviewApprove.MineReview.PleaseSelectCollboration')
          "
          :style="{ width: '100%' }"
          multiple
          collapse-tags
          clearable
        >
          <!-- 这边需要排除，不能是当前操作人，排除已存在的审核人，排除已存在的协同审核人 -->
          <el-option
            v-for="item in users"
            :key="item.guid"
            :label="item.name"
            :value="item.guid"
            :disabled="
              props.editData.collborations.find(
                (item1) => item1.user_guid === item.guid
              ) ||
              props.editData.approvers.find(
                (item1) => item1.user_guid === item.guid
              )
                ? true
                : false
            "
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup>
import {
  ref,
  reactive,
  markRaw,
  onMounted,
  defineProps,
  defineEmits,
} from "vue";
import { WarnTriangleFilled } from "@element-plus/icons-vue";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { useI18n } from "vue-i18n";
import {
  request_reviewapprove_distribute,
} from "@/api/apis";
import { ElMessage, ElMessageBox } from "element-plus";

const { t } = useI18n();
const props = defineProps({
  users: {
    type: Array,
    default: () => [],
  },
  editData: {
    type: Object,
    default: () => {},
  },
});

const emits = defineEmits(["handleCloseDialog", "getMineReviewPage"]);
// 标题
const dialogTitle = ref("");
// 表单ref
const formRef = ref();
// 表单数据
const form = reactive({
  approvers: [],
  collborations: props.editData && props.editData.collborations ? props.editData.collborations.map(item=>item.user_guid) : []
});

// 表单校验规则
const rules = ref({
  // user_guid: [
  //   {
  //     required: true,
  //     message: t("Components.ReviewApprove.MineReview.PleaseSelectReviewer"),
  //     trigger: "blur",
  //   },
  // ],
});

onMounted(async () => {
  dialogTitle.value = t(
    "Components.ReviewApprove.MineReview.DialogTitleDistribute"
  );
});

// 处理提交事件
const handleCommit = async () => {
  if (!formRef.value) return;
  // 至少选择一个
  if((!form.approvers || form.approvers.length === 0) && (!form.collborations || form.collborations.length == 0)){
    ElMessage.warning(t('Components.ReviewApprove.MineReview.ApproverAndCollborationAtLeastOne'))
    return
  }
  await formRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(
        t("Components.ReviewApprove.MineReview.ConfirmDistributeReviewer"),
        t("Common.ElMessageBox.OperateConfirm"),
        {
          confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
          cancelButtonText: t("Common.ElMessageBox.CancelText"),
          type: "warning",
          icon: markRaw(WarnTriangleFilled),
        }
      )
        .then(async () => {
          await saveReviewer();
        })
        .catch(() => {});
    }
  });
};
// 保存审阅人
const saveReviewer = async () => {
  const trans = {
    approvers: form.approvers,
    collborations: form.collborations,
    project_task_guid: props.editData.guid,
  };
  await request_reviewapprove_distribute(trans);
  emits("getMineReviewPage");
  emits("handleCloseDialog");
};
// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};
</script>
<style scoped lang="scss"></style>
