import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/global.css'
import 'element-plus/dist/index.css'
// import vuex from '@/vuex'
import { router } from '@/router'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import '@/assets/icons/iconfont/iconfont.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import i18n from '@/language'
import * as ELIcons from '@element-plus/icons-vue'
import {createPinia} from 'pinia'
// import Print from 'vue3-print-nb'
import globalVar from '@/util/global'
import { Boot } from "@wangeditor/editor";
import attachmentModule from '@wangeditor/plugin-upload-attachment'
Boot.registerModule(attachmentModule)
// 如果不增加下面两个函数，使用table 组件将会报错
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}
const app = createApp(App)

app.config.globalProperties.$icons = []
for (const [key, component] of Object.entries(ELIcons)) {
    app.config.globalProperties.$icons.push(key)
    app.component(key, component)
}
// Print.install = function (Vue) {
//   Vue.directive('print', Print);
// };

window.$global = globalVar
router.afterEach((to,from,next) => {
  window.scrollTo({ // 页面切换时，滚动条平滑切换至顶部
    top:0,
    behavior:'smooth'
  })
})
app.use(ElementPlus, { locale: zhCn })
app.use(router)
// app.use(vuex)
app.use(i18n)
app.use(createPinia())
// app.use(Print)
app.mount('#app')