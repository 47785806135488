<template>
  <ul class="reportappendix-ul" v-loading="fullLoading">
    <li class="hm-search">
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item
          :label="$t('Components.Project.ProjectTask.ReportAppendix.Search1')"
        >
          <el-input
            v-model="searchForm.input"
            :placeholder="
              $t(
                'Components.Project.ProjectTask.ReportAppendix.PleaseInputSearch'
              )
            "
            clearable
          />
        </el-form-item>
        <el-form-item
          :label="t('Components.Project.ProjectTask.ReportAppendix.ImportDate')"
        >
          <el-date-picker
            v-model="searchForm.createtime"
            type="daterange"
            range-separator="-"
            :start-placeholder="
              t(
                'Components.Project.ProjectTask.ReportAppendix.PleaseSelectStartDate'
              )
            "
            :end-placeholder="
              t(
                'Components.Project.ProjectTask.ReportAppendix.PleaseSelectEndDate'
              )
            "
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="buttonVisible.searchButton"
            type="primary"
            @click="queryClick"
            :icon="Search"
            >{{
              $t("Components.Project.ProjectTask.ReportAppendix.Search")
            }}</el-button
          >
          <el-button
            v-if="buttonVisible.resetButton"
            @click="resetClick"
            :icon="RefreshLeft"
            >{{
              $t("Components.Project.ProjectTask.ReportAppendix.Reset")
            }}</el-button
          >
        </el-form-item>
      </el-form>
    </li>
    <li class="hm-operate">
      <!-- 操作部分 -->
      <el-button
        v-if="buttonVisible.addButton"
        :disabled="props.projectTaskData.progress === ProjectTaskProgress.Complete"
        :style="{ color: 'white' }"
        :icon="Plus"
        :color="ButtonColor.Add"
        @click="
          () => {
            dialogVisible = true;
          }
        "
        >{{
          $t("Components.Project.ProjectTask.ReportAppendix.Add")
        }}</el-button
      >
      <el-button
        v-if="buttonVisible.editButton"
        :disabled="
          !(selectReportAppendixs && selectReportAppendixs.length === 1) ||
          props.projectTaskData.progress === ProjectTaskProgress.Complete
        "
        :style="{ color: 'white' }"
        :icon="Edit"
        :color="ButtonColor.Edit"
        @click="handleEdit(1)"
        >{{
          $t("Components.Project.ProjectTask.ReportAppendix.Edit")
        }}</el-button
      >
      <el-button
        v-if="buttonVisible.downloadTemplateButton"
        :style="{ color: 'white' }"
        :icon="Download"
        :color="ButtonColor.Download"
        @click="handleDownloadTemplate"
        >{{
          $t("Components.Project.ProjectTask.ReportAppendix.DownloadTempalte")
        }}</el-button
      >
      <el-button
        v-if="buttonVisible.deleteButton"
        :disabled="
          !(selectReportAppendixs && selectReportAppendixs.length > 0) ||
          props.projectTaskData.progress === ProjectTaskProgress.Complete
        "
        :style="{ color: 'white' }"
        :icon="Delete"
        :color="ButtonColor.Delete"
        @click="handleDeleteBatch"
        >{{
          $t("Components.Project.ProjectTask.ReportAppendix.Delete")
        }}</el-button
      >
    </li>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          row-key="guid"
          @selection-change="handleSelectionChange"
          @row-click="handleRowClick"
          :row-class-name="tableRowClassName"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            prop="name"
            :label="$t('Components.Project.ProjectTask.ReportAppendix.Name')"
            width="140"
          >
            <!-- <template #default="scope">
          <el-button type="primary" link @click="handleDownload(scope.row)">{{ scope.file_name }}</el-button>
        </template> -->
          </el-table-column>
          <el-table-column
            prop="sort"
            :label="$t('Components.Project.ProjectTask.ReportAppendix.Sort')"
            width="100"
          />
          <el-table-column
            prop="createname"
            :label="
              $t('Components.Project.ProjectTask.ReportAppendix.CreateName')
            "
          />
          <el-table-column
            prop="createtime"
            :label="
              $t('Components.Project.ProjectTask.ReportAppendix.CreateTime')
            "
          />
          <el-table-column
            :label="$t('Components.Project.ProjectTask.ReportAppendix.Operate')"
            width="180"
          >
            <template #default="scope">
              <el-space wrap>
                <el-button
                  v-if="buttonVisible.editButtonLine"
                  :disabled="
                    props.projectTaskData.progress === ProjectTaskProgress.Complete
                  "
                  :style="{ color: 'white' }"
                  :icon="Edit"
                  :color="ButtonColor.Edit"
                  size="small"
                  @click.stop="handleEdit(2, scope.row)"
                  >{{
                    $t("Components.Project.ProjectTask.ReportAppendix.Edit")
                  }}</el-button
                >
                <el-button
                  v-if="buttonVisible.deleteButtonLine"
                  :disabled="
                    props.projectTaskData.progress === ProjectTaskProgress.Complete
                  "
                  :style="{ color: 'white' }"
                  :icon="Delete"
                  :color="ButtonColor.Delete"
                  size="small"
                  bg
                  @click.stop="handleDelete(scope.row)"
                  >{{
                    $t("Components.Project.ProjectTask.ReportAppendix.Delete")
                  }}</el-button
                >
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </li>
    </el-scrollbar>

    <li class="hm-page">
      <el-pagination
        small
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="AllPageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </li>
  </ul>
  <ReportAppendixModify
    :projectTaskGuid="props.projectTaskGuid"
    v-if="dialogVisible"
    @handleCloseDialog="handleCloseDialog"
    @getProjectTaskReportAppendixPage="getProjectTaskReportAppendixPage"
    :editData="editData"
  />
</template>
<script setup>
import {
  reactive,
  ref,
  markRaw,
  onMounted,
  watchEffect,
  defineEmits,
  defineProps
} from "vue";
import {
  Plus,
  Delete,
  RefreshLeft,
  Edit,
  Search,
  WarnTriangleFilled,
  Download,
} from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";
import {
  AllPageSize,
  ButtonColor,
  DefaultPageSize,
} from "@/consts/frame";
import {
  request_projecttask_reportappendix_page,
  request_projecttask_reportappendix_delete_batch,
  request_projecttask_reportappendix_delete,
  request_exceltemplate_bycode
} from "@/api/apis";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";
import { useStore } from "@/store/index";
import ReportAppendixModify from "@/components/Project/ProjectTask/ReportAppendix/ReportAppendix_LeftModify.vue";
import { ProjectTaskProgress } from "@/consts/enum";
import {ExcelTemplateCodes} from '@/consts/excelTemplateCode'

const store = useStore();
const { t } = useI18n();

const props = defineProps({
  projectTaskGuid: {
    type: String,
    default: () => "",
  },
  projectTaskData:{
    type: Object,
    default: ()=>{}
  },
  selectProjectTaskReportAppendixGuid:{
    type: String,
    default: ()=> ''
  }
});

const emits = defineEmits([
  "handleReportAppendixClick",
  "handleClearReportAppendixDetail",
  'updateSelectProjectTaskReportAppendixGuid'
]);
const searchForm = reactive({
  input: '',
  createtime: undefined
});
const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  addButton: false,
  editButton: false,
  deleteButton: false,
  downloadTemplateButton: false,
  editButtonLine: false,
  deleteButtonLine: false,
});
// 当前页码
const currentPage = ref(1);
// 页码大小
const pageSize = ref(DefaultPageSize);
// 表格数据
const tableData = ref([]);
// 表格加载
const tableLoading = ref(false);
// 总数量
const total = ref(0);
// 编辑的数据
const editData = ref({});
// 弹窗是否可见
const dialogVisible = ref(false);
// 全屏加载
const fullLoading = ref(false);

const selectReportAppendixs = ref([]);

onMounted(() => {
  getProjectTaskReportAppendixPage();
 
});
// 分页查询
const getProjectTaskReportAppendixPage = async () => {
  emits('updateSelectProjectTaskReportAppendixGuid', '')
  emits("handleClearReportAppendixDetail");
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    text: searchForm.input,
    createtime_start:  searchForm.createtime && searchForm.createtime.length > 0
        ? searchForm.createtime[0]
        : undefined,
    createtime_end:  searchForm.createtime && searchForm.createtime.length > 1
        ? searchForm.createtime[1]
        : undefined,
  };
  tableLoading.value = true;
  const result = await request_projecttask_reportappendix_page(
    props.projectTaskGuid,
    trans,
    () => (tableLoading.value = false)
  );
  total.value = result.total;
  tableData.value = result.rows;
  editData.value = {};
};

// 查询事件
const queryClick = () => {
  getProjectTaskReportAppendixPage();
};
// 重置事件
const resetClick = () => {
  searchForm.createtime = undefined
  searchForm.input = ''
  getProjectTaskReportAppendixPage();
};
// 处理页码大小改变事件
const handleSizeChange = (size) => {
  pageSize.value = size;
  getProjectTaskReportAppendixPage();
};
// 处理当前页改变事件
const handleCurrentChange = (index) => {
  currentPage.value = index;
  getProjectTaskReportAppendixPage();
};
// 处理编辑事件
const handleEdit = (type, data) => {
  let row = undefined;
  if (type === 1) {
    // 判断是否有且仅选择了一条用户数据
    if (selectReportAppendixs.value.length !== 1) {
      ElMessage.warning(
        t("Components.Project.ProjectTask.ReportAppendix.OnlyOneSelectEdit")
      );
      return;
    }
    row = selectReportAppendixs.value[0];
  } else if (type === 2) {
    row = data;
  }

  editData.value = {
    guid: row.guid,
    sort: row.sort,
    name: row.name,
    project_task_guid: props.projectTaskGuid,
  };
  dialogVisible.value = true;
};
// 处理下载模板
const handleDownloadTemplate = async ()=>{
  const trans = {
    code: ExcelTemplateCodes.ReportAppendix,
    dataflag: true
  }
  var result = await request_exceltemplate_bycode(trans)
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = result.path;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
}
// 处理回调关闭事件
const handleCloseDialog = () => {
  dialogVisible.value = false;
  editData.value = {};
};
// 处理选择回调事件
const handleSelectionChange = (reportAppendixs) => {
  selectReportAppendixs.value = reportAppendixs;
};
// 处理删除
const handleDelete = async (row) => {
  ElMessageBox.confirm(
    t(
      "Components.Project.ProjectTask.ReportAppendix.ConfirmDeleteReportAppendix"
    ),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {

      await request_projecttask_reportappendix_delete(props.projectTaskGuid, row.guid)

      getProjectTaskReportAppendixPage();
    })
    .catch(() => {});
};
// 批量删除
const handleDeleteBatch = async () => {
  if (selectReportAppendixs.value.length === 0) {
    ElMessage.warning(
      t(
        "Components.Project.ProjectTask.ReportAppendix.AtLeastOneReportAppendix"
      )
    );
    return;
  }
  ElMessageBox.confirm(
    t("Components.Project.ProjectTask.ReportAppendix.ConfirmDeleteReportAppendix"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const trans = {
        project_task_report_appendixs: selectReportAppendixs.value.map(
          (item) => item.guid
        ),
      };
      await request_projecttask_reportappendix_delete_batch(
        props.projectTaskGuid,
        trans,
        true
      );
      getProjectTaskReportAppendixPage();
    })
    .catch(() => {});
};
// 行点击事件
const handleRowClick = (row) => {
  emits('updateSelectProjectTaskReportAppendixGuid', row.guid)
};
// 激活行的颜色
const tableRowClassName = ({ row }) => {
  if (row.guid === props.selectProjectTaskReportAppendixGuid) {
    return "success-row";
  }
};
// // 处理下载
// const handleDownload = (row)=>{
//   let path =row.file_path
//   const link = document.createElement("a");
//   link.style.display = "none";
//   link.href = path;
//   link.target = '_blank'
//   document.body.appendChild(link);
//   link.click();
// }
watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskReportAppendixSearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskReportAppendixReset
    );
    // 处理新增
    buttonVisible.addButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskReportAppendixAdd
    );
    // 处理编辑
    buttonVisible.editButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskReportAppendixEdit
    );
    // 处理删除
    buttonVisible.deleteButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskReportAppendixDelete
    );
    // 处理下载模板
    buttonVisible.downloadTemplateButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskReportAppendixDownload
    );
    // 处理编辑行
    buttonVisible.editButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskReportAppendixEditLine
    );
    // 处理删除行
    buttonVisible.deleteButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskReportAppendixDeleteLine
    );
  }
});
</script>

<style>
.reportappendix-ul .el-scrollbar__view {
  height: calc(100% - 8px);
}
.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}
</style>
<style scoped lang="scss">
.reportappendix-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: calc(100% - 22px);
}
</style>
