<template>
  <ul class="loginlog-ul" v-loading="fullLoading">
    <li class="hm-search">
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item :label="$t('Components.Log.LoginLog.Search')">
          <el-input
            v-model="searchForm.inputSearch"
            :placeholder="$t('Components.Log.LoginLog.PleaseInputSearch')"
            clearable
          />
        </el-form-item>

        <el-form-item :label="$t('Components.Log.LoginLog.Status')">
          <el-select
            v-model="searchForm.status"
            :placeholder="$t('Components.Log.LoginLog.PleaseSelectStatus')"
            clearable
          >
            <el-option
              :label="$t('Components.Log.LoginLog.Success')"
              :value="StatusCode.Success"
            />
            <!-- 非200就是错误，这边就给了500 -->
            <el-option
              :label="$t('Components.Log.LoginLog.Failure')"
              :value="StatusCode.ServerError"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="t('Components.Log.LoginLog.LoginTime')">
          <el-date-picker
            v-model="searchForm.time_range"
            type="daterange"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            range-separator=" - "
            :start-placeholder="t('Components.Log.LoginLog.StartDate')"
            :end-placeholder="t('Components.Log.LoginLog.EndDate')"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryClick" :icon="Search">{{
            $t("Components.Log.LoginLog.Search1")
          }}</el-button>
          <el-button @click="resetClick" :icon="RefreshLeft">{{
            $t("Components.Log.LoginLog.Reset")
          }}</el-button>
        </el-form-item>
      </el-form>
    </li>
    <li class="hm-operate">
      <!-- 操作部分 -->
      <el-button
        :disabled="!(selectLoginLogs && selectLoginLogs.length > 0)"
        v-if="buttonVisible.deleteButton"
        :style="{ color: 'white' }"
        :icon="Delete"
        :color="ButtonColor.Delete"
        @click="handleDeleteBatch"
        >{{ $t("Components.Log.LoginLog.Delete") }}</el-button
      >
      <el-button
      v-if="buttonVisible.clearButton"
        :disabled="total <= 0"
        :style="{ color: 'white' }"
        :icon="Delete"
        :color="ButtonColor.Clear"
        @click="handleClear"
        >{{ $t("Components.Log.LoginLog.Clear") }}</el-button
      >
      <el-button
      v-if="buttonVisible.exportButton"
        :style="{ color: 'white' }"
        :icon="Download"
        :color="ButtonColor.Export"
        @click="handleExport"
        >{{ $t("Components.Log.LoginLog.Export") }}</el-button
      >
    </li>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          row-key="guid"
          @sort-change="handleSortChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            prop="id"
            width="120"
            :label="$t('Components.Log.LoginLog.Id')"
          />
          <el-table-column
            prop="user_name"
            min-width="120"
            :label="$t('Components.Log.LoginLog.UserName')"
          />
          <el-table-column
            prop="user_account"
            min-width="120"
            :label="$t('Components.Log.LoginLog.UserAccount')"
          />
          <!-- show-overflow-tooltip -->
          <el-table-column
            prop="ip"
            min-width="120"
            :label="$t('Components.Log.LoginLog.LoginIP')"
          />
          <el-table-column
            prop="browser"
            min-width="120"
            :label="$t('Components.Log.LoginLog.Browser')"
          />
          <el-table-column
            prop="os"
            min-width="120"
            :label="$t('Components.Log.LoginLog.OS')"
          />
          <el-table-column
            prop="status"
            min-width="120"
            :label="$t('Components.Log.LoginLog.Status')"
          >
            <template #default="scope">
              <el-tag
                v-if="scope.row.status == StatusCode.Success"
                type="success"
              >
                {{ $t("Components.Log.LoginLog.Success") }}
              </el-tag>
              <el-tag v-else type="warning">
                {{ $t("Components.Log.LoginLog.Failure") }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="message"
            min-width="120"
            show-overflow-tooltip
            :label="$t('Components.Log.LoginLog.Message')"
          />
          <el-table-column
            prop="createtime"
            sortable
            min-width="120"
            :label="$t('Components.Log.LoginLog.LoginTime')"
          />
        </el-table>
      </li>
    </el-scrollbar>

    <li class="hm-page">
      <el-pagination
      small
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="AllPageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </li>
  </ul>
  <!-- <ViewOperateLog
    v-if="dialogVisible"
    :editData="editData"
    @handleCloseDialog="handleCloseDialog"
  /> -->
</template>
<script setup>
import {
  reactive,
  ref,
  markRaw,
  onMounted,
  watchEffect,
} from "vue";
import {
  Delete,
  RefreshLeft,
  Search,
  WarnTriangleFilled,
  Download,
} from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";
import {
  AllPageSize,
  ButtonColor,
  DefaultPageSize,
} from "@/consts/frame";
import {
  request_loginlog_page,
  request_loginlog_delete_batch,
  request_loginlog_export,
  request_loginlog_clear,
} from "@/api/apis";
import { useStore } from "@/store/index";
// import { AccessLogType, AccessLogModule } from "@/consts/enum";
import StatusCode from "@/api/statusCode";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";

const store = useStore();
const { t } = useI18n();
const searchForm = reactive({
  inputSearch: "",
  status: undefined,
  time_range: undefined,
});
const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  deleteButton: false,
  clearButton: false,
  exportButton: false,
});
// 当前页码
const currentPage = ref(1);
// 页码大小
const pageSize = ref(DefaultPageSize);
// 表格数据
const tableData = ref([]);
// 表格加载
const tableLoading = ref(false);
// 总数量
const total = ref(0);
// 编辑的数据
const editData = ref({});
// 创建时间排序规则 如果为undefined 则没有这个参数，如果为true代表倒叙，为false代表正序
const createTimeSort = ref(true);
// 选择的登录日志
const selectLoginLogs = ref([]);
// 全屏加载
const fullLoading = ref(false);

onMounted(async () => {
  await getLoginLogPage();
});
// 分页查询通知公告
const getLoginLogPage = async () => {
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    text: searchForm.inputSearch,
    createTimeSort: createTimeSort.value,
    status: searchForm.status,
    start_date:
      searchForm.time_range && searchForm.time_range.length > 0
        ? searchForm.time_range[0]
        : undefined,
    end_date:
      searchForm.time_range && searchForm.time_range.length > 1
        ? searchForm.time_range[1]
        : undefined,
  };
  tableLoading.value = true;
  const result = await request_loginlog_page(
    trans,
    () => (tableLoading.value = false)
  );
  total.value = result.total;
  tableData.value = result.rows;
  selectLoginLogs.value = [];
  editData.value = {};
};

// 查询事件
const queryClick = () => {
  getLoginLogPage();
};
// 重置事件
const resetClick = () => {
  searchForm.dataflag = undefined;
  searchForm.inputSearch = "";
  searchForm.module = undefined;
  searchForm.type = undefined;
  searchForm.status = undefined;
  getLoginLogPage();
};
// 处理页码大小改变事件
const handleSizeChange = (size) => {
  pageSize.value = size;
  getLoginLogPage();
};
// 处理当前页改变事件
const handleCurrentChange = (index) => {
  currentPage.value = index;
  getLoginLogPage();
};
// 处理选择回调事件
const handleSelectionChange = (ammouncements) => {
  selectLoginLogs.value = ammouncements;
};
// 批量删除
const handleDeleteBatch = async () => {
  if (selectLoginLogs.value.length === 0) {
    ElMessage.warning(t("Components.Log.LoginLog.AtLeastOneLoginLog"));
    return;
  }
  ElMessageBox.confirm(
    t("Components.Log.LoginLog.ConfirmDeleteLoginLog"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const trans = {
        guids: selectLoginLogs.value.map((item) => item.guid),
      };
      await request_loginlog_delete_batch(trans, true);
      getLoginLogPage();
    })
    .catch(() => {});
};
// 处理排序改变事件
const handleSortChange = async ({ prop, order }) => {
  if (prop === "createtime") {
    // 创建时间排序
    if (order === "ascending") {
      // 时间正序
      createTimeSort.value = false;
    } else if (order === "descending") {
      // 时间倒叙
      createTimeSort.value = true;
    } else {
      createTimeSort.value = undefined;
    }
  }
  await getLoginLogPage();
};
// 导出
const handleExport = async () => {
  fullLoading.value = true;
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    text: searchForm.inputSearch,
    createTimeSort: createTimeSort.value,
    type: searchForm.type,
    module: searchForm.module,
    status: searchForm.status,
  };
  const result = await request_loginlog_export(
    trans,
    true,
    () => (fullLoading.value = false)
  );
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = result;
  link.target = '_blank'
  document.body.appendChild(link);
  link.click();
};
// 处理清除
const handleClear = async () => {
  ElMessageBox.confirm(
    t("Components.Log.LoginLog.ConfirmClearLoginLog"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      fullLoading.value = true;
      await request_loginlog_clear(() => (fullLoading.value = false));
      getLoginLogPage();
    })
    .catch(() => {});
};

watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Log.LoginLog,
      MenuButtonCodes.Log.LoginLog.LoginLogSearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Log.LoginLog,
      MenuButtonCodes.Log.LoginLog.LoginLogReset
    );
    // 处理删除
    buttonVisible.addButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Log.LoginLog,
      MenuButtonCodes.Log.LoginLog.LoginLogDelete
    );
    // 处理清空
    buttonVisible.editButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Log.LoginLog,
      MenuButtonCodes.Log.LoginLog.LoginLogClear
    );
    // 处理导出
    buttonVisible.exportButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Log.LoginLog,
      MenuButtonCodes.Log.LoginLog.LoginLogExport
    );
  }
});
</script>
<style>
.loginlog-ul .el-scrollbar__view {
  height: calc(100% - 8px);
}
</style>
<style scoped lang="scss">
.loginlog-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  .loginlog-li-search {
    // height: 100px;
  }
}
</style>
