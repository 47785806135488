<template>
  <Dialog
    width="40%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form
      :model="form"
      label-width="140px"
      ref="formRef"
      size="large"
      :rules="rules"
    >
      <el-form-item
        :label="$t('Components.AuditCalendar.PersoCalendar.Person')"
        prop="user_guid"
      >
        <el-select
          v-model="form.user_guid"
          :placeholder="
            t('Components.AuditCalendar.PersoCalendar.PleaseSelectUser')
          "
          :style="{ width: '100%' }"
        >
          <el-option
            v-for="item in users"
            :key="item.guid"
            :label="item.name"
            :value="item.guid"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('Components.AuditCalendar.PersoCalendar.DateRange')"
        prop="scheduling_time"
      >
        <el-date-picker
          :style="{ width: '100%' }"
          v-model="form.scheduling_time"
          type="daterange"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          range-separator=" - "
          :start-placeholder="
            t('Components.AuditCalendar.TaskCalendar.PleaseSelectStartDate')
          "
          :end-placeholder="
            t('Components.AuditCalendar.TaskCalendar.PleaseSelectEndDate')
          "
        />
      </el-form-item>
      <el-form-item
        :label="$t('Components.AuditCalendar.PersoCalendar.Type')"
        prop="type"
      >
        <el-radio-group v-model="form.type" class="ml-4">
          <el-radio :label="UserSchedulingType.Scheduling" size="large">{{
            t("Components.AuditCalendar.PersoCalendar.Scheduling")
          }}</el-radio>
          <el-radio :label="UserSchedulingType.Vacation" size="large">{{
            t("Components.AuditCalendar.PersoCalendar.Vacation")
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        :label="$t('Components.AuditCalendar.PersoCalendar.Remark')"
        prop="remark"
      >
        <el-input
          v-model="form.remark"
          type="textarea"
          maxlength="999"
          show-word-limit
          :placeholder="
            t('Components.AuditCalendar.PersoCalendar.PleaseInputRemark')
          "
        ></el-input>
      </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup>
import {
  ref,
  reactive,
  markRaw,
  onMounted,
  defineProps,
  defineEmits,
} from "vue";
import { WarnTriangleFilled } from "@element-plus/icons-vue";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { useI18n } from "vue-i18n";
import {
  request_userscheduling_add,
  request_userscheduling_modify,
} from "@/api/apis";
import { ElMessageBox } from "element-plus";
import { UserSchedulingType } from "@/consts/enum";

const { t } = useI18n();
const props = defineProps({
  users: {
    type: Array,
    default: () => [],
  },
  editData: {
    type: Object,
    default: () => undefined,
  },
});
const emits = defineEmits(["handleCloseDialog", "researchData"]);
// 标题
const dialogTitle = ref("");
// 表单ref
const formRef = ref();
// 表单数据
const form = reactive({
  user_guid: props.editData ? props.editData.user_guid : "",
  scheduling_time: props.editData
    ? [props.editData.plan_start_time, props.editData.plan_end_time]
    : undefined,
  type: props.editData ? props.editData.type : UserSchedulingType.Scheduling,
  remark: props.editData? props.editData.remark: ''
});
// 表单校验规则
const rules = ref({
  user_guid: [
    {
      required: true,
      message: t("Components.AuditCalendar.PersoCalendar.PleaseSelectUser"),
      trigger: "blur",
    },
  ],
  scheduling_time: [
    {
      required: true,
      message: t(
        "Components.AuditCalendar.PersoCalendar.PleaseSelectSchedulingDate"
      ),
      trigger: "blur",
    },
  ],
  type: [
    {
      required: true,
      message: t("Components.AuditCalendar.PersoCalendar.PleaseSelectType"),
      trigger: "blur",
    },
  ],
});

onMounted(async () => {
  if (props.editData && props.editData.guid) {
    dialogTitle.value = t(
      "Components.AuditCalendar.PersoCalendar.ModifyScheduling"
    );
  } else {
    dialogTitle.value = t(
      "Components.AuditCalendar.PersoCalendar.AddScheduling"
    );
  }
});
// 处理提交事件
const handleCommit = async () => {
  if (!formRef.value) return;
  await formRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(
        t("Components.AuditCalendar.PersoCalendar.ConfirmSaveData"),
        t("Common.ElMessageBox.OperateConfirm"),
        {
          confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
          cancelButtonText: t("Common.ElMessageBox.CancelText"),
          type: "warning",
          icon: markRaw(WarnTriangleFilled),
        }
      )
        .then(async () => {
          await saveData();
        })
        .catch(() => {});
    }
  });
};
// 保存数据
const saveData = async () => {
  const trans = {
    user_guid: form.user_guid,
    starttime:
      form.scheduling_time && form.scheduling_time.length > 0
        ? form.scheduling_time[0]
        : undefined,
    endtime:
      form.scheduling_time && form.scheduling_time.length > 1
        ? form.scheduling_time[1]
        : undefined,
    type: form.type,
    remark: form.remark
  };
  if (props.editData && props.editData.user_scheduling_guid) {
    await request_userscheduling_modify(
      props.editData.user_scheduling_guid,
      trans
    );
  } else {
    await request_userscheduling_add(trans);
  }
  emits("handleCloseDialog");
  emits("researchData");
};
// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};
</script>
<style scoped lang="scss"></style>
