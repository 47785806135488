<template>
  <ul class="auditsummaryitem-right-ul">
    <li class="hm-search">
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item
          :label="$t('Components.Database.AuditSummary.Item.Search')"
        >
          <el-input
            :disabled="!auditSummaryGuid"
            v-model="searchForm.inputSearch"
            :placeholder="
              $t('Components.Database.AuditSummary.Item.PleaseInputName')
            "
            clearable
          />
        </el-form-item>
        <el-form-item
          :label="$t('Components.Database.AuditSummary.Item.Dataflag')"
        >
          <el-select
            :disabled="!auditSummaryGuid"
            v-model="searchForm.dataflag"
            :placeholder="
              $t('Components.Database.AuditSummary.Item.SelectDataflag')
            "
            clearable
          >
            <el-option
              :label="$t('Components.Database.AuditSummary.Item.DataflagY')"
              value="true"
            />
            <el-option
              :label="$t('Components.Database.AuditSummary.Item.DataflagN')"
              value="false"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="buttonVisible.searchButton"
            :disabled="!auditSummaryGuid"
            type="primary"
            @click="queryClick"
            :icon="Search"
            >{{
              $t("Components.Database.AuditSummary.Item.Search1")
            }}</el-button
          >
          <el-button
            v-if="buttonVisible.resetButton"
            :disabled="!auditSummaryGuid"
            @click="resetClick"
            :icon="RefreshLeft"
            >{{ $t("Components.Database.AuditSummary.Item.Reset") }}</el-button
          >
        </el-form-item>
      </el-form>
    </li>
    <li class="hm-operate">
      <!-- 操作部分 -->
      <el-button
        v-if="buttonVisible.addButton"
        :disabled="!auditSummaryGuid"
        :style="{ color: 'white' }"
        :icon="Plus"
        :color="ButtonColor.Add"
        @click="
          () => {
            dialogVisible = true;
          }
        "
        >{{ $t("Components.Database.AuditSummary.Item.Add") }}</el-button
      >
    </li>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <el-table :data="tableData" row-key="guid" v-loading="tableLoading">
          <el-table-column
            prop="name"
            sortable
            show-overflow-tooltip
            :label="$t('Components.Database.AuditSummary.Item.Name')"
          />
          <el-table-column
            prop="default_value"
            sortable
            show-overflow-tooltip
            :label="$t('Components.Database.AuditSummary.Item.DefaultValue')"
          />
          <el-table-column
            prop="sort"
            :label="$t('Components.Database.AuditSummary.Item.Sort')"
            sortable
            width="120"
          />

          <el-table-column
            prop="dataflag"
            width="120"
            :label="$t('Components.Database.AuditSummary.Item.Dataflag')"
          >
            <template #default="scope">
              <el-switch
                :disabled="!buttonVisible.dataflag"
                v-model="scope.row.dataflag"
                @click.stop
                @change="(flag) => handleDataflagChange(flag, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('Components.Database.AuditSummary.Item.Operate')"
            width="240"
          >
            <template #default="scope">
              <el-space wrap>
                <el-button
                  v-if="buttonVisible.editButtonLine"
                  :style="{ color: 'white' }"
                  :icon="Edit"
                  :color="ButtonColor.Edit"
                  size="small"
                  @click="handleEditAuditSummaryItem(scope.row)"
                  >{{
                    $t("Components.Database.AuditSummary.Item.Edit")
                  }}</el-button
                >
                <el-button
                  v-if="buttonVisible.deleteButtonLine"
                  :style="{ color: 'white' }"
                  :icon="Delete"
                  :color="ButtonColor.Delete"
                  size="small"
                  bg
                  @click.stop="handleDelete(scope.row)"
                  >{{
                    $t("Components.Database.AuditSummary.Item.Delete")
                  }}</el-button
                >
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </li>
    </el-scrollbar>
    <li class="hm-page"></li>
  </ul>

  <AuditSummaryItemModify
    v-if="dialogVisible"
    @handleCloseDialog="handleCloseDialog"
    @getAuditSummaryItemPage="getAuditSummaryItem"
    :editData="editData"
    :auditSummaryGuid="auditSummaryGuid"
  />
</template>
<script setup>
import {
  Search,
  RefreshLeft,
  Plus,
  Edit,
  Delete,
  WarnTriangleFilled,
} from "@element-plus/icons-vue";
import {
  defineExpose,
  ref,
  reactive,
  watchEffect,
  markRaw,
} from "vue";
import {
  request_auditsummary_item_all,
  request_auditsummary_item_delete,
  request_auditsummary_item_dataflag,
} from "@/api/apis";
import { validateMenuButton } from "@/util/util";
import { useStore } from "@/store/index";
import { useI18n } from "vue-i18n";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { ButtonColor } from "@/consts/frame";
import AuditSummaryItemModify from "@/components/Database/AuditSummary/RightModify.vue";
import { ElMessageBox } from "element-plus";

const store = useStore();
const { t } = useI18n();
// 当前激活的稽查小结guid
const auditSummaryGuid = ref("");
const searchForm = reactive({
  inputSearch: "",
  dataflag: undefined,
});
const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  addButton: false,
  editButtonLine: false,
  deleteButtonLine: false,
  dataflag: false,
});
// 数据
const tableData = ref([]);
// 列表加载
const tableLoading = ref(false);
// 维护弹窗是否可见
const dialogVisible = ref(false);
// 编辑的数据
const editData = ref({});
// 查询事件
const queryClick = async () => {
  await getAuditSummaryItem();
};
// 重置事件
const resetClick = async () => {
  searchForm.inputSearch = "";
  searchForm.dataflag = undefined;
  await getAuditSummaryItem();
};
// 获取
const getAuditSummaryItem = async (guid) => {
  if (guid) {
    auditSummaryGuid.value = guid;
  }
  const trans = {
    text: searchForm.inputSearch,
    dataflag: searchForm.dataflag,
  };
  tableLoading.value = true;
  const result = await request_auditsummary_item_all(
    auditSummaryGuid.value,
    trans,
    () => (tableLoading.value = false)
  );
  tableData.value = result;
};
// 清空
const clearInfo = () => {
  auditSummaryGuid.value = "";
  tableData.value = [];
  searchForm.inputSearch = "";
  searchForm.dataflag = undefined;
};
// 编辑
const handleEditAuditSummaryItem = (row) => {
  editData.value = {
    guid: row.guid,
    name: row.name,
    sort: row.sort,
    default_value: row.default_value,
  };
  dialogVisible.value = true;
};
// 处理关闭事件
const handleCloseDialog = () => {
  dialogVisible.value = false;
  editData.value = {};
};
// 处理有效性改变事件
const handleDataflagChange = async (flag, row) => {
  const trans = {
    guid: row.guid,
  };
  await request_auditsummary_item_dataflag(
    auditSummaryGuid.value,
    trans,
    true,
    () => {
      row.dataflag = !row.dataflag;
    }
  );
};
// 处理删除菜单事件
const handleDelete = async (row) => {
  ElMessageBox.confirm(
    t("Components.Database.AuditSummary.Item.ConfirmDeleteAuditSummaryItem"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const trans = {
        guid: row.guid,
      };
      await request_auditsummary_item_delete(auditSummaryGuid.value, trans);
      dialogVisible.value = false;
      getAuditSummaryItem();
    })
    .catch(() => {});
};

watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditSummary,
      MenuButtonCodes.Database.AuditSummary.AuditSummaryItemSearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditSummary,
      MenuButtonCodes.Database.AuditSummary.AuditSummaryItemReset
    );
    // 处理新增
    buttonVisible.addButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditSummary,
      MenuButtonCodes.Database.AuditSummary.AuditSummaryItemAdd
    );
    // 处理编辑行
    buttonVisible.editButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditSummary,
      MenuButtonCodes.Database.AuditSummary.AuditSummaryItemEditLine
    );
    // 处理删除行
    buttonVisible.deleteButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditSummary,
      MenuButtonCodes.Database.AuditSummary.AuditSummaryItemDeleteLine
    );
    // 处理有效性
    buttonVisible.dataflag = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditSummary,
      MenuButtonCodes.Database.AuditSummary.AuditSummaryItemDataflag
    );
  }
});
defineExpose({
  getAuditSummaryItem,
  clearInfo,
});
</script>
<style>
.auditsummaryitem-right-li .el-scrollbar__view {
  height: calc(100% - 8px);
}
</style>
<style scoped lang="scss">
.auditsummaryitem-right-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  .hm-table {
    height: calc(100%);
    .el-table {
      height: calc(100%);
    }
  }
}
</style>
