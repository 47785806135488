<template>
  <ul class="menu-root-ul">
    <li class="menu-root-left-li">
      <el-scrollbar>
        <Left
          @handleMenuClick="handleMenuClick"
          @handleClearActiveMenu="handleClearActiveMenu"
        />
      </el-scrollbar>
    </li>
    <li class="menu-root-right-li">
      <el-scrollbar>
        <Right ref="rightRef" />
      </el-scrollbar>
    </li>
  </ul>
</template>
<script setup>
import { ref } from "vue";
import Left from "@/components/System/Menu/LeftIndex.vue";
import Right from "@/components/System/Menu/RightIndex.vue";
// 右侧对象
const rightRef = ref();
// 处理菜单点击事件
const handleMenuClick = async (menuGuid) => {
  rightRef.value.clearInfo();
  // 需要查询右侧菜单按钮的数据
  await rightRef.value.getMenuButtonAll(menuGuid);
};
// 清空右侧数据
const handleClearActiveMenu = () => {
  rightRef.value.clearInfo();
};
</script>
<style scoped lang="scss">
.menu-root-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .menu-root-left-li {
    width: 50%;
    padding-right: 8px;
  }
  .menu-root-right-li {
    padding-left: 8px;
    width: 50%;
  }
}
</style>
