<template>
  <div class="root-div">
    <div class="title-div">
      <!-- <div class="name-div">{{ props.data ? props.data.name : "" }}</div> -->
      <div
        class="name-div"
        @click="handleEditData(ReportAppendixModifyType.Name, props.data? props.data.name: '')"
      >
        <span v-if="!nameIsEdit">{{ props.data ? props.data.name : "" }}</span>
        <div class="dd" v-else>
          <el-input
            v-model="editingName"
            :placeholder="
              t(
                'Components.Project.ProjectTask.Report1.ReportAppendix.PleaseInputName'
              )
            "
          ></el-input>
          <el-space>
            <el-button
              type="primary"
              circle
              size="small"
              :icon="Check"
              @click.stop="handleOK(ReportAppendixModifyType.Name)"
            ></el-button>
            <el-button
              circle
              size="small"
              type="danger"
              :icon="Close"
              @click.stop="handleCancel"
            ></el-button>
          </el-space>
        </div>
      </div>
      <div class="badge-div">
        <div class="dd" v-if="computedCommentCount() > 0">
          <el-badge
            :value="computedCommentCount()"
            type="success"
            class="item"
            v-if="
              props.projectTaskProgress !== ProjectTaskProgress.ToBeAllocated &&
              props.projectTaskProgress !== ProjectTaskProgress.Ready &&
              props.projectTaskProgress !==
                ProjectTaskProgress.AuditImplementation &&
              props.projectTaskProgress !== ProjectTaskProgress.ReportWriting &&
              props.projectTaskProgress !== ProjectTaskProgress.Complete
            "
          >
            <el-badge
              :value="computedNotReadCommentCount()"
              class="notread-comment-count"
              v-if="computedNotReadCommentCount() > 0"
            >
              <el-button
                type="primary"
                size="small"
                circle
                :icon="ChatDotSquare"
                @click="
                  handleCommentClick(
                    '',
                    undefined,
                    ProjectTaskCommentModule.ReportAppendixSingleAll
                  )
                "
              ></el-button>
            </el-badge>
            <el-button
              v-else
              type="primary"
              size="small"
              circle
              :icon="ChatDotSquare"
              @click="
                handleCommentClick(
                  '',
                  undefined,
                  ProjectTaskCommentModule.ReportAppendixSingleAll
                )
              "
            ></el-button>
          </el-badge>
        </div>
        <div class="dd" v-else>
          <el-button
            v-if="
              props.projectTaskProgress !== ProjectTaskProgress.ToBeAllocated &&
              props.projectTaskProgress !== ProjectTaskProgress.Ready &&
              props.projectTaskProgress !==
                ProjectTaskProgress.AuditImplementation &&
              props.projectTaskProgress !== ProjectTaskProgress.ReportWriting &&
              props.projectTaskProgress !== ProjectTaskProgress.Complete
            "
            type="primary"
            size="small"
            circle
            :icon="ChatDotSquare"
            @click="
              handleCommentClick(
                '',
                undefined,
                ProjectTaskCommentModule.ReportAppendixSingleAll
              )
            "
          ></el-button>
        </div>
      </div>
    </div>

    <div class="data-div">
      <el-table
        border
        :data="
          props.data && props.data.report_appendix_details
            ? props.data.report_appendix_details
            : []
        "
      >
        <el-table-column
          :label="
            t(
              'Components.Project.ProjectTask.Report1.ReportAppendix.SerialNumber'
            )
          "
          prop="serial_number"
          :width="!serialNumberIsEdit ? 80 : 200"
        >
          <template #default="scope">
            <div
              :style="{ width: '100%', minWidth: '80px', minHeight: '20px' }"
              @click="
                handleEditData(ReportAppendixModifyType.SerialNumber, scope.row)
              "
            >
              <span
                v-if="
                  !serialNumberIsEdit || appendixDetailGuid !== scope.row.guid
                "
                >{{ scope.row.serial_number }}</span
              >
              <div class="dd1" v-else>
                <el-input
                  v-model="editingSerialNumber"
                  :placeholder="
                    t(
                      'Components.Project.ProjectTask.Report1.ReportAppendix.PleaseInputSerialNumber'
                    )
                  "
                ></el-input>
                <el-space>
                  <el-button
                    type="primary"
                    circle
                    size="small"
                    :icon="Check"
                    @click.stop="
                      handleOK(ReportAppendixModifyType.SerialNumber)
                    "
                  ></el-button>
                  <el-button
                    circle
                    size="small"
                    type="danger"
                    :icon="Close"
                    @click.stop="handleCancel"
                  ></el-button>
                </el-space>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="
            t(
              'Components.Project.ProjectTask.Report1.ReportAppendix.FilterNumber'
            )
          "
          prop="filter_number"
          :width="140"
        >
          <template #default="scope">
            <div
              :style="{ width: '100%', minWidth: '80px', minHeight: '20px' }"
              @click="
                handleEditData(ReportAppendixModifyType.FilterNumber, scope.row)
              "
            >
              <span
                v-if="
                  !filterNumberIsEdit || appendixDetailGuid !== scope.row.guid
                "
                >{{ scope.row.filter_number }}</span
              >
              <div class="dd1" v-else>
                <el-input
                  v-model="editingFilterNumber"
                  :placeholder="
                    t(
                      'Components.Project.ProjectTask.Report1.ReportAppendix.PleaseInputFilterNumber'
                    )
                  "
                ></el-input>
                <el-space>
                  <el-button
                    type="primary"
                    circle
                    size="small"
                    :icon="Check"
                    @click.stop="
                      handleOK(ReportAppendixModifyType.FilterNumber)
                    "
                  ></el-button>
                  <el-button
                    circle
                    size="small"
                    type="danger"
                    :icon="Close"
                    @click.stop="handleCancel"
                  ></el-button>
                </el-space>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="
            t('Components.Project.ProjectTask.Report1.ReportAppendix.Visit')
          "
          prop="visit"
          :width="140"
        >
          <template #default="scope">
            <div
              :style="{ width: '100%', minWidth: '80px', minHeight: '20px' }"
              @click="handleEditData(ReportAppendixModifyType.Visit, scope.row)"
            >
              <span
                v-if="!visitIsEdit || appendixDetailGuid !== scope.row.guid"
                >{{ scope.row.visit }}</span
              >
              <div class="dd1" v-else>
                <el-input
                  v-model="editingVisit"
                  :placeholder="
                    t(
                      'Components.Project.ProjectTask.Report1.ReportAppendix.PleaseInputVisit'
                    )
                  "
                ></el-input>
                <el-space>
                  <el-button
                    type="primary"
                    circle
                    size="small"
                    :icon="Check"
                    @click.stop="handleOK(ReportAppendixModifyType.Visit)"
                  ></el-button>
                  <el-button
                    circle
                    size="small"
                    type="danger"
                    :icon="Close"
                    @click.stop="handleCancel"
                  ></el-button>
                </el-space>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="
            t(
              'Components.Project.ProjectTask.Report1.ReportAppendix.SourceRecord'
            )
          "
          prop="source_record"
        >
          <template #default="scope">
            <div
              :style="{ width: '100%', minWidth: '80px', minHeight: '20px' }"
              @click="
                handleEditData(ReportAppendixModifyType.SourceRecord, scope.row)
              "
            >
              <span
                v-if="
                  !sourceRecordIsEdit || appendixDetailGuid !== scope.row.guid
                "
                >{{ scope.row.source_record }}</span
              >
              <div class="dd1" v-else>
                <el-input
                  v-model="editingSourceRecord"
                  :placeholder="
                    t(
                      'Components.Project.ProjectTask.Report1.ReportAppendix.PleaseInputSourceRecord'
                    )
                  "
                ></el-input>
                <el-space>
                  <el-button
                    type="primary"
                    circle
                    size="small"
                    :icon="Check"
                    @click.stop="
                      handleOK(ReportAppendixModifyType.SourceRecord)
                    "
                  ></el-button>
                  <el-button
                    circle
                    size="small"
                    type="danger"
                    :icon="Close"
                    @click.stop="handleCancel"
                  ></el-button>
                </el-space>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="
            t(
              'Components.Project.ProjectTask.Report1.ReportAppendix.EDCPosition'
            )
          "
          prop="edc_position"
          :width="140"
        >
          <template #default="scope">
            <div
              :style="{ width: '100%', minWidth: '80px', minHeight: '20px' }"
              @click="
                handleEditData(ReportAppendixModifyType.EDCPosition, scope.row)
              "
            >
              <span
                v-if="
                  !edcPositionIsEdit || appendixDetailGuid !== scope.row.guid
                "
                >{{ scope.row.edc_position }}</span
              >
              <div class="dd1" v-else>
                <el-input
                  v-model="editingEDCPosition"
                  :placeholder="
                    t(
                      'Components.Project.ProjectTask.Report1.ReportAppendix.PleaseInputEDCPosition'
                    )
                  "
                ></el-input>
                <el-space>
                  <el-button
                    type="primary"
                    circle
                    size="small"
                    :icon="Check"
                    @click.stop="handleOK(ReportAppendixModifyType.EDCPosition)"
                  ></el-button>
                  <el-button
                    circle
                    size="small"
                    type="danger"
                    :icon="Close"
                    @click.stop="handleCancel"
                  ></el-button>
                </el-space>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="
            t(
              'Components.Project.ProjectTask.Report1.ReportAppendix.EDCContent'
            )
          "
          prop="edc_content"
        >
          <template #default="scope">
            <div
              :style="{ width: '100%', minWidth: '80px', minHeight: '20px' }"
              @click="
                handleEditData(ReportAppendixModifyType.EDCContent, scope.row)
              "
            >
              <span
                v-if="
                  !edcContentIsEdit || appendixDetailGuid !== scope.row.guid
                "
                >{{ scope.row.edc_content }}</span
              >
              <div class="dd1" v-else>
                <el-input
                  v-model="editingEDCContent"
                  :placeholder="
                    t(
                      'Components.Project.ProjectTask.Report1.ReportAppendix.PleaseInputEDCContent'
                    )
                  "
                ></el-input>
                <el-space>
                  <el-button
                    type="primary"
                    circle
                    size="small"
                    :icon="Check"
                    @click.stop="handleOK(ReportAppendixModifyType.EDCContent)"
                  ></el-button>
                  <el-button
                    circle
                    size="small"
                    type="danger"
                    :icon="Close"
                    @click.stop="handleCancel"
                  ></el-button>
                </el-space>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="" prop="edc_content" width="50">
          <template #default="scope">
            <div class="badge-div">
              <div v-if="computedCommentCount(scope.row.guid) > 0">
                <el-badge
                  :value="computedCommentCount(scope.row.guid)"
                  class="item"
                  type="success"
                  v-if="
                    props.projectTaskProgress !==
                      ProjectTaskProgress.ToBeAllocated &&
                    props.projectTaskProgress !== ProjectTaskProgress.Ready &&
                    props.projectTaskProgress !==
                      ProjectTaskProgress.AuditImplementation &&
                    props.projectTaskProgress !==
                      ProjectTaskProgress.ReportWriting &&
                    props.projectTaskProgress !== ProjectTaskProgress.Complete
                  "
                >
                  <el-badge
                    :value="computedNotReadCommentCount(scope.row.guid)"
                    class="notread-comment-count"
                    v-if="computedNotReadCommentCount(scope.row.guid) > 0"
                  >
                    <el-button
                      type="primary"
                      size="small"
                      circle
                      :icon="ChatDotSquare"
                      @click="
                        handleCommentClick(
                          scope.row.guid,
                          scope.row.sort,
                          ProjectTaskCommentModule.ReportAppendixDetail
                        )
                      "
                    ></el-button>
                  </el-badge>
                  <el-button
                    v-else
                    type="primary"
                    size="small"
                    circle
                    :icon="ChatDotSquare"
                    @click="
                      handleCommentClick(
                        scope.row.guid,
                        scope.row.sort,
                        ProjectTaskCommentModule.ReportAppendixDetail
                      )
                    "
                  ></el-button>
                </el-badge>
              </div>
              <div v-else>
                <el-button
                  v-if="
                    props.projectTaskProgress !==
                      ProjectTaskProgress.ToBeAllocated &&
                    props.projectTaskProgress !== ProjectTaskProgress.Ready &&
                    props.projectTaskProgress !==
                      ProjectTaskProgress.AuditImplementation &&
                    props.projectTaskProgress !==
                      ProjectTaskProgress.ReportWriting &&
                    props.projectTaskProgress !== ProjectTaskProgress.Complete
                  "
                  type="primary"
                  size="small"
                  circle
                  :icon="ChatDotSquare"
                  @click="
                    handleCommentClick(
                      scope.row.guid,
                      scope.row.sort,
                      ProjectTaskCommentModule.ReportAppendixDetail
                    )
                  "
                ></el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script setup>
import { defineProps, watchEffect, computed, ref, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import {
  ProjectTaskProgress,
  ProjectTaskCommentModule,
  ReportAppendixModifyType,
} from "@/consts/enum";
import { ChatDotSquare, Check, Close } from "@element-plus/icons-vue";
import pubsub from "pubsub-js";
import PubsubMessage from "@/consts/pubsubMessage";
import { request_projecttask_report_appendix_update } from "@/api/apis";

const { t } = useI18n();
const emits = defineEmits(["getProjectTask"]);

const nameIsEdit = ref(false); // 标题是否编辑
const editingName = ref(""); // 标题编辑中的数据
const sourceName = ref(""); // 原始标题数据

const serialNumberIsEdit = ref(false); // 序号是否编辑
const editingSerialNumber = ref(""); // 序号编辑中的数据
const sourceSerialNumber = ref(""); // 原始序号数据

const filterNumberIsEdit = ref(false); // 筛选号是否编辑
const editingFilterNumber = ref(""); // 筛选号编辑中的数据
const sourceFilterNumber = ref(""); // 筛选号原始数据

const visitIsEdit = ref(false); // 访视是否编辑
const editingVisit = ref(""); // 访视编辑中的数据
const sourceVisit = ref(""); // 访视原始数据

const sourceRecordIsEdit = ref(false); // 原始内容是否编辑
const editingSourceRecord = ref(""); // 原始内容编辑中的数据
const sourceSourceRecord = ref(""); // 原始内容原始数据

const edcPositionIsEdit = ref(false); // edc位置是否编辑
const editingEDCPosition = ref(""); // edc位置编辑中的数据
const sourceEDCPosition = ref(""); // edc位置原始数据

const edcContentIsEdit = ref(false); // edc内容是否编辑
const editingEDCContent = ref(""); // edc内容编辑中的数据
const sourceEDCContent = ref(""); // edc内容原始数据

const props = defineProps({
  data: {
    type: Object,
    default: () => undefined,
  },
  projectTaskProgress: {
    type: Number,
    default: () => 0,
  },
  commentModuleCount: {
    type: Array,
    default: () => [],
  },
  canReview: {
    type: Boolean,
    default: () => false,
  },
});

const projectTaskGuid = props.data ? props.data.project_task_guid : "";
// 当前操作的附件详情guid
const appendixDetailGuid = ref();

// 是否可以编辑
const computedCanEdit = computed(() => {
  return (
    props.projectTaskProgress === ProjectTaskProgress.ReportWriting ||
    props.projectTaskProgress === ProjectTaskProgress.Rewrite
  );
});

// 计算模块评论数量
const computedCommentCount = computed(() => (reprotAppendixDetailGuid) => {
  if (props.commentModuleCount && props.commentModuleCount.length > 0) {
    const findCommentModuleCountData = props.commentModuleCount.filter(
      (item) =>
        item.module === ProjectTaskCommentModule.ReportAppendixDetail &&
        item.relate_guid === props.data.guid &&
        (reprotAppendixDetailGuid
          ? item.appendix_detail_guid === reprotAppendixDetailGuid
          : true)
    );
    if (findCommentModuleCountData && findCommentModuleCountData.length > 0) {
      let count = 0;
      findCommentModuleCountData.forEach((item) => {
        count += item.count;
      });
      return count;
    }
  }
  return 0;
});
const computedNotReadCommentCount = computed(
  () => (reprotAppendixDetailGuid) => {
    if (props.commentModuleCount && props.commentModuleCount.length > 0) {
      const findCommentModuleCountData = props.commentModuleCount.filter(
        (item) =>
          item.module === ProjectTaskCommentModule.ReportAppendixDetail &&
          item.relate_guid === props.data.guid &&
          (reprotAppendixDetailGuid
            ? item.appendix_detail_guid === reprotAppendixDetailGuid
            : true)
      );
      if (findCommentModuleCountData && findCommentModuleCountData.length > 0) {
        let count = 0;
        findCommentModuleCountData.forEach((item) => {
          if (props.canReview) count += item.not_read_count_for_approver;
          else count += item.not_read_count;

          // count += item.not_read_count;
        });
        return count;
      }
    }
    return 0;
  }
);
// 评论点击
const handleCommentClick = (subRelateGuid, subTitle, module) => {
  let title =
    t("Components.Project.ProjectTask.Report1.ReportAppendix.Title") +
    "→" +
    props.data.name;
  if (subTitle) {
    title +=
      "→ " +
      t("Components.Project.ProjectTask.Report1.ReportAppendix.SerialNumber") +
      subTitle;
  }
  const data = {
    projectTaskProgress: props.projectTaskProgress,
    module: module,
    title,
    relateGuid: props.data.guid,
    appendixDetailGuid: subRelateGuid,
  };
  pubsub.publish(PubsubMessage.ProjectTaskReportComment, data);
};
// 处理编辑数据
const handleEditData = (type, data) => {
  if (!computedCanEdit.value) return;

  // 先将所有的关闭
  handleCancel();
  if (type === ReportAppendixModifyType.Name) {
    // 表头名称
    nameIsEdit.value = true;
    sourceName.value = data
    editingName.value = data;
  } else {
    appendixDetailGuid.value = data.guid;
    if (type === ReportAppendixModifyType.SerialNumber) {
      // 序号
      serialNumberIsEdit.value = true;
      editingSerialNumber.value = data ? data.serial_number : "";
    } else if (type == ReportAppendixModifyType.FilterNumber) {
      // 筛选号
      filterNumberIsEdit.value = true;
      editingFilterNumber.value = data ? data.filter_number : "";
    } else if (type === ReportAppendixModifyType.Visit) {
      // 访视
      visitIsEdit.value = true;
      editingVisit.value = data ? data.visit : "";
    } else if (type === ReportAppendixModifyType.SourceRecord) {
      // 原始记录
      sourceRecordIsEdit.value = true;
      editingSourceRecord.value = data ? data.source_record : "";
    } else if (type === ReportAppendixModifyType.EDCPosition) {
      // EDC位置
      edcPositionIsEdit.value = true;
      editingEDCPosition.value = data ? data.edc_position : "";
    } else if (type === ReportAppendixModifyType.EDCContent) {
      // EDC内容
      edcContentIsEdit.value = true;
      editingEDCContent.value = data ? data.edc_content : "";
    }
  }
};
// 保存
const handleOK = async (type) => {
  let text = "";
  if (type === ReportAppendixModifyType.Name) {
    text = editingName.value;
  } else if (type === ReportAppendixModifyType.SerialNumber) {
    text = editingSerialNumber.value;
  } else if (type === ReportAppendixModifyType.FilterNumber) {
    text = editingFilterNumber.value;
  } else if (type === ReportAppendixModifyType.Visit) {
    text = editingVisit.value;
  } else if (type === ReportAppendixModifyType.SourceRecord) {
    text = editingSourceRecord.value;
  } else if (type === ReportAppendixModifyType.EDCPosition) {
    text = editingEDCPosition.value;
  } else if (type === ReportAppendixModifyType.EDCContent) {
    text = editingEDCContent.value;
  }
  const trans = {
    appendix_guid: props.data ? props.data.guid : "",
    appendix_detail_guid: appendixDetailGuid.value,
    text: text,
    type: type,
  };
  await request_projecttask_report_appendix_update(projectTaskGuid, trans);

  editingName.value = "";
  nameIsEdit.value = false;

  editingSerialNumber.value = "";
  serialNumberIsEdit.value = false;

  editingFilterNumber.value = "";
  filterNumberIsEdit.value = false;

  editingVisit.value = "";
  visitIsEdit.value = false;

  editingSourceRecord.value = "";
  sourceRecordIsEdit.value = false;

  editingEDCPosition.value = "";
  edcPositionIsEdit.value = false;

  editingEDCContent.value = "";
  edcContentIsEdit.value = false;

  // 通知更新数据
  emits("getProjectTask");
};
// 取消编辑
const handleCancel = () => {
  nameIsEdit.value = false;
  sourceName.value = editingName.value;
  editingName.value = "";

  serialNumberIsEdit.value = false;
  sourceSerialNumber.value = editingSerialNumber.value;
  editingSerialNumber.value = "";

  filterNumberIsEdit.value = false;
  sourceFilterNumber.value = editingFilterNumber.value;
  editingFilterNumber.value = "";

  visitIsEdit.value = false;
  sourceVisit.value = editingVisit.value;
  editingVisit.value = "";

  sourceRecordIsEdit.value = false;
  sourceSourceRecord.value = editingSourceRecord.value;
  editingSourceRecord.value = "";

  edcPositionIsEdit.value = false;
  sourceEDCPosition.value = editingEDCPosition.value;
  editingEDCPosition.value = "";

  edcContentIsEdit.value = false;
  sourceEDCContent.value = editingEDCContent.value;
  editingEDCContent.value = "";

  appendixDetailGuid.value = "";
};

watchEffect(() => {
  if (props.data) {
    sourceName.value = props.data.name;
  }
});
</script>
<style scoped lang="scss">
.notread-comment-count {
  ::v-deep .el-badge__content {
    margin-top: 20px;
    font-size: 0.8em;
  }
}
.root-div {
  margin-bottom: 16px;
  .title-div {
    display: flex;
    align-items: center;
    .name-div {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .badge-div {
      width: 50px;
      display: flex;
      justify-content: center;
    }
  }
  .data-div {
    margin-top: 4px;
  }
}
.item {
  margin-top: 10px;
}
.dd {
  display: flex;
  align-items: center;
  .el-space {
    margin-left: 4px;
  }
}
.dd1 {
  align-items: center;
  .el-space {
    margin-left: 4px;
  }
}
</style>
