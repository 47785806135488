<template>
  <Dialog
  class="my-dialog"
    width="60%"
    :showFooter="false"
    @handleCancle="handleCancle"
  >
  <div class="main-announcement">
    <div class="div-title">{{  props.announcementData ? props.announcementData.title: ''  }}</div>
    <div class="div-time">{{  props.announcementData ? props.announcementData.createtime : '' }}</div>
    <div class="div-content" v-html="content"></div>
  </div>
  </Dialog>
</template>
<script setup>
import { ref, onMounted, defineEmits,defineProps } from "vue";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import {request_announcement_content} from '@/api/apis'

const emits = defineEmits(['handleCloseDialog'])
const props = defineProps({
  announcementData: {
    type: Object,
    default: ()=>undefined
  },
})
const content = ref('')
onMounted(async () => {
  getAnnouncementContent()
});

// 获取通告详情
const getAnnouncementContent = async () => {
  if (props.announcementData) {
    const result = await request_announcement_content(props.announcementData.guid);
    content.value = result;
  }
};

// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};
</script>
<style>

.my-dialog .el-dialog__body{
  padding-top: 10px;
}
</style>
<style scoped lang="scss">
.main-announcement{
  min-height: 300px;
  .div-title{
    height: 46px;
    font-size: 1.5em;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .div-time{
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
  .div-content{
    margin-top: 20px;
  }
}
</style>
