<template>
  <ul class="projectprogress-ul" v-loading="fullLoading">
    <li class="count-li">
      <ul class="count-root-ul">
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left all">
              <el-icon>
                <i class="iconfont icon-all"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">
                {{
                  countData && countData.totalcount ? countData.totalcount : 0
                }}
              </div>
              <div class="bottom-div">
                {{ t("Components.Progress.ReportAndCAPA.TotalHyperWindow") }}
              </div>
            </li>
          </ul>
        </li>
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left tobeallowcate">
              <el-icon>
                <i class="iconfont icon-tobeallowcate"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">
                {{
                  countData && countData.report_hyperwindow_count
                    ? countData.report_hyperwindow_count
                    : 0
                }}
              </div>
              <div class="bottom-div">
                {{ t("Components.Progress.ReportAndCAPA.ReportHyperWindow") }}
              </div>
            </li>
          </ul>
        </li>
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left ready">
              <el-icon>
                <i class="iconfont icon-ready"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">
                {{
                  countData && countData.capa_hyperwindow_count
                    ? countData.capa_hyperwindow_count
                    : 0
                }}
              </div>
              <div class="bottom-div">
                {{ t("Components.Progress.ReportAndCAPA.CAPAHyperWindow") }}
              </div>
            </li>
          </ul>
        </li>
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left ready">
              <el-icon>
                <i class="iconfont icon-ready"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">
                {{
                  countData && countData.report_hyperwindow_days_count
                    ? countData.report_hyperwindow_days_count
                    : 0
                }}
              </div>
              <div class="bottom-div">
                {{
                  t("Components.Progress.ReportAndCAPA.ReportHyperWindowBefore")
                }}
              </div>
            </li>
          </ul>
        </li>
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left ready">
              <el-icon>
                <i class="iconfont icon-ready"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">
                {{
                  countData && countData.capa_hyperwindow_days_count
                    ? countData.capa_hyperwindow_days_count
                    : 0
                }}
              </div>
              <div class="bottom-div">
                {{
                  t("Components.Progress.ReportAndCAPA.CAPAHyperWindowBefore")
                }}
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </li>
    <li class="hm-search">
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm" :label-width="100">
        <el-form-item
          :label="$t('Components.Progress.ReportAndCAPA.ProjectTaskNumber')"
        >
          <el-input
            v-model="searchForm.project_task_number"
            :placeholder="
              $t(
                'Components.Progress.ReportAndCAPA.PleaseInputProjectTaskNumber'
              )
            "
            clearable
          />
        </el-form-item>
        <el-form-item :label="$t('Components.Progress.ReportAndCAPA.Progress')">
          <el-select
            v-model="searchForm.progress"
            :placeholder="
              $t('Components.Progress.ReportAndCAPA.PleaseSelectProgress')
            "
            clearable
          >
            <el-option
              v-for="item in projectTaskProgresses"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('Components.Progress.ReportAndCAPA.AuditedParty')"
        >
          <el-select
            v-model="searchForm.audited_party_guid"
            :placeholder="
              $t('Components.Progress.ReportAndCAPA.PleaseSelectAuditedParty')
            "
            clearable
          >
            <el-option
              v-for="item in auditedPartys"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('Components.Progress.ReportAndCAPA.MainAuditor')"
        >
          <el-select
            v-model="searchForm.main_audit_user"
            :placeholder="
              $t('Components.Progress.ReportAndCAPA.PleaseSelectMainAuditor')
            "
            clearable
          >
            <el-option
              v-for="item in users"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('Components.Progress.ReportAndCAPA.Auditor')">
          <el-select
            v-model="searchForm.audit_user"
            :placeholder="
              $t('Components.Progress.ReportAndCAPA.PleaseSelectAuditor')
            "
            clearable
          >
            <el-option
              v-for="item in users"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('Components.Progress.ReportAndCAPA.ReprotHyperWindow')"
        >
          <el-select
            v-model="searchForm.report_hyperwindow_type"
            :placeholder="
              $t(
                'Components.Progress.ReportAndCAPA.PleaseSelectReportIsHyperWindow'
              )
            "
            clearable
            @change="(value)=> reportHyperWindowDaysShow = value === ReportAndCAPAHyperWindowType.HyperWindow"
          >
            <el-option
              v-for="item in hyperWindowsReport"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('Components.Progress.ReportAndCAPA.ReportHyperWindowDays')"
          v-if="reportHyperWindowDaysShow"
        >
          <el-input-number
            v-model="searchForm.report_hyperwindow_days_start"
            :min="0"
            :placeholder="
              $t(
                'Components.Progress.ReportAndCAPA.StartDays'
              )
            "
            clearable
          />
          <span :style="{marginLeft: '4px', marginRight: '4px'}">至</span>
          <el-input-number
            v-model="searchForm.report_hyperwindow_days_end"
            :min="0"
            :placeholder="
              $t(
                'Components.Progress.ReportAndCAPA.EndDays'
              )
            "
            clearable
          />
        </el-form-item>
        <el-form-item
          :label="$t('Components.Progress.ReportAndCAPA.CAPAHyperWindow')"
        >
          <el-select
            v-model="searchForm.capa_hyperwindow_type"
            :placeholder="
              $t(
                'Components.Progress.ReportAndCAPA.PleaseSelectCAPAIsHyperWindow'
              )
            "
            clearable
            @change="(value)=> capaHyperWindowDaysShow = value === ReportAndCAPAHyperWindowType.HyperWindow"
          >
            <el-option
              v-for="item in hyperWindowsCAPA"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="capaHyperWindowDaysShow"
          :label="$t('Components.Progress.ReportAndCAPA.CAPAHyperWindowDays')"
        >
          <el-input-number
            v-model="searchForm.capa_hyperwindow_days_start"
            :min="0"
            :placeholder="
              $t(
                'Components.Progress.ReportAndCAPA.StartDays'
              )
            "
            clearable
          />
          <span :style="{marginLeft: '4px', marginRight: '4px'}">至</span>
          <el-input-number
            v-model="searchForm.capa_hyperwindow_days_end"
            :min="0"
            :placeholder="
              $t(
                'Components.Progress.ReportAndCAPA.EndDays'
              )
            "
            clearable
          />
        </el-form-item>
        <el-form-item
          :label="$t('Components.Progress.ReportAndCAPA.PlannedAuditTime')"
        >
          <el-date-picker
            v-model="searchForm.audit_time"
            type="daterange"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            range-separator=" - "
            :start-placeholder="
              t('Components.Progress.ReportAndCAPA.PleaseSelectStartDate')
            "
            :end-placeholder="
              t('Components.Progress.ReportAndCAPA.PleaseSelectEndDate')
            "
          />
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="buttonVisible.searchButton"
            type="primary"
            @click="queryClick"
            :icon="Search"
            >{{ $t("Components.Progress.Project.Search") }}</el-button
          >
          <el-button
            v-if="buttonVisible.resetButton"
            @click="resetClick"
            :icon="RefreshLeft"
            >{{ $t("Components.Progress.Project.Reset") }}</el-button
          >
        </el-form-item>
      </el-form>
    </li>
    <li class="hm-operate">
      <!-- 操作部分 -->
      <el-button
        v-if="buttonVisible.exportButton"
        :style="{ color: 'white' }"
        :icon="Download"
        :color="ButtonColor.Export"
        @click="handleExport"
        >{{ $t("Components.Progress.ReportAndCAPA.Export") }}</el-button
      >
    </li>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          row-key="guid"
          @sort-change="handleSortChange"
        >
          <el-table-column
            prop="project_task_number"
            :label="$t('Components.Progress.ReportAndCAPA.ProjectTaskNumber')"
            width="200"
            sortable
          />
          <el-table-column
            prop="progress"
            :label="$t('Components.Progress.ReportAndCAPA.Progress')"
            width="100"
          >
            <template #default="scope">
              <el-text
                v-if="scope.row.progress === ProjectTaskProgress.ToBeAllocated"
                >{{
                  t("Components.Project.ProjectTask.ToBeAllocated")
                }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectTaskProgress.Ready"
                >{{ t("Components.Project.ProjectTask.Ready") }}</el-text
              >
              <el-text
                v-else-if="
                  scope.row.progress === ProjectTaskProgress.AuditImplementation
                "
                >{{
                  t("Components.Project.ProjectTask.AuditImplementation")
                }}</el-text
              >
              <el-text
                v-else-if="
                  scope.row.progress === ProjectTaskProgress.ReportWriting
                "
                >{{
                  t("Components.Project.ProjectTask.ReportWriting")
                }}</el-text
              >
              <el-text
                v-else-if="
                  scope.row.progress === ProjectTaskProgress.SubmitForReview
                "
                >{{
                  t("Components.Project.ProjectTask.SubmitForReview")
                }}</el-text
              >
              <el-text
                v-else-if="
                  scope.row.progress === ProjectTaskProgress.UnderReview
                "
                >{{ t("Components.Project.ProjectTask.UnderReview") }}</el-text
              >
              <el-text
                v-else-if="
                  scope.row.progress === ProjectTaskProgress.ReviewedAndApproved
                "
                >{{
                  t("Components.Project.ProjectTask.ReviewedAndApproved")
                }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectTaskProgress.Cancel"
                >{{ t("Components.Project.ProjectTask.Cancel") }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectTaskProgress.Complete"
                >{{ t("Components.Project.ProjectTask.Complete") }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectTaskProgress.Rewrite"
                >{{ t("Components.Project.ProjectTask.Rewrite") }}</el-text
              >
              <el-text
                v-else-if="
                  scope.row.progress === ProjectTaskProgress.CAPAFinalize
                "
                >{{ t("Components.Project.ProjectTask.CAPAFinalize") }}</el-text
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="audited_party_name"
            :label="$t('Components.Progress.ReportAndCAPA.AuditedPartyName')"
            width="160"
          />
          <el-table-column
            prop="main_audit_user_name"
            :label="$t('Components.Progress.ReportAndCAPA.MainAuditor')"
            width="120"
          >
            <template #default="scope">
              <el-tag
                v-if="scope.row.main_audit_user_name"
                :style="{ margin: '0 8px 8px 0' }"
                >{{ scope.row.main_audit_user_name }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="audit_users"
            :label="$t('Components.Progress.ReportAndCAPA.Auditor')"
            width="160"
          >
            <template #default="scope">
              <el-tag
                v-for="item in scope.row.audit_users"
                :key="item.user_guid"
                :style="{ margin: '0 8px 8px 0' }"
                >{{ item.user_name }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="audit_address"
            :label="$t('Components.Progress.ReportAndCAPA.AuditAddress')"
            width="160"
          />
          <el-table-column
            prop="planned_audit_starttime"
            :label="$t('Components.Progress.ReportAndCAPA.PlanAuditStartTime')"
            width="160"
            sortable
          >
            <template #default="scope">
              <el-text v-if="scope.row.planned_audit_starttime">{{
                formatTime(new Date(scope.row.planned_audit_starttime))
              }}</el-text>
            </template>
          </el-table-column>
          <el-table-column
            prop="planned_audit_endtime"
            :label="$t('Components.Progress.ReportAndCAPA.PlanAuditEndTime')"
            width="160"
            sortable
          >
            <template #default="scope">
              <el-text v-if="scope.row.planned_audit_endtime">{{
                formatTime(new Date(scope.row.planned_audit_endtime))
              }}</el-text>
            </template>
          </el-table-column>

          <el-table-column
            prop="actual_audit_starttime"
            :label="
              $t('Components.Progress.ReportAndCAPA.ActualAuditStartTime')
            "
            width="160"
            sortable
          >
            <template #default="scope">
              <el-text v-if="scope.row.actual_audit_starttime">{{
                formatTime(new Date(scope.row.actual_audit_starttime))
              }}</el-text>
            </template>
          </el-table-column>
          <el-table-column
            prop="actual_audit_endtime"
            :label="$t('Components.Progress.ReportAndCAPA.ActualAuditEndTime')"
            width="160"
            sortable
          >
            <template #default="scope">
              <el-text v-if="scope.row.actual_audit_endtime">{{
                formatTime(new Date(scope.row.actual_audit_endtime))
              }}</el-text>
            </template>
          </el-table-column>

          <el-table-column
            prop="planned_reporting_date"
            :label="$t('Components.Progress.ReportAndCAPA.PlanReportTime')"
            width="160"
            sortable
          >
            <template #default="scope">
              <el-text v-if="scope.row.planned_reporting_date">{{
                formatTime(new Date(scope.row.planned_reporting_date))
              }}</el-text>
            </template>
          </el-table-column>
          <el-table-column
            prop="completed_reporting_date"
            :label="$t('Components.Progress.ReportAndCAPA.CompleteReportTime')"
            width="160"
            sortable
          >
            <template #default="scope">
              <el-text v-if="scope.row.completed_reporting_date">{{
                formatTime(new Date(scope.row.completed_reporting_date))
              }}</el-text>
            </template>
          </el-table-column>

          <el-table-column
            prop="planned_capa_date"
            :label="$t('Components.Progress.ReportAndCAPA.PlanCAPATime')"
            width="160"
            sortable
          >
            <template #default="scope">
              <el-text v-if="scope.row.planned_capa_date">{{
                formatTime(new Date(scope.row.planned_capa_date))
              }}</el-text>
            </template>
          </el-table-column>
          <el-table-column
            prop="actual_capa_date"
            :label="$t('Components.Progress.ReportAndCAPA.ActualCAPATtime')"
            width="160"
            sortable
          >
            <template #default="scope">
              <el-text v-if="scope.row.actual_capa_date">{{
                formatTime(new Date(scope.row.actual_capa_date))
              }}</el-text>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            prop="report_hyperwindow_days"
            :label="
              $t('Components.Progress.ReportAndCAPA.ReprotHyperWindowDays')
            "
            width="120"
          />
          <el-table-column
            fixed="right"
            prop="capa_hyperwindow_days"
            :label="$t('Components.Progress.ReportAndCAPA.CAPAHyperWindowDays')"
            width="120"
          />
        </el-table>
      </li>
    </el-scrollbar>

    <li class="hm-page">
      <el-pagination
        small
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="AllPageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </li>
  </ul>
</template>
<script setup>
import { reactive, ref, onMounted, watchEffect } from "vue";
import { RefreshLeft, Search, Download } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import { AllPageSize, ButtonColor, DefaultPageSize } from "@/consts/frame";
import {
  request_reportcapaprogress_export,
  request_auditedparty_page,
  request_reportcapaprogress_page,
  request_user_page,
  request_reportcapaprogress_count,
} from "@/api/apis";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";
import { useStore } from "@/store/index";
import {
  ProjectTaskProgress,
  ReportAndCAPAHyperWindowType,
} from "@/consts/enum";
import { formatTime } from "@/util/timeUtil";
import { useRoute } from "vue-router";

const store = useStore();
const { t } = useI18n();
const route = useRoute();
const _type = (route.query && route.query.type) ?? "";
const searchForm = reactive({
  project_task_number: "",
  progress: undefined,
  audited_party_guid: "",
  main_audit_user: "",
  audit_user: "",
  report_hyperwindow_type: _type && _type == 1 ? 2 : undefined,
  capa_hyperwindow_type: _type && _type == 2 ? 2 : undefined,
  report_hyperwindow_days_start: undefined,
  report_hyperwindow_days_end: undefined,
  capa_hyperwindow_days_start: undefined,
  capa_hyperwindow_days_end: undefined,
  audit_time: undefined, // 时间区间
});
const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  exportButton: false,
});
// 当前页码
const currentPage = ref(1);
// 页码大小
const pageSize = ref(DefaultPageSize);
// 表格数据
const tableData = ref([]);
// 表格加载
const tableLoading = ref(false);
// 总数量
const total = ref(0);
// 全屏加载
const fullLoading = ref(false);

const countData = ref();

// 项目任务进度
const projectTaskProgresses = ref([
  {
    label: t("Components.Project.ProjectTask.ToBeAllocated"),
    value: ProjectTaskProgress.ToBeAllocated,
  },
  {
    label: t("Components.Project.ProjectTask.Ready"),
    value: ProjectTaskProgress.Ready,
  },
  {
    label: t("Components.Project.ProjectTask.AuditImplementation"),
    value: ProjectTaskProgress.AuditImplementation,
  },
  {
    label: t("Components.Project.ProjectTask.ReportWriting"),
    value: ProjectTaskProgress.ReportWriting,
  },
  {
    label: t("Components.Project.ProjectTask.SubmitForReview"),
    value: ProjectTaskProgress.SubmitForReview,
  },
  {
    label: t("Components.Project.ProjectTask.UnderReview"),
    value: ProjectTaskProgress.UnderReview,
  },
  {
    label: t("Components.Project.ProjectTask.ReviewedAndApproved"),
    value: ProjectTaskProgress.ReviewedAndApproved,
  },
  {
    label: t("Components.Project.ProjectTask.Cancel"),
    value: ProjectTaskProgress.Cancel,
  },
  {
    label: t("Components.Project.ProjectTask.Complete"),
    value: ProjectTaskProgress.Complete,
  },
  {
    label: t("Components.Project.ProjectTask.Rewrite"),
    value: ProjectTaskProgress.Rewrite,
  },
  {
    label: t("Components.Project.ProjectTask.CAPAFinalize"),
    value: ProjectTaskProgress.CAPAFinalize,
  },
]);

const auditedPartys = ref([]);
const users = ref([]);

const projectTaskNumberSort = ref(undefined);
const planAuditStartTimeSort = ref(undefined);
const planAuditEndTimeSort = ref(undefined);
const actualAuditStartTimeSort = ref(undefined);
const actualAuditEndTimeSort = ref(undefined);
const plannedReportingTimeSort = ref(undefined);
const completedReportingTimeSort = ref(undefined);
const planCAPATimeSort = ref(undefined);
const actualCAPATimeSort = ref(undefined);

const hyperWindowsReport = ref([
  {
    label: t("Components.Progress.ReportAndCAPA.NotHyperWindow"),
    value: ReportAndCAPAHyperWindowType.NotHyperWindow,
  },
  {
    label: t("Components.Progress.ReportAndCAPA.HyperWindow"),
    value: ReportAndCAPAHyperWindowType.HyperWindow,
  },
  {
    label: t("Components.Progress.ReportAndCAPA.HyperWindow3Days"),
    value: -1,
  },
]);
const hyperWindowsCAPA = ref([
  {
    label: t("Components.Progress.ReportAndCAPA.NotHyperWindow"),
    value: ReportAndCAPAHyperWindowType.NotHyperWindow,
  },
  {
    label: t("Components.Progress.ReportAndCAPA.HyperWindow"),
    value: ReportAndCAPAHyperWindowType.HyperWindow,
  },
  {
    label: t("Components.Progress.ReportAndCAPA.HyperWindow7Days"),
    value: -1,
  },
]);

const reportHyperWindowDaysShow = ref(false);
const capaHyperWindowDaysShow = ref(false);
onMounted(async () => {
  getUserAll();
  getAuditedPartyAll();
  getProgressProjectCount();
  await getProgressReportCAPAPage();
});
// 获取所有被稽查方
const getAuditedPartyAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
  };
  const result = await request_auditedparty_page(trans);
  if (result.total > 0) {
    auditedPartys.value = result.rows;
  } else {
    auditedPartys.value = [];
  }
};
// 获取所有用户
const getUserAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
    is_system: false,
  };
  const result = await request_user_page(trans);
  if (result.total > 0) {
    users.value = result.rows;
  } else {
    users.value = [];
  }
};
// 分页查询
const getProgressReportCAPAPage = async () => {
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    progress: searchForm.progress,
    project_task_number: searchForm.project_task_number,
    audited_party_guid: searchForm.audited_party_guid,
    main_audit_user: searchForm.main_audit_user,
    audit_user: searchForm.audit_user,

    report_hyperwindow_type:
      searchForm.report_hyperwindow_type === -1
        ? ReportAndCAPAHyperWindowType.HyperWindow
        : searchForm.report_hyperwindow_type,
    report_hyperwindow_days_before:
      searchForm.report_hyperwindow_type !== -1 ? undefined : 3,
    capa_hyperwindow_type:
      searchForm.capa_hyperwindow_type === -1
        ? ReportAndCAPAHyperWindowType.HyperWindow
        : searchForm.capa_hyperwindow_type,
    capa_hyperwindow_days_before:
      searchForm.capa_hyperwindow_type !== -1 ? undefined : 7,

    start_time:
      searchForm.audit_time && searchForm.audit_time.length > 0
        ? searchForm.audit_time[0]
        : undefined,
    end_time:
      searchForm.audit_time && searchForm.audit_time.length > 1
        ? searchForm.audit_time[1]
        : undefined,
    ProjectTaskNumberSort: projectTaskNumberSort.value,

    PlanAuditStartTimeSort: planAuditStartTimeSort.value,
    PlanAuditEndTimeSort: planAuditEndTimeSort.value,
    ActualAuditStartTimeSort: actualAuditStartTimeSort.value,
    ActualAuditEndTimeSort: actualAuditEndTimeSort.value,
    PlanReportTimeSort: plannedReportingTimeSort.value,
    CompleteReportTimeSort: completedReportingTimeSort.value,
    PlanCAPATimeSort: planCAPATimeSort.value,
    ActualCAPATimeSort: actualCAPATimeSort.value,
    report_hyperwindow_days_start: searchForm.report_hyperwindow_days_start,
    report_hyperwindow_days_end: searchForm.report_hyperwindow_days_end,
    capa_hyperwindow_days_start: searchForm.capa_hyperwindow_days_start,
    capa_hyperwindow_days_end: searchForm.capa_hyperwindow_days_end
  };
  tableLoading.value = true;
  const result = await request_reportcapaprogress_page(
    trans,
    () => (tableLoading.value = false)
  );
  total.value = result.total;
  tableData.value = result.rows;
};
// 获取进度数量
const getProgressProjectCount = async () => {
  const result = await request_reportcapaprogress_count();
  countData.value = result;
};

// 查询事件
const queryClick = () => {
  getProgressReportCAPAPage();
};
// 重置事件
const resetClick = () => {
  searchForm.project_task_number = "";
  searchForm.progress = undefined;
  searchForm.audited_party_guid = "";
  searchForm.main_audit_user = "";
  searchForm.audit_user = "";

  searchForm.report_hyperwindow_type = undefined;
  searchForm.capa_hyperwindow_type = undefined;

  searchForm.audit_time = undefined;
  searchForm.report_hyperwindow_days_start = undefined
  searchForm.report_hyperwindow_days_end = undefined
  searchForm.capa_hyperwindow_days_start = undefined
  searchForm.capa_hyperwindow_days_end = undefined

  reportHyperWindowDaysShow.value = false
  capaHyperWindowDaysShow.value = false

  getProgressReportCAPAPage();
};
// 处理页码大小改变事件
const handleSizeChange = (size) => {
  pageSize.value = size;
  getProgressReportCAPAPage();
};
// 处理当前页改变事件
const handleCurrentChange = (index) => {
  currentPage.value = index;
  getProgressReportCAPAPage();
};
// 导出
const handleExport = async () => {
  fullLoading.value = true;
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    progress: searchForm.progress,
    project_task_number: searchForm.project_task_number,
    audited_party_guid: searchForm.audited_party_guid,
    main_audit_user: searchForm.main_audit_user,
    audit_user: searchForm.audit_user,

    report_hyperwindow_type:
      searchForm.report_hyperwindow_type === -1
        ? ReportAndCAPAHyperWindowType.HyperWindow
        : searchForm.report_hyperwindow_type,
    report_hyperwindow_days_before:
      searchForm.report_hyperwindow_type !== -1 ? undefined : 3,
    capa_hyperwindow_type:
      searchForm.capa_hyperwindow_type === -1
        ? ReportAndCAPAHyperWindowType.HyperWindow
        : searchForm.capa_hyperwindow_type,
    capa_hyperwindow_days_before:
      searchForm.capa_hyperwindow_type !== -1 ? undefined : 7,

    start_time:
      searchForm.audit_time && searchForm.audit_time.length > 0
        ? searchForm.audit_time[0]
        : undefined,
    end_time:
      searchForm.audit_time && searchForm.audit_time.length > 1
        ? searchForm.audit_time[1]
        : undefined,
    ProjectTaskNumberSort: projectTaskNumberSort.value,

    PlanAuditStartTimeSort: planAuditStartTimeSort.value,
    PlanAuditEndTimeSort: planAuditEndTimeSort.value,
    ActualAuditStartTimeSort: actualAuditStartTimeSort.value,
    ActualAuditEndTimeSort: actualAuditEndTimeSort.value,
    PlanReportTimeSort: plannedReportingTimeSort.value,
    CompleteReportTimeSort: completedReportingTimeSort.value,
    PlanCAPATimeSort: planCAPATimeSort.value,
    ActualCAPATimeSort: actualCAPATimeSort.value,
    report_hyperwindow_days_start: searchForm.report_hyperwindow_days_start,
    report_hyperwindow_days_end: searchForm.report_hyperwindow_days_end,
    capa_hyperwindow_days_start: searchForm.capa_hyperwindow_days_start,
    capa_hyperwindow_days_end: searchForm.capa_hyperwindow_days_end
  };
  const result = await request_reportcapaprogress_export(
    trans,
    true,
    () => (fullLoading.value = false)
  );
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = result;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
};
// 处理排序改变事件
const handleSortChange = async ({ prop, order }) => {
  projectTaskNumberSort.value = undefined;
  planAuditStartTimeSort.value = undefined;
  planAuditEndTimeSort.value = undefined;
  actualAuditStartTimeSort.value = undefined;
  actualAuditEndTimeSort.value = undefined;
  plannedReportingTimeSort.value = undefined;
  completedReportingTimeSort.value = undefined;
  planCAPATimeSort.value = undefined;
  actualCAPATimeSort.value = undefined;

  if (prop === "project_task_number") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      projectTaskNumberSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      projectTaskNumberSort.value = true;
    } else {
      projectTaskNumberSort.value = undefined;
    }
  }
  if (prop === "planned_audit_starttime") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      planAuditStartTimeSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      planAuditStartTimeSort.value = true;
    } else {
      planAuditStartTimeSort.value = undefined;
    }
  }
  if (prop === "planned_audit_endtime") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      planAuditEndTimeSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      planAuditEndTimeSort.value = true;
    } else {
      planAuditEndTimeSort.value = undefined;
    }
  }
  if (prop === "actual_audit_starttime") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      actualAuditStartTimeSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      actualAuditStartTimeSort.value = true;
    } else {
      actualAuditStartTimeSort.value = undefined;
    }
  }
  if (prop === "actual_audit_endtime") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      actualAuditEndTimeSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      actualAuditEndTimeSort.value = true;
    } else {
      actualAuditEndTimeSort.value = undefined;
    }
  }
  if (prop === "planned_reporting_date") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      plannedReportingTimeSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      plannedReportingTimeSort.value = true;
    } else {
      plannedReportingTimeSort.value = undefined;
    }
  }
  if (prop === "completed_reporting_date") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      completedReportingTimeSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      completedReportingTimeSort.value = true;
    } else {
      completedReportingTimeSort.value = undefined;
    }
  }
  if (prop === "planned_capa_date") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      planCAPATimeSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      planCAPATimeSort.value = true;
    } else {
      planCAPATimeSort.value = undefined;
    }
  }
  if (prop === "actual_capa_date") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      actualCAPATimeSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      actualCAPATimeSort.value = true;
    } else {
      actualCAPATimeSort.value = undefined;
    }
  }
  await getProgressReportCAPAPage();
};

watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Progress.ProjectProgress,
      MenuButtonCodes.Progress.ProjectProgress.ProjectProgressSearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Progress.ProjectProgress,
      MenuButtonCodes.Progress.ProjectProgress.ProjectProgressReset
    );
    // 处理导出
    buttonVisible.exportButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Progress.ProjectProgress,
      MenuButtonCodes.Progress.ProjectProgress.ProjectProgressExport
    );
  }
});
</script>
<style>
.projectprogress-ul .el-scrollbar__view {
  height: calc(100% - 8px);
}
</style>
<style scoped lang="scss">
.projectprogress-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  .count-li {
    .count-root-ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      .li-single {
        border-radius: 12px;
        width: 200px;
        height: 80px;
        padding: 8px 28px;
        .single-ul {
          border-radius: 12px;
          display: flex;
          list-style: none;
          margin: 0;
          padding: 0;
          height: 80px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          // 全部
          .all {
            background-color: #519be0;
          }
          // 待分配
          .tobeallowcate {
            background-color: #86bc53;
          }
          // 准备中
          .ready {
            background-color: #90c4ba;
          }
          // 进行中
          .inprogress {
            background-color: #b066d8;
          }
          // 完成
          .complte {
            background-color: #21ba6d;
          }
          // 取消
          .cancel {
            background-color: #f3730e;
          }
          .li-left {
            border-radius: 12px 0 0 12px;
            width: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            .iconfont {
              font-size: 48px;
              color: white;
            }
          }
          .li-right {
            border-radius: 0 12px 12px 0;
            padding: 8px 0;
            width: 140px;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: white;
            .top-div {
              font: 600;
              flex: 1;
              font-size: 2em;
            }
          }
        }
      }
    }
  }
}
</style>
