export default {
  Common: {
    PopoverBottom: {
      Cancel: 'Cancel',
    }
  },
  // 组件
  Components: {
    LockScreen:{
      InputPassword: 'Please input password'
    },
    // 首页
    Index: {
      // 登录
      Login: {
        Login: 'Login',
        Account: 'Account',
        Password: 'Password',
        InputAccount: 'Please input account',
        InputPhone: 'Please input phone number',
        PhoneFormatIncorrect: 'Phone number format is incorrect',
        InputPassword: 'Please input password',
        PasswordMin6: 'Password length at least 6 characters'
      }
    },
    // 头部
    Header: {
      // 头部弹出层
      HeaderPopover: {
        PersonalInfo: 'Personal information',
        DayAndNightMode: 'Day and night mode',
        ClearCache: 'Clear cache',
        Language: 'Language',
        LoginOut: 'Sign out',
        Chinese: 'Chinese',
        English: 'English'
      }
    }
  }
}