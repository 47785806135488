<template>
  <el-dialog v-model="dialogVisible" :draggable="props.appendToBody" :modal="props.modal" :append-to-body="props.appendToBody" :title="props.title" :width="props.width" :center="props.center" :top="props.top"
    :close-on-click-modal="false" :before-close="handleDialogBeforeClose" modal-class="modalClass" >
    <slot></slot>
    <template v-if="props.showFooter" #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancel">{{ $t('Common.PopoverBottom.Cancel') }}</el-button>
        <el-button :loading="buttonLoading" type="primary" @click="handleCommit">
          {{ $t('Common.PopoverBottom.Confirm') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup >
import { ref, defineProps, defineEmits, defineExpose, } from 'vue'
const props = defineProps({
  title: { // 标题
    type: String,
    default: ()=>''
  }, 
  width:{
    type: String,
    default: ()=>'60%'
  } , // 宽度 百分比 或者 固定px 500
  center: {
    type: Boolean,
    default: ()=>false
  }, // 是否居中
  showFooter: {
    type: Boolean,
    default: ()=>true
  },
  top: {
    type: String,
    default: ()=>'15vh'
  },
  modal:{
    type: Boolean,
    default: ()=>true,
  },
  appendToBody:{
    type: Boolean,
    default: ()=> false
  }
})
// emits
const emits = defineEmits(['handleCancle', 'handleCommit'])
// 弹窗是否可见
const dialogVisible = ref(true)
// 处理关闭前的动作
const handleDialogBeforeClose = ()=>{
  handleCancel()
}
// 按钮加载
const buttonLoading = ref(false)

// 处理关闭
const handleCancel = () => {
  emits('handleCancle')
  // dialogVisible.value = false
}
// 处理保存逻辑，调用父传过来的方法，并将关闭的方法提供给父
const handleCommit = () => {
  emits('handleCommit', () => {
    handleCancel()
  })
}

// 暴露
defineExpose({
  // dialogVisible,
})
</script>
<style>
.el-dialog__wrapper{
    pointer-events: none !important;
}
.el-dialog{
    pointer-events: auto !important;
}
</style>
<style scoped lang='scss'>
</style>