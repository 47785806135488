<template>
  <Dialog
    width="40%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form :model="form" label-width="120px" :rules="rules" ref="formRef">
      <el-form-item :label="$t('Components.System.SystemParam.Name')" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item :label="$t('Components.System.SystemParam.Code')" prop="code">
        <template #label v-if="!isAdmin">
          <div :style="{ display: 'flex', justifyContent: 'center' }">
            <span>{{ $t("Components.System.SystemParam.Code") }}</span>
            <div>
              <el-tooltip
                effect="dark"
                :content="t('Components.System.SystemParam.OnlyAdminCanEdit')"
                placement="top"
              >
                <el-icon :style="{ marginLeft: '8px' }">
                  <InfoFilled />
                </el-icon>
              </el-tooltip>
            </div>
          </div>
        </template>
        <template #default>
          <el-input
            :disabled="props.editData && props.editData.guid && !isAdmin"
            v-model="form.code"
          />
        </template>
      </el-form-item>
      <el-form-item :label="$t('Components.System.SystemParam.Value')" prop="value">
        <template #label v-if="!isAdmin">
          <div :style="{ display: 'flex' }">
            <span>{{ $t("Components.System.SystemParam.Value") }}</span>
            <div>
              <el-tooltip
                effect="dark"
                :content="t('Components.System.SystemParam.OnlyAdminCanEdit')"
                placement="top"
              >
                <el-icon :style="{ marginLeft: '8px' }">
                  <InfoFilled />
                </el-icon>
              </el-tooltip>
            </div>
          </div>
        </template>
        <template #default>
          <el-input
            :disabled="props.editData && props.editData.guid && !isAdmin"
            v-model="form.value"
          />
        </template>
      </el-form-item>
      <el-form-item
        v-if="isAdmin"
        :label="$t('Components.System.SystemParam.IsSystem')"
        prop="is_system"
      >
        <el-radio-group v-model="form.is_system">
          <el-radio :label="YesOrNo.Yes">{{ $t("Common.YesOrNo.Yes") }}</el-radio>
          <el-radio :label="YesOrNo.No">{{ $t("Common.YesOrNo.No") }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('Components.System.SystemParam.Remark')" prop="remark">
        <el-input v-model="form.remark" :rows="5" type="textarea" />
      </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup>
import {
  ref,
  reactive,
  markRaw,
  onMounted,
  defineProps,
  defineEmits,
  computed,
} from "vue";
import { WarnTriangleFilled, InfoFilled } from "@element-plus/icons-vue";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { useI18n } from "vue-i18n";
import { request_systemparam_add, request_systemparam_modify } from "@/api/apis";
import { ElMessageBox } from "element-plus";
import { useStore } from "@/store/index";
import { SystemParamCode } from "@/consts/systemParam";
import { YesOrNo } from "@/consts/enum";

const { t } = useI18n();
const store = useStore();
const props = defineProps({
  editData: {
    type: Object,
    default: () => {},
  },
});
const emits = defineEmits(["handleCloseDialog", "getSystemParamPage"]);
// 标题
const dialogTitle = ref("");
// 表单ref
const formRef = ref();
// 表单数据
const form = reactive({
  name: props.editData.name,
  code: props.editData.code,
  value: props.editData.value,
  remark: props.editData.remark,
  is_system:
    props.editData && props.editData.is_system ? props.editData.is_system : false,
});

// 判断是否可以修改是否系统
const isAdmin = computed(() => {
  if (
    store.userInfo &&
    store.userInfo.system_params &&
    store.userInfo.system_params.length > 0
  ) {
    const findData = store.userInfo.system_params.find(
      (item) => item.code === SystemParamCode.AdminAccount
    );
    if (findData) {
      // 找到了这笔数据
      if (findData.value === store.userInfo.user_info.account) {
        // 如果管理员账号和这个账号一致
        return true;
      }
    }
    return false;
  }
  return false
});

// 表单校验规则
const rules = ref({
  name: [
    {
      required: true,
      message: t("Components.System.SystemParam.ValidateSystemParamNameRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.SystemParam.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  code: [
    {
      required: true,
      message: t("Components.System.SystemParam.ValidateSystemParamCodeRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.SystemParam.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  value: [
    {
      required: true,
      message: t("Components.System.SystemParam.ValidateSystemParamValueRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.SystemParam.ValidateMaxLength"),
      trigger: "change",
    },
  ],
});

onMounted(async () => {
  if (props.editData && props.editData.guid) {
    // 代表修改
    dialogTitle.value = t(
      "Components.System.SystemParam.DialogSystemParamTitleMaintance"
    );
  } else {
    dialogTitle.value = t("Components.System.SystemParam.DialogSystemParamTitleAdd");
  }
});
// 处理提交事件
const handleCommit = async () => {
  if (!formRef.value) return;
  await formRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(
        t("Components.System.SystemParam.ConfirmSaveSystemParam"),
        t("Common.ElMessageBox.OperateConfirm"),
        {
          confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
          cancelButtonText: t("Common.ElMessageBox.CancelText"),
          type: "warning",
          icon: markRaw(WarnTriangleFilled),
        }
      )
        .then(async () => {
          await saveSystemParam();
        })
        .catch(() => {});
    }
  });
};
// 保存岗位数据
const saveSystemParam = async () => {
  const trans = {
    code: form.code,
    name: form.name,
    value: form.value,
    remark: form.remark,
    is_system: form.is_system,
  };
  if (props.editData && props.editData.guid) {
    // 修改
    await request_systemparam_modify(props.editData.guid, trans);
  } else {
    // 新增
    await request_systemparam_add(trans);
  }
  emits("getSystemParamPage");
  emits("handleCloseDialog");
};
// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};
</script>
<style scoped lang="scss"></style>
