<template>
  <Dialog
    width="60%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form
      class="user-form"
      :model="form"
      label-width="80px"
      :rules="rules"
      ref="formRef"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('Components.System.User.Name')" prop="name">
            <el-input v-model="form.name" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.System.User.Account')"
            prop="account"
          >
            <el-input :disabled="props.editData && props.editData.guid? true: false" v-model="form.account" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.System.User.Phone')"
            prop="phone"
          >
            <el-input v-model="form.phone" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.System.User.Email')"
            prop="email"
          >
            <el-input v-model="form.email" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('Components.System.User.Sex')">
            <el-radio-group v-model="form.sex">
              <el-radio :label="Sex.Male">{{
                $t("Components.System.User.Male")
              }}</el-radio>
              <el-radio :label="Sex.Female">{{
                $t("Components.System.User.Female")
              }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.System.User.Department')"
            prop="department_guid"
          >
            <el-tree-select
              v-model="form.department_guid"
              :data="props.departmentTreeData"
              :style="{ width: '100%' }"
              node-key="guid"
              check-strictly
              :render-after-expand="false"
              :props="{ label: 'name' }"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('Components.System.User.Job')">
            <el-select
              v-model="form.jobs"
              multiple
              collapse-tags
              collapse-tags-tooltip
              :placeholder="$t('Components.System.User.SelectJobPlaceholder')"
              :style="{ width: '100%' }"
            >
              <el-option
                v-for="item in props.jobsData"
                :key="item.guid"
                :label="item.name"
                :value="item.guid"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('Components.System.User.Role')">
            <el-select
              v-model="form.roles"
              multiple
              collapse-tags
              collapse-tags-tooltip
              :placeholder="$t('Components.System.User.SelectRolePlaceholder')"
              :style="{ width: '100%' }"
            >
              <el-option
                v-for="item in props.rolesData"
                :key="item.guid"
                :label="item.name"
                :value="item.guid"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="!props.editData || !props.editData.guid">
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.System.User.Password')"
            prop="password"
          >
            <el-input
              v-model="form.password"
              type="password"
              :placeholder="
                $t('Components.System.User.InputPasswordPlaceholder')
              "
              show-password
            />
          </el-form-item>
        </el-col>
      </el-row>
      
      <el-form-item :label="$t('Components.System.User.Remark')" prop="remark">
        <el-input v-model="form.remark" :rows="5" type="textarea" />
      </el-form-item>
      <!-- 
      -->
    </el-form>
  </Dialog>
</template>
<script setup>
import {
  ref,
  reactive,
  markRaw,
  onMounted,
  defineProps,
  defineEmits,
} from "vue";
import { WarnTriangleFilled } from "@element-plus/icons-vue";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { useI18n } from "vue-i18n";
import { validateEmail, validatePhone } from "@/util/util";
import {
  request_user_modify,
  request_user_add,
} from "@/api/apis";
import { ElMessageBox } from "element-plus";
import { Sex } from "@/consts/enum";

const { t } = useI18n();

const props = defineProps({
  editData: {
    type: Object,
    default: () => {},
  },
  departmentTreeData: {
    type: Array,
    default: () => [],
  },
  jobsData: {
    type: Array,
    default: () => [],
  },
  rolesData: {
    type: Array,
    default: () => [],
  },
  initAccountPassword:{
    type: String,
    default: ()=>''
  }
});
const emits = defineEmits(["handleCloseDialog", "getUserPage"]);
// 标题
const dialogTitle = ref("");
// 表单ref
const formRef = ref();
// 表单数据
const form = reactive({
  name: props.editData.name,
  nick_name: props.editData.nick_name,
  account: props.editData.account,
  phone: props.editData.phone,
  email: props.editData.email,
  sex: props.editData.sex ? props.editData.sex : Sex.Male,
  remark: props.editData.remark,
  jobs: props.editData.jobs,
  roles: props.editData.roles,
  department_guid: props.editData.department_guid,
  password: props.editData && props.editData.guid ? '' : props.initAccountPassword
});
// 手机校验
const validatePhone_ = (rule, value, callback) => {
  if (!validatePhone(value)) {
    callback(new Error(t("Components.System.User.InplueValidePhone")));
  } else {
    callback();
  }
};
// 邮箱校验
const validateEmail_ = (rule, value, callback) => {
  if (!validateEmail(value)) {
    callback(new Error(t("Components.System.User.InplueValideEmail")));
  } else {
    callback();
  }
};
// 表单校验规则
const rules = ref({
  name: [
    {
      required: true,
      message: t("Components.System.User.ValidateUserNameRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.User.ValidateMaxLength100"),
      trigger: "change",
    },
  ],
  nick_name: [
    {
      required: true,
      message: t("Components.System.User.ValidateUserNickNameRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.User.ValidateMaxLength100"),
      trigger: "change",
    },
  ],
  account: [
    {
      required: true,
      message: t("Components.System.User.ValidateUserAccountRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.User.ValidateMaxLength100"),
      trigger: "change",
    },
  ],
  phone: [ {
      required: true,
      message: t("Components.System.User.ValidateUserEmailRequired"),
      trigger: "blur",
    },{ validator: validatePhone_, trigger: ["blur"] }],
  email: [
   { validator: validateEmail_, trigger: ["blur"] }],
  password: [
    {
      required: true,
      message: t("Components.System.User.ValidateUserPasswordRequired"),
      trigger: "blur",
    },
    {
      min: 6,
      message: t("Components.System.User.ValidateMinPassword"),
      trigger: "change",
    },
    {
      min: 6,
      message: t("Components.System.User.ValidateMinPassword"),
      trigger: "blur",
    },
  ],
});

onMounted(() => {
  if (props.editData && props.editData.guid) {
    // 代表修改
    dialogTitle.value = t("Components.System.User.DialogUserTitleMaintance");
  } else {
    dialogTitle.value = t("Components.System.User.DialogUserTitleAdd");
  }
});
// 处理提交事件
const handleCommit = async () => {
  if (!formRef.value) return;
  await formRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(
        t("Components.System.User.ConfirmSaveUser"),
        t("Common.ElMessageBox.OperateConfirm"),
        {
          confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
          cancelButtonText: t("Common.ElMessageBox.CancelText"),
          type: "warning",
          icon: markRaw(WarnTriangleFilled),
        }
      )
        .then(async () => {
          await saveUser();
        })
        .catch(() => {});
    }
  });
};
// 保存用户数据
const saveUser = async () => {
  const trans = {
    name: form.name,
    department_guid: form.department_guid,
    phone: form.phone,
    account: form.account,
    email: form.email,
    nick_name: form.name,
    sex: form.sex,
    jobs: form.jobs,
    roles: form.roles,
    password: form.password,
    remark: form.remark,
  };
  if (props.editData && props.editData.guid) {
    // 修改
    await request_user_modify(props.editData.guid, trans);
  } else {
    // 新增
    await request_user_add(trans);
  }
  emits("getUserPage");
  emits("handleCloseDialog");
};
// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};
</script>
<style>
/* el-upload附件列表框去除'按 delete 键可删除'提示 */
.el-upload-list__item .el-icon--close-tip {
  display: none !important;
}
</style>
<style scoped lang="scss"></style>
