<template>
  <div class="menu" >
    <el-menu
      @select="handleSelect"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#fff"
    >
      <el-menu-item index="del">{{ t("Components.AuditCalendar.PersoCalendar.DeleteTask")}}</el-menu-item>
    </el-menu>
  </div>
</template>
<script setup >
import { defineEmits, onMounted } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const emits = defineEmits(["menu-item"]);
// 处理选择
const handleSelect = (action) => {
  emits("menu-item", action);
};

onMounted(()=>{
})
</script>
<style scoped lang='scss'>

.menu {
  position: fixed;
  transition: all 1s ease;
  ::v-deep(.el-menu-item) {
    height: 40px;
    line-height: 40px;
    width: 140px;
  }
}
</style>