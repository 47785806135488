<template>
  <Dialog
    width="60%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form :model="form" label-width="80px" :rules="rules" ref="formRef">
      <el-form-item :label="$t('Components.System.Announcement.Title')" prop="title">
        <el-input
          v-model="form.title"
          :placeholder="$t('Components.System.Announcement.PleaseInputTitle')"
        />
      </el-form-item>
      <el-form-item :label="$t('Components.System.Announcement.Type')" prop="type">
        <el-radio-group v-model="form.type">
          <el-radio
            v-for="item in props.announcementTypes"
            :label="item.value"
            :key="item.guid"
          >
            {{ item.name }}
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item :label="$t('Components.System.Announcement.Sort')" prop="sort">
        <el-input-number
          :style="{ width: '50%' }"
          v-model="form.sort"
          :placeholder="$t('Components.System.Announcement.PleaseInputSort')"
        />
      </el-form-item>
      <el-form-item :label="$t('Components.System.Announcement.Content')">
        <div style="border: 1px solid #ccc">
          <div id="toolbar-container" style="border-bottom: 1px solid #ccc"></div>
          <div id="editor-container" style="height: 300px; overflow-y: hidden"></div>
        </div>
      </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup>
import {
  ref,
  reactive,
  markRaw,
  onMounted,
  defineProps,
  defineEmits,
  onBeforeUnmount,
  defineExpose
} from "vue";
import { WarnTriangleFilled } from "@element-plus/icons-vue";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { useI18n } from "vue-i18n";
import {
  request_announcement_add,
  request_announcement_modify,
  request_announcement_content,
  request_util_upload_api,
} from "@/api/apis";
import { ElMessageBox, ElMessage } from "element-plus";
import { LocalStorageKey } from "@/consts/frame";
// import attachmentModule from '@wangeditor/plugin-upload-attachment'
// import "@wangeditor/editor/dist/css/style.css"; // 引入 css
// import { Editor, Toolbar } from "@wangeditor/editor-for-vue";

import { createToolbar, createEditor } from "@wangeditor/editor";

const { t } = useI18n();
const props = defineProps({
  announcementTypes: {
    type: Array,
    default: () => [],
  },
  editData: {
    type: Object,
    default: () => {},
  },
});
const emits = defineEmits(["handleCloseDialog", "getAnnouncementPage"]);
// 标题
const dialogTitle = ref("");
// 表单ref
const formRef = ref();
// 表单数据
const form = reactive({
  title: props.editData.title,
  type: props.editData.type,
  sort: props.editData.sort,
});
const token = localStorage.getItem(LocalStorageKey.Token);

const editorContainer = ref();
const toolBarContainer = ref();

const toolbarConfig = {
  // excludeKeys: ["insertImage", "uploadVideo", "insertVideo","editVideoSize"]
  toolbarKeys: [
    "headerSelect",
    "blockquote",
    "|",
    "bold",
    "underline",
    "italic",
    {
      key: "group-more-style",
      title: "更多",
      menuKeys: ["through", "code", "sup", "sub", "clearStyle"],
    },
    "color",
    "bgColor",
    "|",
    "fontSize",
    "fontFamily",
    "lineHeight",
    "|",
    "bulletedList",
    "numberedList",
    "todo",
    {
      key: "group-justify",
      title: "对齐",
      menuKeys: ["justifyLeft", "justifyRight", "justifyCenter", "justifyJustify"],
    },
    {
      key: "group-indent",
      title: "缩进",
      menuKeys: ["indent", "delIndent"],
    },
    "|",
    "emotion",
    {
      key: "group-image",
      title: "图片",
      menuKeys: ["uploadImage"],
    },
    "insertTable",
    "codeBlock",
    "divider",
    "|",
    "undo",
    "redo",
    "|",
    "fullScreen",
  ],
  insertKeys: {
    index: 22, // 自定义插入的位置
    keys: ["uploadAttachment"], // “上传附件”菜单
  },
};
const editorConfig = {
  placeholder: t("Components.System.Announcement.PleaseInputContent"),
  onChange(editor) {
    const html = editor.getHtml();
    valueHtml.value = html;
    // 也可以同步到 <textarea>
  },
  hoverbarKeys: {
    attachment: {
      menuKeys: ["downloadAttachment"], // “下载附件”菜单
    },
  },
  MENU_CONF: {
    uploadImage: {
      fieldName: "formFiles",
      // 上传图片的配置
      server: request_util_upload_api,
      headers: {
        token: token,
      },
      // 自定义插入返回格式【后端返回的格式】
      customInsert(res, insertFn) {
        if (res.code != 200) {
          ElMessage.warning(res.message);
          return;
        }
        if (res.response && res.response.length > 0) {
          insertFn(res.response[0].internet_path, res.response[0].file_name, "");
        }
      },
      // 单个文件上传失败
      onFailed(file, res) {
        ElMessage.warning('onFailed:' + res)
      },
      // 上传错误，或者触发 timeout 超时
      onError(file, err) {
        ElMessage.warning('onError:' + err)
      },
      // 单个文件上传成功之后
      onSuccess() {
      },
    },
    uploadAttachment: {
      server: request_util_upload_api, // 服务端地址
      headers: {
        token: token,
      },
      fieldName: "formFiles",
      onSuccess() {
      },
      onFailed(file, res) {
        ElMessage.warning('onFailed:' + res)
      },
      onError(file, err) {
        ElMessage.warning('onError:' + err)
      },
      // 上传成功后，用户自定义插入文件
      customInsert(res, file, insertFn) {
        if (res.code != 200) {
          ElMessage.warning(res.message);
          return;
        }
        if (res.response && res.response.length > 0) {
          // insertFn(res.response[0].internet_path, res.response[0].file_name, "");
          insertFn(res.response[0].file_name, res.response[0].internet_path,);
        }
      },
    },
  },
};

// 表单校验规则
const rules = ref({
  name: [
    {
      required: true,
      message: t("Components.System.Announcement.ValidateAnnouncementTitleRequired"),
      trigger: "blur",
    },
    {
      max: 255,
      message: t("Components.System.Announcement.ValidateMaxLength255"),
      trigger: "change",
    },
  ],
  type: [
    {
      required: true,
      message: t("Components.System.Announcement.ValidateAnnouncementTypeRequired"),
      trigger: "blur",
    },
  ],
  sort: [
    {
      required: true,
      message: t("Components.System.Announcement.PleaseInputSort"),
      trigger: "blur",
    },
  ],
});
// 内容 HTML
const valueHtml = ref("");

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  if (editorContainer.value) {
    editorContainer.value.destroy();
  }
});
onMounted(async () => {
  if (props.editData && props.editData.guid) {
    // 代表修改
    dialogTitle.value = t(
      "Components.System.Announcement.DialogAnnouncementTitleMaintance"
    );
  } else {
    dialogTitle.value = t("Components.System.Announcement.DialogAnnouncementTitleAdd");
  }

  setTimeout(() => {
    editorContainer.value = createEditor({
      selector: "#editor-container",
      config: editorConfig,
      mode: "default", // 或 'simple'
    });
    toolBarContainer.value = createToolbar({
      selector: "#toolbar-container",
      config: toolbarConfig,
      mode: "default", // 或 default'simple'
      editor: editorContainer.value,
    });
  }, 0);

  getAnnouncementContent1();
});
// 处理提交事件
const handleCommit = async () => {
  if (!formRef.value) return;
  await formRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(
        t("Components.System.Announcement.ConfirmSaveAnnouncement"),
        t("Common.ElMessageBox.OperateConfirm"),
        {
          confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
          cancelButtonText: t("Common.ElMessageBox.CancelText"),
          type: "warning",
          icon: markRaw(WarnTriangleFilled),
        }
      )
        .then(async () => {
          await saveAnnouncement();
        })
        .catch(() => {});
    }
  });
};
// 保存通知公告数据
const saveAnnouncement = async () => {
  const trans = {
    title: form.title,
    // content: form.content,
    type: form.type,
    content: valueHtml.value,
    sort: form.sort,
  };
  if (props.editData && props.editData.guid) {
    // 修改
    await request_announcement_modify(props.editData.guid, trans);
  } else {
    // 新增
    await request_announcement_add(trans);
  }
  emits("getAnnouncementPage");
  emits("handleCloseDialog");
};
// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};

const getAnnouncementContent = async () => {
  if (props.editData && props.editData.guid) {
    const result = await request_announcement_content(props.editData.guid);
    valueHtml.value = result;
  }
};
const getAnnouncementContent1 = async () => {
  if (props.editData && props.editData.guid) {
    const result = await request_announcement_content(props.editData.guid);
    valueHtml.value = result;
    editorContainer.value.setHtml(result);
  }
};
defineExpose({
  getAnnouncementContent,
});
</script>
<style scoped lang="scss"></style>
