<template>
  <ul class="reportappendix-ul">
    <li class="hm-search">
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item
          :label="$t('Components.Project.ProjectTask.ReportAppendix.Search1')"
        >
          <el-input
            :disabled="!props.selectProjectTaskReportAppendixGuid"
            v-model="searchForm.input"
            :placeholder="
              $t('Components.Project.ProjectTask.ReportAppendix.PleaseInputSearch')
            "
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="buttonVisible.searchButton"
            :disabled="!props.selectProjectTaskReportAppendixGuid"
            type="primary"
            @click="queryClick"
            :icon="Search"
            >{{ $t("Components.Project.ProjectTask.ReportAppendix.Search") }}</el-button
          >
          <el-button
            v-if="buttonVisible.resetButton"
            :disabled="!props.selectProjectTaskReportAppendixGuid"
            @click="resetClick"
            :icon="RefreshLeft"
            >{{ $t("Components.Project.ProjectTask.ReportAppendix.Reset") }}</el-button
          >
        </el-form-item>
      </el-form>
    </li>
    <li class="hm-operate">
      <!-- 操作部分 -->
      <el-button
        v-if="buttonVisible.addButton"
        :disabled="
          props.projectTaskData.progress === ProjectTaskProgress.Complete ||  !props.selectProjectTaskReportAppendixGuid
        "
        :style="{ color: 'white' }"
        :icon="Plus"
        :color="ButtonColor.Add"
        @click="
          () => {
            dialogVisible = true;
          }
        "
        >{{
          $t("Components.Project.ProjectTask.ReportAppendix.Add")
        }}</el-button
      >
      <el-button
        v-if="buttonVisible.editButton"
        :disabled="
          !(selectReportAppendixDetails && selectReportAppendixDetails.length === 1) ||
          props.projectTaskData.progress === ProjectTaskProgress.Complete ||
          !props.selectProjectTaskReportAppendixGuid
        "
        :style="{ color: 'white' }"
        :icon="Edit"
        :color="ButtonColor.Edit"
        @click="handleEdit(1)"
        >{{ $t("Components.Project.ProjectTask.ReportAppendix.Edit") }}</el-button
      >
      <el-button
        v-if="buttonVisible.deleteButton"
        :disabled="
          !(selectReportAppendixDetails && selectReportAppendixDetails.length > 0) ||
          props.projectTaskData.progress === ProjectTaskProgress.Complete ||
          !props.selectProjectTaskReportAppendixGuid
        "
        :style="{ color: 'white' }"
        :icon="Delete"
        :color="ButtonColor.Delete"
        @click="handleDeleteBatch"
        >{{ $t("Components.Project.ProjectTask.ReportAppendix.Delete") }}</el-button
      >
      <el-upload
        v-if="buttonVisible.importButton"
        :disabled="!props.selectProjectTaskReportAppendixGuid"
        ref="uploadRef"
        accept=".xls, .xlsx"
        :action="request_projecttask_reportappendix_import"
        :limit="1"
        name="file"
        :headers="{ token: token }"
        :on-success="handleUploadSuccess"
        :show-file-list="false"
        :data="{
          guid: props.projectTaskGuid,
          project_task_report_appendix_guid: props.selectProjectTaskReportAppendixGuid,
        }"
      >
        <el-button
          v-if="buttonVisible.importButton"
          :disabled="
            props.projectTaskData.progress === ProjectTaskProgress.Complete ||
            !props.selectProjectTaskReportAppendixGuid
          "
          :style="{ color: 'white' }"
          :icon="Upload"
          :color="ButtonColor.Upload"
          >{{ $t("Components.Project.ProjectTask.ReportAppendix.Import") }}</el-button
        >
      </el-upload>
    </li>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <el-table v-loading="tableLoading" :data="tableData" row-key="guid" 
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" />
          <el-table-column
            :label="$t('Components.Project.ProjectTask.ReportAppendix.SerialNumber')"
            prop="serial_number"
          >
          </el-table-column>
          <el-table-column
            :label="$t('Components.Project.ProjectTask.ReportAppendix.FilterNumber')"
            prop="filter_number"
          >
          </el-table-column>
          <el-table-column
            :label="$t('Components.Project.ProjectTask.ReportAppendix.Visit')"
            width="120"
            prop="visit"
          >
          </el-table-column>
          <el-table-column
            :label="$t('Components.Project.ProjectTask.ReportAppendix.SourceRecord')"
            width="100"
            prop="source_record"
          >
          </el-table-column>
          <el-table-column
            :label="$t('Components.Project.ProjectTask.ReportAppendix.EDCPosition')"
            prop="edc_position"
          >
          </el-table-column>
          <el-table-column
            :label="$t('Components.Project.ProjectTask.ReportAppendix.EDCContent')"
            width="100"
            prop="edc_content"
          >
          </el-table-column>
          <el-table-column
            :label="$t('Components.Project.ProjectTask.ReportAppendix.Sort')"
            prop="sort"
          >
          </el-table-column>
          <el-table-column
            :label="$t('Components.Project.ProjectTask.ReportAppendix.Operate')"
            width="200"
          >
            <template #default="scope">
              <el-space wrap>
               
                <el-button
                  v-if="buttonVisible.editButtonLine"
                  :disabled="
                    props.projectTaskData.progress === ProjectTaskProgress.Complete
                  "
                  :style="{ color: 'white' }"
                  :icon="Edit"
                  :color="ButtonColor.Edit"
                  size="small"
                  @click.stop="handleEdit(2, scope.row)"
                  >{{
                    $t("Components.Project.ProjectTask.ReportAppendix.Edit")
                  }}</el-button
                >
                <el-button
                  v-if="buttonVisible.deleteButtonLine"
                  :disabled="
                    props.projectTaskData.progress === ProjectTaskProgress.Complete
                  "
                  :style="{ color: 'white' }"
                  :icon="Delete"
                  :color="ButtonColor.Delete"
                  size="small"
                  bg
                  @click.stop="handleDelete(scope.row)"
                  >{{
                    $t("Components.Project.ProjectTask.ReportAppendix.Delete")
                  }}</el-button
                >
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </li>
    </el-scrollbar>

    <li class="hm-page">
      <el-pagination
        :disabled="!props.selectProjectTaskReportAppendixGuid"
        small
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="AllPageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </li>
  </ul>
  <ReportAppendixRightModify
    :projectTaskGuid="props.projectTaskGuid"
    v-if="dialogVisible"
    @handleCloseDialog="handleCloseDialog"
    @getProjectTaskReportAppendixDetailPage="getProjectTaskReportAppendixDetailPage"
    :editData="editData"
    :selectProjectTaskReportAppendixGuid="props.selectProjectTaskReportAppendixGuid"
  />
</template>
<script setup>
import {
  reactive,
  ref,
  markRaw,
  onMounted,
  watchEffect,
  defineProps,
  defineExpose,
  watch,
} from "vue";
import {
  Plus,
  Delete,
  RefreshLeft,
  Edit,
  Search,
  WarnTriangleFilled,
  Upload,
} from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";
import {
  AllPageSize,
  ButtonColor,
  LocalStorageKey,
  DefaultPageSize,
} from "@/consts/frame";
import {
  request_projecttask_reportappendix_detail_page,
  request_projecttask_reportappendix_detail_delete,
  request_projecttask_reportappendix_detail_delete_batch,
  request_projecttask_reportappendix_import,
} from "@/api/apis";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";
import { useStore } from "@/store/index";
import {  ProjectTaskProgress } from "@/consts/enum";
import statusCode from "@/api/statusCode";
import ReportAppendixRightModify from "@/components/Project/ProjectTask/ReportAppendix/ReportAppendix_RightModify.vue";

const store = useStore();
const { t } = useI18n();
const props = defineProps({
  projectTaskGuid: {
    type: String,
    default: () => "",
  },
  projectTaskData: {
    type: Object,
    default: () => {},
  },
  selectProjectTaskReportAppendixGuid: {
    type: String,
    default: () => "",
  },
});

const searchForm = reactive({
  input: "",
});
const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  addButton: false,
  editButton: false,
  deleteButton: false,
  editButtonLine: false,
  deleteButtonLine: false,
  importButton: false,
});
// 当前页码
const currentPage = ref(1);
// 页码大小
const pageSize = ref(DefaultPageSize);
// 表格数据
const tableData = ref([]);
// 表格加载
const tableLoading = ref(false);
// 总数量
const total = ref(0);
const token = ref("");

const selectReportAppendixDetails = ref([]);
const uploadRef = ref();
const editData = ref({});
const dialogVisible = ref(false);

onMounted(() => {
  if (localStorage) {
    token.value = localStorage.getItem(LocalStorageKey.Token);
  }
});
// 分页查询
const getProjectTaskReportAppendixDetailPage = async () => {
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    text: searchForm.input
  };
  tableLoading.value = true;
  const result = await request_projecttask_reportappendix_detail_page(
    props.projectTaskGuid,
    props.selectProjectTaskReportAppendixGuid,
    trans,
    () => (tableLoading.value = false)
  );
  total.value = result.total;
  tableData.value = result.rows;
  editData.value = {};
};
// 查询事件
const queryClick = () => {
  getProjectTaskReportAppendixDetailPage();
};
// 重置事件
const resetClick = () => {
  searchForm.input = ''
  currentPage.value = 1

  getProjectTaskReportAppendixDetailPage();
};
// 处理页码大小改变事件
const handleSizeChange = (size) => {
  pageSize.value = size;
  getProjectTaskReportAppendixDetailPage();
};
// 处理当前页改变事件
const handleCurrentChange = (index) => {
  currentPage.value = index;
  getProjectTaskReportAppendixDetailPage();
};
// 处理选择回调事件
const handleSelectionChange = (reportAppendixDetails) => {
  selectReportAppendixDetails.value = reportAppendixDetails;
};
// 清空
const clearInfo = () => {
  searchForm.text = "";
  tableData.value = [];
  currentPage.value = 1;
  total.value = 0;
};
// 处理上传成功
const handleUploadSuccess = async (response) => {
  uploadRef.value.clearFiles();
  if (response.code === statusCode.Success) {
    ElMessage.success(response.message);
    getProjectTaskReportAppendixDetailPage();
  } else {
    ElMessage.warning(response.message);
  }
};
// 处理编辑事件
const handleEdit = (type, data) => {
  let row = undefined;
  if (type === 1) {
    // 判断是否有且仅选择了一条用户数据
    if (selectReportAppendixDetails.value.length !== 1) {
      ElMessage.warning(
        t("Components.Project.ProjectTask.ReportAppendix.OnlyOneSelectEdit")
      );
      return;
    }
    row = selectReportAppendixDetails.value[0];
  } else if (type === 2) {
    row = data;
  }

  editData.value = {
    guid: row.guid,
    serial_number: row.serial_number,
    filter_number: row.filter_number,
    visit: row.visit,
    source_record: row.source_record,
    edc_position: row.edc_position,
    edc_content: row.edc_content,
    sort: row.sort
  };
  dialogVisible.value = true;
};
// 处理回调关闭事件
const handleCloseDialog = () => {
  dialogVisible.value = false;
  editData.value = {};
};
// 处理删除
const handleDelete = async (row) => {
  ElMessageBox.confirm(
    t(
      "Components.Project.ProjectTask.ReportAppendix.ConfirmDeleteReportAppendixDetail"
    ),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {

      await request_projecttask_reportappendix_detail_delete(props.projectTaskGuid, props.selectProjectTaskReportAppendixGuid, row.guid)

      getProjectTaskReportAppendixDetailPage();
    })
    .catch(() => {});
};
// 批量删除
const handleDeleteBatch = async () => {
  if (selectReportAppendixDetails.value.length === 0) {
    ElMessage.warning(
      t("Components.Project.ProjectTask.ReportAppendix.AtLeastOneReportAppendixDetail")
    );
    return;
  }
  ElMessageBox.confirm(
    t("Components.Project.ProjectTask.ReportAppendix.ConfirmDeleteReportAppendixDetail"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const trans = {
        project_task_report_appendix_detail_guids: selectReportAppendixDetails.value.map(
          (item) => item.guid
        ),
      };
      await request_projecttask_reportappendix_detail_delete_batch(
        props.projectTaskGuid,
        props.selectProjectTaskReportAppendixGuid,
        trans,
        true
      );
      getProjectTaskReportAppendixDetailPage();
    })
    .catch(() => {});
};

watch(()=>props.selectProjectTaskReportAppendixGuid, ()=>{
    if(props.selectProjectTaskReportAppendixGuid){
    getProjectTaskReportAppendixDetailPage()
  }
})
watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskReportAppendixDetailSearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskReportAppendixDetailReset
    );
    // 处理新增
    buttonVisible.addButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskReportAppendixDetailAdd
    );
    // 处理编辑
    buttonVisible.editButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskReportAppendixDetailEdit
    );
    // 处理删除
    buttonVisible.deleteButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskReportAppendixDetailDelete
    );
    // 处理编辑行
    buttonVisible.editButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskReportAppendixDetailEditLine
    );
    // 处理删除行
    buttonVisible.deleteButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskReportAppendixDetailDeleteLine
    );
    // 处理导入
    buttonVisible.importButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskReportAppendixDetailImport
    );
  }
});
defineExpose({
  clearInfo,
});
</script>
<style scoped lang="scss">
.reportappendix-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: calc(100% - 22px);
}
</style>
