export const MenuButtonCodes = {
  Home:{
    ChooseUser: 'ChooseUser'
  },
  System: {
    Role: {
      RoleSearch: 'RoleSearch',
      RoleReset: 'RoleReset',
      RoleAdd: 'RoleAdd',
      RoleCopy: 'RoleCopy',
      RoleEdit: 'RoleEdit',
      RoleDelete: 'RoleDelete',
      RoleEditLine: 'RoleEditLine',
      RoleDeleteLine: 'RoleDeleteLine',
      RoleDataflag: 'RoleDataflag',
      RoleExport: 'RoleExport'
    },
    User: {
      UserSearch: 'UserSearch',
      UserReset: 'UserReset',
      UserAdd: 'UserAdd',
      UserEdit: 'UserEdit',
      UserDelete: 'UserDelete',
      UserExport: 'UserExport',
      UserEditLine: 'UserEditLine',
      UserDeleteLine: 'UserDeleteLine',
      UserDataflag: 'UserDataflag',
      UserResetPasswordLine: 'UserResetPasswordLine'
    },
    Department: {
      DepartmentSearch: 'DepartmentSearch',
      DepartmentReset: 'DepartmentReset',
      DepartmentAdd: 'DepartmentAdd',
      DepartmentExpand: 'DepartmentExpand',
      DepartmentAddLine: 'DepartmentAddLine',
      DepartmentEditLine: 'DepartmentEditLine',
      DepartmentDeleteLine: 'DepartmentDeleteLine',
      DepartmentDataflag: 'DepartmentDataflag',
    },
    Job: {
      JobSearch: 'JobSearch',
      JobReset: 'JobReset',
      JobAdd: 'JobAdd',
      JobEdit: 'JobEdit',
      JobDelete: 'JobDelete',
      JobEditLine: 'JobEditLine',
      JobDeleteLine: 'JobDeleteLine',
      JobDataflag: 'JobDataflag',
      JobExport: 'JobExport'
    },
    Dictionary: {
      DictionarySearch: 'DictionarySearch',
      DictionaryReset: 'DictionaryReset',
      DictionaryAdd: 'DictionaryAdd',
      DictionaryEdit: 'DictionaryEdit',
      DictionaryDelete: 'DictionaryDelete',
      DictionaryExport: 'DictionaryExport',
      DictionaryEditLine: 'DictionaryEditLine',
      DictionaryDeleteLine: 'DictionaryDeleteLine',
      DictionaryDataflag: 'DictinaryDataflag',
      DictionaryImport: 'DictionaryImport',
      DictionaryItemSearch: 'DictionaryItemSearch',
      DictionaryItemReset: 'DictionaryItemReset',
      DictionaryItemAdd: 'DictionaryItemAdd',
      DictionaryItemEdit: 'DictionaryItemEdit',
      DictionaryItemDelete: 'DictionaryItemDelete',
      DictionaryItemExport: 'DictionaryItemExport',
      DictionaryItemEditLine: 'DictionaryItemEditLine',
      DictionaryItemDeleteLine: 'DictionaryItemDeleteLine',
      DictionaryItemDataflag: 'DictinaryItemDataflag',
      DictionaryItemImport: 'DictionaryItemImport'
    },
    SystemParam: {
      SystemParamSearch: 'SystemParamSearch',
      SystemParamReset: 'SystemParamReset',
      SystemParamAdd: 'SystemParamAdd',
      SystemParamEdit: 'SystemParamEdit',
      SystemParamDelete: 'SystemParamDelete',
      SystemParamExport: 'SystemParamExport',
      SystemParamEditLine: 'SystemParamEditLine',
      SystemParamDeleteLine: 'SystemParamDeleteLine',
      SystemParamDataflag: 'SystemParamDataflag',

    },
    Announcement: {
      AnnouncementSearch: 'AnnouncementSearch',
      AnnouncementReset: 'AnnouncementReset',
      AnnouncementAdd: 'AnnouncementAdd',
      AnnouncementEdit: 'AnnouncementEdit',
      AnnouncementDelete: 'AnnouncementDelete',
      AnnouncementEditLine: 'AnnouncementEditLine',
      AnnouncementDeleteLine: 'AnnouncementDeleteLine',
      AnnouncementDataflag: 'AnnouncementDataflag',
      AnnouncementShowLine: 'AnnouncementShowLine'
    },
    TemplateManager: {
      ExcelTemplateSearch: 'ExcelTemplateSearch',
      ExcelTemplateReset: 'ExcelTemplateReset',
      ExcelTemplateAdd: 'ExcelTemplateAdd',
      ExcelTemplateEdit: 'ExcelTemplateEdit',
      ExcelTemplateDelete: 'ExcelTemplateDelete',
      ExcelTemplateEditLine: 'ExcelTemplateEditLine',
      ExcelTemplateDeleteLine: 'ExcelTemplateDeleteLine',
      ExcelTemplateDownloadLine: 'ExcelTemplateDownloadLine',
      ExcelTemplateDataflag: 'ExcelTemplateDataflag',
    },
    Menu: {
      MenuSearch: 'MenuSearch',
      MenuReset: 'MenuReset',
      MenuAdd: 'MenuAdd',
      MenuExpand: 'MenuExpand',
      MenuAddLine: 'MenuAddLine',
      MenuEditLine: 'MenuEditLine',
      MenuDeleteLine: 'MenuDeleteLine',
      MenuDataflagLine: 'MenuDataflagLine',
      MenuButtonSearch: 'MenuButtonSearch',
      MenuButtonReset: 'MenuButtonReset',
      MenuButtonAdd: 'MenuButtonAdd',
      MenuButtonEdit: 'MenuButtonEdit',
      MenuButtonDelete: 'MenuButtonDelete',
      MenuButtonDataflag: 'MenuButtonDataflag'
    }
  },
  Log: {
    OperateLog: {
      OperateLogSearch: 'OperateLogSearch',
      OperateLogReset: 'OperateLogReset',
      OperateLogDelete: 'OperateLogDelete',
      OperateLogClear: 'OperateLogClear',
      OperateLogExport: 'OperateLogExport',
      OperateLogViewLine: 'OperateLogViewLine',
      OperateLogDeleteLine: 'OperateLogDeleteLine'
    },
    LoginLog: {
      LoginLogSearch: 'LoginLogSearch',
      LoginLogReset: 'LoginLogReset',
      LoginLogDelete: 'LoginLogDelete',
      LoginLogClear: 'LoginLogClear',
      LoginLogExport: 'LoginLogExport'
    }
  },
  Database:{
    AuditedParty: {
      AuditedPartySearch: 'AuditedPartySearch',
      AuditedPartyReset: 'AuditedPartyReset',
      AuditedPartyAdd: 'AuditedPartyAdd',
      AuditedPartyEdit: 'AuditedPartyEdit',
      AuditedPartyDelete: 'AuditedPartyDelete',
      AuditedPartyEditLine: 'AuditedPartyEditLine',
      AuditedPartyDeleteLine: 'AuditedPartyDeleteLine',
      AuditedPartyDataflag: 'AuditedPartyDataflag',
      AuditedPartyExport: 'AuditedPartyExport'
    },
    Client: {
      ClientSearch: 'ClientSearch',
      ClientReset: 'ClientReset',
      ClientAdd: 'ClientAdd',
      ClientEdit: 'ClientEdit',
      ClientDelete: 'ClientDelete',
      ClientEditLine: 'ClientEditLine',
      ClientDeleteLine: 'ClientDeleteLine',
      ClientDataflag: 'ClientDataflag',
      ClientExport: 'ClientExport'
    },
    AuditSummary: {
      AuditSummarySearch: 'AuditSummarySearch',
      AuditSummaryReset: 'AuditSummaryReset',
      AuditSummaryAdd: 'AuditSummaryAdd',
      AuditSummaryEdit: 'AuditSummaryEdit',
      AuditSummaryDelete: 'AuditSummaryDelete',
      AuditSummaryEditLine: 'AuditSummaryEditLine',
      AuditSummaryDeleteLine: 'AuditSummaryDeleteLine',
      AuditSummaryDataflag: 'AuditSummaryDataflag',
      AuditSummaryExport: 'AuditSummaryExport',

      AuditSummaryItemSearch: 'AuditSummaryItemSearch',
      AuditSummaryItemReset: 'AuditSummaryItemReset',
      AuditSummaryItemAdd: 'AuditSummaryItemAdd',
      AuditSummaryItemEditLine: 'AuditSummaryItemEditLine',
      AuditSummaryItemDeleteLine: 'AuditSummaryItemDeleteLine',
      AuditSummaryItemDataflag: 'AuditSummaryItemDataflag',
      
    },
  },
  Project:{
    ProjectList: {
      ProjectListSearch: 'ProjectListSearch',
      ProjectListReset: 'ProjectListReset',
      ProjectListAdd: 'ProjectListAdd',
      ProjectListEdit: 'ProjectListEdit',
      ProjectListDelete: 'ProjectListDelete',
      ProjectListEditLine: 'ProjectListEditLine',
      ProjectListDeleteLine: 'ProjectListDeleteLine',
      ProjectListDataflag: 'ProjectListDataflag',
      ProjectListExport: 'ProjectListExport',
      ProjectListComplete: 'ProjectListComplete',
      ProjectListRestart: 'ProjectListRestart',
      ProjectListCompleteLine: 'ProjectListCompleteLine',
      ProjectListRestartLine: 'ProjectListRestartLine',
      ProjectListCancel: 'ProjectListCancel',
      ProjectListCancelLine: 'ProjectListCancelLine',
      ProjectListTaskLine:'ProjectListTaskLine',
    },
    ProjectTask: {
      ProjectTaskSearch: 'ProjectTaskSearch',
      ProjectTaskReset: 'ProjectTaskReset',
      ProjectTaskAdd: 'ProjectTaskAdd',
      ProjectTaskEdit: 'ProjectTaskEdit',
      ProjectTaskEditLine: 'ProjectTaskEditLine',
      ProjectTaskDispatchLine: 'ProjectTaskDispatchLine',
      ProjectTaskInfoLine: 'ProjectTaskInfoLine',
      ProjectTaskAuditSummaryLine: 'ProjectTaskAuditSummaryLine',
      ProjectTaskDiscoveryLine:'ProjectTaskDiscoveryLine',
      ProjectTaskCancelLine:'ProjectTaskCancelLine',
      ProjectTaskReportLine:'ProjectTaskReportLine',
      ProjectTaskAuditCertificateLine:'ProjectTaskAuditCertificateLine',
      ProjectTaskExportCAPALine:'ProjectTaskExportCAPALine',
      ProjectTaskCompleteLine: 'ProjectTaskCompleteLine',
      ProjectTaskExportReportLine:'ProjectTaskExportReportLine',
      ProjectTaskRestartLine: 'ProjectTaskRestartLine',
      ProjectTaskReportAppendixLine: 'ProjectTaskReportAppendixLine',
      ProjectTaskRewriteLine:'ProjectTaskRewriteLine',
      ProjectTaskDiscoveryImport:'ProjectTaskDiscoveryImport',
      ProjectTaskCAPAFinalize: 'ProjectTaskCAPAFinalize',

      // 派遣稽查人员
      ProjectTaskDispatchAdd: 'ProjectTaskDispatchAdd',
      ProjectTaskDispatchEditLine: 'ProjectTaskDispatchEditLine',
      ProjectTaskDispatchDeleteLine:'ProjectTaskDispatchDeleteLine',

      // 项目任务稽查发现
      ProjectTaskDicoverySearch: 'ProjectTaskDicoverySearch',
      ProjectTaskDiscoveryReset:'ProjectTaskDiscoveryReset',
      ProjectTaskDiscoveryAdd:'ProjectTaskDiscoveryAdd',
      ProjectTaskDiscoveryEdit:'ProjectTaskDiscoveryEdit',
      ProjectTaskDiscoveryDelete:'ProjectTaskDiscoveryDelete',
      ProjectTaskDiscoveryExport:'ProjectTaskDiscoveryExport',
      ProjectTaskDiscoveryGenerateReport:'ProjectTaskDiscoveryGenerateReport',
      ProjectTaskDiccoveryEditLine:'ProjectTaskDiccoveryEditLine',
      ProjectTaskDiccoveryDeleteLine:'ProjectTaskDiccoveryDeleteLine',

      // 报告附件
      ProjectTaskReportAppendixSearch:'ProjectTaskReportAppendixSearch',
      ProjectTaskReportAppendixReset:'ProjectTaskReportAppendixReset',
      ProjectTaskReportAppendixAdd:'ProjectTaskReportAppendixAdd',
      ProjectTaskReportAppendixEdit:'ProjectTaskReportAppendixEdit',
      ProjectTaskReportAppendixDelete:'ProjectTaskReportAppendixDelete',
      ProjectTaskReportAppendixEditLine:'ProjectTaskReportAppendixEditLine',
      ProjectTaskReportAppendixDeleteLine:'ProjectTaskReportAppendixDeleteLine',
      ProjectTaskReportAppendixDownload:'ProjectTaskReportAppendixDownload',

      //报告附件详情
      ProjectTaskReportAppendixDetailAdd:'ProjectTaskReportAppendixDetailAdd',
      ProjectTaskReportAppendixDetailImport:'ProjectTaskReportAppendixDetailImport',
      ProjectTaskReportAppendixDetailSearch:'ProjectTaskReportAppendixDetailSearch',
      ProjectTaskReportAppendixDetailReset:'ProjectTaskReportAppendixDetailReset',
      ProjectTaskReportAppendixDetailEdit:'ProjectTaskReportAppendixDetailEdit',
      ProjectTaskReportAppendixDetailDelete:'ProjectTaskReportAppendixDetailDelete',
      ProjectTaskReportAppendixDetailEditLine:'ProjectTaskReportAppendixDetailEditLine',
      ProjectTaskReportAppendixDetailDeleteLine:'ProjectTaskReportAppendixDetailDeleteLine'
    },
  },
  ReviewApprove:{
    MineReview:{
      
    MineReviewSearch:'MineReviewSearch',
    MineReviewReset:'MineReviewReset',
    MineReviewExport:'MineReviewExport',
    MineReviewDistributeLine:'MineReviewDistributeLine',
    MineReviewReviewLine:'MineReviewReviewLine'
    }
  },
  Progress:{
    ProjectProgress:{
      ProjectProgressSearch:'ProjectProgressSearch',
      ProjectProgressReset:'ProjectProgressReset',
      ProjectProgressExport:'ProjectProgressExport'
    },
    ProjectTaskProgress:{
      ProjectTaskProgressSearch:'ProjectTaskProgressSearch',
      ProjectTaskProgressReset:'ProjectTaskProgressReset',
      ProjectTaskProgressExport:'ProjectTaskProgressExport'
    },
    ProjectAndTaskProgress:{
      ProjectAndTaskProgressSearch:'ProjectAndTaskProgressSearch',
      ProjectAndTaskProgressReset:'ProjectAndTaskProgressReset',
      ProjectAndTaskProgressExport:'ProjectAndTaskProgressExport'
    },
  },
  WorkingHours:{
    WorkingHours:{
      WorkingHoursSearch:'WorkingHoursSearch',
      WorkingHoursReset:'WorkingHoursReset',
      WorkingHoursAdd:'WorkingHoursAdd',
      WorkingHoursExport:'WorkingHoursExport',
      WorkingHoursEditLine:'WorkingHoursEditLine',
      WorkingHoursDelete: 'WorkingHoursDelete',
      WorkingHourseDeleteLine: 'WorkingHourseDeleteLine',
    }
  },
  Statistics:{
    AuditDiscovery:{
      AuditDiscoverySearch:'AuditDiscoverySearch',
      AuditDiscoveryReset:'AuditDiscoveryReset',
      AuditDiscoveryExport:'AuditDiscoveryExport',
      AuditDiscoverySingleMainCateogryExport: 'AuditDiscoverySingleMainCateogryExport'
    },
    TaskStatistics:{
      ProjectTaskStatisticsSearch:'ProjectTaskStatisticsSearch',
      ProjectTaskStatisticsReset:'ProjectTaskStatisticsReset',
      ProjectTaskStatisticsExport:'ProjectTaskStatisticsExport',
      ProjectTaskStatisticsExportSingle: 'ProjectTaskStatisticsExportSingle'
    }
  },
  AuditCalendar:{
    PersonCalendar:{
      UserCalendarAdd: 'UserCalendarAdd'
    }
  }
}

export const MenuCodes = {
  Home: 'Home',
  System: {
    SystemManager: 'SystemManager',
    User: 'User',
    Role: 'Role',
    Department: 'Department',
    Job: 'Job',
    Dictionary: 'Dictionary',
    SystemParam: 'SystemParam',
    Announcement: 'Announcement',
    Menu: 'Menu',
    TemplateManager: 'TemplateManager'
  },
  Log: {
    Log: 'Log',
    OperateLog: 'OperateLog',
    LoginLog: 'LoginLog'
  },
  Database:{
    AuditedParty: 'AuditedParty',
    Client:'Client',
    AuditSummary: 'AuditSummary',
  },
  Project:{
    ProjectList: 'ProjectList',
    ProjectTask: 'ProjectTask'
  },
  ReviewApprove:{
    MineReview: 'MineReview'
  },
  Progress:{
    ProjectProgress: 'ProjectProgress',
    ProjectTaskProgress:'ProjectTaskProgress',
    ProjectAndTaskProgress: 'ProjectAndTaskProgress',
  },
  WorkingHours:{
    WorkingHours: 'WorkingHours'
  },
  Statistics:{
    AuditDiscovery: 'AuditDiscovery',
    TaskStatistics:'TaskStatistics'
  },
  AuditCalendar:{
    PersonCalendar: 'PersonCalendar'
  }
}