<template>
  <Dialog
    width="40%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form :model="form" label-width="80px" :rules="rules" ref="formRef">
      <el-form-item
        :label="$t('Components.Project.ProjectTask.ReportAppendix.Name')"
        prop="name"
      >
        <el-input
          :style="{ width: '100%' }"
          v-model="form.name"
          :placeholder="
            t('Components.Project.ProjectTask.ReportAppendix.PleaseInputName')
          "
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('Components.Project.ProjectTask.ReportAppendix.Sort')" prop="sort">
        <el-input-number
          :style="{ width: '100%' }"
          v-model="form.sort"
          :placeholder="
            t('Components.Project.ProjectTask.ReportAppendix.PleaseInputSort')
          "
        ></el-input-number>
      </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup>
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import {
  defineProps,
  ref,
  reactive,
  onMounted,
  markRaw,
  defineEmits,
} from "vue";
import { ElMessageBox } from "element-plus";
import { WarnTriangleFilled } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import { request_projecttask_reportappendix_modify, 
  request_projecttask_reportappendix_add } from "@/api/apis";

const { t } = useI18n();
const emits = defineEmits(["getProjectTaskReportAppendixPage", "handleCloseDialog"]);
const props = defineProps({
  editData: {
    type: Object,
    default: () => {},
  },
  projectTaskGuid: {
    type: String,
    default: ()=> ''
  }
});
// 标题
const dialogTitle = ref("");

const form = reactive({
  name: props.editData.name,
  sort: props.editData.sort,
  guid: props.editData.guid,
});
const formRef = ref();
// 表单校验规则
const rules = ref({
  name: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.ReportAppendix.PleaseInputName"),
      trigger: "blur",
    },
  ],
  sort: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.ReportAppendix.PleaseInputSort"),
      trigger: "blur",
    },
  ],
});
onMounted(() => {
  if (props.editData && props.editData.guid) {
    // 代表修改
    dialogTitle.value = t("Components.Project.ProjectTask.ReportAppendix.ModifyReportAppendix");
  } else {
    dialogTitle.value = t("Components.Project.ProjectTask.ReportAppendix.AddReportAppendix");
  }
});

// 处理提交事件
const handleCommit = async () => {
  // 校验
  if (!formRef.value) return;
  await formRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(
        t("Components.Project.ProjectTask.ReportAppendix.ConfirmSave"),
        t("Common.ElMessageBox.OperateConfirm"),
        {
          confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
          cancelButtonText: t("Common.ElMessageBox.CancelText"),
          type: "warning",
          icon: markRaw(WarnTriangleFilled),
        }
      )
        .then(async () => {
          await save();
        })
        .catch(() => {});
    }
  });
  return;
};
// 保存数据
const save = async () => {
  const trans = {
    sort: form.sort,
    name: form.name,
  };
  if (props.editData && props.editData.guid) {
    // 修改
    await request_projecttask_reportappendix_modify(props.projectTaskGuid, props.editData.guid,  trans);
  } else {
    // 新增
    await request_projecttask_reportappendix_add(props.projectTaskGuid,trans);
  }
  emits("getProjectTaskReportAppendixPage");
  emits("handleCloseDialog");
};
// 关闭
const handleCancle = () => {
  emits("handleCloseDialog");
};
// // 处理下载
// const handleDownload = ()=>{
//   let path = form.file_path
//   const link = document.createElement("a");
//   link.style.display = "none";
//   link.href = path;
//   link.target = '_blank'
//   document.body.appendChild(link);
//   link.click();
// }
</script>
<style scoped lang="scss">
.input-principle {
  &:hover {
    cursor: pointer;
  }
}
</style>
