<template>
  <Dialog
    width="40%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form :model="form" label-width="80px" :rules="rules" ref="formRef">
      <el-form-item :label="$t('Components.System.Dictionary.Name')" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item :label="$t('Components.System.Dictionary.Code')" prop="code">
        <el-input :disabled="props.editData && props.editData.guid ? true: false" v-model="form.code" />
      </el-form-item>
      <el-form-item
        v-if="isAdmin"
        :label="$t('Components.System.SystemParam.IsSystem')"
        prop="is_system"
      >
        <el-radio-group v-model="form.is_system">
          <el-radio :label="YesOrNo.Yes">{{ $t("Common.YesOrNo.Yes") }}</el-radio>
          <el-radio :label="YesOrNo.No">{{ $t("Common.YesOrNo.No") }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- <el-form-item :label="$t('Components.System.Dictionary.Sort')" prop="sort">
        <el-input-number :style="{ width: '100%' }" v-model="form.sort" />
      </el-form-item> -->
      <el-form-item :label="$t('Components.System.Dictionary.Remark')" prop="remark">
        <el-input v-model="form.remark" type="textarea"  rows="5"/>
      </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup>
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { defineProps, ref, reactive, onMounted, markRaw , defineEmits, computed} from "vue";
import { ElMessageBox } from "element-plus";
import { WarnTriangleFilled} from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import {request_dictionary_add, request_dictionary_modify} from '@/api/apis'
import { SystemParamCode } from "@/consts/systemParam";
import { YesOrNo } from "@/consts/enum";
import { useStore } from "@/store/index";

const store = useStore();
const { t } = useI18n();
const emits = defineEmits(["getDictionaryPage", "handleCloseDialog"]);
const props = defineProps({
  editData: {
    type: Object,
    default: () => {},
  },
  addParentGuid: {
    type: String,
    default: () => "",
  },
});
// 标题
const dialogTitle = ref("");

const form = reactive({
  name: props.editData.name,
  code: props.editData.code,
  is_system: props.editData.is_system,
  // sort: props.editData.sort || 0,
  remark: props.editData.remark
});
const formRef = ref();
// 表单校验规则
const rules = ref({
  name: [
    {
      required: true,
      message: t("Components.System.Dictionary.ValidateDicNameRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.Dictionary.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  code: [
    {
      required: true,
      message: t("Components.System.Dictionary.ValidateDicCodeRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.Dictionary.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  // sort: [
  //   {
  //     required: true,
  //     message: t("Components.System.Dictionary.ValidateDictionarySortRequired"),
  //     trigger: "blur",
  //   },
  // ],
});
onMounted(() => {
  if (props.editData && props.editData.guid) {
    // 代表修改
    dialogTitle.value = t("Components.System.Dictionary.DialogTitleMaintance");
  } else {
    dialogTitle.value = t("Components.System.Dictionary.DialogTitleAdd");
  }
});

// 处理提交事件
const handleCommit = async () => {
  // 校验
  if (!formRef.value) return;
  await formRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(
        t("Components.System.Dictionary.ConfirmSaveDic"),
        t("Common.ElMessageBox.OperateConfirm"),
        {
          confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
          cancelButtonText: t("Common.ElMessageBox.CancelText"),
          type: "warning",
          icon: markRaw(WarnTriangleFilled),
        }
      )
        .then(async () => {
          await saveDictionary();
        })
        .catch(() => {});
    }
  });
  return;
};
// 保存字典数据
const saveDictionary = async () => {
  const trans = {
    name: form.name,
    code: form.code,
    // sort: form.sort,
    remark: form.remark,
  };
  if (props.editData && props.editData.guid) {
    // 修改
    await request_dictionary_modify(props.editData.guid, trans);
  } else {
    // 新增
    await request_dictionary_add(trans);
  }
  emits("getDictionaryPage");
  emits("handleCloseDialog");
};
// 关闭
const handleCancle = () => {
  emits("handleCloseDialog");
};
// 判断是否可以修改是否系统
const isAdmin = computed(() => {
  if (
    store.userInfo &&
    store.userInfo.system_params &&
    store.userInfo.system_params.length > 0
  ) {
    const findData = store.userInfo.system_params.find(
      (item) => item.code === SystemParamCode.AdminAccount
    );
    if (findData) {
      // 找到了这笔数据
      if (findData.value === store.userInfo.user_info.account) {
        // 如果管理员账号和这个账号一致
        return true;
      }
    }
    return false;
  }
  return false
});
</script>
<style scoped lang="scss">
.input-principle {
  &:hover {
    cursor: pointer;
  }
}
</style>
