<template>
  <ul class="auditsummary-root-ul">
    <li class="auditsummary-root-left-li">
      <el-scrollbar>
        <Left
          @handleAuditSummaryClick="handleAuditSummaryClick"
          @handleClearActiveAuditSummary="handleClearActiveAuditSummary"
        />
      </el-scrollbar>
    </li>
    <li class="auditsummary-root-right-li">
      <el-scrollbar>
        <Right ref="rightRef" />
      </el-scrollbar>
    </li>
  </ul>
</template>
<script setup>
import { ref } from "vue";
import Left from "@/components/Database/AuditSummary/AuditSummaryLeft.vue";
import Right from "@/components/Database/AuditSummary/AuditSummaryRight.vue";
// 右侧对象
const rightRef = ref();
// 处理稽查小结点击事件
const handleAuditSummaryClick = async (auditSummaryGuid) => {
  rightRef.value.clearInfo();
  // 需要查询右侧稽查小结项的数据
  await rightRef.value.getAuditSummaryItem(auditSummaryGuid);
};
// 清空右侧数据
const handleClearActiveAuditSummary = () => {
  rightRef.value.clearInfo();
};
</script>
<style>
.auditsummary-root-left-li .el-scrollbar__view {
  height: calc(100% - 8px);
}
.auditsummary-root-right-li .el-scrollbar__view {
  height: calc(100% - 8px);
}
</style>

<style scoped lang="scss">
.auditsummary-root-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .auditsummary-root-left-li {
    width: 50%;
    padding-right: 8px;
  }
  .auditsummary-root-right-li {
    padding-left: 8px;
    width: 50%;
  }
}
</style>
