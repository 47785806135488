<template>
  <Dialog
    width="40%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form :model="form" label-width="120px" ref="formRef">
      <el-form-item
        :label="$t('Components.Report.GenerateReport.MainCategory')"
      >
        <el-input v-model="form.mainCategory" disabled />
      </el-form-item>
      <el-form-item :label="$t('Components.Report.GenerateReport.SubCategory')">
        <el-input v-model="form.subCategory" disabled />
      </el-form-item>
      <el-form-item :label="$t('Components.Report.GenerateReport.Grading')">
        <el-select
          v-model="form.grading"
          :placeholder="
            t('Components.Report.GenerateReport.PleaseSelectGrading')
          "
        >
          <el-option
            v-for="item in gradings"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        :label="$t('Components.Report.GenerateReport.FilterNumber')"
      >
        <el-input
          v-model="form.filterNumber"
          type="textarea"
        />
      </el-form-item>

      <el-form-item
        :label="$t('Components.Report.GenerateReport.QuestionDetail')"
      >
        <el-input
          v-model="form.questionDetail"
          type="textarea"
        />
      </el-form-item>

      <el-form-item
        :label="$t('Components.Report.GenerateReport.QuestionInduction')"
      >
        <el-input
          v-model="form.questionInduction"
          type="textarea"
        />
      </el-form-item>

      <el-form-item :label="$t('Components.Report.GenerateReport.Remark')">
        <el-input
          v-model="form.remark"
          type="textarea"
        />
      </el-form-item>

      <el-form-item
        :label="$t('Components.Report.GenerateReport.IsImportReport')"
      >
        <el-switch v-model="form.isImportReport" />
      </el-form-item>

      <el-form-item :label="$t('Components.Report.GenerateReport.Sort')">
        <el-input-number v-model="form.sort" :min="1" />
      </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup >
import { ref, defineEmits, onMounted, reactive } from "vue";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { ReportGrading } from "@/consts/enum";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const emits = defineEmits(["handleCloseDialog"]);
// 标题
const dialogTitle = ref("");
// 表单ref
const formRef = ref();
// 表单数据
const form = reactive({
  mainCategory: "监督管理部门", // 主分类
  subCategory: "监管部门审查、批准和备案", // 子分类
  grading: 2, // 分级
  filterNumber: "ZS001", // 筛选号
  questionDetail: "这是问题详情测试数据", // 问题详情
  questionInduction: "这是问题归纳测试数据", // 问题归纳
  remark: "这是一条备注信息", // 备注
  isImportReport: false, // 是否导入报告
  sort: 3, // 序号
});

const gradings = ref([]);

onMounted(async () => {
  dialogTitle.value = t("Components.Report.GenerateReport.DescriptionModify");

  gradings.value = [
    {
      label: t("Components.Report.GenerateReport.Certical"),
      value: ReportGrading.Certical,
    },
    {
      label: t("Components.Report.GenerateReport.Major"),
      value: ReportGrading.Major,
    },
    {
      label: t("Components.Report.GenerateReport.Minor"),
      value: ReportGrading.Minor,
    },
    {
      label: t("Components.Report.GenerateReport.Comment"),
      value: ReportGrading.Comment,
    },
  ];
});

// 处理提交事件
const handleCommit = async () => {
  emits("handleCloseDialog");
};
// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};
</script>
<style scoped lang='scss'>
</style>