// 状态码
module.exports = {
  // 成功
  Success: 200,
  // 过期
  Expired: 601,
  // 服务器内部错误
  ServerError: 500,
  // 业务错误
  BusinessError: 501,
  // 未授权
  Unauthorized: 401,
  // 异地登录
  RemoteLogin: 600 
}