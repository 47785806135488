<template>
  <table class="table" :style="{ width: '100%' }">
    <tr>
      <td class="label-bold" :style="{ width: '16%' }">
        {{ t("Components.Project.ProjectTask.Report1.DiscoveryDescription.Number") }}
      </td>
      <td>{{ props.prefix }}-{{ props.index + 1 }}</td>
      <!-- <td rowspan="5" :style="{ width: '10%' }" align="center">
        <el-button type="primary" circle :icon="Edit"></el-button>
      </td> -->
    </tr>
    <tr>
      <td class="label-bold">
        {{ t("Components.Project.ProjectTask.Report1.DiscoveryDescription.Grading") }}
      </td>
      <td>
        <ul class="td-ul">
          <li class="td-ul-li-left">
            <span
              ref="gradingRef"
              @dblclick="handleGradingClick"
              v-if="!gradingIsEdit || !computedCanEdit"
              >{{ computedGrading }}</span
            >
            <div class="dd" v-else>
              <el-select
                v-model="gradingData"
                :placeholder="
                  t(
                    'Components.Project.ProjectTask.Report1.DiscoveryDescription.PleaseSelectGrading'
                  )
                "
              >
                <el-option
                  v-for="item in discoverydGradingDics"
                  :key="item.guid"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-space>
                <el-button
                  type="primary"
                  circle
                  size="small"
                  :icon="Check"
                  @click.stop="handleGradingOK"
                ></el-button>
                <el-button
                  circle
                  size="small"
                  type="danger"
                  :icon="Close"
                  @click.stop="handleGradingCancel"
                ></el-button>
              </el-space>
            </div>
          </li>
          <li class="td-ul-li-right">
            <div v-if="computedCommentCount(ProjectTaskCommentModule.Grading) > 0">
              <el-badge
                :value="computedCommentCount(ProjectTaskCommentModule.Grading)"
                type="success"
                class="item"
                v-if="
                  props.projectTaskProgress !== ProjectTaskProgress.ToBeAllocated &&
                  props.projectTaskProgress !== ProjectTaskProgress.Ready &&
                  props.projectTaskProgress !== ProjectTaskProgress.AuditImplementation &&
                  props.projectTaskProgress !== ProjectTaskProgress.ReportWriting &&
                  props.projectTaskProgress !== ProjectTaskProgress.Complete
                "
              >
                <el-badge
                  :value="computedNotReadCommentCount(ProjectTaskCommentModule.Grading)"
                  class="notread-comment-count"
                  v-if="computedNotReadCommentCount(ProjectTaskCommentModule.Grading) > 0"
                >
                  <el-button
                    type="primary"
                    circle
                    size="small"
                    :icon="ChatDotSquare"
                    @click="
                      handleCommentClick(
                        ProjectTaskCommentModule.Grading,
                        t(
                          'Components.Project.ProjectTask.Report1.DiscoveryDescription.Grading'
                        )
                      )
                    "
                  ></el-button>
                </el-badge>
                <el-button
                  v-else
                  type="primary"
                  circle
                  size="small"
                  :icon="ChatDotSquare"
                  @click="
                    handleCommentClick(
                      ProjectTaskCommentModule.Grading,
                      t(
                        'Components.Project.ProjectTask.Report1.DiscoveryDescription.Grading'
                      )
                    )
                  "
                ></el-button>
              </el-badge>
            </div>
            <div v-else>
              <el-button
                v-if="
                  props.projectTaskProgress !== ProjectTaskProgress.ToBeAllocated &&
                  props.projectTaskProgress !== ProjectTaskProgress.Ready &&
                  props.projectTaskProgress !== ProjectTaskProgress.AuditImplementation &&
                  props.projectTaskProgress !== ProjectTaskProgress.ReportWriting &&
                  props.projectTaskProgress !== ProjectTaskProgress.Complete
                "
                type="primary"
                circle
                size="small"
                :icon="ChatDotSquare"
                @click="
                  handleCommentClick(
                    ProjectTaskCommentModule.Grading,
                    t(
                      'Components.Project.ProjectTask.Report1.DiscoveryDescription.Grading'
                    )
                  )
                "
              ></el-button>
            </div>
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td class="label-bold">
        {{
          t("Components.Project.ProjectTask.Report1.DiscoveryDescription.MainCategory")
        }}
      </td>
      <td>
        <ul class="td-ul">
          <li class="td-ul-li-left">
            <span
              ref="mainCategoryRef"
              v-if="!categoryIsEdit || !computedCanEdit"
              @dblclick="handleCategoryClick"
              >{{ props.groupData.main_category_name }}</span
            >
            <div class="dd" v-else>
              <el-select
                v-model="mainCategoryData"
                :placeholder="
                  t(
                    'Components.Project.ProjectTask.Report1.DiscoveryDescription.PleaseSelectMainCategory'
                  )
                "
                @change="handleCategoryChange"
              >
                <el-option
                  v-for="item in props.mainCategorys"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </li>
          <li class="td-ul-li-right">
            <div v-if="computedCommentCount(ProjectTaskCommentModule.MainCategory) > 0">
              <el-badge
                :value="computedCommentCount(ProjectTaskCommentModule.MainCategory)"
                type="success"
                class="item"
                v-if="
                  props.projectTaskProgress !== ProjectTaskProgress.ToBeAllocated &&
                  props.projectTaskProgress !== ProjectTaskProgress.Ready &&
                  props.projectTaskProgress !== ProjectTaskProgress.AuditImplementation &&
                  props.projectTaskProgress !== ProjectTaskProgress.ReportWriting &&
                  props.projectTaskProgress !== ProjectTaskProgress.Complete
                "
              >
                <el-badge
                  :value="
                    computedNotReadCommentCount(ProjectTaskCommentModule.MainCategory)
                  "
                  class="notread-comment-count"
                  v-if="
                    computedNotReadCommentCount(ProjectTaskCommentModule.MainCategory) > 0
                  "
                >
                  <el-button
                    type="primary"
                    circle
                    size="small"
                    :icon="ChatDotSquare"
                    @click="
                      handleCommentClick(
                        ProjectTaskCommentModule.MainCategory,
                        t(
                          'Components.Project.ProjectTask.Report1.DiscoveryDescription.MainCategory'
                        )
                      )
                    "
                  ></el-button>
                </el-badge>
                <el-button
                  v-else
                  type="primary"
                  circle
                  size="small"
                  :icon="ChatDotSquare"
                  @click="
                    handleCommentClick(
                      ProjectTaskCommentModule.MainCategory,
                      t(
                        'Components.Project.ProjectTask.Report1.DiscoveryDescription.MainCategory'
                      )
                    )
                  "
                ></el-button>
              </el-badge>
            </div>
            <div v-else>
              <el-button
                v-if="
                  props.projectTaskProgress !== ProjectTaskProgress.ToBeAllocated &&
                  props.projectTaskProgress !== ProjectTaskProgress.Ready &&
                  props.projectTaskProgress !== ProjectTaskProgress.AuditImplementation &&
                  props.projectTaskProgress !== ProjectTaskProgress.ReportWriting &&
                  props.projectTaskProgress !== ProjectTaskProgress.Complete
                "
                type="primary"
                circle
                size="small"
                :icon="ChatDotSquare"
                @click="
                  handleCommentClick(
                    ProjectTaskCommentModule.MainCategory,
                    t(
                      'Components.Project.ProjectTask.Report1.DiscoveryDescription.MainCategory'
                    )
                  )
                "
              ></el-button>
            </div>
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td class="label-bold">
        {{ t("Components.Project.ProjectTask.Report1.DiscoveryDescription.SubCategory") }}
      </td>
      <td>
        <ul class="td-ul">
          <li class="td-ul-li-left">
            <span
              ref="subCategoryRef"
              v-if="!categoryIsEdit || !computedCanEdit"
              @dblclick="handleCategoryClick"
              >{{ props.groupData.sub_category_name }}</span
            >
            <div class="dd" v-else>
              <el-select
                v-model="subCategoryData"
                :placeholder="
                  t(
                    'Components.Project.ProjectTask.Report1.DiscoveryDescription.PleaseSelectSubCategory'
                  )
                "
              >
                <el-option
                  v-for="item in currentSubCategorys"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-space>
                <el-button
                  type="primary"
                  circle
                  size="small"
                  :icon="Check"
                  @click.stop="handleCategoryOK"
                ></el-button>
                <el-button
                  circle
                  size="small"
                  type="danger"
                  :icon="Close"
                  @click.stop="handleCategoryCancel"
                ></el-button>
              </el-space>
            </div>
          </li>
          <li class="td-ul-li-right">
            <div v-if="computedCommentCount(ProjectTaskCommentModule.SubCategory) > 0">
              <el-badge
                :value="computedCommentCount(ProjectTaskCommentModule.SubCategory)"
                type="success"
                class="item"
                v-if="
                  props.projectTaskProgress !== ProjectTaskProgress.ToBeAllocated &&
                  props.projectTaskProgress !== ProjectTaskProgress.Ready &&
                  props.projectTaskProgress !== ProjectTaskProgress.AuditImplementation &&
                  props.projectTaskProgress !== ProjectTaskProgress.ReportWriting &&
                  props.projectTaskProgress !== ProjectTaskProgress.Complete
                "
              >
                <el-badge
                  :value="
                    computedNotReadCommentCount(ProjectTaskCommentModule.SubCategory)
                  "
                  class="notread-comment-count"
                  v-if="
                    computedNotReadCommentCount(ProjectTaskCommentModule.SubCategory) > 0
                  "
                >
                  <el-button
                    type="primary"
                    circle
                    size="small"
                    :icon="ChatDotSquare"
                    @click="
                      handleCommentClick(
                        ProjectTaskCommentModule.SubCategory,
                        t(
                          'Components.Project.ProjectTask.Report1.DiscoveryDescription.SubCategory'
                        )
                      )
                    "
                  ></el-button>
                </el-badge>
                <el-button
                  v-else
                  type="primary"
                  circle
                  size="small"
                  :icon="ChatDotSquare"
                  @click="
                    handleCommentClick(
                      ProjectTaskCommentModule.SubCategory,
                      t(
                        'Components.Project.ProjectTask.Report1.DiscoveryDescription.SubCategory'
                      )
                    )
                  "
                ></el-button>
              </el-badge>
            </div>
            <div v-else>
              <el-button
                v-if="
                  props.projectTaskProgress !== ProjectTaskProgress.ToBeAllocated &&
                  props.projectTaskProgress !== ProjectTaskProgress.Ready &&
                  props.projectTaskProgress !== ProjectTaskProgress.AuditImplementation &&
                  props.projectTaskProgress !== ProjectTaskProgress.ReportWriting &&
                  props.projectTaskProgress !== ProjectTaskProgress.Complete
                "
                type="primary"
                circle
                size="small"
                :icon="ChatDotSquare"
                @click="
                  handleCommentClick(
                    ProjectTaskCommentModule.SubCategory,
                    t(
                      'Components.Project.ProjectTask.Report1.DiscoveryDescription.SubCategory'
                    )
                  )
                "
              ></el-button>
            </div>
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td class="label-bold">
        {{ t("Components.Project.ProjectTask.Report1.DiscoveryDescription.Dilatation") }}
      </td>
      <td class="issue-td">
        <ul class="td-ul">
          <li class="td-ul-li-left">
            <div
              ref="descriptionRef"
              id="div-description"
              class="div-summary"
              v-for="(item, index) in computedElaborate1"
              :key="item.random"
            >
              <SpanInput
                :type="item.type"
                :data="item"
                :projectDiscoveryData="props.projectDiscoveryData"
                :projectTaskGuid="projectTaskGuid"
                :groupData="props.groupData"
                @getProjectTaskDiscoveryAll="() => emits('getProjectTaskDiscoveryAll')"
                :id="nanoid()"
                :projectTaskProgress="props.projectTaskProgress"
                :prefix="props.prefix"
                :index="props.index"
                :before1="index + '.'"
                :commentModuleCount="props.commentModuleCount"
                :canReview="props.canReview"
              />
              <div class="issue-root" v-if="item.content">
                <ul>
                  <li
                    class="li-1 issue-item"
                    v-for="(item1, index1) in item.children"
                    :key="item1.random"
                  >
                    <SpanInput
                      :type="item1.type"
                      :data="item1"
                      :projectDiscoveryData="props.projectDiscoveryData"
                      :projectTaskGuid="projectTaskGuid"
                      :groupData="props.groupData"
                      @getProjectTaskDiscoveryAll="
                        () => emits('getProjectTaskDiscoveryAll')
                      "
                      :id="nanoid()"
                      :projectTaskProgress="props.projectTaskProgress"
                      :prefix="props.prefix"
                      :index="props.index"
                      :before1="index + '.' + (index1 + 1) + '.'"
                      :commentModuleCount="props.commentModuleCount"
                      :canReview="props.canReview"
                    />
                    <ul v-if="item1.children && item1.children.length > 1">
                      <li
                        class="li-2 issue-item"
                        v-for="(item2, index2) in item1.children"
                        :key="item2.random"
                      >
                        <SpanInput
                          :type="item2.type"
                          :data="item2"
                          :projectDiscoveryData="props.projectDiscoveryData"
                          :projectTaskGuid="projectTaskGuid"
                          :groupData="props.groupData"
                          @getProjectTaskDiscoveryAll="
                            () => emits('getProjectTaskDiscoveryAll')
                          "
                          :id="nanoid()"
                          :projectTaskProgress="props.projectTaskProgress"
                          :prefix="props.prefix"
                          :index="props.index"
                          :before1="index + '.' + (index1 + 1) + '.' + (index2 + 1) + '.'"
                          :commentModuleCount="props.commentModuleCount"
                          :canReview="props.canReview"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td class="label-bold">
        {{ t("Components.Project.ProjectTask.Report1.DiscoveryDescription.Reference") }}
      </td>
      <td @dblclick="handleRefertoClick">
        <ul class="td-ul">
          <li class="td-ul-li-left">
            <span ref="refertoRef" v-if="!refertoEdit || !computedCanEdit">
              <span
                class="1"
                v-if="refertoSourceData"
                :style="{ whiteSpace: 'pre-wrap' }"
              >
                {{ refertoSourceData }}
              </span>
              <span v-else :style="{ color: '#D3D3D3' }">
                {{
                  t(
                    "Components.Project.ProjectTask.Report1.DiscoveryDescription.NoContent"
                  )
                }}
              </span>
            </span>
            <div class="dd" v-else>
              <el-input
                v-model="refertoEditingData"
                type="textarea"
                :placeholder="
                  t(
                    'Components.Project.ProjectTask.Report1.DiscoveryDescription.PleaseInputContent'
                  )
                "
              />
              <el-space>
                <el-button
                  type="primary"
                  circle
                  size="small"
                  :icon="Check"
                  @click.stop="handleRefertoOkClick"
                ></el-button>
                <el-button
                  circle
                  size="small"
                  type="danger"
                  :icon="Close"
                  @click.stop="handleRefertoCancelClick"
                ></el-button>
              </el-space>
            </div>
          </li>
          <li class="td-ul-li-right">
            <div v-if="computedCommentCount(ProjectTaskCommentModule.ReferTo) > 0">
              <el-badge
                :value="computedCommentCount(ProjectTaskCommentModule.ReferTo)"
                class="item"
                type="success"
                v-if="
                  props.projectTaskProgress !== ProjectTaskProgress.ToBeAllocated &&
                  props.projectTaskProgress !== ProjectTaskProgress.Ready &&
                  props.projectTaskProgress !== ProjectTaskProgress.AuditImplementation &&
                  props.projectTaskProgress !== ProjectTaskProgress.ReportWriting &&
                  props.projectTaskProgress !== ProjectTaskProgress.Complete
                "
              >
                <el-badge
                  :value="computedNotReadCommentCount(ProjectTaskCommentModule.ReferTo)"
                  class="notread-comment-count"
                  v-if="computedNotReadCommentCount(ProjectTaskCommentModule.ReferTo) > 0"
                >
                  <el-button
                    type="primary"
                    circle
                    size="small"
                    :icon="ChatDotSquare"
                    @click="
                      handleCommentClick(
                        ProjectTaskCommentModule.ReferTo,
                        t(
                          'Components.Project.ProjectTask.Report1.DiscoveryDescription.Reference'
                        )
                      )
                    "
                  ></el-button>
                </el-badge>
                <el-button
                  v-else
                  type="primary"
                  circle
                  size="small"
                  :icon="ChatDotSquare"
                  @click="
                    handleCommentClick(
                      ProjectTaskCommentModule.ReferTo,
                      t(
                        'Components.Project.ProjectTask.Report1.DiscoveryDescription.Reference'
                      )
                    )
                  "
                ></el-button>
              </el-badge>
            </div>
            <div v-else>
              <el-button
                v-if="
                  props.projectTaskProgress !== ProjectTaskProgress.ToBeAllocated &&
                  props.projectTaskProgress !== ProjectTaskProgress.Ready &&
                  props.projectTaskProgress !== ProjectTaskProgress.AuditImplementation &&
                  props.projectTaskProgress !== ProjectTaskProgress.ReportWriting &&
                  props.projectTaskProgress !== ProjectTaskProgress.Complete
                "
                type="primary"
                circle
                size="small"
                :icon="ChatDotSquare"
                @click="
                  handleCommentClick(
                    ProjectTaskCommentModule.ReferTo,
                    t(
                      'Components.Project.ProjectTask.Report1.DiscoveryDescription.Reference'
                    )
                  )
                "
              ></el-button>
            </div>
          </li>
        </ul>
      </td>
    </tr>
  </table>
  <DescriptionModify v-if="descriptionModifyVisible" />
</template>
<script setup>
import {
  ref,
  defineProps,
  computed,
  defineEmits,
  onMounted,
  onBeforeUnmount,
  watchEffect,
  markRaw,
} from "vue";
import { Close, Check, ChatDotSquare, WarnTriangleFilled } from "@element-plus/icons-vue";
import DescriptionModify from "./_DescriptionModify.vue";
import SpanInput from "./_SpanInput.vue";
import {
  request_projecttask_discoverydescription_grading,
  request_projecttask_discoverydescription_category,
  request_projecttask_discoverydescription_referto,
} from "@/api/apis";
import { ElMessage, ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";
import { nanoid } from "nanoid";
import pubsub from "pubsub-js";
import PubsubMessage from "@/consts/pubsubMessage";
import {
  ProjectTaskProgress,
  ProjectTaskCommentModule,
  ProjectTaskCommentElaborate,
} from "@/consts/enum";

const { t } = useI18n();
const emits = defineEmits(["getProjectTaskDiscoveryAll", "getCommentModuleCount",'getProjectTaskDiscoveryDescription']);
const props = defineProps({
  data: {
    type: Object,
    default: () => undefined,
  },
  groups: {
    type: Object,
    default: () => {},
  },
  groupData: {
    type: Object,
    default: () => {},
  },
  projectTaskGuid: {
    type: String,
    default: () => "",
  },
  index: {
    type: Number,
    default: () => 0,
  },
  discoverydGradingDics: {
    type: Array,
    default: () => [],
  },
  mainCategorys: {
    type: Array,
    default: () => [],
  },
  subCategorys: {
    type: Array,
    default: () => [],
  },
  projectDiscoveryData: {
    type: Array,
    default: () => [],
  },
  projectTaskProgress: {
    type: Number,
    default: () => 0,
  },
  commentModuleCount: {
    type: Array,
    default: () => [],
  },
  prefix: {
    type: String,
    default: () => "",
  },
  canReview:{
    type: Boolean,
    default: ()=>false
  },
  projectTaskDiscoveryDescriptions:{
    type: Object,
    default: ()=> undefined
  }
});
// 分级是否处理修改状态
const gradingIsEdit = ref(false);
// 分级数据
const gradingData = ref(props.groupData.grading);

// 分类是否修改
const categoryIsEdit = ref(false);
// 主分类选中的值
const mainCategoryData = ref(props.groupData.main_category);
// 子分类下拉框选择
const currentSubCategorys = ref([]);
// 子分类选中的值
const subCategoryData = ref(props.groupData.sub_category);

// 参考是否编辑
const refertoEdit = ref(false);
// 参考原始内容
const refertoSourceData = ref("");
// 参考编辑中的内容
const refertoEditingData = ref("");
const descriptionModifyVisible = ref(false);

const gradingRef = ref();
const mainCategoryRef = ref();
const subCategoryRef = ref();
const descriptionRef = ref();
const refertoRef = ref();

console.log(props.projectTaskDiscoveryDescriptions)
// 根据字典值处理名称
const computedGrading = computed(() => {
  const findData = props.discoverydGradingDics.find(
    (item) => item.value === props.groupData.grading
  );
  if (findData) {
    return findData.name;
  }
  return "";
});
// 计算详述 type = 1代表概述；2：代表筛选号；3：代表详述；4：代表概述
const computedElaborate1 = computed(() => {
  let processProjectTaskDiscoveryData = []; // 处理完成的
  // const allProjectTaskDiscoveryData = []; // 所有的发现数据
  // 找到当前分类分级下的发现数据
  const findCurrentCategoryGradingDiscoveryData = props.projectDiscoveryData.filter(
    (item) =>
      item.main_category === props.groupData.main_category &&
      item.sub_category === props.groupData.sub_category &&
      item.grading === props.groupData.grading
  );
  if (
    findCurrentCategoryGradingDiscoveryData &&
    findCurrentCategoryGradingDiscoveryData.length > 0
  ) {
    // 将问题概述加进去
    processProjectTaskDiscoveryData.push({
      data: findCurrentCategoryGradingDiscoveryData[0],
      content: findCurrentCategoryGradingDiscoveryData[0].problem_overview,
      type: ProjectTaskCommentElaborate.Overview,
      children: [],
      random: nanoid(),
    });
    // 循环当前分类分级下的发现数据
    findCurrentCategoryGradingDiscoveryData.forEach((item) => {
      // 判断缓存是否已有这个问题归纳
      const findCurrentCacheProblemSummary = processProjectTaskDiscoveryData.find(
        (m) =>
          m.content === item.problem_summary && item.problem_summary &&
          m.type === ProjectTaskCommentElaborate.Summary
      );
      if (findCurrentCacheProblemSummary) {
        // 缓存中已有这个问题归纳数据了
        if (!findCurrentCacheProblemSummary.children) {
          findCurrentCacheProblemSummary.children = [];
        }
        // 确认缓存中这个筛选号是否已经存在了
        if (item.filter_number) {
          // 如果有筛选号
          const findCurrentCacheFilterNumber = findCurrentCacheProblemSummary.children.find(
            (m) => m.content === item.filter_number
          );
          if (findCurrentCacheFilterNumber) {
            // 如果这个筛选号数据也已存在
            if (!findCurrentCacheFilterNumber.children) {
              findCurrentCacheFilterNumber.children = [];
            }
            if (item.problem_details) {
              // 如果存在详情
              findCurrentCacheFilterNumber.children.push({
                data: item,
                content: item.problem_details,
                type: ProjectTaskCommentElaborate.Detail,
                children: [],
                random: nanoid(),
              });
            }
          } else {
            // 这个筛选号数据不存在
            // 就需要将这个筛选号数据添加进去
            const filterData = {
              data: item,
              content: item.filter_number,
              type: ProjectTaskCommentElaborate.FilterNumber,
              children: [],
              random: nanoid(),
            };
            if (item.problem_details) {
              // 存在问题详述
              filterData.children.push({
                data: item,
                content: item.problem_details,
                type: ProjectTaskCommentElaborate.Detail,
                children: [],
                random: nanoid(),
              });
            }
            findCurrentCacheProblemSummary.children.push(filterData);
          }
        } else {
          // 如果没有筛选号，则查找详情
          if (item.problem_details) {
            // 存在问题详述
            findCurrentCacheProblemSummary.children.push({
              data: item,
              content: item.problem_details,
              type: ProjectTaskCommentElaborate.Detail,
              children: [],
              random: nanoid(),
            });
          }
        }
      } else {
        // 缓存中还没有这个问题归纳数据
        // 需要将这个问题归纳数据添加进去
        const summaryData = {
          data: item,
          content: item.problem_summary,
          type: ProjectTaskCommentElaborate.Summary,
          children: [],
          random: nanoid(),
        };

        if (item.filter_number) {
          // 如果有筛选号
          const filterNumberData = {
            data: item,
            content: item.filter_number,
            type: ProjectTaskCommentElaborate.FilterNumber,
            children: [],
            random: nanoid(),
          };
          if (item.problem_details) {
            // 存在问题详述
            filterNumberData.children.push({
              data: item,
              content: item.problem_details,
              type: ProjectTaskCommentElaborate.Detail,
              children: [],
              random: nanoid(),
            });
          }
          summaryData.children.push(filterNumberData);
        } else {
          // 如果没有筛选号
          if (item.problem_details) {
            summaryData.children.push({
              data: item,
              content: item.problem_details,
              type: ProjectTaskCommentElaborate.Detail,
              children: [],
              random: nanoid(),
            });
          }
        }
        processProjectTaskDiscoveryData.push(summaryData);
      }
    });
  }
  processProjectTaskDiscoveryData.forEach((item) => {
    // 循环所有问题简述
    item.children.forEach((item1) => {
      // 循环所有问题筛选号
      item1.children = item1.children.sort((a, b) => {
        return a.children.length - b.children.length;
        // return a.content - b.content;
      });
    });
    item.children = item.children.sort((a, b) => {
      // return a.children.length - b.children.length;
        return a.content - b.content;
    });
  });
  processProjectTaskDiscoveryData = processProjectTaskDiscoveryData.sort((a, b) => {
    return a.children.length - b.children.length;
  });
  // if(props.groupData.main_category === '生物样本管理'){
  //   console.log(processProjectTaskDiscoveryData)
  // }

  return processProjectTaskDiscoveryData;
});

// 是否可以编辑
const computedCanEdit = computed(() => {
  return (
    props.projectTaskProgress === ProjectTaskProgress.ReportWriting ||
    props.projectTaskProgress === ProjectTaskProgress.Rewrite
  );
});

// 计算模块评论数量
const computedCommentCount = computed(() => (module) => {
  if (props.commentModuleCount && props.commentModuleCount.length > 0) {
    const findCommentModuleCountData = props.commentModuleCount.find(
      (item) =>
        item.module === module &&
        item.grading === props.groupData.grading &&
        item.main_category === props.groupData.main_category &&
        item.sub_category === props.groupData.sub_category
    );
    if (findCommentModuleCountData) {
      return findCommentModuleCountData.count;
    }
  }
  return 0;
});
// 计算模块未读评论数量
const computedNotReadCommentCount = computed(() => (module) => {
  if (props.commentModuleCount && props.commentModuleCount.length > 0) {
    const findCommentModuleCountData = props.commentModuleCount.find(
      (item) =>
        item.module === module &&
        item.grading === props.groupData.grading &&
        item.main_category === props.groupData.main_category &&
        item.sub_category === props.groupData.sub_category
    );
    if (findCommentModuleCountData) {
      // return findCommentModuleCountData.not_read_count;
      if(props.canReview) return findCommentModuleCountData.not_read_count_for_approver
      else  return findCommentModuleCountData.not_read_count;
    }
  }
  return 0;
});

onMounted(() => {});
// 处理分级完成
const handleGradingOK = async () => {
  const trans = {
    source_grading: props.groupData.grading,
    new_grading: gradingData.value,
    main_category: props.groupData.main_category,
    sub_category: props.groupData.sub_category,
  };
  await request_projecttask_discoverydescription_grading(props.projectTaskGuid, trans);
  // 页面即将刷新，下面的代码不需要了
  emits("getProjectTaskDiscoveryAll");
  emits("getCommentModuleCount");
  emits('getProjectTaskDiscoveryDescription')
  gradingIsEdit.value = false;

  // location.reload();
};
// 处理分级点击事件
const handleGradingClick = () => {
  categoryIsEdit.value = false;
  gradingIsEdit.value = true;
  gradingData.value = props.groupData.grading;
};
// 处理分级取消
const handleGradingCancel = () => {
  gradingIsEdit.value = false;
};
// 处理分离点击事件
const handleCategoryClick = () => {
  gradingIsEdit.value = false;
  refertoEdit.value = false;
  categoryIsEdit.value = true;
  mainCategoryData.value = props.groupData.main_category;
  handleCategoryChange(props.groupData.main_category);
  subCategoryData.value = props.groupData.sub_category;
  pubsub.publish(PubsubMessage.ProjectTaskReportDiscoveryClearOtherEdit, "");
};
// 处理参考点击
const handleRefertoClick = () => {
  gradingIsEdit.value = false;
  refertoEdit.value = false;
  categoryIsEdit.value = false;
  pubsub.publish(PubsubMessage.ProjectTaskReportDiscoveryClearOtherEdit, "");
  refertoEdit.value = true;
  refertoEditingData.value = refertoSourceData.value;
};
// 处理分类取消
const handleCategoryCancel = () => {
  categoryIsEdit.value = false;
};
// 处理主分类改变事件
const handleCategoryChange = (value) => {
  subCategoryData.value = undefined;
  const findMainCategory = props.mainCategorys.find((item) => item.value === value);
  if (findMainCategory) {
    const findSubCategorys = props.subCategorys.filter((item) => item.remark === value);
    if (findSubCategorys && findSubCategorys.length > 0) {
      currentSubCategorys.value = findSubCategorys;
    } else {
      currentSubCategorys.value = [];
    }
  } else {
    currentSubCategorys.value = [];
  }
};
// 处理分类OK
const handleCategoryOK = async () => {
  // 判断主分类是否选择
  if (!mainCategoryData.value) {
    ElMessage.warning(
      t(
        "Components.Project.ProjectTask.Report1.DiscoveryDescription.PleaseSelectMainCategory"
      )
    );
    return;
  }
  if (!subCategoryData.value) {
    ElMessage.warning(
      t(
        "Components.Project.ProjectTask.Report1.DiscoveryDescription.PleaseSelectSubCategory"
      )
    );
    return;
  }
  // 校验主分类和子分类是否在其中
  const findMainCategory = props.mainCategorys.find(
    (item) => item.value == mainCategoryData.value
  );
  if (!findMainCategory) {
    ElMessage.warning(
      t(
        "Components.Project.ProjectTask.Report1.DiscoveryDescription.MainCategoryNotInDic"
      )
    );
    return;
  }
  const findSubCagtegory = props.subCategorys.find(
    (item) =>
      item.remark === findMainCategory.value && item.value === subCategoryData.value
  );
  if (!findSubCagtegory) {
    ElMessage.warning(
      t("Components.Project.ProjectTask.Report1.DiscoveryDescription.SubCategoryNotInDic")
    );
    return;
  }
  const trans = {
    grading: props.groupData.grading,
    source_main_category: props.groupData.main_category,
    source_sub_category: props.groupData.sub_category,
    new_main_category: mainCategoryData.value,
    new_sub_category: subCategoryData.value,
  };

  await request_projecttask_discoverydescription_category(props.projectTaskGuid, trans);
  // 页面即将刷新，所以下面的功能不需要
  emits("getProjectTaskDiscoveryAll");
  emits("getCommentModuleCount");
  emits('getProjectTaskDiscoveryDescription')
  categoryIsEdit.value = false;
};
const processCategoryUpdate = async () => {};
onBeforeUnmount(() => {
  pubsub.unsubscribe(PubsubMessage.ProjectTaskReportDiscoveryClearOtherEdit);
});
// 评论点击
const handleCommentClick = (commentModule, subTitle, relateGuid, elaborateModule) => {
  let contentDom = undefined;
  const data = {
    projectTaskProgress: props.projectTaskProgress,
    module: commentModule,
    title:
      t("Components.Project.ProjectTask.Report1.DiscoveryDescription.Title") +
      "→" +
      subTitle,
    relateGuid,
    grading: props.groupData.grading,
    mainCategory: props.groupData.main_category,
    subCategory: props.groupData.sub_category,
    contentDom,
  };
  if (commentModule === ProjectTaskCommentModule.Grading) {
    contentDom = gradingRef.value;
  } else if (commentModule === ProjectTaskCommentModule.MainCategory) {
    contentDom = mainCategoryRef.value;
  } else if (commentModule === ProjectTaskCommentModule.SubCategory) {
    contentDom = subCategoryRef.value;
  } else if (commentModule === ProjectTaskCommentModule.Elaborate) {
    contentDom = descriptionRef.value[0];
    data.elaborateModule = elaborateModule;
  } else if (commentModule === ProjectTaskCommentModule.ReferTo) {
    contentDom = refertoRef.value;
  }
  pubsub.publish(PubsubMessage.ProjectTaskReportComment, data);
};
// 处理参考点击事件
const handleRefertoOkClick = async () => {
  const trans = {
    text: refertoEditingData.value,
    main_category: mainCategoryData.value,
    sub_category: subCategoryData.value,
    grading: gradingData.value,
    refer_to: refertoEditingData.value,
    // source_grading: 
  };
  await request_projecttask_discoverydescription_referto(props.projectTaskGuid, trans);
  refertoSourceData.value = refertoEditingData.value;
  refertoEdit.value = false;
  // 更新原始数据
  if(props.data && props.data.discovery_descriptions && props.data.discovery_descriptions.length > 0){
    const findData = props.data.discovery_descriptions.filter(m=>m.grading == trans.grading && m.main_category == trans.main_category && m.sub_category == trans.sub_category);
    if(findData && findData.length > 0){
      findData.forEach(item=>{
        item.refer_to = trans.refer_to
      })
    }
  }
  emits('getProjectTaskDiscoveryDescription')
};
const handleRefertoCancelClick = async () => {
  refertoEditingData.value = "";
  refertoEdit.value = false;
};
watchEffect(() => {
  if (
    props.projectTaskDiscoveryDescriptions &&
    props.projectTaskDiscoveryDescriptions.length > 0
  ) {
    const findData = props.projectTaskDiscoveryDescriptions.find(
      (item) =>
        item.main_category === mainCategoryData.value &&
        item.sub_category === subCategoryData.value &&
        item.grading === gradingData.value
    );
    if (findData) {
      refertoSourceData.value = findData.refer_to;
    }
  }
});
</script>
<style scoped lang="scss">
.notread-comment-count {
  ::v-deep .el-badge__content {
    margin-top: 20px;
    font-size: 0.8em;
  }
}
table {
  // counter-reset: issueItemCount; /* 创建一个计数器 issueItemCount */
  // border: 1px solid #000000;
  border-collapse: collapse;
  margin-bottom: 28px;
  tr {
    min-height: 54px;
    td {
      padding: 12px;
      line-height: 30px;
      align-items: center;
      border: 1px solid #000000;
      word-break: break-all;
      .dd {
        display: flex;
        align-items: center;
        .el-space {
          margin-left: 4px;
        }
      }
      .td-ul {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        .td-ul-li-left {
          flex: 1;
          counter-reset: summaryCount; /* 创建一个计数器issueCount */
          .div-summary {
            position: relative;
            // &::before {
            //   counter-increment: summaryCount; /* 将变量递增（默认从1开始） */
            //   content: counter(summaryCount) ".";
            // }
          }
        }
        .td-ul-li-right {
          width: 50px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        .td-ul-li-right-not-center {
          align-items: flex-start !important;
        }
      }
    }
    .label-bold {
      font-weight: 600;
    }
    .issue-td {
      padding-left: 0 !important;
      p {
        font-weight: 600;
      }
      .issue-root {
        counter-reset: issueCount issueItemCount; /* 创建一个计数器issueCount */
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          .issue-item {
            box-sizing: border-box;
            padding-right: 20px;
          }
          .li-1 {
            // &::before {
            //   counter-increment: issueCount; /* 将变量递增（默认从1开始） */
            //   counter-reset: issueItemCount;
            //   content: counter(summaryCount) "." counter(issueCount) "."
            //     " 筛选号";
            // }
          }
          .li-2 {
            // &::before {
            //   counter-increment: issueItemCount; /* 将变量递增（默认从1开始） */
            //   content: counter(summaryCount) "." counter(issueCount) "."
            //     counter(issueItemCount) ".";
            // }
          }
          span {
            padding-left: 1em;
          }
        }
      }
    }
  }
}
</style>
