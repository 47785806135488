<template>
  <Dialog
    width="40%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form :model="menuButtonForm" label-width="80px" :rules="rules" ref="menuButtonRef">
      <el-form-item :label="$t('Components.System.Menu.CNName')" prop="cn_name">
        <el-input v-model="menuButtonForm.cn_name" />
      </el-form-item>
      <el-form-item :label="$t('Components.System.Menu.ENName')" prop="en_name">
        <el-input v-model="menuButtonForm.en_name" />
      </el-form-item>
      <el-form-item :label="$t('Components.System.Menu.Code')" prop="code">
        <el-input v-model="menuButtonForm.code" />
      </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup >
import {ref, defineProps, onMounted, reactive,markRaw,defineEmits} from 'vue'
import { useI18n } from "vue-i18n";
import {request_menubutton_add, request_menubutton_modify} from '@/api/apis'
import Dialog from '@/components/Common/Dialog/DialogIndex.vue'
import { ElMessageBox } from "element-plus";
import {WarnTriangleFilled} from '@element-plus/icons-vue'

const { t } = useI18n();
const props = defineProps({
  lang: {
    type: String,
    default: () => "zh-cn",
  },
  editMenuButtonData: {
    type: Object,
    default: () => {},
  },
  menuGuid:{
    type: String,
    default: ()=> ''
  }
});
const emits = defineEmits(["handleCloseDialog1", "getMenuButtonAll"]);
// 标题
const dialogTitle = ref("");
const menuButtonForm = reactive({
  cn_name: props.editMenuButtonData.cn_name,
  en_name: props.editMenuButtonData.en_name,
  code: props.editMenuButtonData.code,
});
const menuButtonRef = ref();

// 表单校验规则
const rules = ref({
  cn_name: [
    {
      required: true,
      message: t("Components.System.Menu.ValidateMenuCNNameRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.Menu.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  en_name: [
    {
      required: true,
      message: t("Components.System.Menu.ValidateMenuENNameRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.Menu.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  code: [
    {
      required: true,
      message: t("Components.System.Menu.ValidateMenuCodeRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.Menu.ValidateMaxLength"),
      trigger: "change",
    },
  ],
});

onMounted(() => {
  if (props.editMenuButtonData && props.editMenuButtonData.guid) {
    // 代表修改
    dialogTitle.value = t("Components.System.Menu.DialogMenuButtonTitleMaintance");
  } else {
    dialogTitle.value = t("Components.System.Menu.DialogMenuButtonTitleAdd");
  }
});
// 处理提交事件
const handleCommit = async () => {
  // 校验
  if (!menuButtonRef.value) return;
  await menuButtonRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(t('Components.System.Menu.ConfirmSaveMenuButton'), t('Common.ElMessageBox.OperateConfirm'), {
        confirmButtonText: t('Common.ElMessageBox.ConfirmText'),
        cancelButtonText: t('Common.ElMessageBox.CancelText'),
        type: "warning",
        icon: markRaw(WarnTriangleFilled),
      })
        .then(async () => {
          await saveMenuButton();
        })
        .catch(() => {});
    }
  });
  return;
};
// 保存菜单按钮数据
const saveMenuButton = async () => {
  const trans = {
    code: menuButtonForm.code,
    cn_name: menuButtonForm.cn_name,
    en_name: menuButtonForm.en_name,
    menu_guid: props.menuGuid
  };
  if (props.editMenuButtonData && props.editMenuButtonData.guid) {
    // 修改
    await request_menubutton_modify(props.editMenuButtonData.guid, trans);
  } else {
    // 新增
    await request_menubutton_add(trans);
  }
  emits("getMenuButtonAll", props.menuGuid);
  emits("handleCloseDialog1");
};
const handleCancle = () => {
  emits('handleCloseDialog1')
};
</script>
<style scoped lang='scss'>
</style>