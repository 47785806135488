<template>
  <ul class="menu-right-ul">
    <li class="hm-search">
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item :label="$t('Components.System.Menu.Search')">
          <el-input
            :disabled="!menuGuid"
            v-model="searchForm.inputSearch"
            :placeholder="$t('Components.System.Menu.NameCode')"
            clearable
          />
        </el-form-item>
        <el-form-item :label="$t('Components.System.Menu.Dataflag')">
          <el-select
            :disabled="!menuGuid"
            v-model="searchForm.dataflag"
            :placeholder="$t('Components.System.Menu.SelectDataflag')"
            clearable
          >
            <el-option
              :label="$t('Components.System.Menu.DataflagY')"
              value="true"
            />
            <el-option
              :label="$t('Components.System.Menu.DataflagN')"
              value="false"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            :disabled="!menuGuid"
            @click="queryClick"
            :icon="Search"
            >{{ $t("Components.System.Menu.Search1") }}</el-button
          >
          <el-button
            @click="resetClick"
            :disabled="!menuGuid"
            :icon="RefreshLeft"
            >{{ $t("Components.System.Menu.Reset") }}</el-button
          >
        </el-form-item>
      </el-form>
    </li>
    <li class="hm-operate">
      <!-- 操作部分 -->
      <el-button
        :disabled="!menuGuid"
        :style="{ color: 'white' }"
        :icon="Plus"
        :color="ButtonColor.Add"
        @click="
          () => {
            dialogVisible = true;
          }
        "
        >{{ $t("Components.System.Menu.Add") }}</el-button
      >
    </li>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <el-table
          :data="tableData"
          row-key="guid"
          stripe
          v-loading="tableLoading"
        >
          <el-table-column
            prop="cn_name"
            min-width="120"
            :label="$t('Components.System.Menu.CNName')"
          />
          <el-table-column
            prop="en_name"
            min-width="120"
            :label="$t('Components.System.Menu.ENName')"
          />
          <el-table-column
            prop="code"
            min-width="120"
            :label="$t('Components.System.Menu.Code')"
          />
          <el-table-column
            prop="dataflag"
            :label="$t('Components.System.Menu.Dataflag')"
            width="80"
          >
            <template #default="scope">
              <el-tag v-if="scope.row.dataflag" type="" effect="dark">{{
                $t("Components.System.Menu.DataflagY")
              }}</el-tag>
              <el-tag
                v-else
                :style="{ color: 'white' }"
                :color="ButtonColor.DataflagN"
                >{{ $t("Components.System.Menu.DataflagN") }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('Components.System.Menu.Operate')"
            width="200"
            fixed="right"
          >
            <template #default="scope">
              <el-space wrap>
                <el-button
                  :style="{ color: 'white' }"
                  :icon="Edit"
                  :color="ButtonColor.Edit"
                  size="small"
                  @click="handleEditMenuButton(scope.row)"
                  >{{ $t("Components.System.Menu.Edit") }}</el-button
                >
                <el-button
                  :style="{ color: 'white' }"
                  :icon="Delete"
                  :color="ButtonColor.Delete"
                  size="small"
                  bg
                  @click="handleDelete(scope.row)"
                  >{{ $t("Components.System.Menu.Delete") }}</el-button
                >
                <el-button
                  v-if="!scope.row.dataflag"
                  :style="{ color: 'white' }"
                  :icon="Bell"
                  :color="ButtonColor.DataflagY"
                  size="small"
                  @click="handleDataflag(scope.row)"
                  >{{ $t("Components.System.Menu.SetDataflagY") }}</el-button
                >
                <el-button
                  v-else
                  :style="{ color: 'white' }"
                  :icon="Bell"
                  :color="ButtonColor.DataflagN"
                  size="small"
                  @click="handleDataflag(scope.row)"
                  >{{ $t("Components.System.Menu.SetDataflagN") }}</el-button
                >
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </li>
      <li class="menu-right-ul-li-page"></li>
    </el-scrollbar>
  </ul>

  <MenuButtonModify
    v-if="dialogVisible && menuGuid"
    @handleCloseDialog1="handleCloseDialog"
    :editMenuButtonData="editMenuButtonData"
    @getMenuButtonAll="getMenuButtonAll"
    :menuGuid="menuGuid"
  />
</template>
<script setup>
import { reactive, ref, markRaw, defineExpose } from "vue";
import { ButtonColor } from "@/consts/frame";
import {
  Search,
  RefreshLeft,
  Plus,
  Edit,
  Delete,
  Bell,
  WarnTriangleFilled,
} from "@element-plus/icons-vue";
import {
  request_menubutton_all,
  request_menubutton_dataflag,
  request_menubutton_delete,
} from "@/api/apis";
import MenuButtonModify from "@/components/System/Menu/MenuButtonModify.vue";
import { useI18n } from "vue-i18n";
import { ElMessageBox } from "element-plus";

const { t } = useI18n();
const searchForm = reactive({
  inputSearch: "",
  dataflag: undefined,
});
// 菜单按钮数据
const tableData = ref([]);
// 列表加载
const tableLoading = ref(false);
// 编辑的菜单数据
const editMenuButtonData = ref({});
// 当前激活的菜单guid
const menuGuid = ref("");
// 编辑弹窗是否可见
const dialogVisible = ref("");
// 获取菜单下的所有菜单按钮
const getMenuButtonAll = async (guid) => {
  menuGuid.value = guid;
  const trans = {
    text: searchForm.inputSearch,
    dataflag: searchForm.dataflag,
    menu_guid: guid,
  };
  tableLoading.value = true;
  const result = await request_menubutton_all(trans, () => {
    tableLoading.value = false;
  });
  tableData.value = result;
};
// 处理关闭事件
const handleCloseDialog = () => {
  dialogVisible.value = false;
  editMenuButtonData.value = {};
};
// 查询事件
const queryClick = async () => {
  await getMenuButtonAll(menuGuid.value);
};
// 重置事件
const resetClick = async () => {
  searchForm.inputSearch = "";
  searchForm.dataflag = undefined;
  await getMenuButtonAll(menuGuid.value);
};
// 清空
const clearInfo = () => {
  menuGuid.value = "";
  tableData.value = [];
  searchForm.inputSearch = "";
  searchForm.dataflag = undefined;
};
// 编辑菜单按钮
const handleEditMenuButton = (row) => {
  editMenuButtonData.value = {
    guid: row.guid,
    cn_name: row.cn_name,
    en_name: row.en_name,
    code: row.code,
  };
  dialogVisible.value = true;
};
// 处理设置菜单按钮有效性
const handleDataflag = async (row) => {
  let title = "";
  if (row.dataflag)
    title = t("Components.System.Menu.ConfirmMenuButtonDataflagN");
  else title = t("Components.System.Menu.ConfirmMenuButtonDataflagY");
  ElMessageBox.confirm(title, t("Common.ElMessageBox.OperateConfirm"), {
    confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
    cancelButtonText: t("Common.ElMessageBox.CancelText"),
    type: "warning",
    icon: markRaw(WarnTriangleFilled),
  })
    .then(async () => {
      await request_menubutton_dataflag(row.guid);
      dialogVisible.value = false;
      await getMenuButtonAll(menuGuid.value);
    })
    .catch(() => {});
};
// 处理删除菜单事件
const handleDelete = async (row) => {
  ElMessageBox.confirm(
    t("Components.System.Menu.ConfirmDeleteMenuButton"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      await request_menubutton_delete(row.guid);
      dialogVisible.value = false;
      await getMenuButtonAll(menuGuid.value);
    })
    .catch(() => {});
};
defineExpose({
  getMenuButtonAll,
  clearInfo,
});
</script>
<style>
.menu-root-right-li .el-scrollbar__view {
  height: calc(100% - 8px);
}
</style>

<style scoped lang="scss">
.menu-right-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  .menu-right-ul-li-search {
  }
  .menu-right-ul-li-page {
    height: 8px;
  }
}
</style>
