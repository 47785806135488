<template>
    <Dialog
    width="40%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form :model="form" label-width="120px" :rules="rules" ref="formRef">
      <el-row>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.Project.ProjectTask.Discovery.MainCategory')"
            prop="main_category"
          >
            <el-select
              v-model="form.main_category"
              :placeholder="
                t(
                  'Components.Project.ProjectTask.Discovery.PleaseSelectMainCategory'
                )
              "
              :style="{ width: '100%' }"
              @change="handleMainCategoryChange"
            >
              <el-option
                v-for="item in mainCategorys"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.Project.ProjectTask.Discovery.SubCategory')"
            prop="sub_category"
          >
            <el-select
              v-model="form.sub_category"
              :placeholder="
                t(
                  'Components.Project.ProjectTask.Discovery.PleaseSelectSubCategory'
                )
              "
              :style="{ width: '100%' }"
            >
              <el-option
                v-for="item in currentSubCategorys"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select> </el-form-item
        ></el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.Project.ProjectTask.Discovery.Grading')"
            prop="grading"
          >
            <el-select
              v-model="form.grading"
              :placeholder="
                t(
                  'Components.Project.ProjectTask.Discovery.PleaseSelectGrading'
                )
              "
              :style="{ width: '100%' }"
            >
              <el-option
                v-for="item in props.gradings"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select> </el-form-item
        ></el-col>
        <el-col :span="12">
          <el-form-item
            :label="
              $t('Components.Project.ProjectTask.Discovery.IsImportReport')
            "
            prop="is_import_report"
          >
            <el-switch
              v-model="form.is_import_report"
              class="ml-2"
              style="
                --el-switch-on-color: #13ce66;
                --el-switch-off-color: #ff4949;
              "
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
        :label="$t('Components.Project.ProjectTask.Discovery.ProblemOverview')"
        prop="problem_overview"
      >
        <el-input
          v-model="form.problem_overview"
          :placeholder="
            $t(
              'Components.Project.ProjectTask.Discovery.PleaseInputProblemOverview'
            )
          "
          type="textarea"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('Components.Project.ProjectTask.Discovery.ProblemSummary')"
        prop="problem_summary"
      >
        <el-input
          v-model="form.problem_summary"
          :placeholder="
            $t(
              'Components.Project.ProjectTask.Discovery.PleaseInputProblemSummary'
            )
          "
          type="textarea"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('Components.Project.ProjectTask.Discovery.FilterNumber')"
        prop="filter_number"
      >
        <el-input
          v-model="form.filter_number"
          :placeholder="
            $t(
              'Components.Project.ProjectTask.Discovery.PleaseInputFilterNumber'
            )
          "
          maxlength="255"
          show-word-limit
          type="textarea"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('Components.Project.ProjectTask.Discovery.ProblemDetail')"
        prop="problem_details"
      >
        <el-input
          v-model="form.problem_details"
          :placeholder="
            $t(
              'Components.Project.ProjectTask.Discovery.PleaseInputProblemDetails'
            )
          "
          type="textarea"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('Components.Project.ProjectTask.Discovery.Remark')"
        prop="remark"
      >
        <el-input
          v-model="form.remark"
          :placeholder="
            $t(
              'Components.Project.ProjectTask.Discovery.PleaseInputRemark'
            )
          "
          maxlength="255"
          show-word-limit
          type="textarea"
        ></el-input>
      </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup>
import {
  ref,
  reactive,
  markRaw,
  onMounted,
  defineProps,
  defineEmits,
} from "vue";
import { WarnTriangleFilled } from "@element-plus/icons-vue";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { useI18n } from "vue-i18n";
import {
  request_projecttask_discovery_add,
  request_projecttask_discovery_modify,
} from "@/api/apis";
import { ElMessageBox } from "element-plus";

const { t } = useI18n();
const props = defineProps({
  projectTaskGuid: {
    type: String,
    default: () => "",
  },
  mainCategorys: {
    type: Array,
    default: () => [],
  },
  subCategorys: {
    type: Array,
    default: () => [],
  },
  editData: {
    type: Object,
    default: () => {},
  },
  gradings: {
    type: Array,
    default: () => [],
  },
});

const emits = defineEmits([
  "handleCloseDialog",
  "getProjectTaskDiscoverysPage",
]);
// 标题
const dialogTitle = ref("");
// 表单ref
const formRef = ref();
// 表单数据
const form = reactive({
  main_category: props.editData.main_category,
  sub_category: props.editData.sub_category,
  grading: props.editData.grading,
  filter_number: props.editData.filter_number,
  problem_details: props.editData.problem_details,
  problem_summary: props.editData.problem_summary,
  problem_overview: props.editData.problem_overview,
  remark: props.editData.remark,
  is_import_report: props.editData.is_import_report,
});
const currentSubCategorys = ref([]);

// 表单校验规则
const rules = ref({
  main_category: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.Discovery.PleaseSelectMainCategory"),
      trigger: "blur",
    },
  ],
  sub_category: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.Discovery.PleaseSelectSubCategory"),
      trigger: "blur",
    },
  ],
  grading: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.Discovery.PleaseSelectGrading"),
      trigger: "blur",
    },
  ],
  filter_number: [
    {
      max: 255,
      message: t("Components.Project.ProjectTask.Discovery.ValidateMaxLength255"),
      trigger: "change",
    },
  ],
  problem_overview: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.Discovery.PleaseInputProblemOverview"),
      trigger: "blur",
    },
  ],
  remark: [
    {
      max: 255,
      message: t("Components.Project.ProjectTask.Discovery.ValidateMaxLength255"),
      trigger: "change",
    },
  ],
});


onMounted(async () => {
  if (props.editData && props.editData.guid) {
    // 代表修改
    dialogTitle.value = t(
      "Components.Project.ProjectTask.Discovery.DialogDiscoveryTitleMaintance"
    );
  } else {
    dialogTitle.value = t(
      "Components.Project.ProjectTask.Discovery.DialogDiscoveryTitleAdd"
    );
  }
});
// 处理提交事件
const handleCommit = async () => {
  if (!formRef.value) return;
  await formRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(
        t("Components.Project.ProjectTask.Discovery.ConfirmSaveDiscovery"),
        t("Common.ElMessageBox.OperateConfirm"),
        {
          confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
          cancelButtonText: t("Common.ElMessageBox.CancelText"),
          type: "warning",
          icon: markRaw(WarnTriangleFilled),
        }
      )
        .then(async () => {
          await saveDiscovery();
        })
        .catch(() => {});
    }
  });
};
// 保存被稽查方数据
const saveDiscovery = async () => {
  const trans = {
    project_task_discovery_guid: props.editData.guid,
    main_category: form.main_category,
    sub_category: form.sub_category,
    grading: form.grading,
    filter_number: form.filter_number,
    problem_details: form.problem_details,
    problem_summary: form.problem_summary,
    problem_overview: form.problem_overview,
    remark: form.remark,
    is_import_report: form.is_import_report
  };
  if (props.editData && props.editData.guid) {
    trans.guid = props.editData.guid;
    // 修改
    await request_projecttask_discovery_modify(props.projectTaskGuid, trans);
  } else {
    // 新增
    await request_projecttask_discovery_add(props.projectTaskGuid, trans);
  }
  emits("getProjectTaskDiscoverysPage");
  emits("handleCloseDialog");
};
// 处理主分类改变事件
const handleMainCategoryChange = (value) => {
  form.sub_category = undefined;
  const findMainCategory = props.mainCategorys.find(
    (item) => item.value === value
  );
  if (findMainCategory) {
    const findSubCategorys = props.subCategorys.filter(
      (item) => item.remark === value
    );
    if (findSubCategorys && findSubCategorys.length > 0) {
      currentSubCategorys.value = findSubCategorys;
    } else {
      currentSubCategorys.value = [];
    }
  } else {
    currentSubCategorys.value = [];
  }
};
// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};
</script>
<style>
.modalClass{
    pointer-events: none !important;
}
.el-dialog{
    pointer-events: auto !important;
}
</style>
<style scoped lang="scss"></style>
