<template>
  <Dialog width="40%" :title="$t('Components.Index.Index.SystemSetting')" :showFooter="true"
    @handleCommit="handleCommit" @handleCancle="handleCancle">
    <el-row>
      <el-col :span="18">
        <div class="system-setting-left-root">
          <div class="system-setting-icon">
            <el-icon><i class="iconfont icon-suoping"></i></el-icon>
          </div>
          <div class="system-setting-text">
            <span>{{ $t('Components.Index.Index.LockTime') }}</span>
            <el-tooltip class="box-item" effect="dark" :content="$t('Components.Index.Index.LockTimeTip')"
              placement="top">
              <el-icon>
                <InfoFilled />
              </el-icon>
            </el-tooltip>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="system-setting-right-root">
          <el-input v-model="lockingTime" onkeyup="value=value.replace(/[^\d]/g,'')">
            <template #append>min</template>
          </el-input>
        </div>
      </el-col>
    </el-row>
  </Dialog>
</template>
<script setup >
import { ref, defineExpose, onMounted, defineEmits } from 'vue'
import { request_save_system_setting, request_user_baseinfo } from '@/api/apis'
import Dialog from '@/components/Common/Dialog/DialogIndex.vue'
import {useStore} from '@/store/index'
import { InfoFilled } from '@element-plus/icons-vue'
const emits = defineEmits(['handleCancleDialog'])
const store = useStore()
// 锁定时间
const lockingTime = ref()
// 挂载
onMounted(()=>{
  if(store.userInfo && store.userInfo.system_setting){
    lockingTime.value = store.userInfo.system_setting.lock_time
  }
})
// 处理保存提交事件
const handleCommit = async () => {
  const data = {
    lock_time: lockingTime.value
  }
  await request_save_system_setting(data)
  // 重新获取用户基本信息
  const result = await request_user_baseinfo()
  // 将用户数据缓存起来
  store.setUserInfo(result)
  handleCancle()
}
// 处理关闭
const handleCancle = ()=>{
  emits('handleCancleDialog')
}
defineExpose({
  
})
</script>
<style scoped lang='scss'>
.el-row {
  margin: 20px 0;
  .system-setting-left-root {
    display: flex;

    .system-setting-text {
      padding: 0 10px;
      display: flex;
      align-items: center;

      .el-icon {
        margin-left: 2px;
      }
    }
  }

  .system-setting-right-root {
    .system-setting-input {
      color: red;
    }
  }
}
</style>