<template>
  <Dialog
    width="40%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form :model="form" label-width="80px" :rules="rules" ref="formRef">
      <el-form-item :label="$t('Components.System.Department.Name')" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
      
      <el-form-item :label="$t('Components.System.Department.ParentDepartment')">
        <el-tree-select
          :style="{ width: '100%' }"
          v-model="form.parent_guid"
          :data="props.departmentDataAll"
          node-key="guid"
          check-strictly
          :render-after-expand="false"
          show-checkbox
          :props="{ label: 'name' }"
          :placeholder="$t('Components.System.Department.ChooseParentDepartment')"
          clearable
        />
      </el-form-item>
      <el-form-item
        :label="$t('Components.System.Department.Principle')"
        prop="principle_name"
      >
        <el-input
          v-model="form.principle_name"
          readonly
          :placeholder="$t('Components.System.Department.ChooseUser')"
          class="input-principle"
          :suffix-icon="User"
          @click="() => (chooseUserDialogVisible = true)"
        />
      </el-form-item>
      <el-form-item :label="$t('Components.System.Department.Sort')" prop="sort">
        <el-input-number :style="{ width: '100%' }" v-model="form.sort" />
      </el-form-item>
    </el-form>
    <ChooseUserSingle
      v-if="chooseUserDialogVisible"
      @handleCloseDialog="handleCloseDialog1"
      :chooseUserGuid="form.principle"
      @updateChooseUser="updateChooseUser"
    />
  </Dialog>
</template>
<script setup>
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { defineProps, ref, reactive, onMounted, markRaw , defineEmits} from "vue";
import { ElMessageBox } from "element-plus";
import { WarnTriangleFilled, User} from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import ChooseUserSingle from "@/components/Common/ChooseUser/ChooseUserSingle.vue";
import {request_department_modify, request_department_add} from '@/api/apis'

const { t } = useI18n();
const emits = defineEmits(["getDepartmentAll", "handleCloseDialog"]);
const props = defineProps({
  departmentDataAll: {
    type: Array,
    default: () => [],
  },
  editData: {
    type: Object,
    default: () => {},
  },
  addParentGuid: {
    type: String,
    default: () => "",
  },
});
// 标题
const dialogTitle = ref("");
// 选择用户弹窗是否可见
const chooseUserDialogVisible = ref(false);

const form = reactive({
  name: props.editData.name,
  path: props.editData.path,
  sort: props.editData.sort || 0,
  parent_guid: props.editData.guid ? props.editData.parent_guid : props.addParentGuid,
  principle: props.editData.principle,
  principle_name: props.editData.principle_name,
});
const formRef = ref();
// 表单校验规则
const rules = ref({
  name: [
    {
      required: true,
      message: t("Components.System.Department.ValidateDepartmentNameRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.Department.ValidateMaxLength100"),
      trigger: "change",
    },
  ],
  sort: [
    {
      required: true,
      message: t("Components.System.Department.ValidateDepartmentSortRequired"),
      trigger: "blur",
    },
  ],
});
onMounted(() => {
  if (props.editData && props.editData.guid) {
    // 代表修改
    dialogTitle.value = t("Components.System.Department.DialogTitleMaintance");
  } else {
    dialogTitle.value = t("Components.System.Department.DialogTitleAdd");
  }
});

// 处理提交事件
const handleCommit = async () => {
  // 校验
  if (!formRef.value) return;
  await formRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(
        t("Components.System.Department.ConfirmSaveDepartment"),
        t("Common.ElMessageBox.OperateConfirm"),
        {
          confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
          cancelButtonText: t("Common.ElMessageBox.CancelText"),
          type: "warning",
          icon: markRaw(WarnTriangleFilled),
        }
      )
        .then(async () => {
          await saveDepartment();
        })
        .catch(() => {});
    }
  });
  return;
};
// 保存部门数据
const saveDepartment = async () => {
  const trans = {
    parent_guid: form.parent_guid,
    name: form.name,
    principle: form.principle,
    sort: form.sort,
  };
  if (props.editData && props.editData.guid) {
    // 修改
    await request_department_modify(props.editData.guid, trans);
  } else {
    // 新增
    await request_department_add(trans);
  }
  emits("getDepartmentAll");
  emits("handleCloseDialog");
};
// 关闭
const handleCancle = () => {
  emits("handleCloseDialog");
};
// 处理回调关闭事件
const handleCloseDialog1 = () => {
  chooseUserDialogVisible.value = false;
};
// 更新选择的负责人
const updateChooseUser = (row) => {
  form.principle = row.guid;
  form.principle_name = row.name;
};
</script>
<style scoped lang="scss">
.input-principle {
  &:hover {
    cursor: pointer;
  }
}
</style>
