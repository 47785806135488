<template>
  <ul class="workinghours-ul" v-loading="fullLoading">
    <li class="hm-search">
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item
          :label="$t('Components.WorkingHours.WorkingHours.ContractNo')"
        >
          <el-input
            v-model="searchForm.contract_no"
            :placeholder="
              $t('Components.WorkingHours.WorkingHours.PleaseInputContractNo')
            "
            clearable
          />
        </el-form-item>
        <el-form-item
          :label="$t('Components.WorkingHours.WorkingHours.ProjectNumber')"
        >
          <el-select
            v-model="searchForm.project_guid"
            :placeholder="
              $t(
                'Components.WorkingHours.WorkingHours.PleaseSelectProjectNumber'
              )
            "
            clearable
            filterable
            @change="handleProjectSelectChange"
          >
            <el-option
              v-for="item in projects"
              :key="item.guid"
              :label="item.project_number"
              :value="item.guid"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('Components.WorkingHours.WorkingHours.ProjectTaskNumber')"
        >
          <el-select
            v-model="searchForm.project_task_guid"
            :placeholder="
              $t(
                'Components.WorkingHours.WorkingHours.PleaseSelectProjectTaskNumber'
              )
            "
            clearable
            filterable
          >
            <el-option
              v-for="item in projectTasks"
              :key="item.guid"
              :label="item.number"
              :value="item.guid"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('Components.WorkingHours.WorkingHours.User')">
          <el-select
            v-model="searchForm.user_guids"
            :placeholder="
              $t('Components.WorkingHours.WorkingHours.PleaseSelectUser')
            "
            clearable
            multiple
          >
            <el-option
              v-for="item in users"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('Components.WorkingHours.WorkingHours.ParttimeUser')">
          <el-input v-model="searchForm.parttime_user" :placeholder="$t('Components.WorkingHours.WorkingHours.PleaseInputParttimeUser')"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="$t('Components.WorkingHours.WorkingHours.ServiceType')">
          <el-select
            v-model="searchForm.service_type"
            :placeholder="
              $t('Components.WorkingHours.WorkingHours.PleaseSelectServiceType')
            "
            clearable
          >
            <el-option
              v-for="item in serviceTypes"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item :label="$t('Components.WorkingHours.WorkingHours.Type')">
          <el-select
            v-model="searchForm.type"
            :placeholder="
              $t('Components.WorkingHours.WorkingHours.PleaseSelectType')
            "
            clearable
          >
            <el-option
              key="1"
              :label="$t('Components.WorkingHours.WorkingHours.AuditType')"
              value="1"
            />
            <el-option
              key="2"
              :label="$t('Components.WorkingHours.WorkingHours.NotAuditType')"
              value="2"
            />
          </el-select>
        </el-form-item> -->
        <el-form-item
          :label="$t('Components.WorkingHours.WorkingHours.StartTime2')"
        >
          <el-date-picker
            v-model="searchForm.startTime"
            type="daterange"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            range-separator=" - "
            :start-placeholder="
              t('Components.WorkingHours.WorkingHours.PleaseSelectStartDate')
            "
            :end-placeholder="
              t('Components.WorkingHours.WorkingHours.PleaseSelectStartDate')
            "
          />
        </el-form-item>
        <el-form-item
          :label="$t('Components.WorkingHours.WorkingHours.EndTime2')"
        >
          <el-date-picker
            v-model="searchForm.endTime"
            type="daterange"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            range-separator=" - "
            :start-placeholder="
              t('Components.WorkingHours.WorkingHours.PleaseSelectEndDate')
            "
            :end-placeholder="
              t('Components.WorkingHours.WorkingHours.PleaseSelectEndDate')
            "
          />
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="buttonVisible.searchButton"
            type="primary"
            @click="queryClick"
            :icon="Search"
            >{{ $t("Components.WorkingHours.WorkingHours.Search1") }}</el-button
          >
          <el-button
            v-if="buttonVisible.resetButton"
            @click="resetClick"
            :icon="RefreshLeft"
            >{{ $t("Components.WorkingHours.WorkingHours.Reset") }}</el-button
          >
        </el-form-item>
      </el-form>
    </li>
    <li class="hm-operate">
      <!-- 操作部分 -->
      <el-button
        v-if="buttonVisible.addButton"
        :style="{ color: 'white' }"
        :icon="Plus"
        :color="ButtonColor.Add"
        @click="
          () => {
            dialogVisible = true;
          }
        "
        >{{ $t("Components.WorkingHours.WorkingHours.Add") }}</el-button
      >
      <el-button
        v-if="buttonVisible.deleteButton"
        :disabled="!(selectWorkingHours && selectWorkingHours.length > 0)"
        :style="{ color: 'white' }"
        :icon="Delete"
        :color="ButtonColor.Delete"
        @click="handleDeleteBatch"
        >{{ $t("Components.WorkingHours.WorkingHours.Delete") }}</el-button
      >
      <!-- <el-button
        v-if="buttonVisible.editButton"
        :disabled="!(selectWorkinghours && selectWorkinghours.length === WorkingHoursType.Audit)"
        :style="{ color: 'white' }"
        :icon="Edit"
        :color="ButtonColor.Edit"
        @click="handleEdit(scope.row)"
        >{{ $t("Components.WorkingHours.WorkingHours.Edit") }}</el-button
      > -->
      <el-button
        v-if="buttonVisible.exportButton"
        :style="{ color: 'white' }"
        :icon="Download"
        :color="ButtonColor.Export"
        @click="handleExport"
        >{{ $t("Components.WorkingHours.WorkingHours.Export") }}</el-button
      >
    </li>
    <div class="div-audittype">
      <el-radio-group v-model="audiType" @change="handleAuditTypeChange">
        <el-radio-button :label="1">{{
          t("Components.WorkingHours.WorkingHours.AuditType")
        }}</el-radio-button>
        <el-radio-button :label="2">{{
          t("Components.WorkingHours.WorkingHours.NotAuditType")
        }}</el-radio-button>
      </el-radio-group>
    </div>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          row-key="guid"
          @selection-change="handleSelectionChange"
          @sort-change="handleSortChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            prop="contract_no"
            :label="$t('Components.WorkingHours.WorkingHours.ContractNo')"
            :width="160"
          />
          <el-table-column
            v-if="audiType === WorkingHoursType.NotAudit"
            prop="project_number"
            :label="$t('Components.WorkingHours.WorkingHours.ProjectNumber')"
            :width="160"
            sortable
          >
          </el-table-column>
          <el-table-column
            v-if="audiType === WorkingHoursType.Audit"
            prop="project_task_number"
            :label="
              $t('Components.WorkingHours.WorkingHours.ProjectTaskNumber')
            "
            :width="160"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="client_name"
            :label="$t('Components.WorkingHours.WorkingHours.Client')"
            :width="160"
          >
          </el-table-column>
          <!-- <el-table-column
            v-if="audiType === WorkingHoursType.Audit"
            prop="center_number"
            :label="$t('Components.WorkingHours.WorkingHours.CenterNumber')"
            :width="160"
          >
          </el-table-column> -->
          <el-table-column
            v-if="audiType === WorkingHoursType.Audit"
            prop="audited_party_name"
            :label="$t('Components.WorkingHours.WorkingHours.AuditedPartyName')"
            :width="160"
          >
          </el-table-column>
          <el-table-column
            v-if="audiType === WorkingHoursType.Audit"
            prop="start_time"
            :label="$t('Components.WorkingHours.WorkingHours.StartTime')"
            :width="120"
            sortable
          >
            <template #default="scope">
              {{
                scope.row.start_time
                  ? formatTime(new Date(scope.row.start_time))
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="audiType === WorkingHoursType.NotAudit"
            prop="start_time"
            :label="$t('Components.WorkingHours.WorkingHours.StartTime1')"
            sortable
          >
            <template #default="scope">
              {{
                scope.row.start_time
                  ? formatTime(new Date(scope.row.start_time))
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="audiType === WorkingHoursType.Audit"
            prop="end_time"
            :label="$t('Components.WorkingHours.WorkingHours.EndTime')"
            :width="120"
            sortable
          >
            <template #default="scope">
              {{
                scope.row.end_time
                  ? formatTime(new Date(scope.row.end_time))
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="audiType === WorkingHoursType.NotAudit"
            prop="end_time"
            :label="$t('Components.WorkingHours.WorkingHours.EndTime1')"
            sortable
          >
            <template #default="scope">
              {{
                scope.row.end_time
                  ? formatTime(new Date(scope.row.end_time))
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="audiType === WorkingHoursType.Audit"
            prop="user_name"
            :label="$t('Components.WorkingHours.WorkingHours.User')"
            :width="120"
          >
          </el-table-column>
          <el-table-column
            v-if="audiType === WorkingHoursType.NotAudit"
            prop="user_name"
            :label="$t('Components.WorkingHours.WorkingHours.User1')"
            :width="120"
          >
          </el-table-column>
          <el-table-column
            v-if="audiType === WorkingHoursType.NotAudit"
            prop="work_content"
            :label="$t('Components.WorkingHours.WorkingHours.WorkContent')"
            :width="120"
          >
          </el-table-column>

          <el-table-column
            v-if="audiType === WorkingHoursType.Audit"
            align="center"
            :label="$t('Components.WorkingHours.WorkingHours.TotalHours')"
          >
            <!-- 准备 -->
            <el-table-column
              align="center"
              :label="workingHoursAuditReady ? workingHoursAuditReady.name : ''"
            >
              <template #default="scope">
                {{ computedSingleTime1(scope.row, workingHoursAuditReady) }}
              </template>
            </el-table-column>
            <!-- 现场稽查 -->
            <el-table-column
              align="center"
              :label="
                workingHoursOnsideAudit ? workingHoursOnsideAudit.name : ''
              "
            >
              <template #default="scope">
                {{ computedSingleTime1(scope.row, workingHoursOnsideAudit) }}
              </template>
            </el-table-column>
            <!-- 后续工作 -->
            <el-table-column
              align="center"
              :label="workingHoursFollowup ? workingHoursFollowup.name : ''"
            >
              <template #default="scope">
                {{ computedSingleTime1(scope.row, workingHoursFollowup) }}
              </template>
            </el-table-column>
            <!-- 差旅 -->
            <el-table-column
              align="center"
              :label="
                workingHoursBusinessTrip ? workingHoursBusinessTrip.name : ''
              "
            >
              <template #default="scope">
                {{ computedSingleTime1(scope.row, workingHoursBusinessTrip) }}
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column
            v-if="audiType === WorkingHoursType.NotAudit"
            align="center"
            prop="actual_total_hours"
            :label="$t('Components.WorkingHours.WorkingHours.TotalHours')"
            sortable
          >
          </el-table-column>

          <el-table-column
            prop="unit_price"
            :width="120"
            :label="$t('Components.WorkingHours.WorkingHours.UnitPrice')"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="discount"
            :width="120"
            :label="$t('Components.WorkingHours.WorkingHours.Discount')"
            sortable
          >
          </el-table-column>
          <!-- 带教折扣 -->
          <el-table-column
            prop="teaching_conversion"
            :width="120"
            :label="
              $t('Components.WorkingHours.WorkingHours.TeachingConversion')
            "
            sortable
          >
          </el-table-column>
          <!-- 兼职折扣 -->
          <el-table-column
            prop="parttime_job_conversion"
            :width="120"
            :label="
              $t('Components.WorkingHours.WorkingHours.ParttimeJobConversion')
            "
            sortable
          >
          </el-table-column>
          <!-- 兼职人员 -->
          <el-table-column
            prop="parttime_users"
            :width="120"
            :label="
              $t('Components.WorkingHours.WorkingHours.ParttimeUser')
            "
          >
          </el-table-column>
          <el-table-column
            prop="total_amount"
            :width="120"
            :label="$t('Components.WorkingHours.WorkingHours.TotalAmount')"
            class-name="temp"
            align="center"
            sortable
          >
            <template #default="scope">
              {{ scope.row.actual_total_amount }}
            </template>
          </el-table-column>

          <el-table-column
            prop="remark"
            :label="$t('Components.WorkingHours.WorkingHours.Remark')"
            :width="120"
          >
          </el-table-column>
          <el-table-column
            prop="total_service_fee_before_tax"
            :label="
              $t(
                'Components.WorkingHours.WorkingHours.TotalServiceFeeBeforeTax'
              )
            "
            :width="120"
            sortable
          >
          </el-table-column>
          <el-table-column
            :label="$t('Components.WorkingHours.WorkingHours.Operate')"
            width="200"
            fixed="right"
          >
            <template #default="scope">
              <el-space wrap>
                <el-button
                  v-if="buttonVisible.editButtonLine"
                  :style="{ color: 'white' }"
                  :icon="Edit"
                  :color="ButtonColor.Edit"
                  size="small"
                  @click="handleEdit(2, scope.row)"
                  >{{
                    $t("Components.WorkingHours.WorkingHours.Edit")
                  }}</el-button
                >
                <el-button
                  v-if="buttonVisible.deleteButtonLine"
                  :style="{ color: 'white' }"
                  :icon="Delete"
                  :color="ButtonColor.Delete"
                  size="small"
                  bg
                  @click="handleDelete(scope.row)"
                  >{{
                    $t("Components.WorkingHours.WorkingHours.Delete")
                  }}</el-button
                >
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </li>
    </el-scrollbar>

    <li class="hm-page">
      <el-pagination
        small
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="AllPageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </li>
  </ul>
  <WorkingHoursModify
    v-if="dialogVisible"
    @handleCloseDialog="handleCloseDialog"
    :editData="editData"
    @getWorkingHoursPage="getWorkingHoursPage"
    :projects="projects"
    :users="users"
    :auditType="audiType"
    :initProjectTasks="editInitProjectTask"
    :serviceTypes="serviceTypes"
    :workingHoursAuditReadys="workingHoursAuditReadys"
    :workingHoursAuditReady="workingHoursAuditReady"
    :workingHoursOnsideAudits="workingHoursOnsideAudits"
    :workingHoursOnsideAudit="workingHoursOnsideAudit"
    :workingHoursFollowups="workingHoursFollowups"
    :workingHoursFollowup="workingHoursFollowup"
    :workingHoursBusinessTrips="workingHoursBusinessTrips"
    :workingHoursBusinessTrip="workingHoursBusinessTrip"
  />
</template>
<script setup>
import { reactive, ref, markRaw, onMounted, watchEffect, computed } from "vue";
import {
  Plus,
  Delete,
  RefreshLeft,
  Edit,
  Search,
  WarnTriangleFilled,
  Download,
} from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";
import { AllPageSize, ButtonColor, DefaultPageSize } from "@/consts/frame";
import {
  request_workinghours_page,
  request_workinghours_export,
  request_project_all,
  request_projecttask_all_byproject,
  request_user_page,
  request_workinghours_delete,
  request_workinghours_delete_batch,
} from "@/api/apis";
// import AuditedPartyModify from "@/components/Database/AuditedParty/AuditedPartyModify";
import WorkingHoursModify from "@/components/WorkingHours/WorkingHours/WorkingHoursModify.vue";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";
import { useStore } from "@/store/index";
import { Dics } from "@/consts/dic";
import { formatTime } from "@/util/timeUtil";
import { WorkingHoursType } from "@/consts/enum";

const store = useStore();
const { t } = useI18n();
const searchForm = reactive({
  inputSearch: "",
  contract_no: "",
  project_guid: "",
  project_task_guid: "",
  type: "",
  user_guids: [],
  startTime: undefined,
  endTime: undefined,
  parttime_user: undefined
});
const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  addButton: false,
  exportButton: false,
  editButtonLine: false,
  deleteButton: false,
  deleteButtonLine: false,
});
// 当前页码
const currentPage = ref(1);
// 页码大小
const pageSize = ref(DefaultPageSize);
// 表格数据
const tableData = ref([]);
// 表格加载
const tableLoading = ref(false);
// 总数量
const total = ref(0);
// 编辑的数据
const editData = ref({});
// 弹窗是否可见
const dialogVisible = ref(false);
// 选择的工时
const selectWorkinghours = ref([]);
// 全屏加载
const fullLoading = ref(false);
// 服务类型
const serviceTypes = ref([]);
// 工时稽查准备
const workingHoursAuditReadys = ref([]);
const workingHoursAuditReady = ref();
// 工时现场稽查
const workingHoursOnsideAudits = ref([]);
const workingHoursOnsideAudit = ref();
// 工时后续工作
const workingHoursFollowups = ref([]);
const workingHoursFollowup = ref();
// 工时差旅时间
const workingHoursBusinessTrips = ref([]);
const workingHoursBusinessTrip = ref();
// 选择的工时
const selectWorkingHours = ref([]);

const editInitProjectTask = ref([]);

const projects = ref([]);
const projectTasks = ref([]);
const users = ref([]);
const audiType = ref(WorkingHoursType.Audit);

const ProjectNumberSort = ref(undefined);
const ProjectTaskNumberSort = ref(undefined);
const AuditStartTimeSort = ref(undefined);
const AuditEndTimeSort = ref(undefined);
const UnitPriceSort = ref(undefined);
const DiscountSort = ref(undefined);
const TeachingConversionSort = ref(undefined);
const ParttimeJobConversionSort = ref(undefined);
const ActualTotalAmountSort = ref(undefined);
const TotalServiceFeeBeforeTax = ref(undefined);

const computedSingleTime1 = computed(() => (row, data) => {
  let dd = 0;
  if (data) {
    const findData = row.details.filter(
      (item) =>
        item.dic_guid === data.guid && item.type === WorkingHoursType.NotAudit
    );

    if (findData && findData.length > 0) {
      findData.forEach((item) => {
        dd += item.time;
      });
    }
  }

  return dd;
});
onMounted(async () => {
  getProjectAll();
  getUserAll();
  await getWorkingHoursPage();
});

// 获取所有的项目
const getProjectAll = async () => {
  const trans = {
    progresses: [],
  };
  const result = await request_project_all(trans);
  if (result && result.length > 0) {
    projects.value = result;
  } else {
    projects.value = [];
  }
};
// 获取所有用户
const getUserAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
    is_system: false,
  };
  const result = await request_user_page(trans);
  if (result.total > 0) {
    users.value = result.rows;
  } else {
    users.value = [];
  }
};
// 分页查询工时
const getWorkingHoursPage = async () => {
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    contract_no: searchForm.contract_no,
    project_guid: searchForm.project_guid,
    project_task_guid: searchForm.project_task_guid,
    // type: searchForm.type,
    type: audiType.value,
    user_guids: searchForm.user_guids,
    start_time_start:
      searchForm.startTime && searchForm.startTime.length > 0
        ? searchForm.startTime[0]
        : undefined,
    start_time_end:
      searchForm.startTime && searchForm.startTime.length > 1
        ? searchForm.startTime[1]
        : undefined,
    end_time_start:
      searchForm.endTime && searchForm.endTime.length > 0
        ? searchForm.endTime[0]
        : undefined,
    end_time_end:
      searchForm.endTime && searchForm.endTime.length > 1
        ? searchForm.endTime[1]
        : undefined,
        parttime_user: searchForm.parttime_user,

    ProjectTaskNumberSort: ProjectTaskNumberSort.value,
    AuditStartTimeSort: AuditStartTimeSort.value,
    AuditEndTimeSort: AuditEndTimeSort.value,
    UnitPriceSort: UnitPriceSort.value,
    DiscountSort: DiscountSort.value,
    TeachingConversionSort: TeachingConversionSort.value,
    ParttimeJobConversionSort: ParttimeJobConversionSort.value,
    ActualTotalAmountSort: ActualTotalAmountSort.value,
    TotalServiceFeeBeforeTax: TotalServiceFeeBeforeTax.value,
  };
  tableLoading.value = true;
  const result = await request_workinghours_page(
    trans,
    () => (tableLoading.value = false)
  );
  total.value = result.total;
  tableData.value = result.rows;
  selectWorkinghours.value = [];
  editData.value = {};
  selectWorkingHours.value = [];
};

// 处理选择回调事件
const handleSelectionChange = (workingHours) => {
  selectWorkingHours.value = workingHours;
};
// 查询事件
const queryClick = () => {
  getWorkingHoursPage();
};
// 重置事件
const resetClick = () => {
  searchForm.dataflag = undefined;
  searchForm.inputSearch = "";
  searchForm.contract_no = "";
  searchForm.project_guid = "";
  searchForm.project_task_guid = "";
  searchForm.type = "";
  searchForm.user_guids = [];
  searchForm.startTime = undefined;
  searchForm.endTime = undefined;
  searchForm.parttime_user = ''
  getWorkingHoursPage();
};
// 处理页码大小改变事件
const handleSizeChange = (size) => {
  pageSize.value = size;
  getWorkingHoursPage();
};
// 处理当前页改变事件
const handleCurrentChange = (index) => {
  currentPage.value = index;
  getWorkingHoursPage();
};
// 处理编辑事件
const handleEdit = async (type, data) => {
  let row = undefined;
  if (type === WorkingHoursType.Audit) {
    // 判断是否有且仅选择了一条用户数据
    if (selectWorkingHours.value.length !== 1) {
      ElMessage.warning(
        t("Components.WorkingHours.WorkingHours.OnlyOneSelectEdit")
      );
      return;
    }
    row = selectWorkingHours.value[0];
  } else if (type === WorkingHoursType.NotAudit) {
    row = data;
  }

  // 先获取这个项目的所有任务数据
  const trans = {
    project_guid: row.project_guid,
  };
  const projectAllProjectTask = await request_projecttask_all_byproject(trans);
  editInitProjectTask.value = projectAllProjectTask;

  editData.value = {
    guid: row.guid,
    contract_no: row.contract_no,
    project_guid: row.project_guid,
    project_task_guid: row.project_task_guid,
    user_guid: row.user_guid,
    service_type: row.service_type,
    start_time: row.start_time,
    end_time: row.end_time,
    startEndTime: [row.start_time, row.end_time],
    work_content: row.work_content,
    remark: row.remark,
    unit_price: row.unit_price,
    discount: row.discount,
    plan_total_amount: row.plan_total_amount,
    plan_total_hours: row.plan_total_hours,
    actual_total_amount: row.actual_total_amount,
    actual_total_hours: row.actual_total_hours,
    details: row.details,
    other_amount: row.other_amount,
    total_service_fee_before_tax: row.total_service_fee_before_tax,
    parttime_job_conversion: row.parttime_job_conversion,
    teaching_conversion: row.teaching_conversion,
  };

  dialogVisible.value = true;
};
// 处理回调关闭事件
const handleCloseDialog = () => {
  dialogVisible.value = false;
  editData.value = {};
};
// 处理删除
const handleDelete = async (row) => {
  ElMessageBox.confirm(
    t("Components.WorkingHours.WorkingHours.ConfirmDeleteWorkingHoursParty"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      await request_workinghours_delete(row.guid, true);
      getWorkingHoursPage();
    })
    .catch(() => {});
};
// 批量删除
const handleDeleteBatch = async () => {
  if (selectWorkingHours.value.length === 0) {
    ElMessage.warning(
      t("Components.WorkingHours.WorkingHours.AtLeastOneWorkingHours")
    );
    return;
  }
  ElMessageBox.confirm(
    t("Components.WorkingHours.WorkingHours.ConfirmDeleteWorkingHoursParty"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const trans = {
        guids: selectWorkingHours.value.map((item) => item.guid),
      };
      await request_workinghours_delete_batch(trans, true);
      getWorkingHoursPage();
    })
    .catch(() => {});
};
// 导出
const handleExport = async () => {
  fullLoading.value = true;
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    text: searchForm.inputSearch,
    contract_no: searchForm.contract_no,
    project_guid: searchForm.project_guid,
    project_task_guid: searchForm.project_task_guid,
    type: audiType.value,
    user_guids: searchForm.user_guids,
    start_time_start:
      searchForm.startTime && searchForm.startTime.length > 0
        ? searchForm.startTime[0]
        : undefined,
    start_time_end:
      searchForm.startTime && searchForm.startTime.length > 1
        ? searchForm.startTime[1]
        : undefined,
    end_time_start:
      searchForm.endTime && searchForm.endTime.length > 0
        ? searchForm.endTime[0]
        : undefined,
    end_time_end:
      searchForm.endTime && searchForm.endTime.length > 1
        ? searchForm.endTime[1]
        : undefined,
    ProjectTaskNumberSort: ProjectTaskNumberSort.value,
    AuditStartTimeSort: AuditStartTimeSort.value,
    AuditEndTimeSort: AuditEndTimeSort.value,
    UnitPriceSort: UnitPriceSort.value,
    DiscountSort: DiscountSort.value,
    TeachingConversionSort: TeachingConversionSort.value,
    ParttimeJobConversionSort: ParttimeJobConversionSort.value,
    ActualTotalAmountSort: ActualTotalAmountSort.value,
    TotalServiceFeeBeforeTax: TotalServiceFeeBeforeTax.value,
        parttime_user: searchForm.parttime_user,
  };
  const result = await request_workinghours_export(
    trans,
    true,
    () => (fullLoading.value = false)
  );
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = result;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
};
// 处理项目改变事件
const handleProjectSelectChange = async (data) => {
  const trans = {
    project_guid: data,
  };
  const result = await request_projecttask_all_byproject(trans);
  projectTasks.value = result;
};
// 处理类型改变事件
const handleAuditTypeChange = () => {
  getWorkingHoursPage();
};
// 处理排序改变事件
const handleSortChange = async ({ prop, order }) => {
  ProjectNumberSort.value = undefined;
  ProjectTaskNumberSort.value = undefined;
  AuditStartTimeSort.value = undefined;
  AuditEndTimeSort.value = undefined;
  UnitPriceSort.value = undefined;
  DiscountSort.value = undefined;
  TeachingConversionSort.value = undefined;
  ParttimeJobConversionSort.value = undefined;
  ActualTotalAmountSort.value = undefined;
  TotalServiceFeeBeforeTax.value = undefined;

  if (prop === "project_number") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      ProjectNumberSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      ProjectNumberSort.value = true;
    } else {
      ProjectNumberSort.value = undefined;
    }
  }
  if (prop === "project_task_number") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      ProjectTaskNumberSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      ProjectTaskNumberSort.value = true;
    } else {
      ProjectTaskNumberSort.value = undefined;
    }
  }
  if (prop === "start_time") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      AuditStartTimeSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      AuditStartTimeSort.value = true;
    } else {
      AuditStartTimeSort.value = undefined;
    }
  }
  if (prop === "end_time") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      AuditEndTimeSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      AuditEndTimeSort.value = true;
    } else {
      AuditEndTimeSort.value = undefined;
    }
  }
  if (prop === "unit_price") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      UnitPriceSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      UnitPriceSort.value = true;
    } else {
      UnitPriceSort.value = undefined;
    }
  }
  if (prop === "discount") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      DiscountSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      DiscountSort.value = true;
    } else {
      DiscountSort.value = undefined;
    }
  }
  if (prop === "teaching_conversion") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      TeachingConversionSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      TeachingConversionSort.value = true;
    } else {
      TeachingConversionSort.value = undefined;
    }
  }
  if (prop === "parttime_job_conversion") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      ParttimeJobConversionSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      ParttimeJobConversionSort.value = true;
    } else {
      ParttimeJobConversionSort.value = undefined;
    }
  }
  if (prop === "actual_total_amount") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      ActualTotalAmountSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      ActualTotalAmountSort.value = true;
    } else {
      ActualTotalAmountSort.value = undefined;
    }
  }
  if (prop === "total_service_fee_before_tax") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      TotalServiceFeeBeforeTax.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      TotalServiceFeeBeforeTax.value = true;
    } else {
      TotalServiceFeeBeforeTax.value = undefined;
    }
  }

  getWorkingHoursPage();
};
watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.WorkingHours.WorkingHours,
      MenuButtonCodes.WorkingHours.WorkingHours.WorkingHoursSearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.WorkingHours.WorkingHours,
      MenuButtonCodes.WorkingHours.WorkingHours.WorkingHoursReset
    );
    // 处理新增
    buttonVisible.addButton = validateMenuButton(
      store.userMenus,
      MenuCodes.WorkingHours.WorkingHours,
      MenuButtonCodes.WorkingHours.WorkingHours.WorkingHoursAdd
    );
    // 处理删除
    buttonVisible.deleteButton = validateMenuButton(
      store.userMenus,
      MenuCodes.WorkingHours.WorkingHours,
      MenuButtonCodes.WorkingHours.WorkingHours.WorkingHoursDelete
    );
    // 处理编辑行
    buttonVisible.editButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.WorkingHours.WorkingHours,
      MenuButtonCodes.WorkingHours.WorkingHours.WorkingHoursEditLine
    );
    // 处理删除行
    buttonVisible.deleteButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.WorkingHours.WorkingHours,
      MenuButtonCodes.WorkingHours.WorkingHours.WorkingHourseDeleteLine
    );
    // 处理导出
    buttonVisible.exportButton = validateMenuButton(
      store.userMenus,
      MenuCodes.WorkingHours.WorkingHours,
      MenuButtonCodes.WorkingHours.WorkingHours.WorkingHoursExport
    );
  }
});

watchEffect(() => {
  if (store.userInfo) {
    if (store.userInfo.dics && store.userInfo.dics.length > 0) {
      const findWorkingHoursDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.ServiceType
      );
      if (findWorkingHoursDic) {
        if (findWorkingHoursDic.items && findWorkingHoursDic.items.length > 0) {
          serviceTypes.value = findWorkingHoursDic.items;
        } else {
          serviceTypes.value = [];
        }
      } else {
        serviceTypes.value = [];
      }
      //  工时稽查准备
      const findWorkingHoursAuditReadyDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.WorkingHoursAuditReady
      );
      if (findWorkingHoursAuditReadyDic) {
        workingHoursAuditReady.value = findWorkingHoursAuditReadyDic;
        if (
          findWorkingHoursAuditReadyDic.items &&
          findWorkingHoursAuditReadyDic.items.length > 0
        ) {
          workingHoursAuditReadys.value = findWorkingHoursAuditReadyDic.items;
        } else {
          workingHoursAuditReadys.value = [];
        }
      } else {
        workingHoursAuditReadys.value = [];
      }
      // 工时现场稽查
      const findWorkingHoursOnsideAudit = store.userInfo.dics.find(
        (item) => item.code === Dics.System.WorkingHoursOnsideAudit
      );
      if (findWorkingHoursOnsideAudit) {
        workingHoursOnsideAudit.value = findWorkingHoursOnsideAudit;
        if (
          findWorkingHoursOnsideAudit.items &&
          findWorkingHoursOnsideAudit.items.length > 0
        ) {
          workingHoursOnsideAudits.value = findWorkingHoursOnsideAudit.items;
        } else {
          workingHoursOnsideAudits.value = [];
        }
      } else {
        workingHoursOnsideAudits.value = [];
      }
      // 工时后续工作
      const findWorkingHoursFollowup = store.userInfo.dics.find(
        (item) => item.code === Dics.System.WorkingHoursFollowup
      );
      if (findWorkingHoursFollowup) {
        workingHoursFollowup.value = findWorkingHoursFollowup;
        if (
          findWorkingHoursFollowup.items &&
          findWorkingHoursFollowup.items.length > 0
        ) {
          workingHoursFollowups.value = findWorkingHoursFollowup.items;
        } else {
          workingHoursFollowups.value = [];
        }
      } else {
        workingHoursFollowups.value = [];
      }
      // 工时差旅时间
      const findWorkingHoursBusinessTrip = store.userInfo.dics.find(
        (item) => item.code === Dics.System.WorkingHoursBusinessTrip
      );
      if (findWorkingHoursBusinessTrip) {
        workingHoursBusinessTrip.value = findWorkingHoursBusinessTrip;
        if (
          findWorkingHoursBusinessTrip.items &&
          findWorkingHoursBusinessTrip.items.length > 0
        ) {
          workingHoursBusinessTrips.value = findWorkingHoursBusinessTrip.items;
        } else {
          workingHoursBusinessTrips.value = [];
        }
      } else {
        workingHoursBusinessTrips.value = [];
      }
      workingHoursAuditReadys.value;
    } else {
      serviceTypes.value = [];
    }
  }
});
</script>
<style>
.workinghours-ul .el-scrollbar__view {
  height: calc(100% - 8px);
}
.temp .cell {
  padding: 0 !important;
}
</style>
<style scoped lang="scss">
.workinghours-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  .dd-div {
    padding: 8px 0;
  }
  .bottom-border-div {
    border-bottom: 1px solid #dcdcdc;
  }
  .div-audittype {
    padding-top: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
