export default {
  LoginDialog: 'LoginDialog',
  ProjectTaskContentSummaryFindParent: 'ProjectTaskContentSummaryFindParent',
  ProjectTaskClearCheckComment: 'ProjectTaskClearCheckComment',
  ProjectTaskReportDiscoveryClearOtherEdit: 'ProjectTaskReportDiscoveryClearOtherEdit',
  ProjectTaskReportComment: 'ProjectTaskReportComment',
  ProjectTaskReportContentDom: 'ProjectTaskReportContentDom',
  ProjectTaskReportUpdateComment: 'ProjectTaskReportUpdateComment',
  MenuClick:'MenuClick',
  RouteChange: 'RouteChange'
} 