
// 相关请求的配置项，请求api等
const {
  ENV
} = require('./env')
console.log(ENV)
let BASEURL
switch (ENV) {
  case 'daoyi':
    BASEURL = 'https://www.daoyi-cro.com'
    break;
  case 'study':
    BASEURL = 'http://training.daoyi-cro.com'
    break;
  case 'dev':
    BASEURL = 'https://localhost:44378'
    break;
    case 'hm':
    BASEURL='http://119.45.173.107:8000'
    break;
  default:
    break;
}

const PAGESIZE = 15

module.exports = {
  BASEURL, PAGESIZE
}