<template>
  <el-scrollbar>
    <div class="preview-div">
      <div v-if="parentComment">
        <el-divider content-position="left">{{
          t("Components.Project.ProjectTask.Report1.ContentSummary.ReplyTo")
        }}</el-divider>
        <el-form :label-width="100" size="small">
          <el-form-item
            :label="
              t('Components.Project.ProjectTask.Report1.ContentSummary.Commentator')
            "
            >{{ parentComment.name }}</el-form-item
          >
          <el-form-item
            :label="
              t('Components.Project.ProjectTask.Report1.ContentSummary.SourceContent')
            "
            ><span>{{ parentComment.source_content }}</span></el-form-item
          >
          <el-form-item
            :label="
              t('Components.Project.ProjectTask.Report1.ContentSummary.QuotedPassage')
            "
          >
            <span
              :style="{ color: '#008080', fontWeight: 600 }"
              v-if="
                (parentComment.select_start === 0 && parentComment.select_end === 0) ||
                parentComment.select_start >= parentComment.select_end
              "
              >{{
                t("Components.Project.ProjectTask.Report1.ContentSummary.AllText")
              }}</span
            >
            <span v-else>{{ parentComment.select_content }}</span>
          </el-form-item>
          <el-form-item
            :label="t('Components.Project.ProjectTask.Report1.ContentSummary.Comments')"
            ><span>{{ parentComment.comment }}</span></el-form-item
          >
          <el-form-item
            :label="
              t('Components.Project.ProjectTask.Report1.ContentSummary.CommentTime')
            "
            >{{ parentComment.time }}</el-form-item
          >
        </el-form>
      </div>

      <div>
        <el-divider content-position="left">{{
          t("Components.Project.ProjectTask.Report1.ContentSummary.CurrentComment")
        }}</el-divider>
        <el-form :label-width="100" size="small">
          <el-form-item
            :label="
              t('Components.Project.ProjectTask.Report1.ContentSummary.Commentator')
            "
            >{{ props.comment.name }}</el-form-item
          >
          <el-form-item
            :label="
              t('Components.Project.ProjectTask.Report1.ContentSummary.SourceContent')
            "
          >
            <span>
              {{ props.comment.source_content }}
            </span>
          </el-form-item>
          <el-form-item
            :label="
              t('Components.Project.ProjectTask.Report1.ContentSummary.QuotedPassage')
            "
          >
            <span
              :style="{ color: '#008080', fontWeight: 600 }"
              v-if="
                (props.comment.select_start === 0 && props.comment.select_end === 0) ||
                props.comment.select_start >= props.comment.select_end
              "
              >{{
                t("Components.Project.ProjectTask.Report1.ContentSummary.AllText")
              }}</span
            >
            <span v-else>{{ props.comment.select_content }}</span>
          </el-form-item>
          <el-form-item
            :label="t('Components.Project.ProjectTask.Report1.ContentSummary.Comments')"
            ><span>{{ props.comment.comment }}</span></el-form-item
          >
          <el-form-item
            :label="
              t('Components.Project.ProjectTask.Report1.ContentSummary.CommentTime')
            "
            >{{ props.comment.time }}</el-form-item
          >
        </el-form>
      </div>
    </div>
  </el-scrollbar>
</template>
<script setup>
import { defineProps, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
const props = defineProps({
  comments: {
    type: Array,
    default: () => [],
  },
  comment: {
    type: Object,
    default: () => {},
  },
});
const { t } = useI18n();

const parentComment = ref(undefined);

onMounted(() => {
  props.comments.forEach((item) => {
    if (item.guid === props.comment.parent_guid) {
      // 找到了
      parentComment.value = item;
      return;
    } else {
      if (item.children && item.children.length > 0) {
        item.children.forEach((item1) => {
          if (item1.guid === props.comment.parent_guid) {
            parentComment.value = item1;
            return;
          }
        });
      }
    }
  });
});
</script>
<style scoped lang="scss">
.preview-div {
  padding: 0 20px 0 0px;
  height: 400px;
}
span {
  white-space: pre-wrap;
}
</style>
