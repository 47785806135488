<template>
  <ul class="auditsummary-left-ul">
    <li class="hm-search">
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item :label="$t('Components.Database.AuditSummary.Search')">
          <el-input
            v-model="searchForm.inputSearch"
            :placeholder="$t('Components.Database.AuditSummary.PleaseInputName')"
            clearable
          />
        </el-form-item>
        <el-form-item :label="$t('Components.Database.AuditSummary.Dataflag')">
          <el-select
            v-model="searchForm.dataflag"
            :placeholder="$t('Components.Database.AuditSummary.SelectDataflag')"
            clearable
          >
            <el-option
              :label="$t('Components.Database.AuditSummary.DataflagY')"
              value="true"
            />
            <el-option
              :label="$t('Components.Database.AuditSummary.DataflagN')"
              value="false"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="buttonVisible.searchButton"
            type="primary"
            @click="queryClick"
            :icon="Search"
            >{{ $t("Components.Database.AuditSummary.Search1") }}</el-button
          >
          <el-button
            v-if="buttonVisible.resetButton"
            @click="resetClick"
            :icon="RefreshLeft"
            >{{ $t("Components.Database.AuditSummary.Reset") }}</el-button
          >
        </el-form-item>
      </el-form>
    </li>
    <li class="hm-operate">
      <!-- 操作部分 -->
      <el-button
        v-if="buttonVisible.addButton"
        :style="{ color: 'white' }"
        :icon="Plus"
        :color="ButtonColor.Add"
        @click="
          () => {
            dialogVisible = true;
          }
        "
        >{{ $t("Components.Database.AuditSummary.Add") }}</el-button
      >
    </li>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <el-table
          :data="tableData"
          row-key="guid"
          v-loading="tableLoading"
          @row-click="handleRowClick"
          :row-class-name="tableRowClassName"
        >
          <el-table-column
            prop="name"
            sortable
            show-overflow-tooltip
            :label="$t('Components.Database.AuditSummary.Name')"
          />
          <el-table-column
            prop="default_value"
            sortable
            show-overflow-tooltip
            :label="$t('Components.Database.AuditSummary.DefaultValue')"
          />
          <el-table-column
            prop="sort"
            :label="$t('Components.Database.AuditSummary.Sort')"
            sortable
            width="120"
          />
         
          <el-table-column
            prop="dataflag"
            width="120"
            :label="$t('Components.Database.AuditSummary.Dataflag')"
          >
            <template #default="scope">
              <el-switch
                :disabled="!buttonVisible.dataflag"
                v-model="scope.row.dataflag"
                @click.stop
                @change="(flag) => handleDataflagChange(flag, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('Components.Database.AuditSummary.Operate')"
            width="240"
          >
            <template #default="scope">
              <el-space wrap>
                <el-button
                v-if="buttonVisible.editButtonLine"
                  :style="{ color: 'white' }"
                  :icon="Edit"
                  :color="ButtonColor.Edit"
                  size="small"
                  @click.stop="handleEditAuditSummary(scope.row)"
                  >{{ $t("Components.Database.AuditSummary.Edit") }}</el-button
                >
                <el-button
                v-if="buttonVisible.deleteButtonLine"
                  :style="{ color: 'white' }"
                  :icon="Delete"
                  :color="ButtonColor.Delete"
                  size="small"
                  bg
                  @click.stop="handleDelete(scope.row)"
                  >{{ $t("Components.Database.AuditSummary.Delete") }}</el-button
                >
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </li>
    </el-scrollbar>
    <li class="hm-page">
      <el-pagination
      small
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="AllPageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </li>
  </ul>
  
  <AuditSummaryModify
    v-if="dialogVisible"
    @handleCloseDialog="handleCloseDialog"
    @getAuditSummaryPage="getAuditSummaryPage"
    :editData="editData"
  />
</template>
<script setup>
import { onMounted, reactive, ref, markRaw, defineEmits, watchEffect } from "vue";
import {
  request_auditsummary_page,
  request_auditsummary_delete,
  request_auditsummary_dataflag,
} from "@/api/apis";
import {
  Search,
  RefreshLeft,
  Plus,
  Edit,
  Delete,
  WarnTriangleFilled,
} from "@element-plus/icons-vue";
import { ButtonColor, DefaultPageSize, AllPageSize } from "@/consts/frame";
import AuditSummaryModify from "@/components/Database/AuditSummary/LeftModify.vue";
import { ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";
import { useStore } from "@/store/index";

const store = useStore();
const { t } = useI18n();
const emits = defineEmits(["handleAuditSummaryClick", "handleClearActiveAuditSummary"]);

const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  addButton: false,
  editButton: false,
  deleteButton: false,
  editButtonLine: false,
  deleteButtonLine: false,
  dataflag: false,
  exportButton: false,
});
const searchForm = reactive({
  inputSearch: "",
  dataflag: undefined,
});
// 当前页码
const currentPage = ref(1);
// 页码大小
const pageSize = ref(DefaultPageSize);
// 总数量
const total = ref(0);
// 数据
const tableData = ref([]);
// 列表加载
const tableLoading = ref(false);
// 维护弹窗是否可见
const dialogVisible = ref(false);
// 编辑的数据
const editData = ref({});
// 激活的稽查小结guid
const activeAuditSummaryGuid = ref("");

onMounted(async () => {
  await getAuditSummaryPage();
});
// 获取所有菜单
const getAuditSummaryPage = async () => {
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    text: searchForm.inputSearch,
    dataflag: searchForm.dataflag,
  };
  tableLoading.value = true;
  const result = await request_auditsummary_page(
    trans,
    () => (tableLoading.value = false)
  );
  total.value = result.total;
  tableData.value = result.rows;

  activeAuditSummaryGuid.value = "";
  // 触发右侧清空
  emits("handleClearActiveAuditSummary");
};

// 查询事件
const queryClick = async () => {
  await getAuditSummaryPage();
};
// 重置事件
const resetClick = async () => {
  searchForm.inputSearch = "";
  searchForm.dataflag = undefined;
  await getAuditSummaryPage();
};
// 处理页码大小改变事件
const handleSizeChange = (size) => {
  pageSize.value = size;
  getAuditSummaryPage();
};
// 处理当前页改变事件
const handleCurrentChange = (index) => {
  currentPage.value = index;
  getAuditSummaryPage();
};
// 编辑
const handleEditAuditSummary = (row) => {
  editData.value = {
    guid: row.guid,
    name: row.name,
    default_value: row.default_value,
    sort: row.sort,
  };
  dialogVisible.value = true;
};
// 处理关闭事件
const handleCloseDialog = () => {
  dialogVisible.value = false;
  editData.value = {};
};
// 处理删除菜单事件
const handleDelete = async (row) => {
  ElMessageBox.confirm(
    t("Components.Database.AuditSummary.ConfirmDeleteAuditSummary"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      await request_auditsummary_delete(row.guid);
      dialogVisible.value = false;
      getAuditSummaryPage();
    })
    .catch(() => {});
};
// 处理有效性改变事件
const handleDataflagChange = async (flag, row) => {
  await request_auditsummary_dataflag(row.guid, true, () => {
    row.dataflag = !row.dataflag;
  });
};
// 行点击事件
const handleRowClick = (row) => {
  activeAuditSummaryGuid.value = row.guid;
  emits("handleAuditSummaryClick", row.guid);
};
// 激活行的颜色
const tableRowClassName = ({ row }) => {
  if (row.guid === activeAuditSummaryGuid.value) {
    return "success-row";
  }
};
watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditSummary,
      MenuButtonCodes.Database.AuditSummary.AuditSummarySearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditSummary,
      MenuButtonCodes.Database.AuditSummary.AuditSummaryReset
    );
    // 处理新增
    buttonVisible.addButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditSummary,
      MenuButtonCodes.Database.AuditSummary.AuditSummaryAdd
    );
    // 处理编辑
    buttonVisible.editButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditSummary,
      MenuButtonCodes.Database.AuditSummary.AuditSummaryEdit
    );
    // 处理删除
    buttonVisible.deleteButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditSummary,
      MenuButtonCodes.Database.AuditSummary.AuditSummaryDelete
    );
    // 处理删除行
    buttonVisible.editButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditSummary,
      MenuButtonCodes.Database.AuditSummary.AuditSummaryEditLine
    );
    // 处理删除行
    buttonVisible.deleteButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditSummary,
      MenuButtonCodes.Database.AuditSummary.AuditSummaryDeleteLine
    );
    // 处理有效性
    buttonVisible.dataflag = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditSummary,
      MenuButtonCodes.Database.AuditSummary.AuditSummaryDataflag
    );
    // 处理导出
    buttonVisible.exportButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditSummary,
      MenuButtonCodes.Database.AuditSummary.AuditSummaryExport
    );
  }
});
</script>
<style>
.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}
</style>
<style scoped lang="scss">
.auditsummary-left-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  .hm-table {
    height: calc(100%);
    .el-table {
      height: calc(100%);
    }
  }
}
</style>
