<template>
  <Dialog
    width="40%"
    :title="props.dialogTitle"
    :showFooter="true"
    @handleCancle="handleCancle"
    @handleCommit="handleCommit"
  >
    <el-form label-width="120px" size="large">
      <el-form-item
        v-for="item in items"
        :key="item.guid"
        :label="item.name"
        prop="project_guid"
      >
        <el-input
          v-model="item.value"
          placeholder=""
          type="textarea"
        ></el-input>
      </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup>
import { onMounted, defineProps, defineEmits, ref, markRaw } from "vue";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { request_projecttask_auditsummary_item_get, request_projecttask_auditsummary_item_modify } from "@/api/apis";
import { ElMessageBox } from "element-plus";
import { WarnTriangleFilled } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const emits = defineEmits(["handleCloseDialog", 'getProjectTaskAuditSummary']);
const props = defineProps({
  dialogTitle: {
    type: String,
    default: () => "",
  },
  projectTaskGuid: {
    type: String,
    default: () => "",
  },
  auditSummaryGuid: {
    type: String,
    default: () => "",
  },
});

const items = ref([]);

onMounted(() => {
  getProjectTaskAuditSummaryItem();
});
// 获取项目任务稽查小结项
const getProjectTaskAuditSummaryItem = async () => {
  const trans = {
    audit_summary_guid: props.auditSummaryGuid,
  };
  const result = await request_projecttask_auditsummary_item_get(
    props.projectTaskGuid,
    trans
  );
  items.value = result;
};

// 处理提交事件
const handleCommit = async () => {
  ElMessageBox.confirm(
    t(
      "Components.Project.ProjectTask.AuditSummaryView.ConfirmSaveAuditSummary"
    ),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const itemsConvert = items.value.map((item) => {
        return {
          audit_summary_item_guid: item.guid,
          value: item.value,
        };
      });
      const trans = {
        audit_summary_guid: props.auditSummaryGuid,
        items: itemsConvert,
      }
      await request_projecttask_auditsummary_item_modify(props.projectTaskGuid, trans)
      emits('getProjectTaskAuditSummary')
      emits("handleCloseDialog");
    })
    .catch(() => {});
};

// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};
</script>
<style scoped lang="scss"></style>
