<template>
<div>
    <h2>
      7.
      {{ t("Components.Project.ProjectTask.Report1.ReportAppendix.Title") }}
    </h2>
    <!-- 稽查参考标准 -->
    <!-- <ItemEdit
      v-if="contentSummaryData && contentSummaryData.length > 0"
      :projectTaskContentSummary="contentSummaryData[0]"
      :projectTaskGuid="props.projectTaskGuid"
      @getContentSummary="getContentSummary"
      :checkCommentGuid="checkCommentGuid"
      :projectTaskProgress="props.projectTaskProgress"
      :commentModuleCount="commentModuleCount"
    /> -->

<div v-if="props.data && props.data.report_appendixs" >
  <ReportAppendixSingle v-for="(item,index) in props.data.report_appendixs" :key="index" :data="item"
  :projectTaskProgress="props.projectTaskProgress"
      :commentModuleCount="commentModuleCount"
      @getProjectTask="()=>emits('getProjectTask')"
      :canReview="props.canReview" />
</div>
    
  </div>
</template>
<script setup >
import {defineProps,defineEmits} from 'vue'
import { useI18n } from "vue-i18n";
import ReportAppendixSingle from './_ReportAppendixSingle.vue'

const { t } = useI18n();

const emits = defineEmits(['getProjectTask'])

const props = defineProps({
  data:{
    type: Object,
    default: ()=>{}
  },
  projectTaskProgress: {
    type: Number,
    default: () => 0,
  },
  commentModuleCount:{
    type: Array,
    default: ()=>[]
  },
  canReview: {
    type: Boolean,
    default: ()=> false
  }
})

</script>
<style scoped lang='scss'>

</style>