<template>
  <Dialog
    class="my-dialog"
    width="80%"
    :showFooter="false"
    @handleCancle="handleCancle"
  >
    <div class="date-div">
      {{ props.currentDate ? props.currentDate.date_str : "" }}
    </div>
    <el-table
      :data="
        props.currentDate && props.currentDate.project_tasks
          ? props.currentDate.project_tasks
          : []
      "
      row-key="project_task_guid"
    >
      <el-table-column
        prop="index"
        width="60"
        :label="t('Components.Index.Home.Calendar.Index')"
      />
      <el-table-column
        prop="project_task_number"
        :label="t('Components.Index.Home.Calendar.ProjectTaskNumber')"
      />
      <el-table-column
        prop="scheme_name"
        :label="t('Components.Index.Home.Calendar.SchemeName')"
      />
      <el-table-column
        prop="audited_party_name"
        :label="t('Components.Index.Home.Calendar.AuditedPartyName')"
      />
      <el-table-column
        prop="planned_audit_starttime"
        :label="t('Components.Index.Home.Calendar.PlannedAuditStartTime')"
        v-if="props.calenderType === 1"
      >
        <template #default="scope">
          <el-text>{{
            scope.row.planned_audit_starttime
              ? formatTime(new Date(scope.row.planned_audit_starttime))
              : ""
          }}</el-text>
        </template>
      </el-table-column>
      <el-table-column
        prop="planned_audit_endtime"
        :label="t('Components.Index.Home.Calendar.PlannedAuditEndTime')"
        v-if="props.calenderType === 1"
      >
        <template #default="scope">
          <el-text>{{
            scope.row.planned_audit_endtime
              ? formatTime(new Date(scope.row.planned_audit_endtime))
              : ""
          }}</el-text>
        </template>
      </el-table-column>
      

      <el-table-column
        prop="actual_audit_starttime"
        :label="t('Components.Index.Home.Calendar.ActualAuditStartTime')"
        v-if="props.calenderType === 1"
      >
        <template #default="scope">
          <el-text>{{
            scope.row.actual_audit_starttime
              ? formatTime(new Date(scope.row.actual_audit_starttime))
              : ""
          }}</el-text>
        </template>
      </el-table-column>
      <el-table-column
        prop="actual_audit_endtime"
        :label="t('Components.Index.Home.Calendar.ActualAuditEndTime')"
        v-if="props.calenderType === 1"
      >
        <template #default="scope">
          <el-text>{{
            scope.row.actual_audit_endtime
              ? formatTime(new Date(scope.row.actual_audit_endtime))
              : ""
          }}</el-text>
        </template>
      </el-table-column>



      <el-table-column
        :label="t('Components.Index.Home.Calendar.PlannedReportingTime')"
        prop="planned_reporting_date"
        v-if="props.calenderType === 2"
      >
        <template #default="scope">
          <el-text>{{
            scope.row.planned_reporting_date
              ? formatTime(new Date(scope.row.planned_reporting_date))
              : ""
          }}</el-text>
        </template>
      </el-table-column>
      <el-table-column
        :label="t('Components.Index.Home.Calendar.PlannedCAPATime')"
        prop="planned_capa_date"
        v-if="props.calenderType === 2"
      >
        <template #default="scope">
          <el-text>{{
            scope.row.planned_capa_date
              ? formatTime(new Date(scope.row.planned_capa_date))
              : ""
          }}</el-text>
        </template>
      </el-table-column>
      
      <el-table-column
        prop="project_task_createname"
        :label="t('Components.Index.Home.Calendar.IsCAPA')"
      >
      <template #default="scope"  >
        <el-tag :type="scope.row.is_capa ? '' : 'info'">{{ scope.row.is_capa ? '是' : '否' }}</el-tag>
      </template>
      </el-table-column>
      <el-table-column
        prop="project_task_createname"
        :label="t('Components.Index.Home.Calendar.ProjectTaskCreator')"
      />
      <el-table-column
        prop="project_manager_name"
        :label="t('Components.Index.Home.Calendar.ProjectManager')"
      />
      <el-table-column
        prop="project_assistance_name"
        :label="t('Components.Index.Home.Calendar.PorjectAssistance')"
      />
      <el-table-column
        prop="project_task_number"
        :label="t('Components.Index.Home.Calendar.DispatchUsers')"
        :width="120"
      >
        <template #default="scope">
            <el-tag
              v-for="(item, index) in scope.row.dispatch_users"
              :style="{margin: '0 8px 8px 0'}"
              :key="index"
              >{{ item.user_name }}</el-tag
            >
        </template>
      </el-table-column>
    </el-table>
  </Dialog>
</template>
<script setup>
import {onMounted, defineEmits,defineProps } from "vue";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { useI18n } from "vue-i18n";
import { formatTime } from "@/util/timeUtil";

const { t } = useI18n();
const emits = defineEmits(["handleCloseDialog"]);
const props = defineProps({
  currentDate: {
    type: Object,
    default: () => undefined,
  },
  calenderType: {
    type: Number,
    default: () => 0,
  },
});
onMounted(async () => {});

// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};
</script>
<style>
.my-dialog .el-dialog__body {
  padding-top: 10px;
}
</style>
<style scoped lang="scss">
.date-div {
  height: 38px;
  font-size: 1.2em;
  font-weight: 900;
  justify-content: center;
  // align-items: center;
  display: flex;
}
</style>
