<template>
  <ul class="dictionary-root-ul">
    <li class="dictionary-root-left-li">
      <el-scrollbar>
        <Dictionary
          @handleDictionaryClick="handleDictionaryClick"
          @handleClearActiveDictionary="handleClearActiveDictionary"
        />
      </el-scrollbar>
    </li>
    <li class="dictionary-root-right-li">
      <el-scrollbar>
        <DictionaryItem ref="dictionaryItemRef"/>
      </el-scrollbar>
    </li>
  </ul>
</template>
<script setup>
import { ref } from "vue";
import Dictionary from "@/components/System/Dictionary/DictionaryIndex.vue";
import DictionaryItem from "@/components/System/Dictionary/DictionaryItem.vue";
// 右侧对象
const dictionaryItemRef = ref();
// 处理字典点击事件
const handleDictionaryClick = async (dictionaryGuid) => {
  dictionaryItemRef.value.clearInfo();
  // 需要查询右侧菜单按钮的数据
  await dictionaryItemRef.value.getDictionaryItem(dictionaryGuid);
};
// 清空右侧数据
const handleClearActiveDictionary = () => {
  dictionaryItemRef.value.clearInfo();
};
</script>
<style>

.dictionary-root-left-li .el-scrollbar__view{
  height: calc(100% - 8px);
}
</style>
<style scoped lang="scss">
.dictionary-root-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .dictionary-root-left-li {
    width: 50%;
    padding-right: 8px;
  }
  .dictionary-root-right-li {
    padding-left: 8px;
    width: 50%;
  }
}
</style>
