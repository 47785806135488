
// api
export const ApiStatus = {
  Expired: 601,
  RemoteLogin: 600,
  Permission: 401,
  NoCode: 999,
  Success: 200,
  InternalError: 500,
  BusinessError: 501
}
// 支付类型
export const PaymentType = {
  Ali: 0,
  Weixin: 1
}
// 订单状态
export const OrderStatus = {
  WaitPay: 1, // 待支付
  PaySuccess: 2, // 支付完成
  PayError: 3, // 支付失败 
  PayClose: 4, // 订单关闭
  Refunding: 5, // 退款
  RefundSuccess: 6, // 退款完成
}
// 退款申请单状态
export const RefundApplyStatus = {
  WaitProcess: 1, // 待处理
  Complete: 2, // 处理完成
}
// 发票申请单状态
export const BillApplyStatus = {
  WaitProcess: 0, // 待处理
  Complete: 1, // 处理完成
}
// 菜单和菜单按钮类型
export const MenuAndMenuButtonType = {
  Menu: 1, // 菜单
  MenuButton: 2, // 菜单按钮
}
// 选中类型
export const CheckType = {
  Checked: 1,// 全选中
  HalfChecked: 2, //半选中
}
// 性别
export const Sex={
  Male: 1, // 男
  Female: 2, // 女
}
export const YesOrNo = {
  Yes: true,
  No: false,
}
// 字典项回显样式
export const DictionaryItemEchoStyle = {
  Default: 'default', // 默认
  Primary: 'primary', // 主要
  Success: 'success', // 成功
  Info: 'info', // 信息
  Warning: 'warning', // 警告
  Danger: 'danger', // 危险
}
// 访问日志方法
export const AccessLogType = {
  Search: 1, // 查询
  Add: 2, // 新增
  Modify: 3, // 修改
  SingleDelete: 4, // 单条删除
  BatchDelete: 5, // 批量删除
  Export: 6, //  导出
  Import: 7, // 导入
  Clear: 8, // 清空
  Dataflag: 9, // 设置有效性
  Login: 10, // 登录
}
// 访问日志模块
export const AccessLogModule = {
  SysemModule: 1, // 系统模块
  Util: 2, // 工具模块
  BaseService: 3, // 基础服务
  Log: 4, // 日志模块
  Database: 5, // 信息库模块
  Project: 6, // 项目模块
  ReviewApprove: 7, // 审阅批准
  Progress: 8, // 进度模块
  WorkingHours: 9, // 工时模块
  Calendar: 10, // 日历模块
  Statistics: 11, // 统计模块
}
// 报告分级
export const ReportGrading = {
  Certical: 1, // 重大
  Major: 2, // 重要
  Minor: 3, // 轻微
  Comment: 4, // 建议
}
// 项目进度
export const ProjectProgress = {
  ToBeAllocated: 0,
  Ready: 1, // 准备中
  InProgress: 2, // 进行中
  Complete: 3, // 完成
  Cancel: 4, // 取消
}
// 项目操作类型
export const ProjectOperateType = {
  Complete : 1, // 完成操作
  Restart : 2, // 重启操作
  Cancel: 3, // 取消操作
}
// 项目任务进度
export const ProjectTaskProgress = {
  ToBeAllocated: 1, // 待分配
  Ready: 2, // 准备中
  AuditImplementation: 3, // 稽查实施
  ReportWriting: 4, // 报告撰写
  SubmitForReview: 5, // 提交审阅（待审阅）
  UnderReview: 6, // 审阅中
  ReviewedAndApproved: 7, // 审阅通过
  Cancel: 8, // 已取消
  Complete: 9, // 已完成
  Rewrite: 10, // 返改
  CAPAFinalize: 11, // CAPA定稿
}
// 项目任务操作类型
export const ProjectTaskOperateType = {
  Cancel: 1, // 取消
  StartReview: 2, // 开始审阅
  Complete: 3, //完成
  Restart: 4, // 重启
  Rewrite: 5, // 返改
  CAPAFinalize: 6, //CAPA定稿
}
// 中心状态类型
export const ProjectTaskCenterStatusType = {
  Filter: 1, // 筛选
  Import: 2, // 导入
  JoinGroup: 3, // 入组
  Exist: 4, // 退出
  FollowUp: 5, // 随访
  Complete: 6, //完成
}
// 项目任务评论模块
export const ProjectTaskCommentModule = {
  Default: 0, // 默认
  BaseInfo: 1, // 基础信息模块
  Distribution: 2, // 稽查报告分发抄送模块
  ContentSummary: 3, // 稽查内容及小结
  DiscoveryDescriptionAll: 4, //稽查发现描述全部
  Grading: 5, // 分级
  MainCategory: 6, // 主分类
  SubCategory: 7, // 子分类
  Elaborate: 8, //稽查发现描述详述模块
  ReportAppendixDetail: 9, // 报告附件详情
  ReportAppendixSingleAll: 10, // 报告附件单个全部
  ReferTo: 11, // 参考
}
// 项目任务评论中稽查发现描述详述模块枚举
export const ProjectTaskCommentElaborate = {
  Default: 0, //默认
  Summary: 1, // 归纳
  FilterNumber: 2, // 筛选号
  Detail: 3, // 详情
  Overview: 4, // 概述
}
// 项目任务报告基本信息
export const ProjectTaskBaseInfoOperateType = {
  Sponsor: 0,
  AuditDate: 1,
  ReportDate: 2,
  ProjectManager:3,
  Inspector:4,
  CenterNumber: 5,
  AuditAddress: 6,
}
// 审核模式
export const ProjectTaskApproveMode = {
  And: 1, // 会签
  Or: 2, // 或签
}
// 统计任务 查询类型
export const StatisticsProjectTaskSearchType = {
  Indications: 1, // 适应症
  TherapeuticArea: 2, // 治疗领域
}
// 工时类型
export const WorkingHoursType = {
  Audit: 1, // 稽查类型
  NotAudit : 2, // 非稽查类型
}
// 工时详情类型
export const WorkingHoursDetailType = {
  Plan: 1, // 计划
  Actual: 2, // 实际
}
// 用户排班类型
export const UserSchedulingType={
  Scheduling: 1, //排班
  Vacation: 2, // 休假
}
// 审批状态
export const ApproveStatus = {
  Pass: 1, // 通过
  Refuse : 2, // 拒绝
}
// 任务日志类型
export const ProjectTaskLogType = {
  Commit: 1, // 提交
  Dipatch: 2, // 派遣
  Approve: 3, // 审核
}
// 审阅查询类型
export const ReviewApproveType = {
  WaitApprove: 1, // 待审阅
  HaveApprove: 2, // 已审阅
  Collboration: 3, // 协同
}
// 超窗类型
export const ReportAndCAPAHyperWindowType = {
  NotHyperWindow: 1, // 未超窗
  HyperWindow: 2, // 超窗
}
export const ReportAppendixModifyType = {
  Name: 1,
  FilterNumber: 2,
  SerialNumber: 3,
  Visit: 4,
  SourceRecord: 5,
  EDCPosition: 6,
  EDCContent: 7,
}
// 审阅进度
export const ApproveProgress= {
  SubmitForReview: 5, // 提交审阅（待审阅）
  UnderReview: 6, // 审阅中
  ReviewedAndApproved: 7, // 审阅通过
  Rewrite: 10, // 返改
}