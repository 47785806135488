import { get, post, put, del } from './request'
import { BASEURL } from './config'
/**
 * showErrorMessage500 服务器内部错误
 * showErrorMessage501 业务错误
 * showSuccessMessage 显示成功信息
 * alwaysDoFunc 始终执行的回调函数
 * errDoFunc 错误执行的回调函数
 */

// 登录
export const request_login = (data, alwaysDoFunc = null, showSuccessMessage = true) => post({
  url: '/api/v1.0/login',
  data,
  alwaysDoFunc,
  showSuccessMessage
})
// 账户锁定
export const request_hm_lock = (data, showSuccessMessage=true)=>post({
  url: '/api/v1.0/hm/lock',
  data,
  showSuccessMessage,
})
// 获取用户基本信息
export const request_user_baseinfo = () => post({
  url: `/api/v1.0/user/baseinfo`
})
// 获取用户菜单权限数据
export const request_user_menupermission = () => post({
  url: `/api/v1.0/user/menupermission`
})
// 分页获取用户 
export const request_user_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/user/page`,
  data,
  alwaysDoFunc
})
// 保存用户
export const request_user_add = (data, showSuccessMessage = true) => post({
  url: `/api/v1.0/user`,
  data,
  showSuccessMessage
})
// 修改用户
export const request_user_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/user/${guid}`,
  data,
  showSuccessMessage
})
// 删除用户
export const request_user_delete = (guid, showSuccessMessage = true, alwaysDoFunc = null) => del({
  url: `/api/v1.0/user/${guid}`,
  showSuccessMessage,
  alwaysDoFunc
})
// 批量删除用户
export const request_user_delete_batch = (data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/user/batchdelete`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 设置用户有效性
export const request_user_dataflag = (guid, showSuccessMessage = true, errDoFunc = null) => put({
  url: `/api/v1.0/user/${guid}/dataflag`,
  showSuccessMessage,
  errDoFunc
})
// 导出
export const request_user_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/user/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 修改基本信息
export const request_user_upbaseinfo = (data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/user/upbaseinfo`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 修改密码
export const request_user_uppassword = (data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/user/uppassword`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 修改头像
export const request_user_upavator = (data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/user/upavator`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 重置密码
export const request_user_resetpassword = (guid, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/user/${guid}/resetpassword`,
  showSuccessMessage,
  alwaysDoFunc
})
// 系统设置
export const request_save_system_setting = (data, showSuccessMessage = true) => put({
  url: `/api/v1.0/systemsetting`,
  data,
  showSuccessMessage
})
// 菜单全部
export const request_menu_all = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/menu/all`,
  data,
  alwaysDoFunc
})
// 获取菜单和菜单按钮的属性结构
export const request_menu_menubutton_tree = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/menu/tree`,
  data,
  alwaysDoFunc
})
// 添加菜单
export const request_menu_add = (data, showSuccessMessage = true) => post({
  url: `/api/v1.0/menu`,
  data,
  showSuccessMessage
})
// 修改菜单
export const request_menu_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/menu/${guid}`,
  data,
  showSuccessMessage
})
// 删除菜单
export const request_menu_delete = (guid, showSuccessMessage = true) => del({
  url: `/api/v1.0/menu/${guid}`,
  showSuccessMessage
})
// 设置菜单有效性
export const request_menu_dataflag = (guid, showSuccessMessage = true) => put({
  url: `/api/v1.0/menu/${guid}/dataflag`,
  showSuccessMessage
})
// 获取菜单下的所有菜单按钮
export const request_menubutton_all = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/menubutton/all`,
  data,
  alwaysDoFunc
})
// 添加菜单按钮
export const request_menubutton_add = (data, showSuccessMessage = true) => post({
  url: `/api/v1.0/menubutton`,
  data,
  showSuccessMessage
})
// 修改菜单按钮
export const request_menubutton_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/menubutton/${guid}`,
  data,
  showSuccessMessage
})
// 设置菜单按钮有效性
export const request_menubutton_dataflag = (guid, showSuccessMessage = true) => put({
  url: `/api/v1.0/menubutton/${guid}/dataflag`,
  showSuccessMessage
})
// 删除菜单按钮
export const request_menubutton_delete = (guid, showSuccessMessage = true) => del({
  url: `/api/v1.0/menubutton/${guid}`,
  showSuccessMessage
})
// 分页查询角色
export const request_role_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/role/page`,
  data,
  alwaysDoFunc
})
// 保存角色
export const request_role_add = (data, showSuccessMessage = true) => post({
  url: `/api/v1.0/role`,
  data,
  showSuccessMessage
})
// 复制角色
export const request_role_copy = (data, showSuccessMessage = true) => post({
  url: `/api/v1.0/role/copy`,
  data,
  showSuccessMessage
})
// 修改角色
export const request_role_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/role/${guid}`,
  data,
  showSuccessMessage
})
// 删除角色
export const request_role_delete = (guid, showSuccessMessage = true, alwaysDoFunc = null) => del({
  url: `/api/v1.0/role/${guid}`,
  showSuccessMessage,
  alwaysDoFunc
})
// 批量删除角色
export const request_role_delete_batch = (data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/role/batchdelete`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 设置角色有效性
export const request_role_dataflag = (guid, showSuccessMessage = true, errDoFunc = null) => put({
  url: `/api/v1.0/role/${guid}/dataflag`,
  showSuccessMessage,
  errDoFunc
})
// 导出
export const request_role_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/role/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 获取所有部门数据
export const request_department_all = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/department/all`,
  data,
  alwaysDoFunc
})
// 获取所有部门树
export const request_department_tree = (data) => post({
  url: `/api/v1.0/department/tree`,
  data,
})
// 添加部门数据
export const request_department_add = (data, showSuccessMessage = true) => post({
  url: `/api/v1.0/department`,
  data,
  showSuccessMessage
})
// 修改部门
export const request_department_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/department/${guid}`,
  data,
  showSuccessMessage
})
// 删除部门
export const request_department_delete = (guid, showSuccessMessage = true) => del({
  url: `/api/v1.0/department/${guid}`,
  showSuccessMessage
})
// 设置部门有效性
export const request_department_dataflag = (guid, showSuccessMessage = true, errDoFunc = null) => put({
  url: `/api/v1.0/department/${guid}/dataflag`,
  showSuccessMessage,
  errDoFunc
})


// 分页查询岗位
export const request_job_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/job/page`,
  data,
  alwaysDoFunc
})
// 保存岗位
export const request_job_add = (data, showSuccessMessage = true) => post({
  url: `/api/v1.0/job`,
  data,
  showSuccessMessage
})
// 修改岗位
export const request_job_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/job/${guid}`,
  data,
  showSuccessMessage
})
// 删除岗位
export const request_job_delete = (guid, showSuccessMessage = true, alwaysDoFunc = null) => del({
  url: `/api/v1.0/job/${guid}`,
  showSuccessMessage,
  alwaysDoFunc
})
// 批量删除岗位
export const request_job_delete_batch = (data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/job/batchdelete`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 设置岗位有效性
export const request_job_dataflag = (guid, showSuccessMessage = true, errDoFunc = null) => put({
  url: `/api/v1.0/job/${guid}/dataflag`,
  showSuccessMessage,
  errDoFunc
})
// 导出
export const request_job_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/job/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 清除缓存
export const request_util_clearcache = (showSuccessMessage = true) => post({
  url: `/api/v1.0/util/clearcache`,
  showSuccessMessage
})
// 上传文件地址
export const request_util_upload_api = BASEURL + '/api/v1.0/util/upload'
// 上传文件地址
export const request_util_upload_api_res = (type)=>{
  return BASEURL + `/api/v1.0/util/upload/res/${type}`
}

// 分页查询字典
export const request_dictionary_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/dictionary/page`,
  data,
  alwaysDoFunc
})
// 保存字典
export const request_dictionary_add = (data, showSuccessMessage = true) => post({
  url: `/api/v1.0/dictionary`,
  data,
  showSuccessMessage
})
// 修改字典
export const request_dictionary_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/dictionary/${guid}`,
  data,
  showSuccessMessage
})
// 删除字典
export const request_dictionary_delete = (guid, showSuccessMessage = true, alwaysDoFunc = null) => del({
  url: `/api/v1.0/dictionary/${guid}`,
  showSuccessMessage,
  alwaysDoFunc
})
// 批量删除字典
export const request_dictionary_delete_batch = (data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/dictionary/batchdelete`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 设置字典有效性
export const request_dictionary_dataflag = (guid, showSuccessMessage = true, errDoFunc = null) => put({
  url: `/api/v1.0/dictionary/${guid}/dataflag`,
  showSuccessMessage,
  errDoFunc
})
// 导出
export const request_dictionary_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/dictionary/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 导入地址
export const request_dic_import = BASEURL + '/api/v1.0/dictionary/import'

// 分页查询字典
export const request_dictionaryitem = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/dictionaryitem/get`,
  data,
  alwaysDoFunc
})
// 根据字典编号获取字典项
export const request_dicionaryitem_bydiccode = (data) => post({
  url: `/api/v1.0/dictionaryitem/bydiccode`,
  data,
})
// 保存字典
export const request_dictionaryitem_add = (data, showSuccessMessage = true) => post({
  url: `/api/v1.0/dictionaryitem`,
  data,
  showSuccessMessage
})
// 修改字典
export const request_dictionaryitem_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/dictionaryitem/${guid}`,
  data,
  showSuccessMessage
})
// 删除字典
export const request_dictionaryitem_delete = (guid, showSuccessMessage = true, alwaysDoFunc = null) => del({
  url: `/api/v1.0/dictionaryitem/${guid}`,
  showSuccessMessage,
  alwaysDoFunc
})
// 批量删除字典项
export const request_dictionaryitem_delete_batch = (data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/dictionaryitem/batchdelete`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 设置字典有效性
export const request_dictionaryitem_dataflag = (guid, showSuccessMessage = true, errDoFunc = null) => put({
  url: `/api/v1.0/dictionaryitem/${guid}/dataflag`,
  showSuccessMessage,
  errDoFunc
})
// 导出
export const request_dictionaryitem_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/dictionaryitem/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 导入地址
export const request_dictionaryitem_import = BASEURL + '/api/v1.0/dictionaryitem/import'

// 分页查询系统参数
export const request_systemparam_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/systemparam/page`,
  data,
  alwaysDoFunc
})
// 获取单条系统参数
export const request_systemparam_single = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/systemparam/single`,
  data,
  alwaysDoFunc
})
// 保存系统参数
export const request_systemparam_add = (data, showSuccessMessage = true) => post({
  url: `/api/v1.0/systemparam`,
  data,
  showSuccessMessage
})
// 修改系统参数
export const request_systemparam_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/systemparam/${guid}`,
  data,
  showSuccessMessage
})
// 删除系统参数
export const request_systemparam_delete = (guid, showSuccessMessage = true, alwaysDoFunc = null) => del({
  url: `/api/v1.0/systemparam/${guid}`,
  showSuccessMessage,
  alwaysDoFunc
})
// 批量删除系统参数
export const request_systemparam_delete_batch = (data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/systemparam/batchdelete`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 设置系统参数有效性
export const request_systemparam_dataflag = (guid, showSuccessMessage = true, errDoFunc = null) => put({
  url: `/api/v1.0/systemparam/${guid}/dataflag`,
  showSuccessMessage,
  errDoFunc
})
// 导出
export const request_systemparam_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/systemparam/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 分页查询通知公告
export const request_announcement_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/announcement/page`,
  data,
  alwaysDoFunc
})
// 获取通知公告单条内容
export const request_announcement_content = (guid, alwaysDoFunc = null) => get({
  url: `/api/v1.0/announcement/${guid}/content`,
  alwaysDoFunc
})
// 保存通知公告
export const request_announcement_add = (data, showSuccessMessage = true) => post({
  url: `/api/v1.0/announcement`,
  data,
  showSuccessMessage
})
// 修改通知公告
export const request_announcement_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/announcement/${guid}`,
  data,
  showSuccessMessage
})
// 删除通知公告
export const request_announcement_delete = (guid, showSuccessMessage = true, alwaysDoFunc = null) => del({
  url: `/api/v1.0/announcement/${guid}`,
  showSuccessMessage,
  alwaysDoFunc
})
// 批量删除通知公告
export const request_announcement_delete_batch = (data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/announcement/batchdelete`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 设置通知公告有效性
export const request_announcement_dataflag = (guid, showSuccessMessage = true, errDoFunc = null) => put({
  url: `/api/v1.0/announcement/${guid}/dataflag`,
  showSuccessMessage,
  errDoFunc
})
// 设置通知公告显隐
export const request_announcement_showornot = (guid, showSuccessMessage = true, errDoFunc = null) => put({
  url: `/api/v1.0/announcement/${guid}/showornot`,
  showSuccessMessage,
  errDoFunc
})
// 获取发布状态的公共
export const request_announcement_show = () => get({
  url: `/api/v1.0/announcement/show`,
})

// 分页查询请求日志
export const request_accesslog_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/accesslog/page`,
  data,
  alwaysDoFunc
})
// 清空请求日志
export const request_accesslog_clear = (alwaysDoFunc = null, showSuccessMessage = true) => post({
  url: `/api/v1.0/accesslog/clear`,
  alwaysDoFunc,
  showSuccessMessage
})
// 删除请求日志
export const request_accesslog_delete = (guid, showSuccessMessage = true, alwaysDoFunc = null) => del({
  url: `/api/v1.0/accesslog/${guid}`,
  showSuccessMessage,
  alwaysDoFunc
})
// 批量删除请求日志
export const request_accesslog_delete_batch = (data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/accesslog/batchdelete`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 导出
export const request_accesslog_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/accesslog/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})


// 分页查询登录日志
export const request_loginlog_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/loginlog/page`,
  data,
  alwaysDoFunc
})
// 清空登录日志
export const request_loginlog_clear = (alwaysDoFunc = null, showSuccessMessage = true) => post({
  url: `/api/v1.0/loginlog/clear`,
  alwaysDoFunc,
  showSuccessMessage
})
// 删除登录日志
export const request_loginlog_delete = (guid, showSuccessMessage = true, alwaysDoFunc = null) => del({
  url: `/api/v1.0/loginlog/${guid}`,
  showSuccessMessage,
  alwaysDoFunc
})
// 批量删除登录日志
export const request_loginlog_delete_batch = (data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/loginlog/batchdelete`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 导出
export const request_loginlog_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/loginlog/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})

// 分页查询模板
export const request_exceltemplate_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/exceltemplate/page`,
  data,
  alwaysDoFunc
})
// 根据编码获取excel模板
export const request_exceltemplate_bycode = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/exceltemplate/bycode`,
  data,
  alwaysDoFunc
})
// 保存模板
export const request_exceltemplate_add = (data, showSuccessMessage = true) => post({
  url: `/api/v1.0/exceltemplate`,
  data,
  showSuccessMessage
})
// 修改模板
export const request_exceltemplate_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/exceltemplate/${guid}`,
  data,
  showSuccessMessage
})
// 删除模板
export const request_exceltemplate_delete = (guid, showSuccessMessage = true, alwaysDoFunc = null) => del({
  url: `/api/v1.0/exceltemplate/${guid}`,
  showSuccessMessage,
  alwaysDoFunc
})
// 批量删除模板
export const request_exceltemplate_delete_batch = (data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/exceltemplate/batchdelete`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 设置模板有效性
export const request_exceltemplate_dataflag = (guid, showSuccessMessage = true, errDoFunc = null) => put({
  url: `/api/v1.0/exceltemplate/${guid}/dataflag`,
  showSuccessMessage,
  errDoFunc
})

// 分页查询被稽查方
export const request_auditedparty_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/auditedparty/page`,
  data,
  alwaysDoFunc
})
// 保存被稽查方
export const request_auditedparty_add = (data, showSuccessMessage = true) => post({
  url: `/api/v1.0/auditedparty`,
  data,
  showSuccessMessage
})
// 修改被稽查方
export const request_auditedparty_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/auditedparty/${guid}`,
  data,
  showSuccessMessage
})
// 删除被稽查方
export const request_auditedparty_delete = (guid, showSuccessMessage = true, alwaysDoFunc = null) => del({
  url: `/api/v1.0/auditedparty/${guid}`,
  showSuccessMessage,
  alwaysDoFunc
})
// 批量删除被稽查方
export const request_auditedparty_delete_batch = (data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/auditedparty/batchdelete`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 设置被稽查方有效性
export const request_auditedparty_dataflag = (guid, showSuccessMessage = true, errDoFunc = null) => put({
  url: `/api/v1.0/auditedparty/${guid}/dataflag`,
  showSuccessMessage,
  errDoFunc
})
// 导出
export const request_auditedparty_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/auditedparty/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})

// 分页查询委托方
export const request_client_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/client/page`,
  data,
  alwaysDoFunc
})
// 保存委托方
export const request_client_add = (data, showSuccessMessage = true) => post({
  url: `/api/v1.0/client`,
  data,
  showSuccessMessage
})
// 修改委托方
export const request_client_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/client/${guid}`,
  data,
  showSuccessMessage
})
// 删除委托方
export const request_client_delete = (guid, showSuccessMessage = true, alwaysDoFunc = null) => del({
  url: `/api/v1.0/client/${guid}`,
  showSuccessMessage,
  alwaysDoFunc
})
// 批量删除委托方
export const request_client_delete_batch = (data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/client/batchdelete`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 设置委托方有效性
export const request_client_dataflag = (guid, showSuccessMessage = true, errDoFunc = null) => put({
  url: `/api/v1.0/client/${guid}/dataflag`,
  showSuccessMessage,
  errDoFunc
})
// 导出
export const request_client_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/client/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})

// 查询所有的项目
export const request_project_all = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/project/all`,
  data,
  alwaysDoFunc
})
// 分页查询项目列表
export const request_project_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/project/page`,
  data,
  alwaysDoFunc
})
// 保存项目列表
export const request_project_add = (data, showSuccessMessage = true) => post({
  url: `/api/v1.0/project`,
  data,
  showSuccessMessage
})
// 生成项目编号
export const request_generate_projectnumber = (data) => post({
  url: `/api/v1.0/project/generateprojectnumber`,
  data
})
// 修改项目列表
export const request_project_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/project/${guid}`,
  data,
  showSuccessMessage
})
// 删除项目列表
export const request_project_delete = (guid, showSuccessMessage = true, alwaysDoFunc = null) => del({
  url: `/api/v1.0/project/${guid}`,
  showSuccessMessage,
  alwaysDoFunc
})
// 批量删除项目列表
export const request_project_delete_batch = (data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/project/batchdelete`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 设置项目列表方有效性
export const request_project_dataflag = (guid, showSuccessMessage = true, errDoFunc = null) => put({
  url: `/api/v1.0/project/${guid}/dataflag`,
  showSuccessMessage,
  errDoFunc
})
// 设置项目列表进度
export const request_project_progress = (guid, data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/project/${guid}/progress`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 导出
export const request_project_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/project/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 获取所有联想
export const request_project_association_all = () => get({
  url: `/api/v1.0/project/association/all`
})
// 分页查询项目任务
export const request_projecttask_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/projecttask/page`,
  data,
  alwaysDoFunc
})
// 查询项目下的所有任务
export const request_projecttask_all_byproject = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/projecttask/all/byproject`,
  data,
  alwaysDoFunc
})
// 获取项目任务详情
export const request_projecttask_detail = (guid) => get({
  url: `/api/v1.0/projecttask/${guid}`,
})
// 获取项目任务详情
export const request_projecttask_detail1 = (guid) => get({
  url: `/api/v1.0/projecttask/${guid}/detail`,
})
// 项目任务实际稽查时间校验
export const request_projecttask_actualtime_validate = (data) => post({
  url: `/api/v1.0/projecttask/actualtime/validate`,
  data
})
// 保存项目任务
export const request_projecttask_add = (data, showSuccessMessage = true) => post({
  url: `/api/v1.0/projecttask`,
  data,
  showSuccessMessage
})
// 修改项目任务
export const request_projecttask_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/projecttask/${guid}`,
  data,
  showSuccessMessage
})
// 获取任务日志
export const request_projecttask_log = (guid) => get({
  url: `/api/v1.0/projecttask/${guid}/log`,
})
// 项目任务修改进度
export const request_projecttask_progress = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/projecttask/${guid}/progress`,
  data,
  showSuccessMessage
})
// 项目任务派遣稽查人员查询
export const request_projecttask_dispatch_user = (guid) => get({
  url: `/api/v1.0/projecttask/${guid}/dispatchuser/get`,
})
// 项目任务派遣校验
export const request_projecttask_dispatch_validate = (guid, data) => post({
  url: `/api/v1.0/projecttask/${guid}/dispatchuser/validate`,
  data
})
// 项目任务派遣
export const request_projecttask_dispatch = (guid, data, showSuccessMessage = true) => post({
  url: `/api/v1.0/projecttask/${guid}/dispatchuser`,
  data,
  showSuccessMessage
})
// 项目任务派遣修改
export const request_projecttask_dispatch_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/projecttask/${guid}/dispatchuser`,
  data,
  showSuccessMessage
})
// 项目任务派遣删除
export const request_projecttask_dispatch_delete = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/projecttask/${guid}/dispatchuser/delete`,
  data,
  showSuccessMessage
})
// 获取项目任务的稽查小结
export const request_projecttask_auditsummary_get = (guid, alwaysDoFunc = null) => get({
  url: `/api/v1.0/projecttask/${guid}/auditsummary`,
  alwaysDoFunc
})
// 醒目任务稽查小结项获取
export const request_projecttask_auditsummary_item_get = (guid, data) => post({
  url: `/api/v1.0/projecttask/${guid}/auditsummary/item`,
  data
})
// 项目任务稽查小结的修改
export const request_projecttask_auditsummary_item_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/projecttask/${guid}/auditsummary/item`,
  data,
  showSuccessMessage
})
// 项目任务发现分页获取
export const request_projecttask_discovery_page = (guid, data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/projecttask/${guid}/discovery/page`,
  data,
  alwaysDoFunc
})
// 项目任务稽查发现新增
export const request_projecttask_discovery_add = (guid, data, showSuccessMessage = true) => post({
  url: `/api/v1.0/projecttask/${guid}/discovery`,
  data,
  showSuccessMessage
})
// 项目任务稽查发现修改
export const request_projecttask_discovery_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/projecttask/${guid}/discovery`,
  data,
  showSuccessMessage
})
// 项目任务稽查发现删除
export const request_projecttask_discovery_delete = (guid, data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/projecttask/${guid}/discovery/delete`,
  showSuccessMessage,
  data,
  alwaysDoFunc
})
// 项目任务稽查发现批量删除
export const request_projecttask_discovery_delete_batch = (guid, data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/projecttask/${guid}/discovery/batchdelete`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 设置稽查发现是否入报告
export const request_projecttask_discovery_isimportreport = (guid, data, showSuccessMessage = true, errDoFunc = null) => put({
  url: `/api/v1.0/projecttask/${guid}/discovery/isimportreport`,
  data,
  showSuccessMessage,
  errDoFunc
})
// 导出稽查发现
export const request_projecttask_discovery_export = (guid, data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/projecttask/${guid}/discovery/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 导入地址
export const request_discovery_import = BASEURL + `/api/v1.0/projecttask/discovery/import`
// 中心状态获取
export const request_projecttask_centerstatus_get = (guid) => get({
  url: `/api/v1.0/projecttask/${guid}/centerstatus`,
})
// 中心状态保存
export const request_projecttask_centerstatus_save = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/projecttask/${guid}/centerstatus`,
  data,
  showSuccessMessage
})
// 出席人员获取
export const request_projecttask_attendees_get = (guid) => get({
  url: `/api/v1.0/projecttask/${guid}/attendees`,
})
// 添加出席人员
export const request_projecttask_attendees_add = (guid, data, showSuccessMessage = true) => post({
  url: `/api/v1.0/projecttask/${guid}/attendees`,
  data,
  showSuccessMessage
})
// 修改出席人员
export const request_projecttask_attendees_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/projecttask/${guid}/attendees`,
  data,
  showSuccessMessage
})
// 删除出席人员
export const request_projecttask_attendees_delete = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/projecttask/${guid}/attendees/delete`,
  data,
  showSuccessMessage
})
// 分发抄送人员获取
export const request_projecttask_distribution_get = (guid) => get({
  url: `/api/v1.0/projecttask/${guid}/distribution`,
})
// 添加分发抄送人员
export const request_projecttask_distribution_add = (guid, data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/projecttask/${guid}/distribution`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 修改分发抄送人员
export const request_projecttask_distribution_modify = (guid, data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/projecttask/${guid}/distribution`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 删除分发抄送人员
export const request_projecttask_distribution_delete = (guid, data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/projecttask/${guid}/distribution/delete`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 排序分发抄送人员
export const request_projecttask_distribution_sort = (guid, data, alwaysDoFunc = null) => put({
  url: `/api/v1.0/projecttask/${guid}/distribution/sort`,
  data,
  alwaysDoFunc
})
// 报告初始化
export const request_projecttask_initreport = (guid) => post({
  url: `/api/v1.0/projecttask/${guid}/report/init`,
})
// 发现描述分级修改
export const request_projecttask_discoverydescription_grading = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/projecttask/${guid}/discoverydescription/grading`,
  data,
  showSuccessMessage
})
// 发现描述分类修改
export const request_projecttask_discoverydescription_category = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/projecttask/${guid}/discoverydescription/category`,
  data,
  showSuccessMessage
})
// 获取发现描述数据
export const request_projecttask_discoverydescription_get = (guid) => get({
  url: `/api/v1.0/projecttask/${guid}/discoverydescription`,
})
// 发现描述问题修改
export const request_projecttask_discoverydescription_problem = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/projecttask/${guid}/discoverydescription/problem`,
  data,
  showSuccessMessage
})
// 发现描述参考
export const request_projecttask_discoverydescription_referto = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/projecttask/${guid}/discoverydescription/referto`,
  data,
  showSuccessMessage
})
// 获取评论
export const request_project_comment_get = (guid, data) => post({
  url: `/api/v1.0/projecttask/${guid}/comment/single`,
  data,
})
// 获取评论模块数量
export const request_project_comment_modulecount = (guid) => get({
  url: `/api/v1.0/projecttask/${guid}/comment/modulecount`,
})
// 阅读评论
export const request_project_comment_read = (guid, data, showSuccessMessage=true) => put({
  url: `/api/v1.0/projecttask/${guid}/comment/read`,
  data,
  showSuccessMessage
})
// 获取内容和小结
export const request_project_contentsummary_get = (guid) => get({
  url: `/api/v1.0/projecttask/${guid}/contentsummary1`,
})
// 获取内容和小结
export const request_project_contentsummary_get2 = (guid) => get({
  url: `/api/v1.0/projecttask/${guid}/contentsummary2`,
})
// 保存内容和小结
export const request_project_contentsummary_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/projecttask/${guid}/contentsummary`,
  data,
  showSuccessMessage
})
// 提交评论
export const request_project_contentsummary_comment = (guid, data, showSuccessMessage = true) => post({
  url: `/api/v1.0/projecttask/${guid}/contentsummary/comment`,
  data,
  showSuccessMessage
})
// 提交评论
export const request_project_comment = (guid, data, showSuccessMessage = true) => post({
  url: `/api/v1.0/projecttask/${guid}/comment`,
  data,
  showSuccessMessage
})
// 提交审阅
export const request_projecttask_review_submit = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/projecttask/${guid}/review/submit`,
  data,
  showSuccessMessage
})
// 审阅
export const request_projecttask_review_approve = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/projecttask/${guid}/review/approve`,
  data,
  showSuccessMessage
})
// 导出稽查证明
export const request_projecttask_auditcertificate_export = (guid, showSuccessMessage = true) => post({
  url: `/api/v1.0/projecttask/${guid}/auditcertificate/export`,
  showSuccessMessage
})
// 导出capa
export const request_projecttask_capa_export = (guid, showSuccessMessage = true) => post({
  url: `/api/v1.0/projecttask/${guid}/capa/export`,
  showSuccessMessage
})
// 导出报告
export const request_projecttask_report_export = (guid, showSuccessMessage = true) => post({
  url: `/api/v1.0/projecttask/${guid}/report/export`,
  showSuccessMessage
})

// 稽查小结分页
export const request_auditsummary_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/auditsummary/page`,
  data,
  alwaysDoFunc
})
// 稽查小结添加
export const request_auditsummary_add = (data, showSuccessMessage = true) => post({
  url: `/api/v1.0/auditsummary`,
  data,
  showSuccessMessage
})
// 稽查小结修改
export const request_auditsummary_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/auditsummary/${guid}`,
  data,
  showSuccessMessage
})
// 删除稽查小结
export const request_auditsummary_delete = (guid, showSuccessMessage = true, alwaysDoFunc = null) => del({
  url: `/api/v1.0/auditsummary/${guid}`,
  showSuccessMessage,
  alwaysDoFunc
})
// 批量删除稽查小结
export const request_auditsummary_delete_batch = (data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/auditsummary/batchdelete`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 设置稽查小结有效性
export const request_auditsummary_dataflag = (guid, showSuccessMessage = true, errDoFunc = null) => put({
  url: `/api/v1.0/auditsummary/${guid}/dataflag`,
  showSuccessMessage,
  errDoFunc
})
// 获取稽查小结项
export const request_auditsummary_item_all = (guid, data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/auditsummary/${guid}/item/all`,
  data,
  alwaysDoFunc
})
// 添加稽查小结项
export const request_auditsummary_item_add = (guid, data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/auditsummary/${guid}/item`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 修改稽查小结项
export const request_auditsummary_item_modify = (guid, data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/auditsummary/${guid}/item`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 删除稽查小结项
export const request_auditsummary_item_delete = (guid, data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/auditsummary/${guid}/item/delete`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 设置稽查小结项有效性
export const request_auditsummary_item_dataflag = (guid, data, showSuccessMessage = true, errDoFunc = null) => put({
  url: `/api/v1.0/auditsummary/${guid}/item/dataflag`,
  showSuccessMessage,
  errDoFunc,
  data,
})

// 分页查询我的审阅
export const request_reviewapprove_page = (data, alwaysDoFunc = null, errDoFunc = null,) => post({
  url: `/api/v1.0/reviewapprove/page`,
  data,
  errDoFunc,
  alwaysDoFunc
})
// 项目任务我的审阅信息
export const request_reviewapprove_info = (data, alwaysDoFunc = null, errDoFunc = null,) => post({
  url: `/api/v1.0/reviewapprove/info`,
  data,
  errDoFunc,
  alwaysDoFunc
})
// 分配审阅人
export const request_reviewapprove_distribute = (data, alwaysDoFunc = null, errDoFunc = null, showSuccessMessage = true) => post({
  url: `/api/v1.0/reviewapprove/distribute`,
  data,
  errDoFunc,
  alwaysDoFunc,
  showSuccessMessage
})
// 开始审阅
export const request_reviewapprove_startreview = (data, alwaysDoFunc = null, errDoFunc = null, showSuccessMessage = false) => post({
  url: `/api/v1.0/reviewapprove/startreview`,
  data,
  errDoFunc,
  alwaysDoFunc,
  showSuccessMessage
})
// 我的审阅导出
export const request_reviewapprove_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/reviewapprove/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 项目进度数量
export const request_projectprogress_count = () => get({
  url: `/api/v1.0/projectprogress/count`
})
// 项目进度分页查询
export const request_projectprogress_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/projectprogress/page`,
  data,
  alwaysDoFunc
})
// 项目进度导出
export const request_projectprogress_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/projectprogress/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 项目任务进度数量
export const request_projecttaskprogress_count = () => get({
  url: `/api/v1.0/projecttaskprogress/count`
})
// 项目任务进度分页查询
export const request_projecttaskprogress_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/projecttaskprogress/page`,
  data,
  alwaysDoFunc
})
// 项目任务进度导出
export const request_projecttaskprogress_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/projecttaskprogress/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})

// 项目及任务进度分页查询
export const request_projectandtaskprogress_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/projectandtaskprogress/page`,
  data,
  alwaysDoFunc
})
// 项目及任务进度导出
export const request_projectandtaskprogress_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/projectandtaskprogress/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 项目任务报告的基本信息数据更新
export const request_projecttask_report_baseinfo_update = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/projecttask/${guid}/baseinfo`,
  data,
  showSuccessMessage,
})
// 新增项目任务兼职人员
export const request_projecttask_parttime_user_add = (guid, data, showSuccessMessage=true)=>post({
  url: `/api/v1.0/projecttask/${guid}/parttimeuser/add`,
  data,
  showSuccessMessage,
})
// 修改项目任务兼职人员
export const request_projecttask_parttime_user_modify = (guid, data, showSuccessMessage=true)=>put({
  url: `/api/v1.0/projecttask/${guid}/parttimeuser/modify`,
  data,
  showSuccessMessage,
})
// 删除项目任务兼职人员
export const request_projecttask_parttime_user_delete = (guid, data, showSuccessMessage=true)=>put({
  url: `/api/v1.0/projecttask/${guid}/parttimeuser/delete`,
  data,
  showSuccessMessage,
})
// 项目任务报告的报告附件数据更新
export const request_projecttask_report_appendix_update = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/projecttask/${guid}/reportappendix`,
  data,
  showSuccessMessage,
})

// 报告CAPA进度分页查询
export const request_reportcapaprogress_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/reportcapaprogress/page`,
  data,
  alwaysDoFunc
})
// 报告CAPA进度统计
export const request_reportcapaprogress_count = (alwaysDoFunc = null) => get({
  url: `/api/v1.0/reportcapaprogress/count`,
  alwaysDoFunc
})
// 报告CAPA进度统计包括超窗3天和7天
export const request_reportcapaprogress_count1 = (alwaysDoFunc = null) => get({
  url: `/api/v1.0/reportcapaprogress/count1`,
  alwaysDoFunc
})
// 项目及任务进度导出
export const request_reportcapaprogress_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/reportcapaprogress/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})

// 分页查询工时
export const request_workinghours_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/workinghours/page`,
  data,
  alwaysDoFunc
})
// 保存工时
export const request_workinghours_add = (data, showSuccessMessage = true) => post({
  url: `/api/v1.0/workinghours`,
  data,
  showSuccessMessage
})
// 修改被工时
export const request_workinghours_modify = (guid, data, showSuccessMessage = true) => put({
  url: `/api/v1.0/workinghours/${guid}`,
  data,
  showSuccessMessage
})
// 删除工时
export const request_workinghours_delete = (guid, showSuccessMessage = true, alwaysDoFunc = null) => del({
  url: `/api/v1.0/workinghours/${guid}`,
  showSuccessMessage,
  alwaysDoFunc
})
// 批量删除工时
export const request_workinghours_delete_batch = (data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/workinghours/batchdelete`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 导出
export const request_workinghours_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/workinghours/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 获取项目任务日历
export const request_calendar_projecttask_all = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/calendar/projecttask`,
  data,
  alwaysDoFunc
})
// 获取人员日历
export const request_calendar_person = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/calendar/person`,
  data,
  alwaysDoFunc
})
// 修改日历中项目任务的相关时间
export const request_calendar_projecttask_time = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/calendar/projecttask/time`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 获取统计稽查发现统计
export const request_statistics_auditdiscovery_statistics = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/statistics/auditdiscovery/statistics`,
  data,
  alwaysDoFunc
})
// 获取分页统计稽查发现
export const request_statistics_auditdiscovery_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/statistics/auditdiscovery/page`,
  data,
  alwaysDoFunc
})
// 导出统计稽查发现统计
export const request_statistics_auditdiscovery_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/statistics/auditdiscovery/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 导出统计稽查发现统计单个主分类
export const request_statistics_auditdiscovery_singlemaincategory_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/statistics/auditdiscovery/singlemaincategory/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 获取任务统计
export const request_statistics_proejct_task = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/statistics/projecttask/statistics`,
  data,
  alwaysDoFunc
})
// 导出任务统计
export const request_statistics_project_task_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/statistics/projecttask/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 获取分页统计任务
export const request_statistics_projecttask_page = (data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/statistics/projecttask/page`,
  data,
  alwaysDoFunc
})
// 导出任务统计单个
export const request_statistics_project_task_single_export = (data, showSuccessMessage = true, alwaysDoFunc = null) => post({
  url: `/api/v1.0/statistics/projecttask/single/export`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})

export const request_home_project_countstatistic = (data)=>post({
  url: `/api/v1.0/home/project/countstatistic`,
  data,
})
export const request_home_projectandtask_progresscount = (data, alwaysDoFunc=null)=>post({
  url: `/api/v1.0/home/projectandtask/progresscount`,
  data,
  alwaysDoFunc
})
// export const request_home_project_statistic = (alwaysDoFunc=null)=>get({
//   url: `/api/v1.0/home/project/statistic`,
//   alwaysDoFunc
// })
export const request_home_calendar = (data,alwaysDoFunc=null)=>post({
  url: `/api/v1.0/home/calendar`,
  data,
  alwaysDoFunc
})
// 新增用户排班
export const request_userscheduling_add = (data, showSuccessMessage=true)=>post({
  url: `/api/v1.0/userscheduling`,
  data,
  showSuccessMessage,
})
// 修改用户排班
export const request_userscheduling_modify = (guid, data, showSuccessMessage=true)=>put({
  url: `/api/v1.0/userscheduling/${guid}`,
  data,
  showSuccessMessage,
})
// 删除用户排班
export const request_userscheduling_delete = (guid, showSuccessMessage=true)=>del({
  url: `/api/v1.0/userscheduling/${guid}`,
  showSuccessMessage,
})

// 获取分页项目任务报告附件
export const request_projecttask_reportappendix_page = (guid, data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/projecttask/${guid}/reportappendix/page`,
  data,
  alwaysDoFunc
})
// 新增项目任务报告附件
export const request_projecttask_reportappendix_add = (guid, data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/projecttask/${guid}/reportappendix`,
  data,
  alwaysDoFunc
})
// 修改项目任务报告附件
export const request_projecttask_reportappendix_modify = (guid, projectTaskReportAppendixGuid, data, showSuccessMessage=true)=>put({
  url: `/api/v1.0/projecttask/${guid}/reportappendix/${projectTaskReportAppendixGuid}`,
  data,
  showSuccessMessage,
})
// 删除项目任务报告附件
export const request_projecttask_reportappendix_delete = (guid,projectTaskReportAppendixGuid,showSuccessMessage=true)=>put({
  url: `/api/v1.0/projecttask/${guid}/reportappendix/${projectTaskReportAppendixGuid}/delete`,

  showSuccessMessage,
})
// 批量删除报告附件
export const request_projecttask_reportappendix_delete_batch = (guid, data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/projecttask/${guid}/reportappendix/batchdelete`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})
// 导入报告附件地址
export const request_projecttask_reportappendix_import = BASEURL + `/api/v1.0/projecttask/reportappendix/import`
// 获取项目任务报告附件详情分页
export const request_projecttask_reportappendix_detail_page = (guid,projectTaskReportAppendixGuid, data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/projecttask/${guid}/reportappendix/${projectTaskReportAppendixGuid}/detail/page`,
  data,
  alwaysDoFunc
})
// 新增项目任务报告附件详情
export const request_projecttask_reportappendix_detail_add = (guid, projectTaskReportAppendixGuid, data, alwaysDoFunc = null) => post({
  url: `/api/v1.0/projecttask/${guid}/reportappendix/${projectTaskReportAppendixGuid}/detail`,
  data,
  alwaysDoFunc
})
// 修改项目任务报告附件详情
export const request_projecttask_reportappendix_detail_modify = (guid, projectTaskReportAppendixGuid,projectTaskReportAppendixDetailGuid, data, showSuccessMessage=true)=>put({
  url: `/api/v1.0/projecttask/${guid}/reportappendix/${projectTaskReportAppendixGuid}/detail/${projectTaskReportAppendixDetailGuid}`,
  data,
  showSuccessMessage,
})
// 删除项目任务报告附件详情
export const request_projecttask_reportappendix_detail_delete = (guid,projectTaskReportAppendixGuid,projectTaskReportAppendixDetailGuid,showSuccessMessage=true)=>put({
  url: `/api/v1.0/projecttask/${guid}/reportappendix/${projectTaskReportAppendixGuid}/detail/${projectTaskReportAppendixDetailGuid}/delete`,

  showSuccessMessage,
})
// 批量删除报告附件详情
export const request_projecttask_reportappendix_detail_delete_batch = (guid, projectTaskReportAppendixGuid, data, showSuccessMessage = true, alwaysDoFunc = null) => put({
  url: `/api/v1.0/projecttask/${guid}/reportappendix/${projectTaskReportAppendixGuid}/detail/batchdelete`,
  data,
  showSuccessMessage,
  alwaysDoFunc
})