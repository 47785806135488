<template>
  <Dialog
    width="40%"
    :title="dialogTitle"
    :showFooter="
      props.editData &&
      props.editData.progress !== ProjectTaskProgress.Cancel &&
      props.editData.progress !== ProjectTaskProgress.Complete
    "
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form :model="form" label-width="140px" ref="formRef" size="large">
      <el-row>
        <el-col :span="24">
          <el-form-item
            :label="$t('Components.AuditCalendar.TaskCalendar.PlanAuditTime')"
            prop="name"
          >
            <el-date-picker
              disabled
              :style="{ width: '100%' }"
              v-model="form.planned_audit_time"
              type="daterange"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              range-separator=" - "
              :start-placeholder="
                t('Components.AuditCalendar.TaskCalendar.PleaseSelectStartDate')
              "
              :end-placeholder="
                t('Components.AuditCalendar.TaskCalendar.PleaseSelectEndDate')
              "
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item
            :label="$t('Components.AuditCalendar.TaskCalendar.ActualAuditTime')"
            prop="name"
          >
            <el-date-picker
              :style="{ width: '100%' }"
              v-model="form.actual_audit_time"
              type="daterange"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              range-separator=" - "
              :start-placeholder="
                t('Components.AuditCalendar.TaskCalendar.PleaseSelectStartDate')
              "
              :end-placeholder="
                t('Components.AuditCalendar.TaskCalendar.PleaseSelectEndDate')
              "
              :disabled-date="(date)=>{
                return date.getTime() > Date.now()
              }"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            :label="
              $t('Components.AuditCalendar.TaskCalendar.PlannedReportTime')
            "
            prop="name"
          >
            <el-date-picker
              v-model="form.planned_reporting_date"
              :style="{width: '100%'}"
              type="date"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              :placeholder="
                t(
                  'Components.AuditCalendar.TaskCalendar.PleaseSelectPlannedReportTime'
                )
              "
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="
              $t('Components.AuditCalendar.TaskCalendar.CompleteReportTime')
            "
            prop="name"
          >
            <el-date-picker
              v-model="form.completed_reporting_date"
              :style="{width: '100%'}"
              type="date"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              :placeholder="
                t(
                  'Components.AuditCalendar.TaskCalendar.PleaseSelectCompleteReportTime'
                )
              "
              :disabled-date="(date)=>{
                return date.getTime() > Date.now()
              }"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="props.editData && props.editData.is_capa">
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.AuditCalendar.TaskCalendar.CAPAPlanTime')"
            prop="name"
          >
            <el-date-picker
              :style="{width: '100%'}"
              disabled
              v-model="form.planned_capa_date"
              type="date"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              :placeholder="
                t(
                  'Components.AuditCalendar.TaskCalendar.PleaseSelectCAPAPlanTime'
                )
              "
            />
          </el-form-item>
        </el-col>

        <el-col :span="12">
        <el-form-item
          :label="$t('Components.AuditCalendar.TaskCalendar.CAPAActualTime')"
          prop="name"
        >
            <el-date-picker
              v-model="form.actual_capa_date"
              :style="{width: '100%'}"
              type="date"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              :placeholder="
                t(
                  'Components.AuditCalendar.TaskCalendar.PleaseSelectCAPAActualTime'
                )
              "
              :disabled-date="(date)=>{
                return date.getTime() > Date.now()
              }"
            />
        </el-form-item>
          </el-col>
      </el-row>
    </el-form>
  </Dialog>
</template>
<script setup>
import {
  ref,
  reactive,
  markRaw,
  onMounted,
  defineProps,
  defineEmits,
} from "vue";
import { WarnTriangleFilled } from "@element-plus/icons-vue";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { useI18n } from "vue-i18n";
import {
  request_calendar_projecttask_time,
} from "@/api/apis";
import { ElMessageBox } from "element-plus";
import { ProjectTaskProgress } from "@/consts/enum";

const { t } = useI18n();
const props = defineProps({
  auditedPartyCategorys: {
    type: Array,
    default: () => [],
  },
  editData: {
    type: Object,
    default: () => {},
  },
});

const emits = defineEmits(["handleCloseDialog", "handleSearch"]);
// 标题
const dialogTitle = ref("");
// 表单ref
const formRef = ref();
// 表单数据
const form = reactive({
  planned_audit_time: props.editData
    ? props.editData.planned_audit_starttime &&
      props.editData.planned_audit_endtime
      ? [
          props.editData.planned_audit_starttime,
          props.editData.planned_audit_endtime,
        ]
      : [undefined, undefined]
    : [undefined, undefined],
  actual_audit_time: props.editData
    ? props.editData.actual_audit_starttime &&
      props.editData.actual_audit_endtime
      ? [
          props.editData.actual_audit_starttime,
          props.editData.actual_audit_endtime,
        ]
      : [undefined, undefined]
    : [undefined, undefined],
  planned_capa_date: props.editData
    ? props.editData.planned_capa_date
    : undefined,
  actual_capa_date: props.editData
    ? props.editData.actual_capa_date
    : undefined,
  planned_reporting_date: props.editData
    ? props.editData.planned_reporting_date
    : undefined,
  completed_reporting_date: props.editData
    ? props.editData.completed_reporting_date
    : undefined,
});
onMounted(async () => {
  // dialogTitle.value = t("Components.AuditCalendar.TaskCalendar.ModifyTask");
  dialogTitle.value = "";
});
// 处理提交事件
const handleCommit = async () => {
  ElMessageBox.confirm(
    t("Components.AuditCalendar.TaskCalendar.ConfirmSaveData"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      await saveData();
    })
    .catch(() => {});
};
// 保存数据
const saveData = async () => {
  const trans = {
    project_task_guid: props.editData.guid,
    actual_audit_starttime:
      form.actual_audit_time && form.actual_audit_time.length > 0
        ? form.actual_audit_time[0]
        : undefined,
    actual_audit_endtime:
      form.actual_audit_time && form.actual_audit_time.length > 1
        ? form.actual_audit_time[1]
        : undefined,
    planned_audit_starttime:
      form.planned_audit_time && form.planned_audit_time.length > 0
        ? form.planned_audit_time[0]
        : undefined,
    planned_audit_endtime:
      form.planned_audit_time && form.planned_audit_time.length > 1
        ? form.planned_audit_time[1]
        : undefined,
    actual_capa_date: form.actual_capa_date,
    planned_capa_date: form.planned_capa_date,
    planned_reporting_date: form.planned_reporting_date,
    completed_reporting_date: form.completed_reporting_date,
  };
  await request_calendar_projecttask_time(trans, true);
  emits("handleSearch");
  emits("handleCloseDialog");
};
// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};
</script>
<style scoped lang="scss"></style>
