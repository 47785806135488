<template>
  <el-menu
    :collapse="isCollapse"
    :default-active="routerPath"
    style="
      --el-menu-bg-color: #304155;
      --el-menu-text-color: #bfcbd9;
      --el-menu-active-text-color: #bfcbd9;
    "
  >
    <MenuItem v-for="item in data" :menuItem="item" :key="item.menu_guid" />
  </el-menu>
</template>
<script setup>
import { ref, defineExpose,  watchEffect } from "vue";
import { useRouter } from "vue-router";
import { routes } from "@/router";
import MenuItem from "@/components/Index/Menu/MenuItem.vue";
import { LocalStorageKey, LangName } from "@/consts/frame";
import { useStore } from "@/store/index";
// import { useI18n } from "vue-i18n";

// import Index from "@/views/MainMain.vue";
const store = useStore();
const router = useRouter();
// const { t } = useI18n();
const lang = ref(localStorage.getItem(LocalStorageKey.Lang) || LangName.ZHCN);
// 是否折叠
const isCollapse = ref(false);
// 当前页面的路由
const routerPath = ref();
// 菜单数据
const data = ref([]);

// // 挂载事件
// onMounted(() => {
//   // 这边使用settimeout 是因为 宏任务和微任务的问题，总结就是要等待其他执行完毕之后才能拿到router的路径
//   const fullPath = router.currentRoute.value.fullPath;
// });


const initMenus = () => {
  const menus = [];
  // 先找到根菜单
  const rootMenus = store.userMenus.filter((item) => !item.menu_parent_guid);
  rootMenus.forEach((item) => {
    const menu = {
      menu_guid: item.menu_guid,
      menu_name:
        lang.value === LangName.ZHCN
          ? item.menu_cn_name
          : lang.value === LangName.ENUS
          ? item.menu_en_name
          : item.menu_cn_name,
      menu_path: item.menu_path,
      menu_icon: item.menu_icon,
      children: [],
    };
    recursionMenu(item, menu);

    menus.push(menu);
  });
  data.value = menus;
};

const recursionMenu = (storeMenu, menu) => {
  if (storeMenu.children && storeMenu.children.length > 0) {
    // 存在子菜单
    storeMenu.children.forEach((item) => {
      const tempMenu = {
        menu_guid: item.menu_guid,
        menu_name:
          lang.value === LangName.ZHCN
            ? item.menu_cn_name
            : lang.value === LangName.ENUS
            ? item.menu_en_name
            : item.menu_cn_name,
        menu_path: item.menu_path,
        menu_icon: item.menu_icon,
        children: [],
      };
      recursionMenu(item, tempMenu);
      menu.children.push(tempMenu);
    });
  }
};
// 修改菜单的折叠展开
const changeMenuCollapse = (status) => {
  isCollapse.value = status;
};

watchEffect(() => {
  if (store.userMenus) {
    initMenus();
  }
  routerPath.value = router.currentRoute.value.path;

  const fullPath = router.currentRoute.value.fullPath;
  routes.forEach((item) => {
    if (item.path === fullPath) {
      // 一级找到了
      store.setActiveMenu({
        path: item.path,
        name: item.name,
        // component: shallowRef(item.component),
      });
      return;
    } else {
      if (item.children && item.children.length > 0) {
        // 存在节点数据
        const childrenFind = item.children.filter((item1) => item1.path === fullPath);
        if (childrenFind && childrenFind.length > 0) {
          routerPath.value = childrenFind[0].path;
          store.setActiveMenu({
            path: childrenFind[0].path,
            name: childrenFind[0].name,
            // component: shallowRef(childrenFind[0].component),
          });
        }
      }
    }
  });
});

defineExpose({
  changeMenuCollapse,
});
</script>
<style>
.el-menu .el-scrollbar__view {
  height: calc(100% - 8px);
}
/*滚动条里面轨道*/
.el-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
  background-color: #304155;
}
/*定义滚动条整体的样式*/
.el-menu::-webkit-scrollbar {
  width: 6px;
  background-color: #d6d8dd;
}
/*滚动条的样式*/
.el-menu::-webkit-scrollbar-thumb {
  height: 20px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.2, rgb(125, 126, 128)),
    color-stop(0.4, rgb(97, 98, 99)),
    color-stop(0.8, rgb(125, 126, 128))
  );
}
</style>
<style scoped lang="scss">
.el-menu {
  width: 100%;
  height: 100%;
  padding-bottom: 8px;
  overflow: auto;
  // background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%);
}
</style>
