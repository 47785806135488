<template>
  <div class="login-root">
    <div class="login-root-bg" :style="{left:  left + 'px', top: top + 'px', backgroundSize: backgroundSize+ 'px'}"></div>
    <div class="login-body">
      <ul class="login-ul">
        <li class="li-title">
          <div class="div-title"><span>iDao</span>{{ t("Common.Customer") }}</div>
          <div class="div-version">{{ t("Common.Version") }}</div>
        </li>

        <li class="form-li">
          <el-form :model="form" label-width="0px" :rules="rules" ref="formRef">
            <el-form-item label="" prop="account">
              <el-input
                v-model="form.account"
                :prefix-icon="User"
                :placeholder="t('Components.Login.InputAccount')"
              />
            </el-form-item>
            <el-form-item label="" prop="password">
              <el-input
                v-model="form.password"
                type="password"
                :prefix-icon="Lock"
                :placeholder="t('Components.Login.InputPassword')"
              />
            </el-form-item>
            <el-form-item label="">
              <el-checkbox
                :style="{ color: 'white' }"
                v-model="form.remember"
                :label="t('Components.Login.RememberPassword')"
                size="small"
              />
            </el-form-item>
            <el-form-item label="">
              <el-button
                type="primary"
                :style="{ width: '100%' }"
                @click="handleLogin"
                :loading="loginButtonLoading"
                >{{ t("Components.Login.Login") }}</el-button
              >
            </el-form-item>
          </el-form>
        </li>
      </ul>
    </div>
    <div class="div-footer">
      <el-space>
        <span>{{ t("Common.Company") }} ©{{ new Date().getFullYear() }}</span>
        <span>|</span>
        <span class="beian-span" @click="handleBeian">沪ICP备2024062923号-1</span>
      </el-space>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import md5 from "js-md5";
import Index from "@/views/MainIndex.vue";
import { useStore } from "@/store/index";
import { onMounted, reactive, ref, shallowRef } from "vue";
import { LocalStorageKey, IsLock } from "@/consts/frame";
import { request_login } from "@/api/apis";
import DeviceDetector from "device-detector-js";
import { User, Lock } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const router = useRouter();

const form = reactive({
  account: "",
  password: "",
  remember: false,
});
const formRef = ref();
const loginButtonLoading = ref(false);

// const windowHeight = ref(0)
// const windowWidth = ref(0)
const backgroundSize = ref(0)
const left = ref(0)
const top = ref(0)

onMounted(() => {
  getWindowResize()
  window.addEventListener('resize', getWindowResize)

  const findLocalstorageTitle = localStorage.getItem(LocalStorageKey.Title);
  if (findLocalstorageTitle) {
    window.document.title = findLocalstorageTitle;
  } else {
    window.document.title = t("Common.Customer");
  }

  const isRememberPassword = localStorage.getItem(LocalStorageKey.RememberPassword);
  if (isRememberPassword && isRememberPassword == 1) {
    form.remember = true;
  }
  const account = localStorage.getItem(LocalStorageKey.Account);
  if (account) {
    form.account = window.atob(account);
  }
  const password = localStorage.getItem(LocalStorageKey.Password1);
  if (password) {
    form.password = window.atob(password);
  }
});
// 获取屏幕尺寸
const getWindowResize = function () {
  // windowWidth.value = window.innerWidth
  // windowHeight.value = window.innerHeight
  backgroundSize.value = window.innerWidth / 8
  left.value = window.innerWidth / 25
  top.value = window.innerHeight / 12
}
// 表单校验规则
const rules = ref({
  account: [
    {
      required: true,
      message: t("Components.Login.InputAccount"),
      trigger: "blur",
    },
  ],
  password: [
    {
      required: true,
      message: t("Components.Login.InputPassword"),
      trigger: "blur",
    },
    {
      min: 6,
      message: t("Components.Login.PasswordMin6"),
      trigger: "change",
    },
  ],
});
// 登录
const handleLogin = () => {
  if (!formRef.value) return;
  formRef.value.validate(async (valid) => {
    if (valid) {
      var userAgent = navigator.userAgent.toLowerCase();
      const deviceDetector = new DeviceDetector();
      const device = deviceDetector.parse(userAgent);
      const data = {
        account: form.account,
        password: form.password,
        os: device.os.name + " " + device.os.version,
        browser: device.client.name + " " + device.client.version,
      };
      loginButtonLoading.value = true;
      const result = await request_login(data, () => (loginButtonLoading.value = false));
      localStorage.setItem(LocalStorageKey.IsLock, md5(IsLock.False));
      localStorage.setItem(LocalStorageKey.Token, result);
      localStorage.setItem(LocalStorageKey.Password, md5(form.password));
      localStorage.setItem(LocalStorageKey.Account, window.btoa(form.account));
      if (form.remember) {
        // 记住密码
        localStorage.setItem(LocalStorageKey.Password1, window.btoa(form.password));
        localStorage.setItem(LocalStorageKey.RememberPassword, 1);
      } else {
        // 不记住密码
        localStorage.removeItem(LocalStorageKey.Password1);
        localStorage.removeItem(LocalStorageKey.RememberPassword);
      }
      store.setKeepAliveCache(true);
      router.push("/");
      store.setActivePage({ component: shallowRef(Index) });
    }
  });
};

const handleBeian = ()=>{
  window.open("http://beian.miit.gov.cn/")
}
</script>
<style scoped lang="scss">
.login-root {
  height: 100%;
  width: 100%;
  background-image: url("~@/assets/images/login-bg4.jpg");
  /* 背景图垂直、水平均居中 */
  // background-position: center center;
  /* 背景图不平铺 */
  background-repeat: no-repeat;
  /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
  background-attachment: fixed;
  /* 让背景图基于容器大小伸缩 */
  background-size: cover;
  display: flex;
  flex-direction: column;
  position: relative;
  .login-root-bg {
    display: flex;
    position: absolute;
    background-image: url("~@/assets/images/login-bg3.png");
    width: 100%;
    height: 50%;
    // background-size: 180px;
    background-repeat: no-repeat;
    // background-position: center center;
    opacity: 0.8;
    // left: 80px;
    // top: 80px;
  }
  .login-logo {
    width: 160px;
  }
  .login-body {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .login-ul {
      margin: 0;
      padding: 0;
      list-style: none;
      width: 400px;
      background-color: rgba(3, 68, 126, 0.6);
      height: 360px;
      display: flex;
      margin-right: 100px;
      border-radius: 12px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      flex-direction: column;
      .li-title {
        height: 100px;
        // background-color: antiquewhite;

        color: white;
        width: 100%;
        line-height: 100px;
        display: flex;
        justify-content: center;
        .div-logo {
          width: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 8px;
          .el-image {
            display: flex;
          }
        }
        .div-title {
          font-size: 1.6em;
          font-weight: 600;
          margin-left: 28px;
          span{
            font-family: Arial, Helvetica, sans-serif;
          }
        }
        .div-version {
          font-size: 0.8em;
          margin-top: 0.5em;
          margin-left: 0.5em;
        }
      }
      .form-li {
        padding: 10px 40px;
      }
    }
  }
  .div-footer {
    width: 100%;
    height: 20px;
    font-size: 0.6em;
    color: #dcdcdc;
    display: flex;
    align-items: center;
    line-height: 20px;
    justify-content: center;
    .beian-span{
      cursor: pointer;
    }
  }
}
</style>
