<template>
  <div>
    <!-- 第一条稽查小结数据就是单独处理 -->
    <h2>
      3.
      {{ t("Components.Project.ProjectTask.Report1.ContentSummary.Title") }}
    </h2>
    <!-- 稽查参考标准 -->
    <ItemEdit
      v-if="props.contentSummaryData && props.contentSummaryData.length > 0"
      :projectTaskContentSummary="props.contentSummaryData[0]"
      :projectTaskGuid="props.projectTaskGuid"
      @getContentSummary="getContentSummary"
      :checkCommentGuid="checkCommentGuid"
      :projectTaskProgress="props.projectTaskProgress"
      :commentModuleCount="commentModuleCount"
      :canReview="props.canReview"
    />
  </div>
  <div>
    <!-- 稽查的范围和主要内容，包 -->
    <h2>
      {{
        t("Components.Project.ProjectTask.Report1.ContentSummary.RangeAndMainContent")
      }}：
    </h2>
    <!-- <ItemEdit
      :title="t('Components.Project.ProjectTask.Report1.ContentSummary.CenterOverview')"
      ref="centerOverviewRef"
    /> -->
  </div>

  <div v-if="props.contentSummaryData.length > 1">
    <ItemEdit
      v-for="item in props.contentSummaryData.slice(1)"
      :key="item.guid"
      :projectTaskContentSummary="item"
      :projectTaskGuid="props.projectTaskGuid"
      @getContentSummary="getContentSummary"
      :checkCommentGuid="checkCommentGuid"
      :projectTaskProgress="props.projectTaskProgress"
      :commentModuleCount="commentModuleCount"
      :canReview="props.canReview"
    />
  </div>
</template>
<script setup>
import { onMounted, ref, defineProps, onBeforeUnmount, watchEffect } from "vue";
import ItemEdit from "./_ItemEdit.vue";
import pubsub from "pubsub-js";
import PubsubMessage from "@/consts/pubsubMessage";
import { useI18n } from "vue-i18n";
import { request_project_contentsummary_get2 } from "@/api/apis";

const { t } = useI18n();
const props = defineProps({
  projectTaskGuid: {
    type: String,
    default: () => "",
  },
  projectTaskProgress: {
    type: Number,
    default: () => 0,
  },
  comments: {
    type: Object,
    default: () => undefined,
  },
  commentModuleCount: {
    type: Array,
    default: () => [],
  },
  contentSummaryData: {
    type: Array,
    default: ()=>[]
  },
  canReview: {
    type: Boolean,
    default: ()=> false
  }
});
// 内容小结数组
const contentSummaryData = ref([]);
// 选中的评论
const checkCommentGuid = ref("");

onMounted(() => {
  // getContentSummary();
  pubsub.subscribe(PubsubMessage.ProjectTaskContentSummaryFindParent, (_, data) => {
    checkCommentGuid.value = data.parent_guid;
  });
  pubsub.subscribe(PubsubMessage.ProjectTaskClearCheckComment, () => {
    checkCommentGuid.value = "";
  });
});

onBeforeUnmount(() => {
  pubsub.unsubscribe(PubsubMessage.ProjectTaskContentSummaryFindParent);
  pubsub.unsubscribe(PubsubMessage.ProjectTaskClearCheckComment);
});

// 获取内容小结
const getContentSummary = async () => {
  const result = await request_project_contentsummary_get2(props.projectTaskGuid);
  result.forEach((item) => {
    if (!item.content) {
      item.content = item.audit_summary_default_value;
    }
  });
  contentSummaryData.value = result;
};

watchEffect(() => {
  contentSummaryData.value.forEach((item) => {
    if (props.comments && props.comments) {
      const findComments = props.comments.find(
        (item1) => item1.relate_guid === item.guid
      );
      if (findComments && findComments.comments && findComments.comments.length > 0) {
        item.comments = findComments.comments;
      }
    }
  });
});
</script>
<style scoped lang="scss"></style>
