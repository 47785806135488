<template>
  <!-- <h2>2.{{ t("Components.Project.ProjectTask.Report1.Distribution.Title") }}</h2> -->

  <ul class="title-ul">
    <li class="left-li">
      <h2>
        2.{{ t("Components.Project.ProjectTask.Report1.Distribution.Title") }}
      </h2>
    </li>
    <li class="right-li">
      <!-- 只有非待分配，准备中，稽查实施，报告撰写，完成状态可以进行评论 -->
      <div v-if="computedCommentCount > 0">
        <el-badge
          :value="computedCommentCount"
          type="success"
          class="item"
          v-if="
            props.projectTaskProgress !== ProjectTaskProgress.ToBeAllocated &&
            props.projectTaskProgress !== ProjectTaskProgress.Ready &&
            props.projectTaskProgress !==
              ProjectTaskProgress.AuditImplementation &&
            props.projectTaskProgress !== ProjectTaskProgress.ReportWriting &&
            props.projectTaskProgress !== ProjectTaskProgress.Complete
          "
        >
          <el-badge
            :value="computedNotReadCommentCount"
            v-if="computedNotReadCommentCount > 0"
            class="notread-comment-count"
          >
            <el-button
              type="primary"
              size="small"
              circle
              :icon="ChatDotSquare"
              @click="handleCommentClick"
            ></el-button>
          </el-badge>

          <el-button
            v-else
            type="primary"
            size="small"
            circle
            :icon="ChatDotSquare"
            @click="handleCommentClick"
          ></el-button>
        </el-badge>
      </div>
      <div v-else>
        <el-button
          v-if="
            props.projectTaskProgress !== ProjectTaskProgress.ToBeAllocated &&
            props.projectTaskProgress !== ProjectTaskProgress.Ready &&
            props.projectTaskProgress !==
              ProjectTaskProgress.AuditImplementation &&
            props.projectTaskProgress !== ProjectTaskProgress.ReportWriting &&
            props.projectTaskProgress !== ProjectTaskProgress.Complete
          "
          type="primary"
          size="small"
          circle
          :icon="ChatDotSquare"
          @click="handleCommentClick"
        ></el-button>
      </div>
    </li>
  </ul>

  <el-button
    v-if="computedCanEdit"
    type="primary"
    :style="{ marginBottom: '20px' }"
    @click="handleAdd"
    >{{
      t("Components.Project.ProjectTask.Report1.Distribution.Add")
    }}</el-button
  >
  <div ref="divSorttableBox" class="not-select">
    <el-table
      v-loading="tableLoading"
      :header-cell-style="{ background: '#f8f8f9', color: '#515a6e' }"
      :data="tableData"
      row-key="guid"
      :row-class-name="rowClassName"
    >
      <el-table-column
        prop="sort"
        :label="t('Components.Project.ProjectTask.Report1.Distribution.Sort')"
        width="60"
      >
        <template #default="scope">
          <div :style="{ width: '100%' }" class="can-handle">
            {{ scope.row.sort }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        :label="t('Components.Project.ProjectTask.Report1.Distribution.Name')"
      >
        <template #default="scope">
          <span
            v-if="
              !scope.row.isAdd &&
              (!currentData || currentData.guid !== scope.row.guid)
            "
            >{{ scope.row.name }}</span
          >
          <el-input
            v-else
            v-model="scope.row.name"
            :placeholder="
              t(
                'Components.Project.ProjectTask.Report1.Distribution.PleaseInputName'
              )
            "
          >
            <template #append>
              <el-select
                :placeholder="
                  t(
                    'Components.Project.ProjectTask.Report1.Distribution.PleaseCompanyUser'
                  )
                "
                @change="(value) => handleSelectChange(scope.row, value)"
              >
                <el-option
                  v-for="item in props.users"
                  :key="item.guid"
                  :label="item.name"
                  :value="item.guid"
                ></el-option>
              </el-select>
            </template>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="job"
        :label="t('Components.Project.ProjectTask.Report1.Distribution.Job')"
      >
        <template #default="scope">
          <span
            v-if="
              !scope.row.isAdd &&
              (!currentData || currentData.guid !== scope.row.guid)
            "
            >{{ scope.row.job }}</span
          >
          <el-input
            v-else
            v-model="scope.row.job"
            :placeholder="
              t(
                'Components.Project.ProjectTask.Report1.Distribution.PleaseInputJob'
              )
            "
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="company"
        :label="
          t('Components.Project.ProjectTask.Report1.Distribution.Company')
        "
      >
        <template #default="scope">
          <span
            v-if="
              !scope.row.isAdd &&
              (!currentData || currentData.guid !== scope.row.guid)
            "
            >{{ scope.row.company }}</span
          >
          <el-input
            v-else
            v-model="scope.row.company"
            :placeholder="
              t(
                'Components.Project.ProjectTask.Report1.Distribution.PleaseInputCompany'
              )
            "
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="operate"
        :label="
          t('Components.Project.ProjectTask.Report1.Distribution.Operate')
        "
        width="180"
        v-if="computedCanEdit"
      >
        <template #default="scope">
          <el-space
            v-if="
              !scope.row.isAdd &&
              (!currentData || currentData.guid !== scope.row.guid)
            "
          >
            <el-button
              type="primary"
              size="small"
              :icon="Edit"
              @click="handleEdit(scope.row)"
              >{{
                t("Components.Project.ProjectTask.Report1.Distribution.Edit")
              }}</el-button
            >
            <el-button
              type="danger"
              size="small"
              :icon="Delete"
              @click="handleDelete(scope.row)"
              >{{
                t("Components.Project.ProjectTask.Report1.Distribution.Delete")
              }}</el-button
            >
          </el-space>
          <el-space v-else>
            <el-button
              type="primary"
              size="small"
              circle
              :icon="Check"
              @click="handleOk(scope.row)"
            ></el-button>
            <el-button
              type="danger"
              size="small"
              circle
              :icon="Close"
              @click="handleCancel(scope.row)"
            ></el-button>
          </el-space>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { ref, onMounted, watchEffect, computed, defineProps } from "vue";
import Sortable from "sortablejs";
import {
  Edit,
  Delete,
  Check,
  Close,
  ChatDotSquare,
} from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import { nanoid } from "nanoid";
import {
  request_projecttask_distribution_add,
  request_projecttask_distribution_modify,
  request_projecttask_distribution_delete,
  request_projecttask_distribution_sort,
  request_job_page,
} from "@/api/apis";
import { ElMessage, ElMessageBox } from "element-plus";
import { useStore } from "@/store/index";
import { SystemParamCode } from "@/consts/systemParam";
import { ProjectTaskProgress, ProjectTaskCommentModule } from "@/consts/enum";
import pubsub from "pubsub-js";
import PubsubMessage from "@/consts/pubsubMessage";

const store = useStore();
const { t } = useI18n();
const props = defineProps({
  data: {
    type: Object,
    default: () => undefined,
  },
  projectTaskGuid: {
    type: String,
    default: () => "",
  },
  projectTaskProgress: {
    type: Number,
    default: () => 0,
  },
  users: {
    type: Array,
    default: () => [],
  },
  commentModuleCount: {
    type: Array,
    default: () => [],
  },
  canReview: {
    type: Boolean,
    default: ()=> false
  }
});
const tableLoading = ref(false);
const divSorttableBox = ref();
// 用户数据
const tableData = ref([]);
const currentData = ref(undefined);
const jobsData = ref([]);
const companyName = ref("");

// 是否可以编辑
const computedCanEdit = computed(() => {
  return (
    props.projectTaskProgress === ProjectTaskProgress.ReportWriting ||
    props.projectTaskProgress === ProjectTaskProgress.Rewrite
  );
});
// 计算模块评论数量
const computedCommentCount = computed(() => {
  if (props.commentModuleCount && props.commentModuleCount.length > 0) {
    const findCommentModuleCountData = props.commentModuleCount.find(
      (item) => item.module === ProjectTaskCommentModule.Distribution
    );
    if (findCommentModuleCountData) {
      return findCommentModuleCountData.count;
    }
  }
  return 0;
});
// 计算模块评论数量
const computedNotReadCommentCount = computed(() => {
  if (props.commentModuleCount && props.commentModuleCount.length > 0) {
    const findCommentModuleCountData = props.commentModuleCount.find(
      (item) => item.module === ProjectTaskCommentModule.Distribution
    );
    if (findCommentModuleCountData) {
      // return findCommentModuleCountData.not_read_count;
      
      if(props.canReview) return findCommentModuleCountData.not_read_count_for_approver
      else  return findCommentModuleCountData.not_read_count;
    }
  }
  return 0;
});
onMounted(() => {
  initSorttable();
  // getDistributions();
  getAllJob();
});
// 处理行样式
const rowClassName = () => {
  const findIsAdd = tableData.value.find((item) => item.isAdd);
  if (findIsAdd) {
    // 找到添加的，不允许移动
    return "cc-table-row no-sort";
  } else {
    return "cc-table-row";
  }
};
// 初始化排序表格
const initSorttable = () => {
  const el = divSorttableBox.value.querySelector(
    ".el-table__body-wrapper tbody"
  );
  Sortable.create(el, {
    animation: 150, // 动画
    handle: ".can-handle",
    onEnd: async (evt) => {
      tableLoading.value = true;
      const curRow = tableData.value.splice(evt.oldIndex, 1)[0];
      tableData.value.splice(evt.newIndex, 0, curRow);
      let sort = 1;
      tableData.value.forEach((item) => {
        if (!item.isAdd) {
          item.sort = sort;
          sort++;
        }
      });

      // 需要更新
      await request_projecttask_distribution_sort(
        props.projectTaskGuid,
        { distributions: tableData.value },
        () => (tableLoading.value = false)
      );
    },
    filter: ".no-sort", // 过滤器，不需要进行拖动的元素
    sort: true,
  });
};
// 添加
const handleAdd = () => {
  tableData.value.push({
    guid: nanoid(),
    isAdd: true,
  });
};
// 完成
const handleOk = async (row) => {
  if (!row.name) {
    ElMessage.warning(
      t("Components.Project.ProjectTask.Report1.Distribution.PleaseInputName")
    );
    return;
  }
  if (!row.job) {
    ElMessage.warning(
      t("Components.Project.ProjectTask.Report1.Distribution.PleaseInputJob")
    );
    return;
  }
  if (!row.company) {
    ElMessage.warning(
      t(
        "Components.Project.ProjectTask.Report1.Distribution.PleaseInputCompany"
      )
    );
    return;
  }
  const trans = {
    name: row.name,
    job: row.job,
    guid: row.guid,
    company: row.company,
    sort: row.sort,
  };
  if (!row.isAdd) {
    tableLoading.value = true;
    // 修改
    await request_projecttask_distribution_modify(
      props.projectTaskGuid,
      trans,
      true,
      () => (tableLoading.value = false)
    );
    currentData.value = undefined;
  } else {
    const aa = tableData.value.filter((item) => !item.isAdd);
    trans.sort = aa ? aa.length + 1 : 1;
    tableLoading.value = true;
    // 添加
    const result = await request_projecttask_distribution_add(
      props.projectTaskGuid,
      trans,
      true,
      () => (tableLoading.value = false)
    );
    row.sort = trans.sort;
    row.guid = result;
    Reflect.deleteProperty(row, "isAdd");

    // 需要对其重新排序，需要将新增的放到最后
    const addData = tableData.value.filter((item) => item.isAdd);
    const notAddData = tableData.value.filter((item) => !item.isAdd);
    tableData.value = [
      ...notAddData.sort((a, b) => a.sort - b.sort),
      ...addData,
    ];
  }
};

// 处理取消
const handleCancel = (row) => {
  if (row.isAdd) {
    // 删除添加的数据
    tableData.value.splice(
      tableData.value.findIndex((item) => item.guid === row.guid),
      1
    );
  } else {
    // 取消编辑中的数据，需要复原
    if (currentData.value) {
      row.name = currentData.value.name;
      row.job = currentData.value.job;
      row.company = currentData.value.company;
      row.sort = currentData.value.sort;
      currentData.value = undefined;
    }
  }
};

// 处理编辑
const handleEdit = (row) => {
  // 先判断是否有值
  if (currentData.value) {
    // 如果有值，需要还原之前的数据
    const findData = tableData.value.find(
      (item) => item.guid === currentData.value.guid
    );
    if (findData) {
      findData.name = currentData.value.name;
      findData.job = currentData.value.job;
      findData.company = currentData.value.company;
    }
  }
  currentData.value = {
    name: row.name,
    job: row.job,
    guid: row.guid,
    company: row.company,
    sort: row.sort,
  };
};
// 处理删除
const handleDelete = (row) => {
  ElMessageBox.confirm(
    t(
      "Components.Project.ProjectTask.Report1.Distribution.ConfirmDeleteDistribution"
    ),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
    }
  )
    .then(async () => {
      const trans = {
        guid: row.guid,
      };
      tableLoading.value = true;
      await request_projecttask_distribution_delete(
        props.projectTaskGuid,
        trans,
        true,
        () => (tableLoading.value = false)
      );
      // 删除添加的数据
      tableData.value.splice(
        tableData.value.findIndex((item) => item.guid === row.guid),
        1
      );
      if (tableData.value.length > 0) {
        // 重新排序
        const addData = tableData.value.filter((item) => item.isAdd);
        const notAddData = tableData.value.filter((item) => !item.isAdd);
        let index = 1;
        notAddData.forEach((item) => {
          item.sort = index;
          index++;
        });
        tableData.value = [...notAddData, ...addData];
        await request_projecttask_distribution_sort(props.projectTaskGuid, {
          distributions: tableData.value,
        });
      }
    })
    .catch(() => {});
};
// 获取所有岗位
const getAllJob = async () => {
  const trans = {
    dataflag: true,
    pagesize: -1,
  };
  const result = await request_job_page(trans);
  jobsData.value = result.rows;
};

const handleSelectChange = (row, userGuid) => {
  // 找到这个用户
  const findUser = props.users.find((item) => item.guid === userGuid);
  if (findUser) {
    if (findUser.jobs && findUser.jobs.length > 0) {
      // 存在岗位数据
      const findJob = jobsData.value.find(
        (item) => item.guid === findUser.jobs[0]
      );
      if (findJob) {
        row.job = findJob.name;
      } else {
        row.job = "";
      }
    } else {
      row.job = "";
    }
    row.name = findUser.name;
    row.company = companyName.value;
  } else {
    row.name = "";
    row.company = "";
    row.job = "";
  }
};
// 评论点击
const handleCommentClick = () => {
  const data = {
    projectTaskProgress: props.projectTaskProgress,
    module: ProjectTaskCommentModule.Distribution,
    title: t("Components.Project.ProjectTask.Report1.Distribution.Title"),
  };
  pubsub.publish(PubsubMessage.ProjectTaskReportComment, data);
};
// 拆分
watchEffect(() => {
  if (props.data) {
    tableData.value = props.data.distributions;
  }
});

watchEffect(() => {
  if (store.userInfo) {
    if (store.userInfo.system_params) {
      const findCompany = store.userInfo.system_params.find(
        (item) => item.code === SystemParamCode.CompanyName
      );
      if (findCompany) {
        companyName.value = findCompany.value;
      }
    }
  }
});
</script>
<style scoped lang="scss">
.notread-comment-count {
  ::v-deep .el-badge__content {
    margin-top: 20px;
    font-size: 0.8em;
  }
}

.title-ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  .left-li {
    flex: 1;
  }
  .right-li {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
