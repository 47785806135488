<template>
  <Dialog
    width="60%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
    top="6vh"
  >
    <el-form :model="form" label-width="126px" :rules="rules" ref="formRef" size="large">
      <el-row>
        <el-col :span="12">
          <!-- 委托方 -->
          <el-form-item
            :label="$t('Components.Project.ProjectList.Client')"
            prop="client_guid"
          >
            <el-select
              :style="{ width: '100%' }"
              v-model="form.client_guid"
              filterable
              :disabled="props.isView"
              clearable
              :placeholder="t('Components.Project.ProjectList.PleaseSelectClient')"
              @change="handleClientChange"
            >
              <el-option
                v-for="item in props.clients"
                :key="item.guid"
                :label="item.name"
                :value="item.guid"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 委托方信息 -->
          <el-form-item :label="$t('Components.Project.ProjectList.ClientIno')">
            <el-input
              disabled
              v-model="clientInfo"
              :placeholder="t('Components.Project.ProjectList.PleaseSelectClient')"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <!-- 服务类型 -->
          <el-form-item
            :label="$t('Components.Project.ProjectList.ServiceType')"
            prop="service_type"
          >
            <el-select
              :style="{ width: '100%' }"
              v-model="form.service_type"
              filterable
              clearable
              :disabled="props.isView"
              :placeholder="t('Components.Project.ProjectList.PleaseSelectServiceType')"
            >
              <el-option
                v-for="item in props.serviceTypes"
                :key="item.guid"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 项目编号 -->
          <el-form-item :label="$t('Components.Project.ProjectList.ProjectNumber')">
            <el-input
              disabled
              v-model="form.project_number"
              :placeholder="t('Components.Project.ProjectList.AutoGenerateProjectNumber')"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div v-if="computedIsAuditProject">
        <el-row>
          <el-col :span="12">
            <!-- 稽查类型 -->
            <el-form-item
              :label="$t('Components.Project.ProjectList.AuditType')"
              prop="audit_type"
            >
              <el-select
                :style="{ width: '100%' }"
                v-model="form.audit_type"
                filterable
                clearable
              :disabled="props.isView"
                :placeholder="t('Components.Project.ProjectList.PleaseSelectAuditType')"
              >
                <el-option
                  v-for="item in props.auditTypes"
                  :key="item.guid"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="12">
            <!-- 方案编号 -->
            <el-form-item
              :label="$t('Components.Project.ProjectList.PlanNumber')"
              prop="plan_number"
            >
              <el-input
                v-model="form.plan_number"
              :disabled="props.isView"
                :placeholder="t('Components.Project.ProjectList.PleaseInputPlanNumber')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <!-- 申办者 -->
            <el-form-item
              v-if="computedIsAuditProject"
              :label="$t('Components.Project.ProjectList.Sponsor')"
              prop="sponsor"
            >
              <el-input
                v-model="form.sponsor"
              :disabled="props.isView"
                :placeholder="t('Components.Project.ProjectList.PleaseInputSponsor')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              v-if="computedIsAuditProject"
              :label="$t('Components.Project.ProjectList.SponsorType')"
              prop="sponsor_type"
            >
            <el-select
                :style="{ width: '100%' }"
                v-model="form.sponsor_type"
                filterable
                clearable
              :disabled="props.isView"
                :placeholder="
                  t('Components.Project.ProjectList.PleaseSelectSponsorType')
                "
              >
                <el-option
                  v-for="item in props.sponsorTypes"
                  :key="item.guid"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row> </el-row>
        <el-row>
          <el-col :span="12">
            <!-- 适应症 -->
            <el-form-item
              :label="$t('Components.Project.ProjectList.Indications')"
              prop="indications"
            >
              <el-select
                :style="{ width: '100%' }"
                v-model="form.indications"
                filterable
                clearable
              :disabled="props.isView"
                allow-create
                :placeholder="t('Components.Project.ProjectList.PleaseSelectIndications')"
              >
                <el-option
                  v-for="(item, index) in props.projectIndications"
                  :key="index"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- 治疗领域 -->
            <el-form-item
              :label="$t('Components.Project.ProjectList.TherapeuticArea')"
              prop="therapeutic_area"
            >
              <el-select
                :style="{ width: '100%' }"
                v-model="form.therapeutic_area"
                filterable
              :disabled="props.isView"
                clearable
                :placeholder="
                  t('Components.Project.ProjectList.PleaseSelectTherapeuticArea')
                "
              >
                <el-option
                  v-for="item in props.therapeuticAreas"
                  :key="item.guid"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <!-- 研究产品 -->
            <el-form-item
              :label="$t('Components.Project.ProjectList.ResearchProduct')"
              prop="research_products"
            >
              <el-input
                v-model="form.research_products"
              :disabled="props.isView"
                :placeholder="
                  t('Components.Project.ProjectList.PleaseInputResearchProduct')
                "
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <!-- 研究产品类型 -->
            <el-form-item
              :label="$t('Components.Project.ProjectList.ResearchProductType')"
              prop="research_products_type"
            >
              <el-select
                :style="{ width: '100%' }"
                v-model="form.research_products_type"
                filterable
                clearable
              :disabled="props.isView"
                :placeholder="
                  t('Components.Project.ProjectList.PleaseSelectResearchProductType')
                "
              >
                <el-option
                  v-for="item in props.researchProductTypes"
                  :key="item.guid"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <!-- 试验进展阶段 -->
            <el-form-item
              :label="$t('Components.Project.ProjectList.TrialProgressStage')"
              prop="trial_progress_stage"
            >
              <el-select
                :style="{ width: '100%' }"
                v-model="form.trial_progress_stage"
                filterable
                clearable
              :disabled="props.isView"
                :placeholder="
                  t('Components.Project.ProjectList.PleaseSelectTrialProgressStage')
                "
              >
                <el-option
                  v-for="item in props.trailProgressStages"
                  :key="item.guid"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="12">
            <!-- 试验分期 -->
            <el-form-item
              :label="$t('Components.Project.ProjectList.TrialStaging')"
              prop="trial_staging"
            >
              <el-select
                :style="{ width: '100%' }"
                v-model="form.trial_staging"
                filterable
                clearable
              :disabled="props.isView"
                :placeholder="
                  t('Components.Project.ProjectList.PleaseSelectTrialStaging')
                "
              >
                <el-option
                  v-for="item in props.trailStagings"
                  :key="item.guid"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <!-- 方案名称 -->
        <el-form-item
          :label="$t('Components.Project.ProjectList.ScheMenuName')"
          prop="scheme_name"
        >
          <el-input
            v-model="form.scheme_name"
              :disabled="props.isView"
            :placeholder="t('Components.Project.ProjectList.PleaseInputScheMeName')"
          ></el-input>
        </el-form-item>

        <el-row>
          <el-col :span="12">
            <!-- cro名称 -->
            <el-form-item
              :label="$t('Components.Project.ProjectList.CROName')"
              prop="cro_name"
            >
            <el-select
                :style="{ width: '100%' }"
                v-model="form.cro_name"
                filterable
                clearable
              :disabled="props.isView"
                allow-create
                :placeholder="t('Components.Project.ProjectList.PleaseSelectInputCroName')"
              >
                <el-option
                  v-for="(item, index) in props.projectCROs"
                  :key="index"
                  :label="item"
                  :value="item"
                />
              </el-select></el-form-item
          >
        </el-col>
          <el-col :span="12">
            <!-- 计划稽查次数 -->
            <el-form-item
              :label="$t('Components.Project.ProjectList.PlanAuditsCount')"
              prop="plan_audits_count"
            >
              <el-input-number
                :style="{ width: '100%' }"
                v-model="form.plan_audits_count"
              :disabled="props.isView"
                :placeholder="
                  $t('Components.Project.ProjectList.PleaseInputPlanAuditsCount')
                "
                :min="0"
              /> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <!-- 稽查目的 -->
            <el-form-item
              :label="$t('Components.Project.ProjectList.AuditPurpose')"
              prop="audit_purpose"
            >
              <el-input
                v-model="form.audit_purpose"
                type="textarea"
                maxlength="999"
              :disabled="props.isView"
                show-word-limit
                :placeholder="t('Components.Project.ProjectList.PleaseInputAuditPurpose')"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <!-- 稽查范围 -->
            <el-form-item
              :label="$t('Components.Project.ProjectList.AuditScope')"
              prop="audit_scope"
            >
              <el-input
                v-model="form.audit_scope"
                type="textarea"
                maxlength="999"
              :disabled="props.isView"
                show-word-limit
                :placeholder="t('Components.Project.ProjectList.PleaseInputAuditScope')"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <!-- 稽查依据 -->
            <el-form-item
              :label="$t('Components.Project.ProjectList.AuditBasis')"
              prop="audit_basis"
            >
              <el-input
                v-model="form.audit_basis"
                type="textarea"
                maxlength="999"
              :disabled="props.isView"
                show-word-limit
                :placeholder="t('Components.Project.ProjectList.PleaseInputAuditBasis')"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item
              class="special-line"
              :label="$t('Components.Project.ProjectList.IsCAPA')"
              prop="is_capa"
            >
              <el-switch 
              :disabled="props.isView" v-model="form.is_capa" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <!-- 合同约定的报告交付时间 -->
            <el-form-item
              class="special-line"
              :label="$t('Components.Project.ProjectList.ContractedReportDeliveryTime')"
              prop="contracted_report_delivery_time"
            >
              <el-input 
              :disabled="props.isView" v-model="form.contracted_report_delivery_time">
                <template #append>{{
                  t("Components.Project.ProjectList.WorkDay")
                }}</template>
              </el-input>
            </el-form-item></el-col
          >
          <el-col :span="12">
            <!-- 合同约定的CAPA交付时间 -->
            <el-form-item
              class="special-line"
              :label="$t('Components.Project.ProjectList.ContractedCapaDeliveryTime')"
              prop="contracted_capa_delivery_time"
            >
              <el-input 
              :disabled="props.isView" v-model="form.contracted_capa_delivery_time">
                <template #append>{{ t("Components.Project.ProjectList.Day") }}</template>
              </el-input>
            </el-form-item></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="12">
            <!-- 项目负责人 -->
            <el-form-item
              :label="$t('Components.Project.ProjectList.ProjectManager')"
              prop="project_manager"
            >
              <el-select
                :style="{ width: '100%' }"
                v-model="form.project_manager"
                filterable
                clearable
              :disabled="props.isView"
                :placeholder="
                  t('Components.Project.ProjectList.PleaseSelectProjectManager')
                "
              >
                <el-option
                  v-for="item in props.users"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="12">
            <!-- 项目协助人 -->
            <el-form-item
              :label="$t('Components.Project.ProjectList.PorjectAssistance')"
              prop="project_assistance"
            >
              <el-select
                :style="{ width: '100%' }"
                v-model="form.project_assistance"
                filterable
              :disabled="props.isView"
                clearable
                :placeholder="
                  t('Components.Project.ProjectList.PleaseSelectProjectAssistance')
                "
              >
                <el-option
                  v-for="item in props.users"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <!-- 委托方稽查要求 -->
        <el-form-item
          :label="$t('Components.Project.ProjectList.AuditRequirements')"
          prop="audit_requirements"
        >
          <el-input
            v-model="form.audit_requirements"
            type="textarea"
            maxlength="999"
              :disabled="props.isView"
            show-word-limit
            :placeholder="auditRequirementPresetText"
          ></el-input>
        </el-form-item>
      </div>

      <div v-if="!computedIsAuditProject">
        <!-- 服务人员 -->
        <el-form-item
          :label="$t('Components.Project.ProjectTask.ServiceUser')"
          prop="service_user_guids"
          :disabled="props.isView"
          v-if="!computedIsAuditProject"
        >
          <el-select
            v-model="form.service_user_guids"
            filterable
            multiple
            :style="{ width: '50%' }"
              :disabled="props.isView"
            clearable
            :placeholder="t('Components.Project.ProjectTask.PleaseSelectServiceUser')"
          >
            <el-option
              v-for="item in props.users"
              :key="item.guid"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <!-- 服务内容 -->
        <el-form-item
          v-if="!computedIsAuditProject"
          prop="service_content"
          :label="$t('Components.Project.ProjectTask.ServiceContent')"
        >
          <el-input
            :disabled="props.isView"
            v-model="form.service_content"
            type="textarea"
            show-word-limit
            :placeholder="t('Components.Project.ProjectTask.PleaseInputServiceContent')"
          ></el-input>
        </el-form-item>
        <!-- 服务时间 -->
        <el-form-item
          v-if="!computedIsAuditProject"
          prop="service_time"
          :label="$t('Components.Project.ProjectTask.ServiceTime')"
        >
          <el-date-picker
            :disabled="props.isView"
            :style="{ width: '50%' }"
            v-model="form.service_time"
            type="datetimerange"
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            range-separator=" - "
            :start-placeholder="
              t('Components.Project.ProjectTask.PleaseSelectServiceStartTime')
            "
            :end-placeholder="
              t('Components.Project.ProjectTask.PleaseSelectServiceEndTime')
            "
          />
        </el-form-item>
      </div>
        <el-form-item
          prop="remark"
          :label="$t('Components.Project.ProjectTask.Remark')"
        >
          <el-input
            :disabled="props.isView"
            v-model="form.remark"
            type="textarea"
            show-word-limit
            :placeholder="t('Components.Project.ProjectTask.PleaseInputRemark')"
          ></el-input>
        </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup>
import {
  ref,
  reactive,
  markRaw,
  onMounted,
  defineProps,
  defineEmits,
  computed,
  watchEffect,
} from "vue";
import { WarnTriangleFilled } from "@element-plus/icons-vue";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { useI18n } from "vue-i18n";
import { request_project_add, request_project_modify } from "@/api/apis";
import { ElMessageBox } from "element-plus";
import { SystemParamCode } from "@/consts/systemParam";
import { useStore } from "@/store/index";
import {
  ProjectProgress,
} from "@/consts/enum";

const store = useStore();
const { t } = useI18n();
const props = defineProps({
  editData: {
    type: Object,
    default: () => {},
  },
  // 委托方
  clients: {
    type: Array,
    default: () => [],
  },
  // 稽查类型
  auditTypes: {
    type: Array,
    default: () => [],
  },
  // 试验分期
  trailStagings: {
    type: Array,
    default: () => [],
  },
  // 试验进展阶段
  trailProgressStages: {
    type: Array,
    default: () => [],
  },
  // 研究产品类型
  researchProductTypes: {
    type: Array,
    default: () => [],
  },
  // 申办者类型
  sponsorTypes: {
    type: Array,
    default: () => [],
  },
  // 服务类型
  serviceTypes: {
    type: Array,
    default: () => [],
  },
  // 治疗领域
  therapeuticAreas: {
    type: Array,
    default: () => [],
  },
  // 用户
  users: {
    type: Array,
    default: () => [],
  },
  // 预置委托方要求
  auditRequirementPresetText: {
    type: String,
    default: () => "",
  },
  // 所有适应症
  projectIndications: {
    type: Array,
    default: () => [],
  },
  // 所有申办者
  projectSponsors: {
    type: Array,
    default: () => [],
  },
  // 所有CRO
  projectCROs: {
    type: Array,
    default: () => [],
  },
  isView:{
    type: Boolean,
    default: ()=> false
  }
});
const emits = defineEmits([
  "handleCloseDialog",
  "getProjectlistPage",
  "getAllProjectAssociations",
]);
// 标题
const dialogTitle = ref("");
// 委托方信息
const clientInfo = ref("");
// 表单ref
const formRef = ref();
// 表单数据
const form = reactive({
  sponsor: props.editData.sponsor, // 申办者
  client_guid: props.editData.client_guid, // 委托方guid
  audit_type: props.editData.audit_type, // 稽查类型
  plan_number: props.editData.plan_number, // 方案编号
  project_number: props.editData.project_number, // 项目编号
  therapeutic_area: props.editData.therapeutic_area, // 治疗领域
  indications: props.editData.indications, // 适应症
  research_products: props.editData.research_products, // 研究产品
  trial_staging: props.editData.trial_staging, // 试验分期
  scheme_name: props.editData.scheme_name, // 方案名称
  trial_progress_stage: props.editData.trial_progress_stage, // 试验进展阶段
  cro_name: props.editData.cro_name, // cro名称
  plan_audits_count: props.editData.plan_audits_count ?? 1, // 计划稽查次数
  audit_requirements: props.editData.audit_requirements
    ? props.editData.audit_requirements
    : props.auditRequirementPresetText, // 委托方稽查要求
  contracted_report_delivery_time: props.editData.contracted_report_delivery_time, // 合同约定的报告交付时间
  contracted_capa_delivery_time: props.editData.contracted_capa_delivery_time, // 合同约定的CAPA交付时间
  audit_purpose: props.editData.audit_purpose, // 稽查目的
  audit_scope: props.editData.audit_scope, // 稽查范围
  audit_basis: props.editData.audit_basis, // 稽查依据
  is_capa: props.editData.is_capa, // 是否capa
  project_manager: props.editData.project_manager, // 项目负责人
  project_assistance: props.editData.project_assistance, // 项目协助人
  service_type: props.editData.service_type, // 服务类型
  service_user_guids: props.editData ? props.editData.service_user_guids : undefined,
  service_time: props.editData ? props.editData.service_time : undefined,
  service_content: props.editData ? props.editData.service_content : "",
  remark: props.editData ? props.editData.remark : "",
  sponsor_type: props.editData? props.editData.sponsor_type: undefined,
  research_products_type: props.editData? props.editData.research_products_type: undefined
});
// 表单校验规则
const rules = ref({
  client_guid: [
    {
      required: true,
      message: t("Components.Project.ProjectList.PleaseSelectClient"),
      trigger: "change",
    },
  ],
  service_type: [
    {
      required: true,
      message: t("Components.Project.ProjectList.PleaseSelectServiceType"),
      trigger: "change",
    },
  ],
  audit_type: [
    {
      required: true,
      message: t("Components.Project.ProjectList.PleaseSelectAuditType"),
      trigger: "change",
    },
  ],
  plan_number: [
    {
      required: true,
      message: t("Components.Project.ProjectList.PleaseInputPlanNumber"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.Project.ProjectList.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  project_number: [
    {
      required: true,
      message: t("Components.Project.ProjectList.PleaseInputProjectNumber"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.Project.ProjectList.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  therapeutic_area: [
    {
      required: true,
      message: t("Components.Project.ProjectList.PleaseInputTherapeuticArea"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.Project.ProjectList.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  indications: [
    {
      required: true,
      message: t("Components.Project.ProjectList.PleaseInputIndications"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.Project.ProjectList.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  research_products: [
    {
      required: true,
      message: t("Components.Project.ProjectList.PleaseInputResearchProduct"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.Project.ProjectList.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  research_products_type:[
    {
      required: true,
      message: t("Components.Project.ProjectList.PleaseSelectResearchProductType"),
      trigger: "change",
    },
  ],
  sponsor_type:[
    {
      required: true,
      message: t("Components.Project.ProjectList.PleaseSelectSponsorType"),
      trigger: "change",
    },
  ],
  trial_staging: [
    {
      required: true,
      message: t("Components.Project.ProjectList.PleaseSelectTrialStaging"),
      trigger: "change",
    },
  ],
  scheme_name: [
    {
      required: true,
      message: t("Components.Project.ProjectList.PleaseInputScheMeName"),
      trigger: "blur",
    },
    {
      max: 999,
      message: t("Components.Project.ProjectList.ValidateMaxLength999"),
      trigger: "change",
    },
  ],
  trial_progress_stage: [
    {
      required: true,
      message: t("Components.Project.ProjectList.PleaseSelectTrialProgressStage"),
      trigger: "change",
    },
  ],
  cro_name: [
    {
      max: 100,
      message: t("Components.Project.ProjectList.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  plan_audits_count: [
    {
      required: true,
      message: t("Components.Project.ProjectList.PleaseInputActualAuditsCount"),
      trigger: "blur",
    },
  ],
  audit_purpose: [
    {
      required: true,
      message: t("Components.Project.ProjectList.PleaseInputAuditPurpose"),
      trigger: "blur",
    },
    {
      max: 999,
      message: t("Components.Project.ProjectList.ValidateMaxLength999"),
      trigger: "change",
    },
  ],
  audit_scope: [
    {
      required: true,
      message: t("Components.Project.ProjectList.PleaseInputAuditScope"),
      trigger: "blur",
    },
    {
      max: 999,
      message: t("Components.Project.ProjectList.ValidateMaxLength999"),
      trigger: "change",
    },
  ],
  audit_basis: [
    // {
    //   required: true,
    //   message: t("Components.Project.ProjectList.PleaseInputAuditBasis"),
    //   trigger: "blur",
    // },
    {
      max: 999,
      message: t("Components.Project.ProjectList.ValidateMaxLength999"),
      trigger: "change",
    },
  ],
  service_user_guids: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.PleaseSelectServiceUser"),
      trigger: "change",
    },
  ],
  service_content: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.PleaseInputServiceContent"),
      trigger: "change",
    },
  ],
  service_time: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.PleaseSelectServiceTime"),
      trigger: "change",
    },
  ],
});

const canEditComputed = computed(()=>{
  if(props.editData.progress === ProjectProgress.Complete)
    return false
  return true
})
// 稽查服务类型 系统参数
const auditServiceTypeSystemParam = ref(undefined);
onMounted(async () => {
  if (props.editData && props.editData.guid) {
    // 代表修改
    dialogTitle.value = t(
      "Components.Project.ProjectList.DialogProjectListTitleMaintance"
    );
    handleClientChange(props.editData.client_guid);
  } else {
    dialogTitle.value = t("Components.Project.ProjectList.DialogProjectListTitleAdd");
  }
});
// 判断项目是否不是稽查类型
const computedIsAuditProject = computed(() => {
  if (form.service_type) {
    if (auditServiceTypeSystemParam.value.value === form.service_type) {
      // 服务类型是稽查
      return true;
    } else {
      // 非稽查
      return false;
    }
  } else {
    return true;
  }
});
// 处理提交事件
const handleCommit = async () => {
  if (!formRef.value) return;
  await formRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(
        t("Components.Project.ProjectList.ConfirmSaveProjectList"),
        t("Common.ElMessageBox.OperateConfirm"),
        {
          confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
          cancelButtonText: t("Common.ElMessageBox.CancelText"),
          type: "warning",
          icon: markRaw(WarnTriangleFilled),
        }
      )
        .then(async () => {
          await saveProject();
        })
        .catch(() => {});
    }
  });
};
// 保存项目数据
const saveProject = async () => {
  const trans = {
    sponsor: form.sponsor,
    client_guid: form.client_guid,
    audit_type: form.audit_type,
    plan_number: form.plan_number,
    project_number: form.project_number,
    therapeutic_area: form.therapeutic_area,
    indications: form.indications,
    research_products: form.research_products,
    trial_staging: form.trial_staging,
    scheme_name: form.scheme_name,
    trial_progress_stage: form.trial_progress_stage,
    cro_name: form.cro_name,
    plan_audits_count: form.plan_audits_count,
    audit_requirements: form.audit_requirements,
    contracted_report_delivery_time: form.contracted_report_delivery_time,
    contracted_capa_delivery_time: form.contracted_capa_delivery_time,
    audit_purpose: form.audit_purpose,
    audit_scope: form.audit_scope,
    audit_basis: form.audit_basis,
    is_capa: form.is_capa,
    project_manager: form.project_manager,
    project_assistance: form.project_assistance,
    service_type: form.service_type,
    service_user_guids: form.service_user_guids,
    service_content: form.service_content,
    service_start_time:
      form.service_time && form.service_time.length > 0
        ? form.service_time[0]
        : undefined,
    service_end_time:
      form.service_time && form.service_time.length > 1
        ? form.service_time[1]
        : undefined,
    remark: form.remark,
    sponsor_type: form.sponsor_type,
    research_products_type: form.research_products_type
  };
  if (props.editData && props.editData.guid) {
    // 修改
    await request_project_modify(props.editData.guid, trans);
  } else {
    // 新增
    await request_project_add(trans);
  }
  emits("getProjectlistPage");
  emits("handleCloseDialog");
  emits("getAllProjectAssociations");
};
// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};
// 处理委托方选择改变事件
const handleClientChange = (value) => {
  const findClient = props.clients.find((item) => item.guid === value);
  if (findClient) {
    clientInfo.value =
      findClient.category_name + `(${findClient.category}) / ${findClient.sort}`;
    if(!form.sponsor)
      form.sponsor = findClient.name
  } else {
    clientInfo.value = "";
  }
};

// 监听字典数据
watchEffect(() => {
  if (store.userInfo) {
    if (store.userInfo.system_params && store.userInfo.system_params.length > 0) {
      // 服务类型
      const findServiceType = store.userInfo.system_params.find(
        (item) => item.code === SystemParamCode.AuditServiceType
      );
      if (findServiceType) {
        auditServiceTypeSystemParam.value = findServiceType;
      }
    }
  }
});
</script>

<style>
.special-line .el-form-item__label {
  line-height: 24px !important;
}
</style>
<style scoped lang="scss">
.el-row {
  .el-col {
    padding: 0 4px;
    .icon-generate-projectnumber {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
