<template>
  <!-- 头部信息 -->
  <div class="header-root">
    <!-- <el-icon @click="handleMenuCollapse">
      <i v-if="!isCollapse" class="iconfont icon-zhedie1" />
      <i v-else class="iconfont icon-zhedie2" />
    </el-icon> -->
    <div class="header-right">
      <span>{{ userName }}</span>
      <el-image :src="avator">
        <template #placeholder>
          <div class="image-slot">记载中<span class="dot">...</span></div>
        </template>
      </el-image>
      <!-- <img v-if="avator" :src="avator" />
      <img v-else src="~@/assets/images/default_avator.png"/> -->
      <el-tooltip
        :content="$t('Components.Index.Header.Tip_Suoding')"
        placement="bottom"
      >
        <el-icon @click="handleLock"
          ><i class="iconfont icon-suoding"></i
        ></el-icon>
      </el-tooltip>

      <el-popover
        trigger="hover"
        class="header-popover"
        placement="bottom-end"
        title=""
        :width="260"
      >
        <HeaderPopover
          @setSystemSettingVisible="() => (systemSettingDialogVisible = true)"
          @setPersonalInfomationVisible="(status)=> {
            personalInfomationDialogVisible = status
          }"
        />
        <template #reference>
          <el-icon>
            <i class="iconfont icon-gengduo"></i>
          </el-icon>
        </template>
      </el-popover>
    </div>
  </div>
  <!-- 系统设置 -->
  <SystemSetting
    v-if="systemSettingDialogVisible"
    ref="systemSettingRef"
    @handleCancleDialog="() => (systemSettingDialogVisible = false)"
  />
  <!-- 个人信息 -->
  <PersonalInformation
    v-if="personalInfomationDialogVisible"
    ref="personalInfomationRef"
    @handleCancleDialog="() => (personalInfomationDialogVisible = false)"
  />
</template>
<script setup >
import HeaderPopover from "@/components/Index/Header/HeaderPopover.vue";
import { ref, shallowRef, watchEffect } from "vue";
import { useStore } from "@/store/index";
import { useRouter } from "vue-router";
import LockScreen from "@/views/LockScreen.vue";
import md5 from "js-md5";
import { LocalStorageKey, IsLock } from "@/consts/frame";
import SystemSetting from "@/components/Index/Header/SystemSetting.vue";
import PersonalInformation from "@/components/Index/Header/PersonalInformation1.vue";
const router = useRouter();
const store = useStore();
// 是否显示dialog
const systemSettingDialogVisible = ref(false);
const personalInfomationDialogVisible = ref(false);
// 用户名称
const userName = ref();
// 头像
const avator = ref();
// 系统设置ref
const systemSettingRef = ref();
const personalInfomationRef = ref();
// 锁定
const handleLock = () => {
  // 跳转到锁定页面
  router.push("/lock");
  if (store.lastOperateTimeIntervalId && store.lastOperateTimeIntervalId > 0) {
    // 如果存在定时器，则需要删除
    clearInterval(store.lastOperateTimeIntervalId);
    store.setLastOperateTimeId(undefined);
  }
  store.setLastOperateTime(undefined);
  window.document.onmousedown = null;
  // 清空监听事件
  store.setActivePage({ component: shallowRef(LockScreen) });
  localStorage.setItem(LocalStorageKey.IsLock, md5(IsLock.True));
};

watchEffect(()=>{
  if(store.userInfo){
    if(store.userInfo.user_info){
      userName.value = store.userInfo.user_info.nick_name
      avator.value = store.userInfo.user_info.avator
    }
  }
})
</script>
<style>
.el-popover.el-popper {
  padding: 10px 0 !important;
}
</style>
<style scoped lang='scss'>
.header-root {
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 12px;
  .el-icon {
    &:hover {
      cursor: pointer;
    }
  }

  .header-right {
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span {
      font-size: 12px;
    }
    .el-image{
      width: 32px;
      height: 32px;
      margin-left: 18px;
      border-radius: 16px;

      &:hover {
        cursor: pointer;
      }
    }
    img {
      width: 32px;
      height: 32px;
      margin-left: 18px;
      border-radius: 16px;

      &:hover {
        cursor: pointer;
      }
    }

    .el-icon {
      margin-left: 18px;
    }
  }
}
</style>