<template>
  <Dialog
    width="40%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form :model="form" label-width="80px" :rules="rules" ref="formRef">
      <el-form-item :label="$t('Components.Database.AuditSummary.Item.Name')" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item :label="$t('Components.Database.AuditSummary.Item.DefaultValue')" prop="default_value">
        <el-input 
          type="textarea" v-model="form.default_value" />
      </el-form-item>
      <el-form-item :label="$t('Components.Database.AuditSummary.Item.Sort')" prop="sort">
        <el-input-number :style="{ width: '100%' }" v-model="form.sort" />
      </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup>
import { ref, reactive, markRaw, onMounted, defineProps, defineEmits } from "vue";
import { WarnTriangleFilled } from "@element-plus/icons-vue";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { useI18n } from "vue-i18n";
import { request_auditsummary_item_add,  request_auditsummary_item_modify} from "@/api/apis";
import { ElMessageBox } from "element-plus";

const { t } = useI18n();
const props = defineProps({
  editData: {
    type: Object,
    default: () => {},
  },
  auditSummaryGuid:{
    type: String,
    default: ()=>''
  }
});
const emits = defineEmits(["handleCloseDialog", "getAuditSummaryItemPage"]);
// 标题
const dialogTitle = ref("");
// 表单ref
const formRef = ref();
// 表单数据
const form = reactive({
  name: props.editData.name,
  sort: props.editData.sort || 0,
  default_value: props.editData.default_value
});

// 表单校验规则
const rules = ref({
  name: [
    {
      required: true,
      message: t("Components.Database.AuditSummary.Item.PleaseInputName"),
      trigger: "blur",
    },
    {
      max: 255,
      message: t("Components.Database.AuditSummary.Item.ValidateMaxLength255"),
      trigger: "change",
    },
  ],
  sort: [
    {
      required: true,
      message: t("Components.Database.AuditSummary.Item.PleaseInputSort"),
      trigger: "blur",
    },
  ],
});

onMounted(async () => {
  if (props.editData && props.editData.guid) {
    // 代表修改
    dialogTitle.value = t("Components.Database.AuditSummary.Item.DialogAuditSummaryItemTitleMaintance");
  } else {
    dialogTitle.value = t("Components.Database.AuditSummary.Item.DialogAuditSummaryItemTitleAdd");
  }
});
// 处理提交事件
const handleCommit = async () => {
  if (!formRef.value) return;
  await formRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(
        t("Components.Database.AuditSummary.Item.ConfirmSaveAuditSummaryItem"),
        t("Common.ElMessageBox.OperateConfirm"),
        {
          confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
          cancelButtonText: t("Common.ElMessageBox.CancelText"),
          type: "warning",
          icon: markRaw(WarnTriangleFilled),
        }
      )
        .then(async () => {
          await saveAuditSummary();
        })
        .catch(() => {});
    }
  });
};
// 保存稽查小结项数据
const saveAuditSummary = async () => {
  const trans = {
    name: form.name,
    sort: form.sort,
    default_value: form.default_value,
    guid: props.editData.guid,
  };
  if (props.editData && props.editData.guid) {
    // 修改
    await request_auditsummary_item_modify(props.auditSummaryGuid, trans);
  } else {
    // 新增
    await request_auditsummary_item_add(props.auditSummaryGuid, trans);
  }
  emits("getAuditSummaryItemPage");
  emits("handleCloseDialog");
};
// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};
</script>
<style scoped lang="scss"></style>
