<template>
  <Dialog
    width="60%"
    :title="dialogTitle"
    :showFooter="!props.isView"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form
      class="projecttaskmodify-form"
      :model="form"
      label-width="140px"
      :rules="rules"
      ref="formRef"
      size="large"
    >
      <el-form-item
        :label="$t('Components.Project.ProjectTask.PlanNumber')"
        prop="project_guid"
      >
        <el-select
          :disabled="props.isView"
          :style="{ width: '100%' }"
          v-model="form.project_guid"
          filterable
          clearable
          :placeholder="t('Components.Project.ProjectTask.PleaseSelectProject')"
        >
          <el-option
            v-for="item in props.projects"
            :key="item.guid"
            :label="item.project_number"
            :value="item.guid"
          />
        </el-select>
      </el-form-item>
      <el-row >
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.Project.ProjectTask.AuditedParty')"
            prop="audited_party_guid"
          >
            <el-select
              :disabled="props.isView"
              :style="{ width: '100%' }"
              v-model="form.audited_party_guid"
              filterable
              clearable
              :placeholder="
                t('Components.Project.ProjectTask.PleaseSelectAuditedParty1')
              "
            >
              <el-option
                v-for="item in props.auditedPartys"
                :key="item.guid"
                :label="item.name"
                :value="item.guid"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="
              $t('Components.Project.ProjectTask.ProjectTrialProgressStage')
            "
            prop="project_stage"
          >
            <el-select
              :disabled="props.isView"
              :style="{ width: '100%' }"
              v-model="form.project_stage"
              filterable
              clearable
              :placeholder="
                t(
                  'Components.Project.ProjectTask.PleaseSelectProjectTrialProgressStage'
                )
              "
            >
              <el-option
                v-for="item in props.trailProgressStages"
                :key="item.guid"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            :label="
              $t('Components.Project.ProjectTask.HospitalType')
            "
            prop="hospital_type"
          >
            <el-select
              :disabled="props.isView"
              :style="{ width: '100%' }"
              v-model="form.hospital_type"
              filterable
              clearable
              :placeholder="
                t(
                  'Components.Project.ProjectTask.PleaseSelectHospitalType'
                )
              "
            >
              <el-option
                v-for="item in props.hospitalTypes"
                :key="item.guid"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            :label="
              $t('Components.Project.ProjectTask.ImplementationDepartment')
            "
            prop="implementation_department"
          >
            <el-input
              :disabled="props.isView"
              v-model="form.implementation_department"
              :placeholder="
                t(
                  'Components.Project.ProjectTask.PleaseInputImplementationDepartment'
                )
              "
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.Project.ProjectTask.MainResearcher')"
            prop="main_researcher"
          >
            <el-input
              :disabled="props.isView"
              v-model="form.main_researcher"
              :placeholder="
                t('Components.Project.ProjectTask.PleaseInputMainResearcher')
              "
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row >
        <el-col :span="12"> </el-col>
        <el-col :span="12"> </el-col>
      </el-row>
      <el-row >
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.Project.ProjectTask.PlannedAuditTime')"
            prop="planned_audit_time"
          >
            <el-date-picker
              :disabled="props.isView"
              :style="{ width: '100%' }"
              v-model="form.planned_audit_time"
              type="daterange"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              range-separator=" - "
              :start-placeholder="t('Components.Project.ProjectTask.StartDate')"
              :end-placeholder="t('Components.Project.ProjectTask.EndDate')"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.Project.ProjectTask.ActualAuditTime')"
            prop="actual_audit_time"
          >
            <el-date-picker
              :disabled="props.isView"
              :style="{ width: '100%' }"
              v-model="form.actual_audit_time"
              type="daterange"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              range-separator=" - "
              :start-placeholder="t('Components.Project.ProjectTask.StartDate')"
              :end-placeholder="t('Components.Project.ProjectTask.EndDate')"
              :disabled-date="(date)=>{
                return date.getTime() > Date.now()
              }"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row >
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.Project.ProjectTask.PlannedReportingDate')"
            prop="planned_reporting_date"
          >
            <el-date-picker
              v-model="form.planned_reporting_date"
              type="date"
              :disabled="props.isView"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              :placeholder="
                t(
                  'Components.Project.ProjectTask.PleaseSelectPlannedReportDate'
                )
              "
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.Project.ProjectTask.CompletedReportingDate')"
            prop="completed_reporting_date"
          >
            <el-date-picker
              v-model="form.completed_reporting_date"
              :disabled="props.isView"
              type="date"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              :placeholder="
                t(
                  'Components.Project.ProjectTask.PleaseSelectCompletedReportDate'
                )
              "
              :disabled-date="(date)=>{
                return date.getTime() > Date.now()
              }"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="computeIsCAPA ">
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.Project.ProjectTask.PlannedCAPADate')"
            prop="planned_capa_date"
          >
            <el-date-picker
              disabled
              v-model="form.planned_capa_date"
              type="date"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              :placeholder="
                t('Components.Project.ProjectTask.PleaseSelectPlannedCAPADate')
              "
              :disabled-date="(date)=>{
                return date.getTime() > Date.now()
              }"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.Project.ProjectTask.ActualCAPADate')"
            prop="actual_capa_date"
          >
            <el-date-picker
              :disabled="props.isView"
              v-model="form.actual_capa_date"
              type="date"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              :placeholder="
                t('Components.Project.ProjectTask.PleaseSelectActualCAPADate')
              "
              :disabled-date="(date)=>{
                return date.getTime() > Date.now()
              }"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row >
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.Project.ProjectTask.CenterNumber')"
            prop="center_number"
          >
            <el-input
              :disabled="props.isView"
              v-model="form.center_number"
              :placeholder="
                t('Components.Project.ProjectTask.PleaseInputCenterNumber')
              "
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- prop="audit_address" -->
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.Project.ProjectTask.AuditAddress')"
          >
            <el-input
              :disabled="props.isView"
              v-model="form.audit_address"
              type="textarea"
              maxlength="255"
              show-word-limit
              :placeholder="
                t('Components.Project.ProjectTask.PleaseInputAuditAddress')
              "
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        v-if="props.editData && props.editData.guid "
      >
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.Project.ProjectTask.CustomerProjectManager')"
            prop="customer_project_manager"
          >
            <el-input
              :disabled="props.isView"
              v-model="form.customer_project_manager"
              :placeholder="
                t(
                  'Components.Project.ProjectTask.PleaseInputCustomerProjectManager'
                )
              "
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            :label="$t('Components.Project.ProjectTask.CustomerInspector')"
            prop="customer_inspector"
          >
            <el-input
              :disabled="props.isView"
              v-model="form.customer_inspector"
              clearable
              :placeholder="
                t(
                  'Components.Project.ProjectTask.PleaseInputCustomerProjectAssistance'
                )
              "
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
        <el-form-item
          prop="remark"
          :label="$t('Components.Project.ProjectTask.Remark')"
        >
          <el-input
            :disabled="props.isView"
            v-model="form.remark"
            type="textarea"
            show-word-limit
            :placeholder="t('Components.Project.ProjectTask.PleaseInputRemark')"
          ></el-input>
        </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup>
import {
  ref,
  reactive,
  markRaw,
  onMounted,
  defineProps,
  defineEmits,
  computed,
} from "vue";
import { WarnTriangleFilled } from "@element-plus/icons-vue";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { useI18n } from "vue-i18n";
import {
  request_projecttask_add,
  request_projecttask_actualtime_validate,
  request_projecttask_modify,
} from "@/api/apis";
import { ElMessageBox } from "element-plus";

const { t } = useI18n();
const props = defineProps({
  editData: {
    type: Object,
    default: () => undefined,
  },
  projects: {
    type: Array,
    default: () => [],
  },
  auditedPartys: {
    type: Array,
    default: () => [],
  },
  trailProgressStages: {
    type: Array,
    default: () => [],
  },
  users: {
    type: Array,
    default: () => [],
  },
  isView: {
    type: Boolean,
    default: () => false,
  },
  hospitalTypes:{
    type: Array,
    default: ()=>[]
  }
});

const emits = defineEmits(["handleCloseDialog", "getProjectTaskPage"]);
// 标题
const dialogTitle = ref("");
// 表单ref
const formRef = ref();
// 表单数据
const form = reactive({
  project_guid: props.editData ? props.editData.project_guid : "",
  audited_party_guid: props.editData ? props.editData.audited_party_guid : "",
  project_stage: props.editData ? props.editData.project_stage : undefined,
  implementation_department: props.editData
    ? props.editData.implementation_department
    : "",
  main_researcher: props.editData ? props.editData.main_researcher : "",
  center_number: props.editData ? props.editData.center_number : "",
  audit_address: props.editData ? props.editData.audit_address : "",
  // planned_audit_starttime: props.editData.planned_audit_starttime,
  // planned_audit_endtime: props.editData.planned_audit_endtime,
  planned_audit_time: props.editData
    ? props.editData.planned_audit_time
    : undefined,
  actual_audit_time: props.editData
    ? props.editData.actual_audit_time
    : undefined,
  planned_reporting_date: props.editData
    ? props.editData.planned_reporting_date
    : undefined,
  completed_reporting_date: props.editData
    ? props.editData.completed_reporting_date
    : undefined,
  planned_capa_date: props.editData
    ? props.editData.planned_capa_date
    : undefined,
  actual_capa_date: props.editData
    ? props.editData.actual_capa_date
    : undefined,
  customer_project_manager: props.editData
    ? props.editData.customer_project_manager
    : "",
  customer_inspector: props.editData ? props.editData.customer_inspector : "",
  hospital_type: props.editData? props.editData.hospital_type: undefined,
  remark: props.editData ? props.editData.remark: ""
});
// 表单校验规则
const rules = ref({
  project_guid: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.PleaseSelectProject"),
      trigger: "change",
    },
  ],
  audited_party_guid: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.PleaseSelectAuditedParty1"),
      trigger: "change",
    },
  ],
  project_stage: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.PleaseSelectProjectStage"),
      trigger: "change",
    },
  ],
  hospital_type: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.PleaseSelectHospitalType"),
      trigger: "change",
    },
  ],
  implementation_department: [
    {
      required: true,
      message: t(
        "Components.Project.ProjectTask.PleaseInputImplementationDepartment"
      ),
      trigger: "change",
    },
    {
      max: 100,
      message: t("Components.Project.ProjectTask.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  main_researcher: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.PleaseInputMainResearcher"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.Project.ProjectTask.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  audit_address: [
    {
      required: true,
      message: t("Components.Project.ProjectTask.PleaseInputAuditAddress"),
      trigger: "change",
    },
    {
      max: 100,
      message: t("Components.Project.ProjectTask.ValidateMaxLength"),
      trigger: "change",
    },
  ],
});
// 计算是否capa
const computeIsCAPA = computed(() => {
  if (form.project_guid) {
    const findProject = props.projects.find(
      (item) => item.guid === form.project_guid
    );
    if (findProject) {
      return findProject.is_capa;
    }
  }
  return false;
});
onMounted(async () => {
  if (props.isView) {
    // 查看
    dialogTitle.value = t(
      "Components.Project.ProjectTask.DialogProjectTaskTitleView"
    );
  } else {
    if (props.editData && props.editData.guid) {
      // 代表修改
      dialogTitle.value = t(
        "Components.Project.ProjectTask.DialogProjectTaskTitleMaintance"
      );
    } else {
      dialogTitle.value = t(
        "Components.Project.ProjectTask.DialogProjectTaskTitleAdd"
      );
    }
  }
});
// 处理提交事件
const handleCommit = async () => {
  if (!formRef.value) return;
  await formRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(
        t("Components.Project.ProjectTask.ConfirmSaveProjectTask"),
        t("Common.ElMessageBox.OperateConfirm"),
        {
          confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
          cancelButtonText: t("Common.ElMessageBox.CancelText"),
          type: "warning",
          icon: markRaw(WarnTriangleFilled),
        }
      )
        .then(async () => {
          await saveProjectTask();
        })
        .catch(() => {});
    }
  });
};
// 保存项目任务数据
const saveProjectTask = async () => {
  const trans = {
    project_guid: form.project_guid,
    audited_party_guid: form.audited_party_guid,
    project_stage: form.project_stage,
    implementation_department: form.implementation_department,
    main_researcher: form.main_researcher,
    center_number: form.center_number,
    audit_address: form.audit_address,
    planned_audit_starttime:
      form.planned_audit_time && form.planned_audit_time.length > 0
        ? form.planned_audit_time[0]
        : undefined,
    planned_audit_endtime:
      form.planned_audit_time && form.planned_audit_time.length > 1
        ? form.planned_audit_time[1]
        : undefined,
    actual_audit_starttime:
      form.actual_audit_time && form.actual_audit_time.length > 0
        ? form.actual_audit_time[0]
        : undefined,
    actual_audit_endtime:
      form.actual_audit_time && form.actual_audit_time.length > 1
        ? form.actual_audit_time[1]
        : undefined,
    planned_reporting_date: form.planned_reporting_date,
    completed_reporting_date: form.completed_reporting_date,
    planned_capa_date: form.planned_capa_date,
    actual_capa_date: form.actual_capa_date,
    customer_project_manager: form.customer_project_manager,
    customer_inspector: form.customer_inspector,
    hospital_type: form.hospital_type,
    remark: form.remark
  };

  const validateTrans = {
    project_task_guid: (props.editData && props.editData.guid) ? props.editData.guid : '',
    actual_audit_starttime: trans.actual_audit_starttime,
    actual_audit_endtime: trans.actual_audit_endtime
  }

  var result = await request_projecttask_actualtime_validate(validateTrans);
  if(!result.flag){ // 有其他任务
    // 先校验是否存在其他
    ElMessageBox.confirm(
      result.message,
      t("Common.ElMessageBox.OperateConfirm"),
      {
        confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
        cancelButtonText: t("Common.ElMessageBox.CancelText"),
        type: "warning",
        icon: markRaw(WarnTriangleFilled),
      })
    .then(async () => {
      await saveProjectTaskInner(trans);
    })
    .catch(() => {});
  }else{
    saveProjectTaskInner(trans)
  }

};

const saveProjectTaskInner = async (trans)=>{
  if (props.editData && props.editData.guid) {
    // 修改
    await request_projecttask_modify(props.editData.guid, trans);
  } else {
    // 新增
    await request_projecttask_add(trans);
  }
  emits("getProjectTaskPage");
  emits("handleCloseDialog", 1);
}

// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog", 1);
};

</script>
<style>
.projecttaskmodify-form .el-date-editor {
  width: 100% !important;
}
</style>
<style scoped lang="scss"></style>
