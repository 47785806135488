<template>
  <ul class="my-calendar-ul" @click="() => (menuVisible = false)">
    <li class="hm-search">
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item
          :label="t('Components.AuditCalendar.PersoCalendar.StartDate')"
        >
          <el-date-picker
            v-model="searchForm.start_date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :placeholder="
              t('Components.AuditCalendar.PersoCalendar.PleaseSelectStartDate')
            "
          />
        </el-form-item>
        <el-form-item
          :label="t('Components.AuditCalendar.PersoCalendar.EndDate')"
        >
          <el-date-picker
            v-model="searchForm.end_date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :placeholder="
              t('Components.AuditCalendar.PersoCalendar.PleaseSelectEndDate')
            "
          />
        </el-form-item>
        <el-form-item
          :label="t('Components.AuditCalendar.PersoCalendar.Person')"
        >
          <el-select
            v-model="searchForm.user_guid"
            :placeholder="
              t('Components.AuditCalendar.PersoCalendar.PleaseSelectUser')
            "
            clearable
            filterable
          >
            <el-option
              v-for="item in users"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('Components.AuditCalendar.PersoCalendar.Client')"
        >
          <el-select
            v-model="searchForm.client_guid"
            filterable
            clearable
            :placeholder="
              t('Components.AuditCalendar.PersoCalendar.PleaseSelectClient')
            "
          >
            <el-option
              v-for="item in clients"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('Components.AuditCalendar.PersoCalendar.ProjectNumber')"
        >
          <el-select
            v-model="searchForm.project_guid"
            filterable
            clearable
            :placeholder="
              t(
                'Components.AuditCalendar.PersoCalendar.PleaseSelectProjectNumber'
              )
            "
          >
            <el-option
              v-for="item in projects"
              :key="item.guid"
              :label="item.project_number"
              :value="item.guid"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          :label="
            $t('Components.AuditCalendar.PersoCalendar.ProjectTaskNumber')
          "
        >
          <el-select
            v-model="searchForm.project_task_guid"
            filterable
            clearable
            :placeholder="
              t(
                'Components.AuditCalendar.PersoCalendar.PleaseSelectProjectTaskNumber'
              )
            "
          >
            <el-option
              v-for="item in projectTasks"
              :key="item.guid"
              :label="item.number"
              :value="item.guid"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch" :icon="Search">{{
            $t("Components.AuditCalendar.PersoCalendar.Search")
          }}</el-button>
          <el-button @click="handleReset" :icon="RefreshLeft">{{
            $t("Components.AuditCalendar.PersoCalendar.Reset")
          }}</el-button>
        </el-form-item>
      </el-form>
    </li>
    <li class="hm-operate">
      <!-- 操作部分 -->
      <el-button
        v-if="buttonVisible.addButton"
        :style="{ color: 'white' }"
        :icon="Plus"
        :color="ButtonColor.Add"
        @click="
          () => {
            visibleSchedulingDialog = true;
          }
        "
        >{{ $t("Components.AuditCalendar.PersoCalendar.Add") }}</el-button
      >
    </li>
    <div class="div-other">
      <div class="div-zoom">
        <el-space>
          <el-button
            type=""
            size="small"
            @click="
              () => {
                if (zoomSize - 0.1 >= 0) {
                  zoomSize -= 0.1;
                  researchData();
                }
              }
            "
          >
            <el-icon><Minus /></el-icon>
          </el-button>

          <span>{{ zoomSizeComputed }}%</span>
          <el-button
            type=""
            size="small"
            @click="
              () => {
                zoomSize += 0.1;
                researchData();
              }
            "
          >
            <el-icon><Plus /></el-icon>
          </el-button>
        </el-space>
      </div>
      <div class="div-tip">
        <div class="div-block">
          <div class="div-color not-audit"></div>
          <div class="div-color-name">
            {{
              t("Components.AuditCalendar.PersoCalendar.TipNotActualAuditTime")
            }}
          </div>
        </div>
        <div class="div-block">
          <div class="div-color audit"></div>
          <div class="div-color-name">
            {{
              t("Components.AuditCalendar.PersoCalendar.TipHaveActualAuditTime")
            }}
          </div>
        </div>
        <div class="div-block">
          <div class="div-color scheduling"></div>
          <div class="div-color-name">
            {{ t("Components.AuditCalendar.PersoCalendar.TipScheduling") }}
          </div>
        </div>
        <div class="div-block">
          <div class="div-color vacation"></div>
          <div class="div-color-name">
            {{ t("Components.AuditCalendar.PersoCalendar.TipVacation") }}
          </div>
        </div>
      </div>
    </div>

    <div
      :style="{ zoom: zoomSize }"
      class="gantt-no"
      ref="ganttRef"
      v-loading="loading"
    ></div>
  </ul>
  <TaskCalendarModify
    v-if="visibleDialog"
    :editData="editData"
    @handleCloseDialog="handleCloseDialog"
    @handleSearch="handleSearch"
  />
  <PersonCalendarScheduling
    v-if="visibleSchedulingDialog"
    @handleCloseDialog="handleCloseSchedulingDialog"
    :editData="editSchedulingData"
    :users="users"
    @researchData="researchData"
  />
  <RightMenu
    :x="menuX"
    :y="menuY"
    v-if="menuVisible"
    @menu-item="handleItemClick"
  />
</template>

<script setup>
import {
  reactive,
  onMounted,
  watchEffect,
  ref,
  onBeforeUnmount,
  markRaw,
  computed,
} from "vue";
import { gantt } from "dhtmlx-gantt";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import {
  request_calendar_person,
  request_user_page,
  request_userscheduling_delete,
  request_project_all,
  request_projecttask_page,
  request_client_page,
} from "@/api/apis";
import { useI18n } from "vue-i18n";
import { formatTime } from "@/util/timeUtil";
import dayjs from "dayjs";
import {
  Search,
  RefreshLeft,
  WarnTriangleFilled,
  Minus,
  Plus,
} from "@element-plus/icons-vue";
import { ElMessageBox } from "element-plus";
import TaskCalendarModify from "@/components/AuditCalendar/TaskCalendar/TaskCalendarModify.vue";
import { validateMenuButton } from "@/util/util";
import { useStore } from "@/store/index";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { ButtonColor } from "@/consts/frame";
import PersonCalendarScheduling from "@/components/AuditCalendar/PersonCalendar/PersonCalendarScheduling.vue";
import RightMenu from "@/components/AuditCalendar/PersonCalendar/RightMenu.vue";
import { UserSchedulingType } from "@/consts/enum";

const store = useStore();
const { t } = useI18n();
const buttonVisible = reactive({
  addButton: false,
});
// 初始化为本月，下月
const nowDate = new Date();
const startDate = new Date(nowDate.setMonth(nowDate.getMonth() - 1));
const endDate = new Date(nowDate.setMonth(nowDate.getMonth() + 2));
const searchForm = reactive({
  start_date: undefined,
  end_date: undefined,
  user_guid: undefined,
  client_guid: undefined,
  project_guid: undefined,
  project_task_guid: undefined,
});
const ganttRef = ref();
const visibleDialog = ref(false);
const visibleSchedulingDialog = ref(false);
const tasks = ref({ data: [], links: [] });
const loading = ref(false);
const users = ref([]);
const editData = ref(undefined);
const editSchedulingData = ref(undefined);
const menuVisible = ref(false); // 控制菜单显示
const menuX = ref(0); // left
const menuY = ref(0); // top
const currentRightClickTaskGuid = ref("");
const zoomSize = ref(0.8);
const zoomSizeComputed = computed(() => {
  return (zoomSize.value * 100).toFixed(0);
});
const clients = ref([]);
const projects = ref([]);
const projectTasks = ref([]);
let isShowConfirmDialog = false;
onMounted(async () => {
  await getUserAll();
  await getProejctPersonCalendar();
  if (ganttRef.value) {
    initGantt();
    gantt.init(ganttRef.value); // 初始化 DOM
    gantt.clearAll();
    gantt.parse(tasks.value); // 传入 tasks
  }
  getClientsAll();
  getProjectAll();
  getProjectTaskAll();
});
//初始化甘特图
const initGantt = () => {
  // gantt.config.scrollable = true;
  gantt.config.show_links = false;
  gantt.config.drag_progress = false;
  gantt.config.duration_unit = "day";
  gantt.config.duration_step = 1;
  gantt.config.drag_resize = false; // 禁止右侧时间工期 左右拖动
  gantt.config.drag_move = false;
  // gantt.config.show_progress = true; //取消右边表格进度条显示与拖动
  gantt.config.columns = [
    {
      name: "text",
      label: t("Components.AuditCalendar.PersoCalendar.Person"),
      tree: false,
      width: "100",
      align: "center",
      template: function (obj) {
        let styleColor = "";
        if (!obj.data.is_project) {
          if (obj.data.type === UserSchedulingType.Scheduling) {
            styleColor = "background-color: #6495ED;color: white";
          } else if (obj.data.type === UserSchedulingType.Vacation) {
            styleColor = "background-color: #A9A9A9;color: white";
          }
        }
        return `<div class="nn" style="${styleColor} " title="${obj.user_name}">${obj.user_name}</div>`;
      },
    },
    {
      name: "text",
      label: t("Components.AuditCalendar.PersoCalendar.Client"),
      tree: false,
      width: "240",
      align: "left",
      template: function (obj) {
        let styleColor = "";
        if (!obj.data.is_project) {
          if (obj.data.type === UserSchedulingType.Scheduling) {
            styleColor = "background-color: #6495ED;color: white";
          } else if (obj.data.type === UserSchedulingType.Vacation) {
            styleColor = "background-color: #A9A9A9;color: white";
          }
        }
        return `<div class="nn" style="${styleColor}" title="${
          obj.data.client_name ? obj.data.client_name : "-"
        }">${obj.data.client_name ? obj.data.client_name : "-"}</div>`;
      },
    },
    {
      name: "text",
      label: t("Components.AuditCalendar.PersoCalendar.ProjectTaskNumber"),
      tree: false,
      width: "260",
      align: "left",
      template: function (obj) {
        let styleColor = "";
        if (!obj.data.is_project) {
          if (obj.data.type === UserSchedulingType.Scheduling) {
            styleColor = "background-color: #6495ED;color: white";
          } else if (obj.data.type === UserSchedulingType.Vacation) {
            styleColor = "background-color: #A9A9A9;color: white";
          }
        }
        return `<div class="nn" style="${styleColor}" title="${
          obj.data.project_task_number ? obj.data.project_task_number : "-"
        }">${
          obj.data.project_task_number ? obj.data.project_task_number : "-"
        }</div>`;
      },
    },
  ];
  gantt.config.xml_date = "%Y-%m-%d"; // 日期格式化的匹配格式
  gantt.config.scale_height = 180; // 日期栏的高度
  gantt.plugins({
    marker: true,
  });
  // 添加点击事件
  gantt.attachEvent("onTaskClick", (id) => {
    const findData = tasks.value.data.find((item) => item.id === id);
    if (findData) {
      if (findData.data.is_project) {
        visibleDialog.value = true;
        editData.value = findData.data;
      } else {
        // 排班数据
        visibleSchedulingDialog.value = true;
        editSchedulingData.value = findData.data;
      }
    }
  });
  // 覆盖自带的双击事件
  gantt.attachEvent("onTaskDblClick", () => {});
  gantt.attachEvent("onContextMenu", (taskId, linkId, event) => {
    const findData = tasks.value.data.find((item) => item.id === taskId);
    if (findData && !findData.data.is_project) {
      // const x =
      //   event.clientX +
      //   document.body.scrollLeft +
      //   document.documentElement.scrollLeft;
      // const y =
      //   event.clientY +
      //   document.body.scrollTop +
      //   document.documentElement.scrollTop;
      // 判断要是在树上的右键菜单才有效果
      if (taskId && event.target.className === "nn") {
        // menuX.value = x;
        // menuY.value = y;
        // menuVisible.value = true;
        // currentRightClickTaskGuid.value = taskId;
        ElMessageBox.confirm(
          t("Components.AuditCalendar.PersoCalendar.ConfirmDeleteTask"),
          t("Common.ElMessageBox.OperateConfirm"),
          {
            confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
            cancelButtonText: t("Common.ElMessageBox.CancelText"),
            type: "warning",
            icon: markRaw(WarnTriangleFilled),
          }
        )
          .then(
            async () => {
              await request_userscheduling_delete(taskId);
              taskId = "";
              researchData();
            },
            () => {}
          )
          .catch(() => {});
      }
      if (taskId || linkId) {
        return false;
      }
      return true;
    }
    menuVisible.value = false;
    return false;
  });
  gantt.addMarker({
    start_date: new Date(),
    text: t("Components.AuditCalendar.PersoCalendar.Today"),
  });
  gantt.templates.task_class = function (start, end, task) {
    if (task.data.is_project) {
      if (task.data.actual_audit_starttime && task.data.actual_audit_endtime) {
        // 有实际时间
        return "complete";
      } else {
        return "plan";
      }
    } else {
      if (task.data.type === UserSchedulingType.Scheduling) {
        return "scheduling";
      } else if (task.data.type === UserSchedulingType.Vacation) {
        return "vacation";
      }
    }
  };
  const weekScaleTemplate = function (date) {
    const mouthMap = {
      Jan: t("Common.Month.January"),
      Feb: t("Common.Month.February"),
      Mar: t("Common.Month.March"),
      Apr: t("Common.Month.April"),
      May: t("Common.Month.May"),
      Jun: t("Common.Month.June"),
      Jul: t("Common.Month.July"),
      Aug: t("Common.Month.August"),
      Sept: t("Common.Month.September"),
      Oct: t("Common.Month.October"),
      Nov: t("Common.Month.November"),
      Dec: t("Common.Month.December"),
    };
    // 可以时使用dayjs 处理返回
    const dateToStr = gantt.date.date_to_str("%d");
    const mToStr = gantt.date.date_to_str("%M");
    const endDate = gantt.date.add(gantt.date.add(date, 1, "week"), -1, "day");
    // 处理一下月份
    return `${dateToStr(date)} - ${dateToStr(endDate)} (${
      mouthMap[mToStr(date)]
    })`;
  };
  const dayFormat = function (date) {
    const weeks = [
      t("Common.Week.Sunday"),
      t("Common.Week.Monday"),
      t("Common.Week.Tuesday"),
      t("Common.Week.Wednesday"),
      t("Common.Week.Thursday"),
      t("Common.Week.Friday"),
      t("Common.Week.Saturday"),
    ];
    const weekName = weeks[dayjs(date).day()];
    const dateToStr = gantt.date.date_to_str("%d");
    return `<ul class="gantt-inner-ul" ><li class="gantt-inner-li-day">${dateToStr(
      date
    )}</li><li class="gantt-inner-li-week">${weekName}</li></ul>`;
  };
  gantt.config.scales = [
    { unit: "year", step: 1, format: "%Y" },
    { unit: "week", step: 1, format: weekScaleTemplate },
    { unit: "day", step: 1, format: dayFormat },
  ];
};
// 菜单点击
const handleItemClick = async () => {
  // 删除
  ElMessageBox.confirm(
    t("Components.AuditCalendar.PersoCalendar.ConfirmDeleteTask"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      await request_userscheduling_delete(currentRightClickTaskGuid.value);
      currentRightClickTaskGuid.value = "";
      researchData();
    })
    .catch(() => {});
  menuVisible.value = false; // 隐藏菜单
};
// 获取所有用户
const getUserAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
    is_system: false,
  };
  const result = await request_user_page(trans);
  if (result.total > 0) {
    users.value = result.rows;
  } else {
    users.value = [];
  }
};
// 获取人员日历数据
const getProejctPersonCalendar = async () => {
  loading.value = true;
  const trans = {
    start_date: searchForm.start_date ? formatTime(new Date(searchForm.start_date)): undefined,
    end_date: searchForm.end_date ? formatTime(new Date(searchForm.end_date)) : undefined,
    user_guid: searchForm.user_guid,
    client_guid: searchForm.client_guid,
    project_guid: searchForm.project_guid,
    project_task_guid: searchForm.project_task_guid,
  };
  console.log(trans)
  const result = await request_calendar_person(
    trans,
    () => (loading.value = false)
  );
  tasks.value.data = result.map((item) => {
    // t("Components.AuditCalendar.PersoCalendar.Person")
    return {
      id: item.project_task_guid
        ? item.project_task_guid
        : item.user_scheduling_guid,
      text: item.is_project
        ? item.audited_party_name
          ? `${item.audited_party_name}`
          : `${item.project_task_number}`
        : item.type === UserSchedulingType.Scheduling
        ? item.remark
        : item.type === UserSchedulingType.Vacation
        ? `${t("Components.AuditCalendar.PersoCalendar.Vacation")}`
        : "",
      start_date: `${item.plan_start_time}`,
      duration: item.days,
      progress: 0,
      open: true,
      user_name: item.user_name,
      data: item,
    };
  });
};
// 处理查询
const handleSearch = async () => {
  await getProejctPersonCalendar();
  gantt.clearAll();
  gantt.parse(tasks.value);
  gantt.render();
  if(searchForm.start_date){
    gantt.showDate(new Date(searchForm.start_date))
  }
};
// 重新查询
const researchData = async () => {
  await getProejctPersonCalendar();
  gantt.clearAll();
  gantt.parse(tasks.value);
  gantt.render();
  if(searchForm.start_date){
    gantt.showDate(new Date(searchForm.start_date))
  }
};

// 处理重置
const handleReset = async () => {
  searchForm.start_date = undefined;
  searchForm.end_date = undefined;
  searchForm.user_guid = "";
  searchForm.client_guid = "";
  searchForm.project_guid = "";
  searchForm.project_task_guid = "";
  await getProejctPersonCalendar();
  gantt.clearAll();
  gantt.parse(tasks.value);
  gantt.render();
  if(searchForm.start_date){
    gantt.showDate(new Date(searchForm.start_date))
  }
};
// 处理回调关闭事件
const handleCloseDialog = () => {
  visibleDialog.value = false;
  editData.value = undefined;
};
// 处理关闭排班回调事件
const handleCloseSchedulingDialog = () => {
  visibleSchedulingDialog.value = false;
  editSchedulingData.value = undefined;
};
onBeforeUnmount(() => {
});
// 获取所有的项目
const getProjectAll = async () => {
  const trans = {
    progresses: [],
    is_audit_service_type: true,
  };
  const result = await request_project_all(trans);
  if (result && result.length > 0) {
    projects.value = result;
  } else {
    projects.value = [];
  }
};
// 获取所有任务
const getProjectTaskAll = async () => {
  const trans = {
    pagesize: -1,
  };
  const result = await request_projecttask_page(trans);
  if (result.total > 0) {
    projectTasks.value = result.rows.map((item) => {
      return { guid: item.guid, number: item.number };
    });
  } else {
    projectTasks.value = [];
  }
};
// 获取所有委托方
const getClientsAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
  };
  const result = await request_client_page(trans);
  if (result.total > 0) {
    clients.value = result.rows;
  } else {
    clients.value = [];
  }
};
watchEffect(() => {
  if (store.userMenus) {
    // 处理新增
    buttonVisible.addButton = validateMenuButton(
      store.userMenus,
      MenuCodes.AuditCalendar.PersonCalendar,
      MenuButtonCodes.AuditCalendar.PersonCalendar.UserCalendarAdd
    );
  }
});
</script>
<style>
.gantt_task_line.scheduling {
  background-color: #6495ed;
}
.gantt_task_line.vacation {
  background-color: #a9a9a9;
}
.gantt_task_line.scheduling .gantt_task_content {
  color: #fff;
}
.gantt_task_line.complete {
  background-color: #008000;
}
.gantt_task_line.complete .gantt_task_content {
  color: #fff;
}
.gantt_task_line.plan {
  background-color: #ff0000;
}
.gantt_task_line.plan .gantt_task_content {
  color: #fff;
}
.gantt_cell {
  padding: 0;
}
/* 这边的每一个格子的高度是总日期高度 / 3 */
.gantt-inner-ul {
  padding: 0;
  margin: 0;
  list-style: none;
  flex-direction: column;
  display: flex;
}
.gantt-inner-ul .gantt-inner-li-day {
  /* 每一个格子的高度 / 2 */
  height: 40px;
  line-height: 40px;
  font-size: 1.4em;
  font-weight: 600;
}
.gantt-inner-ul .gantt-inner-li-week {
  height: 20px;
  line-height: 20px;
}
/* .gantt_task_cell{
  width: 50px !important;
}
.gantt_scale_cell{
  width: 50px !important;
} */
</style>
<style scoped lang="scss">
.my-calendar-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  .gantt-no {
    height: calc(100% - 80px);
    // margin-top: 8px;
  }

  .div-other {
    height: 32px;
    display: flex;
    .div-zoom {
      width: 100px;
      display: flex;
      align-items: center;
      span {
        font-size: 12px;
      }
      .el-button {
        width: 16px;
        height: 20px;
      }
    }
    .div-tip {
      flex: 1;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: flex-end;
      .div-block {
        height: 16px;
        // width: 16px;
        display: flex;
        align-items: center;
        margin-left: 16px;
        .div-color {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
        .not-audit {
          background-color: #ff0000;
        }
        .audit {
          background-color: #008000;
        }
        .scheduling {
          background-color: #6495ed;
        }
        .vacation {
          background-color: #a9a9a9;
        }

        .div-color-name {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
