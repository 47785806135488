<template>
  <div class="el-icon-picker_con">
    <el-popover v-model:visible="showPopv" :width="440" placement="bottom-start">
      <template #reference>
        <el-button :icon="chooseIcon" class="icon_picker_btn" @click="showPopover">{{
          chooseIcon || $t('Common.IconPicker.ChooseIcon')
        }}</el-button>
      </template>
      <div class="el-icon-picker">
        <div class="icon_con">
          <component
            v-for="icon in iconList"
            :key="icon"
            class="icon"
            :class="{ icon, 'icon-active': icon == chooseIcon }"
            :is="icon"
            @click="handleChooseIcon(icon)"
          >
          123
          </component>
        </div>
      </div>
    </el-popover>
    <el-icon
      v-if="chooseIcon"
      class="delete_icon"
      size="16"
      @click="clearPicker"
      ><Close
    /></el-icon>
  </div>
</template>
<script setup>
import { ref, watch, getCurrentInstance ,defineEmits, defineProps} from "vue";

const globalProperties = getCurrentInstance().appContext.config.globalProperties; // 获取全局挂载

const props = defineProps({
  icon:{
    type: String,
    default: ()=>''
  },
})
const chooseIcon = ref(props.icon)

let filterVal = ref("");
const emits = defineEmits(['changeIcon']);
const showPopover = () => {
  filterVal.value = "";
  showPopv.value = true;
};

let showPopv = ref(false);
const handleChooseIcon = (icon) => {
  chooseIcon.value = icon
  emits('changeIcon', icon)
  showPopv.value = false;
};

// 图标过滤查询
let iconList = ref([]);
const inputFilter = (val) => {
  if (val) {
    iconList.value = globalProperties.$icons.filter(
      (item) => item.toLowerCase().indexOf(val.toLowerCase()) !== -1
    );
  } else {
    iconList.value = [].concat(globalProperties.$icons);
  }
};

// 清除选中
const clearPicker = () => {
  chooseIcon.value = ''
  emits('changeIcon', '')
};

watch(
  () => showPopv.value,
  (newVal) => {
    if (newVal) {
      filterVal.value = "";
      inputFilter();
    }
  }
);
</script>
<style scoped lang="scss">
.el-icon-picker {
  height: 256px;
  overflow-y: scroll;
  //   display: flex;
  //   justify-content: space-around;
  //   flex-wrap: wrap;

  .icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    // color: $dark_gray;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    line-height: 45px;
    margin: 10px;
  }

  .icon:hover {
    color: var(--el-color-primary);
  }

  .icon-active {
    color: var(--el-color-primary);
  }
  .icon_picker_btn {
    float: left;
  }
  .filter_input {
    width: 100%;
    padding: 0 12px;
    box-sizing: border-box;
    margin-bottom: 3px;
  }
  .icon_con {
    heigth: calc(100% - 39px);
  }
}
.el-icon-picker_con {
  .delete_icon {
    float: right;
    margin-left: 8px;
    height: 32px;
    cursor: pointer;
    visibility: hidden;
  }
  .delete_icon:hover {
    // color: $errorColor;
  }
}
.el-icon-picker_con:hover {
  .delete_icon {
    visibility: visible;
  }
}
</style>
