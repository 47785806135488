<template>
  <h2>4. 稽查发现分级</h2>
  <table class="table" :style="{ width: '100%' }">
    <tr  v-for="item in discoveryLevelDics" :key="item.guid">
      <td :style="{padding: '20px'}">
        <div class="label-bold">{{ item.name }}</div>
        <div :style="{marginTop: '12px'}">
         <span>
          {{ item.value }}
         </span>
        </div>
      </td>
    </tr>
  </table>
</template>
<script setup >
import {watchEffect, ref} from 'vue'
import { useStore } from "@/store/index";
import { Dics } from "@/consts/dic";


const store = useStore();

const discoveryLevelDics = ref([])

// 监听字典数据
watchEffect(() => {
  if (store.userInfo) {
    if (store.userInfo.dics && store.userInfo.dics.length > 0) {
      // 发现等级
      const findDiscoveryLevelDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.DiscoveryLevel
      );
      if (findDiscoveryLevelDic) {
        if (findDiscoveryLevelDic.items && findDiscoveryLevelDic.items.length > 0) {
          discoveryLevelDics.value = findDiscoveryLevelDic.items;
        } else {
          discoveryLevelDics.value = [];
        }
      } else {
        discoveryLevelDics.value = [];
      }
    }
  }
});
</script>
<style scoped lang='scss'>
span{
  white-space: pre-wrap;
}
</style>