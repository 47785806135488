<template>
  <!-- 评审内容 -->
  <div>
    <ul
      :class="[
        'reply-ul',
        props.checkCommentGuid === props.comment.guid ? 'select-ul' : '',
      ]"
    >
      <li
        :class="[
          !props.comment.rootGuid ? 'left-avator-li' : 'left-avator-li-mini',
        ]"
      >
        <!-- <el-icon
          v-if="props.checkCommentGuid === props.comment.guid"
          @click.stop="handleClearCheckComment"
        >
          <i class="iconfont icon-qingchu"></i>
        </el-icon> -->
        <el-icon
          class="clear-icon"
          v-if="props.checkCommentGuid === props.comment.guid"
          @click.stop="handleClearCheckComment"
        >
          <i class="iconfont icon-qingchu"></i>
        </el-icon>
        <el-image class="avator" :src="props.comment.avator" />
      </li>
      <li class="right-content-li">
        <div class="common-div user-name-div">
          <ul>
            <li class="left-li">
              <span>{{ props.comment.name }}</span>
              <span v-if="props.comment.parent_name">
                <el-icon :style="{ marginLeft: '4px' }"><CaretRight /></el-icon>
                <span class="span-parentname" @click="handleParentNameClick">{{
                  props.comment.parent_name
                }}</span>
              </span>
            </li>
            <li class="right-li">
              <el-icon @click.stop="handleClickComment">
                <i class="iconfont icon-refer"></i>
              </el-icon>

              <!-- <el-space>
                <el-tooltip
                  effect="dark"
                  :content="t('Components.Report.GenerateReport.Check')"
                  placement="top"
                >
                  <el-icon @click.stop="handleClickComment">
                    <i class="iconfont icon-refer"></i>
                  </el-icon>
                </el-tooltip>
                <el-tooltip
                  effect="dark"
                  :content="t('Components.Report.GenerateReport.Clear')"
                  placement="top"
                >
                  <el-icon
                    v-if="props.checkCommentGuid === props.comment.guid"
                    @click.stop="handleClearCheckComment"
                  >
                    <i class="iconfont icon-qingchu"></i>
                  </el-icon>
                </el-tooltip>
              </el-space> -->
            </li>
          </ul>
        </div>
        <div
          :class="[
            'ff-div',
            props.comment && props.comment.is_last ? 'ff-div-last' : '',
          ]"
        >
          <el-popover placement="left" :width="400">
            <template #reference>
              <div class="common-div content-div">
                <div
                  :class="[
                    'c-div',
                    props.comment.is_approver && props.comment.is_read
                      ? 'read-content'
                      : '',
                  ]"
                >
                  <span ref="contentRef">
                    {{ props.comment.comment }}
                  </span>
                </div>
              </div>
            </template>
            <ItemEditReviewSingleParentPreview
              :comments="props.comments"
              :comment="props.comment"
            />
          </el-popover>
          <div class="o-div">
            <el-tooltip
              v-if="
                props.projectTaskProgress === ProjectTaskProgress.Rewrite &&
                props.comment.is_approver &&
                !props.comment.is_read &&
                // !props.comment.current_user_is_approver
                // 意思就是： 当前用户不在审核人名单中 或者 当前用户是审核人但是当前用户也是这条评论的提交人（言外之意就是当前人既是发起人也是审核人）
                (!props.comment.current_user_is_approver ||
                  (props.comment.current_user_is_approver &&
                    cacheUserInfo.guid !== props.comment.user_guid))
              "
              class="box-item"
              effect="dark"
              :content="t('Components.Report.GenerateReport.View')"
              placement="bottom"
            >
              <el-icon><Search @click="handleRead" /></el-icon>
            </el-tooltip>
          </div>
        </div>
        <div class="common-div info-div">
          <span class="time-span">{{ props.comment.time }}</span>
          <el-button
            v-if="
              computedIsShow &&
              props.module !==
                ProjectTaskCommentModule.DiscoveryDescriptionAll &&
              module !== ProjectTaskCommentModule.ReportAppendixSingleAll
            "
            class="btn-reply"
            type="primary"
            size="small"
            @click="handleReply"
            >{{ t("Components.Report.GenerateReport.Reply") }}</el-button
          >
        </div>
        <!-- 回复信息 -->
        <div
          v-if="
            !props.comment.rootGuid &&
            props.comment.children &&
            props.comment.children.length > 0
          "
          class="comment-div"
          @click="
            () => {
              showCollspaceTransition = !showCollspaceTransition;
            }
          "
        >
          <div class="allcomment-div">
            {{ t("Components.Report.GenerateReport.Expand") }}
            {{ props.comment.children.length }}
            {{ t("Components.Report.GenerateReport.CountReply") }}
          </div>
          <el-icon v-if="!showCollspaceTransition"><ArrowDownBold /></el-icon>
          <el-icon v-else><ArrowUpBold /></el-icon>
        </div>
        <el-collapse-transition v-if="!props.comment.rootGuid">
          <div v-show="showCollspaceTransition">
            <ItemEditReviewSingle
              v-for="item in props.comment.children"
              :key="item.guid"
              :comment="item"
              :projectTaskGuid="props.projectTaskGuid"
              :checkCommentGuid="props.checkCommentGuid"
              :projectTaskProgress="props.projectTaskProgress"
              :contentDoms="props.contentDoms"
              :rootContentDom="props.rootContentDom"
              @getComment="() => emits('getComment')"
              :module="props.module"
              :relateGuid="props.relateGuid"
              :grading="props.grading"
              :mainCategory="props.mainCategory"
              :subCategory="props.subCategory"
              :elaborateModule="props.elaborateModule"
              :appendixDetailGuid="props.appendixDetailGuid"
              :comments="props.comments"
              :data="props.data"
              :userInfo="props.userInfo"
            />
          </div>
        </el-collapse-transition>
      </li>
    </ul>
  </div>
  <ReviewComments
    v-if="showReviewComments"
    @handleCloseDialog="handleCloseDialog"
    @handleCommitDialog="handleCommitDialog"
    :projectTaskGuid="props.projectTaskGuid"
    :selectStart="selectStart"
    :selectEnd="selectEnd"
    :selectContent="selectContent"
    :rootGuid="
      props.comment.root_guid ? props.comment.root_guid : props.comment.guid
    "
    :sourecContent="props.comment.comment"
    :parentGuid="props.comment.guid"
    :module="props.module"
    :relateGuid="props.relateGuid"
    :grading="props.grading"
    :mainCategory="props.mainCategory"
    :subCategory="props.subCategory"
    :elaborateModule="props.elaborateModule"
    :appendixDetailGuid="props.appendixDetailGuid"
  />
</template>
<script setup>
import {
  ref,
  defineProps,
  computed,
  defineEmits,
  watchEffect,
  onMounted,
} from "vue";
import ItemEditReviewSingle from "./_ItemEditReviewSingle.vue";
import ReviewComments from "./_ReviewComments.vue";
import PubsubMessage from "@/consts/pubsubMessage";
import pubsub from "pubsub-js";
import ItemEditReviewSingleParentPreview from "./_ItemEditReviewSingleParentPreview.vue";
import { ProjectTaskProgress, ProjectTaskCommentModule } from "@/consts/enum";
import { useI18n } from "vue-i18n";
import { Search } from "@element-plus/icons-vue";
import { request_project_comment_read } from "@/api/apis";
import { useStore } from "@/store/index";

const store = useStore();
const { t } = useI18n();
const emits = defineEmits(["getComment"]);
const props = defineProps({
  comments: {
    type: Array,
    default: () => [],
  },
  comment: {
    // 单条评论的信息
    type: Object,
    default: () => {},
  },
  projectTaskGuid: {
    type: String,
    default: () => "",
  },
  checkCommentGuid: {
    type: String,
    default: () => "",
  },
  contentDoms: {
    type: Array,
    default: () => [],
  },
  projectTaskProgress: {
    type: Number,
    default: () => 0,
  },
  rootContentDom: {
    type: Object,
    default: () => undefined,
  },
  module: {
    type: Number,
    default: () => 0,
  },
  relateGuid: {
    type: String,
    default: () => "",
  },
  grading: {
    type: String,
    default: () => "",
  },
  mainCategory: {
    type: String,
    default: () => "",
  },
  subCategory: {
    type: String,
    default: () => "",
  },
  elaborateModule: {
    type: Number,
    default: () => 0,
  },
  appendixDetailGuid: {
    type: String,
    default: () => "",
  },
  data:{
    type: Object,
    default: ()=>{}
  },
  userInfo:{
    type: Object,
    default: ()=>{}
  }
});
// 是否展示折叠评论
const showCollspaceTransition = ref(true);
// 是否显示评论模块
const showReviewComments = ref(false);
// 选区开始位置
const selectStart = ref(0);
// 选取结束位置
const selectEnd = ref(0);
// 选取内容
const selectContent = ref("");
// 内容
const contentRef = ref();
// 当前用户缓存信息
const cacheUserInfo = ref();
// 是否显示
const computedIsShow = computed(() => {
  let canReply = false
  debugger
  if(props.data && props.data.project_task){
    // 判断主稽查员是否包含
    if(props.data.project_task.main_auditor_guids && props.data.project_task.main_auditor_guids.length > 0){
      if(props.userInfo && props.data.project_task.main_auditor_guids.indexOf(props.userInfo.guid)>-1){
        canReply = true
      }
    }
    if(props.data.project_task.auditor_guids && props.data.project_task.auditor_guids.length > 0){
      if(props.userInfo && props.data.project_task.auditor_guids.indexOf(props.userInfo.guid)>-1){
        canReply = true
      }
    }
    if(props.data.project_task.approved_user_guids && props.data.project_task.approved_user_guids.length > 0){
      if(props.userInfo && props.data.project_task.approved_user_guids.indexOf(props.userInfo.guid)>-1){
        canReply = true
      }
    }
    // 待审核人员
    if(props.data.approvers && props.data.approvers.length > 0){
      if(props.userInfo && props.data.approvers.map(item=>item.user_guid).indexOf(props.userInfo.guid)>-1){
        canReply = true
      }
    }
    // 协同审核
    if(props.data.collborations && props.data.collborations.length > 0){
      if(props.userInfo && props.data.collborations.map(item=>item.user_guid).indexOf(props.userInfo.guid)>-1){
        canReply = true
      }
    }
  }
  return (
   ( props.projectTaskProgress === ProjectTaskProgress.ReportWriting ||
    props.projectTaskProgress === ProjectTaskProgress.SubmitForReview ||
    props.projectTaskProgress === ProjectTaskProgress.UnderReview  ||
    // 返改状态 且当前人
    props.projectTaskProgress === ProjectTaskProgress.Rewrite ||
    props.projectTaskProgress === ProjectTaskProgress.ReviewedAndApproved ) && canReply
  );
});
onMounted(() => {});
// 处理点击评论数据，需要将文本选中
const handleClickComment = () => {
  let contentDom = undefined;
  if (props.comment.parent_guid) {
    const findParentCommentDom = props.contentDoms.find(
      (item) => item.guid === props.comment.parent_guid
    );
    if (findParentCommentDom) contentDom = findParentCommentDom.contentDom;
  } else {
    // 代表一级评论
    contentDom = props.rootContentDom;
  }
  // 找到当前的评论的父级评论
  if (contentDom) {
    const selection = window.getSelection();
    selection.removeAllRanges();
    const range = document.createRange();
    if (props.comment.select_end != props.comment.select_start) {
      if (props.comment.select_end > props.comment.select_start) {
        if (contentDom && contentDom.firstChild) {
          range.setStart(
            contentDom.firstChild,
            props.comment.select_start > contentDom.innerText.length
              ? 0
              : props.comment.select_start
          );
          range.setEnd(
            contentDom.firstChild,
            props.comment.select_end > contentDom.innerText.length
              ? contentDom.innerText.length
              : props.comment.select_end
          );
        }
      } else {
        if (contentDom && contentDom.firstChild) {
          range.setStart(
            contentDom.firstChild,
            props.comment.select_end > contentDom.innerText.length
              ? 0
              : props.comment.select_end
          );
          range.setEnd(
            contentDom.firstChild,
            props.comment.select_start > contentDom.innerText.length
              ? contentDom.innerText.length
              : props.comment.select_start
          );
        }
      }
    } else {
      range.selectNodeContents(contentDom); // 需要选中的dom节点
    }
    selection.addRange(range);
  }
};
// 处理回调关闭
const handleCloseDialog = () => {
  showReviewComments.value = false;
  selectStart.value = 0;
  selectEnd.value = 0;
  selectContent.value = "";
};
// 处理提交数据
const handleCommitDialog = () => {
  showReviewComments.value = false;
  // 更新评论数据
  emits("getComment");
};
// 处理回复点击
const handleReply = () => {
  const selection = window.getSelection();
  if (selection.toString()) {
    selectStart.value = selection.anchorOffset;
    selectEnd.value = selection.focusOffset;
    selectContent.value = selection.toString();
  } else {
    selectContent.value = props.comment.comment;
  }
  // 拿到选取的内容
  showReviewComments.value = true;
};
// 父级名称点击
const handleParentNameClick = () => {
  pubsub.publish(
    PubsubMessage.ProjectTaskContentSummaryFindParent,
    props.comment
  );
};
// 清除选中的评论
const handleClearCheckComment = () => {
  pubsub.publish(PubsubMessage.ProjectTaskClearCheckComment);
};
// 处理阅读
const handleRead = async () => {
  const trans = {
    project_task_comment_guid: props.comment.guid,
  };
  await request_project_comment_read(props.projectTaskGuid, trans);
  emits("getComment");
};

watchEffect(() => {
  if (contentRef.value) {
    pubsub.publish(PubsubMessage.ProjectTaskReportContentDom, {
      guid: props.comment.guid, // 标识是评论的guid
      contentDom: contentRef.value,
    });
  }
});
watchEffect(() => {
  if (store.userInfo) {
    if (store.userInfo.user_info) {
      cacheUserInfo.value = store.userInfo.user_info;
    }
  }
});
</script>
<style scoped lang="scss">
.select-ul {
  background-color: #ffe4c4;
}
.reply-ul {
  margin: 0;
  // padding: 8px;
  padding: 8px 0;
  list-style: none;
  display: flex;
  .left-avator-li {
    width: 60px;
    display: flex;
    justify-content: center;
    position: relative;
    .clear-icon {
      position: absolute;
      left: 2px;
      &:hover {
        cursor: pointer;
      }
    }
    .avator {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
  }
  .left-avator-li-mini {
    width: 40px;
    display: flex;
    justify-content: center;
    .avator {
      width: 20px;
      height: 20px;
      border-radius: 10px;
    }
  }
  .right-content-li {
    flex: 1;
    .user-name-div {
      color: #a9a9a9;
      margin-bottom: 2px;
      ul {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        .left-li {
          flex: 1;
        }
        .right-li {
          // width: 80px;
          margin-right: 8px;
          display: flex;
          align-items: center;
          .el-icon {
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .span-parentname {
        margin-left: 4px;
        &:hover {
          cursor: pointer;
          color: #6495ed;
        }
      }
    }
    .ff-div-last {
      background-color: #ffffe0;
    }
    .ff-div {
      display: flex;
      align-items: center;
      .content-div {
        flex: 1;
        margin-bottom: 2px;
        // padding: 8px 0;
        display: flex;
        .c-div {
          flex: 1;
          padding: 8px 0;
          &:hover {
            background-color: #f5f7fa;
            cursor: pointer;
          }
        }
        .read-content {
          color: #87cefa;
          // background-color: #87CEFA;
          font-weight: 900;
          // color: white;
        }
      }
      .o-div {
        display: flex;
        align-items: center;
        margin-right: 8px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    // .content-div {
    //   margin-bottom: 2px;
    //   // padding: 8px 0;
    //   display: flex;
    //   .c-div {
    //     flex: 1;
    //     padding: 8px 0;
    //     &:hover {
    //       background-color: #f5f7fa;
    //       cursor: pointer;
    //     }
    //   }
    //   .o-div {
    //     width: 36px;
    //     display: flex;
    //     align-items: center;
    //     &:hover {
    //       cursor: pointer;
    //     }
    //   }
    // }
    .info-div {
      color: #a9a9a9;
      .btn-reply {
        margin-left: 8px;
        // &:hover {
        //   cursor: pointer;
        //   color: #1e90ff;
        // }
      }
    }
    .comment-div {
      position: relative;
      height: 40px;
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      .allcomment-div {
        height: 40px;
        display: flex;
        align-items: center;
        &:before {
          content: "";
          width: 80px;
          height: 1px;
          background-color: #d3d3d3;
          display: inline-block;
          margin-top: 1px;
          margin-right: 12px;
        }
      }
      .el-icon {
        margin-left: 4px;
      }
    }
  }
}
</style>
