<template>
  <div v-if="props.dicItemValue">
    <el-text
      v-if="
        computeDicItemEchoStyle(
          props.dicType,
          props.dicItemValue
        ) === DictionaryItemEchoStyle.Default
      "
      >{{
        computeDicItem(
          props.dicType,
          props.dicItemValue
        )
      }}</el-text
    >
    <el-tag
      v-else-if="computeDicItem(
          props.dicType,
          props.dicItemValue
        )"
      :type="
        computeDicItemEchoStyle(
          props.dicType,
          props.dicItemValue
        )
      "
      >{{
        computeDicItem(
          props.dicType,
          props.dicItemValue
        )
      }}</el-tag
    >
  </div>
</template>
<script setup >
import { defineProps,computed  } from "vue";
import { DictionaryItemEchoStyle } from "@/consts/enum";
import { Dics } from "@/consts/dic";

const props = defineProps({
  dicData: {
    type: Array,
    default: () => [],
  },
  dicType: {
    type: String,
    default: () => "",
  },
  dicItemValue: {
    type: String,
    default: () => "",
  },
});
// 处理字典数据
const computeDicItem = computed(() => (dic, data) => {
  switch (dic) {
    case Dics.System.ClientCategory: // 委托方类型类别
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.ServiceType: // 服务类型
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.AuditType: // 稽查类型
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.SponsorType: // 申办者类型
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.ResearchProductType: // 研究产品类型
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.TrialStaging: // 试验分期
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.TherapeuticArea: // 治疗领域
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.TrialProgressStage: // 试验进展阶段
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.AuditedPartyArea: // 被稽查方区域
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.AuditedPartyQualification: // 被稽查方资质
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.HospitalType: // 医院类型
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.DiscoveryMainCategory: // 稽查发现主分类
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        }
      }
      break;
  }
});

// 处理回显样式
const computeDicItemEchoStyle = computed(() => (dic, data) => {
  let echoStyle = "";
  switch (dic) {
    case Dics.System.ClientCategory: // 委托方类型
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
    case Dics.System.ServiceType: // 服务类型
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
    case Dics.System.AuditType: // 稽查类型
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
    case Dics.System.SponsorType: // 申办者类型
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
    case Dics.System.ResearchProductType: // 研究产品类型
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
    case Dics.System.TrialStaging: // 试验分期
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
    case Dics.System.TherapeuticArea: // 治疗领域
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
    case Dics.System.TrialProgressStage: // 试验进展阶段
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
    case Dics.System.AuditedPartyArea: // 被稽查方区域
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
    case props.dicType: // 被稽查方资质
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
    case Dics.System.HospitalType: // 医院类型
      if (props.dicData) {
        const findData = props.dicData.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
  }
  return !echoStyle || echoStyle === DictionaryItemEchoStyle.Primary
    ? ""
    : echoStyle;
});
</script>
<style scoped lang='scss'>
</style>