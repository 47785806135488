<template>
  <ul class="title-ul">
    <li class="left-li">
      <h2>
        6.
        {{
          t("Components.Project.ProjectTask.Report1.DiscoveryDescription.Title")
        }}
      </h2>
    </li>
    <li class="right-li">
      <div v-if="computedCommentCount > 0">
        <el-badge
          :value="computedCommentCount"
          type="success"
          class="item"
          v-if="
            props.projectTaskProgress !== ProjectTaskProgress.ToBeAllocated &&
            props.projectTaskProgress !== ProjectTaskProgress.Ready &&
            props.projectTaskProgress !==
              ProjectTaskProgress.AuditImplementation &&
            props.projectTaskProgress !== ProjectTaskProgress.ReportWriting &&
            props.projectTaskProgress !== ProjectTaskProgress.Complete
          "
        >
          <el-badge
            :value="computedNotReadCommentCount"
            class="notread-comment-count"
            v-if="computedNotReadCommentCount > 0"
          >
            <el-button
              type="primary"
              circle
              size="small"
              :icon="ChatDotSquare"
              @click="handleCommentClick"
            ></el-button>
          </el-badge>
          <el-button
            v-else
            type="primary"
            circle
            size="small"
            :icon="ChatDotSquare"
            @click="handleCommentClick"
          ></el-button>
        </el-badge>
      </div>
      <div v-else>
        <el-button
          v-if="
            props.projectTaskProgress !== ProjectTaskProgress.ToBeAllocated &&
            props.projectTaskProgress !== ProjectTaskProgress.Ready &&
            props.projectTaskProgress !==
              ProjectTaskProgress.AuditImplementation &&
            props.projectTaskProgress !== ProjectTaskProgress.ReportWriting &&
            props.projectTaskProgress !== ProjectTaskProgress.Complete
          "
          type="primary"
          circle
          size="small"
          :icon="ChatDotSquare"
          @click="handleCommentClick"
        ></el-button>
      </div>
    </li>
  </ul>

  <DiscoveryDescriptionSingle
    v-for="(item, index) in group1"
    :groups="group1"
    :key="item.random"
    :groupData="item"
    :data="props.data"
    :index="index"
    :discoverydGradingDics="discoverydGradingDics"
    :projectTaskDiscoveryDescriptions="props.projectTaskDiscoveryDescriptions"
    :mainCategorys="props.mainCategorys"
    :subCategorys="props.subCategorys"
    :projectTaskGuid="props.projectTaskGuid"
    :projectDiscoveryData="props.projectDiscoveryData"
    @getProjectTaskDiscoveryAll="() => emits('getProjectTaskDiscoveryAll')"
    @getCommentModuleCount="() => emits('getCommentModuleCount')"
    @getProjectTaskDiscoveryDescription="()=>emits('getProjectTaskDiscoveryDescription')"
    :projectTaskProgress="props.projectTaskProgress"
    :commentModuleCount="commentModuleCount"
    prefix="F"
    :canReview="props.canReview"
  />

  <DiscoveryDescriptionSingle
    v-for="(item, index) in group2"
    :groups="group2"
    :key="item.random"
    :groupData="item"
    :data="props.data"
    :index="index"
    :discoverydGradingDics="discoverydGradingDics"
    :projectTaskDiscoveryDescriptions="props.projectTaskDiscoveryDescriptions"
    :mainCategorys="props.mainCategorys"
    :subCategorys="props.subCategorys"
    :projectTaskGuid="props.projectTaskGuid"
    :projectDiscoveryData="props.projectDiscoveryData"
    @getProjectTaskDiscoveryAll="() => emits('getProjectTaskDiscoveryAll')"
    @getCommentModuleCount="() => emits('getCommentModuleCount')"
    @getProjectTaskDiscoveryDescription="()=>emits('getProjectTaskDiscoveryDescription')"
    :projectTaskProgress="props.projectTaskProgress"
    :commentModuleCount="commentModuleCount"
    prefix="C"
    :canReview="props.canReview"
  />
</template>
<script setup>
import {
  ref,
  computed,
  onMounted,
  watchEffect,
  defineEmits,
  defineProps,
  defineExpose,
} from "vue";
import { useStore } from "@/store/index";
import { Dics } from "@/consts/dic";
import { useI18n } from "vue-i18n";
import DiscoveryDescriptionSingle from "./_DiscoveryDescriptionSingle.vue";
import { ChatDotSquare } from "@element-plus/icons-vue";
import { ProjectTaskProgress, ProjectTaskCommentModule } from "@/consts/enum";
import pubsub from "pubsub-js";
import PubsubMessage from "@/consts/pubsubMessage";
import { nanoid } from "nanoid";

const store = useStore();
const { t } = useI18n();
const emits = defineEmits([
  "getProjectTaskDiscoveryAll",
  "getCommentModuleCount",
  "getProjectTaskDiscoveryDescription"
]);

const props = defineProps({
  data: {
    type: Object,
    default: () => undefined,
  },
  projectTaskGuid: {
    type: String,
    default: () => "",
  },
  projectDiscoveryData: {
    type: Array,
    default: () => [],
  },
  mainCategorys: {
    type: Array,
    default: () => [],
  },
  subCategorys: {
    type: Array,
    default: () => [],
  },
  projectTaskProgress: {
    type: Number,
    default: () => 0,
  },
  commentModuleCount: {
    type: Array,
    default: () => [],
  },
  canReview: {
    type: Boolean,
    default: () => false,
  },
  projectTaskDiscoveryDescriptions:{
    type: Object,
    default: ()=> undefined
  }
});

const group1 = ref([]); // 非建议分级
const group2 = ref([]); // 建议分级
const discoverydGradingDics = ref([]);

// 计算模块评论数量
const computedCommentCount = computed(() => {
  if (props.commentModuleCount && props.commentModuleCount.length > 0) {
    const findCommentModuleCountData = props.commentModuleCount.filter(
      (item) =>
        item.module === ProjectTaskCommentModule.Grading ||
        item.module === ProjectTaskCommentModule.MainCategory ||
        item.module === ProjectTaskCommentModule.SubCategory ||
        item.module === ProjectTaskCommentModule.Elaborate
    );
    if (findCommentModuleCountData && findCommentModuleCountData.length > 0) {
      let count = 0;
      findCommentModuleCountData.forEach((item) => {
        count += item.count;
      });
      return count;
    }
  }
  return 0;
});
const computedNotReadCommentCount = computed(() => {
  if (props.commentModuleCount && props.commentModuleCount.length > 0) {
    const findCommentModuleCountData = props.commentModuleCount.filter(
      (item) =>
        item.module === ProjectTaskCommentModule.Grading ||
        item.module === ProjectTaskCommentModule.MainCategory ||
        item.module === ProjectTaskCommentModule.SubCategory ||
        item.module === ProjectTaskCommentModule.Elaborate
    );
    if (findCommentModuleCountData && findCommentModuleCountData.length > 0) {
      let count = 0;
      findCommentModuleCountData.forEach((item) => {
        if (props.canReview) count += item.not_read_count_for_approver;
        else count += item.not_read_count;

        // count += item.not_read_count;
      });
      return count;
    }
  }
  return 0;
});

onMounted(() => {});

// 评论点击
const handleCommentClick = () => {
  const data = {
    projectTaskProgress: props.projectTaskProgress,
    module: ProjectTaskCommentModule.DiscoveryDescriptionAll,
    title: t("Components.Project.ProjectTask.Report1.GeneralInfomation.Title"),
  };
  pubsub.publish(PubsubMessage.ProjectTaskReportComment, data);
};
// 监听字典数据
watchEffect(() => {
  let commentGradingValue = "";
  if (store.userInfo) {
    if (store.userInfo.dics && store.userInfo.dics.length > 0) {
      // 发现主分类
      const findDiscoveryGradingDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.DiscoveryGrading
      );
      if (findDiscoveryGradingDic) {
        if (
          findDiscoveryGradingDic.items &&
          findDiscoveryGradingDic.items.length > 0
        ) {
          discoverydGradingDics.value = findDiscoveryGradingDic.items;
          commentGradingValue =
            findDiscoveryGradingDic.items[
              findDiscoveryGradingDic.items.length - 1
            ];
        } else {
          discoverydGradingDics.value = [];
        }
      } else {
        discoverydGradingDics.value = [];
      }
    }
  }
  let tempData = [];
  props.projectDiscoveryData.forEach((item) => {
    const findData = tempData.find(
      (item1) =>
        item.main_category === item1.main_category &&
        item.sub_category === item1.sub_category &&
        item.grading === item1.grading
    );
    if (!findData) {
      // 找到这个对应的字典值
      const findMainDic = props.mainCategorys.find(
        (item1) => item1.value === item.main_category
      );
      const findSubDic = props.subCategorys.find(
        (item1) => item1.value === item.sub_category
      );
      const findGradingDic = discoverydGradingDics.value.find(
        (m) => m.value === item.grading
      );
      tempData.push({
        main_category: item.main_category,
        main_category_guid: findMainDic ? findMainDic.guid : "",
        main_category_name: findMainDic ? findMainDic.name : "",
        sub_category: item.sub_category,
        sub_category_guid: findSubDic ? findSubDic.guid : "",
        sub_category_name: findSubDic ? findSubDic.name : "",
        grading: item.grading,
        grading_sort: findGradingDic ? findGradingDic.sort : 0,
        random: nanoid(),
      });
    }
  });
  // 找出非建议的分级
  const findNotCommentData = tempData.filter(
    (item) => item.grading !== commentGradingValue
  );
  // 找出建议分级
  const findCommentData = tempData.filter(
    (item) => item.grading === commentGradingValue
  );
  group1.value = findNotCommentData.sort(
    (a, b) => a.grading_sort - b.grading_sort
  );
  group2.value = findCommentData.sort(
    (a, b) => a.grading_sort - b.grading_sort
  );
});

const generateTableInfo = () => {
  const tableInfo = [];
  if (group1.value) {
    group1.value.forEach((item, index) => {
      tableInfo.push({
        table_number: "F-" + (index + 1),
        grading: item.grading,
        main_category: item.main_category,
        sub_category: item.sub_category,
      });
    });
  }
  if (group2.value) {
    group2.value.forEach((item, index) => {
      tableInfo.push({
        table_number: "C-" + (index + 1),
        grading: item.grading,
        main_category: item.main_category,
        sub_category: item.sub_category,
      });
    });
  }
  return tableInfo;
};

defineExpose({
  generateTableInfo,
});
</script>
<style scoped lang="scss">
.notread-comment-count {
  ::v-deep .el-badge__content {
    margin-top: 20px;
    font-size: 0.8em;
  }
}
.title-ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  .left-li {
    flex: 1;
  }
  .right-li {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
