<template>
  <Dialog
    width="40%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form :model="form" label-width="120px" :rules="rules" ref="formRef">
      <el-form-item :label="$t('Components.Database.AuditedParty.Name')" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item
        :label="$t('Components.Database.AuditedParty.Category')"
        prop="category"
      >
        <el-select
          v-model="form.category"
          :placeholder="t('Components.Database.AuditedParty.PleaseSelectCategory')"
          :style="{ width: '100%' }"
        >
          <el-option
            v-for="item in auditedPartyCategorys"
            :key="item.guid"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('Components.Database.AuditedParty.Qualification')"
        prop="qualification"
      >
        <el-select
          v-model="form.qualification"
          :placeholder="t('Components.Database.AuditedParty.PleaseSelectQualification')"
          :style="{ width: '100%' }"
        >
          <el-option
            v-for="item in props.qualifications"
            :key="item.guid"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('Components.Database.AuditedParty.Area')"
        prop="area"
      >
        <el-select
          v-model="form.area"
          :placeholder="t('Components.Database.AuditedParty.PleaseSelectArea')"
          :style="{ width: '100%' }"
        >
          <el-option
            v-for="item in props.areas"
            :key="item.guid"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup>
import { ref, reactive, markRaw, onMounted, defineProps, defineEmits } from "vue";
import { WarnTriangleFilled } from "@element-plus/icons-vue";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { useI18n } from "vue-i18n";
import { request_auditedparty_add, request_auditedparty_modify } from "@/api/apis";
import { ElMessageBox } from "element-plus";

const { t } = useI18n();
const props = defineProps({
  auditedPartyCategorys: {
    type: Array,
    default: () => [],
  },
  qualifications: {
    type: Array,
    default: () => [],
  },
  areas: {
    type: Array,
    default: () => [],
  },
  editData: {
    type: Object,
    default: () => {},
  },
});
const emits = defineEmits(["handleCloseDialog", "getAuditedPartyPage"]);
// 标题
const dialogTitle = ref("");
// 表单ref
const formRef = ref();
// 表单数据
const form = reactive({
  name: props.editData.name,
  area: props.editData.area,
  category: props.editData.category,
  qualification: props.editData.qualification,
});

// 表单校验规则
const rules = ref({
  name: [
    {
      required: true,
      message: t("Components.Database.AuditedParty.ValidateAuditedPartyNameRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.Database.AuditedParty.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  area: [
    {
      required: true,
      message: t("Components.Database.AuditedParty.PleaseSelectArea"),
      trigger: "change",
    },
  ],
  category: [
    {
      required: true,
      message: t("Components.Database.AuditedParty.PleaseSelectCategory"),
      trigger: "blur",
    },
  ],
  qualification: [
    {
      required: true,
      message: t("Components.Database.AuditedParty.PleaseSelectQualification"),
      trigger: "change",
    },
  ],
});

onMounted(async () => {
  if (props.editData && props.editData.guid) {
    // 代表修改
    dialogTitle.value = t(
      "Components.Database.AuditedParty.DialogAuditedPartyTitleMaintance"
    );
  } else {
    dialogTitle.value = t("Components.Database.AuditedParty.DialogAuditedPartyTitleAdd");
  }
});
// 处理提交事件
const handleCommit = async () => {
  if (!formRef.value) return;
  await formRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(
        t("Components.Database.AuditedParty.ConfirmSaveAuditedParty"),
        t("Common.ElMessageBox.OperateConfirm"),
        {
          confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
          cancelButtonText: t("Common.ElMessageBox.CancelText"),
          type: "warning",
          icon: markRaw(WarnTriangleFilled),
        }
      )
        .then(async () => {
          await saveAuditedParty();
        })
        .catch(() => {});
    }
  });
};
// 保存被稽查方数据
const saveAuditedParty = async () => {
  const trans = {
    name: form.name,
    area: form.area,
    category: form.category,
    qualification: form.qualification
  };
  if (props.editData && props.editData.guid) {
    // 修改
    await request_auditedparty_modify(props.editData.guid, trans);
  } else {
    // 新增
    await request_auditedparty_add(trans);
  }
  emits("getAuditedPartyPage");
  emits("handleCloseDialog");
};
// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};
</script>
<style scoped lang="scss"></style>
