import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '@/views/LoginIndex.vue'
import Main from '@/views/MainMain.vue'
import LockScreen from '@/views/LockScreen.vue'
import NoPage from '@/views/NoPage.vue'
import { LocalStorageKey, IsLock } from '@/consts/frame'
import md5 from 'js-md5'

import SystemMenu from '@/views/System/Menu/MenuIndex.vue'
import SystemRole from '@/views/System/Role/RoleIndex.vue'
import SystemDepartment from '@/views/System/Department/DepartmentIndex.vue'
import SystemJob from '@/views/System/Job/JobIndex.vue'
import SystemUser from '@/views/System/User/UserIndex.vue'
import SystemDictionary from '@/views/System/Dictionary/DictionaryIndex.vue'
import SystemParam from '@/views/System/SystemParam/SystemParam.vue'
import SystemExcelTemplate from '@/views/System/ExcelTemplate/ExcelTemplateIndex.vue'
import Announcement from '@/views/System/Announcement/AnnouncementIndex.vue'
import OperateLog from '@/views/Log/OperateLog/OperateLogIndex.vue'
import LoginLog from '@/views/Log/LoginLog/LoginLogIndex.vue'
import AuditedParty from '@/views/Database/AuditedParty/AuditedPartyIndex.vue'
import Client from '@/views/Database/Client/ClientIndex.vue'
import ProjectList from '@/views/Project/ProjectList/ProjectListIndex.vue'
import ProjectTask from '@/views/Project/ProjectTask/ProjectTaskIndex.vue'
import AuditSummary from '@/views/Database/AuditSummary/AuditSummaryIndex.vue'
import MineReview from '@/views/ReviewApprove/MineReview/MineReviewIndex.vue'
import Discovery from '@/components/Project/ProjectTask/DiscoveryIndex.vue'
import ReportAppendix from '@/components/Project/ProjectTask/ReportAppendix.vue'
import GenerateReport from '@/components/Project/ProjectTask/Report/MainIndex.vue'
import ProjectProgress from '@/views/Progress/Project/ProjectIndex.vue'
import ProjectTaskProgress from '@/views/Progress/ProjectTask/ProjectTaskIndex.vue'
import ProjectAndTaskProgress from '@/views/Progress/ProjectAndTask/ProjectAndTaskIndex.vue'
import ReportAndCAPA from '@/views/Progress/ReportAndCAPA/ReportAndCAPAIndex.vue'
import WorkingHours from '@/views/WorkingHours/WorkingHours/WorkingHours.vue'
import TaskCalendar from '@/views/AuditCalendar/TaskCalendar/TaskCalendarIndex.vue'
import HM from '@/views/_HM.vue'
import PersonCalendar from '@/views/AuditCalendar/PersonCalendar/PersonCalendarIndex.vue'
import AuditDiscoveryStatistics from '@/views/Statistics/AuditDiscovery/AuditDiscoveryIndex.vue'
import ProjectTaskStatistics from '@/views/Statistics/ProjectTask/ProjectTaskIndex.vue'
import { request_user_menupermission } from '@/api/apis'
import { useStore } from "@/store/index";
import { createPinia } from 'pinia';
import pubsub from "pubsub-js";
import PubsubMessage from "@/consts/pubsubMessage";

const pinia = createPinia();
const store = useStore(pinia)

// 缓存页面的写法
// {
//   path: '/test1',
//   name: 'test1',
//   component: () => import('@/views/Index.vue'),
//   children: [
//     {
//       path: '/test1/test1-1',
//       name: 'test1-1',
//       component: test11
//     },
//     {
//       path: '/test1/test1-2',
//       name: 'test1-2',
//       component: test12
//     }
//   ]
// }
// 为了防止控制端warning ，则menu 更改activeMenu处，增加了shallowRef(component)写法
const routes = [
  {
    path: '',
    name: 'root',
    redirect: '/home',
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/lock',
    name: 'lockscreen',
    component: LockScreen
  },
  {
    path: '/hmadmin',
    name: 'hmadmin',
    component: HM
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/MainIndex.vue'),
    redirect: '/home/index',
    children: [
      {
        path: '/home/index',
        name: 'index',
        component: Main
      }
    ]
  },
  {
    path: '/system',
    name: 'system',
    component: () => import('@/views/MainIndex.vue'),
    children: [
      {
        path: '/system/menu',
        name: 'system-menu',
        component: SystemMenu
      },
      {
        path: '/system/role',
        name: 'system-role',
        component: SystemRole
      },
      {
        path: '/system/department',
        name: 'system-department',
        component: SystemDepartment
      },
      {
        path: '/system/job',
        name: 'system-job',
        component: SystemJob
      },
      {
        path: '/system/user',
        name: 'system-user',
        component: SystemUser
      },
      {
        path: '/system/dictionary',
        name: 'system-dictionary',
        component: SystemDictionary
      },
      {
        path: '/system/systemparam',
        name: 'system-systemparam',
        component: SystemParam
      },
      {
        path: '/system/announcement',
        name: 'system-announcement',
        component: Announcement
      },
      {
        path: '/system/exceltemplate',
        name: 'system-exceltemplate',
        component: SystemExcelTemplate
      }
    ]
  },
  {
    path: '/log',
    name: 'log',
    component: () => import('@/views/MainIndex.vue'),
    children: [
      {
        path: '/log/operatelog',
        name: 'log-operatelog',
        component: OperateLog
      },
      {
        path: '/log/loginlog',
        name: 'log-loginlog',
        component: LoginLog
      },
    ]
  },
  {
    path: '/database',
    name: 'database',
    component: () => import('@/views/MainIndex.vue'),
    children: [
      {
        path: '/database/auditedparty',
        name: 'database-auditedparty',
        component: AuditedParty
      },
      {
        path: '/database/client',
        name: 'database-client',
        component: Client
      },
      {
        path: '/database/auditsummary',
        name: 'database-auditsummary',
        component: AuditSummary
      },
    ]
  },
  {
    path: '/project',
    name: 'project',
    component: () => import('@/views/MainIndex.vue'),
    children: [
      {
        path: '/project/projectlist',
        name: 'project-projectlist',
        component: ProjectList
      },
      {
        path: '/project/projecttask',
        name: 'project-projecttask',
        component: ProjectTask
      },
      {
        path: '/project/discovery',
        name: 'project-discovery',
        component: Discovery
      },
      {
        path: '/project/reportappendix',
        name: 'project-reportappendix',
        component: ReportAppendix
      },
      {
        path: '/project/generatereport',
        name: 'project-generatereport',
        component: GenerateReport
      }
    ]
  },
  {
    path: '/reviewapprove',
    name: 'reviewapprove',
    component: () => import('@/views/MainIndex.vue'),
    children: [
      {
        path: '/reviewapprove/minereview',
        name: 'reviewapprove-minereview',
        component: MineReview
      },
    ]
  },
  {
    path: '/progress',
    name: 'progress',
    component: () => import('@/views/MainIndex.vue'),
    children: [
      {
        path: '/progress/project',
        name: 'progress-project',
        component: ProjectProgress
      },
      {
        path: '/progress/projecttask',
        name: 'progress-projecttask',
        component: ProjectTaskProgress
      },
      {
        path: '/progress/projectandtask',
        name: 'progress-projectandtask',
        component: ProjectAndTaskProgress
      },
      {
        path: '/progress/reportandcapa',
        name: 'progress-reportandcapa',
        component: ReportAndCAPA
      },
    ]
  },
  {
    path: '/workinghours',
    name: 'workinghours',
    component: () => import('@/views/MainIndex.vue'),
    children: [
      {
        path: '/workinghours/workinghours',
        name: 'workinghours-workinghours',
        component: WorkingHours
      },
    ]
  },
  {
    path: '/auditcalendar',
    name: 'auditcanlendar',
    component: () => import('@/views/MainIndex.vue'),
    children: [
      {
        path: '/auditcalendar/taskcalendar',
        name: 'auditcanlendar-taskcalendar',
        component: TaskCalendar
      },
      {
        path: '/auditcalendar/personcalendar',
        name: 'auditcanlendar-personcalendar',
        component: PersonCalendar
      },
    ]
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('@/views/MainIndex.vue'),
    children: [
      {
        path: '/statistics/auditdiscovery',
        name: 'statistics-auditdiscovery',
        component: AuditDiscoveryStatistics
      },
      {
        path: '/statistics/taskstatistics',
        name: 'statistics-taskstatistics',
        component: ProjectTaskStatistics
      },
    ]
  },
  {
    path: '/404',
    name: '404',
    component: NoPage
  },
  {
    path: "/:catchAll(.*)",
    redirect: '/404',
    hidden: true
  }

]
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  // beforeRouteLeave(to, from, next) {
  //   // 禁止浏览器后退
  //   history.pushState(null, null, location.href);
  //   next();
  // },
  // // 禁止上一步下一步
  // scrollBehavior: () => {
  //   history.pushState(null, null, document.URL)
  //  }

})
// 路由守卫
router.beforeEach((to, from, next) => {
  // 1. 需要判断是否处于锁定状态
  if (localStorage.getItem(LocalStorageKey.IsLock) === md5(IsLock.True)) { // 处于锁定状态，且目标地址是lock地址
    if (to.fullPath === '/lock' || to.fullPath === '/login') { // 如果目标地址是lock地址则可以跳转，否则不可以；因为 从别的页面跳转到lock页面的时候，此时的isLock已经变成true了
      next()
    } else {
      next({ path: '/lock' })
    }
  } else { // 非锁定状态，然后进行下一步判断
    // 2. 需要校验 是否有 路由权限
    next()
  }
})


router.afterEach(async (to) => {
  if (!store.userMenus || store.userMenus.length === 0) {
    // 获取基础用户信息
    const result = await request_user_menupermission()
    store.setUserMenu(result);
  }
  // 先获取缓存中的
  // 判断当前要跳转的页面是否是首页
  const menuTags = localStorage.getItem(LocalStorageKey.MenuTag);
  let homePath = ''
  if (store.userMenus && store.userMenus.length > 0) {
    homePath = store.userMenus[0].menu_path
  }
  // 判断当前要跳转的页面是否是首页
  if (to.fullPath === homePath) { // 是首页
    // 判断首页是否已在缓存中
    if (menuTags) { // 缓存已经存在
      const arr = menuTags.split("|");
      const findMenu = arr.find(item => item === to.fullPath)
      if(!findMenu){ // 不存在缓存中就需要添加进去
        localStorage.setItem(LocalStorageKey.MenuTag, to.fullPath + '|' + menuTags);
      }
    } else { // 缓存不存在
      localStorage.setItem(LocalStorageKey.MenuTag, to.fullPath);
    }
  } else { // 不是首页
    // 也需要判断是否需要将首页添加进去
    if(menuTags){ // 缓存数据存在
      // 先判断首页是否存在
      const arr = menuTags.split("|");
      let newStr = menuTags
      const findHomeMenu = arr.find(item => item === homePath)
      if(!findHomeMenu){ // 首页不存在
        newStr = homePath + "|" + menuTags
      }
      if (arr.findIndex(item => item === to.fullPath) === -1) { // 判断当前跳转的是否存在
        newStr += '|' + to.fullPath
      }
      localStorage.setItem(LocalStorageKey.MenuTag, newStr);
    }else{ // 缓存数据不存在
      // 将首页添加进去
      localStorage.setItem(LocalStorageKey.MenuTag, homePath + "|" + to.fullPath);
    }
  }
  pubsub.publish(PubsubMessage.RouteChange, { menu_path: to.fullPath })
})

export { router, routes }