<template>
  <ul class="projectlist-ul" v-loading="fullLoading">
    <el-collapse-transition>
      <div v-show="isShowSearch">
        <li class="hm-search">
          <!-- 查询部分 -->
          <el-form :inline="true" :model="searchForm" label-width="100">
            <el-form-item
              :label="$t('Components.Project.ProjectList.ProjectNumber')"
            >
              <el-input
                v-model="searchForm.project_number"
                :placeholder="
                  $t('Components.Project.ProjectList.PleaseInputProjectNumber')
                "
                clearable
              />
            </el-form-item>
            <el-form-item
              :label="$t('Components.Project.ProjectList.ProjectManager')"
            >
              <el-select
                v-model="searchForm.project_manager"
                filterable
                clearable
                :placeholder="
                  t('Components.Project.ProjectList.PleaseSelectProjectManager')
                "
              >
                <el-option
                  v-for="item in users"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('Components.Project.ProjectList.Client')">
              <el-select
                v-model="searchForm.client_guid"
                filterable
                clearable
                :placeholder="
                  t('Components.Project.ProjectList.PleaseSelectClient')
                "
              >
                <el-option
                  v-for="item in clients"
                  :key="item.guid"
                  :label="item.name"
                  :value="item.guid"
                />
              </el-select>
            </el-form-item>
            <!-- 委托方类型 -->
            <el-form-item
              :label="$t('Components.Project.ProjectList.ClientCategory')"
            >
              <el-select
                v-model="searchForm.client_category"
                :placeholder="
                  t('Components.Project.ProjectList.PleaseSelectClientCategory')
                "
                clearable
              >
                <el-option
                  v-for="item in clientCateorys"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('Components.Project.ProjectList.ServiceType')"
            >
              <el-select
                v-model="searchForm.service_type"
                filterable
                clearable
                :placeholder="
                  t('Components.Project.ProjectList.PleaseSelectServiceType')
                "
              >
                <el-option
                  v-for="item in serviceTypes"
                  :key="item.guid"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <!-- 稽查类型 -->
            <el-form-item
              :label="$t('Components.Project.ProjectList.AuditType')"
            >
              <el-select
                v-model="searchForm.audit_type"
                :placeholder="
                  $t('Components.Project.ProjectList.PleaseSelectAuditType')
                "
                clearable
              >
                <el-option
                  v-for="item in auditTypes"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('Components.Project.ProjectList.Sponsor')">
              <el-input
                v-model="searchForm.sponsor"
                :placeholder="
                  $t('Components.Project.ProjectList.PleaseInputSponsor')
                "
                clearable
              />
            </el-form-item>
            <!-- 申办者类型 -->
            <el-form-item
              :label="$t('Components.Project.ProjectList.SponsorType')"
            >
              <el-select
                v-model="searchForm.sponsor_type"
                :placeholder="
                  $t('Components.Project.ProjectList.PleaseSelectSponsorType')
                "
                clearable
              >
                <el-option
                  v-for="item in sponsorTypes"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('Components.Project.ProjectList.ResearchProduct')"
            >
              <el-input
                v-model="searchForm.research_products"
                :placeholder="
                  $t(
                    'Components.Project.ProjectList.PleaseInputResearchProduct'
                  )
                "
                clearable
              />
            </el-form-item>
            <el-form-item
              :label="$t('Components.Project.ProjectList.ResearchProductType')"
            >
              <el-select
                v-model="searchForm.research_products_type"
                filterable
                clearable
                :placeholder="
                  t('Components.Project.ProjectList.PleaseSelectResearchProductType')
                "
              >
                <el-option
                  v-for="item in researchProductTypes"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('Components.Project.ProjectList.TrialStaging')"
            >
              <el-select
                v-model="searchForm.trial_staging"
                filterable
                clearable
                :placeholder="
                  t('Components.Project.ProjectList.PleaseSelectTrialStaging')
                "
              >
                <el-option
                  v-for="item in trailStagings"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('Components.Project.ProjectList.TherapeuticArea')"
            >
              <el-input
                v-model="searchForm.therapeutic_area"
                :placeholder="
                  $t(
                    'Components.Project.ProjectList.PleaseInputTherapeuticArea'
                  )
                "
                clearable
              />
            </el-form-item>
            <el-form-item
              :label="$t('Components.Project.ProjectList.Indications')"
            >
              <el-input
                v-model="searchForm.indications"
                :placeholder="
                  $t('Components.Project.ProjectList.PleaseInputIndications')
                "
                clearable
              />
            </el-form-item>
            <el-form-item
              :label="$t('Components.Project.ProjectList.TrialProgressStage')"
            >
              <el-select
                v-model="searchForm.trial_progress_stage"
                filterable
                clearable
                :placeholder="
                  t(
                    'Components.Project.ProjectList.PleaseSelectTrialProgressStage'
                  )
                "
              >
                <el-option
                  v-for="item in trailProgressStages"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <!-- <el-form-item
              :label="$t('Components.Project.ProjectList.AuditType')"
            >
              <el-select
                v-model="searchForm.audit_type"
                filterable
                clearable
                :placeholder="
                  t('Components.Project.ProjectList.PleaseSelectAuditType')
                "
              >
                <el-option
                  v-for="item in auditTypes"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('Components.Project.ProjectList.ProjectProgress')"
            >
              <el-select
                v-model="searchForm.project_progress"
                filterable
                clearable
                :placeholder="
                  t(
                    'Components.Project.ProjectList.PleaseSelectProjectProgress'
                  )
                "
              >
                <el-option
                  v-for="item in projectProgresses"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item> -->

            <el-form-item
              :label="$t('Components.Project.ProjectList.PorjectAssistance')"
            >
              <el-select
                v-model="searchForm.project_assistance"
                filterable
                clearable
                :placeholder="
                  t(
                    'Components.Project.ProjectList.PleaseSelectProjectAssistance'
                  )
                "
              >
                <el-option
                  v-for="item in users"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('Components.Project.ProjectList.CROName')">
              <el-input
                v-model="searchForm.cro_name"
                :placeholder="
                  $t('Components.Project.ProjectList.PleaseInputCroName')
                "
                clearable
              />
            </el-form-item>
            <el-form-item
              :label="t('Components.Project.ProjectList.CreateTime')"
            >
              <el-date-picker
                v-model="searchForm.time_range"
                type="daterange"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                range-separator=" - "
                :start-placeholder="
                  t('Components.Project.ProjectList.StartDate')
                "
                :end-placeholder="t('Components.Project.ProjectList.EndDate')"
              />
            </el-form-item>
            <el-form-item
              :label="$t('Components.Project.ProjectList.ProjectProgress')"
            >
              <el-select
                v-model="searchForm.project_progress"
                filterable
                clearable
                :placeholder="
                  t(
                    'Components.Project.ProjectList.PleaseSelectProjectProgress'
                  )
                "
              >
                <el-option
                  v-for="item in projectProgresses"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-button
                v-if="buttonVisible.searchButton"
                type="primary"
                @click="queryClick"
                :icon="Search"
                >{{ $t("Components.Project.ProjectList.Search1") }}</el-button
              >
              <el-button
                v-if="buttonVisible.resetButton"
                @click="resetClick"
                :icon="RefreshLeft"
                >{{ $t("Components.Project.ProjectList.Reset") }}</el-button
              >
            </el-form-item>
          </el-form>
        </li>
      </div>
    </el-collapse-transition>

    <li class="hm-operate">
      <!-- 操作部分 -->
      <ul class="operate-ul">
        <li class="left-li">
          <el-button
            v-if="buttonVisible.addButton"
            :style="{ color: 'white' }"
            :icon="Plus"
            :color="ButtonColor.Add"
            @click="
              () => {
                dialogVisible = true;
              }
            "
            >{{ $t("Components.Project.ProjectList.Add") }}</el-button
          >
          <el-button
            v-if="buttonVisible.editButton"
            :disabled="
              !(selectProjectLists && selectProjectLists.length === 1) ||
              selectProjectLists[0].progress === ProjectProgress.Complete ||
              selectProjectLists[0].progress === ProjectProgress.Cancel
            "
            :style="{ color: 'white' }"
            :icon="Edit"
            :color="ButtonColor.Edit"
            @click="handleEdit(1)"
            >{{ $t("Components.Project.ProjectList.Edit") }}</el-button
          >
          <el-button
            v-if="buttonVisible.completeButton"
            :disabled="
              !(selectProjectLists && selectProjectLists.length === 1) ||
              selectProjectLists[0].progress === ProjectProgress.Complete ||
              selectProjectLists[0].progress === ProjectProgress.Cancel
            "
            :style="{ color: 'white' }"
            :icon="Finished"
            :color="ButtonColor.Complete"
            @click="handleComplete(1)"
            >{{ $t("Components.Project.ProjectList.Complete") }}</el-button
          >
          <el-button
            v-if="buttonVisible.restartButton"
            :disabled="
              !(selectProjectLists && selectProjectLists.length === 1) ||
              selectProjectLists[0].progress === ProjectProgress.Cancel
            "
            :style="{ color: 'white' }"
            :icon="RefreshRight"
            :color="ButtonColor.Restart"
            @click="handleRestart(1)"
            >{{ $t("Components.Project.ProjectList.Restart") }}</el-button
          >
          <el-button
            v-if="buttonVisible.cancelButton"
            :disabled="
              !(selectProjectLists && selectProjectLists.length === 1) ||
              selectProjectLists[0].progress === ProjectProgress.Complete ||
              selectProjectLists[0].progress === ProjectProgress.InProgress ||
              selectProjectLists[0].progress === ProjectProgress.Cancel
            "
            @click="handleCancel(1)"
            :style="{ color: 'white' }"
            :icon="Close"
            :color="ButtonColor.Delete"
            >{{ $t("Components.Project.ProjectList.Cancel") }}</el-button
          >
          <el-button
            v-if="buttonVisible.exportButton"
            :style="{ color: 'white' }"
            :icon="Download"
            :color="ButtonColor.Export"
            @click="handleExport"
            >{{ $t("Components.Project.ProjectList.Export") }}</el-button
          >
        </li>
        <li class="right-li">
          <el-space>
            <el-tooltip
              effect="dark"
              :content="
                !isShowSearch
                  ? t('Components.Project.ProjectList.ShowSearch')
                  : t('Components.Project.ProjectList.HideSearch')
              "
              placement="top"
            >
              <el-button
                type=""
                circle
                :icon="Search"
                size="small"
                @click="() => (isShowSearch = !isShowSearch)"
              ></el-button>
            </el-tooltip>
          </el-space>
        </li>
      </ul>
    </li>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <!-- <el-table
          v-loading="tableLoading"
          :data="tableData"
          row-key="guid"
          @sort-change="handleSortChange"
          @selection-change="handleSelectionChange"
        > -->
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          row-key="guid"
          :cell-class-name="handleCellClassName"
          @selection-change="handleSelectionChange"
          @sort-change="handleSortChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            prop="project_number"
            min-width="200"
            sortable
            fixed="left"
            :label="$t('Components.Project.ProjectList.ProjectNumber')"
          >
            <template #default="scope">
              <el-text
                type="primary"
                class="text-projectnumber"
                @click="handleEdit(2, scope.row, true)"
                >{{ scope.row.project_number }}</el-text
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="project_manager_name"
            width="120"
            :label="$t('Components.Project.ProjectList.ProjectManager')"
          />
          <el-table-column
            prop="client_name"
            width="140"
            :label="$t('Components.Project.ProjectList.Client')"
          >
          </el-table-column>
          <el-table-column
            prop="client_category"
            width="140"
            :label="$t('Components.Project.ProjectList.ClientCategory')"
          >
            <template #default="scope">
              <div v-if="scope.row.client_category">
                <el-text
                  v-if="
                    computeDicItemEchoStyle(
                      Dics.System.ClientCategory,
                      scope.row.client_category
                    ) === DictionaryItemEchoStyle.Default
                  "
                  >{{
                    computeDicItem(
                      Dics.System.ClientCategory,
                      scope.row.client_category
                    )
                  }}</el-text
                >
                <el-tag
                  v-else
                  :type="
                    computeDicItemEchoStyle(
                      Dics.System.ClientCategory,
                      scope.row.client_category
                    )
                  "
                  >{{
                    computeDicItem(
                      Dics.System.ClientCategory,
                      scope.row.client_category
                    )
                  }}</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="service_type"
            width="120"
            :label="$t('Components.Project.ProjectList.ServiceType')"
          >
            <template #default="scope">
              <div v-if="scope.row.service_type">
                <el-text
                  v-if="
                    computeDicItemEchoStyle(
                      Dics.System.ServiceType,
                      scope.row.service_type
                    ) === DictionaryItemEchoStyle.Default
                  "
                  >{{
                    computeDicItem(
                      Dics.System.ServiceType,
                      scope.row.service_type
                    )
                  }}</el-text
                >
                <el-tag
                  v-else
                  :type="
                    computeDicItemEchoStyle(
                      Dics.System.ServiceType,
                      scope.row.service_type
                    )
                  "
                  >{{
                    computeDicItem(
                      Dics.System.ServiceType,
                      scope.row.service_type
                    )
                  }}</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="audit_type"
            width="120"
            :label="$t('Components.Project.ProjectList.AuditType')"
          >
            <template #default="scope">
              <div v-if="scope.row.audit_type">
                <el-text
                  v-if="
                    !computeDicItemEchoStyle(
                      Dics.System.AuditType,
                      scope.row.audit_type
                    ) ||
                    computeDicItemEchoStyle(
                      Dics.System.AuditType,
                      scope.row.audit_type
                    ) === 'default'
                  "
                  >{{
                    computeDicItem(Dics.System.AuditType, scope.row.audit_type)
                  }}</el-text
                >
                <el-tag
                  v-else
                  :type="
                    computeDicItemEchoStyle(
                      Dics.System.AuditType,
                      scope.row.audit_type
                    )
                  "
                  >{{
                    computeDicItem(Dics.System.AuditType, scope.row.audit_type)
                  }}</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="sponsor"
            width="140"
            :label="$t('Components.Project.ProjectList.Sponsor')"
          />
          <el-table-column
            prop="sponsor_type"
            width="120"
            :label="$t('Components.Project.ProjectList.SponsorType')"
          >
            <template #default="scope">
              <div v-if="scope.row.sponsor_type">
                <el-text
                  v-if="
                    !computeDicItemEchoStyle(
                      Dics.System.SponsorType,
                      scope.row.sponsor_type
                    ) ||
                    computeDicItemEchoStyle(
                      Dics.System.SponsorType,
                      scope.row.sponsor_type
                    ) === 'default'
                  "
                  >{{
                    computeDicItem(
                      Dics.System.SponsorType,
                      scope.row.sponsor_type
                    )
                  }}</el-text
                >
                <el-tag
                  v-else
                  :type="
                    computeDicItemEchoStyle(
                      Dics.System.SponsorType,
                      scope.row.sponsor_type
                    )
                  "
                  >{{
                    computeDicItem(
                      Dics.System.SponsorType,
                      scope.row.sponsor_type
                    )
                  }}</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="research_products"
            width="140"
            :label="$t('Components.Project.ProjectList.ResearchProduct')"
          />

          <el-table-column
            prop="sponsor_type"
            width="120"
            :label="$t('Components.Project.ProjectList.ResearchProductType')"
          >
            <template #default="scope">
              <div v-if="scope.row.research_products_type">
                <el-text
                  v-if="
                    !computeDicItemEchoStyle(
                      Dics.System.ResearchProductType,
                      scope.row.research_products_type
                    ) ||
                    computeDicItemEchoStyle(
                      Dics.System.ResearchProductType,
                      scope.row.research_products_type
                    ) === 'default'
                  "
                  >{{
                    computeDicItem(
                      Dics.System.ResearchProductType,
                      scope.row.research_products_type
                    )
                  }}</el-text
                >
                <el-tag
                  v-else
                  :type="
                    computeDicItemEchoStyle(
                      Dics.System.ResearchProductType,
                      scope.row.research_products_type
                    )
                  "
                  >{{
                    computeDicItem(
                      Dics.System.ResearchProductType,
                      scope.row.sponsor_type
                    )
                  }}</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="trial_staging"
            width="120"
            :label="$t('Components.Project.ProjectList.TrialStaging')"
          >
            <template #default="scope">
              <el-text
                v-if="
                  !computeDicItemEchoStyle(
                    Dics.System.TrialStaging,
                    scope.row.trial_staging
                  ) ||
                  computeDicItemEchoStyle(
                    Dics.System.TrialStaging,
                    scope.row.trial_staging
                  ) === 'default'
                "
                >{{
                  computeDicItem(
                    Dics.System.TrialStaging,
                    scope.row.trial_staging
                  )
                }}</el-text
              >
              <el-tag
                v-else
                :type="
                  computeDicItemEchoStyle(
                    Dics.System.TrialStaging,
                    scope.row.trial_staging
                  )
                "
                >{{
                  computeDicItem(
                    Dics.System.TrialStaging,
                    scope.row.trial_staging
                  )
                }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="plan_number"
            width="140"
            :label="$t('Components.Project.ProjectList.PlanNumber')"
          />
          <el-table-column
            prop="therapeutic_area"
            width="140"
            :label="$t('Components.Project.ProjectList.TherapeuticArea')"
          >
            <template #default="scope">
              <div v-if="scope.row.therapeutic_area">
                <el-text
                  v-if="
                    !computeDicItemEchoStyle(
                      Dics.System.TherapeuticArea,
                      scope.row.therapeutic_area
                    ) ||
                    computeDicItemEchoStyle(
                      Dics.System.TherapeuticArea,
                      scope.row.therapeutic_area
                    ) === 'default'
                  "
                  >{{
                    computeDicItem(
                      Dics.System.TherapeuticArea,
                      scope.row.therapeutic_area
                    )
                  }}</el-text
                >
                <el-tag
                  v-else
                  :type="
                    computeDicItemEchoStyle(
                      Dics.System.TherapeuticArea,
                      scope.row.therapeutic_area
                    )
                  "
                  >{{
                    computeDicItem(
                      Dics.System.TherapeuticArea,
                      scope.row.therapeutic_area
                    )
                  }}</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="indications"
            width="140"
            :label="$t('Components.Project.ProjectList.Indications')"
          />
          <el-table-column
            prop="trial_progress_stage"
            width="120"
            :label="$t('Components.Project.ProjectList.TrialProgressStage')"
          >
            <template #default="scope">
              <div v-if="scope.row.trial_progress_stage">
                <el-text
                  v-if="
                    !computeDicItemEchoStyle(
                      Dics.System.TrialProgressStage,
                      scope.row.trial_progress_stage
                    ) ||
                    computeDicItemEchoStyle(
                      Dics.System.TrialProgressStage,
                      scope.row.trial_progress_stage
                    ) === 'default'
                  "
                  >{{
                    computeDicItem(
                      Dics.System.TrialProgressStage,
                      scope.row.trial_progress_stage
                    )
                  }}</el-text
                >
                <el-tag
                  v-else
                  :type="
                    computeDicItemEchoStyle(
                      Dics.System.TrialProgressStage,
                      scope.row.trial_progress_stage
                    )
                  "
                  >{{
                    computeDicItem(
                      Dics.System.TrialProgressStage,
                      scope.row.trial_progress_stage
                    )
                  }}</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="audit_type"
            width="120"
            :label="$t('Components.Project.ProjectList.AuditType')"
          >
            <template #default="scope">
              <div v-if="scope.row.audit_type">
                <el-text
                  v-if="
                    !computeDicItemEchoStyle(
                      Dics.System.AuditType,
                      scope.row.audit_type
                    ) ||
                    computeDicItemEchoStyle(
                      Dics.System.AuditType,
                      scope.row.audit_type
                    ) === 'default'
                  "
                  >{{
                    computeDicItem(Dics.System.AuditType, scope.row.audit_type)
                  }}</el-text
                >
                <el-tag
                  v-else
                  :type="
                    computeDicItemEchoStyle(
                      Dics.System.AuditType,
                      scope.row.audit_type
                    )
                  "
                  >{{
                    computeDicItem(Dics.System.AuditType, scope.row.audit_type)
                  }}</el-tag
                >
              </div>
            </template>
          </el-table-column> -->
          <el-table-column
            prop="plan_audits_count"
            width="120"
            :label="$t('Components.Project.ProjectList.PlanAuditsCount')"
          />
          <el-table-column
            prop="actual_audits_count"
            width="120"
            :label="$t('Components.Project.ProjectList.ActualAuditsCount')"
          />
          <el-table-column
            prop="project_progress"
            width="120"
            fixed="right"
            :label="$t('Components.Project.ProjectList.ProjectProgress')"
          >
            <template #default="scope">
              <el-text
                v-if="scope.row.progress === ProjectProgress.ToBeAllocated"
                type="info"
                >{{
                  t("Components.Project.ProjectList.ToBeAllocated")
                }}</el-text
              >
              <el-text
                v-if="scope.row.progress === ProjectProgress.Ready"
                type="info"
                >{{ t("Components.Project.ProjectList.Ready") }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectProgress.InProgress"
                >{{ t("Components.Project.ProjectList.InProgress") }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectProgress.Complete"
                >{{ t("Components.Project.ProjectList.Complete") }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectProgress.Cancel"
                >{{ t("Components.Project.ProjectList.Cancel1") }}</el-text
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="project_assistance_name"
            width="120"
            :label="$t('Components.Project.ProjectList.PorjectAssistance')"
          />
          <el-table-column
            prop="cro_name"
            width="120"
            :label="$t('Components.Project.ProjectList.CROName')"
          />
          <el-table-column
            prop="cancel_reason"
            width="160"
            :label="$t('Components.Project.ProjectList.CancelReason')"
          />
          <el-table-column
            prop="createtime"
            width="160"
            sortable
            :label="$t('Components.Project.ProjectList.CreateTime')"
          />
          <el-table-column
            prop="remark"
            width="160"
            sortable
            :label="$t('Components.Project.ProjectList.Remark')"
          />
          <el-table-column
            :label="$t('Components.Project.ProjectList.Operate')"
            width="220"
            fixed="right"
          >
            <template #default="scope">
              <el-space wrap v-if="!scope.row.is_system">
                <el-button
                  v-if="
                    buttonVisible.editButtonLine &&
                    scope.row.progress !== ProjectProgress.Complete &&
                    scope.row.progress !== ProjectProgress.Cancel
                  "
                  :style="{ color: 'white' }"
                  :icon="Edit"
                  :color="ButtonColor.Edit"
                  size="small"
                  @click="handleEdit(2, scope.row)"
                  >{{ $t("Components.Project.ProjectList.Edit") }}</el-button
                >
                <el-button
                  v-if="
                    buttonVisible.completeButtonLine &&
                    scope.row.progress !== ProjectProgress.Complete &&
                    scope.row.progress !== ProjectProgress.Cancel
                  "
                  :style="{ color: 'white' }"
                  :icon="Finished"
                  :color="ButtonColor.Complete"
                  size="small"
                  @click="handleComplete(2, scope.row)"
                  >{{
                    $t("Components.Project.ProjectList.Complete")
                  }}</el-button
                >
                <el-button
                  v-if="
                    buttonVisible.taskButtonLine &&
                    auditServiceTypeSystemParam &&
                    auditServiceTypeSystemParam.value === scope.row.service_type
                  "
                  :style="{ color: 'white' }"
                  :icon="Operation"
                  :color="ButtonColor.Task"
                  size="small"
                  @click="handleTask(scope.row)"
                  >{{ $t("Components.Project.ProjectList.Task") }}</el-button
                >
                <el-button
                  v-if="
                    buttonVisible.restartButton &&
                    scope.row.progress === ProjectProgress.Complete &&
                    scope.row.progress !== ProjectProgress.Cancel
                  "
                  :style="{ color: 'white' }"
                  :icon="RefreshRight"
                  :color="ButtonColor.Restart"
                  size="small"
                  @click="handleRestart(2, scope.row)"
                  >{{ $t("Components.Project.ProjectList.Restart") }}</el-button
                >
                <el-button
                  v-if="
                    buttonVisible.cancelButtonLine &&
                    scope.row.progress !== ProjectProgress.Complete &&
                    scope.row.progress !== ProjectProgress.InProgress &&
                    scope.row.progress !== ProjectProgress.Cancel
                  "
                  :style="{ color: 'white' }"
                  :icon="Close"
                  :color="ButtonColor.Delete"
                  size="small"
                  @click="handleCancel(2, scope.row)"
                  >{{ $t("Components.Project.ProjectList.Cancel") }}</el-button
                >
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </li>
    </el-scrollbar>

    <li class="hm-page">
      <el-pagination
        small
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="AllPageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </li>
  </ul>
  <ProjectListModify
    v-if="dialogVisible"
    @handleCloseDialog="handleCloseDialog"
    :editData="editData"
    @getProjectlistPage="getProjectlistPage"
    :clients="clients"
    :auditTypes="auditTypes"
    :projectProgresses="projectProgresses"
    :trailStagings="trailStagings"
    :researchProductTypes="researchProductTypes"
    :sponsorTypes="sponsorTypes"
    :trailProgressStages="trailProgressStages"
    :serviceTypes="serviceTypes"
    :users="users"
    :therapeuticAreas="therapeuticAreas"
    :auditRequirementPresetText="auditRequirementPresetText"
    :projectIndications="projectIndications"
    :selectProjectLists="projectSponsors"
    :projectCROs="projectCROs"
    @getAllProjectAssociations="getAllProjectAssociations"
    :isView="isView"
  />
</template>
<script setup>
import { reactive, ref, markRaw, onMounted, watchEffect, computed } from "vue";
import {
  Plus,
  RefreshLeft,
  Edit,
  Search,
  WarnTriangleFilled,
  Download,
  Finished,
  RefreshRight,
  Close,
  Operation,
} from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";
import { AllPageSize, ButtonColor, DefaultPageSize } from "@/consts/frame";
import {
  request_project_page,
  request_project_export,
  request_client_page,
  request_user_page,
  request_project_progress,
  request_project_association_all,
} from "@/api/apis";
import ProjectListModify from "@/components/Project/ProjectList/ProjectListModify.vue";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";
import { useStore } from "@/store/index";
import { Dics } from "@/consts/dic";
import { router } from "@/router";
import { useRoute } from "vue-router";
import { SystemParamCode } from "@/consts/systemParam";

import {
  ProjectProgress,
  DictionaryItemEchoStyle,
  ProjectOperateType,
} from "@/consts/enum";

const route = useRoute();
const store = useStore();
const { t } = useI18n();
const searchForm = reactive({
  therapeutic_area: "", // 治疗领域
  indications: "", // 适应症
  research_products: "", // 研究产品
  sponsor: "", // 申办者
  cro_name: "", // cro名称
  dataflag: undefined, // 有效性
  client_guid: undefined, // 委托方
  project_number: "", // 项目编号
  audit_type: undefined,
  trial_staging: undefined, // 试验分期
  trial_progress_stage: undefined, // 试验进行阶段
  actual_audits_count: undefined, // 实际稽查次数
  project_progress: undefined, // 项目进度
  project_manager: undefined, // 项目负责人
  project_assistance: undefined, //项目协助人
  time_range: undefined, // 时间区间
  service_type: undefined, // 服务类型
  client_category: undefined, // 委托方类型
  sponsor_type: undefined, // 申办者类型
  research_products_type: undefined, // 研究产品类型
});
const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  addButton: false,
  editButton: false,
  deleteButton: false,
  editButtonLine: false,
  deleteButtonLine: false,
  dataflag: false,
  exportButton: false,
  completeButton: false,
  completeButtonLine: false,
  restartButton: false,
  restartButtonLine: false,
  cancelButton: false,
  cancelButtonLine: false,
  taskButtonLine: false,
});
// 当前页码
const currentPage = ref(1);
// 页码大小
const pageSize = ref(DefaultPageSize);
// 表格数据
const tableData = ref([]);
// 表格加载
const tableLoading = ref(false);
// 总数量
const total = ref(0);
// 编辑的数据
const editData = ref({});
// 弹窗是否可见
const dialogVisible = ref(false);
// 创建时间排序规则 如果为undefined 则没有这个参数，如果为true代表倒叙，为false代表正序
const createTimeSort = ref(undefined);
const projectNumberSort = ref(undefined);
// 排序排序规则 如果为undefined 则没有这个参数，如果为true代表倒叙，为false代表正序
const sortSort = ref(undefined);
// 选择的项目
const selectProjectLists = ref([]);
// 项目进度
const projectProgresses = ref([
  {
    label: t("Components.Project.ProjectList.ToBeAllocated"),
    value: ProjectProgress.ToBeAllocated,
  },
  {
    label: t("Components.Project.ProjectList.Ready"),
    value: ProjectProgress.Ready,
  },
  {
    label: t("Components.Project.ProjectList.InProgress"),
    value: ProjectProgress.InProgress,
  },
  {
    label: t("Components.Project.ProjectList.Complete"),
    value: ProjectProgress.Complete,
  },
  {
    label: t("Components.Project.ProjectList.Cancel1"),
    value: ProjectProgress.Cancel,
  },
]);
// 全屏加载
const fullLoading = ref(false);
const auditTypes = ref([]); // 稽查类型字典集合
const therapeuticAreas = ref([]); // 治疗领域字典集合
const trailStagings = ref([]); // 试验分期字典集合
const researchProductTypes = ref([]); // 研究产品类型字典集合
const sponsorTypes = ref([]); // 申办者类型字典集合

const trailProgressStages = ref([]); // 试验进展阶段字典集合
const serviceTypes = ref([]); // 服务类型
const auditRequirementPresetText = ref(""); // 委托方要求预置文本
const clientCateorys = ref([]); // 委托方类别
// 客户集合
const clients = ref([]);
// 用户集合
const users = ref([]);
// 是否显示搜索
const isShowSearch = ref(true);

const auditServiceTypeSystemParam = ref(undefined);
// 所有适应症
const projectIndications = ref([]);
// 所有申办者
const projectSponsors = ref([]);
// 所有CRO
const projectCROs = ref([]);

const isView = ref(false)

// 处理稽查类型字典数据
const computeDicItem = computed(() => (dic, data) => {
  switch (dic) {
    case Dics.System.AuditType: // 稽查类型
      if (auditTypes.value) {
        const findData = auditTypes.value.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.TherapeuticArea: // 治疗领域
      if (therapeuticAreas.value) {
        const findData = therapeuticAreas.value.find(
          (item) => item.value == data
        );
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.TrialStaging: // 试验分期
      if (trailStagings.value) {
        const findData = trailStagings.value.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.TrialProgressStage: // 试验进行阶段
      if (trailProgressStages.value) {
        const findData = trailProgressStages.value.find(
          (item) => item.value == data
        );
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.ServiceType: // 服务类型
      if (serviceTypes.value) {
        const findData = serviceTypes.value.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.ClientCategory: // 委托方类型
      if (clientCateorys.value) {
        const findData = clientCateorys.value.find(
          (item) => item.value == data
        );
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.SponsorType: // 申办者类型
      if (sponsorTypes.value) {
        const findData = sponsorTypes.value.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.ResearchProductType: // 研究产品类型
      if (researchProductTypes.value) {
        const findData = researchProductTypes.value.find(
          (item) => item.value == data
        );
        if (findData) {
          return findData.name;
        }
      }
      break;
  }
});

// 处理回显样式
const computeDicItemEchoStyle = computed(() => (dic, data) => {
  let echoStyle = "";
  switch (dic) {
    case Dics.System.AuditType: // 稽查类型
      if (auditTypes.value) {
        const findData = auditTypes.value.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
    case Dics.System.TherapeuticArea: // 治疗领域
      if (therapeuticAreas.value) {
        const findData = therapeuticAreas.value.find(
          (item) => item.value == data
        );
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
    case Dics.System.TrialStaging: // 试验分期
      if (trailStagings.value) {
        const findData = trailStagings.value.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
    case Dics.System.TrialProgressStage: // 试验进行阶段
      if (trailProgressStages.value) {
        const findData = trailProgressStages.value.find(
          (item) => item.value == data
        );
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
    case Dics.System.ServiceType: // 服务类型
      if (serviceTypes.value) {
        const findData = serviceTypes.value.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;

    case Dics.System.ClientCategory: // 委托方类型
      if (clientCateorys.value) {
        const findData = clientCateorys.value.find(
          (item) => item.value == data
        );
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
  }
  return !echoStyle || echoStyle === DictionaryItemEchoStyle.Primary
    ? ""
    : echoStyle;
});
onMounted(async () => {
  searchForm.project_progress =
    route.query && route.query.progress
      ? Number(route.query.progress)
      : undefined;
  getClientsAll();
  getProjectlistPage();
  getUserAll();
  getAllProjectAssociations();
});
// 分页查询项目
const getProjectlistPage = async () => {
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    therapeutic_area: searchForm.therapeutic_area,
    indications: searchForm.indications,
    research_products: searchForm.research_products,
    createTimeSort: createTimeSort.value,
    project_number: searchForm.project_number,
    client_guid: searchForm.client_guid,
    trial_staging: searchForm.trial_staging,
    research_products_type: searchForm.research_products_type,
    trial_progress_stage: searchForm.trial_progress_stage,
    audit_type: searchForm.audit_type,
    actual_audits_count: searchForm.actual_audits_count,
    project_manager: searchForm.project_manager,
    project_assistance: searchForm.project_assistance,
    start_time:
      searchForm.time_range && searchForm.time_range.length > 0
        ? searchForm.time_range[0]
        : undefined,
    end_time:
      searchForm.time_range && searchForm.time_range.length > 1
        ? searchForm.time_range[1]
        : undefined,
    project_progress: searchForm.project_progress,
    service_type: searchForm.service_type,
    sponsor: searchForm.sponsor,
    sponsor_type: searchForm.sponsor_type,
    cro_name: searchForm.cro_name,
    ProjectNumberSort: projectNumberSort.value,
    client_category: searchForm.client_category,
  };
  tableLoading.value = true;
  const result = await request_project_page(
    trans,
    () => (tableLoading.value = false)
  );
  total.value = result.total;
  tableData.value = result.rows;
  selectProjectLists.value = [];
  editData.value = {};
};
// 获取所有联想数据
const getAllProjectAssociations = async () => {
  const result = await request_project_association_all();
  projectIndications.value = result.indications;
  projectSponsors.value = result.sponsors;
  projectCROs.value = result.cros;
};
// 获取所有委托方
const getClientsAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
  };
  const result = await request_client_page(trans);
  if (result.total > 0) {
    clients.value = result.rows;
  } else {
    clients.value = [];
  }
};
// 获取所有用户
const getUserAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
    is_system: false,
  };
  const result = await request_user_page(trans);
  if (result.total > 0) {
    users.value = result.rows.map((item) => {
      return { label: item.name, value: item.guid };
    });
  } else {
    users.value = [];
  }
};

// 查询事件
const queryClick = () => {
  getProjectlistPage();
};
// 重置事件
const resetClick = () => {
  searchForm.dataflag = undefined;

  searchForm.therapeutic_area = "";
  searchForm.indications = "";
  searchForm.research_products = "";
  searchForm.sponsor = "";
  searchForm.sponsor_type = undefined,
  searchForm.cro_name = "";

  searchForm.client_guid = undefined;
  searchForm.project_number = "";
  searchForm.trial_staging = undefined;
  searchForm.research_products_type = undefined;
  searchForm.trial_progress_stage = undefined;
  searchForm.actual_audits_count = undefined;
  searchForm.project_progress = undefined;
  searchForm.project_manager = undefined;
  searchForm.project_assistance = undefined;
  searchForm.time_range = undefined;
  searchForm.audit_type = undefined;
  searchForm.service_type = undefined;
  searchForm.client_category = undefined;
  getProjectlistPage();
};
// 处理页码大小改变事件
const handleSizeChange = (size) => {
  pageSize.value = size;
  getProjectlistPage();
};
// 处理当前页改变事件
const handleCurrentChange = (index) => {
  currentPage.value = index;
  getProjectlistPage();
};
// 处理编辑事件
const handleEdit = (type, data, _isView=false) => {
  let row = undefined;
  if (type === 1) {
    // 判断是否有且仅选择了一条用户数据
    if (selectProjectLists.value.length !== 1) {
      ElMessage.warning(t("Components.Project.ProjectList.OnlyOneSelectEdit"));
      return;
    }
    row = selectProjectLists.value[0];
  } else if (type === 2) {
    row = data;
  }
  isView.value = _isView

  // 判断项目负责人是否存在下拉框中
  const findProjectManager = users.value.find(
    (item) => item.value === row.project_manager
  );
  const findProjectAssistance = users.value.find(
    (item) => item.value === row.project_assistance
  );

  editData.value = {
    guid: row.guid,
    sponsor: row.sponsor,
    client_guid: row.client_guid,
    audit_type: row.audit_type,
    plan_number: row.plan_number,
    project_number: row.project_number,
    therapeutic_area: row.therapeutic_area,
    indications: row.indications,
    research_products: row.research_products,
    trial_staging: row.trial_staging,
    scheme_name: row.scheme_name,
    trial_progress_stage: row.trial_progress_stage,
    cro_name: row.cro_name,
    plan_audits_count: row.plan_audits_count,
    audit_requirements: row.audit_requirements,
    contracted_report_delivery_time: row.contracted_report_delivery_time,
    contracted_capa_delivery_time: row.contracted_capa_delivery_time,
    audit_purpose: row.audit_purpose,
    audit_scope: row.audit_scope,
    audit_basis: row.audit_basis,
    is_capa: row.is_capa,
    project_manager: findProjectManager ? row.project_manager : "",
    project_assistance: findProjectAssistance ? row.project_assistance : "",
    service_type: row.service_type,
    service_time: [row.service_start_time, row.service_end_time],
    service_user_guids: row.service_user_guids,
    service_content: row.service_content,
    remark: row.remark,
    sponsor_type: row.sponsor_type,
    research_products_type: row.research_products_type,
    progress: row.progress
  };
  dialogVisible.value = true;
};
// 处理排序改变事件
const handleSortChange = async ({ prop, order }) => {
  if (prop === "createtime") {
    // 创建时间
    createTimeSort.value = undefined;
    projectNumberSort.value = undefined;
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      createTimeSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      createTimeSort.value = true;
    } else {
      createTimeSort.value = undefined;
    }
  } else if (prop === "project_number") {
    // 创建时间
    createTimeSort.value = undefined;
    projectNumberSort.value = undefined;
    if (order === "ascending") projectNumberSort.value = false;
    else if (order === "descending") projectNumberSort.value = true;
    else projectNumberSort.value = undefined;
  }
  await getProjectlistPage();
};
// 处理回调关闭事件
const handleCloseDialog = () => {
  dialogVisible.value = false;
  editData.value = {};
};
// 处理选择回调事件
const handleSelectionChange = (data) => {
  selectProjectLists.value = data;
};
// 导出
const handleExport = async () => {
  fullLoading.value = true;
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    therapeutic_area: searchForm.therapeutic_area,
    indications: searchForm.indications,
    research_products: searchForm.research_products,
    createTimeSort: createTimeSort.value,
    project_number: searchForm.project_number,
    client_guid: searchForm.client_guid,
    trial_staging: searchForm.trial_staging,
    research_products_type: searchForm.research_products_type,
    trial_progress_stage: searchForm.trial_progress_stage,
    audit_type: searchForm.audit_type,
    actual_audits_count: searchForm.actual_audits_count,
    project_manager: searchForm.project_manager,
    project_assistance: searchForm.project_assistance,
    start_time:
      searchForm.time_range && searchForm.time_range.length > 0
        ? searchForm.time_range[0]
        : undefined,
    end_time:
      searchForm.time_range && searchForm.time_range.length > 1
        ? searchForm.time_range[1]
        : undefined,
    project_progress: searchForm.project_progress,
    service_type: searchForm.service_type,
    sponsor: searchForm.sponsor,
    sponsor_type: searchForm.sponsor_type,
    cro_name: searchForm.cro_name,
    ProjectNumberSort: projectNumberSort.value,
    client_category: searchForm.client_category,
  };
  const result = await request_project_export(
    trans,
    true,
    () => (fullLoading.value = false)
  );
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = result;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
};
// 处理单元格的样式
const handleCellClassName = (data) => {
  if (data.column.label === t("Components.Project.ProjectList.CreateTime")) {
    // 如果是创建时间
    return "createtime-small";
  }
  return "";
};
// 处理完成
const handleComplete = async (type, data) => {
  let row = undefined;
  if (type === 1) {
    // 判断是否有且仅选择了一条用户数据
    if (selectProjectLists.value.length !== 1) {
      ElMessage.warning(
        t("Components.Project.ProjectList.OnlyOneSelectComplete")
      );
      return;
    }
    row = selectProjectLists.value[0];
  } else if (type === 2) {
    row = data;
  }
  ElMessageBox.confirm(
    t("Components.Project.ProjectList.ConfirmCompleteProjectList"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const trans = {
        operate_type: ProjectOperateType.Complete,
      };
      fullLoading.value = true;
      await request_project_progress(
        row.guid,
        trans,
        true,
        () => (fullLoading.value = false)
      );
      getProjectlistPage();
    })
    .catch(() => {});
};
// 处理重启
const handleRestart = async (type, data) => {
  let row = undefined;
  if (type === 1) {
    // 判断是否有且仅选择了一条用户数据
    if (selectProjectLists.value.length !== 1) {
      ElMessage.warning(
        t("Components.Project.ProjectList.OnlyOneSelectRestart")
      );
      return;
    }
    row = selectProjectLists.value[0];
  } else if (type === 2) {
    row = data;
  }
  ElMessageBox.confirm(
    t("Components.Project.ProjectList.ConfirmRestartProjectList"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const trans = {
        operate_type: ProjectOperateType.Restart,
      };
      fullLoading.value = true;
      await request_project_progress(
        row.guid,
        trans,
        true,
        () => (fullLoading.value = false)
      );
      getProjectlistPage();
    })
    .catch(() => {});
};
// 处理取消
const handleCancel = (type, data) => {
  let row = undefined;
  if (type === 1) {
    // 判断是否有且仅选择了一条用户数据
    if (selectProjectLists.value.length !== 1) {
      ElMessage.warning(
        t("Components.Project.ProjectList.OnlyOneSelectCancel")
      );
      return;
    }
    row = selectProjectLists.value[0];
  } else if (type === 2) {
    row = data;
  }

  ElMessageBox.prompt(t('Components.Project.ProjectList.InputCancelReason'), t('Components.Project.ProjectList.Tip'),{
    confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
    cancelButtonClass: t("Common.ElMessageBox.CancelText"),
    inputType: 'textarea',
    inputPattern: /^.+$/,
    inputErrorMessage: t('Components.Project.ProjectList.InputCancelReason')
  })
  .then(async ({ value }) => {
    const trans = {
        operate_type: ProjectOperateType.Cancel,
        cancel_reason: value
    };
    fullLoading.value = true;
    await request_project_progress(
      row.guid,
      trans,
      true,
      () => (fullLoading.value = false)
    );
    getProjectlistPage();
  })
  .catch(() => {})


  // ElMessageBox.confirm(
  //   t("Components.Project.ProjectList.ConfirmCancelProjectList"),
  //   t("Common.ElMessageBox.OperateConfirm"),
  //   {
  //     confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
  //     cancelButtonText: t("Common.ElMessageBox.CancelText"),
  //     type: "warning",
  //     icon: markRaw(WarnTriangleFilled),
  //   }
  // )
  //   .then(async () => {
  //     const trans = {
  //       operate_type: ProjectOperateType.Cancel,
  //     };
  //     fullLoading.value = true;
  //     await request_project_progress(
  //       row.guid,
  //       trans,
  //       true,
  //       () => (fullLoading.value = false)
  //     );
  //     getProjectlistPage();
  //   })
  //   .catch(() => {});
};
// 跳转任务
const handleTask = (row) => {
  router.push({ path: "/project/projecttask", query: { projectid: row.guid } });
};
// 监听菜单数据
watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectList,
      MenuButtonCodes.Project.ProjectList.ProjectListSearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectList,
      MenuButtonCodes.Project.ProjectList.ProjectListReset
    );
    // 处理新增
    buttonVisible.addButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectList,
      MenuButtonCodes.Project.ProjectList.ProjectListAdd
    );
    // 处理编辑
    buttonVisible.editButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectList,
      MenuButtonCodes.Project.ProjectList.ProjectListEdit
    );
    // // 处理删除
    // buttonVisible.deleteButton = validateMenuButton(
    //   store.userMenus,
    //   MenuCodes.Project.ProjectList,
    //   MenuButtonCodes.Project.ProjectList.AuditedPartyDelete
    // );
    // 处理编辑单行
    buttonVisible.editButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectList,
      MenuButtonCodes.Project.ProjectList.ProjectListEditLine
    );
    // 处理完成
    buttonVisible.completeButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectList,
      MenuButtonCodes.Project.ProjectList.ProjectListComplete
    );
    buttonVisible.completeButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectList,
      MenuButtonCodes.Project.ProjectList.ProjectListCompleteLine
    );
    buttonVisible.restartButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectList,
      MenuButtonCodes.Project.ProjectList.ProjectListRestart
    );
    buttonVisible.restartButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectList,
      MenuButtonCodes.Project.ProjectList.ProjectListRestartLine
    );
    buttonVisible.cancelButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectList,
      MenuButtonCodes.Project.ProjectList.ProjectListCancel
    );
    buttonVisible.cancelButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectList,
      MenuButtonCodes.Project.ProjectList.ProjectListCancelLine
    );
    // // 处理删除行
    // buttonVisible.deleteButtonLine = validateMenuButton(
    //   store.userMenus,
    //   MenuCodes.Project.ProjectList,
    //   MenuButtonCodes.Project.ProjectList.AuditedPartyDeleteLine
    // );
    // // 处理有效性
    // buttonVisible.dataflag = validateMenuButton(
    //   store.userMenus,
    //   MenuCodes.Project.ProjectList,
    //   MenuButtonCodes.Project.ProjectList.AuditedPartyDataflag
    // );
    // 处理导出
    buttonVisible.exportButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectList,
      MenuButtonCodes.Project.ProjectList.ProjectListExport
    );
    // 任务单行
    buttonVisible.taskButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectList,
      MenuButtonCodes.Project.ProjectList.ProjectListTaskLine
    );
  }
});
// 监听字典数据
watchEffect(() => {
  if (store.userInfo) {
    if (
      store.userInfo.system_params &&
      store.userInfo.system_params.length > 0
    ) {
      // 服务类型
      const findServiceType = store.userInfo.system_params.find(
        (item) => item.code === SystemParamCode.AuditServiceType
      );
      if (findServiceType) {
        auditServiceTypeSystemParam.value = findServiceType;
      }
    }
  }
});
// 监听字典数据
watchEffect(() => {
  if (store.userInfo) {
    if (store.userInfo.dics && store.userInfo.dics.length > 0) {
      // 稽查类型
      const findAuditTypeDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.AuditType
      );
      if (findAuditTypeDic) {
        if (findAuditTypeDic.items && findAuditTypeDic.items.length > 0) {
          auditTypes.value = findAuditTypeDic.items;
        } else {
          auditTypes.value = [];
        }
      } else {
        auditTypes.value = [];
      }
      // 治疗领域
      const findTherapeuticAreasDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.TherapeuticArea
      );
      if (findTherapeuticAreasDic) {
        if (
          findTherapeuticAreasDic.items &&
          findTherapeuticAreasDic.items.length > 0
        ) {
          therapeuticAreas.value = findTherapeuticAreasDic.items;
        } else {
          therapeuticAreas.value = [];
        }
      } else {
        therapeuticAreas.value = [];
      }
      // 试验分期
      const findTrailStagingDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.TrialStaging
      );
      if (findTrailStagingDic) {
        if (findTrailStagingDic.items && findTrailStagingDic.items.length > 0) {
          trailStagings.value = findTrailStagingDic.items;
        } else {
          trailStagings.value = [];
        }
      } else {
        trailStagings.value = [];
      }
      // 研究产品类型
      const findResearchProductTypeDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.ResearchProductType
      );
      if (findResearchProductTypeDic) {
        if (
          findResearchProductTypeDic.items &&
          findResearchProductTypeDic.items.length > 0
        ) {
          researchProductTypes.value = findResearchProductTypeDic.items;
        } else {
          researchProductTypes.value = [];
        }
      } else {
        researchProductTypes.value = [];
      }
      // 申办者类型
      const findSponsorTypeDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.SponsorType
      );
      if (findSponsorTypeDic) {
        if (findSponsorTypeDic.items && findSponsorTypeDic.items.length > 0) {
          sponsorTypes.value = findSponsorTypeDic.items;
        } else {
          sponsorTypes.value = [];
        }
      } else {
        sponsorTypes.value = [];
      }
      // 试验进展阶段
      const findTrailProgressStageDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.TrialProgressStage
      );
      if (findTrailProgressStageDic) {
        if (
          findTrailProgressStageDic.items &&
          findTrailProgressStageDic.items.length > 0
        ) {
          trailProgressStages.value = findTrailProgressStageDic.items;
        } else {
          trailProgressStages.value = [];
        }
      } else {
        trailProgressStages.value = [];
      }
      // 委托方类型
      const findClientCategoryDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.ClientCategory
      );
      if (findClientCategoryDic) {
        if (
          findClientCategoryDic.items &&
          findClientCategoryDic.items.length > 0
        ) {
          clientCateorys.value = findClientCategoryDic.items;
        } else {
          clientCateorys.value = [];
        }
      } else {
        clientCateorys.value = [];
      }
      // 服务类型
      const findServiceTypeDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.ServiceType
      );
      if (findServiceTypeDic) {
        if (findServiceTypeDic.items && findServiceTypeDic.items.length > 0) {
          serviceTypes.value = findServiceTypeDic.items;
        } else {
          serviceTypes.value = [];
        }
      } else {
        serviceTypes.value = [];
      }
      // 预置文本
      const findPresetTextDic = store.userInfo.dics.find(
        (item) => item.code === Dics.PresetText.Title
      );
      if (findPresetTextDic) {
        if (findPresetTextDic.items && findPresetTextDic.items.length > 0) {
          const findItem = findPresetTextDic.items.find(
            (item) => item.name === Dics.PresetText.ClientAuditRequirements
          );
          if (findItem) {
            auditRequirementPresetText.value = findItem.value;
          }
        } else {
          auditRequirementPresetText.value = "";
        }
      } else {
        auditRequirementPresetText.value = "";
      }
    } else {
      auditTypes.value = [];
      trailProgressStages.value = [];
      trailStagings.value = [];
    }
  }
});
</script>
<style>
.projectlist-ul .el-scrollbar__view {
  height: calc(100% - 8px);
}
.projectlist-ul .createtime-small {
  font-size: 12px !important;
}
</style>
<style scoped lang="scss">
.projectlist-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  .hm-operate {
    .operate-ul {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      width: 100%;
      .left-li {
        flex: 1;
      }
      .right-li {
        display: flex;
        align-items: center;
      }
    }
  }
  .text-projectnumber {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
