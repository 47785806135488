import { createI18n } from 'vue-i18n'
import zhCN from './locales/zh-CN.ts'
import enUS from './locales/en-US.ts'
import {LocalStorageKey, LangName} from '@/consts/frame'

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem(LocalStorageKey.Lang) ||  LangName.ZHCN, // 默认显示语言
  messages: {
    'zh-cn': zhCN,
    'en-us': enUS
  }
})

/**
 * 1. 在模板中使用
 * {{ $t('Index.Menu.Home') }} 
 * 2. 在js中使用
 * import {useI18n} from 'vue-i18n'
 * const {t} = useI18n()
 * 
 */

export default i18n