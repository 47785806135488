<template>
  <div class="lockscreen-root">
    <div class="lockscreen-div">
      <div class="lockscreen-header">
        <img src="~@/assets/images/lock.png" />
      </div>
      <div class="lockscreen-name" v-if="userName">
        <h3>{{ userName }}</h3>
      </div>
      <div class="lockscreen-content">
        <el-space fill>
          <el-input
            type="password"
            v-model="password"
            :placeholder="$t('Components.LockScreen.InputPassword')"
            @keyup.enter="handleUnlock"
          >
            <template #prepend>
              <el-button :icon="Lock" />
            </template>
          </el-input>
          <span class="relogin-span" @click="handleExit2Login">{{
            $t("Components.LockScreen.ExitLockToLogin")
          }}</span>
          <el-button
            :icon="Lock"
            type="primary"
            :style="{ width: '100%' }"
            @click="handleUnlock"
            >{{ $t("Components.LockScreen.Unlock") }}</el-button
          >
        </el-space>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from "@/store/index";
import Index from "@/views/MainIndex.vue";
import { useRouter } from "vue-router";
import { shallowRef, ref, onMounted } from "vue";
import { Lock } from "@element-plus/icons-vue";
import { LocalStorageKey, IsLock } from "@/consts/frame";
import { ElMessage, localeContextKey } from "element-plus";
import { useI18n } from "vue-i18n";
import md5 from "js-md5";
import Login from "@/views/LoginIndex.vue";
import { routes } from "@/router";
const { t } = useI18n();
const store = useStore();
const router = useRouter();
// 密码
const password = ref("");
// 用户名称
const userName = ref("");
// 挂载
onMounted(() => {
  // 拿到账户信息
  if (store.userInfo && store.userInfo.user_info) {
    userName.value = store.userInfo.user_info.name;
  } else {
    userName.value = localStorage.getItem(LocalStorageKey.UserInfo);
  }
  // 清除缓存
  if (localStorage.getItem(LocalStorageKey.LastOperateTime)) {
    localStorage.removeItem(localeContextKey.LastOperateTime);
  }
});

// 解锁
const handleUnlock = () => {
  // 解锁需要校验缓存中的密码
  // 0. 判断token票据
  if (!localStorage.getItem(LocalStorageKey.Token)) {
    ElMessage.warning(t("Components.LockScreen.NoToken"));
  } else {
    // 1. 判断缓存中是否有密码
    if (localStorage.getItem(LocalStorageKey.Password)) {
      // 存在密码
      // 校验密码是否正确
      if (localStorage.getItem(LocalStorageKey.Password) === md5(password.value)) {
        // 密码一致，则退出锁屏
        store.setActivePage({ component: shallowRef(Index) })
        if (store.activeMenu && store.activeMenu.path) {
          // 存在缓存的数据
          router.push(store.activeMenu.path);
        } else {
          // 没有缓存数据
          const find404 = routes.filter((item) => item.path === "/404")[0];
          const activeMenu = {
            path: find404.path,
            name: find404.name,
            component: shallowRef(find404.component),
          };
          // 没有缓存数据，先找浏览器的缓存
          const webStorage = localStorage.getItem(LocalStorageKey.LastActiveMenuPath);
          let menuPath = "/home/index";
          if (webStorage) {
            // 存在浏览器缓存
            menuPath = webStorage;
          } else {
            localStorage.setItem(LocalStorageKey.LastActiveMenuPath, menuPath);
          }
          routes.forEach((item) => {
            // 先判断一级是否有，一级有的话就找一级的；一级没有就找children
            if (item.path === menuPath) {
              // 一级找到了
              activeMenu.path = item.path;
              activeMenu.name = item.name;
              activeMenu.component = shallowRef(item.component);
              return;
            } else {
              // 一级没有找到，找二级
              if (item.children && item.children.length > 0) {
                // 存在节点数据
                const childrenFind = item.children.filter(
                  (item1) => item1.path === menuPath
                );
                if (childrenFind && childrenFind.length > 0) {
                  activeMenu.path = childrenFind[0].path;
                  activeMenu.name = childrenFind[0].name;
                  activeMenu.component = shallowRef(childrenFind[0].component);
                  return;
                }
              }
            }
          });
          store.setActiveMenu(activeMenu)
          router.push(activeMenu.path);
        }
        localStorage.setItem(LocalStorageKey.IsLock, md5(IsLock.False));
      } else {
        ElMessage.warning(t("Components.LockScreen.PasswordNotMatch"));
      }
    } else {
      // 不存在密码，则需要提示
      ElMessage.warning(t("Components.LockScreen.UnSearchLockCache"));
    }
  }
};
// 退出锁屏并前往登录页面
const handleExit2Login = () => {
  // 清空是否锁定缓存数据
  localStorage.removeItem(LocalStorageKey.IsLock);
  // 清除密码数据
  localStorage.removeItem(LocalStorageKey.Password);
  // 清空token数据
  localStorage.removeItem(LocalStorageKey.Token);
  // 清空上一个页面
  localStorage.removeItem(LocalStorageKey.LastActiveMenuPath);
  // 清空用户信息
  store.setUserInfo(undefined)
  // 判断vuex是否有数据
  if (
    store.lastOperateTimeIntervalId &&
    store.lastOperateTimeIntervalId > 0
  ) {
    // 如果存在定时器，则需要删除
    clearInterval(store.lastOperateTimeIntervalId);
    store.setLastOperateTimeId(undefined)
  }
  if (store.lastOperateTime) {
    store.setLastOperateTime(undefined)
  }
  if (window.document.onmousedown) {
    window.document.onmousedown = null;
  }
  // 跳转到登录页面
  store.setActivePage({ component: shallowRef(Login) })
  router.push("/login");
};
</script>
<style scoped lang="scss">
.lockscreen-root {
  width: 100%;
  height: 100%;
  background-color: rgb(140, 206, 234);
  display: flex;
  justify-content: center;
  align-items: center;

  .lockscreen-div {
    padding: 20px 20px;
    width: 300px;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 8px;

    .lockscreen-header {
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 50px;
        width: 50px;
      }
    }

    .lockscreen-name {
      display: flex;
      justify-content: center;
    }

    .lockscreen-content {
      .relogin-span {
        font-size: 12px;
        padding: 10px;
        display: flex;
        justify-content: center;
        color: #409eff;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
