<template>
  <ul class="minereview-ul" v-loading="fullLoading">
    <li class="hm-search">
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item
          :label="$t('Components.ReviewApprove.MineReview.ProjectNumber')"
        >

          <el-select
            v-model="searchForm.project_number"
            :placeholder="
              $t(
                'Components.ReviewApprove.MineReview.PleaseISelectProjectNumber'
              )
            "
            clearable
            @change="handleProjectChange"
            filterable
          >
            <el-option
              v-for="item in allProjects"
              :key="item.guid"
              :label="item.project_number"
              :value="item.guid"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('Components.ReviewApprove.MineReview.ProjectTaskNumber')"
        >
          <el-select
            v-model="searchForm.project_task_number"
            :placeholder="
              $t(
                'Components.ReviewApprove.MineReview.PleaseSelectProjectTaskNumber'
              )
            "
            clearable
          >
            <el-option
              v-for="item in projectAllProjectTasks"
              :key="item.guid"
              :label="item.number"
              :value="item.guid"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('Components.ReviewApprove.MineReview.AuditedParty')"
        >
          <el-select
            v-model="searchForm.audited_party_guid"
            :placeholder="
              $t('Components.ReviewApprove.MineReview.PleaseSelectAuditParty')
            "
            clearable
          >
            <el-option
              v-for="item in auditedPartys"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('Components.ReviewApprove.MineReview.SubmitTime')"
        >
          <el-date-picker
            v-model="searchForm.time_range"
            type="daterange"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            range-separator=" - "
            :start-placeholder="
              t('Components.ReviewApprove.MineReview.StartDate')
            "
            :end-placeholder="t('Components.ReviewApprove.MineReview.EndDate')"
          />
        </el-form-item>
        <el-form-item
          :label="$t('Components.ReviewApprove.MineReview.Reviewer')"
        >
          <el-select
            v-model="searchForm.user_guid"

            :placeholder="
              $t('Components.ReviewApprove.MineReview.PleaseSelectReviewer')
            "
            clearable
          >
            <el-option
              v-for="item in users"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            />
          </el-select>
        </el-form-item>
        <!-- $t('Components.ReviewApprove.MineReview.ReviewApproveProgress') -->
        <el-form-item
          :label="$t('Components.ReviewApprove.MineReview.ReviewApproveProgress')"
        >
          <el-select
            v-model="searchForm.approve_progress"
            :placeholder="
              $t('Components.ReviewApprove.MineReview.PleaseSelectReviewApproveProgress')
            "
            clearable
          >
            <el-option
              v-for="item in approveProgresses"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="buttonVisible.searchButton"
            type="primary"
            :icon="Search"
            @click="getMineReviewPage"
            >{{ $t("Components.ReviewApprove.MineReview.Search") }}</el-button
          >
          <el-button
            v-if="buttonVisible.resetButton"
            @click="resetClick"
            :icon="RefreshLeft"
            >{{ $t("Components.ReviewApprove.MineReview.Reset") }}</el-button
          >
        </el-form-item>
      </el-form>
    </li>
    <li class="hm-operate">
      <!-- 操作部分 -->
      <el-button
        v-if="buttonVisible.exportButton"
        :style="{ color: 'white' }"
        :icon="Download"
        :color="ButtonColor.Export"
        @click="handleExport"
        >{{ $t("Components.ReviewApprove.MineReview.Export") }}</el-button
      >
    </li>
    <div
      :style="{
        display: 'flex',
        justifyContent: 'center',
        margin: '8px 0 0 0',
      }"
    >
      <el-radio-group
        v-model="approveReviewType"
        @change="
          (v) => {
            getMineReviewPage();
          }
        "
      >
        <el-radio-button label="1">{{
          t("Components.ReviewApprove.MineReview.WaitReview")
        }}</el-radio-button>
        <el-radio-button label="2">{{
          t("Components.ReviewApprove.MineReview.HaveReview")
        }}</el-radio-button>
        <el-radio-button label="3">{{
          t("Components.ReviewApprove.MineReview.WaitCollborationReview")
        }}</el-radio-button>
      </el-radio-group>
    </div>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <el-table v-loading="tableLoading" :data="tableData" row-key="guid" 
          @sort-change="handleSortChange">
          <el-table-column
            prop="project_number"
            :label="$t('Components.ReviewApprove.MineReview.ProjectNumber')"
            sortable
          />
          <el-table-column
            prop="project_task_number"
            :label="$t('Components.ReviewApprove.MineReview.ProjectTaskNumber')"
            sortable
          />
          <el-table-column
            prop="audited_party_name"
            :label="$t('Components.ReviewApprove.MineReview.AuditedParty')"
          />
          <el-table-column
            prop="project_task_submit_time"
            sortable
            :label="t('Components.ReviewApprove.MineReview.SubmitTime')"
          />
          <!-- <el-table-column
            prop="project_task_submit_time"
            sortable
            :label="t('Components.ReviewApprove.MineReview.SubmitTime')"
            v-if="approveReviewType == ReviewApproveType.HaveApprove"
          >
          </el-table-column> -->
          <el-table-column
            :label="t('Components.ReviewApprove.MineReview.WaiteReviewer')"
            v-if="approveReviewType == ReviewApproveType.WaitApprove"
          >
            <template #default="scope">
              <el-tag
                v-for="item in scope.row.approvers"
                :key="item.user_guid"
                :style="{ margin: '0 8px 8px 0' }"
                >{{ item.user_name }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            :label="t('Components.ReviewApprove.MineReview.Reviewer')"
            v-if="approveReviewType == ReviewApproveType.HaveApprove"
          >
            <template #default="scope">
              <el-tag
                v-for="item in scope.row.approved_users"
                :key="item.user_guid"
                :style="{ margin: '0 8px 8px 0' }"
                >{{ item.user_name }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            :label="t('Components.ReviewApprove.MineReview.WaiteReviewer')"
            v-if="approveReviewType == ReviewApproveType.Collboration"
          >
            <template #default="scope">
              <el-tag
                v-for="item in scope.row.collborations"
                :key="item.user_guid"
                :style="{ margin: '0 8px 8px 0' }"
                >{{ item.user_name }}</el-tag
              >
            </template>
          </el-table-column>

          <el-table-column
            prop="progress"
            width="120"
            :label="$t('Components.ReviewApprove.MineReview.ReviewApproveProgress')"
          >
            <template #default="scope">
              <el-text
                v-if="scope.row.progress === ProjectTaskProgress.ToBeAllocated"
                >{{
                  t("Components.Project.ProjectTask.ToBeAllocated")
                }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectTaskProgress.Ready"
                >{{ t("Components.Project.ProjectTask.Ready") }}</el-text
              >
              <el-text
                v-else-if="
                  scope.row.progress === ProjectTaskProgress.AuditImplementation
                "
                >{{
                  t("Components.Project.ProjectTask.AuditImplementation")
                }}</el-text
              >
              <el-text
                v-else-if="
                  scope.row.progress === ProjectTaskProgress.ReportWriting
                "
                >{{
                  t("Components.Project.ProjectTask.ReportWriting")
                }}</el-text
              >
              <el-text
                v-else-if="
                  scope.row.progress === ProjectTaskProgress.SubmitForReview
                "
                >{{
                  t("Components.Project.ProjectTask.SubmitForReview")
                }}</el-text
              >
              <el-text
                v-else-if="
                  scope.row.progress === ProjectTaskProgress.UnderReview
                "
                >{{ t("Components.Project.ProjectTask.UnderReview") }}</el-text
              >
              <el-text
                v-else-if="
                  scope.row.progress === ProjectTaskProgress.ReviewedAndApproved
                "
                >{{
                  t("Components.Project.ProjectTask.ReviewedAndApproved")
                }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectTaskProgress.Cancel"
                >{{ t("Components.Project.ProjectTask.Cancel") }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectTaskProgress.Complete"
                >{{ t("Components.Project.ProjectTask.ReviewedAndApproved") }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectTaskProgress.Rewrite"
                >{{ t("Components.Project.ProjectTask.Rewrite") }}</el-text
              >
              <el-text
                v-else-if="
                  scope.row.progress === ProjectTaskProgress.CAPAFinalize
                "
                >{{ t("Components.Project.ProjectTask.ReviewedAndApproved") }}</el-text
              >
            </template>
          </el-table-column>
          <el-table-column
            :label="t('Components.ReviewApprove.MineReview.Operate')"
            width="200"
            v-if="approveReviewType != ReviewApproveType.HaveApprove"
          >
            <template #default="scope">
              <el-space wrap>
                <el-button
                  v-if="
                    buttonVisible.distributeButton &&
                    approveReviewType != ReviewApproveType.Collboration &&
                    scope.row.progress ===
                      ProjectTaskProgress.SubmitForReview &&
                    scope.row.is_current
                  "
                  :style="{ color: 'white' }"
                  :icon="Edit"
                  :color="ButtonColor.Dispatch"
                  size="small"
                  @click="handleEdit(scope.row)"
                  >{{
                    $t("Components.ReviewApprove.MineReview.Distribute")
                  }}</el-button
                >
                <el-button
                  v-if="buttonVisible.reviewButton && scope.row.is_current"
                  :style="{ color: 'white' }"
                  :icon="Stamp"
                  :color="ButtonColor.Complete"
                  size="small"
                  @click="handleReview(scope.row)"
                  >{{
                    $t("Components.ReviewApprove.MineReview.Review")
                  }}</el-button
                >
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </li>
    </el-scrollbar>

    <li class="hm-page">
      <el-pagination
        small
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="AllPageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </li>
  </ul>
  <Distribute
    v-if="dialogVisible"
    @handleCloseDialog="handleCloseDialog"
    @getMineReviewPage="getMineReviewPage"
    :projectTaskGuid="projectTaskGuid"
    :editData="editData"
    :users="users"
  />
</template>
<script setup>
import { reactive, ref, onMounted, watchEffect } from "vue";
import {
  RefreshLeft,
  Edit,
  Search,
  Download,
  Stamp,
} from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import { AllPageSize, ButtonColor, DefaultPageSize } from "@/consts/frame";
import {
  request_reviewapprove_page,
  request_auditedparty_page,
  request_user_page,
  request_reviewapprove_export,
} from "@/api/apis";
import Distribute from "@/components/ReviewApprove/MineReview/DistributeIndex.vue";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";
import { useStore } from "@/store/index";
import { ProjectTaskProgress, ReviewApproveType,ApproveProgress } from "@/consts/enum";
import { router } from "@/router";
import {
  request_project_all,
  request_projecttask_all_byproject,
} from "@/api/apis";
import { useRoute } from "vue-router";

const store = useStore();
const route = useRoute();
const type = 
  (route.query && (route.query.type == ProjectTaskProgress.Rewrite || route.query.type == ProjectTaskProgress.ReviewedAndApproved)) ?ReviewApproveType.HaveApprove : ReviewApproveType.WaitApprove;

  const { t } = useI18n();
const searchForm = reactive({
  project_number: undefined,
  project_task_number: undefined,
  audited_party_guid: undefined,
  time_range: undefined, // 时间区间
  user_guid: "", //
  approve_progress: route.query ? (route.query.type ==ProjectTaskProgress.ToBeAllocated ? undefined:  route.query.type) : undefined,
});
const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  exportButton: false,
  distributeButton: false,
  reviewButton: false,
});
// 当前页码
const currentPage = ref(1);
// 页码大小
const pageSize = ref(DefaultPageSize);
// 表格数据
const tableData = ref([]);
// 表格加载
const tableLoading = ref(false);
// 总数量
const total = ref(0);
const editData = ref({});
const projectTaskGuid = ref("");
// 弹窗是否可见
const dialogVisible = ref(false);
// 全屏加载
const fullLoading = ref(false);
const auditedPartys = ref([]);
const approveReviewType = ref(type);

const users = ref([]);
// 所有项目
const allProjects = ref([]);
// 项目下的所有任务
const projectAllProjectTasks = ref([]);
const projectNumberSort = ref(undefined)
const projectTaskNumberSort = ref(undefined)
const submitTimeSort = ref(undefined);

const approveProgresses = ref([
  {name: t("Components.Project.ProjectTask.SubmitForReview"), value: `${ApproveProgress.SubmitForReview}`},
  {name: t("Components.Project.ProjectTask.UnderReview"), value: `${ApproveProgress.UnderReview}`},
  {name: t("Components.Project.ProjectTask.Rewrite"), value: `${ApproveProgress.Rewrite}`},
  {name: t("Components.Project.ProjectTask.ReviewedAndApproved"), value: `${ApproveProgress.ReviewedAndApproved}`},
])
onMounted(async () => {
  getMineReviewPage();
  getAuditedPartyAll();
  getUserAll();
  getAllProject();
});
// 分页查询我的审阅
const getMineReviewPage = async () => {
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    type: approveReviewType.value,
    project_number: searchForm.project_number,
    project_task_number: searchForm.project_task_number,
    audited_party_guid: searchForm.audited_party_guid,
    start_time:
      searchForm.time_range && searchForm.time_range.length > 0
        ? searchForm.time_range[0]
        : undefined,
    end_time:
      searchForm.time_range && searchForm.time_range.length > 1
        ? searchForm.time_range[1]
        : undefined,
    user_guid: searchForm.user_guid,
    SubmitTimeSort: submitTimeSort.value,
    ProjectNumberSort: projectNumberSort.value,
    ProjectTaskNumberSort: projectTaskNumberSort.value,
    approve_progress: searchForm.approve_progress
  };
  tableLoading.value = true;
  const result = await request_reviewapprove_page(
    trans,
    () => (tableLoading.value = false)
  );
  total.value = result.total;
  tableData.value = result.rows;
  editData.value = {};
  projectTaskGuid.value = "";
};
// 获取所有项目
const getAllProject = async () => {
  const trans = {};
  const result = await request_project_all(trans);
  allProjects.value = result;
};

// 获取所有被稽查方
const getAuditedPartyAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
  };
  const result = await request_auditedparty_page(trans);
  if (result.total > 0) {
    auditedPartys.value = result.rows;
  } else {
    auditedPartys.value = [];
  }
};
// 重置事件
const resetClick = () => {
  searchForm.user_guid = undefined;
  searchForm.project_number = undefined;
  searchForm.project_task_number = undefined;
  searchForm.audited_party_guid = undefined;
  searchForm.time_range = undefined;
  getMineReviewPage();
};
// 处理页码大小改变事件
const handleSizeChange = (size) => {
  pageSize.value = size;
  getMineReviewPage();
};
// 处理当前页改变事件
const handleCurrentChange = (index) => {
  currentPage.value = index;
  getMineReviewPage();
};
// 处理编辑事件
const handleEdit = (row) => {
  editData.value = {
    guid: row.guid,
    user_guid: row.user_guid,
    approvers: row.approvers,
    collborations: row.collborations,
  };
  projectTaskGuid.value = row.guid;
  dialogVisible.value = true;
};
// 处理回调关闭事件
const handleCloseDialog = () => {
  dialogVisible.value = false;
  editData.value = {};
  projectTaskGuid.value = "";
};
// 导出
const handleExport = async () => {
  fullLoading.value = true;
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    type: approveReviewType.value,
    project_number: searchForm.project_number,
    project_task_number: searchForm.project_task_number,
    audited_party_guid: searchForm.audited_party_guid,
    start_time:
      searchForm.time_range && searchForm.time_range.length > 0
        ? searchForm.time_range[0]
        : undefined,
    end_time:
      searchForm.time_range && searchForm.time_range.length > 1
        ? searchForm.time_range[1]
        : undefined,
        user_guid: searchForm.user_guid,
        approve_progress: searchForm.approve_progress
  };
  const result = await request_reviewapprove_export(
    trans,
    true,
    () => (fullLoading.value = false)
  );
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = result;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
};
// 处理审阅点击事件
const handleReview = async (row) => {
  router.push({
    path: "/project/generatereport",
    query: { guid: row.guid },
  });
};
// 获取所有用户
const getUserAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
    is_system: false,
  };
  const result = await request_user_page(trans);
  if (result.total > 0) {
    users.value = result.rows;
  } else {
    users.value = [];
  }
};
// 处理项目改变事件
const handleProjectChange = (data) => {
  getProjectTaskByProject(data);
};
// 获取项目下的任务
const getProjectTaskByProject = async (projectGuid) => {
  const trans = {
    project_guid: projectGuid,
  };
  const result = await request_projecttask_all_byproject(trans);
  projectAllProjectTasks.value = result;
};
// 处理排序改变事件
const handleSortChange = async ({ prop, order }) => {
  projectNumberSort.value = undefined
  projectTaskNumberSort.value = undefined
  submitTimeSort.value = undefined;

  if (prop === "project_number") {
    projectNumberSort.value = undefined;
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      projectNumberSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      projectNumberSort.value = true;
    } else {
      projectNumberSort.value = undefined;
    }
  }
  if (prop === "project_task_number") {
    projectTaskNumberSort.value = undefined;
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      projectTaskNumberSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      projectTaskNumberSort.value = true;
    } else {
      projectTaskNumberSort.value = undefined;
    }
  }
  if (prop === "project_task_submit_time") {
    projectTaskNumberSort.value = undefined;
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      submitTimeSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      submitTimeSort.value = true;
    } else {
      submitTimeSort.value = undefined;
    }
  }
  await getMineReviewPage();
};
watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.ReviewApprove.MineReview,
      MenuButtonCodes.ReviewApprove.MineReview.MineReviewSearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.ReviewApprove.MineReview,
      MenuButtonCodes.ReviewApprove.MineReview.MineReviewReset
    );
    // 处理导出
    buttonVisible.exportButton = validateMenuButton(
      store.userMenus,
      MenuCodes.ReviewApprove.MineReview,
      MenuButtonCodes.ReviewApprove.MineReview.MineReviewExport
    );
    // 处理分配
    buttonVisible.distributeButton = validateMenuButton(
      store.userMenus,
      MenuCodes.ReviewApprove.MineReview,
      MenuButtonCodes.ReviewApprove.MineReview.MineReviewDistributeLine
    );
    // 处理审阅
    buttonVisible.reviewButton = validateMenuButton(
      store.userMenus,
      MenuCodes.ReviewApprove.MineReview,
      MenuButtonCodes.ReviewApprove.MineReview.MineReviewReviewLine
    );
  }
});
watchEffect(()=>{
  if(store.userInfo){
    if(store.userInfo.user_info){
      searchForm.user_guid = store.userInfo.user_info.guid
    }
  }
})
</script>
<style>
.minereview-ul .el-scrollbar__view {
  height: calc(100% - 8px);
}
</style>
<style scoped lang="scss">
.minereview-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
