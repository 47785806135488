<template>
  <el-progress
    :percentage="percent"
    :show-text="false"
    v-if="isShowProgress"
    :striped-flow="true"
  />
  <el-page-header @back="() => router.back()">
    <template #title>
      {{ t("Common.Back") }}
    </template>
  </el-page-header>
  <div class="rp-root">
    <ul class="ul-root">
      <li class="left-li">
        <el-scrollbar>
          <ul class="rp-ul">
            <li class="li-main">
              <!-- 头部 -->
              <h1>
                {{
                  t("Components.Project.ProjectTask.Report1.Header.AuditReport")
                }}
              </h1>
              <!-- 1.一般信息 -->
              <div class="section">
                <GeneralInfomation
                  :projectTaskProgress="projectTaskProgress"
                  :data="projectTask"
                  :projectTaskGuid="projectTaskGuid"
                  @getProjectTask="getProjectTask"
                  :commentModuleCount="commentModuleCount"
                  :canReview="computedCanReview"
                />
              </div>
              <!-- 2.稽查报告分发/抄送至 -->
              <div class="section">
                <Distribution
                  :projectTaskProgress="projectTaskProgress"
                  :projectTaskGuid="projectTaskGuid"
                  :users="users"
                  :data="projectTask"
                  :commentModuleCount="commentModuleCount"
                  :canReview="computedCanReview"
                />
              </div>
              <!-- 3. 内容及小结 -->
              <div class="section">
                <ContentSummary
                  :projectTaskProgress="projectTaskProgress"
                  :projectTaskGuid="projectTaskGuid"
                  :comments="comments.ContentSummaryComment"
                  :commentModuleCount="commentModuleCount"
                  :contentSummaryData="contentSummaryData"
                  :canReview="computedCanReview"
                />
              </div>
              <!-- 4. 发现等级 -->
              <div class="section">
                <DiscoveryLevel />
              </div>
              <!-- 5. 稽查发现汇总 -->
              <div class="section" v-if="showDiscovery">
                <DiscoverySummary
                  :projectTaskGuid="projectTaskGuid"
                  :discoveryCategory="discoveryCategory"
                />
              </div>
              <!-- 6. 稽查发现描述 -->
              <div class="section">
                <DiscoveryDescription
                  ref="discoveryDescriptionRef"
                  :data="projectTask"
                  :projectTaskDiscoveryDescriptions="projectTaskDiscoveryDescriptions"
                  @getProjectTaskDiscoveryDescription="getProjectTaskDiscoveryDescription"
                  :projectTaskGuid="projectTaskGuid"
                  :projectDiscoveryData="projectDiscoveryData"
                  :mainCategorys="mainCategorys"
                  :subCategorys="subCategorys"
                  @getProjectTaskDiscoveryAll="getProjectTaskDiscoveryAll"
                  @getCommentModuleCount="getCommentModuleCount"
                  :projectTaskProgress="projectTaskProgress"
                  :commentModuleCount="commentModuleCount"
                  :canReview="computedCanReview"
                />
              </div>
              <!-- 7. 报告附件 -->
              <div class="section">
                <ReportAppendix
                  :data="projectTask"
                  :projectTaskProgress="projectTaskProgress"
                  :commentModuleCount="commentModuleCount"
                  @getProjectTask="getProjectTask"
                  :canReview="computedCanReview"
                />
              </div>
            </li>
            <!-- <li class="li-comment">
          <div class="li-comment-div">12312</div>
        </li> -->
          </ul>
        </el-scrollbar>
      </li>
      <li class="right-li">
        <Common
          :projectTaskGuid="projectTaskGuid"
          :comments="comments"
          @getCommentModuleCount="getCommentModuleCount"
          :data="projectTask"
          :userInfo="cacheUserInfo"
        />
      </li>
    </ul>

    <!-- 底部 -->
    <el-affix
      position="bottom"
      :offset="40"
      v-if="computedIsShowAffix"
      class="ttt"
    >
      <div class="div-operate">
        <el-space v-if="computedCanEdit">
          <el-select
            clearable
            v-model="approvers"
            :placeholder="
              t(
                'Components.Project.ProjectTask.Report1.Bottom.PleaseSelectUser'
              )
            "
            multiple
            :multiple-limit="1"
            collapse-tags
          >
            <!-- :disabled="item.guid === cacheUserInfo.guid" -->
            <el-option
              v-for="item in users"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            ></el-option>
          </el-select>
          <el-select
            v-if="false"
            v-model="approveMode"
            :placeholder="
              t(
                'Components.Project.ProjectTask.Report1.Bottom.PleaseSelectApproveMode'
              )
            "
          >
            <el-option
              :label="
                t(
                  'Components.Project.ProjectTask.Report1.Bottom.ApproveModeAnd'
                )
              "
              :value="ProjectTaskApproveMode.And"
            ></el-option>
            <el-option
              :label="
                t('Components.Project.ProjectTask.Report1.Bottom.ApproveModeOr')
              "
              :value="ProjectTaskApproveMode.Or"
            ></el-option>
          </el-select>
          <el-button type="primary" :icon="Check" @click="handleReviewCommit">{{
            t("Components.Project.ProjectTask.Report1.Bottom.ReviewCommit")
          }}</el-button>
        </el-space>
        <el-space v-if="computedCanReview">
          <el-button
            type="danger"
            :icon="Close"
            @click="handleReview(ApproveStatus.Refuse)"
            >{{
              t("Components.Project.ProjectTask.Report1.Bottom.Reject")
            }}</el-button
          >
          <el-button
            type="primary"
            :icon="Check"
            @click="handleReview(ApproveStatus.Pass)"
            >{{
              t("Components.Project.ProjectTask.Report1.Bottom.Pass")
            }}</el-button
          >
        </el-space>
      </div>
      <div class="div-tip" v-if="computedCanReview">
        <el-space>
          <el-tooltip
            class="box-item"
            effect="dark"
            :content='t("Components.Project.ProjectTask.Report1.Header.AuditorCount")'
            placement="top"
          >
            <el-tag
              class="count-tag"
              type="danger"
              effect="dark"
              >{{computedAuditorCount}}</el-tag
            >
          </el-tooltip>
          /
          <el-tooltip
            class="box-item"
            effect="dark"
            :content='t("Components.Project.ProjectTask.Report1.Header.ApproverCount")'
            placement="top"
          >
            <el-tag
            class="count-tag"
              type="success"
              effect="dark"
              >{{computedApproverCount}}</el-tag
            >
          </el-tooltip>
        </el-space>
      </div>
    </el-affix>
  </div>
</template>
<script setup>
import {
  onMounted,
  ref,
  watchEffect,
  markRaw,
  computed,
  onBeforeUnmount,
} from "vue";
import { useRoute } from "vue-router";
import { router } from "@/router";
import { useI18n } from "vue-i18n";
import {
  request_projecttask_detail1,
  request_projecttask_discovery_page,
  request_dicionaryitem_bydiccode,
  request_user_page,
  request_projecttask_review_submit,
  request_projecttask_review_approve,
  request_project_comment_modulecount,
  request_projecttask_initreport,
  request_project_contentsummary_get2,
  request_projecttask_discoverydescription_get
} from "@/api/apis";
import { useStore } from "@/store/index";
import { Dics } from "@/consts/dic";
import GeneralInfomation from "./Common/GeneralInfomation.vue";
import Distribution from "./Common/DistributionIndex.vue";
import ContentSummary from "./Common/ContentSummary.vue";
import DiscoveryLevel from "./Common/DiscoveryLevel.vue";
import DiscoverySummary from "./Common/DiscoverySummary.vue";
import DiscoveryDescription from "./Common/DiscoveryDescription.vue";
import ReportAppendix from "./Common/ReportAppendix.vue";
import { Check, WarnTriangleFilled, Close } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  ProjectTaskProgress,
  ProjectTaskApproveMode,
  ApproveStatus,
} from "@/consts/enum";
import Common from "./Common/_Comment.vue";
import pubsub from "pubsub-js";
import PubsubMessage from "@/consts/pubsubMessage";

const store = useStore();
const { t } = useI18n();
const route = useRoute();
const projectTaskGuid = ref((route.query && route.query.guid) ?? "");
const mode = ref(0); // 0代表生成，可以添加；1：代表评审

const projectTask = ref(undefined);
const projectTaskProgress = ref(0);
// 发现分类处理
const discoveryCategory = ref([]);
// 是否展示发现评论
const showDiscovery = ref(false);
// 项目任务发现数据
const projectDiscoveryData = ref([]);
// 主分类
const mainCategorys = ref([]);
// 子分类
const subCategorys = ref([]);
// 所有用户
const users = ref([]);
// 选择的用户
const approvers = ref();
// 会签
const approveMode = ref(1);
// 当前用户缓存信息
const cacheUserInfo = ref();
// 内容小结数组
const contentSummaryData = ref([]);
// 发现描述数据
const projectTaskDiscoveryDescriptions = ref(undefined)
//
const discoveryDescriptionRef = ref();

// 计算是否显示底部操作栏
// 是否可以编辑
const computedCanEdit = computed(() => {
  return (
    projectTaskProgress.value === ProjectTaskProgress.ReportWriting ||
    projectTaskProgress.value === ProjectTaskProgress.Rewrite
  );
});
// 是否可以审阅（需要判断当前审核人中是否有自己）
const computedCanReview = computed(() => {
  if (
    projectTaskProgress.value === ProjectTaskProgress.UnderReview ||
    projectTaskProgress.value === ProjectTaskProgress.SubmitForReview
  ) {
    // 如果是可以审阅的状态
    const existCurrentUser = approvers.value.find(
      (item) => item === cacheUserInfo.value.guid
    );
    if (existCurrentUser) {
      return true;
    }
  }
  return false;
});
// 是否显示底部
const computedIsShowAffix = computed(() => {
  return computedCanEdit.value || computedCanReview.value;
});

const computedAuditorCount = computed(()=>{
  let count = 0;
  if(commentModuleCount.value && commentModuleCount.value.length > 0){
    commentModuleCount.value.forEach(item=>{
      count += item.not_read_count_for_approver
    })
  }
  return count
})
const computedApproverCount = computed(()=>{
  let count = 0;
  if(commentModuleCount.value && commentModuleCount.value.length > 0){
    commentModuleCount.value.forEach(item=>{
      count += item.approver_commit_count
    })
  }
  return count
})

const isShowProgress = ref(false);
const percentInterval = ref(undefined);
const percent = ref(0);

const comments = ref({});
// 评论模块数量
const commentModuleCount = ref([]);

onMounted(async () => {
  mode.value = route.query && route.query.mode ? Number(route.query.mode) : 0;

  await request_projecttask_initreport(projectTaskGuid.value);

  isShowProgress.value = true;
  percentInterval.value = setInterval(() => {
    if (percent.value <= 95) {
      percent.value += 1;
    } else {
      clearInterval(percentInterval.value);
    }
  }, 60);
  // 只有报告撰写状态才可初始化
  await initData();
  await getContentSummary();
  await getProjectTaskDiscoveryDescription();
  await getProjectTask();
  await getUserAll();
  getCommentModuleCount();

  percent.value = 100;
  setTimeout(() => {
    isShowProgress.value = false;
  }, 500);
  if (percentInterval.value) clearInterval(percentInterval.value);

  pubsub.subscribe(PubsubMessage.ProjectTaskReportUpdateComment, () => {
    getCommentModuleCount();
  });
});
// 获取评论模块数量
const getCommentModuleCount = async () => {
  const result = await request_project_comment_modulecount(
    projectTaskGuid.value
  );
  commentModuleCount.value = result;
  
};

// 获取项目任务 数据
const getProjectTask = async () => {
  const result = await request_projecttask_detail1(projectTaskGuid.value);
  projectTask.value = result;
  projectTaskProgress.value = result.project_task.progress;
  if (result.approvers && result.approvers.length > 0) {
    approvers.value = result.approvers.map((item) => item.user_guid);
  }
};
// 获取
const getProjectTaskDiscoveryDescription = async ()=>{
  const result = await request_projecttask_discoverydescription_get(projectTaskGuid.value)
  projectTaskDiscoveryDescriptions.value = result
}

// 获取所有发现
const getProjectTaskDiscoveryAll = async () => {
  let discoverySummaryData = [];
  mainCategorys.value.forEach((item) => {
    const findCurrentSubCategorys = subCategorys.value.filter(
      (m) => m.remark === item.value
    );
    if (findCurrentSubCategorys && findCurrentSubCategorys.length > 0) {
      findCurrentSubCategorys.forEach((item1) => {
        discoverySummaryData.push({
          abbreviation: item.remark, // 缩写
          mainCategoryGuid: item.guid, // 主分类guid
          mainCategoryName: item.name, // 主分类名称
          mainCategoryValue: item.value, // 主分类value
          subCategoryGuid: item1.guid, // 子分类guid
          subCategoryName: item1.name, // 子分类名称
          subCategoryValue: item1.value, // 子分类value
          majorCount: 0, // 重大
          mainCount: 0, // 主要
          slightCount: 0, // 轻微
        });
      });
    }
  });

  const discoveryData = await request_projecttask_discovery_page(
    projectTaskGuid.value,
    {
      pagesize: -1,
      is_import_report: true,
    }
  );
  projectDiscoveryData.value = discoveryData.rows;
  discoveryData.rows.forEach((item) => {
    const findData = discoverySummaryData.find(
      (m) =>
        item.main_category === m.mainCategoryValue &&
        item.sub_category === m.subCategoryValue
    );
    if (findData) {
      if (item.grading === "重大") {
        findData.majorCount += 1;
      } else if (item.grading === "主要") {
        findData.mainCount += 1;
      } else if (item.grading === "轻微") {
        findData.slightCount += 1;
      }
    }
  });
  discoveryCategory.value = discoverySummaryData;
};
// 初始化数据
const initData = async () => {
  // 初始化发现
  const mainCategory = await request_dicionaryitem_bydiccode({
    dic_code: Dics.System.DiscoveryMainCategory,
  });
  mainCategorys.value = mainCategory;
  const subCategory = await request_dicionaryitem_bydiccode({
    dic_code: Dics.System.DiscoverySubCategory,
  });
  subCategorys.value = subCategory;

  let discoverySummaryData = [];
  // 处理
  if (mainCategory && subCategory) {
    mainCategory.forEach((item) => {
      // 找到这个主分类下的子分类
      const findCurrentSubCategorys = subCategory.filter(
        (m) => m.remark === item.value
      );
      if (findCurrentSubCategorys && findCurrentSubCategorys.length > 0) {
        findCurrentSubCategorys.forEach((item1) => {
          discoverySummaryData.push({
            abbreviation: item.remark, // 缩写
            mainCategoryGuid: item.guid, // 主分类guid
            mainCategoryName: item.name, // 主分类名称
            mainCategoryValue: item.value, // 主分类value
            subCategoryGuid: item1.guid, // 子分类guid
            subCategoryName: item1.name, // 子分类名称
            subCategoryValue: item1.value, // 子分类value
            majorCount: 0, // 重大
            mainCount: 0, // 主要
            slightCount: 0, // 轻微
          });
        });
      }
    });
  }

  // 获取发现数据
  const discoveryData = await request_projecttask_discovery_page(
    projectTaskGuid.value,
    {
      pagesize: -1,
      is_import_report: true,
    }
  );
  projectDiscoveryData.value = discoveryData.rows;
  discoveryData.rows.forEach((item) => {
    const findData = discoverySummaryData.find(
      (m) =>
        item.main_category === m.mainCategoryValue &&
        item.sub_category === m.subCategoryValue
    );
    if (findData) {
      if (item.grading === "重大") {
        findData.majorCount += 1;
      } else if (item.grading === "主要") {
        findData.mainCount += 1;
      } else if (item.grading === "轻微") {
        findData.slightCount += 1;
      }
    }
  });
  discoveryCategory.value = discoverySummaryData;
  showDiscovery.value = true;
};
// 获取所有用户
const getUserAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
    is_system: false,
  };
  const result = await request_user_page(trans);
  if (result.total > 0) {
    users.value = result.rows;
  } else {
    users.value = [];
  }
};
// 提交评审
const handleReviewCommit = async () => {
  if (!approvers.value || approvers.value.length === 0) {
    ElMessage.warning(
      t("Components.Project.ProjectTask.Report1.Bottom.PleaseSelectUser")
    );
    return;
  }
  ElMessageBox.confirm(
    t("Components.Project.ProjectTask.Report1.Bottom.ConfirmCommit"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const trans = {
        user_guids: approvers.value,
        approve_mode: approveMode.value,
        table_infos: discoveryDescriptionRef.value.generateTableInfo(),
      };
      await request_projecttask_review_submit(projectTaskGuid.value, trans);
      router.back();
    })
    .catch(() => {});
};
// 处理评审
const handleReview = async (staus) => {
  const trans = {
    status: staus,
  };
  await request_projecttask_review_approve(projectTaskGuid.value, trans);
  // getProjectTaskDiscoveryAll()
  // router.push({ path: "/project/discovery", query: { guid: row.guid } });
  router.back();
};

onBeforeUnmount(() => {
  pubsub.unsubscribe(PubsubMessage.ProjectTaskReportUpdateComment);
});
// 获取内容小结
const getContentSummary = async () => {
  const result = await request_project_contentsummary_get2(
    projectTaskGuid.value
  );
  result.forEach((item) => {
    if (!item.content) {
      item.content = item.audit_summary_default_value;
    }
  });
  contentSummaryData.value = result;
};

watchEffect(() => {
  if (store.userInfo) {
    if (store.userInfo.user_info) {
      cacheUserInfo.value = store.userInfo.user_info;
    }
  }
});
</script>
<style>
.cc-table-row:hover {
  cursor: move;
}
.cc-table-header {
  background-color: red !important;
}
.rp-root .el-affix {
  height: 60px;
}
.rp-root .el-affix--fixed {
  padding: 0 12px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  display: flex;
  align-items: center;
}
.rp-root .el-form--inline .el-form-item {
  margin-bottom: 0;
}
.input-review-user .el-input__inner {
  cursor: pointer !important;
}
</style>
<style scoped lang="scss">
:deep(h1, h2) {
  font-weight: 500;
}
:deep(h1) {
  font-size: 1.8em;
}
:deep(h2) {
  font-size: 1.5em;
}
.rp-root {
  margin-top: 20px;
  padding: 38px;
  padding-bottom: 60px;
  height: calc(100% - 20px);
  box-sizing: border-box;
  background-color: white;

  .ul-root {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    height: 100%;
    .left-li {
      height: 100%;
      width: calc(100% - 380px);
      border-right: solid 1px #d3d3d3;
      .rp-ul {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        .li-main {
          width: calc(94%);
          margin-bottom: 60px;
          .operate-div {
            display: flex;
            justify-content: flex-end;
            padding-right: 20px;
          }
          .not-select {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
          .section {
            position: relative;
            margin-bottom: 24px;
            width: 100%;
            :deep(.table) {
              border-collapse: collapse;
              margin-bottom: 28px;
              tr {
                min-height: 54px;
                td {
                  padding: 12px;
                  line-height: 30px;
                  align-items: center;
                  border: 1px solid #000000;
                  word-break: break-all;
                }
                .label-bold {
                  font-weight: 600;
                }
              }
            }
          }
        }
        .li-comment {
          margin-left: 8px;
          width: 380px;
          background-color: #b0c4de;
        }
      }
    }
    .right-li {
      width: 380px;
      margin-left: 20px;
    }
  }
  .ttt {
    width: calc(100% - 420px);
    display: flex;
    .div-operate {
      flex: 1;
    }
    .div-tip {
      .count-tag{
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}
</style>
