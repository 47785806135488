function debounce(fn, delay) { // 某一高频事件不断被触发时，仅在最后一次真正执行事件处理代码。
  let timer = null
  return function () {
    // if (timer) {
    //   clearTimeout(timer)
    // }
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn()
    }, delay)
  }
}
// 节流
// 某一高频事件被触发时，确保在每一个特定的时间段内被执行一次。
function throttle(fn, delay) {
  //3. 通过闭包保存一个 "节流阀" 默认为false
  let temp = false;
  return function () {
    //8.触发事件被调用 判断"节流阀" 是否为true  如果为true就直接trurn出去不做任何操作
    if (temp) {
      return;
    } else {
      //4. 如果节流阀为false  立即将节流阀设置为true
      temp = true; //节流阀设置为true
      //5.  开启定时器
      setTimeout(() => {
        //6. 将外部传入的函数的执行放在setTimeout中
        fn.apply(this, arguments);
        //7. 最后在setTimeout执行完毕后再把标记'节流阀'为false(关键)  表示可以执行下一次循环了。当定时器没有执行的时候标记永远是true，在开头被return掉
        temp = false;
      }, delay);
    }
  };
}
export { debounce, throttle }


// 校验邮箱
export function validateEmail(value) {
  if (value == '' || value == undefined || value == null) {
    return true
  } else {
    return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(value)
  }

}
// 校验手机号
export function validatePhone(value) {
  const reg = /^[1][3-9][0-9]{9}$/;
  if (value == '' || value == undefined || value == null) {
    return true
  } else {
    if ((!reg.test(value)) && value != '') {
      return false
    } else {
      return true
    }
  }
}
/**
 * 校验按钮是否有权限
 * @param {缓存中按钮集合} menus 
 * @param {菜单编码} menuCode 
 * @param {按钮编码} menuButtonCode 
 * @returns 是否可见
 */
export function validateMenuButton(menus, menuCode, menuButtonCode) {
  if (menus && menus.length > 0) {
    const findCurrentMenu = menus.find(item => item.menu_code === menuCode)
    if (findCurrentMenu) {
      if (findCurrentMenu.menu_buttons && findCurrentMenu.menu_buttons.length > 0) {
        const findCurrentMenuButton = findCurrentMenu.menu_buttons.find(item => item.menu_button_code === menuButtonCode)
        if (findCurrentMenuButton)
          return true
        else
          return false
      } else {
        return false
      }
    } else {
      return false
    }
  }
  return false
}