<template>
  <Dialog
    width="60%"
    :title="dialogTitle"
    :showFooter="false"
    @handleCancle="handleCancle"
  >
    <el-button
      :icon="Plus"
      type="primary"
      @click="handleAddDispatchUser"
      v-if="buttonVisible.addButton"
      :disabled="!computedCanEdit"
      >{{ t("Components.Project.ProjectTask.DispatchUser.AddDispatchUser") }}</el-button
    >
    <el-table v-loading="tableLoading" :data="tableData" row-key="guid">
      <el-table-column
        prop="user_name"
        sortable
        :label="$t('Components.Project.ProjectTask.DispatchUser.Name')"
      >
        <template #default="scope">
          <span
            v-if="
              (!currentEditData || scope.row.guid !== currentEditData.guid) &&
              !scope.row.isAdd
            "
            >{{ scope.row.user_name }}</span
          >
          <el-select
            v-else
            :style="{ width: '90%' }"
            v-model="scope.row.user_guid"
            filterable
            clearable
            :placeholder="
              t('Components.Project.ProjectTask.DispatchUser.PleaseSelectDispatchUser')
            "
          >
            <el-option
              v-for="item in props.users"
              :key="item.guid"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        prop="role_name"
        sortable
        :label="$t('Components.Project.ProjectTask.DispatchUser.Role')"
      >
        <template #default="scope">
          <span
            v-if="
              (!currentEditData || scope.row.guid !== currentEditData.guid) &&
              !scope.row.isAdd
            "
            >{{
              computedRoleName(Dics.System.ProjectTaskAuditUserRole, scope.row.role)
            }}</span
          >
          <el-select
            v-else
            :style="{ width: '90%' }"
            v-model="scope.row.role"
            filterable
            clearable
            :placeholder="
              t(
                'Components.Project.ProjectTask.DispatchUser.PleaseSelectDispatchUserRole'
              )
            "
          >
            <el-option
              v-for="item in projectTaskAuditUserRoles"
              :key="item.guid"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </template>
      </el-table-column>

      <el-table-column
        prop="plan_start_time"
        sortable
        :label="$t('Components.Project.ProjectTask.DispatchUser.PlannedStartTime')"
      >
        <template #default="scope">
          <span
            v-if="
              (!currentEditData || scope.row.guid !== currentEditData.guid) &&
              !scope.row.isAdd
            "
            >{{ formatTime(new Date(scope.row.plan_start_time)) }}</span
          >
          <el-date-picker
            v-else
            :style="{ width: '90%' }"
            v-model="scope.row.plan_start_time"
            type="date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :placeholder="
              t('Components.Project.ProjectTask.DispatchUser.PleaseSelectPlanStartTime')
            "
          />
        </template>
      </el-table-column>

      <el-table-column
        prop="plan_end_time"
        sortable
        :label="$t('Components.Project.ProjectTask.DispatchUser.PlannedEndTime')"
      >
        <template #default="scope">
          <span
            v-if="
              (!currentEditData || scope.row.guid !== currentEditData.guid) &&
              !scope.row.isAdd
            "
            >{{ formatTime(new Date(scope.row.plan_end_time)) }}</span
          >
          <el-date-picker
            v-else
            :style="{ width: '90%' }"
            v-model="scope.row.plan_end_time"
            type="date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :placeholder="
              t('Components.Project.ProjectTask.DispatchUser.PleaseSelectPlanEndTime')
            "
          />
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('Components.Project.ProjectTask.DispatchUser.Operate')"
        width="200"
      >
        <template #default="scope">
          <el-space
            wrap
            v-if="
              (!currentEditData || currentEditData.guid !== scope.row.guid) &&
              !scope.row.isAdd
            "
          >
            <el-button
              v-if="buttonVisible.editButtonLine"
              :disabled="!computedCanEdit"
              size="small"
              :color="ButtonColor.Edit"
              :style="{ color: 'white' }"
              :icon="Edit"
              @click="
                () => {
                  currentEditData = {};
                  currentEditData.guid = scope.row.guid;
                  currentEditData.plan_end_time = scope.row.plan_end_time;
                  currentEditData.plan_start_time = scope.row.plan_start_time;
                  currentEditData.project_task_guid = scope.row.project_task_guid;
                  currentEditData.role = scope.row.role;
                  currentEditData.user_guid = scope.row.user_guid;
                  currentEditData.user_name = scope.row.user_name;
                }
              "
              >{{ t("Components.Project.ProjectTask.DispatchUser.Edit") }}</el-button
            >
            <el-button
              v-if="buttonVisible.deleteButtonLine"
              size="small"
              :disabled="!computedCanEdit"
              :color="ButtonColor.Delete"
              :style="{ color: 'white' }"
              :icon="Delete"
              @click="handleDelete(scope.row.guid)"
              >{{ t("Components.Project.ProjectTask.DispatchUser.Delete") }}</el-button
            >
          </el-space>
          <el-space v-else>
            <el-button
              size="small"
              :color="ButtonColor.Complete"
              :style="{ color: 'white' }"
              :icon="Check"
              circle
              @click="handleOk(scope.row)"
            ></el-button>
            <el-button
              size="small"
              :color="ButtonColor.Delete"
              :style="{ color: 'white' }"
              :icon="Close"
              circle
              @click="handleCancel(scope.row)"
            ></el-button>
          </el-space>
        </template>
      </el-table-column>
    </el-table>
  </Dialog>
</template>
<script setup>
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import {
  onMounted,
  markRaw,
  ref,
  computed,
  watchEffect,
  defineEmits,
  reactive,
  defineProps
} from "vue";
import { useI18n } from "vue-i18n";
import {
  request_projecttask_dispatch_user,
  request_projecttask_dispatch,
  request_projecttask_dispatch_validate,
  request_projecttask_dispatch_modify,
  request_projecttask_dispatch_delete,
} from "@/api/apis";
import { Plus, Delete, Edit, Check, Close, WarnTriangleFilled } from "@element-plus/icons-vue";
import { ButtonColor } from "@/consts/frame";
import { nanoid } from "nanoid";
import { Dics } from "@/consts/dic";
import { useStore } from "@/store/index";
import { ElMessage } from "element-plus";
import { formatTime } from "@/util/timeUtil";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";
import { ProjectTaskProgress } from "@/consts/enum";
import { ElMessageBox } from "element-plus";

const emits = defineEmits(["handleCloseDialog", "getProjectTaskPage"]);
const props = defineProps({
  projectTaskGuid: {
    type: String,
    default: () => "",
  },
  users: {
    type: Array,
    default: () => [],
  },
  projectTask: {
    type: Object,
    default: () => {},
  },
});


const store = useStore();
const { t } = useI18n();
// 标题
const dialogTitle = ref("");
const tableLoading = ref(false);
const tableData = ref([]);
// 当前编辑中的数
const currentEditData = ref(undefined);
// // 计算添加按钮是否可以点击，一旦存在一个不允许点击
// const addButtonEnable = computed(() => {
//   const findData = tableData.value.find((item) => !item.guid);
//   if (findData) {
//     return false;
//   }
//   return true;
// });
const projectTaskAuditUserRoles = ref([]);
const buttonVisible = reactive({
  addButton: false,
  editButtonLine: false,
  deleteButtonLine: false,
});
const computedRoleName = computed(() => (dic, data) => {
  switch (dic) {
    case Dics.System.ProjectTaskAuditUserRole: // 项目任务稽查人员角色
      if (projectTaskAuditUserRoles.value) {
        const findData = projectTaskAuditUserRoles.value.find(
          (item) => item.value == data
        );
        if (findData) {
          return findData.name;
        }
      }
      break;
  }
});
// 是否可以编辑
const computedCanEdit = computed(() => {
  // 只有 待分配 准备中 稽查实施 报告撰写可以 分配人员
  return (
    props.projectTask.progress !== ProjectTaskProgress.Complete && props.projectTask.progress !== ProjectTaskProgress.Cancel
  );
});

onMounted(() => {
  dialogTitle.value = t("Components.Project.ProjectTask.DispatchUser.DispatchUser");
  getDispathUser();
});
// 获取稽查人员
const getDispathUser = async () => {
  const result = await request_projecttask_dispatch_user(props.projectTaskGuid);
  tableData.value = result;
};
// 处理添加稽查人员
const handleAddDispatchUser = () => {
  const user = {
    guid: nanoid(),
    isAdd: true,
    plan_start_time: props.projectTask.planned_audit_starttime,
    plan_end_time: props.projectTask.planned_audit_endtime
  };
  tableData.value.push(user);
};

// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog", 2);
};
// 处理完成
const handleOk = async (data) => {
  // 处理数据判断
  if (!data.user_guid) {
    ElMessage.warning(
      t("Components.Project.ProjectTask.DispatchUser.PleaseSelectDispatchUser")
    );
    return;
  }
  if (!data.role) {
    ElMessage.warning(
      t("Components.Project.ProjectTask.DispatchUser.PleaseSelectDispatchUserRole")
    );
    return;
  }
  if (!data.plan_start_time) {
    ElMessage.warning(
      t("Components.Project.ProjectTask.DispatchUser.PleaseSelectPlanStartTime")
    );
    return;
  }
  if (!data.plan_end_time) {
    ElMessage.warning(
      t("Components.Project.ProjectTask.DispatchUser.PleaseSelectPlanEndTime")
    );
    return;
  }
  if (new Date(data.plan_start_time) > new Date(data.plan_end_time)) {
    ElMessage.warning(
      t("Components.Project.ProjectTask.DispatchUser.PlanStartTimeNotMoreThanPlanEndTime")
    );
    return;
  }
  // 找到这个用户数据
  const findUserData = props.users.find((item) => item.value === data.user_guid);
  if (!findUserData) {
    ElMessage.warning(
      t("Components.Project.ProjectTask.DispatchUser.UserListCannotFindUser")
    );
    return;
  }
  // 找到这个角色
  const findRoleData = projectTaskAuditUserRoles.value.find(
    (item) => item.value === data.role
  );
  if (!findRoleData) {
    ElMessage.warning(
      t("Components.Project.ProjectTask.DispatchUser.RoleListCannotFindRole")
    );
    return;
  }
  data.user_name = findUserData.label;
  data.role_name = findRoleData.name;
  const trans = {
    guid: data.guid,
    user_guid: data.user_guid,
    role: data.role,
    plan_start_time: data.plan_start_time,
    plan_end_time: data.plan_end_time,
  };
  var result = await request_projecttask_dispatch_validate(props.projectTaskGuid, trans);
  if(!result.flag){ // 有其他任务
  // 先校验是否存在其他
  ElMessageBox.confirm(
    result.message,
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    })
  .then(async () => {
    await dispatchUserInner(data, trans);
  })
  .catch(() => {});
  }else{
    dispatchUserInner(data, trans)
  }
};
const dispatchUserInner = async (data, trans)=>{
  if (data.isAdd) {
    const guid = await request_projecttask_dispatch(props.projectTaskGuid, trans);
    data.guid = guid;
    // 处理成功后的数据
    Reflect.deleteProperty(data, "isAdd");
    emits("getProjectTaskPage");
    // }
  } else {
    await request_projecttask_dispatch_modify(props.projectTaskGuid, trans);
    currentEditData.value = undefined;
  }
}
// 处理取消编辑
const handleCancel = (data) => {
  if (data.isAdd) {
    // 删除添加的数据
    tableData.value.splice(
      tableData.value.findIndex((item) => item.guid === data.guid),
      1
    );
  } else {
    // 取消编辑中的数据，需要复原
    if (currentEditData.value) {
      data.user_guid = currentEditData.value.user_guid;
      data.user_name = currentEditData.value.user_name;
      data.role = currentEditData.value.role;
      data.role_name = currentEditData.value.role_name;
      data.plan_start_time = currentEditData.value.plan_start_time;
      data.plan_end_time = currentEditData.value.plan_end_time;
      currentEditData.value = undefined;
    }
  }
};
// 处理删除
const handleDelete = async (guid) => {
  const trans = {
    guid,
  };
  await request_projecttask_dispatch_delete(props.projectTaskGuid, trans);
  // 删除添加的数据
  tableData.value.splice(
    tableData.value.findIndex((item) => item.guid === guid),
    1
  );
};

// 监听字典数据
watchEffect(() => {
  if (store.userInfo) {
    if (store.userInfo.dics && store.userInfo.dics.length > 0) {
      // 项目任务稽查人员角色
      const findDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.ProjectTaskAuditUserRole
      );
      if (findDic) {
        if (findDic.items && findDic.items.length > 0) {
          projectTaskAuditUserRoles.value = findDic.items;
        } else {
          projectTaskAuditUserRoles.value = [];
        }
      } else {
        projectTaskAuditUserRoles.value = [];
      }
    }
  }
});

// 监听菜单数据
watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.addButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskDispatchAdd
    );
    // 处理重置
    buttonVisible.editButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskDispatchEditLine
    );
    // 处理新增
    buttonVisible.deleteButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Project.ProjectTask,
      MenuButtonCodes.Project.ProjectTask.ProjectTaskDispatchDeleteLine
    );
  }
});
</script>
<style scoped lang="scss"></style>
