<template>
  <Dialog
    width="40%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form :model="form" label-width="80px" :rules="rules" ref="formRef">
      <el-form-item :label="$t('Components.System.Job.Name')" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item :label="$t('Components.System.Job.Code')" prop="code">
        <el-input v-model="form.code" />
      </el-form-item>
      <el-form-item :label="$t('Components.System.Job.Sort')" prop="sort">
        <el-input-number :style="{ width: '50%' }" v-model="form.sort" />
      </el-form-item>
      <el-form-item :label="$t('Components.System.Job.Remark')" prop="remark">
        <el-input
          v-model="form.remark"
          :rows="5"
          type="textarea"
        />
      </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup>
import { ref, reactive, markRaw, onMounted, defineProps, defineEmits } from "vue";
import { WarnTriangleFilled } from "@element-plus/icons-vue";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { useI18n } from "vue-i18n";
import { request_job_add, request_job_modify } from "@/api/apis";
import { ElMessageBox } from "element-plus";

const { t } = useI18n();
const props = defineProps({
  editData: {
    type: Object,
    default: () => {},
  },
});
const emits = defineEmits(["handleCloseDialog", "getJobPage"]);
// 标题
const dialogTitle = ref("");
// 表单ref
const formRef = ref();
// 表单数据
const form = reactive({
  name: props.editData.name,
  code: props.editData.code,
  sort: props.editData.sort || 1,
  remark: props.editData.remark
});

// 表单校验规则
const rules = ref({
  name: [
    {
      required: true,
      message: t("Components.System.Job.ValidateJobNameRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.Job.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  code: [
    {
      required: true,
      message: t("Components.System.Job.ValidateJobCodeRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.Job.ValidateMaxLength"),
      trigger: "change",
    },
  ],
});

onMounted(async () => {
  if (props.editData && props.editData.guid) {
    // 代表修改
    dialogTitle.value = t("Components.System.Job.DialogJobTitleMaintance");
  } else {
    dialogTitle.value = t("Components.System.Job.DialogJobTitleAdd");
  }
});
// 处理提交事件
const handleCommit = async () => {
  if (!formRef.value) return;
  await formRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(
        t("Components.System.Job.ConfirmSaveJob"),
        t("Common.ElMessageBox.OperateConfirm"),
        {
          confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
          cancelButtonText: t("Common.ElMessageBox.CancelText"),
          type: "warning",
          icon: markRaw(WarnTriangleFilled),
        }
      )
        .then(async () => {
          await saveJob();
        })
        .catch(() => {});
    }
  });
};
// 保存岗位数据
const saveJob = async () => {
  const trans = {
    code: form.code,
    name: form.name,
    sort: form.sort,
    remark: form.remark
  };
  if (props.editData && props.editData.guid) {
    // 修改
    await request_job_modify(props.editData.guid, trans);
  } else {
    // 新增
    await request_job_add(trans);
  }
  emits("getJobPage");
  emits("handleCloseDialog");
};
// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};
</script>
<style scoped lang="scss"></style>
