<template>
  <ul class="auditedparty-ul" v-loading="fullLoading">
    <li class="hm-search">
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item :label="$t('Components.Database.AuditedParty.Name')">
          <el-input
            v-model="searchForm.inputSearch"
            :placeholder="$t('Components.Database.AuditedParty.PleaseInputName')"
            clearable
          />
        </el-form-item>
        <el-form-item :label="$t('Components.Database.AuditedParty.Category')">
         <el-select v-model="searchForm.category" clearable :placeholder="t('Components.Database.AuditedParty.PleaseSelectCategory')">
           <el-option v-for="item in auditedPartyCategorys" :key="item.guid" :label="item.name" :value="item.value" />
         </el-select>
        </el-form-item>
        <el-form-item :label="$t('Components.Database.AuditedParty.Qualification')">
         <el-select v-model="searchForm.qualification" clearable :placeholder="t('Components.Database.AuditedParty.PleaseSelectQualification')">
           <el-option v-for="item in qualifications" :key="item.guid" :label="item.name" :value="item.value" />
         </el-select>
        </el-form-item>
        <el-form-item :label="$t('Components.Database.AuditedParty.Area')">
         <el-select v-model="searchForm.area"  clearable :placeholder="t('Components.Database.AuditedParty.PleaseSelectArea')">
           <el-option v-for="item in areas" :key="item.guid" :label="item.name" :value="item.value" />
         </el-select>
        </el-form-item>
        <el-form-item :label="$t('Components.Database.AuditedParty.Dataflag')">
          <el-select
            v-model="searchForm.dataflag"
            :placeholder="$t('Components.Database.AuditedParty.SelectDataflag')"
            clearable
          >
            <el-option
              :label="$t('Components.Database.AuditedParty.DataflagY')"
              value="true"
            />
            <el-option
              :label="$t('Components.Database.AuditedParty.DataflagN')"
              value="false"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="buttonVisible.searchButton"
            type="primary"
            @click="queryClick"
            :icon="Search"
            >{{ $t("Components.Database.AuditedParty.Search1") }}</el-button
          >
          <el-button
            v-if="buttonVisible.resetButton"
            @click="resetClick"
            :icon="RefreshLeft"
            >{{ $t("Components.Database.AuditedParty.Reset") }}</el-button
          >
        </el-form-item>
      </el-form>
    </li>
    <li class="hm-operate">
      <!-- 操作部分 -->
      <el-button
        v-if="buttonVisible.addButton"
        :style="{ color: 'white' }"
        :icon="Plus"
        :color="ButtonColor.Add"
        @click="
          () => {
            dialogVisible = true;
          }
        "
        >{{ $t("Components.Database.AuditedParty.Add") }}</el-button
      >
      <el-button
        v-if="buttonVisible.editButton"
        :disabled="!(selectAuditedPartys && selectAuditedPartys.length === 1)"
        :style="{ color: 'white' }"
        :icon="Edit"
        :color="ButtonColor.Edit"
        @click="handleEdit(1)"
        >{{ $t("Components.Database.AuditedParty.Edit") }}</el-button
      >
      <el-button
        v-if="buttonVisible.deleteButton"
        :disabled="!(selectAuditedPartys && selectAuditedPartys.length > 0)"
        :style="{ color: 'white' }"
        :icon="Delete"
        :color="ButtonColor.Delete"
        @click="handleDeleteBatch"
        >{{ $t("Components.Database.AuditedParty.Delete") }}</el-button
      >
      <el-button
        v-if="buttonVisible.exportButton"
        :style="{ color: 'white' }"
        :icon="Download"
        :color="ButtonColor.Export"
        @click="handleExport"
        >{{ $t("Components.Database.AuditedParty.Export") }}</el-button
      >
    </li>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          row-key="guid"
          @sort-change="handleSortChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            prop="name"
            :label="$t('Components.Database.AuditedParty.Name')"
          />
          <el-table-column
            prop="category"
            :label="$t('Components.Database.AuditedParty.Category')"
          >
          <template #default="scope">
              <div v-if="scope.row.category">
                <el-text
                  v-if="
                    !computeDicItemEchoStyle(
                      Dics.System.AuditedPartyCategory,
                      scope.row.category
                    ) ||
                    computeDicItemEchoStyle(
                      Dics.System.AuditedPartyCategory,
                      scope.row.category
                    ) === 'default'
                  "
                  >{{
                    computeDicItem(
                      Dics.System.AuditedPartyCategory,
                      scope.row.category
                    )
                  }}</el-text
                >
                <el-tag
                  v-else
                  :type="
                    computeDicItemEchoStyle(
                      Dics.System.AuditedPartyCategory,
                      scope.row.category
                    )
                  "
                  >{{
                    computeDicItem(
                      Dics.System.AuditedPartyCategory,
                      scope.row.category
                    )
                  }}</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="qualification"
            :label="$t('Components.Database.AuditedParty.Qualification')"
          >
          <template #default="scope">
              <div v-if="scope.row.qualification">
                <el-text
                  v-if="
                    !computeDicItemEchoStyle(
                      Dics.System.AuditedPartyQualification,
                      scope.row.qualification
                    ) ||
                    computeDicItemEchoStyle(
                      Dics.System.AuditedPartyQualification,
                      scope.row.qualification
                    ) === 'default'
                  "
                  >{{
                    computeDicItem(
                      Dics.System.AuditedPartyQualification,
                      scope.row.qualification
                    )
                  }}</el-text
                >
                <el-tag
                  v-else
                  :type="
                    computeDicItemEchoStyle(
                      Dics.System.AuditedPartyQualification,
                      scope.row.qualification
                    )
                  "
                  >{{
                    computeDicItem(
                      Dics.System.AuditedPartyQualification,
                      scope.row.qualification
                    )
                  }}</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="area"
            :label="$t('Components.Database.AuditedParty.Area')"
          >
          <template #default="scope">
              <div v-if="scope.row.area">
                <el-text
                  v-if="
                    !computeDicItemEchoStyle(
                      Dics.System.AuditedPartyArea,
                      scope.row.area
                    ) ||
                    computeDicItemEchoStyle(
                      Dics.System.AuditedPartyArea,
                      scope.row.area
                    ) === 'default'
                  "
                  >{{
                    computeDicItem(
                      Dics.System.AuditedPartyArea,
                      scope.row.area
                    )
                  }}</el-text
                >
                <el-tag
                  v-else
                  :type="
                    computeDicItemEchoStyle(
                      Dics.System.AuditedPartyArea,
                      scope.row.area
                    )
                  "
                  >{{
                    computeDicItem(
                      Dics.System.AuditedPartyArea,
                      scope.row.area
                    )
                  }}</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="dataflag"
            :label="$t('Components.Database.AuditedParty.Dataflag')"
          >
            <template #default="scope">
              <el-switch
                :disabled="!buttonVisible.dataflag || scope.row.is_system"
                v-model="scope.row.dataflag"
                @change="(flag) => handleDataflagChange(flag, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="createtime"
            sortable
            :label="$t('Components.Database.AuditedParty.CreateTime')"
          />
          <el-table-column
            :label="$t('Components.Database.AuditedParty.Operate')"
            width="200"
          >
            <template #default="scope">
              <el-space wrap v-if="!scope.row.is_system">
                <el-button
                  v-if="buttonVisible.editButtonLine"
                  :style="{ color: 'white' }"
                  :icon="Edit"
                  :color="ButtonColor.Edit"
                  size="small"
                  @click="handleEdit(2, scope.row)"
                  >{{ $t("Components.Database.AuditedParty.Edit") }}</el-button
                >
                <el-button
                  v-if="buttonVisible.deleteButtonLine"
                  :style="{ color: 'white' }"
                  :icon="Delete"
                  :color="ButtonColor.Delete"
                  size="small"
                  bg
                  @click="handleDelete(scope.row)"
                  >{{ $t("Components.Database.AuditedParty.Delete") }}</el-button
                >
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </li>
    </el-scrollbar>

    <li class="hm-page">
      <el-pagination
      small
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="AllPageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </li>
  </ul>
  <AuditedPartyModify
    v-if="dialogVisible"
    @handleCloseDialog="handleCloseDialog"
    :editData="editData"
    @getAuditedPartyPage="getAuditedPartyPage"
    :auditedPartyCategorys="auditedPartyCategorys"
    :qualifications="qualifications"
    :areas="areas"
  />
</template>
<script setup>
import { reactive, ref, markRaw, onMounted, watchEffect, computed } from "vue";
import {
  Plus,
  Delete,
  RefreshLeft,
  Edit,
  Search,
  WarnTriangleFilled,
  Download,
} from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";
import {
  AllPageSize,
  ButtonColor,
  DefaultPageSize,
} from "@/consts/frame";
import {
  request_auditedparty_page,
  request_auditedparty_delete,
  request_auditedparty_delete_batch,
  request_auditedparty_dataflag,
  request_auditedparty_export,
} from "@/api/apis";
import AuditedPartyModify from "@/components/Database/AuditedParty/AuditedPartyModify";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";
import { useStore } from "@/store/index";
import { Dics } from "@/consts/dic";
import {
  DictionaryItemEchoStyle,
} from "@/consts/enum";

const store = useStore();
const { t } = useI18n();
const searchForm = reactive({
  inputSearch: "",
  dataflag: undefined,
});
const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  addButton: false,
  editButton: false,
  deleteButton: false,
  editButtonLine: false,
  deleteButtonLine: false,
  dataflag: false,
  exportButton: false,
});
// 当前页码
const currentPage = ref(1);
// 页码大小
const pageSize = ref(DefaultPageSize);
// 表格数据
const tableData = ref([]);
// 表格加载
const tableLoading = ref(false);
// 总数量
const total = ref(0);
// 编辑的数据
const editData = ref({});
// 弹窗是否可见
const dialogVisible = ref(false);
// 创建时间排序规则 如果为undefined 则没有这个参数，如果为true代表倒叙，为false代表正序
const createTimeSort = ref(undefined);
// 排序排序规则 如果为undefined 则没有这个参数，如果为true代表倒叙，为false代表正序
const sortSort = ref(undefined);
// 选择的被稽查方
const selectAuditedPartys = ref([]);
// 全屏加载
const fullLoading = ref(false);
// 被稽查方分类
const auditedPartyCategorys = ref([]);
// 被稽查方资质集合
const qualifications = ref([]);
// 被稽查方区域集合
const areas = ref([]);

// 处理字典数据
const computeDicItem = computed(() => (dic, data) => {
  switch (dic) {
    case Dics.System.AuditedPartyCategory: // 分类
      if (auditedPartyCategorys.value) {
        const findData = auditedPartyCategorys.value.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.AuditedPartyQualification: // 资质
      if (qualifications.value) {
        const findData = qualifications.value.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        }
      }
      break;
    case Dics.System.AuditedPartyArea: // 区域
      if (areas.value) {
        const findData = areas.value.find((item) => item.value == data);
        if (findData) {
          return findData.name;
        }
      }
      break;
  }
});

// 处理回显样式
const computeDicItemEchoStyle = computed(() => (dic, data) => {
  let echoStyle = "";
  switch (dic) {
    case Dics.System.AuditedPartyCategory: // 分类
      if (auditedPartyCategorys.value) {
        const findData = auditedPartyCategorys.value.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
    case Dics.System.AuditedPartyQualification: // 资质
      if (qualifications.value) {
        const findData = qualifications.value.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
    case Dics.System.AuditedPartyArea: // 区域
      if (areas.value) {
        const findData = areas.value.find((item) => item.value == data);
        if (findData) {
          echoStyle = findData.echo_style;
        }
      }
      break;
  }
  return !echoStyle || echoStyle === DictionaryItemEchoStyle.Primary ? "" : echoStyle;
});

onMounted(async () => {
  await getAuditedPartyPage();
});
// 分页查询被稽查方
const getAuditedPartyPage = async () => {
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    text: searchForm.inputSearch,
    dataflag: searchForm.dataflag,
    createTimeSort: createTimeSort.value,
    sort: sortSort.value,
    category: searchForm.category,
    qualification: searchForm.qualification,
    area:searchForm.area
  };
  tableLoading.value = true;
  const result = await request_auditedparty_page(
    trans,
    () => (tableLoading.value = false)
  );
  total.value = result.total;
  tableData.value = result.rows;
  selectAuditedPartys.value = [];
  editData.value = {};
};

// 查询事件
const queryClick = () => {
  getAuditedPartyPage();
};
// 重置事件
const resetClick = () => {
  searchForm.dataflag = undefined;
  searchForm.inputSearch = "";
  searchForm.category = undefined;
  searchForm.qualification = undefined;
  searchForm.area = undefined;
  getAuditedPartyPage();
};
// 处理页码大小改变事件
const handleSizeChange = (size) => {
  pageSize.value = size;
  getAuditedPartyPage();
};
// 处理当前页改变事件
const handleCurrentChange = (index) => {
  currentPage.value = index;
  getAuditedPartyPage();
};
// 处理编辑事件
const handleEdit = (type, data) => {
  let row = undefined;
  if (type === 1) {
    // 判断是否有且仅选择了一条用户数据
    if (selectAuditedPartys.value.length !== 1) {
      ElMessage.warning(t("Components.Database.AuditedParty.OnlyOneSelectEdit"));
      return;
    }
    row = selectAuditedPartys.value[0];
  } else if (type === 2) {
    row = data;
  }
  editData.value = {
    guid: row.guid,
    name: row.name,
    area: row.area,
    category: row.category,
    qualification: row.qualification
  };
  dialogVisible.value = true;
};
// 处理回调关闭事件
const handleCloseDialog = () => {
  dialogVisible.value = false;
  editData.value = {};
};
// 处理有效性改变事件
const handleDataflagChange = async (flag, row) => {
  await request_auditedparty_dataflag(row.guid, true, () => {
    row.dataflag = !row.dataflag;
  });
};
// 处理选择回调事件
const handleSelectionChange = (auditedPartys) => {
  selectAuditedPartys.value = auditedPartys;
};
// 处理删除
const handleDelete = async (row) => {
  ElMessageBox.confirm(
    t("Components.Database.AuditedParty.ConfirmDeleteAuditedParty"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      await request_auditedparty_delete(row.guid, true);
      getAuditedPartyPage();
    })
    .catch(() => {});
};
// 批量删除
const handleDeleteBatch = async () => {
  if (selectAuditedPartys.value.length === 0) {
    ElMessage.warning(t("Components.Database.AuditedParty.AtLeastOneAuditedParty"));
    return;
  }
  ElMessageBox.confirm(
    t("Components.Database.AuditedParty.ConfirmDeleteAuditedParty"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const trans = {
        guids: selectAuditedPartys.value.map((item) => item.guid),
      };
      await request_auditedparty_delete_batch(trans, true);
      getAuditedPartyPage();
    })
    .catch(() => {});
};
// 处理排序改变事件
const handleSortChange = async ({ prop, order }) => {
  if (prop === "createtime") {
    sortSort.value = undefined;
    // 创建时间排序
    if (order === "ascending") {
      // 时间正序
      createTimeSort.value = false;
    } else if (order === "descending") {
      // 时间倒叙
      createTimeSort.value = true;
    } else {
      createTimeSort.value = undefined;
    }
  } else if (prop === "sort") {
    createTimeSort.value = undefined;
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      sortSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      sortSort.value = true;
    } else {
      sortSort.value = undefined;
    }
  }
  await getAuditedPartyPage();
};
// 导出
const handleExport = async () => {
  fullLoading.value = true;
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    text: searchForm.inputSearch,
    dataflag: searchForm.dataflag,
    createTimeSort: createTimeSort.value,
    sort: sortSort.value,
    category: searchForm.category,
    qualification: searchForm.qualification,
    area:searchForm.area
  };
  const result = await request_auditedparty_export(
    trans,
    true,
    () => (fullLoading.value = false)
  );
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = result;
  link.target = '_blank'
  document.body.appendChild(link);
  link.click();
};
watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditedParty,
      MenuButtonCodes.Database.AuditedParty.AuditedPartySearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditedParty,
      MenuButtonCodes.Database.AuditedParty.AuditedPartyReset
    );
    // 处理新增
    buttonVisible.addButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditedParty,
      MenuButtonCodes.Database.AuditedParty.AuditedPartyAdd
    );
    // 处理编辑
    buttonVisible.editButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditedParty,
      MenuButtonCodes.Database.AuditedParty.AuditedPartyEdit
    );
    // 处理删除
    buttonVisible.deleteButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditedParty,
      MenuButtonCodes.Database.AuditedParty.AuditedPartyDelete
    );
    // 处理删除行
    buttonVisible.editButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditedParty,
      MenuButtonCodes.Database.AuditedParty.AuditedPartyEditLine
    );
    // 处理删除行
    buttonVisible.deleteButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditedParty,
      MenuButtonCodes.Database.AuditedParty.AuditedPartyDeleteLine
    );
    // 处理有效性
    buttonVisible.dataflag = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditedParty,
      MenuButtonCodes.Database.AuditedParty.AuditedPartyDataflag
    );
    // 处理导出
    buttonVisible.exportButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Database.AuditedParty,
      MenuButtonCodes.Database.AuditedParty.AuditedPartyExport
    );
  }
});


watchEffect(() => {
  if (store.userInfo) {
    if (store.userInfo.dics && store.userInfo.dics.length > 0) {
      // 分类
      const findAuditedPartyCategoryDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.AuditedPartyCategory
      );
      if (findAuditedPartyCategoryDic) {
        if (
          findAuditedPartyCategoryDic.items &&
          findAuditedPartyCategoryDic.items.length > 0
        ) {
          auditedPartyCategorys.value = findAuditedPartyCategoryDic.items;
        } else {
          auditedPartyCategorys.value = [];
        }
      } else {
        auditedPartyCategorys.value = [];
      }
      // 资质
      const findQulificationDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.AuditedPartyQualification
      );
      if (findQulificationDic) {
        if (
          findQulificationDic.items &&
          findQulificationDic.items.length > 0
        ) {
          qualifications.value = findQulificationDic.items;
        } else {
          qualifications.value = [];
        }
      } else {
        qualifications.value = [];
      }
      // 区域
      const findAreaDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.AuditedPartyArea
      );
      if (findAreaDic) {
        if (
          findAreaDic.items &&
          findAreaDic.items.length > 0
        ) {
          areas.value = findAreaDic.items;
        } else {
          areas.value = [];
        }
      } else {
        areas.value = [];
      }
    }
  }
});
</script>
<style>
.auditedparty-ul .el-scrollbar__view {
  height: calc(100% - 8px);
}
</style>
<style scoped lang="scss">
.auditedparty-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
