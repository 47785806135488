<template>
  <ul class="statistics-ul" v-loading="fullLoading">
    <el-collapse-transition>
      <li class="hm-search" v-show="isShowSearch">
        <!-- 查询部分 -->
        <el-form :inline="true" :model="searchForm" label-width="100">
          <!-- 项目 -->
          <el-form-item
            :label="$t('Components.Statistics.AuditDiscovery.ProjectNumber')"
          >
            <el-select
              clearable
              filterable
              v-model="searchForm.project_guid"
              :placeholder="
                $t('Components.Statistics.AuditDiscovery.PleaseSelectProject')
              "
            >
              <el-option
                v-for="item in projects"
                :label="item.project_number"
                :value="item.guid"
                :key="item.guid"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 委托方 -->
          <el-form-item
            :label="$t('Components.Statistics.AuditDiscovery.Client')"
          >
            <el-select
              v-model="searchForm.client_guid"
              :placeholder="
                $t('Components.Statistics.AuditDiscovery.PleaseSelectClient')
              "
              clearable
              filterable
            >
              <el-option
                v-for="item in clients"
                :key="item.guid"
                :label="item.name"
                :value="item.guid"
              />
            </el-select>
          </el-form-item>
          <!-- 委托方类型 -->
          <el-form-item
            :label="$t('Components.Statistics.AuditDiscovery.Category')"
          >
            <el-select
              v-model="searchForm.client_category"
              :placeholder="
                t('Components.Statistics.AuditDiscovery.PleaseSelectCategory')
              "
              clearable
            >
              <el-option
                v-for="item in clientCategorys"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 服务类型 -->
          <el-form-item
            :label="$t('Components.Statistics.AuditDiscovery.ServiceType')"
          >
            <el-select
              v-model="searchForm.service_type"
              :placeholder="
                t(
                  'Components.Statistics.AuditDiscovery.PleaseSelectServiceType'
                )
              "
              clearable
            >
              <el-option
                v-for="item in serviceTypes"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 稽查类型 -->
          <el-form-item
            :label="$t('Components.Statistics.AuditDiscovery.AuditType')"
          >
            <el-select
              v-model="searchForm.audit_type"
              :placeholder="
                $t('Components.Statistics.AuditDiscovery.PleaseSelectAuditType')
              "
              clearable
            >
              <el-option
                v-for="item in auditTypes"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 申办者 -->
          <el-form-item
            :label="$t('Components.Statistics.AuditDiscovery.Sponsor')"
          >
            <el-input
              v-model="searchForm.sponsor"
              :placeholder="
                $t('Components.Statistics.AuditDiscovery.PleaseInputSponsor')
              "
              clearable
            />
          </el-form-item>
          <!-- 申办者类型 -->
          <el-form-item
            :label="$t('Components.Statistics.AuditDiscovery.SponsorType')"
          >
            <el-select
              v-model="searchForm.sponsor_type"
              :placeholder="
                $t(
                  'Components.Statistics.AuditDiscovery.PleaseSelectSponsorType'
                )
              "
              clearable
            >
              <el-option
                v-for="item in sponsorTypes"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 研究产品 -->

          <el-form-item
            :label="$t('Components.Statistics.AuditDiscovery.ResearchProducts')"
          >
            <el-input
              v-model="searchForm.research_products"
              :placeholder="
                $t(
                  'Components.Statistics.AuditDiscovery.PleaseResearchProducts'
                )
              "
              clearable
            />
          </el-form-item>
          <!-- 研究产品类型 -->
          <el-form-item
            :label="
              $t('Components.Statistics.AuditDiscovery.ResearchProductsType')
            "
          >
            <el-select
              v-model="searchForm.research_products_type"
              :placeholder="
                $t(
                  'Components.Statistics.AuditDiscovery.PleaseSelectResearchProductsType'
                )
              "
              clearable
            >
              <el-option
                v-for="item in researchProductsTypes"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 试验分期 -->
          <el-form-item
            :label="$t('Components.Statistics.AuditDiscovery.TrialStaging')"
          >
            <el-select
              v-model="searchForm.trial_staging"
              :placeholder="
                $t(
                  'Components.Statistics.AuditDiscovery.PleaseSelectTrialStaging'
                )
              "
              clearable
            >
              <el-option
                v-for="item in dicTrialStagings"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 治疗领域 -->
          <el-form-item
            :label="$t('Components.Statistics.AuditDiscovery.TherapeuticArea')"
          >
            <el-select
              v-model="searchForm.therapeutic_area"
              :placeholder="
                $t(
                  'Components.Statistics.AuditDiscovery.PleaseSelectTherapeuticArea'
                )
              "
              clearable
            >
              <el-option
                v-for="item in therapeuticAreas"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 适应症 -->
          <el-form-item
            :label="$t('Components.Statistics.AuditDiscovery.Indications')"
          >
            <el-input
              v-model="searchForm.indications"
              :placeholder="
                $t(
                  'Components.Statistics.AuditDiscovery.PleaseInputIndications'
                )
              "
              clearable
            />
          </el-form-item>
          <!-- 试验进展阶段 -->
          <el-form-item
            :label="
              $t('Components.Statistics.AuditDiscovery.TrialProgressStage')
            "
          >
            <el-select
              v-model="searchForm.trial_progress_stage"
              :placeholder="
                $t(
                  'Components.Statistics.AuditDiscovery.PleaseSelectTrialProgressStage'
                )
              "
              clearable
            >
              <el-option
                v-for="item in dicTrialProgressStages"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 任务编号 -->
          <el-form-item
            :label="
              $t('Components.Statistics.AuditDiscovery.ProjectTaskNumber')
            "
          >
            <el-select
              clearable
              filterable
              v-model="searchForm.project_task_guid"
              :placeholder="
                $t(
                  'Components.Statistics.AuditDiscovery.PleaseSelectProjectTaskNumber'
                )
              "
            >
              <el-option
                v-for="item in projectTasks"
                :label="item.number"
                :value="item.guid"
                :key="item.guid"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 被稽查方 -->
          <el-form-item
            :label="$t('Components.Statistics.AuditDiscovery.AuditedParty')"
          >
            <el-select
              v-model="searchForm.audited_party_guid"
              :placeholder="
                $t(
                  'Components.Statistics.AuditDiscovery.PleaseSelectAuditedParty'
                )
              "
              clearable
            >
              <el-option
                v-for="item in auditedPartys"
                :key="item.guid"
                :label="item.name"
                :value="item.guid"
              />
            </el-select>
          </el-form-item>
          <!-- 被稽查方区域 -->
          <el-form-item
            :label="$t('Components.Statistics.AuditDiscovery.AuditedPartyArea')"
          >
            <el-select
              v-model="searchForm.audited_party_area"
              :placeholder="
                $t(
                  'Components.Statistics.AuditDiscovery.PleaseSelectAuditedPartyArea'
                )
              "
              clearable
            >
              <el-option
                v-for="item in auditedPartyAreas"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 被稽查方资质 -->
          <el-form-item
            :label="
              $t(
                'Components.Statistics.AuditDiscovery.AuditedPartyQualification'
              )
            "
          >
            <el-select
              v-model="searchForm.audited_party_qualification"
              :placeholder="
                $t(
                  'Components.Statistics.AuditDiscovery.PleaseSelectAuditedPartyQualification'
                )
              "
              clearable
            >
              <el-option
                v-for="item in auditedPartyQualifications"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 医院类型 -->
          <el-form-item
            :label="$t('Components.Statistics.AuditDiscovery.HospitalType')"
          >
            <el-select
              v-model="searchForm.hospital_type"
              :placeholder="
                $t(
                  'Components.Statistics.AuditDiscovery.PleaseSelectHospitalType'
                )
              "
              clearable
            >
              <el-option
                v-for="item in hospitalTypes"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 实施试验科室 -->
          <el-form-item
            :label="
              $t(
                'Components.Statistics.AuditDiscovery.ImplementationDepartment'
              )
            "
          >
            <el-input
              v-model="searchForm.implementation_department"
              :placeholder="
                $t(
                  'Components.Statistics.AuditDiscovery.PleaseInputImplementationDepartment'
                )
              "
              clearable
            />
          </el-form-item>
          <!-- 主要研究者 -->
          <el-form-item
            :label="$t('Components.Statistics.AuditDiscovery.MainResearcher')"
          >
            <el-input
              v-model="searchForm.main_researcher"
              :placeholder="
                $t(
                  'Components.Statistics.AuditDiscovery.PleaseInputMainResearcher'
                )
              "
              clearable
            />
          </el-form-item>
          <!-- 报告完成日期 -->
          <el-form-item
            :label="
              t('Components.Statistics.AuditDiscovery.CompleteReportTime')
            "
          >
            <el-date-picker
              v-model="searchForm.completed_reporting_time"
              type="daterange"
              range-separator="-"
              :start-placeholder="
                t(
                  'Components.Statistics.AuditDiscovery.PleaseSelectCompleteReportStartTime'
                )
              "
              :end-placeholder="
                t(
                  'Components.Statistics.AuditDiscovery.PleaseSelectCompleteReportEndTime'
                )
              "
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
          <!-- 问题分级 -->
          <el-form-item
            :label="$t('Components.Statistics.AuditDiscovery.DiscoveryGrading')"
          >
            <el-select
              v-model="searchForm.problem_grading"
              :placeholder="
                $t(
                  'Components.Statistics.AuditDiscovery.PleaseSelectDiscoveryGrading'
                )
              "
              clearable
            >
              <el-option
                v-for="item in searchDiscoveryGradings"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 主分类 -->
          <el-form-item
            :label="$t('Components.Statistics.AuditDiscovery.MainCategory')"
          >
            <el-select
              v-model="searchForm.main_category"
              :placeholder="
                $t(
                  'Components.Statistics.AuditDiscovery.PleaseSelectMainCategory'
                )
              "
              clearable
              @change="handleMainCategoryChange"
            >
              <el-option
                v-for="item in searchMainCategorys"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 子分类 -->
          <el-form-item
            :label="$t('Components.Statistics.AuditDiscovery.SubCategory')"
          >
            <el-select
              v-model="searchForm.sub_category"
              :placeholder="
                $t(
                  'Components.Statistics.AuditDiscovery.PleaseSelectSubCategory'
                )
              "
              clearable
            >
              <el-option
                v-for="item in currentSubCategorys"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 是否导入报告 -->
          <el-form-item
            :label="$t('Components.Statistics.AuditDiscovery.IsImportReport')"
          >
            <el-select
              v-model="searchForm.is_import_report"
              :placeholder="
                $t(
                  'Components.Statistics.AuditDiscovery.PleaseSelectIsImportReport'
                )
              "
              clearable
            >
              <el-option
                :label="$t('Components.Statistics.AuditDiscovery.Yes')"
                :value="true"
              ></el-option>
              <el-option
                :label="$t('Components.Statistics.AuditDiscovery.No')"
                :value="false"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              v-if="buttonVisible.searchButton"
              type="primary"
              @click="queryClick"
              :icon="Search"
              >{{
                $t("Components.Statistics.AuditDiscovery.Search1")
              }}</el-button
            >
            <el-button
              v-if="buttonVisible.resetButton"
              @click="resetClick"
              :icon="RefreshLeft"
              >{{ $t("Components.Statistics.AuditDiscovery.Reset") }}</el-button
            >
          </el-form-item>
        </el-form>
      </li>
    </el-collapse-transition>
    <li class="hm-operate">
      <!-- 操作部分 -->

      <ul class="operate-ul">
        <li class="left-li">
          <el-button
            v-if="buttonVisible.exportButton"
            :style="{ color: 'white' }"
            :icon="Download"
            :color="ButtonColor.Export"
            @click="handleExport"
            >{{ $t("Components.Statistics.AuditDiscovery.Export") }}</el-button
          >
        </li>
        <li class="right-li">
          <el-space>
            <el-tooltip
              effect="dark"
              :content="
                !isShowSearch
                  ? t('Common.Operate.ShowSearch')
                  : t('Common.Operate.HideSearch')
              "
              placement="top"
            >
              <el-button
                type=""
                circle
                :icon="Search"
                size="small"
                @click="() => (isShowSearch = !isShowSearch)"
              ></el-button>
            </el-tooltip>
          </el-space>
        </li>
      </ul>
    </li>
    <div class="oo-div">
      <!-- <div :style="{position: 'absolute', left:0}">
        
      </div> -->
      <div
        :style="{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
        }"
      >
        <el-radio-group v-model="mode" size="small">
          <el-radio-button :label="1">{{
            t("Components.Statistics.AuditDiscovery.BarChart")
          }}</el-radio-button>
          <el-radio-button :label="2">{{
            t("Components.Statistics.AuditDiscovery.Table")
          }}</el-radio-button>
        </el-radio-group>
      </div>
      <div class="right-div">
        <div class="div-block">
          <div class="div-color cc"></div>
          <div class="div-color-name">
            {{
              t("Components.Statistics.AuditDiscovery.OnlyCompleteStatistic")
            }}
          </div>
          <el-icon v-if="!isShowEchart" :style="{marginLeft: '10px'}" @click="()=> isShowEchart=!isShowEchart"><View /></el-icon>
          <el-icon v-else :style="{marginLeft: '10px'}" @click="()=> isShowEchart=!isShowEchart"><Hide /></el-icon>
        </div>
      </div>
    </div>
    <el-scrollbar always>
      <div v-show="mode === 1">
        <div
          id="mainCategoryChartDom"
          class="chart-div"
          v-loading="chartLoading"
          v-show="isShowEchart"
        ></div>
        <div
        v-if="showSubChart"
          id="subCategoryChartDom"
          class="chart-div"
          v-loading="subChartLoading"
          v-show="isShowEchart"
        ></div>
        <li class="hm-operate">
          <!-- 操作部分 -->

          <ul class="operate-ul">
            <li class="left-li">
              <el-button
                v-if="buttonVisible.exportSingleMaincategoryButton"
                :style="{ color: 'white' }"
                :icon="Download"
                :color="ButtonColor.Export"
                @click="handleSingleMaincategoryExport"
                >{{
                  $t("Components.Statistics.AuditDiscovery.Export")
                }}</el-button
              >
            </li>
          </ul>
        </li>
        <li class="hm-table">
          <el-table
            v-loading="tableLoading"
            :data="tableDataChart"
            row-key="guid"
            @sort-change="handleSortChange"
            scrollbar-always-on
          >
            <!-- 任务编号 -->
            <el-table-column
              prop="project_number"
              width="200"
              :label="$t('Components.Statistics.AuditDiscovery.ProjectNumber')"
              sortable
            />
            <!-- 委托方 -->
            <el-table-column
              prop="client_name"
              width="160"
              :label="$t('Components.Statistics.AuditDiscovery.Client')"
            />
            <!-- 委托方类型 -->
            <el-table-column
              prop="client_category"
              width="120"
              :label="$t('Components.Statistics.AuditDiscovery.Category')"
            >
              <template #default="scope">
                <EchoStyle
                  :dicData="clientCategorys"
                  :dicType="Dics.System.ClientCategory"
                  :dicItemValue="scope.row.client_category"
                />
              </template>
            </el-table-column>
            <!-- 服务类型 -->
            <el-table-column
              prop="service_type"
              width="120"
              :label="$t('Components.Statistics.AuditDiscovery.ServiceType')"
            >
              <template #default="scope">
                <EchoStyle
                  :dicData="serviceTypes"
                  :dicType="Dics.System.ServiceType"
                  :dicItemValue="scope.row.service_type"
                />
              </template>
            </el-table-column>
            <!-- 稽查类型 -->
            <el-table-column
              prop="audit_type"
              width="120"
              :label="$t('Components.Statistics.AuditDiscovery.AuditType')"
            >
              <template #default="scope">
                <EchoStyle
                  :dicData="auditTypes"
                  :dicType="Dics.System.AuditType"
                  :dicItemValue="scope.row.audit_type"
                />
              </template>
            </el-table-column>
            <!-- 申办者 -->
            <el-table-column
              prop="sponsor"
              width="160"
              :label="$t('Components.Statistics.AuditDiscovery.Sponsor')"
            />
            <!-- 申办者类型 -->
            <el-table-column
              prop="audit_type"
              width="120"
              :label="$t('Components.Statistics.AuditDiscovery.SponsorType')"
            >
              <template #default="scope">
                <EchoStyle
                  :dicData="sponsorTypes"
                  :dicType="Dics.System.SponsorType"
                  :dicItemValue="scope.row.sponsor_type"
                />
              </template>
            </el-table-column>
            <!-- 研究产品 -->
            <el-table-column
              prop="research_products"
              width="160"
              :label="
                $t('Components.Statistics.AuditDiscovery.ResearchProducts')
              "
            />
            <!-- 研究产品类型 -->
            <el-table-column
              width="120"
              :label="
                $t('Components.Statistics.AuditDiscovery.ResearchProductsType')
              "
            >
              <template #default="scope">
                <EchoStyle
                  :dicData="researchProductsTypes"
                  :dicType="Dics.System.ResearchProductType"
                  :dicItemValue="scope.row.research_products_type"
                />
              </template>
            </el-table-column>
            <!-- 试验分期 -->
            <el-table-column
              width="120"
              :label="$t('Components.Statistics.AuditDiscovery.TrialStaging')"
            >
              <template #default="scope">
                <EchoStyle
                  :dicData="dicTrialStagings"
                  :dicType="Dics.System.TrialStaging"
                  :dicItemValue="scope.row.trial_staging"
                />
              </template>
            </el-table-column>
            <!-- 治疗领域 -->
            <el-table-column
              width="120"
              :label="
                $t('Components.Statistics.AuditDiscovery.TherapeuticArea')
              "
            >
              <template #default="scope">
                <EchoStyle
                  :dicData="therapeuticAreas"
                  :dicType="Dics.System.TherapeuticArea"
                  :dicItemValue="scope.row.therapeutic_area"
                />
              </template>
            </el-table-column>
            <!-- 适应症 -->
            <el-table-column
              prop="indications"
              width="160"
              :label="$t('Components.Statistics.AuditDiscovery.Indications')"
            />
            <!-- 试验进展阶段 -->
            <el-table-column
              width="120"
              :label="
                $t('Components.Statistics.AuditDiscovery.TrialProgressStage')
              "
            >
              <template #default="scope">
                <EchoStyle
                  :dicData="dicTrialProgressStages"
                  :dicType="Dics.System.TrialProgressStage"
                  :dicItemValue="scope.row.trial_progress_stage"
                />
              </template>
            </el-table-column>
            <!-- 任务编号 -->
            <el-table-column
              prop="project_task_number"
              width="200"
              :label="
                $t('Components.Statistics.AuditDiscovery.ProjectTaskNumber')
              "
              sortable
            />
            <!-- 被稽查方 -->
            <el-table-column
              prop="audited_party_name"
              width="160"
              :label="$t('Components.Statistics.AuditDiscovery.AuditedParty')"
            />
            <!-- 被稽查方区域 -->
            <el-table-column
              width="120"
              :label="
                $t('Components.Statistics.AuditDiscovery.AuditedPartyArea')
              "
            >
              <template #default="scope">
                <EchoStyle
                  :dicData="auditedPartyAreas"
                  :dicType="Dics.System.AuditedPartyArea"
                  :dicItemValue="scope.row.audited_party_area"
                />
              </template>
            </el-table-column>
            <!-- 被稽查方资质 -->
            <el-table-column
              width="120"
              :label="
                $t(
                  'Components.Statistics.AuditDiscovery.AuditedPartyQualification'
                )
              "
            >
              <template #default="scope">
                <EchoStyle
                  :dicData="auditedPartyQualifications"
                  :dicType="Dics.System.AuditedPartyQualification"
                  :dicItemValue="scope.row.audited_party_qualification"
                />
              </template>
            </el-table-column>
            <!-- 医院类型 -->
            <el-table-column
              width="120"
              :label="$t('Components.Statistics.AuditDiscovery.HospitalType')"
            >
              <template #default="scope">
                <EchoStyle
                  :dicData="auditedPartyQualifications"
                  :dicType="Dics.System.HospitalType"
                  :dicItemValue="scope.row.hospital_type"
                />
              </template>
            </el-table-column>
            <!-- 实施科室 -->
            <el-table-column
              prop="implementation_department"
              width="160"
              :label="
                $t(
                  'Components.Statistics.AuditDiscovery.ImplementationDepartment'
                )
              "
            />
            <!-- 主要研究者 -->
            <el-table-column
              prop="main_researcher"
              width="160"
              :label="$t('Components.Statistics.AuditDiscovery.MainResearcher')"
            />
            <!-- 报告完成日期 -->
            <el-table-column
              prop="completed_reporting_date"
              width="160"
              :label="
                $t('Components.Statistics.AuditDiscovery.CompleteReportTime')
              "
              sortable
            >
              <template #default="scope">
                <span>{{
                  formatTime(new Date(scope.row.completed_reporting_date))
                }}</span>
              </template>
            </el-table-column>
            <!-- 问题分级 -->
            <el-table-column
              prop="grading"
              width="160"
              :label="
                $t('Components.Statistics.AuditDiscovery.DiscoveryGrading')
              "
            >
            </el-table-column>
            <!-- 主分类 -->
            <el-table-column
              prop="main_category"
              width="160"
              :label="$t('Components.Statistics.AuditDiscovery.MainCategory')"
            />
            <!-- 子分类  -->
            <el-table-column
              prop="sub_category"
              width="160"
              :label="$t('Components.Statistics.AuditDiscovery.SubCategory')"
            />
            <!-- 问题概述 -->
            <el-table-column
              prop="problem_overview"
              width="160"
              :label="
                $t('Components.Statistics.AuditDiscovery.ProblemOverview')
              "
            />
            <!-- 问题归纳 -->
            <el-table-column
              prop="problem_summary"
              width="160"
              :label="$t('Components.Statistics.AuditDiscovery.ProblemSummary')"
            />
            <!-- 筛选号 -->
            <el-table-column
              prop="filter_number"
              width="120"
              :label="$t('Components.Statistics.AuditDiscovery.FilterNumber')"
            />
            <!-- 问题详情 -->
            <el-table-column
              width="200"
              prop="problem_details"
              show-overflow-tooltip
              :label="$t('Components.Statistics.AuditDiscovery.ProblemDetail')"
            />
            <!-- 备注 -->
            <el-table-column
              width="120"
              prop="remark"
              :label="$t('Components.Statistics.AuditDiscovery.Remark')"
            />
            <!-- 是否导入报告 -->
            <el-table-column
              width="120"
              prop="is_import_report"
              :label="$t('Components.Statistics.AuditDiscovery.IsImportReport')"
            >
              <template #default="scope">
                <el-tag
                  :type="scope.row.is_import_report ? 'success' : 'danger'"
                  class="mx-1"
                  effect="dark"
                >
                  {{
                    scope.row.is_import_report
                      ? $t("Components.Statistics.AuditDiscovery.Yes")
                      : $t("Components.Statistics.AuditDiscovery.No")
                  }}
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
        </li>
      </div>

      <div v-show="mode === 2" :style="{ marginTop: '8px' }">
        <el-table :data="tableDataTable" row-key="guid">
          <el-table-column
            prop="main_category"
            :label="$t('Components.Statistics.AuditDiscovery.MainCategory')"
          >
            <template #default="scope">
              <EchoStyle
                :dicData="searchMainCategorys"
                :dicType="Dics.System.DiscoveryMainCategory"
                :dicItemValue="scope.row.main_category"
              />
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, index) in searchDiscoveryGradings"
            :key="item.guid"
            :label="
              searchDiscoveryGradings && searchDiscoveryGradings[index]
                ? searchDiscoveryGradings[index].name
                : ''
            "
          >
            <template #default="scope">
              {{ scope.row.gradings[index].count }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-scrollbar>

    <li class="hm-page">
      <el-pagination
        small
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="AllPageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </li>
  </ul>
</template>
<script setup>
import { reactive, ref, onMounted, watchEffect, onBeforeUnmount } from "vue";
import { RefreshLeft, Search, Download } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import { AllPageSize, ButtonColor, DefaultPageSize } from "@/consts/frame";
import {
  request_user_page,
  request_client_page,
  request_auditedparty_page,
  request_statistics_auditdiscovery_statistics,
  request_dicionaryitem_bydiccode,
  request_statistics_auditdiscovery_page,
  request_statistics_auditdiscovery_export,
  request_statistics_auditdiscovery_singlemaincategory_export,
  request_project_all,
  request_projecttask_page,
} from "@/api/apis";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";
import { useStore } from "@/store/index";
import { Dics } from "@/consts/dic";
import * as echarts from "echarts";
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import EchoStyle from "@/components/Common/EchoStyle.vue";
import { formatTime } from "@/util/timeUtil";

const store = useStore();
const { t } = useI18n();
const searchForm = reactive({
  project_guid: undefined, // 项目
  client_guid: undefined, // 委托方
  client_category: undefined, // 委托方类型
  service_type: undefined, // 服务类型
  audit_type: undefined, // 稽查类型
  sponsor: "", // 申办者
  sponsor_type: undefined, // 申办者类型
  research_products: "", // 研究产品
  research_products_type: undefined, // 研究产品类型
  trial_staging: undefined, // 试验分期
  therapeutic_area: undefined, // 治疗领域
  indications: "", // 适应症
  trial_progress_stage: undefined, // 试验进展阶段
  project_task_guid: undefined, //任务
  audited_party_guid: undefined, // 被稽查方
  audited_party_area: undefined, // 被稽查方区域
  audited_party_qualification: undefined, // 被稽查方资质
  hospital_type: undefined, // 医院类型
  implementation_department: "", // 实施科室
  main_researcher: "", // 主要研究者
  completed_reporting_time: undefined, // 报告完成时间
  problem_grading: undefined, // 问题分级
  main_category: undefined, // 主分类
  sub_category: undefined, // 子分类
  is_import_report: undefined, // 是否导入报告
});
const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  exportButton: false,
  exportSingleMaincategoryButton: false,
});
const isShowSearch = ref(true);
// 当前页码
const currentPage = ref(1);
// 页码大小
const pageSize = ref(DefaultPageSize);
// 图标表格数据
const tableDataChart = ref([]);
// 表格模式表格数据
const tableDataTable = ref([]);
// 表格加载
const tableLoading = ref(false);
// 总数量
const total = ref(0);
// 全屏加载
const fullLoading = ref(false);
// 被稽查方
const auditedPartys = ref([]);
// 委托方
const clients = ref([]);
const users = ref([]);
// 选中的主要分类
const checkMainCategory = ref("");
// 模式，1：代表柱状图；2：代表表格
const mode = ref(1);
// 加载中
const chartLoading = ref(true);

const subChartLoading = ref(false);
const showSubChart = ref(false)

const projects = ref([]);
const projectTasks = ref([]);

// 委托方分类
const clientCategorys = ref([]);
// 服务类型字典集合
const serviceTypes = ref([]);
// 稽查类型字典集合
const auditTypes = ref([]);
// 申办者类型字典集合
const sponsorTypes = ref([]);
// 研究产品类型
const researchProductsTypes = ref([]);
// 试验分期
const dicTrialStagings = ref([]);
// 治疗领域
const therapeuticAreas = ref([]);
// 试验进展阶段
const dicTrialProgressStages = ref([]);
// 被稽查方区域字典集合
const auditedPartyAreas = ref([]);
// 被稽查方资质字典集合
const auditedPartyQualifications = ref([]);
// 医院类型字典集合
const hospitalTypes = ref([]);
// 问题分级字典集合
const searchDiscoveryGradings = ref([]);
// 主分类字典集合
const searchMainCategorys = ref([]);
// 子分类字典集合
const allSubCategorys = ref([]);
// 当前绑定的子分类
const currentSubCategorys = ref([]);

const isShowEchart = ref(true)

const ProjectNumberSort = ref(undefined);
const ProjectTaskNumberSort = ref(undefined);
const ReportCompleteDateSort = ref(undefined);

let mainCategoryOption = null;
let subCategoryOption = null;

echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer,
]);

let app = {};
let mainCategoryChart;
let subCategoryChart;
let mainCategoryBindData = [];
let subCategoryBindData = [];
let gradings = [];
let mainCategorys = [];
let subCategorysAll = [];
let subCategorys = [];

onMounted(async () => {
  chartLoading.value = true;
  getUserAll();
  getClientAll();
  getAuditedPartyAll();
  getProjectTaskAll();
  getAllProject();
  await getAllGradings();
  await getAllMainCategorys();
  await getStatisticsDiscovereyStatistics();
  getStatisticAuditDiscoveryPage();
  configCharts();
  mainCategoryChart = echarts.init(
    document.getElementById("mainCategoryChartDom")
  );
  mainCategoryOption && mainCategoryChart.setOption(mainCategoryOption);
  mainCategoryChart.on("click", handleMainCategoryChartClick);
});
// 配置图标
const configCharts = () => {
  app.config = {
    rotate: 90,
    align: "left",
    verticalAlign: "middle",
    position: "insideBottom",
    distance: 15,
    onChange: function () {},
  };
  mainCategoryOption = {
    title:{
      text:  t('Components.Statistics.AuditDiscovery.MainCategory'),
      textAlign:'auto',
      left: '10%'
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    color: ["#FF0000", "#FFA500", "#0000FF", "#B0C4DE"],
    legend: {
      data: gradings.map((item) => {
        return item.value;
      }),
    },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ["line", "bar", "stack"] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    xAxis: [
      {
        type: "category",
        data: mainCategorys.map((item) => item.value),
        axisTick: { alignWithLabel: true },
        axisLabel: {
          //x轴文字的配置
          show: true,
          interval: 0, //使x轴文字显示全
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        minInterval: 1,
      },
    ],

    series: gradings.map((item) => {
      return {
        name: item.value,
        type: "bar",
        barWidth: 30,
        data: mainCategoryBindData.map((item1) => {
          const findData = item1.gradings.find(
            (item2) => item2.grading === item.value
          );
          if (findData) return findData.count;
          else return 0;
        }),
        label: {
          show: true, //开启显示
          position: "top", //在上方显示
          // formatter: '{c}%',//显示百分号
          //数值样式
          color: "black", //字体颜色
          fontSize: 14, //字体大小
        },
      };
    }),
  };
};

const configSubCategoryCharts = ()=>{
  app.config = {
    rotate: 90,
    align: "left",
    verticalAlign: "middle",
    position: "insideBottom",
    distance: 15,
    onChange: function () {},
  };
  subCategoryOption = {
    title:{
      text:  t('Components.Statistics.AuditDiscovery.SubCategory'),
      textAlign:'auto',
      left: '10%'
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    color: ["#FF0000", "#FFA500", "#0000FF", "#B0C4DE"],
    legend: {
      data: gradings.map((item) => {
        return item.value;
      }),
    },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ["line", "bar", "stack"] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    xAxis: [
      {
        type: "category",
        data: subCategorys.map((item) => item.value),
        axisTick: { alignWithLabel: true },
        axisLabel: {
          //x轴文字的配置
          show: true,
          interval: 0, //使x轴文字显示全
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        minInterval: 1,
      },
    ],

    series: gradings.map((item) => {
      return {
        name: item.value,
        type: "bar",
        barWidth: 30,
        data: subCategoryBindData.map((item1) => {
          
          const findData = item1.gradings.find(
            (item2) => item2.grading === item.value
          );
          if (findData) return findData.count;
          else return 0;
        }),
        label: {
          show: true, //开启显示
          position: "top", //在上方显示
          // formatter: '{c}%',//显示百分号
          //数值样式
          color: "black", //字体颜色
          fontSize: 14, //字体大小
        },
      };
    }),
  };
}
// 获取所有等级
const getAllGradings = async () => {
  const result = await request_dicionaryitem_bydiccode({
    dic_code: Dics.System.DiscoveryGrading,
  });
  if (result && result.length > 0) {
    gradings = result.slice(0, result.length - 1);
  } else {
    gradings = [];
  }
};
// 获取所有分类
const getAllMainCategorys = async () => {
  const result = await request_dicionaryitem_bydiccode({
    dic_code: Dics.System.DiscoveryMainCategory,
  });
  mainCategorys = result;
  const resultSubcategory = await request_dicionaryitem_bydiccode({
    dic_code: Dics.System.DiscoverySubCategory,
  });
  subCategorysAll = resultSubcategory;
};
// 获取所有用户
const getUserAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
    is_system: false,
  };
  const result = await request_user_page(trans);
  if (result.total > 0) {
    users.value = result.rows;
  } else {
    users.value = [];
  }
};
// 获取所有客户
const getClientAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
    is_system: false,
  };
  const result = await request_client_page(trans);
  if (result.total > 0) clients.value = result.rows;
  else clients.value = [];
};
// 获取所有被稽查方
const getAuditedPartyAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
    is_system: false,
  };
  const result = await request_auditedparty_page(trans);
  if (result.total > 0) auditedPartys.value = result.rows;
  else auditedPartys.value = [];
};
// 查询稽查发现统计数据 1：代表获取的是主分类；2：代表获取的是子分类，只有为2的时候才需要主分类
const getStatisticsDiscovereyStatistics = async (searchType = 1) => {
  if(searchType === 1){
    showSubChart.value = false
  }
  const trans = {
    project_guid: searchForm.project_guid,
    client_guid: searchForm.client_guid,
    client_category: searchForm.client_category,
    service_type: searchForm.service_type,
    audit_type: searchForm.audit_type,
    sponsor: searchForm.sponsor,
    sponsor_type: searchForm.sponsor_type,
    research_products: searchForm.research_products,
    research_products_type: searchForm.research_products_type,
    trial_staging: searchForm.trial_staging,
    therapeutic_area: searchForm.therapeutic_area,
    indications: searchForm.indications,
    trial_progress_stage: searchForm.trial_progress_stage,
    project_task_guid: searchForm.project_task_guid,
    audited_party_guid: searchForm.audited_party_guid,
    audited_party_area: searchForm.audited_party_area,
    audited_party_qualification: searchForm.audited_party_qualification,
    hospital_type: searchForm.hospital_type,
    implementation_department: searchForm.implementation_department,
    main_researcher: searchForm.main_researcher,
    completed_reporting_startdate:
      searchForm.completed_reporting_time &&
      searchForm.completed_reporting_time.length > 0
        ? searchForm.completed_reporting_time[0]
        : undefined,
    completed_reporting_enddate:
      searchForm.completed_reporting_time &&
      searchForm.completed_reporting_time.length > 1
        ? searchForm.completed_reporting_time[1]
        : undefined,
    problem_grading: searchForm.problem_grading,
    main_category: searchForm.main_category,
    sub_category: searchForm.sub_category,
    is_import_report: searchForm.is_import_report,
    search_type: searchType,
  };
  const result = await request_statistics_auditdiscovery_statistics(
    trans,
    () => (chartLoading.value = false)
  );
  if (searchType === 1) {
    mainCategoryBindData = result;
  } else if (searchType === 2) {
    subCategoryBindData = result;
  }
  tableDataTable.value = result;
};
// 分页获取某一个分类下的发现数据
const getStatisticAuditDiscoveryPage = async () => {
  // 先要把子分类图表销毁
  if(subCategoryChart){
    subCategoryChart.dispose()
    subCategoryChart = null
    subCategoryBindData = []
  }
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,

    project_guid: searchForm.project_guid,
    client_guid: searchForm.client_guid,
    client_category: searchForm.client_category,
    service_type: searchForm.service_type,
    audit_type: searchForm.audit_type,
    sponsor: searchForm.sponsor,
    sponsor_type: searchForm.sponsor_type,
    research_products: searchForm.research_products,
    research_products_type: searchForm.research_products_type,
    trial_staging: searchForm.trial_staging,
    therapeutic_area: searchForm.therapeutic_area,
    indications: searchForm.indications,
    trial_progress_stage: searchForm.trial_progress_stage,
    project_task_guid: searchForm.project_task_guid,
    audited_party_guid: searchForm.audited_party_guid,
    audited_party_area: searchForm.audited_party_area,
    audited_party_qualification: searchForm.audited_party_qualification,
    hospital_type: searchForm.hospital_type,
    implementation_department: searchForm.implementation_department,
    main_researcher: searchForm.main_researcher,
    completed_reporting_startdate:
      searchForm.completed_reporting_time &&
      searchForm.completed_reporting_time.length > 0
        ? searchForm.completed_reporting_time[0]
        : undefined,
    completed_reporting_enddate:
      searchForm.completed_reporting_time &&
      searchForm.completed_reporting_time.length > 1
        ? searchForm.completed_reporting_time[1]
        : undefined,
    problem_grading: searchForm.problem_grading,
    main_category: searchForm.main_category,
    sub_category: searchForm.sub_category,
    is_import_report: searchForm.is_import_report,
    ProjectNumberSort: ProjectNumberSort.value,
    ProjectTaskNumberSort: ProjectTaskNumberSort.value,
    ReportCompleteDateSort: ReportCompleteDateSort.value,
  };
  tableLoading.value = true;
  const result = await request_statistics_auditdiscovery_page(
    trans,
    () => (tableLoading.value = false)
  );
  tableDataChart.value = result.rows;
  total.value = result.total;
};

// 查询事件
const queryClick = async () => {
  tableDataChart.value = [];
  checkMainCategory.value = "";
  getStatisticAuditDiscoveryPage();
  await getStatisticsDiscovereyStatistics();
  refreshData();
};
// 重新刷新图表数据
const refreshData = () => {
  //刷新数据
  var chart = echarts.getInstanceByDom(
    document.getElementById("mainCategoryChartDom")
  );
  var mainCategoryOption = chart.getOption();
  mainCategoryOption.series = gradings.map((item) => {
    return {
      name: item.name,
      type: "bar",
      // barGap: 0,
      data: mainCategoryBindData.map((item1) => {
        const findData = item1.gradings.find(
          (item2) => item2.grading === item.value
        );
        if (findData) return findData.count;
        else return 0;
      }),
    };
  });
  chart.setOption(mainCategoryOption);
};

// 重置事件
const resetClick = async () => {
  currentPage.value = 1;
  searchForm.project_guid = undefined;
  searchForm.client_guid = undefined;
  searchForm.client_category = undefined;
  searchForm.service_type = undefined;
  searchForm.audit_type = undefined;
  searchForm.sponsor = "";
  searchForm.sponsor_type = undefined;
  searchForm.research_products = "";
  searchForm.research_products_type = undefined;
  searchForm.trial_staging = undefined;
  searchForm.therapeutic_area = undefined;
  searchForm.indications = "";
  searchForm.trial_progress_stage = undefined;
  searchForm.project_task_guid = undefined;
  searchForm.audited_party_guid = undefined;
  searchForm.audited_party_area = undefined;
  searchForm.audited_party_qualification = undefined;
  searchForm.hospital_type = undefined;
  searchForm.implementation_department = "";
  searchForm.main_researcher = "";
  searchForm.completed_reporting_time = undefined;
  searchForm.problem_grading = undefined;
  searchForm.main_category = undefined;
  searchForm.sub_category = undefined;
  searchForm.is_import_report = undefined;
  getStatisticAuditDiscoveryPage();
  await getStatisticsDiscovereyStatistics();
  refreshData();
};
// 处理页码大小改变事件
const handleSizeChange = async (size) => {
  pageSize.value = size;
  await getStatisticAuditDiscoveryPage();
  refreshData();
};
// 处理当前页改变事件
const handleCurrentChange = async (index) => {
  currentPage.value = index;
  await getStatisticAuditDiscoveryPage();
  refreshData();
};
// 导出
const handleExport = async () => {
  fullLoading.value = true;
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,

    project_guid: searchForm.project_guid,
    client_guid: searchForm.client_guid,
    client_category: searchForm.client_category,
    service_type: searchForm.service_type,
    audit_type: searchForm.audit_type,
    sponsor: searchForm.sponsor,
    sponsor_type: searchForm.sponsor_type,
    research_products: searchForm.research_products,
    research_products_type: searchForm.research_products_type,
    trial_staging: searchForm.trial_staging,
    therapeutic_area: searchForm.therapeutic_area,
    indications: searchForm.indications,
    trial_progress_stage: searchForm.trial_progress_stage,
    project_task_guid: searchForm.project_task_guid,
    audited_party_guid: searchForm.audited_party_guid,
    audited_party_area: searchForm.audited_party_area,
    audited_party_qualification: searchForm.audited_party_qualification,
    hospital_type: searchForm.hospital_type,
    implementation_department: searchForm.implementation_department,
    main_researcher: searchForm.main_researcher,
    completed_reporting_startdate:
      searchForm.completed_reporting_time &&
      searchForm.completed_reporting_time.length > 0
        ? searchForm.completed_reporting_time[0]
        : undefined,
    completed_reporting_enddate:
      searchForm.completed_reporting_time &&
      searchForm.completed_reporting_time.length > 1
        ? searchForm.completed_reporting_time[1]
        : undefined,
    problem_grading: searchForm.problem_grading,
    main_category: searchForm.main_category,
    sub_category: searchForm.sub_category,
    is_import_report: searchForm.is_import_report,
    ProjectNumberSort: ProjectNumberSort.value,
    ProjectTaskNumberSort: ProjectTaskNumberSort.value,
    ReportCompleteDateSort: ReportCompleteDateSort.value,
  };
  const result = await request_statistics_auditdiscovery_export(
    trans,
    true,
    () => (fullLoading.value = false)
  );
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = result;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
};
// 单个主分类导出
const handleSingleMaincategoryExport = async () => {
  fullLoading.value = true;
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,

    project_guid: searchForm.project_guid,
    client_guid: searchForm.client_guid,
    client_category: searchForm.client_category,
    service_type: searchForm.service_type,
    audit_type: searchForm.audit_type,
    sponsor: searchForm.sponsor,
    sponsor_type: searchForm.sponsor_type,
    research_products: searchForm.research_products,
    research_products_type: searchForm.research_products_type,
    trial_staging: searchForm.trial_staging,
    therapeutic_area: searchForm.therapeutic_area,
    indications: searchForm.indications,
    trial_progress_stage: searchForm.trial_progress_stage,
    project_task_guid: searchForm.project_task_guid,
    audited_party_guid: searchForm.audited_party_guid,
    audited_party_area: searchForm.audited_party_area,
    audited_party_qualification: searchForm.audited_party_qualification,
    hospital_type: searchForm.hospital_type,
    implementation_department: searchForm.implementation_department,
    main_researcher: searchForm.main_researcher,
    completed_reporting_startdate:
      searchForm.completed_reporting_time &&
      searchForm.completed_reporting_time.length > 0
        ? searchForm.completed_reporting_time[0]
        : undefined,
    completed_reporting_enddate:
      searchForm.completed_reporting_time &&
      searchForm.completed_reporting_time.length > 1
        ? searchForm.completed_reporting_time[1]
        : undefined,
    problem_grading: searchForm.problem_grading,
    main_category: searchForm.main_category,
    sub_category: searchForm.sub_category,
    is_import_report: searchForm.is_import_report,
    ProjectNumberSort: ProjectNumberSort.value,
    ProjectTaskNumberSort: ProjectTaskNumberSort.value,
    ReportCompleteDateSort: ReportCompleteDateSort.value,
  };
  const result =
    await request_statistics_auditdiscovery_singlemaincategory_export(
      trans,
      true,
      () => (fullLoading.value = false)
    );
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = result;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
};
// 处理图表点击事件
const handleMainCategoryChartClick = async (data) => {
  showSubChart.value = true
  checkMainCategory.value = data.name;
  searchForm.main_category = data.name;
  // 获取这个主分类的子分类
  handleMainCategoryChange(data.name)
  await getStatisticAuditDiscoveryPage();
  // 获取子分类图表数据
  await getStatisticsDiscovereyStatistics(2, data.name);
  const findSubCategorys = subCategorysAll.filter(
    (item) => item.remark === data.name
  );
  subCategorys = findSubCategorys;
  showSubChart.value = true
  configSubCategoryCharts()
  initSubCategoryChart();
};
// 初始化子分类图表
const initSubCategoryChart = () => {
  if (subCategoryChart) {
    subCategoryChart.dispose();
    subCategoryChart = null;
  }
  subCategoryChart = echarts.init(
    document.getElementById("subCategoryChartDom")
  );
  subCategoryOption && subCategoryChart.setOption(subCategoryOption);
  subCategoryChart.on("click", handleSubCategoryChartClick);
};

// 处理子分类图表点击事件
const handleSubCategoryChartClick = (data) => {
  searchForm.sub_category = data.name
  // getStatisticAuditDiscoveryPage(2);
};

onBeforeUnmount(() => {
  if (mainCategoryChart) {
    mainCategoryChart.dispose();
    mainCategoryChart = null;
  }
});
// 获取所有项目
const getAllProject = async () => {
  const trans = {};
  const result = await request_project_all(trans);
  projects.value = result;
};
// 获取所有任务
const getProjectTaskAll = async () => {
  const trans = {
    pagesize: -1,
  };
  const result = await request_projecttask_page(trans);
  if (result.total > 0) {
    projectTasks.value = result.rows.map((item) => {
      return { guid: item.guid, number: item.number };
    });
  } else {
    projectTasks.value = [];
  }
};

// 处理主分类改变事件
const handleMainCategoryChange = (value) => {
  searchForm.sub_category = undefined;
  const findMainCategory = searchMainCategorys.value.find(
    (item) => item.value === value
  );
  if (findMainCategory) {
    const findSubCategorys = allSubCategorys.value.filter(
      (item) => item.remark === value
    );
    if (findSubCategorys && findSubCategorys.length > 0) {
      currentSubCategorys.value = findSubCategorys;
    } else {
      currentSubCategorys.value = [];
    }
  } else {
    currentSubCategorys.value = [];
  }
};
// 处理排序改变事件
const handleSortChange = async ({ prop, order }) => {
  ProjectNumberSort.value = undefined;
  ProjectTaskNumberSort.value = undefined;
  ReportCompleteDateSort.value = undefined;

  if (prop === "project_number") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      ProjectNumberSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      ProjectNumberSort.value = true;
    } else {
      ProjectNumberSort.value = undefined;
    }
  }
  if (prop === "project_task_number") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      ProjectTaskNumberSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      ProjectTaskNumberSort.value = true;
    } else {
      ProjectTaskNumberSort.value = undefined;
    }
  }
  if (prop === "completed_reporting_date") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      ReportCompleteDateSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      ReportCompleteDateSort.value = true;
    } else {
      ReportCompleteDateSort.value = undefined;
    }
  }
  getStatisticAuditDiscoveryPage();
};
watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Statistics.AuditDiscovery,
      MenuButtonCodes.Statistics.AuditDiscovery.AuditDiscoverySearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Statistics.AuditDiscovery,
      MenuButtonCodes.Statistics.AuditDiscovery.AuditDiscoveryReset
    );
    // 处理导出
    buttonVisible.exportButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Statistics.AuditDiscovery,
      MenuButtonCodes.Statistics.AuditDiscovery.AuditDiscoveryExport
    );
    // 处理导出
    buttonVisible.exportSingleMaincategoryButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Statistics.AuditDiscovery,
      MenuButtonCodes.Statistics.AuditDiscovery
        .AuditDiscoverySingleMainCateogryExport
    );
  }
});

// 监听字典数据
watchEffect(() => {
  if (store.userInfo) {
    if (store.userInfo.dics && store.userInfo.dics.length > 0) {
      // 试验分期
      const findTrialStagingDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.TrialStaging
      );
      if (findTrialStagingDic) {
        if (findTrialStagingDic.items && findTrialStagingDic.items.length > 0) {
          dicTrialStagings.value = findTrialStagingDic.items;
        } else {
          dicTrialStagings.value = [];
        }
      } else {
        dicTrialStagings.value = [];
      }
      // 试验进展阶段
      const findTrialProgressStagesDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.TrialProgressStage
      );
      if (findTrialProgressStagesDic) {
        if (
          findTrialProgressStagesDic.items &&
          findTrialProgressStagesDic.items.length > 0
        ) {
          dicTrialProgressStages.value = findTrialProgressStagesDic.items;
        } else {
          dicTrialProgressStages.value = [];
        }
      } else {
        dicTrialProgressStages.value = [];
      }
      // 治疗领域
      const findTherapeuticAreaDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.TherapeuticArea
      );
      if (findTherapeuticAreaDic) {
        if (
          findTherapeuticAreaDic.items &&
          findTherapeuticAreaDic.items.length > 0
        ) {
          therapeuticAreas.value = findTherapeuticAreaDic.items;
        } else {
          therapeuticAreas.value = [];
        }
      } else {
        therapeuticAreas.value = [];
      }
      // 委托方类型
      const findClientCategoryDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.ClientCategory
      );
      if (findClientCategoryDic) {
        if (
          findClientCategoryDic.items &&
          findClientCategoryDic.items.length > 0
        ) {
          clientCategorys.value = findClientCategoryDic.items;
        } else {
          clientCategorys.value = [];
        }
      } else {
        clientCategorys.value = [];
      }
      // 服务类型
      const findServiceTypeDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.ServiceType
      );
      if (findServiceTypeDic) {
        if (findServiceTypeDic.items && findServiceTypeDic.items.length > 0) {
          serviceTypes.value = findServiceTypeDic.items;
        } else {
          serviceTypes.value = [];
        }
      } else {
        serviceTypes.value = [];
      }
      // 稽查类型
      const findAuditTypeDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.AuditType
      );
      if (findAuditTypeDic) {
        if (findAuditTypeDic.items && findAuditTypeDic.items.length > 0) {
          auditTypes.value = findAuditTypeDic.items;
        } else {
          auditTypes.value = [];
        }
      } else {
        auditTypes.value = [];
      }
      // 申办者类型
      const findSponsorTypeDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.SponsorType
      );
      if (findSponsorTypeDic) {
        if (findSponsorTypeDic.items && findSponsorTypeDic.items.length > 0) {
          sponsorTypes.value = findSponsorTypeDic.items;
        } else {
          sponsorTypes.value = [];
        }
      } else {
        sponsorTypes.value = [];
      }
      // 研究产品类型
      const findResearchProductsTypeDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.ResearchProductType
      );
      if (findResearchProductsTypeDic) {
        if (
          findResearchProductsTypeDic.items &&
          findResearchProductsTypeDic.items.length > 0
        ) {
          researchProductsTypes.value = findResearchProductsTypeDic.items;
        } else {
          researchProductsTypes.value = [];
        }
      } else {
        researchProductsTypes.value = [];
      }
      // 被稽查方区域
      const findAuditedPartyAreaDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.AuditedPartyArea
      );
      if (findAuditedPartyAreaDic) {
        if (
          findAuditedPartyAreaDic.items &&
          findAuditedPartyAreaDic.items.length > 0
        ) {
          auditedPartyAreas.value = findAuditedPartyAreaDic.items;
        } else {
          auditedPartyAreas.value = [];
        }
      } else {
        auditedPartyAreas.value = [];
      }
      // 被稽查方资质
      const findAuditedPartyQualificationDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.AuditedPartyQualification
      );
      if (findAuditedPartyQualificationDic) {
        if (
          findAuditedPartyQualificationDic.items &&
          findAuditedPartyQualificationDic.items.length > 0
        ) {
          auditedPartyQualifications.value =
            findAuditedPartyQualificationDic.items;
        } else {
          auditedPartyQualifications.value = [];
        }
      } else {
        auditedPartyQualifications.value = [];
      }
      // 医院类型
      const findHospitalTypeDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.HospitalType
      );
      if (findHospitalTypeDic) {
        if (findHospitalTypeDic.items && findHospitalTypeDic.items.length > 0) {
          hospitalTypes.value = findHospitalTypeDic.items;
        } else {
          hospitalTypes.value = [];
        }
      } else {
        hospitalTypes.value = [];
      }
      // 问题分级
      const findDiscoveryGradingDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.DiscoveryGrading
      );
      if (findDiscoveryGradingDic) {
        if (
          findDiscoveryGradingDic.items &&
          findDiscoveryGradingDic.items.length > 0
        ) {
          searchDiscoveryGradings.value = findDiscoveryGradingDic.items;
        } else {
          searchDiscoveryGradings.value = [];
        }
      } else {
        searchDiscoveryGradings.value = [];
      }
      // 主分类
      const findDiscoveryMainCategoryDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.DiscoveryMainCategory
      );
      if (findDiscoveryMainCategoryDic) {
        if (
          findDiscoveryMainCategoryDic.items &&
          findDiscoveryMainCategoryDic.items.length > 0
        ) {
          searchMainCategorys.value = findDiscoveryMainCategoryDic.items;
        } else {
          searchMainCategorys.value = [];
        }
      } else {
        searchMainCategorys.value = [];
      }
      // 子分类
      const findDiscoverySubCategoryDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.DiscoverySubCategory
      );
      if (findDiscoverySubCategoryDic) {
        if (
          findDiscoverySubCategoryDic.items &&
          findDiscoverySubCategoryDic.items.length > 0
        ) {
          allSubCategorys.value = findDiscoverySubCategoryDic.items;
        } else {
          allSubCategorys.value = [];
        }
      } else {
        allSubCategorys.value = [];
      }
    }
  }
});
</script>
<style>
.statistics-ul .el-scrollbar__view {
  height: calc(100% - 20px);
}
</style>
<style scoped lang="scss">
.statistics-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: calc(100% - 28px);
  .operate-ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    .left-li {
      flex: 1;
    }
    .right-li {
      display: flex;
      align-items: center;
    }
  }
  .chart-div {
    height: 300px;
    margin-top: 8px;
    padding-top: 8px;
    background-color: white;
  }
  .hm-table {
    height: 600px !important;
  }
  .oo-div {
    display: flex;
    margin-top: 8px;
    position: relative;
    .type-div {
      display: flex;
      justify-content: center;
    }
    .right-div {
      margin-right: 0;
      .div-block {
        height: 16px;
        display: flex;
        align-items: center;
        margin-left: 16px;
        .div-color {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
        .cc {
          background-color: #6495ed;
        }
        .div-color-name {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
