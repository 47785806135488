import { defineStore } from 'pinia'

export const useStore = defineStore('store', {
  state: () => ({
    userInfo: undefined, // 用户信息
    userMenus: [], // 当前用户的菜单权限数据
    activeMenu: {}, // 激活的菜单
    activePage: {}, // 激活的页面
    showLogin: false, // 是否显示登录弹窗
    lastOperateTime: undefined, // 最近操作时间
    lastOperateTimeIntervalId: undefined, // 最近操作时间定时器id
    keepAliveIsCache: true, // 是否缓存，只有退出登录的时候，Index不需要缓存
  }),

  actions: {
    setKeepAliveCache(payload){
      this.keepAliveIsCache = payload
    },
    // 设置用户信息
    setUserInfo(payload) {
      this.userInfo = payload
    },
    // 设置用户基本信息
    setUserMenu(payload) {
      this.userMenus = payload
    },
    // 设置激活的组件
    setActiveMenu(payload){
      this.activeMenu = Object.assign({}, this.activeMenu, payload)
    },
    // 设置激活的页面
    setActivePage(payload){
      this.activePage = Object.assign({}, this.activePage, payload)
    },
    // 设置登录弹窗是否显示
    setShowLogin(payload){
      this.showLogin = payload
    },
    // 设置最近操作时间
    setLastOperateTime(payload){
      this.lastOperateTime = payload
    },
    // 设置最近操作时间id
    setLastOperateTimeId(payload){
      this.lastOperateTimeIntervalId = payload
    },
  }
})