<template>
  <ul class="announcement-ul" v-loading="fullLoading">
    <li class="hm-search">
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item :label="$t('Components.System.Announcement.Search')">
          <el-input
            v-model="searchForm.inputSearch"
            :placeholder="$t('Components.System.Announcement.TitleCreateName')"
            clearable
          />
        </el-form-item>
        <el-form-item :label="$t('Components.System.Announcement.Type')">
          <el-select
            v-model="searchForm.type"
            :placeholder="$t('Components.System.Announcement.SelectType')"
            clearable
          >
            <el-option
              v-for="item in announcementTypes"
              :key="item.id"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('Components.System.Announcement.Dataflag')">
          <el-select
            v-model="searchForm.dataflag"
            :placeholder="$t('Components.System.Announcement.SelectDataflag')"
            clearable
          >
            <el-option
              :label="$t('Components.System.Announcement.DataflagY')"
              value="true"
            />
            <el-option
              :label="$t('Components.System.Announcement.DataflagN')"
              value="false"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="buttonVisible.searchButton"
            type="primary"
            @click="queryClick"
            :icon="Search"
            >{{ $t("Components.System.Announcement.Search1") }}</el-button
          >
          <el-button
            v-if="buttonVisible.resetButton"
            @click="resetClick"
            :icon="RefreshLeft"
            >{{ $t("Components.System.Announcement.Reset") }}</el-button
          >
        </el-form-item>
      </el-form>
    </li>
    <li class="hm-operate">
      <!-- 操作部分 -->
      <el-button
        v-if="buttonVisible.addButton"
        :style="{ color: 'white' }"
        :icon="Plus"
        :color="ButtonColor.Add"
        @click="
          () => {
            dialogVisible = true;
          }
        "
        >{{ $t("Components.System.Announcement.Add") }}</el-button
      >
      <el-button
        v-if="buttonVisible.editButton"
        :disabled="!(selectAnnouncements && selectAnnouncements.length === 1)"
        :style="{ color: 'white' }"
        :icon="Edit"
        :color="ButtonColor.Edit"
        @click="handleEdit(1)"
        >{{ $t("Components.System.Announcement.Edit") }}</el-button
      >
      <el-button
        v-if="buttonVisible.deleteButton"
        :disabled="!(selectAnnouncements && selectAnnouncements.length > 0)"
        :style="{ color: 'white' }"
        :icon="Delete"
        :color="ButtonColor.Delete"
        @click="handleDeleteBatch"
        >{{ $t("Components.System.Announcement.Delete") }}</el-button
      >
    </li>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          row-key="guid"
          @sort-change="handleSortChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            prop="index"
            width="100"
            :label="$t('Components.System.Announcement.Index')"
          />
          <el-table-column
            prop="title"
            min-width="200"
            show-overflow-tooltip
            :label="$t('Components.System.Announcement.Title')"
          />
          <el-table-column
            prop="type"
            width="100"
            :label="$t('Components.System.Announcement.Type')"
          >
            <template #default="scope">
              <div v-if="scope.row.type">
                <el-text
                  v-if="
                    computeAnnouncementTypeEchoStyle(
                      scope.row.type
                    ) === DictionaryItemEchoStyle.Default
                  "
                  >{{
                    computeAnnouncementType(
                      scope.row.type
                    )
                  }}</el-text
                >
                <el-tag
                v-else
                  :type="computeAnnouncementTypeEchoStyle(scope.row.type)"
                >
                  {{ computeAnnouncementType(scope.row.type) }}
                </el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="dataflag"
            :label="$t('Components.System.Announcement.Dataflag')"
          >
            <template #default="scope">
              <el-switch
                :disabled="!buttonVisible.dataflag"
                v-model="scope.row.dataflag"
                @change="(flag) => handleDataflagChange(flag, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="dataflag"
            :label="$t('Components.System.Announcement.IsShow')"
          >
            <template #default="scope">
              <el-switch
                :disabled="!buttonVisible.isShow"
                v-model="scope.row.is_show"
                @change="(flag) => handleIsShowChange(flag, scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="sort"
            min-width="120"
            sortable
            :label="$t('Components.System.Announcement.Sort')"
          />
          <el-table-column
            prop="createname"
            min-width="120"
            show-overflow-tooltip
            :label="$t('Components.System.Announcement.CreateName')"
          />
          <el-table-column
            prop="createtime"
            sortable
            min-width="120"
            :label="$t('Components.System.Announcement.CreateTime')"
          />
          <el-table-column
            :label="$t('Components.System.Announcement.Operate')"
            width="200"
          >
            <template #default="scope">
              <el-space wrap>
                <el-button
                  v-if="buttonVisible.editButtonLine"
                  :style="{ color: 'white' }"
                  :icon="Edit"
                  :color="ButtonColor.Edit"
                  size="small"
                  @click="handleEdit(2, scope.row)"
                  >{{ $t("Components.System.Announcement.Edit") }}</el-button
                >
                <el-button
                  v-if="buttonVisible.deleteButtonLine"
                  :style="{ color: 'white' }"
                  :icon="Delete"
                  :color="ButtonColor.Delete"
                  size="small"
                  bg
                  @click="handleDelete(scope.row)"
                  >{{ $t("Components.System.Announcement.Delete") }}</el-button
                >
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </li>
    </el-scrollbar>

    <li class="hm-page">
      <el-pagination
      small
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="AllPageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </li>
  </ul>
  <AnnouncementModify
    v-if="dialogVisible"
    @handleCloseDialog="handleCloseDialog"
    :editData="editData"
    @getAnnouncementPage="getAnnouncementPage"
    :announcementTypes="announcementTypes"
  />
</template>
<script setup>
import {
  reactive,
  ref,
  markRaw,
  onMounted,
  computed,
  watchEffect,
} from "vue";
import {
  Plus,
  Delete,
  RefreshLeft,
  Edit,
  Search,
  WarnTriangleFilled,
} from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";
import {
  AllPageSize,
  ButtonColor,
  DefaultPageSize,
} from "@/consts/frame";
import {
  request_announcement_page,
  request_announcement_delete,
  request_announcement_delete_batch,
  request_announcement_dataflag,
  request_announcement_showornot
} from "@/api/apis";
import AnnouncementModify from "@/components/System/Announcement/AnnouncementModify.vue";
import { useStore } from "@/store/index";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";
import { Dics } from "@/consts/dic";
import { DictionaryItemEchoStyle } from "@/consts/enum";

const store = useStore();
const { t } = useI18n();
const searchForm = reactive({
  inputSearch: "",
  dataflag: undefined,
  type: undefined,
});
const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  addButton: false,
  editButton: false,
  deleteButton: false,
  editButtonLine: false,
  deleteButtonLine: false,
  dataflag: false,
  isShow: false,
});
// 当前页码
const currentPage = ref(1);
// 页码大小
const pageSize = ref(DefaultPageSize);
// 表格数据
const tableData = ref([]);
// 表格加载
const tableLoading = ref(false);
// 总数量
const total = ref(0);
// 编辑的数据
const editData = ref({});
// 弹窗是否可见
const dialogVisible = ref(false);
// 创建时间排序规则 如果为undefined 则没有这个参数，如果为true代表倒叙，为false代表正序
const createTimeSort = ref(undefined);
// 选择的通知公告
const selectAnnouncements = ref([]);
// 全屏加载
const fullLoading = ref(false);
// 通知公告类型
const announcementTypes = ref([]);

onMounted(async () => {
  await getAnnouncementPage();
});
// 处理类型成字典数据
const computeAnnouncementType = computed(() => (data) => {
  if (announcementTypes.value) {
    const findAnnnouncementType = announcementTypes.value.find(
      (item) => item.value == data
    );
    if (findAnnnouncementType) {
      return findAnnnouncementType.name;
    }
  }
});
// 处理回显样式
const computeAnnouncementTypeEchoStyle = computed(() => (data) => {
  let echoStyle = ''
  if (announcementTypes.value) {
    const findAnnnouncementType = announcementTypes.value.find(
      (item) => item.value == data
    );
    if (findAnnnouncementType) {
      echoStyle =  findAnnnouncementType.echo_style;
    }
  }
  return !echoStyle || echoStyle === DictionaryItemEchoStyle.Primary ? '' : echoStyle
});
// 分页查询通知公告
const getAnnouncementPage = async () => {
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    text: searchForm.inputSearch,
    dataflag: searchForm.dataflag,
    createTimeSort: createTimeSort.value,
    type: searchForm.type,
  };
  tableLoading.value = true;
  const result = await request_announcement_page(
    trans,
    () => (tableLoading.value = false)
  );
  total.value = result.total;
  tableData.value = result.rows;
  selectAnnouncements.value = [];
  editData.value = {};
};

// 查询事件
const queryClick = () => {
  getAnnouncementPage();
};
// 重置事件
const resetClick = () => {
  searchForm.dataflag = undefined;
  searchForm.inputSearch = "";
  searchForm.type = undefined
  getAnnouncementPage();
};
// 处理页码大小改变事件
const handleSizeChange = (size) => {
  pageSize.value = size;
  getAnnouncementPage();
};
// 处理当前页改变事件
const handleCurrentChange = (index) => {
  currentPage.value = index;
  getAnnouncementPage();
};
// 处理编辑事件
const handleEdit = (type, data) => {
  let row = undefined;
  if (type === 1) {
    // 判断是否有且仅选择了一条用户数据
    if (selectAnnouncements.value.length !== 1) {
      ElMessage.warning(t("Components.System.Announcement.OnlyOneSelectEdit"));
      return;
    }
    row = selectAnnouncements.value[0];
  } else if (type === 2) {
    row = data;
  }
  editData.value = {
    guid: row.guid,
    title: row.title,
    type: row.type,
    sort: row.sort
  };
  dialogVisible.value = true;
};
// 处理回调关闭事件
const handleCloseDialog = () => {
  dialogVisible.value = false;
  editData.value = {};
};
// 处理有效性改变事件
const handleDataflagChange = async (flag, row) => {
  await request_announcement_dataflag(row.guid, true, () => {
    row.dataflag = !row.dataflag;
  });
};
// 处理显示隐藏改变事件
const handleIsShowChange = async (flag, row) => {
  await request_announcement_showornot(row.guid, true,() => {
    row.is_show = !row.is_show;
  });
  getAnnouncementPage()
};
// 处理选择回调事件
const handleSelectionChange = (ammouncements) => {
  selectAnnouncements.value = ammouncements;
};
// 处理删除
const handleDelete = async (row) => {
  ElMessageBox.confirm(
    t("Components.System.Announcement.ConfirmDeleteAnnouncement"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      await request_announcement_delete(row.guid, true);
      getAnnouncementPage();
    })
    .catch(() => {});
};
// 批量删除
const handleDeleteBatch = async () => {
  if (selectAnnouncements.value.length === 0) {
    ElMessage.warning(
      t("Components.System.Announcement.AtLeastOneAnnouncement")
    );
    return;
  }
  ElMessageBox.confirm(
    t("Components.System.Announcement.ConfirmDeleteAnnouncement"),
    t("Common.ElMessageBox.OperateConfirm"),
    {
      confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
      cancelButtonText: t("Common.ElMessageBox.CancelText"),
      type: "warning",
      icon: markRaw(WarnTriangleFilled),
    }
  )
    .then(async () => {
      const trans = {
        guids: selectAnnouncements.value.map((item) => item.guid),
      };
      await request_announcement_delete_batch(trans, true);
      getAnnouncementPage();
    })
    .catch(() => {});
};
// 处理排序改变事件
const handleSortChange = async ({ prop, order }) => {
  if (prop === "createtime") {
    // 创建时间排序
    if (order === "ascending") {
      // 时间正序
      createTimeSort.value = false;
    } else if (order === "descending") {
      // 时间倒叙
      createTimeSort.value = true;
    } else {
      createTimeSort.value = undefined;
    }
  }
  await getAnnouncementPage();
};
watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Announcement,
      MenuButtonCodes.System.Announcement.AnnouncementSearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Announcement,
      MenuButtonCodes.System.Announcement.AnnouncementReset
    );
    // 处理新增
    buttonVisible.addButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Announcement,
      MenuButtonCodes.System.Announcement.AnnouncementAdd
    );
    // 处理编辑
    buttonVisible.editButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Announcement,
      MenuButtonCodes.System.Announcement.AnnouncementEdit
    );
    // 处理删除
    buttonVisible.deleteButton = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Announcement,
      MenuButtonCodes.System.Announcement.AnnouncementDelete
    );
    // 处理删除行
    buttonVisible.editButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Announcement,
      MenuButtonCodes.System.Announcement.AnnouncementEditLine
    );
    // 处理删除行
    buttonVisible.deleteButtonLine = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Announcement,
      MenuButtonCodes.System.Announcement.AnnouncementDeleteLine
    );
    // 处理有效性
    buttonVisible.dataflag = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Announcement,
      MenuButtonCodes.System.Announcement.AnnouncementDataflag
    );
    // 处理显示隐藏
    buttonVisible.isShow = validateMenuButton(
      store.userMenus,
      MenuCodes.System.Announcement,
      MenuButtonCodes.System.Announcement.AnnouncementShowLine
    );
  }
});

watchEffect(() => {
  if (store.userInfo) {
    if (store.userInfo.dics && store.userInfo.dics.length > 0) {
      const findAnnouncementTypeDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.AnnouncementType
      );
      if (findAnnouncementTypeDic) {
        if (
          findAnnouncementTypeDic.items &&
          findAnnouncementTypeDic.items.length > 0
        ) {
          announcementTypes.value = findAnnouncementTypeDic.items;
        } else {
          announcementTypes.value = [];
        }
      } else {
        announcementTypes.value = [];
      }
    } else {
      announcementTypes.value = [];
    }
  }
});
</script>
<style>
.announcement-ul .el-scrollbar__view {
  height: calc(100% - 8px);
}
</style>
<style scoped lang="scss">
.announcement-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  .announcement-li-search {
    // height: 100px;
  }
}
</style>
