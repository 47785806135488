<template>
  <Dialog
    width="60%"
    :title="t('Components.Log.OperateLog.Detail')"
    :showFooter="false"
    @handleCancle="handleCancle"
  >
    <el-form>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label-width="100"
            :label="t('Components.Log.OperateLog.Module') + ':'"
          >
            <el-text
              v-if="props.editData.module === AccessLogModule.SysemModule"
              >{{ $t("Common.AccessLogModule.SystemModule") }}</el-text
            >
            <el-text
              v-else-if="props.editData.module === AccessLogModule.Util"
              >{{ $t("Common.AccessLogModule.UtilModule") }}</el-text
            >
            <el-text
              v-else-if="props.editData.module === AccessLogModule.BaseService"
              >{{ $t("Common.AccessLogModule.BaseServiceModule") }}</el-text
            >
            <el-text
              v-else-if="props.editData.module === AccessLogModule.Log"
              >{{ $t("Common.AccessLogModule.LogModule") }}</el-text
            >
            <el-text
              v-else-if="props.editData.module === AccessLogModule.Database"
              >{{ $t("Common.AccessLogModule.Database") }}</el-text
            >
            <el-text
              v-else-if="props.editData.module === AccessLogModule.Project"
              >{{ $t("Common.AccessLogModule") }}</el-text
            >
            <el-text
              v-else-if="props.editData.module === AccessLogModule.ReviewApprove"
              >{{ $t("Common.AccessLogModule.ReviewApprove") }}</el-text
            >
            <el-text
              v-else-if="props.editData.module === AccessLogModule.Progress"
              >{{ $t("Common.AccessLogModule.Progress") }}</el-text
            >
            <el-text class="mx-1" v-else type="danger">{{
              $t("Common.AccessLogModule.NoModule")
            }}</el-text>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label-width="100"
            :label="t('Components.Log.OperateLog.Type') + ':'"
          >
            <el-tag
              v-if="props.editData.type === AccessLogType.Search"
              effect="dark"
            >
              {{ $t("Common.AccessLogType.Search") }}
            </el-tag>
            <el-tag
              v-else-if="props.editData.type === AccessLogType.Add"
              :style="{ color: 'white' }"
              :color="ButtonColor.Add"
            >
              {{ $t("Common.AccessLogType.Add") }}
            </el-tag>
            <el-tag
              v-else-if="props.editData.type === AccessLogType.Modify"
              :style="{ color: 'white' }"
              :color="ButtonColor.Edit"
            >
              {{ $t("Common.AccessLogType.Modify") }}
            </el-tag>
            <el-tag
              v-else-if="props.editData.type === AccessLogType.SingleDelete"
              :style="{ color: 'white' }"
              :color="ButtonColor.Delete"
            >
              {{ $t("Common.AccessLogType.SingleDelete") }}
            </el-tag>
            <el-tag
              v-else-if="props.editData.type === AccessLogType.BatchDelete"
              :style="{ color: 'white' }"
              :color="ButtonColor.Delete"
            >
              {{ $t("Common.AccessLogType.BatchDelete") }}
            </el-tag>
            <el-tag
              v-else-if="props.editData.type === AccessLogType.Export"
              :style="{ color: 'white' }"
              :color="ButtonColor.Export"
            >
              {{ $t("Common.AccessLogType.Export") }}
            </el-tag>
            <el-tag
              v-else-if="props.editData.type === AccessLogType.Import"
              :style="{ color: 'white' }"
              :color="ButtonColor.Import"
            >
              {{ $t("Common.AccessLogType.Import") }}
            </el-tag>
            <el-tag
              v-else-if="props.editData.type === AccessLogType.Clear"
              :style="{ color: 'white' }"
              :color="ButtonColor.Clear"
            >
              {{ $t("Common.AccessLogType.Clear") }}
            </el-tag>
            <el-tag
              v-else-if="props.editData.type === AccessLogType.Dataflag"
              :style="{ color: 'white' }"
              :color="ButtonColor.DataflagY"
            >
              {{ $t("Common.AccessLogType.Dataflag") }}
            </el-tag>
            <el-tag
              v-else-if="props.editData.type === AccessLogType.Login"
              :style="{ color: 'white' }"
              :color="ButtonColor.Login"
            >
              {{ $t("Common.AccessLogType.Login") }}
            </el-tag>
            <el-tag
              v-else
              :style="{ color: 'white' }"
              :color="ButtonColor.Other"
            >
              {{ $t("Common.AccessLogType.NoType") }}
            </el-tag>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label-width="100"
            :label="t('Components.Log.OperateLog.Method') + ':'"
          >
            <el-text>{{ props.editData.method }}</el-text>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label-width="100"
            :label="t('Components.Log.OperateLog.Address') + ':'"
          >
            <el-text>
              {{ props.editData.address }}
            </el-text>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label-width="100"
            :label="t('Components.Log.OperateLog.IP') + ':'"
          >
            <el-text>{{ props.editData.ip }}</el-text>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label-width="100"
            :label="t('Components.Log.OperateLog.Status') + ':'"
          >
            <el-tag
              v-if="props.editData.status == StatusCode.Success"
              type="success"
            >
              {{ $t("Components.Log.OperateLog.Success") }}
            </el-tag>
            <el-tag v-else type="warning">
              {{ $t("Components.Log.OperateLog.Failure") }}
            </el-tag>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item
            label-width="100"
            :label="t('Components.Log.OperateLog.OperateName') + ':'"
          >
            <el-text>{{ props.editData.createname }}</el-text>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label-width="100"
            :label="t('Components.Log.OperateLog.OperateTime') + ':'"
          >
          <el-text>{{ props.editData.createtime }}</el-text>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            label-width="100"
            :label="t('Components.Log.OperateLog.Request') + ':'"
          >
            <el-text>{{ props.editData.request }}</el-text>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            label-width="100"
            :label="t('Components.Log.OperateLog.Response') + ':'"
          >
            <el-text>{{ props.editData.response }}</el-text>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </Dialog>
</template>
<script setup>
import { onMounted, defineProps, defineEmits } from "vue";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { useI18n } from "vue-i18n";
import { AccessLogType, AccessLogModule ,} from "@/consts/enum";
import {ButtonColor} from "@/consts/frame"
import StatusCode from "@/api/statusCode";

const { t } = useI18n();
const props = defineProps({
  editData: {
    type: Object,
    default: () => {},
  },
});
const emits = defineEmits(["handleCloseDialog"]);

onMounted(async () => {});
// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};
</script>
<style scoped lang="scss"></style>
