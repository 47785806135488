<template>
  <ul class="statistics-ul" v-loading="fullLoading">
    <el-collapse-transition>
      <li class="hm-search" v-show="isShowSearch">
        <!-- 查询部分 -->
        <el-form :inline="true" :model="searchForm" label-width="100">
          <!-- 任务编号 -->
          <el-form-item
            :label="$t('Components.Statistics.ProjectTask.ProjectTaskNumber')"
          >
          <el-select
              clearable
              filterable
              v-model="searchForm.project_task_guid"
              :placeholder="
                $t('Components.Statistics.ProjectTask.PleaseSelectProjectTaskNumber')
              "
            >
              <el-option
                v-for="item in projectTasks"
                :label="item.number"
                :value="item.guid"
                :key="item.guid"
              ></el-option>
              </el-select>
          </el-form-item>
          <!-- 委托方 -->
          <el-form-item :label="$t('Components.Statistics.ProjectTask.Client')">
            <el-select
              v-model="searchForm.client_guid"
              :placeholder="
                $t('Components.Statistics.ProjectTask.PleaseSelectClient')
              "
              clearable
            >
              <el-option
                v-for="item in clients"
                :key="item.guid"
                :label="item.name"
                :value="item.guid"
              />
            </el-select>
          </el-form-item>
          <!-- 委托方类型 -->
          <el-form-item
            :label="$t('Components.Statistics.ProjectTask.Category')"
          >
            <el-select
              v-model="searchForm.client_category"
              :placeholder="
                t('Components.Statistics.ProjectTask.PleaseSelectCategory')
              "
              clearable
            >
              <el-option
                v-for="item in clientCategorys"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 服务类型 -->
          <el-form-item
            :label="$t('Components.Statistics.ProjectTask.ServiceType')"
          >
            <el-select
              v-model="searchForm.service_type"
              :placeholder="
                t('Components.Statistics.ProjectTask.PleaseSelectServiceType')
              "
              clearable
            >
              <el-option
                v-for="item in serviceTypes"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 稽查类型 -->
          <el-form-item
            :label="$t('Components.Statistics.ProjectTask.AuditType')"
          >
            <el-select
              v-model="searchForm.audit_type"
              :placeholder="
                $t('Components.Statistics.ProjectTask.PleaseSelectAuditType')
              "
              clearable
            >
              <el-option
                v-for="item in auditTypes"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 申办者 -->
          <el-form-item
            :label="$t('Components.Statistics.ProjectTask.Sponsor')"
          >
            <el-input
              v-model="searchForm.sponsor"
              :placeholder="
                $t('Components.Statistics.ProjectTask.PleaseInputSponsor')
              "
              clearable
            />
          </el-form-item>
          <!-- 申办者类型 -->
          <el-form-item
            :label="$t('Components.Statistics.ProjectTask.SponsorType')"
          >
            <el-select
              v-model="searchForm.sponsor_type"
              :placeholder="
                $t('Components.Statistics.ProjectTask.PleaseSelectSponsorType')
              "
              clearable
            >
              <el-option
                v-for="item in sponsorTypes"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 研究产品 -->

          <el-form-item
            :label="$t('Components.Statistics.ProjectTask.ResearchProducts')"
          >
            <el-input
              v-model="searchForm.research_products"
              :placeholder="
                $t('Components.Statistics.ProjectTask.PleaseResearchProducts')
              "
              clearable
            />
          </el-form-item>
          <!-- 研究产品类型 -->
          <el-form-item
            :label="
              $t('Components.Statistics.ProjectTask.ResearchProductsType')
            "
          >
            <el-select
              v-model="searchForm.research_products_type"
              :placeholder="
                $t(
                  'Components.Statistics.ProjectTask.PleaseSelectResearchProductsType'
                )
              "
              clearable
            >
              <el-option
                v-for="item in researchProductsTypes"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 试验分期 -->
          <el-form-item
            :label="$t('Components.Statistics.ProjectTask.TrialStaging')"
          >
            <el-select
              v-model="searchForm.trial_staging"
              :placeholder="
                $t('Components.Statistics.ProjectTask.PleaseSelectTrialStaging')
              "
              clearable
            >
              <el-option
                v-for="item in dicTrialStagings"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 治疗领域 -->
          <el-form-item
            :label="$t('Components.Statistics.ProjectTask.TherapeuticArea')"
          >
            <el-select
              v-model="searchForm.therapeutic_area"
              :placeholder="
                $t(
                  'Components.Statistics.ProjectTask.PleaseSelectTherapeuticArea'
                )
              "
              clearable
            >
              <el-option
                v-for="item in therapeuticAreas"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 适应症 -->
          <el-form-item
            :label="$t('Components.Statistics.ProjectTask.Indications')"
          >
            <el-input
              v-model="searchForm.indications"
              :placeholder="
                $t('Components.Statistics.ProjectTask.PleaseInputIndications')
              "
              clearable
            />
          </el-form-item>
          <!-- 试验进展阶段 -->
          <el-form-item
            :label="$t('Components.Statistics.ProjectTask.TrialProgressStage')"
          >
            <el-select
              v-model="searchForm.trial_progress_stage"
              :placeholder="
                $t(
                  'Components.Statistics.ProjectTask.PleaseSelectTrialProgressStage'
                )
              "
              clearable
            >
              <el-option
                v-for="item in dicTrialProgressStages"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 被稽查方 -->
          <el-form-item
            :label="$t('Components.Statistics.ProjectTask.AuditedParty')"
          >
            <el-select
              v-model="searchForm.audited_party_guid"
              :placeholder="
                $t('Components.Statistics.ProjectTask.PleaseSelectAuditedParty')
              "
              clearable
            >
              <el-option
                v-for="item in auditedPartys"
                :key="item.guid"
                :label="item.name"
                :value="item.guid"
              />
            </el-select>
          </el-form-item>
          <!-- 被稽查方区域 -->
          <el-form-item
            :label="$t('Components.Statistics.ProjectTask.AuditedPartyArea')"
          >
            <el-select
              v-model="searchForm.audited_party_area"
              :placeholder="
                $t(
                  'Components.Statistics.ProjectTask.PleaseSelectAuditedPartyArea'
                )
              "
              clearable
            >
              <el-option
                v-for="item in auditedPartyAreas"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 被稽查方资质 -->
          <el-form-item
            :label="
              $t('Components.Statistics.ProjectTask.AuditedPartyQualification')
            "
          >
            <el-select
              v-model="searchForm.audited_party_qualification"
              :placeholder="
                $t(
                  'Components.Statistics.ProjectTask.PleaseSelectAuditedPartyQualification'
                )
              "
              clearable
            >
              <el-option
                v-for="item in auditedPartyQualifications"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 医院类型 -->
          <el-form-item
            :label="$t('Components.Statistics.ProjectTask.HospitalType')"
          >
            <el-select
              v-model="searchForm.hospital_type"
              :placeholder="
                $t('Components.Statistics.ProjectTask.PleaseSelectHospitalType')
              "
              clearable
            >
              <el-option
                v-for="item in hospitalTypes"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- 实施试验科室 -->
          <el-form-item
            :label="
              $t('Components.Statistics.ProjectTask.ImplementationDepartment')
            "
          >
            <el-input
              v-model="searchForm.implementation_department"
              :placeholder="
                $t(
                  'Components.Statistics.ProjectTask.PleaseInputImplementationDepartment'
                )
              "
              clearable
            />
          </el-form-item>
          <!-- 主要研究者 -->
          <el-form-item
            :label="$t('Components.Statistics.ProjectTask.MainResearcher')"
          >
            <el-input
              v-model="searchForm.main_researcher"
              :placeholder="
                $t(
                  'Components.Statistics.ProjectTask.PleaseInputMainResearcher'
                )
              "
              clearable
            />
          </el-form-item>
          <!-- 主稽查员 -->
          <el-form-item
            :label="$t('Components.Statistics.ProjectTask.MainAuditor')"
          >
            <el-select
              v-model="searchForm.main_auditor"
              :placeholder="
                $t('Components.Statistics.ProjectTask.PleaseSelectMainAuditor')
              "
              clearable
            >
              <el-option
                v-for="item in users"
                :key="item.guid"
                :label="item.name"
                :value="item.guid"
              />
            </el-select>
          </el-form-item>
          <!-- 稽查员 -->
          <el-form-item
            :label="$t('Components.Statistics.ProjectTask.Auditor')"
          >
            <el-select
              v-model="searchForm.auditor"
              :placeholder="
                $t('Components.Statistics.ProjectTask.PleaseSelectAuditor')
              "
              clearable
            >
              <el-option
                v-for="item in users"
                :key="item.guid"
                :label="item.name"
                :value="item.guid"
              />
            </el-select>
          </el-form-item>
          <!-- 稽查时间 -->
          <el-form-item
            :label="t('Components.Statistics.ProjectTask.AuditTime')"
          >
            <el-date-picker
              v-model="searchForm.audit_time"
              type="daterange"
              range-separator="-"
              :start-placeholder="
                t('Components.Statistics.ProjectTask.PleaseSelectStartTime')
              "
              :end-placeholder="
                t('Components.Statistics.ProjectTask.PleaseSelectEndTime')
              "
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              v-if="buttonVisible.searchButton"
              type="primary"
              @click="queryClick"
              :icon="Search"
              >{{ $t("Components.Statistics.ProjectTask.Search1") }}</el-button
            >
            <el-button
              v-if="buttonVisible.resetButton"
              @click="resetClick"
              :icon="RefreshLeft"
              >{{ $t("Components.Statistics.ProjectTask.Reset") }}</el-button
            >
          </el-form-item>
        </el-form>
      </li>
    </el-collapse-transition>
    <li class="hm-operate">
      <ul class="operate-ul">
        <li class="left-li">
          <!-- 操作部分 -->
          <el-button
            v-if="buttonVisible.exportButton"
            :style="{ color: 'white' }"
            :icon="Download"
            :color="ButtonColor.Export"
            @click="handleExport"
            >{{ $t("Components.Statistics.ProjectTask.Export") }}</el-button
          >
        </li>
        <li class="right-li">
          <el-space>
            <el-tooltip
              effect="dark"
              :content="
                !isShowSearch
                  ? t('Common.Operate.ShowSearch')
                  : t('Common.Operate.HideSearch')
              "
              placement="top"
            >
              <el-button
                type=""
                circle
                :icon="Search"
                size="small"
                @click="() => (isShowSearch = !isShowSearch)"
              ></el-button>
            </el-tooltip>
          </el-space>
        </li>
      </ul>
    </li>
    <div class="oo-div">
      <div
      :style="{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
      }"
    >
      <el-radio-group
        v-model="searchType"
        size="small"
        @change="handleSearchTypeChange"
      >
      <!-- 适应症 -->
        <el-radio-button :label="1">{{
          t("Components.Statistics.ProjectTask.Indications")
        }}</el-radio-button>
        <!-- 治疗领域 -->
        <el-radio-button :label="2">{{
          t("Components.Statistics.ProjectTask.TherapeuticArea")
        }}</el-radio-button>
        <!-- 试验分期 -->
        <el-radio-button :label="3">{{
          t("Components.Statistics.ProjectTask.TrialStaging")
        }}</el-radio-button>
        <!-- 试验进展阶段 -->
        <el-radio-button :label="4">{{
          t("Components.Statistics.ProjectTask.TrialProgressStage")
        }}</el-radio-button>
        <!-- 研究产品类型 -->
        <el-radio-button :label="5">{{
          t("Components.Statistics.ProjectTask.ResearchProductsType")
        }}</el-radio-button>
        <!-- 申办者类型 -->
        <el-radio-button :label="6">{{
          t("Components.Statistics.ProjectTask.SponsorType")
        }}</el-radio-button>
        <!-- 稽查类型 -->
        <el-radio-button :label="7">{{
          t("Components.Statistics.ProjectTask.AuditType")
        }}</el-radio-button>
        <!-- 被稽查方区域 -->
        <el-radio-button :label="8">{{
          t("Components.Statistics.ProjectTask.AuditedPartyArea")
        }}</el-radio-button>
        <!-- 被稽查方资质 -->
        <el-radio-button :label="9">{{
          t("Components.Statistics.ProjectTask.AuditedPartyQualification")
        }}</el-radio-button>
        <!-- 医院类型 -->
        <el-radio-button :label="10">{{
          t("Components.Statistics.ProjectTask.HospitalType")
        }}</el-radio-button>
      </el-radio-group>

      <div :style="{position:'absolute', right: '0'}">
        <el-icon v-if="!isShowEchart" :style="{marginLeft: '10px'}" @click="()=> isShowEchart=!isShowEchart"><View /></el-icon>
          <el-icon v-else :style="{marginLeft: '10px'}" @click="()=> isShowEchart=!isShowEchart"><Hide /></el-icon>
      </div>
    </div>
    </div>
    
    <el-scrollbar always>
      <!-- <div v-show="searchType === StatisticsProjectTaskSearchType.Indications"> -->
      <div>
        <div id="chartDom" class="chart-div" v-loading="chartLoading" v-show="isShowEchart"></div>
        <li class="hm-operate">
          <!-- 操作部分 -->
          <ul class="operate-ul">
            <li class="left-li">
              <el-button
                v-if="buttonVisible.exportSingleButton"
                :style="{ color: 'white' }"
                :icon="Download"
                :color="ButtonColor.Export"
                @click="handleSingleExport"
                >{{ $t("Components.Statistics.ProjectTask.Export") }}</el-button
              >
            </li>
          </ul>
        </li>
        <li class="hm-table">
          <el-table
            v-loading="tableLoading"
            :data="tableDataChart"
            row-key="guid"
            scrollbar-always-on
            @sort-change="handleSortChange"
          >
          <!-- 任务编号 -->
            <el-table-column
              prop="project_task_number"
              width="200"
              :label="$t('Components.Statistics.ProjectTask.ProjectTaskNumber')"
              sortable
            />
            <!-- 委托方 -->
            <el-table-column
              prop="client_name"
              width="160"
              :label="$t('Components.Statistics.ProjectTask.Client')"
            />
            <!-- 委托方类型 -->
            <el-table-column
              prop="client_category"
              width="120"
              :label="$t('Components.Statistics.ProjectTask.Category')"
            >
              <template #default="scope">
                <EchoStyle :dicData="clientCategorys" :dicType=" Dics.System.ClientCategory" :dicItemValue="scope.row.client_category" />
              </template>
            </el-table-column>
            <!-- 服务类型 -->
            <el-table-column
              prop="service_type"
              width="120"
              :label="$t('Components.Statistics.ProjectTask.ServiceType')"
            >
              <template #default="scope">
                <EchoStyle :dicData="serviceTypes" :dicType=" Dics.System.ServiceType" :dicItemValue="scope.row.service_type" />
              </template>
            </el-table-column>
            <!-- 稽查类型 -->
            <el-table-column
              prop="audit_type"
              width="120"
              :label="$t('Components.Statistics.ProjectTask.AuditType')"
            >
              <template #default="scope">
                <EchoStyle :dicData="auditTypes" :dicType=" Dics.System.AuditType" :dicItemValue="scope.row.audit_type" />
              </template>
            </el-table-column>
            <!-- 申办者 -->
            <el-table-column
              prop="sponsor"
              width="160"
              :label="$t('Components.Statistics.ProjectTask.Sponsor')"
            />
            <!-- 申办者类型 -->
            <el-table-column
              prop="audit_type"
              width="120"
              :label="$t('Components.Statistics.ProjectTask.SponsorType')"
            >
              <template #default="scope">
                <EchoStyle :dicData="sponsorTypes" :dicType=" Dics.System.SponsorType" :dicItemValue="scope.row.sponsor_type" />
              </template>
            </el-table-column>
            <!-- 研究产品 -->
            <el-table-column
              prop="research_products"
              width="160"
              :label="$t('Components.Statistics.ProjectTask.ResearchProducts')"
            />
            <!-- 研究产品类型 -->
            <el-table-column
              width="120"
              :label="$t('Components.Statistics.ProjectTask.ResearchProductsType')"
            >
              <template #default="scope">
                <EchoStyle :dicData="researchProductsTypes" :dicType="Dics.System.ResearchProductType" :dicItemValue="scope.row.research_products_type" />
              </template>
            </el-table-column>
            <!-- 试验分期 -->
            <el-table-column
              width="120"
              :label="$t('Components.Statistics.ProjectTask.TrialStaging')"
            >
              <template #default="scope">
                <EchoStyle :dicData="dicTrialStagings" :dicType="Dics.System.TrialStaging" :dicItemValue="scope.row.trial_staging" />
              </template>
            </el-table-column>
            <!-- 治疗领域 -->
            <el-table-column
              width="120"
              :label="$t('Components.Statistics.ProjectTask.TherapeuticArea')"
            >
              <template #default="scope">
                <EchoStyle :dicData="therapeuticAreas" :dicType="Dics.System.TherapeuticArea" :dicItemValue="scope.row.therapeutic_area" />
              </template>
            </el-table-column>
            <!-- 适应症 -->
            <el-table-column
              prop="indications"
              width="160"
              :label="$t('Components.Statistics.ProjectTask.Indications')"
            />
            <!-- 试验进展阶段 -->
            <el-table-column
              width="120"
              :label="$t('Components.Statistics.ProjectTask.TrialProgressStage')"
            >
              <template #default="scope">
                <EchoStyle :dicData="dicTrialProgressStages" :dicType="Dics.System.TrialProgressStage" :dicItemValue="scope.row.trial_progress_stage" />
              </template>
            </el-table-column>
            <!-- 被稽查方 -->
            <el-table-column
              prop="audited_party_name"
              width="160"
              :label="$t('Components.Statistics.ProjectTask.AuditedParty')"
            />
            <!-- 被稽查方区域 -->
            <el-table-column
              width="120"
              :label="$t('Components.Statistics.ProjectTask.AuditedPartyArea')"
            >
              <template #default="scope">
                <EchoStyle :dicData="auditedPartyAreas" :dicType="Dics.System.AuditedPartyArea" :dicItemValue="scope.row.audited_party_area" />
              </template>
            </el-table-column>
            <!-- 被稽查方资质 -->
            <el-table-column
              width="120"
              :label="$t('Components.Statistics.ProjectTask.AuditedPartyQualification')"
            >
              <template #default="scope">
                <EchoStyle :dicData="auditedPartyQualifications" :dicType="Dics.System.AuditedPartyQualification" :dicItemValue="scope.row.audited_party_qualification" />
              </template>
            </el-table-column>
            <!-- 医院类型 -->
            <el-table-column
              width="120"
              :label="$t('Components.Statistics.ProjectTask.HospitalType')"
            >
              <template #default="scope">
                <EchoStyle :dicData="auditedPartyQualifications" :dicType="Dics.System.HospitalType" :dicItemValue="scope.row.hospital_type" />
              </template>
            </el-table-column>
            <!-- 实施科室 -->
            <el-table-column
              prop="implementation_department"
              width="160"
              :label="$t('Components.Statistics.ProjectTask.ImplementationDepartment')"
            />
            <!-- 主要研究者 -->
            <el-table-column
              prop="main_researcher"
              width="160"
              :label="$t('Components.Statistics.ProjectTask.MainResearcher')"
            />
            <!-- 主稽查员 -->
            <el-table-column
              prop="main_researcher"
              width="160"
              :label="$t('Components.Statistics.ProjectTask.MainAuditor')"
            >
              <template #default="scope">
                <el-tag
                  v-for="item in scope.row.main_auditors"
                  :key="item.user_guid"
                  :style="{ margin: '0 8px 8px 0' }"
                  >{{ item.user_name }}</el-tag
                >
              </template>
            </el-table-column>
            <!-- 稽查员 -->
            <el-table-column
              prop="main_researcher"
              width="160"
              :label="$t('Components.Statistics.ProjectTask.Auditor')"
            >
              <template #default="scope">
                <el-tag
                  v-for="item in scope.row.auditors"
                  :key="item.user_guid"
                  :style="{ margin: '0 8px 8px 0' }"
                  >{{ item.user_name }}</el-tag
                >
              </template>
            </el-table-column>
            <!-- 实际稽查开始时间 -->
            <el-table-column
              prop="actual_audit_starttime_str"
              width="160"
              :label="$t('Components.Statistics.ProjectTask.ActualAuditStartTime')"
              sortable
            >
            </el-table-column>
            <!-- 实际稽查结束时间 -->
            <el-table-column
              prop="actual_audit_endtime_str"
              width="160"
              :label="$t('Components.Statistics.ProjectTask.ActualAuditEndTime')"
              sortable
            >
            </el-table-column>


          </el-table>
        </li>
      </div>
    </el-scrollbar>
        <li class="hm-page">
          <el-pagination
            small
            v-model:current-page="currentPage"
            v-model:page-size="pageSize"
            :page-sizes="AllPageSize"
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </li>
  </ul>
</template>
<script setup>
import {
  reactive,
  ref,
  onMounted,
  watchEffect,
  onBeforeUnmount,
} from "vue";
import { RefreshLeft, Search, Download } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import { AllPageSize, ButtonColor, DefaultPageSize } from "@/consts/frame";
import {
  request_user_page,
  request_client_page,
  request_auditedparty_page,
  request_statistics_proejct_task,
  request_statistics_projecttask_page,
  request_statistics_project_task_export,
  request_statistics_project_task_single_export,
  request_projecttask_page
} from "@/api/apis";
// import AuditedPartyModify from "@/components/Database/AuditedParty/AuditedPartyModify";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";
import { useStore } from "@/store/index";
import { Dics } from "@/consts/dic";
import * as echarts from "echarts";
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import {
  StatisticsProjectTaskSearchType,
} from "@/consts/enum";
import EchoStyle from '@/components/Common/EchoStyle.vue'

const store = useStore();
const { t } = useI18n();
const searchForm = reactive({
  project_task_guid: "", // 任务编号
  client_guid: undefined, // 委托方
  client_category: undefined, // 委托方类型
  service_type: undefined, // 服务类型
  audit_type: undefined, // 稽查类型
  sponsor: "", // 申办者
  sponsor_type: undefined, // 申办者类型
  research_products: "", // 研究产品
  research_products_type: undefined, // 研究产品类型
  trial_staging: undefined, // 试验分期
  therapeutic_area: undefined, // 治疗领域
  indications: "", // 适应症
  trial_progress_stage: undefined, // 试验进展阶段
  audited_party_guid: undefined, // 被稽查方
  audited_party_area: undefined, // 被稽查方区域
  audited_party_qualification: undefined, // 被稽查方资质
  hospital_type: undefined, // 医院类型
  implementation_department: "", // 实施科室
  main_researcher: "", // 主要研究者
  main_auditor: undefined, // 主稽查员
  auditor: undefined, // 稽查员
  audit_time: undefined, // 稽查时间
});
const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  exportButton: false,
  exportSingleButton: false,
});
const isShowSearch = ref(true);
// 当前页码
const currentPage = ref(1);
// 页码大小
const pageSize = ref(DefaultPageSize);
// 图标表格数据
const tableDataChart = ref([]);
// 表格模式表格数据
const tableDataTable = ref([]);
// 表格加载
const tableLoading = ref(false);
// 总数量
const total = ref(0);
// 全屏加载
const fullLoading = ref(false);

// 被稽查方
const auditedPartys = ref([]);
// 委托方
const clients = ref([]);
const users = ref([]);


const chartLoading = ref(true);

// 委托方分类
const clientCategorys = ref([]);
// 服务类型字典集合
const serviceTypes = ref([]);
// 稽查类型字典集合
const auditTypes = ref([]);
// 申办者类型字典集合
const sponsorTypes = ref([]);
// 研究产品类型
const researchProductsTypes = ref([]);
// 试验分期
const dicTrialStagings = ref([]);
// 治疗领域
const therapeuticAreas = ref([]);
// 试验进展阶段
const dicTrialProgressStages = ref([]);
// 被稽查方区域字典集合
const auditedPartyAreas = ref([]);
// 被稽查方资质字典集合
const auditedPartyQualifications = ref([]);
// 医院类型字典集合
const hospitalTypes = ref([]);
// 所有任务
const projectTasks = ref([])

const isShowEchart = ref(true)

const ProjectTaskNumberSort = ref(undefined)
const ActualAuditStartTimeSort = ref(undefined)
const ActualAuditEndTimeSort = ref(undefined)

// 选中的柱状图
const checkBarName = ref("");
// 模式，1：代表适应症；2：代表治疗领域
const searchType = ref(StatisticsProjectTaskSearchType.Indications);

let option = null;

echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer,
]);

const app = {};
let myChart;
let bindData = [];

onMounted(async () => {
  getUserAll();
  getClientAll();
  getAuditedPartyAll();
  getProjectTaskAll();
  getStatisticProjectTaskPage();
  await getStatisticsProjectTaskStatistics();
  configCharts();
  myChart = echarts.init(document.getElementById("chartDom"));
  option && myChart.setOption(option);
  myChart.on("click", handleChartClick);
});
// 配置图标
const configCharts = () => {
  app.config = {
    rotate: 90,
    align: "left",
    verticalAlign: "middle",
    position: "insideBottom",
    distance: 15,
    onChange: function () {},
  };
  option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    // color: ["#ee6666", "#FF8C00", "#FFA07A", "#B0C4DE"],
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ["line", "bar", "stack"] },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    xAxis: [
      {
        type: "category",
        axisTick: { show: false },
        data: bindData.map((item) => item.name),
        axisLabel: {
          //x轴文字的配置
          show: true,
          interval: 0, //使x轴文字显示全
          rotate: 45,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        minInterval: 1,
      },
    ],

    series: [
      {
        type: "bar",
        barWidth: 30,
        // barGap: 0,
        data: bindData.map((item) => item.count),
       
        label: {
            show: true, //开启显示
            position: "top", //在上方显示
              //数值样式
              color: "black",
              fontSize: 16,
          },
      },
    ],
    // series: [],
  };
};
// 获取所有用户
const getUserAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
    is_system: false,
  };
  const result = await request_user_page(trans);
  if (result.total > 0) {
    users.value = result.rows;
  } else {
    users.value = [];
  }
};
// 获取所有客户
const getClientAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
    is_system: false,
  };
  const result = await request_client_page(trans);
  if (result.total > 0) clients.value = result.rows;
  else clients.value = [];
};
// 获取所有被稽查方
const getAuditedPartyAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
    is_system: false,
  };
  const result = await request_auditedparty_page(trans);
  if (result.total > 0) auditedPartys.value = result.rows;
  else auditedPartys.value = [];
};
// 查询稽查发现统计数据
const getStatisticsProjectTaskStatistics = async () => {
  const trans = {
    project_task_guid: searchForm.project_task_guid, // 任务编号
    client_guid: searchForm.client_guid, // 委托方
    client_category: searchForm.client_category, // 委托方类型
    service_type: searchForm.service_type, // 服务类型
    audit_type: searchForm.audit_type, // 稽查类型
    sponsor: searchForm.sponsor, // 申办者
    sponsor_type: searchForm.sponsor_type, // 申办者类型
    research_products: searchForm.research_products, // 研究产品
    research_products_type: searchForm.research_products_type, // 研究产品类型
    trial_staging: searchForm.trial_staging, // 试验分期
    therapeutic_area: searchForm.therapeutic_area, // 治疗领域
    indications: searchForm.indications, // 适应症
    trial_progress_stage: searchForm.trial_progress_stage, // 试验进展阶段
    audited_party_guid: searchForm.audited_party_guid, // 被稽查方
    audited_party_area: searchForm.audited_party_area, // 被稽查方区域
    audited_party_qualification: searchForm.audited_party_qualification, // 被稽查方资质
    hospital_type: searchForm.hospital_type, // 医院类型
    implementation_department: searchForm.implementation_department, // 实施科室
    main_researcher:searchForm.main_researcher, // 主要研究者
    main_auditor: searchForm.main_auditor, // 主稽查员
    auditor: searchForm.auditor, // 稽查员
    audit_starttime:
      searchForm.audit_time && searchForm.audit_time.length > 0
        ? searchForm.audit_time[0]
        : undefined,
    audit_endtime:
      searchForm.audit_time && searchForm.audit_time.length > 1
        ? searchForm.audit_time[1]
        : undefined,
    search_type: searchType.value,
  };
  const result = await request_statistics_proejct_task(
    trans,
    () => (chartLoading.value = false)
  );
  bindData = result;
  tableDataTable.value = result;
};
// 分页获取某一个适应症或者治疗领域下的数据
const getStatisticProjectTaskPage = async () => {
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,

    project_task_guid: searchForm.project_task_guid, // 任务编号
    client_guid: searchForm.client_guid, // 委托方
    client_category: searchForm.client_category, // 委托方类型
    service_type: searchForm.service_type, // 服务类型
    audit_type: searchForm.audit_type, // 稽查类型
    sponsor: searchForm.sponsor, // 申办者
    sponsor_type: searchForm.sponsor_type, // 申办者类型
    research_products: searchForm.research_products, // 研究产品
    research_products_type: searchForm.research_products_type, // 研究产品类型
    trial_staging: searchForm.trial_staging, // 试验分期
    therapeutic_area: searchForm.therapeutic_area, // 治疗领域
    indications: searchForm.indications, // 适应症
    trial_progress_stage: searchForm.trial_progress_stage, // 试验进展阶段
    audited_party_guid: searchForm.audited_party_guid, // 被稽查方
    audited_party_area: searchForm.audited_party_area, // 被稽查方区域
    audited_party_qualification: searchForm.audited_party_qualification, // 被稽查方资质
    hospital_type: searchForm.hospital_type, // 医院类型
    implementation_department: searchForm.implementation_department, // 实施科室
    main_researcher:searchForm.main_researcher, // 主要研究者
    main_auditor: searchForm.main_auditor, // 主稽查员
    auditor: searchForm.auditor, // 稽查员
    audit_starttime:
      searchForm.audit_time && searchForm.audit_time.length > 0
        ? searchForm.audit_time[0]
        : undefined,
    audit_endtime:
      searchForm.audit_time && searchForm.audit_time.length > 1
        ? searchForm.audit_time[1]
        : undefined,
    search_type: searchType.value,

    relate_data: checkBarName.value,
    ProjectTaskNumberSort: ProjectTaskNumberSort.value,
    ActualAuditStartTimeSort: ActualAuditStartTimeSort.value,
    ActualAuditEndTimeSort: ActualAuditEndTimeSort.value
  };
  tableLoading.value = true;
  const result = await request_statistics_projecttask_page(
    trans,
    () => (tableLoading.value = false)
  );
  tableDataChart.value = result.rows;
  total.value = result.total;
};

// 查询事件
const queryClick = async () => {
  tableDataChart.value = [];
  checkBarName.value = "";
  getStatisticProjectTaskPage();
  await getStatisticsProjectTaskStatistics();
  refreshData();
};
// 重新刷新图表数据
const refreshData = () => {
  //刷新数据
  var chart = echarts.getInstanceByDom(document.getElementById("chartDom"));
  var option = chart.getOption();

  option.xAxis = [
    {
      type: "category",
      axisTick: { show: false },
      data: bindData.map((item) => item.name),
      axisLabel: {
          //x轴文字的配置
          show: true,
          interval: 0, //使x轴文字显示全
          rotate: 45,
        },
    },
  ];
  (option.series = [
    {
      type: "bar",
      // barGap: 0,
      data: bindData.map((item) => item.count),
    },
  ]),
    chart.setOption(option);
};

// 重置事件
const resetClick = async () => {
  currentPage.value = 1
  searchForm.project_task_guid = ''
  searchForm.client_guid = ''
  searchForm.client_category = undefined
  searchForm.service_type = undefined
  searchForm.audit_type = undefined
  searchForm.sponsor = ''
  searchForm.sponsor_type = undefined
  searchForm.research_products = ''
  searchForm.research_products_type = undefined
  searchForm.trial_staging = undefined
  searchForm.therapeutic_area = undefined
  searchForm.indications = ''
  searchForm.trial_progress_stage = undefined
  searchForm.audited_party_guid = undefined
  searchForm.audited_party_area = undefined
  searchForm.audited_party_qualification = undefined
  searchForm.hospital_type = undefined
  searchForm.implementation_department = ''
  searchForm.main_researcher = ''
  searchForm.main_auditor = undefined
  searchForm.auditor = undefined
  searchForm.audit_time = undefined
  searchForm.search_type = undefined
  searchForm.relate_data = undefined
  getStatisticProjectTaskPage();
  await getStatisticsProjectTaskStatistics();
  refreshData();
};
// 处理页码大小改变事件
const handleSizeChange = async (size) => {
  pageSize.value = size;
  await getStatisticProjectTaskPage();
  refreshData();
};
// 处理当前页改变事件
const handleCurrentChange = async (index) => {
  currentPage.value = index;
  await getStatisticProjectTaskPage();
  refreshData();
};
// 导出
const handleExport = async () => {
  fullLoading.value = true;
  const trans = {
    project_task_guid: searchForm.project_task_guid, // 任务编号
    client_guid: searchForm.client_guid, // 委托方
    client_category: searchForm.client_category, // 委托方类型
    service_type: searchForm.service_type, // 服务类型
    audit_type: searchForm.audit_type, // 稽查类型
    sponsor: searchForm.sponsor, // 申办者
    sponsor_type: searchForm.sponsor_type, // 申办者类型
    research_products: searchForm.research_products, // 研究产品
    research_products_type: searchForm.research_products_type, // 研究产品类型
    trial_staging: searchForm.trial_staging, // 试验分期
    therapeutic_area: searchForm.therapeutic_area, // 治疗领域
    indications: searchForm.indications, // 适应症
    trial_progress_stage: searchForm.trial_progress_stage, // 试验进展阶段
    audited_party_guid: searchForm.audited_party_guid, // 被稽查方
    audited_party_area: searchForm.audited_party_area, // 被稽查方区域
    audited_party_qualification: searchForm.audited_party_qualification, // 被稽查方资质
    hospital_type: searchForm.hospital_type, // 医院类型
    implementation_department: searchForm.implementation_department, // 实施科室
    main_researcher:searchForm.main_researcher, // 主要研究者
    main_auditor: searchForm.main_auditor, // 主稽查员
    auditor: searchForm.auditor, // 稽查员
    audit_starttime:
      searchForm.audit_time && searchForm.audit_time.length > 0
        ? searchForm.audit_time[0]
        : undefined,
    audit_endtime:
      searchForm.audit_time && searchForm.audit_time.length > 1
        ? searchForm.audit_time[1]
        : undefined,
    search_type: searchType.value,
    ProjectTaskNumberSort: ProjectTaskNumberSort.value,
    ActualAuditStartTimeSort: ActualAuditStartTimeSort.value,
    ActualAuditEndTimeSort: ActualAuditEndTimeSort.value
  };
  const result = await request_statistics_project_task_export(
    trans,
    true,
    () => (fullLoading.value = false)
  );
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = result;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
};
// 单个主分类导出
const handleSingleExport = async () => {
  fullLoading.value = true;
  const trans = {
    project_task_guid: searchForm.project_task_guid, // 任务编号
    client_guid: searchForm.client_guid, // 委托方
    client_category: searchForm.client_category, // 委托方类型
    service_type: searchForm.service_type, // 服务类型
    audit_type: searchForm.audit_type, // 稽查类型
    sponsor: searchForm.sponsor, // 申办者
    sponsor_type: searchForm.sponsor_type, // 申办者类型
    research_products: searchForm.research_products, // 研究产品
    research_products_type: searchForm.research_products_type, // 研究产品类型
    trial_staging: searchForm.trial_staging, // 试验分期
    therapeutic_area: searchForm.therapeutic_area, // 治疗领域
    indications: searchForm.indications, // 适应症
    trial_progress_stage: searchForm.trial_progress_stage, // 试验进展阶段
    audited_party_guid: searchForm.audited_party_guid, // 被稽查方
    audited_party_area: searchForm.audited_party_area, // 被稽查方区域
    audited_party_qualification: searchForm.audited_party_qualification, // 被稽查方资质
    hospital_type: searchForm.hospital_type, // 医院类型
    implementation_department: searchForm.implementation_department, // 实施科室
    main_researcher:searchForm.main_researcher, // 主要研究者
    main_auditor: searchForm.main_auditor, // 主稽查员
    auditor: searchForm.auditor, // 稽查员
    audit_starttime:
      searchForm.audit_time && searchForm.audit_time.length > 0
        ? searchForm.audit_time[0]
        : undefined,
    audit_endtime:
      searchForm.audit_time && searchForm.audit_time.length > 1
        ? searchForm.audit_time[1]
        : undefined,
        search_type: searchType.value,
    relate_data: checkBarName.value,
    ProjectTaskNumberSort: ProjectTaskNumberSort.value,
    ActualAuditStartTimeSort: ActualAuditStartTimeSort.value,
    ActualAuditEndTimeSort: ActualAuditEndTimeSort.value
  };
  const result = await request_statistics_project_task_single_export(
    trans,
    true,
    () => (fullLoading.value = false)
  );
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = result;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
};
// 处理图表点击事件
const handleChartClick = (data) => {
  checkBarName.value = data.name;
  getStatisticProjectTaskPage();
};

const handleSearchTypeChange = async () => {
  tableDataChart.value = [];
  checkBarName.value = "";
  currentPage.value = 1;

  getStatisticProjectTaskPage();
  await getStatisticsProjectTaskStatistics();
  refreshData();
};

// 获取所有任务
const getProjectTaskAll = async () => {
  const trans = {
    pagesize: -1,
  };
  const result = await request_projecttask_page(trans);
  if (result.total > 0) {
    projectTasks.value = result.rows.map((item) => {
      return { guid: item.guid, number: item.number };
    });
  } else {
    projectTasks.value = [];
  }
};
onBeforeUnmount(() => {
  if (myChart) {
    myChart.dispose();
    myChart = null;
  }
});
// 处理排序改变事件
const handleSortChange = async ({ prop, order }) => {
  ProjectTaskNumberSort.value = undefined;
  ActualAuditStartTimeSort.value = undefined;
  ActualAuditEndTimeSort.value = undefined;

  if (prop === "project_task_number") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      ProjectTaskNumberSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      ProjectTaskNumberSort.value = true;
    } else {
      ProjectTaskNumberSort.value = undefined;
    }
  }
  if (prop === "actual_audit_starttime_str") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      ActualAuditStartTimeSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      ActualAuditStartTimeSort.value = true;
    } else {
      ActualAuditStartTimeSort.value = undefined;
    }
  }
  if (prop === "actual_audit_endtime_str") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      ActualAuditEndTimeSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      ActualAuditEndTimeSort.value = true;
    } else {
      ActualAuditEndTimeSort.value = undefined;
    }
  }
  getStatisticProjectTaskPage()
}

watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Statistics.TaskStatistics,
      MenuButtonCodes.Statistics.TaskStatistics.ProjectTaskStatisticsSearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Statistics.TaskStatistics,
      MenuButtonCodes.Statistics.TaskStatistics.ProjectTaskStatisticsReset
    );
    // 处理导出
    buttonVisible.exportButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Statistics.TaskStatistics,
      MenuButtonCodes.Statistics.TaskStatistics.ProjectTaskStatisticsExport
    );
    // 处理导出单个
    buttonVisible.exportSingleButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Statistics.TaskStatistics,
      MenuButtonCodes.Statistics.TaskStatistics
        .ProjectTaskStatisticsExportSingle
    );
  }
});

// 监听字典数据
watchEffect(() => {
  if (store.userInfo) {
    if (store.userInfo.dics && store.userInfo.dics.length > 0) {
      // 试验分期
      const findTrialStagingDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.TrialStaging
      );
      if (findTrialStagingDic) {
        if (findTrialStagingDic.items && findTrialStagingDic.items.length > 0) {
          dicTrialStagings.value = findTrialStagingDic.items;
        } else {
          dicTrialStagings.value = [];
        }
      } else {
        dicTrialStagings.value = [];
      }
      // 试验进展阶段
      const findTrialProgressStagesDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.TrialProgressStage
      );
      if (findTrialProgressStagesDic) {
        if (
          findTrialProgressStagesDic.items &&
          findTrialProgressStagesDic.items.length > 0
        ) {
          dicTrialProgressStages.value = findTrialProgressStagesDic.items;
        } else {
          dicTrialProgressStages.value = [];
        }
      } else {
        dicTrialProgressStages.value = [];
      }
      // 治疗领域
      const findTherapeuticAreaDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.TherapeuticArea
      );
      if (findTherapeuticAreaDic) {
        if (
          findTherapeuticAreaDic.items &&
          findTherapeuticAreaDic.items.length > 0
        ) {
          therapeuticAreas.value = findTherapeuticAreaDic.items;
        } else {
          therapeuticAreas.value = [];
        }
      } else {
        therapeuticAreas.value = [];
      }
      // 委托方类型
      const findClientCategoryDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.ClientCategory
      );
      if (findClientCategoryDic) {
        if (
          findClientCategoryDic.items &&
          findClientCategoryDic.items.length > 0
        ) {
          clientCategorys.value = findClientCategoryDic.items;
        } else {
          clientCategorys.value = [];
        }
      } else {
        clientCategorys.value = [];
      }
      // 服务类型
      const findServiceTypeDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.ServiceType
      );
      if (findServiceTypeDic) {
        if (findServiceTypeDic.items && findServiceTypeDic.items.length > 0) {
          serviceTypes.value = findServiceTypeDic.items;
        } else {
          serviceTypes.value = [];
        }
      } else {
        serviceTypes.value = [];
      }
      // 稽查类型
      const findAuditTypeDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.AuditType
      );
      if (findAuditTypeDic) {
        if (findAuditTypeDic.items && findAuditTypeDic.items.length > 0) {
          auditTypes.value = findAuditTypeDic.items;
        } else {
          auditTypes.value = [];
        }
      } else {
        auditTypes.value = [];
      }
      // 申办者类型
      const findSponsorTypeDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.SponsorType
      );
      if (findSponsorTypeDic) {
        if (findSponsorTypeDic.items && findSponsorTypeDic.items.length > 0) {
          sponsorTypes.value = findSponsorTypeDic.items;
        } else {
          sponsorTypes.value = [];
        }
      } else {
        sponsorTypes.value = [];
      }
      // 研究产品类型
      const findResearchProductsTypeDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.ResearchProductType
      );
      if (findResearchProductsTypeDic) {
        if (
          findResearchProductsTypeDic.items &&
          findResearchProductsTypeDic.items.length > 0
        ) {
          researchProductsTypes.value = findResearchProductsTypeDic.items;
        } else {
          researchProductsTypes.value = [];
        }
      } else {
        researchProductsTypes.value = [];
      }
      // 被稽查方区域
      const findAuditedPartyAreaDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.AuditedPartyArea
      );
      if (findAuditedPartyAreaDic) {
        if (
          findAuditedPartyAreaDic.items &&
          findAuditedPartyAreaDic.items.length > 0
        ) {
          auditedPartyAreas.value = findAuditedPartyAreaDic.items;
        } else {
          auditedPartyAreas.value = [];
        }
      } else {
        auditedPartyAreas.value = [];
      }
      // 被稽查方资质
      const findAuditedPartyQualificationDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.AuditedPartyQualification
      );
      if (findAuditedPartyQualificationDic) {
        if (
          findAuditedPartyQualificationDic.items &&
          findAuditedPartyQualificationDic.items.length > 0
        ) {
          auditedPartyQualifications.value =
            findAuditedPartyQualificationDic.items;
        } else {
          auditedPartyQualifications.value = [];
        }
      } else {
        auditedPartyQualifications.value = [];
      }
      // 医院类型
      const findHospitalTypeDic = store.userInfo.dics.find(
        (item) => item.code === Dics.System.HospitalType
      );
      if (findHospitalTypeDic) {
        if (findHospitalTypeDic.items && findHospitalTypeDic.items.length > 0) {
          hospitalTypes.value = findHospitalTypeDic.items;
        } else {
          hospitalTypes.value = [];
        }
      } else {
        hospitalTypes.value = [];
      }
    }
  }
});
</script>
<style>
.statistics-ul .el-scrollbar__view {
  height: calc(100% - 20px);
}
</style>
<style scoped lang="scss">
.el-scrollbar {
  margin-top: 8px;
}
.statistics-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height:calc(100% - 28px);
  .operate-ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    .left-li {
      flex: 1;
    }
    .right-li {
      display: flex;
      align-items: center;
    }
  }
  .chart-div {
    height: 300px;
    padding-top: 8px;
    background-color: white;
  }
  .hm-table {
    height: 600px !important;
  }
  .oo-div {
    display: flex;
    margin-top: 8px;
    position: relative;
    .type-div {
      display: flex;
      justify-content: center;
    }
    .right-div {
      margin-right: 0;
      .div-block {
        height: 16px;
        display: flex;
        align-items: center;
        margin-left: 16px;
        .div-color {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
        .cc {
          background-color: #6495ed;
        }
        .div-color-name {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
