<template>
  <ul class="projecttaskprogress-ul" v-loading="fullLoading">
    <li class="count-li">
      <ul class="count-root-ul">
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left all">
              <el-icon>
                <i class="iconfont icon-all"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">{{ computedCount(undefined,true) }}</div>
              <div class="bottom-div">{{ t('Components.Progress.ProjectTask.All')}}</div>
            </li>
          </ul>
        </li>
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left tobeallowcate">
              <el-icon>
                <i class="iconfont icon-tobeallowcate"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">{{ computedCount(ProjectTaskProgress.ToBeAllocated) }}</div>
              <div class="bottom-div">{{ t('Components.Progress.ProjectTask.ToBeAllocated')}}</div>
            </li>
          </ul>
        </li>
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left ready">
              <el-icon>
                <i class="iconfont icon-ready"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">{{ computedCount(ProjectTaskProgress.Ready) }}</div>
              <div class="bottom-div">{{ t('Components.Progress.ProjectTask.Ready')}}</div>
            </li>
          </ul>
        </li>
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left auditimplementation">
              <el-icon>
                <i class="iconfont icon-auditimplementation"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">{{ computedCount(ProjectTaskProgress.AuditImplementation) }}</div>
              <div class="bottom-div">{{ t('Components.Progress.ProjectTask.AuditImplementation')}}</div>
            </li>
          </ul>
        </li>
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left reportwriting">
              <el-icon>
                <i class="iconfont icon-reportwriting"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">{{ computedCount(ProjectTaskProgress.ReportWriting) }}</div>
              <div class="bottom-div">{{ t('Components.Progress.ProjectTask.ReportWriting')}}</div>
            </li>
          </ul>
        </li>
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left submitforreview">
              <el-icon>
                <i class="iconfont icon-submitforreview"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">{{ computedCount(ProjectTaskProgress.SubmitForReview) }}</div>
              <div class="bottom-div">{{ t('Components.Progress.ProjectTask.SubmitForReview')}}</div>
            </li>
          </ul>
        </li>
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left underreview">
              <el-icon>
                <i class="iconfont icon-underreview"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">{{ computedCount(ProjectTaskProgress.UnderReview) }}</div>
              <div class="bottom-div">{{ t('Components.Progress.ProjectTask.UnderReview')}}</div>
            </li>
          </ul>
        </li>
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left complte">
              <el-icon>
                <i class="iconfont icon-rewrite"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">{{ computedCount(ProjectTaskProgress.Rewrite) }}</div>
              <div class="bottom-div">{{ t('Components.Progress.ProjectTask.Rewrite')}}</div>
            </li>
          </ul>
        </li>
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left reviewedandapproved">
              <el-icon>
                <i class="iconfont icon-reviewedandapproved"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">{{ computedCount(ProjectTaskProgress.ReviewedAndApproved) }}</div>
              <div class="bottom-div">{{ t('Components.Progress.ProjectTask.ReviewedAndApproved')}}</div>
            </li>
          </ul>
        </li>
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left reportwriting">
              <el-icon>
                <i class="iconfont icon-reportwriting"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">{{ computedCount(ProjectTaskProgress.CAPAFinalize) }}</div>
              <div class="bottom-div">{{ t('Components.Progress.ProjectTask.CAPAFinalize')}}</div>
            </li>
          </ul>
        </li>
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left tobeallowcate">
              <el-icon>
                <i class="iconfont icon-tobeallowcate"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">{{ computedCount(ProjectTaskProgress.Complete) }}</div>
              <div class="bottom-div">{{ t('Components.Progress.ProjectTask.Complete')}}</div>
            </li>
          </ul>
        </li>
        
        <li class="li-single">
          <ul class="single-ul">
            <li class="li-left cancel">
              <el-icon>
                <i class="iconfont icon-cancel"></i>
              </el-icon>
            </li>
            <li class="li-right">
              <div class="top-div">{{ computedCount(ProjectTaskProgress.Cancel) }}</div>
              <div class="bottom-div">{{ t('Components.Progress.ProjectTask.Cancel')}}</div>
            </li>
          </ul>
        </li>
      </ul>
    </li>
    <li class="hm-search">
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item :label="$t('Components.Progress.ProjectTask.ProjectNumber')">
          <el-input
            v-model="searchForm.project_number"
            :placeholder="$t('Components.Progress.ProjectTask.PleaseInputProjectNumber')"
            clearable
          />
        </el-form-item>
        <el-form-item :label="$t('Components.Progress.ProjectTask.Progress')">
          <el-select
            v-model="searchForm.progress"
            :placeholder="$t('Components.Progress.ProjectTask.PleaseSelectProgress')"
            clearable
          >
            <el-option
              v-for="item in projectTaskProgresses"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('Components.Progress.ProjectTask.MainAuditor')"
        >
          <el-select
            v-model="searchForm.main_auditor"
            :placeholder="
              $t('Components.Progress.ProjectTask.PleaseSelectMainAuditor')
            "
            clearable
          >
            <el-option
              v-for="item in users"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('Components.Progress.ProjectTask.Auditor')"
        >
          <el-select
            v-model="searchForm.auditor"
            :placeholder="
              $t('Components.Progress.ProjectTask.PleaseSelectAuditor')
            "
            clearable
          >
            <el-option
              v-for="item in users"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="buttonVisible.searchButton"
            type="primary"
            @click="queryClick"
            :icon="Search"
            >{{ $t("Components.Progress.ProjectTask.Search") }}</el-button
          >
          <el-button
            v-if="buttonVisible.resetButton"
            @click="resetClick"
            :icon="RefreshLeft"
            >{{ $t("Components.Progress.ProjectTask.Reset") }}</el-button
          >
        </el-form-item>
      </el-form>
    </li>
    <li class="hm-operate">
      <!-- 操作部分 -->
      <el-button
        v-if="buttonVisible.exportButton"
        :style="{ color: 'white' }"
        :icon="Download"
        :color="ButtonColor.Export"
        @click="handleExport"
        >{{ $t("Components.Progress.ProjectTask.Export") }}</el-button
      >
    </li>
    <el-scrollbar>
      <li class="hm-table">
        <!-- 表格部分 -->
        <el-table v-loading="tableLoading" :data="tableData" row-key="guid" @sort-change="handleSortChange">
          <el-table-column
            prop="project_number"
            :label="$t('Components.Progress.ProjectTask.ProjectNumber')"
            sortable
          />
          <el-table-column
            prop="number"
            :label="$t('Components.Progress.ProjectTask.ProjectTaskNumber')"
            sortable
          />
          <el-table-column
            prop="number"
            :label="$t('Components.Progress.ProjectTask.MainAuditor')"
          >
          <template #default="scope">
              <el-tag
                v-for="item in scope.row.main_auditors"
                :key="item.user_guid"
                :style="{ margin: '0 8px 8px 0' }"
                >{{ item.user_name }}</el-tag
              >
            </template>
          </el-table-column>
          
          <el-table-column
            prop="number"
            :label="$t('Components.Progress.ProjectTask.Auditor')"
          >
          <template #default="scope">
              <el-tag
                v-for="item in scope.row.auditors"
                :key="item.user_guid"
                :style="{ margin: '0 8px 8px 0' }"
                >{{ item.user_name }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="audited_party_name"
            :label="$t('Components.Progress.ProjectTask.AuditedPartyName')"
          />
          <el-table-column
            prop="project_stage"
            :label="$t('Components.Progress.ProjectTask.ProjectStage')"
          />
          <el-table-column
            prop="implementation_department"
            :label="$t('Components.Progress.ProjectTask.ImplementationDepartment')"
          />
          <el-table-column
            prop="main_researcher"
            :label="$t('Components.Progress.ProjectTask.MainResearcher')"
          />
          <el-table-column
            prop="audit_address"
            :label="$t('Components.Progress.ProjectTask.AuditAddress')"
          />
          <el-table-column
            prop="planned_audit_starttime"
            :label="$t('Components.Progress.ProjectTask.PlannedAuditStartTime')"
            sortable
          />
          <el-table-column
            prop="planned_audit_endtime"
            :label="$t('Components.Progress.ProjectTask.PlannedAuditEndTime')"
            sortable
          />
          <el-table-column
            prop="progress"
            :label="$t('Components.Progress.ProjectTask.Progress')"
          >
          <template #default="scope">
              <el-text v-if="scope.row.progress === ProjectTaskProgress.ToBeAllocated">{{
                t("Components.Project.ProjectTask.ToBeAllocated")
              }}</el-text>
              <el-text v-else-if="scope.row.progress === ProjectTaskProgress.Ready">{{
                t("Components.Project.ProjectTask.Ready")
              }}</el-text>
              <el-text
                v-else-if="scope.row.progress === ProjectTaskProgress.AuditImplementation"
                >{{ t("Components.Project.ProjectTask.AuditImplementation") }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectTaskProgress.ReportWriting"
                >{{ t("Components.Project.ProjectTask.ReportWriting") }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectTaskProgress.SubmitForReview"
                >{{ t("Components.Project.ProjectTask.SubmitForReview") }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectTaskProgress.UnderReview"
                >{{ t("Components.Project.ProjectTask.UnderReview") }}</el-text
              >
              <el-text
                v-else-if="scope.row.progress === ProjectTaskProgress.ReviewedAndApproved"
                >{{ t("Components.Project.ProjectTask.ReviewedAndApproved") }}</el-text
              >
              <el-text v-else-if="scope.row.progress === ProjectTaskProgress.Cancel">{{
                t("Components.Project.ProjectTask.Cancel")
              }}</el-text>
              <el-text v-else-if="scope.row.progress === ProjectTaskProgress.Complete">{{
                t("Components.Project.ProjectTask.Complete")
              }}</el-text>
              <el-text v-else-if="scope.row.progress === ProjectTaskProgress.Rewrite">{{
                t("Components.Project.ProjectTask.Rewrite")
              }}</el-text>
              <el-text v-else-if="scope.row.progress === ProjectTaskProgress.CAPAFinalize">{{
                t("Components.Project.ProjectTask.CAPAFinalize")
              }}</el-text>
            </template>
          </el-table-column>
        </el-table>
      </li>
    </el-scrollbar>

    <li class="hm-page">
      <el-pagination
        small
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="AllPageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </li>
  </ul>
</template>
<script setup>
import { reactive, ref, onMounted, watchEffect, computed } from "vue";
import { RefreshLeft, Search, Download } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import {
  AllPageSize,
  ButtonColor,
  DefaultPageSize,
} from "@/consts/frame";
import {
  request_projecttaskprogress_count,
  request_projecttaskprogress_page,
  request_projecttaskprogress_export,
  request_user_page
} from "@/api/apis";
import { MenuCodes, MenuButtonCodes } from "@/consts/menuAndMenuButtonCode";
import { validateMenuButton } from "@/util/util";
import { useStore } from "@/store/index";
import { ProjectTaskProgress } from "@/consts/enum";

const store = useStore();
const { t } = useI18n();
const searchForm = reactive({
  project_number: "",
  proejct_task_number: '',
  progress: undefined,
  main_auditor: undefined,
  auditor: undefined
});
const buttonVisible = reactive({
  searchButton: false,
  resetButton: false,
  exportButton: false,
});
// 当前页码
const currentPage = ref(1);
// 页码大小
const pageSize = ref(DefaultPageSize);
// 表格数据
const tableData = ref([]);
// 表格加载
const tableLoading = ref(false);
// 总数量
const total = ref(0);
// 全屏加载
const fullLoading = ref(false);

const projectNumberSort = ref(undefined)
const projectTaskNumberSort = ref(undefined)
const planAuditStartTimeSort = ref(undefined)
const planAuditEndTimeSort = ref(undefined)

const countData = ref([]);
const users = ref([])

// 项目任务进度
const projectTaskProgresses = ref([
  {
    label: t("Components.Project.ProjectTask.ToBeAllocated"),
    value: ProjectTaskProgress.ToBeAllocated,
  },
  {
    label: t("Components.Project.ProjectTask.Ready"),
    value: ProjectTaskProgress.Ready,
  },
  {
    label: t("Components.Project.ProjectTask.AuditImplementation"),
    value: ProjectTaskProgress.AuditImplementation,
  },
  {
    label: t("Components.Project.ProjectTask.ReportWriting"),
    value: ProjectTaskProgress.ReportWriting,
  },
  {
    label: t("Components.Project.ProjectTask.SubmitForReview"),
    value: ProjectTaskProgress.SubmitForReview,
  },
  {
    label: t("Components.Project.ProjectTask.UnderReview"),
    value: ProjectTaskProgress.UnderReview,
  },
  {
    label: t("Components.Project.ProjectTask.ReviewedAndApproved"),
    value: ProjectTaskProgress.ReviewedAndApproved,
  },
  {
    label: t("Components.Project.ProjectTask.Cancel"),
    value: ProjectTaskProgress.Cancel,
  },
  {
    label: t("Components.Project.ProjectTask.Complete"),
    value: ProjectTaskProgress.Complete,
  },
  {
    label: t("Components.Project.ProjectTask.Rewrite"),
    value: ProjectTaskProgress.Rewrite,
  },
  {
    label: t("Components.Project.ProjectTask.CAPAFinalize"),
    value: ProjectTaskProgress.CAPAFinalize,
  },
]);
// 计算数量
const computedCount = computed(() => (progress, isAll = false) => {
  if (isAll) {
    if(countData.value){
      let count=  0;
      countData.value.forEach(item=>{
        count += item.count
      })
      return count
    }
  } else {
    if (countData.value) {
      const findData = countData.value.find((item) => item.progress === progress);
      if (findData) {
        return findData.count;
      }
    }
  }

  return 0;
});
onMounted(async () => {
  getProgressProjectTaskPage();
  getProgressProjectTaskCount();
  getUserAll()
});
// 分页查询
const getProgressProjectTaskPage = async () => {
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    project_number: searchForm.project_number,
    proejct_task_number: searchForm.proejct_task_number,
    progress: searchForm.progress,
    main_auditor: searchForm.main_auditor,
    auditor: searchForm.auditor,
    ProjectNumberSort: projectNumberSort.value,
    ProjectTaskNumberSort: projectTaskNumberSort.value,
    PlanAuditStartTimeSort: planAuditStartTimeSort.value,
    PlanAuditEndTimeSort: planAuditEndTimeSort.value
  };
  tableLoading.value = true;
  const result = await request_projecttaskprogress_page(
    trans,
    () => (tableLoading.value = false)
  );
  total.value = result.total;
  tableData.value = result.rows;
};
// 获取进度数量
const getProgressProjectTaskCount = async () => {
  const result = await request_projecttaskprogress_count();
  countData.value = result;
};

// 查询事件
const queryClick = () => {
  getProgressProjectTaskPage();
};
// 重置事件
const resetClick = () => {
  searchForm.progress = undefined;
  searchForm.project_number = "";
  searchForm.proejct_task_number = '';
  searchForm.main_auditor = undefined
  searchForm.auditor = undefined
  getProgressProjectTaskPage();
};
// 处理页码大小改变事件
const handleSizeChange = (size) => {
  pageSize.value = size;
  getProgressProjectTaskPage();
};
// 处理当前页改变事件
const handleCurrentChange = (index) => {
  currentPage.value = index;
  getProgressProjectTaskPage();
};
// 导出
const handleExport = async () => {
  fullLoading.value = true;
  const trans = {
    pagesize: pageSize.value,
    pageindex: currentPage.value,
    project_number: searchForm.project_number,
    proejct_task_number: searchForm.proejct_task_number,
    progress: searchForm.progress,
    main_auditor: searchForm.main_auditor,
    auditor: searchForm.auditor,
    ProjectNumberSort: projectNumberSort.value,
    ProjectTaskNumberSort: projectTaskNumberSort.value,
    PlanAuditStartTimeSort: planAuditStartTimeSort.value,
    PlanAuditEndTimeSort: planAuditEndTimeSort.value
  };
  const result = await request_projecttaskprogress_export(
    trans,
    true,
    () => (fullLoading.value = false)
  );
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = result;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
};
// 获取所有用户
const getUserAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
    is_system: false,
  };
  const result = await request_user_page(trans);
  if (result.total > 0) {
    users.value = result.rows;
  } else {
    users.value = [];
  }
};
// 处理排序改变事件
const handleSortChange = async ({ prop, order }) => {
    projectNumberSort.value = undefined;
    projectTaskNumberSort.value = undefined;
    planAuditStartTimeSort.value = undefined;
    planAuditEndTimeSort.value = undefined;
  if (prop === "project_number") {
    // 序号排序
    if (order === "ascending") {
      // 序号正序
      projectNumberSort.value = false;
    } else if (order === "descending") {
      // 序号倒叙
      projectNumberSort.value = true;
    } else {
      projectNumberSort.value = undefined;
    }
  } else if (prop === "number") {
    if (order === "ascending") projectTaskNumberSort.value = false;
    else if(order === 'descending') projectTaskNumberSort.value = true;
    else projectTaskNumberSort.value = undefined
  }else if (prop === "planned_audit_starttime") {
    if (order === "ascending") planAuditStartTimeSort.value = false;
    else if(order === 'descending') planAuditStartTimeSort.value = true;
    else planAuditStartTimeSort.value = undefined
  }else if (prop === "planned_audit_endtime") {
    if (order === "ascending") planAuditEndTimeSort.value = false;
    else if(order === 'descending') planAuditEndTimeSort.value = true;
    else planAuditEndTimeSort.value = undefined
  }
  await getProgressProjectTaskPage();
};
watchEffect(() => {
  if (store.userMenus) {
    // 处理查询
    buttonVisible.searchButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Progress.ProjectTaskProgress,
      MenuButtonCodes.Progress.ProjectTaskProgress.ProjectTaskProgressSearch
    );
    // 处理重置
    buttonVisible.resetButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Progress.ProjectTaskProgress,
      MenuButtonCodes.Progress.ProjectTaskProgress.ProjectTaskProgressReset
    );
    // 处理导出
    buttonVisible.exportButton = validateMenuButton(
      store.userMenus,
      MenuCodes.Progress.ProjectTaskProgress,
      MenuButtonCodes.Progress.ProjectTaskProgress.ProjectTaskProgressExport
    );
  }
});
</script>
<style>
.projecttaskprogress-ul .el-scrollbar__view {
  height: calc(100% - 8px);
}
</style>
<style scoped lang="scss">
.projecttaskprogress-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  .count-li {
    .count-root-ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      .li-single {
        border-radius: 12px;
        width: 200px;
        height: 80px;
        padding: 8px 28px;
        .single-ul {
          border-radius: 12px;
          display: flex;
          list-style: none;
          margin: 0;
          padding: 0;
          height: 80px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          // 全部
          .all {
            background-color: #519be0;
          }
          // 待分配
          .tobeallowcate {
            background-color: #86bc53;
          }
          // 准备中
          .ready {
            background-color: #90c4ba;
          }
          // 稽查实施
          .auditimplementation {
            background-color: #b066d8;
          }
          // 报告撰写
          .reportwriting{
            background-color: #5f6877;
          }
          // 待审阅
          .submitforreview{
            background-color: #f3cb90;
          }
          // 审阅中
          .underreview{
            background-color: #c3cfe2
          }
          // 审阅通过
          .reviewedandapproved{
            background-color:#2c8cf0
          }
          // 完成
          .complte {
            background-color: #21ba6d;
          }
          // 审批后修改
          .rewrite{
            background-color: #50a7c2;
          }
          // 取消
          .cancel {
            background-color: #f3730e;
          }
          .li-left {
            border-radius: 12px 0 0 12px;
            width: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            .iconfont {
              font-size: 48px;
              color: white;
            }
          }
          .li-right {
            border-radius: 0 12px 12px 0;
            padding: 8px 0;
            width: 140px;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: white;
            .top-div {
              font: 600;
              flex: 1;
              font-size: 2em;
            }
          }
        }
      }
    }
  }
}
</style>
