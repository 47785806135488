<template>
  <el-page-header @back="() => router.back()">
    <template #title>
      {{ t("Common.Back") }}
    </template>
  </el-page-header>
  <ul class="reportappendix-root-ul">
    <li class="reportappendix-root-left-li">
      <el-scrollbar>
        <ReportAppendixLeft
          :projectTaskGuid="projectTaskGuid"
          @handleClearReportAppendixDetail="handleClearReportAppendixDetail"
          @updateSelectProjectTaskReportAppendixGuid="updateSelectProjectTaskReportAppendixGuid"
          :projectTaskData="projectTaskData"
          :selectProjectTaskReportAppendixGuid="selectProjectTaskReportAppendixGuid"
        />
      </el-scrollbar>
    </li>
    <li class="reportappendix-root-right-li">
      <el-scrollbar>
        <ReportAppendixRight
          :projectTaskGuid="projectTaskGuid"
          ref="reportAppendixDetailRef"
          :projectTaskData="projectTaskData"
          :selectProjectTaskReportAppendixGuid="selectProjectTaskReportAppendixGuid"
        />
      </el-scrollbar>
    </li>
  </ul>
</template>
<script setup>
import { onMounted, ref } from "vue";
import ReportAppendixLeft from "@/components/Project/ProjectTask/ReportAppendix/ReportAppendix_Left.vue";
import ReportAppendixRight from "@/components/Project/ProjectTask/ReportAppendix/ReportAppendix_Right.vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { router } from "@/router";
import { request_projecttask_detail } from "@/api/apis";

const { t } = useI18n();
const route = useRoute();
// 右侧对象
const reportAppendixDetailRef = ref();
const projectTaskGuid = (route.query && route.query.guid) ?? "";
const projectTaskData = ref({});
const selectProjectTaskReportAppendixGuid = ref('')

onMounted(() => {
  getProjectTaskDetail();
});

// 获取项目任务guid
const getProjectTaskDetail = async () => {
  const result = await request_projecttask_detail(projectTaskGuid);
  projectTaskData.value = result;
};
// 清空右侧数据
const handleClearReportAppendixDetail = () => {
  reportAppendixDetailRef.value.clearInfo();
};
// 更新选择的报告附件guid
const updateSelectProjectTaskReportAppendixGuid = (reportAppendixGuid)=>{
  selectProjectTaskReportAppendixGuid.value = reportAppendixGuid
}
</script>
<style>
.reportappendix-root-left-li .el-scrollbar__view {
  height: calc(100% - 8px);
}
.reportappendix-root-right-li .el-scrollbar__view {
  height: calc(100% - 8px);
}
</style>
<style scoped lang="scss">
.reportappendix-root-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .reportappendix-root-left-li {
    width: 50%;
    padding-right: 8px;
  }
  .reportappendix-root-right-li {
    padding-left: 8px;
    width: 50%;
  }
}
</style>
