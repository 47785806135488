<template>
  <div class="lock-root-div">
    <div class="lock-content-div">
      <div class="lock-header"><h1>后台管理</h1></div>
      <el-form :model="form" label-width="0px" :rules="rules" ref="formRef">
        <el-form-item label="" prop="account">
          <el-input
            v-model="form.account"
            :prefix-icon="User"
            :placeholder="t('Components.Login.InputAccount')"
          />
        </el-form-item>
        <el-form-item label="" prop="password">
          <el-input
            v-model="form.password"
            type="password"
            :prefix-icon="Lock"
            :placeholder="t('Components.Login.InputPassword')"
          />
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" :style="{ width: '100%' }" @click="handleLogin"
            >锁定账户</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref } from "vue";
import { request_hm_lock } from "@/api/apis";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const form = reactive({
  account: "",
  password: "",
});
const formRef = ref();
// 表单校验规则
const rules = ref({
  account: [
    {
      required: true,
      message: t("Components.Login.InputAccount"),
      trigger: "blur",
    },
  ],
  password: [
    {
      required: true,
      message: t("Components.Login.InputPassword"),
      trigger: "blur",
    },
    {
      min: 6,
      message: t("Components.Login.PasswordMin6"),
      trigger: "change",
    },
  ],
});

const handleLogin = () => {
  formRef.value.validate(async (valid) => {
    if (valid) {
      var userAgent = navigator.userAgent.toLowerCase();
      const deviceDetector = new DeviceDetector();
      const device = deviceDetector.parse(userAgent);
      const data = {
        account: form.account,
        password: form.password,
      };
      await request_hm_lock(data);
    }
  });
};
</script>
<style scoped lang="scss">
.lock-root-div {
  background-color: azure;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .lock-content-div{
    width: 300px;
    height: 500px;
    .lock-header{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
