<template>
  <ul class="my-calendar-ul">
    <li class="hm-search">
      <!-- 查询部分 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item :label="t('Components.AuditCalendar.TaskCalendar.StartDate')">
          <el-date-picker
            v-model="searchForm.startDate "
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
            :placeholder="
              t('Components.AuditCalendar.TaskCalendar.PleaseSelectEndDate')
            "
          />
        </el-form-item>
        <el-form-item :label="t('Components.AuditCalendar.TaskCalendar.EndDate')">
          <el-date-picker
            v-model="searchForm.endDate"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
            :placeholder="
              t('Components.AuditCalendar.TaskCalendar.PleaseSelectEndDate')
            "
          />
        </el-form-item>
        <el-form-item
          :label="$t('Components.AuditCalendar.TaskCalendar.Client')"
        >
          <el-select
            v-model="searchForm.client_guid"
            filterable
            clearable
            :placeholder="
              t('Components.AuditCalendar.TaskCalendar.PleaseSelectClient')
            "
          >
            <el-option
              v-for="item in clients"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('Components.AuditCalendar.TaskCalendar.ProjectNumber')"
        >
          <el-select
            v-model="searchForm.project_guid"
            filterable
            clearable
            :placeholder="
              t(
                'Components.AuditCalendar.TaskCalendar.PleaseSelectProjectNumber'
              )
            "
          >
            <el-option
              v-for="item in projects"
              :key="item.guid"
              :label="item.project_number"
              :value="item.guid"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          :label="
            $t('Components.AuditCalendar.TaskCalendar.ProjectTaskNumber')
          "
        >
          <el-select
            v-model="searchForm.project_task_guid"
            filterable
            clearable
            :placeholder="
              t(
                'Components.AuditCalendar.TaskCalendar.PleaseSelectProjectTaskNumber'
              )
            "
          >
            <el-option
              v-for="item in projectTasks"
              :key="item.guid"
              :label="item.number"
              :value="item.guid"
            />
          </el-select>
        </el-form-item>
        
        <el-form-item
          :label="
            $t('Components.AuditCalendar.TaskCalendar.AuditedParty')
          "
        >
          <el-select
            v-model="searchForm.audited_party_guid"
            filterable
            clearable
            :placeholder="
              t(
                'Components.AuditCalendar.TaskCalendar.PleaseSelectAuditedParty'
              )
            "
          >
            <el-option
              v-for="item in auditedPartys"
              :key="item.guid"
              :label="item.name"
              :value="item.guid"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch" :icon="Search">{{
            $t("Components.AuditCalendar.TaskCalendar.Search")
          }}</el-button>
          <el-button @click="handleReset" :icon="RefreshLeft">{{
            $t("Components.AuditCalendar.TaskCalendar.Reset")
          }}</el-button>
        </el-form-item>
      </el-form>
    </li>
    <div class="div-other">
      <div class="div-zoom">
        <el-space>
          <el-button
            type=""
            size="small"
            @click="
              () => {
                if (zoomSize - 0.1 >= 0) {
                  zoomSize -= 0.1;
                  handleSearch();
                }
              }
            "
          >
            <el-icon><Minus /></el-icon>
          </el-button>

          <span>{{ zoomSizeComputed }}%</span>
          <el-button
            type=""
            size="small"
            @click="
              () => {
                zoomSize += 0.1;
                handleSearch();
              }
            "
          >
            <el-icon><Plus /></el-icon>
          </el-button>
        </el-space>
      </div>
      <div class="div-tip">
        <div class="div-block">
          <div class="div-color not-audit"></div>
          <div class="div-color-name">
            {{ t("Components.AuditCalendar.TaskCalendar.TipNotActualAuditTime") }}
          </div>
        </div>
        <div class="div-block">
          <div class="div-color audit"></div>
          <div class="div-color-name">
            {{ t("Components.AuditCalendar.TaskCalendar.TipHaveActualAuditTime") }}
          </div>
        </div>
      </div>
    </div>

    <div
      :style="{ zoom: zoomSize }"
      class="gantt-no"
      ref="ganttRef"
      v-loading="loading"
    ></div>
  </ul>
  <TaskCalendarModify
    v-if="visibleDialog"
    :editData="editData"
    @handleCloseDialog="handleCloseDialog"
    @handleSearch="handleSearch"
  />
</template>

<script setup>
import { reactive, onMounted, ref, onBeforeUnmount, computed } from "vue";
import { gantt } from "dhtmlx-gantt";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import { request_calendar_projecttask_all,
  request_project_all,
  request_projecttask_page,
  request_client_page,
request_auditedparty_page } from "@/api/apis";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import { Search, RefreshLeft } from "@element-plus/icons-vue";
import TaskCalendarModify from "@/components/AuditCalendar/TaskCalendar/TaskCalendarModify.vue";

const { t } = useI18n();
const searchForm = reactive({
  startDate: undefined,
  endDate: undefined,
  client_guid: undefined,
  project_guid: undefined,
  project_task_guid: undefined,
  audited_party_guid: undefined,
});
const ganttRef = ref();
const visibleDialog = ref(false);
const loading = ref(false);
const clients = ref([]);
const auditedPartys = ref([]);
const projects = ref([]);
const projectTasks = ref([]);

const editData = ref(undefined);
//初始化甘特图
const initGantt = () => {
  // gantt.config.scrollable = true;
  gantt.config.show_links = false;
  gantt.config.drag_progress = false;
  gantt.config.duration_unit = "day";
  gantt.config.duration_step = 1;
  gantt.config.drag_resize = false; // 禁止右侧时间工期 左右拖动
  gantt.config.drag_move = false;
  // gantt.config.show_progress = true; //取消右边表格进度条显示与拖动
  gantt.config.columns = [
    // {
    //   name: "text",
    //   label: t("Components.AuditCalendar.TaskCalendar.Task"),
    //   tree: false,
    //   width: "*",
    //   resize: true,
    //   align: "left",
    //   template: function (obj) {
    //     return `<span title="${obj.text}">${obj.text}</span>`;
    //   },
    // },
    {
      name: "text",
      label: t("Components.AuditCalendar.TaskCalendar.Client"),
      tree: false,
      width: "240",
      align: "left",
      template: function (obj) {
        return `<span title="${obj.data.client_name}">${obj.data.client_name}</span>`;
      },
    },
    {
      name: "text",
      label: t("Components.AuditCalendar.TaskCalendar.ProjectTaskNumber"),
      tree: false,
      width: "240",
      align: "left",
      template: function (obj) {
        return `<span title="${obj.data.number}">${obj.data.number}</span>`;
      },
    },
    {
      name: "text",
      label: t("Components.AuditCalendar.TaskCalendar.AuditedParty"),
      tree: false,
      width: "240",
      align: "left",
      template: function (obj) {
        return `<span title="${obj.data.audited_party_name}">${obj.data.audited_party_name}</span>`;
      },
    },
  ];
  gantt.config.xml_date = "%Y-%m-%d"; // 日期格式化的匹配格式
  gantt.config.scale_height = 180; // 日期栏的高度
  gantt.plugins({
    marker: true,
  });
  // 添加点击事件
  gantt.attachEvent("onTaskClick", (id) => {
    visibleDialog.value = true;
    const findData = tasks.value.data.find((item) => item.id === id);
    if (findData) {
      editData.value = findData.data;
    }
  });
  // 覆盖自带的双击事件
  gantt.attachEvent("onTaskDblClick", () => {});
  gantt.addMarker({
    start_date: new Date(),
    text: t("Components.AuditCalendar.TaskCalendar.Today"),
  });
  gantt.templates.task_class = function (start, end, task) {
    if (task.data.actual_audit_starttime && task.data.actual_audit_endtime) {
      // 有实际时间
      return "complete";
    } else {
      return "plan";
    }
  };
  const weekScaleTemplate = function (date) {
    const mouthMap = {
      Jan: t("Common.Month.January"),
      Feb: t("Common.Month.February"),
      Mar: t("Common.Month.March"),
      Apr: t("Common.Month.April"),
      May: t("Common.Month.May"),
      Jun: t("Common.Month.June"),
      Jul: t("Common.Month.July"),
      Aug: t("Common.Month.August"),
      Sep: t("Common.Month.September"),
      Oct: t("Common.Month.October"),
      Nov: t("Common.Month.November"),
      Dec: t("Common.Month.December"),
    };
    // 可以时使用dayjs 处理返回
    const dateToStr = gantt.date.date_to_str("%d");
    const mToStr = gantt.date.date_to_str("%M");
    const endDate = gantt.date.add(gantt.date.add(date, 1, "week"), -1, "day");
    // 处理一下月份
    return `${dateToStr(date)} - ${dateToStr(endDate)} (${mouthMap[mToStr(date)]})`;
  };
  const dayFormat = function (date) {
    const weeks = [
      t("Common.Week.Sunday"),
      t("Common.Week.Monday"),
      t("Common.Week.Tuesday"),
      t("Common.Week.Wednesday"),
      t("Common.Week.Thursday"),
      t("Common.Week.Friday"),
      t("Common.Week.Saturday"),
    ];
    const weekName = weeks[dayjs(date).day()];
    const dateToStr = gantt.date.date_to_str("%d");
    return `<ul class="gantt-inner-ul" ><li class="gantt-inner-li-day">${dateToStr(
      date
    )}</li><li class="gantt-inner-li-week">${weekName}</li></ul>`;
  };
  gantt.config.scales = [
    { unit: "year", step: 1, format: "%Y" },
    { unit: "week", step: 1, format: weekScaleTemplate },
    { unit: "day", step: 1, format: dayFormat },
  ];
};

const tasks = ref({ data: [], links: [] });
const zoomSize = ref(0.8);

const zoomSizeComputed = computed(() => {
  return (zoomSize.value * 100).toFixed(0);
});
onMounted(async () => {
  getClientsAll()
  getProjectAll()
  getProjectTaskAll()
  getAuditedPartyAll()
  await getProejctTaskCalendar();
  if (ganttRef.value) {
    initGantt();
    gantt.init(ganttRef.value); // 初始化 DOM
    gantt.clearAll();
    gantt.parse(tasks.value); // 传入 tasks
  }
});
// 获取任务日历数据
const getProejctTaskCalendar = async () => {
  loading.value = true;
  const trans = {
    start_date: searchForm.startDate,
    end_date: searchForm.endDate,
    client_guid: searchForm.client_guid,
    project_guid : searchForm.project_guid,
    project_task_guid : searchForm.project_task_guid,
    audited_party_guid: searchForm.audited_party_guid
  };
  const result = await request_calendar_projecttask_all(trans, () => {
    loading.value = false;
  });
  tasks.value.data = result.map((item) => {
    let str
    if(item.auditors && item.auditors.length > 0){
      str = item.auditors.map(obj=>obj.user_name).join(',')
    }else{
      str = ''
    }
    return {
      id: item.guid,
      text: str,
      start_date: item.actual_audit_starttime
        ? item.actual_audit_starttime
        : `${item.planned_audit_starttime}`,
      duration: item.days,
      progress: 0,
      open: true,
      data: item,
    };
  });
};
// 处理查询
const handleSearch = async () => {

  await getProejctTaskCalendar();
  gantt.clearAll();
  gantt.parse(tasks.value);
  gantt.render();
  if(searchForm.startDate){
    gantt.showDate(new Date(searchForm.startDate))
  }
};
// 处理重置
const handleReset = async () => {
  searchForm.startDate = undefined;
  searchForm.endDate = undefined;
  searchForm.client_guid = undefined;
  searchForm.project_guid = undefined;
  searchForm.project_task_guid = undefined;
  searchForm.audited_party_guid = undefined;
  await getProejctTaskCalendar();
  gantt.clearAll();
  gantt.parse(tasks.value);
  gantt.render();
  if(searchForm.startDate){
    gantt.showDate(new Date(searchForm.startDate))
  }
};
// 处理回调关闭事件
const handleCloseDialog = () => {
  visibleDialog.value = false;
  editData.value = undefined;
};
// 获取所有的项目
const getProjectAll = async () => {
  const trans = {
    progresses: [],
    is_audit_service_type: true,
  };
  const result = await request_project_all(trans);
  if (result && result.length > 0) {
    projects.value = result;
  } else {
    projects.value = [];
  }
};
// 获取所有任务
const getProjectTaskAll = async () => {
  const trans = {
    pagesize: -1,
  };
  const result = await request_projecttask_page(trans);
  if (result.total > 0) {
    projectTasks.value = result.rows.map((item) => {
      return { guid: item.guid, number: item.number };
    });
  } else {
    projectTasks.value = [];
  }
};
// 获取所有委托方
const getClientsAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
  };
  const result = await request_client_page(trans);
  if (result.total > 0) {
    clients.value = result.rows;
  } else {
    clients.value = [];
  }
};
// 获取所有被稽查方
const getAuditedPartyAll = async () => {
  const trans = {
    pagesize: -1,
    dataflag: true,
  };
  const result = await request_auditedparty_page(trans);
  if (result.total > 0) {
    auditedPartys.value = result.rows;
  } else {
    auditedPartys.value = [];
  }
};
onBeforeUnmount(() => {
  // gantt.destructor()
});
</script>

<style>
/* .gantt_grid_data{
  overflow: scroll !important;
} */

.gantt_cell {
  padding: 0;
}
.gantt_task_line.complete {
  background-color: #008000;
}
.gantt_task_line.complete .gantt_task_content {
  color: #fff;
}
.gantt_task_line.plan {
  background-color: #ff0000;
}
.gantt_task_line.plan .gantt_task_content {
  color: #fff;
}

::v-deep .gantt-name-box {
  box-sizing: border-box;
  display: block;
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* 这边的每一个格子的高度是总日期高度 / 3 */
.gantt-inner-ul {
  padding: 0;
  margin: 0;
  list-style: none;
  flex-direction: column;
  display: flex;
}
.gantt-inner-ul .gantt-inner-li-day {
  /* 每一个格子的高度 / 2 */
  height: 40px;
  line-height: 40px;
  font-size: 1.4em;
  font-weight: 600;
}
.gantt-inner-ul .gantt-inner-li-week {
  height: 20px;
  line-height: 20px;
}
</style>
<style scoped lang="scss">
.my-calendar-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  .gantt-no {
    height: calc(100% - 80px);
    // margin-top: 8px;
  }
  .div-other {
    height: 32px;
    display: flex;
    .div-zoom {
      width: 100px;
      display: flex;
      align-items: center;
      span {
        font-size: 12px;
      }
      .el-button {
        width: 16px;
        height: 20px;
      }
      
    }
    .div-tip {
      flex: 1;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: flex-end;
        .div-block {
          height: 16px;
          // width: 16px;
          display: flex;
          align-items: center;
          margin-left: 16px;
          .div-color {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
          .not-audit {
            background-color: #ff0000;
          }
          .audit {
            background-color: #008000;
          }
          .div-color-name {
            font-size: 12px;
          }
        }
      }
  }
}
</style>
