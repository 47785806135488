<template>
  <Dialog
    width="40%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form :model="form" label-width="120px" :rules="rules" ref="formRef">
      <el-form-item :label="$t('Components.System.ExcelTemplate.Name')" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item :label="$t('Components.System.ExcelTemplate.Code')" prop="code">
        <template #label v-if="!isAdmin">
          <div :style="{ display: 'flex', justifyContent: 'center' }">
            <span>{{ $t("Components.System.ExcelTemplate.Code") }}</span>
            <div>
              <el-tooltip
                effect="dark"
                :content="t('Components.System.ExcelTemplate.OnlyAdminCanEdit')"
                placement="top"
              >
                <el-icon :style="{marginLeft: '8px'}">
                  <InfoFilled />
                </el-icon>
              </el-tooltip>
            </div>
          </div>
        </template>
        <el-input
          v-model="form.code"
          :disabled="props.editData && props.editData.guid && !isAdmin"
        />
      </el-form-item>
      <el-form-item :label="$t('Components.System.ExcelTemplate.FileName')" prop="path">
        <template #label v-if="!isAdmin">
          <div :style="{ display: 'flex', justifyContent: 'center' }">
            <span>{{ $t("Components.System.ExcelTemplate.FileName") }}</span>
            <div>
              <el-tooltip
                effect="dark"
                :content="t('Components.System.ExcelTemplate.OnlyAdminCanEdit')"
                placement="top"
              >
                <el-icon class="123" :style="{marginLeft: '8px'}">
                  <InfoFilled />
                </el-icon>
              </el-tooltip>
            </div>
          </div>
        </template>
        <el-upload
          :action="request_util_upload_api"
          accept=".xls, .xlsx"
          :on-remove="handleRemove"
          :limit="1"
          name="formFiles"
          :headers="{ token: token }"
          :on-success="handleUploadSuccess"
          v-model:file-list="fileList"
          :before-remove="handleBeforeRemove"
        >
          <el-button
            type="primary"
            :disabled="props.editData && props.editData.guid && !isAdmin"
            >{{ t("Components.System.ExcelTemplate.Upload") }}</el-button
          >
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="t('Components.System.ExcelTemplate.UploadTip')"
            placement="top"
          >
            <el-icon :style="{ marginLeft: '8px', justifyContent: 'center' }">
              <InfoFilled />
            </el-icon>
          </el-tooltip>

          <!-- <template #tip>
            <div class="el-upload__tip">
              
            </div>
          </template> -->
        </el-upload>
      </el-form-item>
      <el-form-item :label="$t('Components.System.ExcelTemplate.Remark')" prop="remark">
        <el-input v-model="form.remark" :rows="5" type="textarea" />
      </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup>
import {
  ref,
  reactive,
  markRaw,
  onMounted,
  defineProps,
  defineEmits,
  computed,
} from "vue";
import { WarnTriangleFilled } from "@element-plus/icons-vue";
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { useI18n } from "vue-i18n";
import {
  request_exceltemplate_add,
  request_exceltemplate_modify,
  request_util_upload_api,
} from "@/api/apis";
import { ElMessageBox, ElMessage } from "element-plus";
import { LocalStorageKey } from "@/consts/frame";
import { InfoFilled } from "@element-plus/icons-vue";
import statusCode from "@/api/statusCode";
import { SystemParamCode } from "@/consts/systemParam";
import { useStore } from "@/store/index";

const { t } = useI18n();
const store = useStore();
const props = defineProps({
  editData: {
    type: Object,
    default: () => {},
  },
});
const token = ref("");
const emits = defineEmits(["handleCloseDialog", "getExcelTemplatePage"]);
// 标题
const dialogTitle = ref("");
// 表单ref
const formRef = ref();
// 表单数据
const form = reactive({
  guid: props.editData.guid,
  name: props.editData.name,
  code: props.editData.code,
  file_name: props.editData.file_name,
  path: props.editData.path,
  remark: props.editData.remark,
});
const fileList = ref([]);

// 表单校验规则
const rules = ref({
  name: [
    {
      required: true,
      message: t("Components.System.ExcelTemplate.ValidateExcelTemplateNameRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.ExcelTemplate.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  code: [
    {
      required: true,
      message: t("Components.System.ExcelTemplate.ValidateExcelTemplateCodeRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.ExcelTemplate.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  path: [
    {
      required: true,
      message: t("Components.System.ExcelTemplate.ValidateExcelTemplateFileRequired"),
      trigger: "change",
    },
  ],
});

// 判断是否可以修改
const isAdmin = computed(() => {
  if (
    store.userInfo &&
    store.userInfo.system_params &&
    store.userInfo.system_params.length > 0
  ) {
    const findData = store.userInfo.system_params.find(
      (item) => item.code === SystemParamCode.AdminAccount
    );
    if (findData) {
      // 找到了这笔数据
      if (findData.value === store.userInfo.user_info.account) {
        // 如果管理员账号和这个账号一致
        return true;
      }
    }
    return false;
  }
  return false
});

onMounted(async () => {
  if (props.editData && props.editData.guid) {
    // 代表修改
    dialogTitle.value = t(
      "Components.System.ExcelTemplate.DialogExcelTemplateTitleMaintance"
    );
    fileList.value = [
      {
        name: props.editData.file_name,
        url: props.editData.path,
      },
    ];
  } else {
    dialogTitle.value = t("Components.System.ExcelTemplate.DialogExcelTemplateTitleAdd");
  }
  if (localStorage) {
    token.value = localStorage.getItem(LocalStorageKey.Token);
  }
});
// 处理提交事件
const handleCommit = async () => {
  if (!formRef.value) return;
  await formRef.value.validate((valid) => {
    if (valid) {
      if (!form.file_name || !form.path) {
        ElMessage.warning(
          t("Components.System.ExcelTemplate.ValidateExcelTemplateFileRequired")
        );
        return;
      }
      ElMessageBox.confirm(
        t("Components.System.ExcelTemplate.ConfirmSaveExcelTemplate"),
        t("Common.ElMessageBox.OperateConfirm"),
        {
          confirmButtonText: t("Common.ElMessageBox.ConfirmText"),
          cancelButtonText: t("Common.ElMessageBox.CancelText"),
          type: "warning",
          icon: markRaw(WarnTriangleFilled),
        }
      )
        .then(async () => {
          await saveExcelTemplate();
        })
        .catch(() => {});
    }
  });
};
// 保模板数据
const saveExcelTemplate = async () => {
  const trans = {
    code: form.code,
    name: form.name,
    file_name: form.file_name,
    path: form.path,
    remark: form.remark,
  };
  if (props.editData && props.editData.guid) {
    // 修改
    await request_exceltemplate_modify(props.editData.guid, trans);
  } else {
    // 新增
    await request_exceltemplate_add(trans);
  }
  emits("getExcelTemplatePage");
  emits("handleCloseDialog");
};
// 处理上传成功
const handleUploadSuccess = async (response) => {
  if (response.code === statusCode.Success) {
    if (response.response && response.response.length > 0) {
      form.file_name = response.response[0].file_name;
      form.path = response.response[0].internet_path;

      fileList.value = [
        {
          name: response.response[0].file_name,
          url: response.response[0].internet_path,
        },
      ];
    } else {
      ElMessage.warning(t("Components.System.ExcelTemplate.ExcelPathNone"));
    }
  } else {
    ElMessage.warning(response.message);
  }
};
// 处理删除
const handleRemove = () => {
  form.file_name = "";
  form.path = "";

  fileList.value = [];
};
// 处理删除之前逻辑，如果是编辑状态的，且不是管理员
const handleBeforeRemove = () => {
  if (props.editData && props.editData.guid && !isAdmin.value) {
    ElMessage.warning(t("Components.System.ExcelTemplate.NotAdminCanDelete"));
    return false;
  } else {
    return true;
  }
};
// 处理关闭事件
const handleCancle = () => {
  emits("handleCloseDialog");
};
</script>
<style scoped lang="scss"></style>
