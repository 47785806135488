<template>
  <el-dialog
    :title="$t('Components.Index.Index.PersonalInfomation')"
    :show-close="false"
    v-model="dialogVisible"
    width="800"
    :close-on-click-modal="false"
    :before-close="handleDialogBeforeClose"
  >
    <template #header="">
      <div class="my-header">
        <el-icon class="close-icon" @click="handleCancle"
          ><CircleCloseFilled
        /></el-icon>
      </div>
    </template>
    <ul class="personalinformation-root" v-loading="loading">
      <li class="left-li">
        <el-upload
          class="avatar-uploader"
          :show-file-list="false"
          :action="request_util_upload_api"
          :multiple="false"
          name="formFiles"
          :headers="{ token: token }"
          :on-success="handleUploadSuccess"
          :before-upload="handleBeforeUpload"
          :on-error="handleError"
        >
          <img :src="data.avator" class="avatar" v-if="data.avator" />
          <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>
        <el-divider content-position="left">{{
          t("Components.Index.PersonalInformation.PersonalInfo")
        }}</el-divider>

        <ul class="baseinfo-ul">
          <li class="baseinfo-left-li">
            <el-icon>
              <i class="iconfont icon-user"></i>
            </el-icon>
            <span class="span-label">{{
              t("Components.Index.PersonalInformation.Name")
            }}</span>
          </li>
          <li class="baseinfo-right-li">
            <el-text>{{ data.name }}</el-text>
          </li>
        </ul>
        <ul class="baseinfo-ul">
          <li class="baseinfo-left-li">
            <el-icon>
              <i class="iconfont icon-account"></i>
            </el-icon>
            <span class="span-label">{{
              t("Components.Index.PersonalInformation.Account")
            }}</span>
          </li>
          <li class="baseinfo-right-li">
            <el-text>{{ data.account }}</el-text>
          </li>
        </ul>
        <ul class="baseinfo-ul">
          <li class="baseinfo-left-li">
            <el-icon>
              <i class="iconfont icon-phone"></i>
            </el-icon>
            <span class="span-label">{{
              t("Components.Index.PersonalInformation.Phone")
            }}</span>
          </li>
          <li class="baseinfo-right-li">
            <el-text>{{ data.phone }}</el-text>
          </li>
        </ul>
        <!-- <ul class="baseinfo-ul">
          <li class="baseinfo-left-li">
            <el-icon>
              <i class="iconfont icon-user"></i>
            </el-icon>
            <span class="span-label">性别</span>
          </li>
          <li class="baseinfo-right-li">{{ data.sex }}</li>
        </ul> -->
        <ul class="baseinfo-ul">
          <li class="baseinfo-left-li">
            <el-icon>
              <i class="iconfont icon-email"></i>
            </el-icon>
            <span class="span-label">{{
              t("Components.Index.PersonalInformation.Email")
            }}</span>
          </li>
          <li class="baseinfo-right-li">
            <el-text>{{ data.email }}</el-text>
          </li>
        </ul>
        <ul class="baseinfo-ul">
          <li class="baseinfo-left-li">
            <el-icon>
              <i class="iconfont icon-department"></i>
            </el-icon>
            <span class="span-label">{{
              t("Components.Index.PersonalInformation.Department")
            }}</span>
          </li>
          <li class="baseinfo-right-li">
            <el-text>{{ data.department_name }}</el-text>
          </li>
        </ul>
        <!-- <ul class="baseinfo-ul noborder">
          <li class="baseinfo-left-li">
            <el-icon>
              <i class="iconfont icon-job"></i>
            </el-icon>
            <span class="span-label">岗位</span>
          </li>
          <li class="baseinfo-right-li">{{ data.job_name }}</li>
        </ul> -->
      </li>

      <li class="right-li">
        <ul class="right-ul">
          <li class="li-name">
            <div :style="{ display: 'flex' }">
              <div>
                <el-text class="span-name">{{ data.name }}</el-text>
              </div>
              <div
                :style="{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '28px',
                }"
              >
                <span class="span-right">
                  <el-icon>
                    <i class="iconfont icon-last"></i>
                  </el-icon>
                </span>
                <span :style="{ marginLeft: '8px' }">
                  {{
                    data.lastLoginlog && data.lastLoginlog
                      ? data.lastLoginlog.last_logintime
                      : ""
                  }}</span
                >
              </div>
            </div>
          </li>
          <li class="li-company">
            {{ data.companyName }}
          </li>
          <li :style="{ marginTop: '12px' }">
            <ul class="tag-ul">
              <li>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  :content="t('Components.Index.PersonalInformation.Role')"
                  placement="top"
                >
                  <el-icon>
                    <i
                      class="iconfont icon-role"
                      :style="{ fontSize: '20px' }"
                    ></i>
                  </el-icon>
                </el-tooltip>
              </li>
              <li :style="{ marginLeft: '12px' }">
                <el-tag
                  :style="{ marginRight: '12px' }"
                  v-for="item in data.roles"
                  :key="item.role_guid"
                  class="mx-1"
                  effect="dark"
                  round
                >
                  {{ item.role_name }}
                </el-tag>
              </li>
            </ul>
          </li>
          <li :style="{ marginTop: '12px' }">
            <ul class="tag-ul">
              <li>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  :content="t('Components.Index.PersonalInformation.Job')"
                  placement="top"
                >
                  <el-icon>
                    <i
                      class="iconfont icon-job"
                      :style="{ fontSize: '20px' }"
                    ></i>
                  </el-icon>
                </el-tooltip>
              </li>
              <li :style="{ marginLeft: '12px' }">
                <el-tag
                  :style="{ marginRight: '12px' }"
                  v-for="item in data.jobs"
                  :key="item.job_guid"
                  type="success"
                  class="mx-1"
                  effect="dark"
                  round
                >
                  {{ item.job_name }}
                </el-tag>
              </li>
            </ul>
          </li>
          <li class="li-info">
            <el-tabs
              v-model="activeName"
              class="demo-tabs"
            >
              <el-tab-pane
                :label="t('Components.Index.PersonalInformation.BaseInfo')"
                name="baseinfo"
              >
                <el-form
                  :model="form"
                  label-width="80px"
                  :rules="rules"
                  ref="formRef"
                >
                  <el-form-item
                    :label="t('Components.Index.PersonalInformation.Name')"
                    prop="name"
                  >
                    <el-input v-model="form.name" />
                  </el-form-item>
                  <el-form-item
                    :label="t('Components.Index.PersonalInformation.Phone')"
                    prop="phone"
                  >
                    <el-input v-model="form.phone" />
                  </el-form-item>
                  <el-form-item
                    :label="t('Components.Index.PersonalInformation.Email')"
                    prop="email"
                  >
                    <el-input v-model="form.email" />
                  </el-form-item>
                  <el-form-item
                    :label="t('Components.Index.PersonalInformation.Sex')"
                  >
                    <el-radio-group v-model="form.sex">
                      <el-radio :label="Sex.Male">{{
                        $t("Components.System.User.Male")
                      }}</el-radio>
                      <el-radio :label="Sex.Female">{{
                        $t("Components.System.User.Female")
                      }}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="">
                    <el-space
                      :style="{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }"
                    >
                      <el-button
                        type="primary"
                        :loading="saveBaseinfoButtonLoading"
                        @click="handleModifyBaseInfo"
                        >{{
                          t("Components.Index.PersonalInformation.Save")
                        }}</el-button
                      >
                    </el-space>
                  </el-form-item>
                </el-form>
              </el-tab-pane>
              <el-tab-pane
                :label="
                  t('Components.Index.PersonalInformation.ModifyPassword')
                "
                name="password"
              >
                <el-form
                  :model="formPassword"
                  label-width="80px"
                  :rules="rulesPassword"
                  ref="formPasswordRef"
                >
                  <el-form-item
                    :label="
                      t('Components.Index.PersonalInformation.OldPassword')
                    "
                    prop="oldPassword"
                  >
                    <el-input
                      type="password"
                      v-model="formPassword.oldPassword"
                    />
                  </el-form-item>
                  <el-form-item
                    :label="
                      t('Components.Index.PersonalInformation.NewPassword1')
                    "
                    prop="newPassword1"
                  >
                    <el-input
                      type="password"
                      v-model="formPassword.newPassword1"
                    />
                  </el-form-item>
                  <el-form-item
                    :label="
                      t('Components.Index.PersonalInformation.NewPassword2')
                    "
                    prop="newPassword2"
                  >
                    <el-input
                      type="password"
                      v-model="formPassword.newPassword2"
                    />
                  </el-form-item>
                  <el-form-item label="">
                    <el-space
                      :style="{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }"
                    >
                      <el-button
                        type="primary"
                        :loading="modifyPasswordButtonLoading"
                        @click="handleModifyPassword"
                        >{{
                          t("Components.Index.PersonalInformation.Save")
                        }}</el-button
                      >
                    </el-space>
                  </el-form-item>
                </el-form>
              </el-tab-pane>
              <!-- <el-tab-pane
                :label="t('Components.Index.PersonalInformation.LoginLog')"
                name="loginlog"
                >Role</el-tab-pane
              > -->
            </el-tabs>
          </li>
        </ul>
      </li>
    </ul>
  </el-dialog>
</template>
<script setup>
import {
  ref,
  defineExpose,
  onMounted,
  defineEmits,
  watchEffect,
  reactive,
} from "vue";
import {} from "@/api/apis";
import { useStore } from "@/store/index";
import { Plus, CircleCloseFilled } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import { SystemParamCode } from "@/consts/systemParam";
import { Sex } from "@/consts/enum";
import {
  request_user_upbaseinfo,
  request_user_uppassword,
  request_user_baseinfo,
  request_util_upload_api,
  request_user_upavator,
} from "@/api/apis";
import { validateEmail, validatePhone } from "@/util/util";
import { LocalStorageKey } from "@/consts/frame";
import statusCode from "@/api/statusCode";
import { ElMessage } from "element-plus";

const { t } = useI18n();
const emits = defineEmits(["handleCancleDialog"]);
const store = useStore();
// 弹窗是否可见
const dialogVisible = ref(true);
// 头像地址
const data = reactive({
  avator: "", //头像
  name: "", // 用户名称
  account: "", // 账号
  nick_name: "", // 昵称
  phone: "", // 手机号
  sex: "", // 性别
  email: "", // 邮箱
  department_name: "", // 部门名称
  jobs: [], // 岗位集合
  roles: [], // 角色集合
  companyName: "", // 公司名称
  lastLoginlog: undefined, // 最近登录信息
  nickName: "", //昵称
});
const activeName = ref("baseinfo");
const formRef = ref();
const formPasswordRef = ref();
const saveBaseinfoButtonLoading = ref(false);
const modifyPasswordButtonLoading = ref(false);
// token
const token = ref("");
const loading = ref(false);

const form = reactive({
  phone: "", // 手机号
  name: "", // 名称
  sex: "", // 性别
  email: "", // 邮箱
});

const formPassword = reactive({
  oldPassword: "",
  newPassword1: "",
  newPassword2: "",
});

// 手机校验
const validatePhone_ = (rule, value, callback) => {
  if (!validatePhone(value)) {
    callback(
      new Error(t("Components.Index.PersonalInformation.InplueValidePhone"))
    );
  } else {
    callback();
  }
};
// 邮箱校验
const validateEmail_ = (rule, value, callback) => {
  if (!validateEmail(value)) {
    callback(
      new Error(t("Components.Index.PersonalInformation.InplueValideEmail"))
    );
  } else {
    callback();
  }
};

const validatePassword1_ = (rile, value, callback) => {
  if (formPassword.newPassword2 != value) {
    callback(
      new Error(t("Components.Index.PersonalInformation.TwoPassworNotEqual"))
    );
  } else {
    callback();
  }
};
const validatePassword2_ = (rile, value, callback) => {
  if (formPassword.newPassword1 != value) {
    callback(
      new Error(t("Components.Index.PersonalInformation.TwoPassworNotEqual"))
    );
  } else {
    formPasswordRef.value.validateField("newPassword1");
    callback();
  }
};

// 表单校验规则
const rules = ref({
  name: [
    {
      required: true,
      message: t("Components.Index.PersonalInformation.ValidateNameRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.Index.PersonalInformation.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  phone: [
    { validator: validatePhone_, trigger: ["blur"] },
    {
      required: true,
      message: t("Components.Index.PersonalInformation.ValidatePhoneRequired"),
      trigger: "blur",
    },
  ],
  email: [{ validator: validateEmail_, trigger: ["blur"] }],
});

const rulesPassword = ref({
  oldPassword: [
    {
      required: true,
      message: t("Components.Index.PersonalInformation.InputOldPassword"),
      trigger: "blur",
    },
    {
      min: 6,
      message: t("Components.Index.PersonalInformation.PasswordMin6"),
      trigger: "change",
    },
  ],
  newPassword1: [
    {
      required: true,
      message: t("Components.Index.PersonalInformation.InputPassword1"),
      trigger: "blur",
    },
    {
      min: 6,
      message: t("Components.Index.PersonalInformation.PasswordMin6"),
      trigger: "change",
    },
    { validator: validatePassword1_, trigger: ["blur"] },
  ],
  newPassword2: [
    {
      required: true,
      message: t("Components.Index.PersonalInformation.InputPassword2"),
      trigger: "blur",
    },
    {
      min: 6,
      message: t("Components.Index.PersonalInformation.PasswordMin6"),
      trigger: "change",
    },
    { validator: validatePassword2_, trigger: ["blur"] },
  ],
  
});
// 挂载
onMounted(() => {
  if (localStorage) {
    token.value = localStorage.getItem(LocalStorageKey.Token);
  }
});

// 处理关闭
const handleCancle = () => {
  emits("handleCancleDialog");
};
// 保存用户基本信息
const handleModifyBaseInfo = async () => {
  if (!formRef.value) return;
  await formRef.value.validate(async (valid) => {
    if (valid) {
      const data = {
        name: form.name,
        email: form.email,
        phone: form.phone,
        sex: form.sex,
      };
      saveBaseinfoButtonLoading.value = true;
      await request_user_upbaseinfo(data, true, () => {
        saveBaseinfoButtonLoading.value = false;
      });
      // 更新成功后需要调用下接口

      // 重新获取用户基本信息
      const result = await request_user_baseinfo();
      // 将用户数据缓存起来
      store.setUserInfo(result);
    }
  });
};
// 处理修改密码
const handleModifyPassword = async () => {
  if (!formPasswordRef.value) return;
  await formPasswordRef.value.validate(async (valid) => {
    if (valid) {
  // 判断两次的密码是否一致
      if(formPassword.oldPassword == formPassword.newPassword1 ){
        ElMessage.warning(t("Components.Index.PersonalInformation.ValidatePasswordOldAndNew"))
        return;
      }
      const data = {
        old_password: formPassword.oldPassword,
        new_password1: formPassword.newPassword1,
        new_password2: formPassword.newPassword2,
      };
      modifyPasswordButtonLoading.value = true;
      const result = await request_user_uppassword(data, true, () => {
        modifyPasswordButtonLoading.value = false;
      });
      //需要清空缓存的密码
      localStorage.setItem(LocalStorageKey.Password, result);
      localStorage.removeItem(LocalStorageKey.Password1);
    }
  });
};
// 处理上传之前
const handleBeforeUpload = () => {
  loading.value = true;
};
// 处理失败
const handleError = (error) => {
  ElMessage.warning(error);
  loading.value = false;
};
const handleUploadSuccess = async (response) => {
  loading.value = false;
  if (response.code === statusCode.Success) {
    if (response.response && response.response.length > 0) {
      // 需要将头像更新进去
      const data = {
        avator: response.response[0].internet_path,
      };
      loading.value = true;
      await request_user_upavator(data, true, () => (loading.value = false));
      // 重新获取用户基本信息
      const result = await request_user_baseinfo(data);
      // 将用户数据缓存起来
      store.setUserInfo(result);
    } else {
      ElMessage.warning(t("Components.Index.PersonalInformation.AvatorNone"));
    }
  } else {
    ElMessage.warning(response.message);
  }
};

// 处理关闭前的动作
const handleDialogBeforeClose = () => {
  handleCancle();
};
watchEffect(() => {
  if (store.userInfo) {
    if (store.userInfo.user_info) {
      data.avator = store.userInfo.user_info.avator;
      data.name = store.userInfo.user_info.name;
      data.account = store.userInfo.user_info.account;
      data.nick_name = store.userInfo.user_info.nick_name;
      data.phone = store.userInfo.user_info.phone;
      data.email = store.userInfo.user_info.email;

      form.sex = store.userInfo.user_info.sex
        ? store.userInfo.user_info.sex
        : Sex.Male;
      form.email = store.userInfo.user_info.email;
      form.name = store.userInfo.user_info.name;
      form.phone = store.userInfo.user_info.phone;
    }

    if (store.userInfo.system_params) {
      const findCompany = store.userInfo.system_params.find(
        (item) => item.code === SystemParamCode.CompanyName
      );
      if (findCompany) {
        data.companyName = findCompany.value;
      }
    }
    if (store.userInfo.last_loginlog) {
      data.lastLoginlog = store.userInfo.last_loginlog;
    }
    if (store.userInfo.roles) {
      data.roles = store.userInfo.roles;
    }
    if (store.userInfo.jobs) {
      data.jobs = store.userInfo.jobs;
    }
    if (store.userInfo.department) {
      data.department_name = store.userInfo.department.department_name;
    }
  }
});

defineExpose({});
</script>
<style>
.personalinformation-root .avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  /* border-radius: 40px; */
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.personalinformation-root .avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.personalinformation-root .el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  text-align: center;
}
</style>
<style scoped lang="scss">
.my-header {
  display: flex;
  justify-content: flex-end;
  .close-icon {
    &:hover {
      cursor: pointer;
    }
  }
}
.personalinformation-root {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  .avatar-uploader {
    display: flex;
    justify-content: center;
    .avatar {
      width: 240px;
      height: 240px;
      display: block;
    }
  }
  .left-li {
    padding: 0 40px 40px 40px;
    width: 280px;
    .baseinfo-ul {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      height: 34px;
      align-items: center;

      border-bottom: solid 1px #d3d3d3;
      .baseinfo-left-li {
        font-weight: 600;
        flex: 20%;
        .span-label {
          margin-left: 4px;
        }
      }
      .baseinfo-right-li {
        flex: 60%;
        display: flex !important;
        justify-content: right !important;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    .noborder {
      border-bottom: solid 0px #d3d3d3 !important;
    }
  }
  .right-li {
    flex: 1;
    min-width: 320px;
    margin-left: 40px;
    .right-ul {
      // display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        margin-bottom: 8px;
      }
      .li-name {
        .span-name {
          font-weight: 900;
          font-size: 2.5em;
        }
        .span-right {
          display: flex;
        }
      }
      .li-company {
        color: #6495ed;
      }
      .tag-ul {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;
        align-items: center;
      }
      .el-tab-pane {
        padding-right: 80px;
        padding-top: 12px;
      }
    }
  }
}
</style>
