<template>
  <Dialog
    width="40%"
    :title="dialogTitle"
    :showFooter="true"
    @handleCommit="handleCommit"
    @handleCancle="handleCancle"
  >
    <el-form :model="menuForm" label-width="80px" :rules="rules" ref="menuRef">
      <el-form-item :label="$t('Components.System.Menu.CNName')" prop="cn_name">
        <el-input v-model="menuForm.cn_name" />
      </el-form-item>
      <el-form-item :label="$t('Components.System.Menu.ENName')" prop="en_name">
        <el-input v-model="menuForm.en_name" />
      </el-form-item>
      <el-form-item :label="$t('Components.System.Menu.Code')" prop="code">
        <el-input v-model="menuForm.code" />
      </el-form-item>
      <el-form-item :label="$t('Components.System.Menu.ParentMenu')">
        <el-tree-select
          :style="{ width: '100%' }"
          v-if="props.lang === LangName.ZHCN"
          v-model="menuForm.parent_guid"
          :data="props.menuDataAll"
          node-key="guid"
          check-strictly
          :render-after-expand="false"
          show-checkbox
          :props="{ label: 'cn_name' }"
          :placeholder="$t('Components.System.Menu.ChooseParentMenu')"
          clearable
        />
        <el-tree-select
          v-if="props.lang === LangName.ENUS"
          v-model="menuForm.parent_guid"
          :data="props.menuDataAll"
          node-key="guid"
          check-strictly
          :render-after-expand="false"
          show-checkbox
          :props="{ label: 'en_name' }"
        />
      </el-form-item>
      <el-form-item :label="$t('Components.System.Menu.Icon')">
        <!-- <el-input v-model="menuForm.icon" /> -->
        <!-- <IconSelector  v-model="menuForm.icon"/> -->
        <IconPicker  @changeIcon="handleIcon" :icon="menuForm.icon" />
      </el-form-item>
      <el-form-item :label="$t('Components.System.Menu.Path')" prop="path">
        <el-input v-model="menuForm.path" />
      </el-form-item>
      <el-form-item :label="$t('Components.System.Menu.Sort')" prop="sort">
        <el-input-number :style="{ width: '100%' }" v-model="menuForm.sort" />
      </el-form-item>
    </el-form>
  </Dialog>
</template>
<script setup>
import Dialog from "@/components/Common/Dialog/DialogIndex.vue";
import { reactive, ref, defineProps, markRaw, defineEmits, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { LangName } from "@/consts/frame";
import IconPicker from "@/components/Common/IconPicker/IconPickerIndex.vue";
import { ElMessageBox } from "element-plus";
import { WarnTriangleFilled } from "@element-plus/icons-vue";
import { request_menu_add, request_menu_modify } from "@/api/apis";

const { t } = useI18n();
const props = defineProps({
  menuDataAll: {
    type: Array,
    default: () => [],
  },
  lang: {
    type: String,
    default: () => "zh-cn",
  },
  editMenuData: {
    type: Object,
    default: () => {},
  },
  addParentGuid: {
    type: String,
    default: () => "",
  },
});
const emits = defineEmits(["handleCloseDialog", "getMenuAll"]);

const handleIcon=  (icon)=>{
  menuForm.icon = icon;
}
// 标题
const dialogTitle = ref("");

const menuForm = reactive({
  cn_name: props.editMenuData.cn_name,
  en_name: props.editMenuData.en_name,
  code: props.editMenuData.code,
  path: props.editMenuData.path,
  sort: props.editMenuData.sort || 0,
  parent_guid: props.editMenuData.guid
    ? props.editMenuData.parent_guid
    : props.addParentGuid,
  icon: props.editMenuData.icon,
});

const menuRef = ref();
// 表单校验规则
const rules = ref({
  cn_name: [
    {
      required: true,
      message: t("Components.System.Menu.ValidateMenuCNNameRequired"),
      trigger: "blur",
    },
    {
      max: 50,
      message: t("Components.System.Menu.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  en_name: [
    {
      required: true,
      message: t("Components.System.Menu.ValidateMenuENNameRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.Menu.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  code: [
    {
      required: true,
      message: t("Components.System.Menu.ValidateMenuCodeRequired"),
      trigger: "blur",
    },
    {
      max: 100,
      message: t("Components.System.Menu.ValidateMaxLength"),
      trigger: "change",
    },
  ],
  path: [
    {
      required: true,
      message: t("Components.System.Menu.ValidateMenuPathRequired"),
      trigger: "blur",
    },
    {
      max: 255,
      message: t("Components.System.Menu.ValidateMaxLength255"),
      trigger: "change",
    },
  ],
  sort: [
    {
      required: true,
      message: t("Components.System.Menu.ValidateMenuSortRequired"),
      trigger: "blur",
    },
  ],
});

onMounted(() => {
  if (props.editMenuData && props.editMenuData.guid) {
    // 代表修改
    dialogTitle.value = t("Components.System.Menu.DialogTitleMaintance");
  } else {
    dialogTitle.value = t("Components.System.Menu.DialogTitleAdd");
  }
});

// 处理提交事件
const handleCommit = async () => {
  // 校验
  if (!menuRef.value) return;
  await menuRef.value.validate((valid) => {
    if (valid) {
      ElMessageBox.confirm(t('Components.System.Menu.ConfirmSaveMenu'), t('Common.ElMessageBox.OperateConfirm'), {
        confirmButtonText: t('Common.ElMessageBox.ConfirmText'),
        cancelButtonText: t('Common.ElMessageBox.CancelText'),
        type: "warning",
        icon: markRaw(WarnTriangleFilled),
      })
        .then(async () => {
          await saveMenu();
        })
        .catch(() => {});
    }
  });
  return;
};
// 保存菜单数据
const saveMenu = async () => {
  const trans = {
    parent_guid: menuForm.parent_guid,
    code: menuForm.code,
    cn_name: menuForm.cn_name,
    en_name: menuForm.en_name,
    icon: menuForm.icon,
    path: menuForm.path,
    sort: menuForm.sort,
  };
  if (props.editMenuData && props.editMenuData.guid) {
    // 修改
    await request_menu_modify(props.editMenuData.guid, trans);
  } else {
    // 新增
    await request_menu_add(trans);
  }
  emits("getMenuAll");
  emits("handleCloseDialog");
};

const handleCancle = () => {
  emits('handleCloseDialog')
};
</script>
<style scoped lang="scss"></style>
