export const LangName = {
  ZHCN: 'zh-cn',
  ENUS: 'en-us'
}

export const LocalStorageKey = {
  // 是否锁定 加密
  IsLock: 'i',
  // 密码 加密（md5），用于校验锁屏
  Password: 'p',
  // 密码 加密(window.btoa)，用于记住密码
  Password1: 'p1',
  // 语言
  Lang: 'l',
  // 模式（亮和暗）
  Mode: 'm',
  // token
  Token: 't',
  // 激活的菜单
  LastActiveMenuPath: 'lamp',
  // 用户信息
  UserInfo: 'u',
  // 记住密码
  RememberPassword: 'r',
  // 账号
  Account: 'a',
  // 网站标题
  Title: 'ti',
  // 跑马灯是否关闭，1代表今日关闭，非1都不关闭
  MQ: 'mq',
  // 菜单标签
  MenuTag: 'mt'
}
// 语言
export const Language = {
  Chinese: 'zh-cn',
  English: 'en-us'
}
// 亮黑模式
export const Mode = {
  Light: 'light',
  Dark: 'dark'
}
// 是否锁定的值，因为md5加密需要字符串
export const IsLock = {
  True: 'true',
  False: 'false'
}
// 系统常量
export const Const = {
  LockingOutTime: 300, // 超时锁定，单位秒，默认5分钟
  LockingIntervalTime: 10, // 定时器检查是否锁定，单位秒，默认10秒
}
// 按钮颜色
export const ButtonColor = {
  Add: '#409EFF', // 添加新增
  Edit: '#626aef', // 编辑
  Copy: '#4169E1', // 复制
  Download: '#5F9EA0', // 下载
  Delete: '#F56C6C', // 删除 | 取消
  DataflagY: '#67C23A', // 设置有效
  DataflagN: '#FF7F50', // 设置无效
  ExpandFold: '#BC8F8F', // 展开折叠
  Choose: '#409EFF', // 选择
  Export: '#FFA500', // 导出
  Clear: '#FF0000', // 清空
  Upload: '#5F9EA0', // 导入
  Login: '#87CEFA', // 登录
  Other: '#D3D3D3', // 其他
  Complete: '#42b983', // 完成
  Restart: '#228B22', // 重启
  ResetPassword: '#42b983', // 重置密码
  Dispatch: '#2db7f5' , // 派遣
  Info: '#108ee9', // 信息
  AuditDispatch: '#4682B4', // 稽查小结
  Discovery: '#228B22', // 发现
  Report: '#008080', // 报告
  AuditProve: '#6495ED', // 稽查证明
  Task: '#D2691E', // 任务,
  Rewrite: '#9370DB', // 返改
  ReportAppendix: '#008B8B', // 报告附件
}
// 标签颜色
export const TagColor = {
  Male: '#2dc2ec',
  Female: '#ee255b',


}
// 所有可选择的分页大小
export const AllPageSize = [10, 20, 50, 100, 200, 400]
// 默认页码大小
export const DefaultPageSize = 20
// 角色数据权限
export const DataPermission = {
  PermissionAll: 1, // 全部数据
  PermissionCustom: 2, // 自定义部门数据
  PermissionCurrentDepartment: 3, // 本部门数据
  PermissionCurrentDepartmentAndChild: 4, // 本部门及子部门
  PermissionMySelf: 5, // 本人
}

export const CalendarColors = ["#42b983", "#4682B4", "#CD853F"]